import {defineMessages} from "react-intl";

export const PrivacyMessages = defineMessages({
  externalTermsOfService: {id: 'app.common.link.external.terms',},
  externalSkypeLegalLink: {id: 'app.common.link.external.skype',},
  tollFreePhoneNumber: {id: 'app.common.phone.tollfree',},
  mailtoEmailAddress: {id: 'app.common.email.support',},
  allAboutCookiesLink: {id: 'app.privacy-policy.all-about-cookies-link',},
  optOutAdsLink: {id:'app.privacy-policy.third-party-advertisers.opt-out-ads-link',},
  optOutNetworkAdvertisingLink: {id:'app.privacy-policy.third-party-advertisers.opt-out-networking-advertising-link',},
  trusteLink: {id:'app.privacy-policy.third-party-advertisers.truste-link' ,},
  googleAdsLink: {id: 'app.privacy-policy.third-party-advertisers.google-ads-link',},
  googlePoliciesLink: {id:'app.privacy-policy.third-party-advertisers.google-policies-link' ,},
  facebookAdsLink: {id: 'app.privacy-policy.third-party-advertisers.facebook-ads-link',},
  facebookPolicyLink: {id: 'app.privacy-policy.third-party-advertisers.facebook-policy-link',},
  euroUnionOnlineChoicesLink: {id:'app.privacy-policy.third-party-advertisers.euro-union-online-choices-link' ,},
  externalPrivacyPolicy: {id: 'app.common.link.external.privacy',},
});

export default PrivacyMessages