import {
  GET_DEBT_REPORT,
  GET_DEBT_REPORT_FAILED,
  GETTING_DEBT_REPORT,
  RETRY_GET_DEBT_REPORT,
  GET_DEBT_REPORT2,
  GETTING_DEBT_REPORT2,
  GET_DEBT_REPORT_FAILED2
} from '../actions/debt-report-actions'
import {combineReducers} from 'redux'

const getting = (state = false, action) => {
  switch (action.type) {
    case GET_DEBT_REPORT_FAILED:
      return false
    case GETTING_DEBT_REPORT:
      return true
    case RETRY_GET_DEBT_REPORT:
      return false
    default:
      return state
  }
}

const failed = (state = false, action) => {
  switch (action.type) {
    case GET_DEBT_REPORT_FAILED:
      return true
    case RETRY_GET_DEBT_REPORT:
      return false
    case GET_DEBT_REPORT:
      return false
    default:
      return state
  }
}

const getting2 = (state = false, action) => {
  switch (action.type) {
    case GET_DEBT_REPORT_FAILED2:
      return false
    case GETTING_DEBT_REPORT2:
      return true
    case RETRY_GET_DEBT_REPORT:
      return false
    default:
      return state
  }
}

const failed2 = (state = false, action) => {
  switch (action.type) {
    case GET_DEBT_REPORT_FAILED2:
      return true
    case RETRY_GET_DEBT_REPORT:
      return false
    case GET_DEBT_REPORT2:
      return false
    default:
      return state
  }
}


export default combineReducers({
  getting,
  failed,
  getting2,
  failed2
})
