import React from 'react'
import {Link} from 'react-router-dom'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'

const rethrowIfFailed = error => {
    if (error && error._error) {
        throw new Error(error);
    }
}

export default class NotificationForm extends React.Component {

    constructor(props){
        super(props);
    }

    resend(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();

        this.ensureResend()
          .then(() => this.goToTempCodePage());
    }

    ensureResend() {
        return this.props.ensureResend
          ? Promise.resolve()
          : this.props.createEnrollment().then(rethrowIfFailed)
    }

    goToTempCodePage() {
        this.props.history.push(`/temporary-code`);
    }

    render() {
        return (
            <div>
                <HeaderContainerAP/>

                <div className='container-ap notification-form'>

                    <div className='page-title'>
                      <FormattedMessage id='app.notification.title'/>
                    </div>
                    <div className='text-ap margin-bottom-16-px'>
                      <FormattedMessage id='app.notification.instruction1'/>
                    </div>
                    <div className='text-ap margin-bottom-32-px'>
                        <FormattedMessage id='app.notification.instruction2'/>
                    </div>
                    <form onSubmit={this.props.handleSubmit}>

                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-primary btn-block btn-lg'>
                                <FormattedMessage id='app.common.got-it.button'/>
                            </button>
                        </div>

                        <div className='text-ap additional-links'>
                            <Link to="" className='back-link' onClick={(e) => this.resend(e)} id='resend'>
                                <FormattedMessage id='app.notification.alt-link'/>
                            </Link>
                        </div>
                    </form>
                </div>
                <FooterContainerAp/>
            </div>)
    }
}
