import {GET_EDS_CONTRACT} from '../actions/cp-flow-actions'

const edsContract = (state = null, action) => {
  switch (action.type) {
    case GET_EDS_CONTRACT:
      return action.edsContract
    default:
      return state
  }
}

export default edsContract
