import React from 'react'
import FormattedMessage from './FormattedMessage'

export const CounselorBubbleBudget = ({i18n, imageId, wrapper}) => (
    <div>
        <div className={wrapper}>
            <div className='budget-advice-text-wrapper margin-bottom-24-px'>
                <div className='budget-advice'>
                    <FormattedMessage id={i18n}/>
                    <div className='budget-advice-triangle-wrapper'>
                        <div className='budget-advice-triangle-border'>
                            <div className='budget-advice-triangle'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='counselor-triangle-picture-wrapper-budget'>
                <div className='counselor-picture-budget'>
                    {
                        imageId ?
                            <img src={`/api/counselor/${imageId}/image`} className='img-circle'/>
                            :
                            <div className='counselor-image-default img-circle'></div>
                    }
                </div>
            </div>
        </div>
    </div>
)

export default CounselorBubbleBudget