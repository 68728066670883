import React from 'react'
import Field from '../helpers/Field'
import FormattedMessage from '../helpers/FormattedMessage'
import FormUserError from '../form-user-error'
import FormError from '../form-error'

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
    props.submitting ||
    !props.valid ||
    state.isProcessing;
}

const renderTextArea = ({input, placeholder, isDisabled, meta: { touched, error, warning }}) => (
    <div>
      <textarea {...input} placeholder={placeholder} rows="6" cols="40"
                className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
                disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
);

export default class ActionPlanFeedbackForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isProcessing: false,
            fatalFormError: null
        }
    }

    componentDidUpdate(prevProps, nextProps, state) {
        if (this.props.error !== prevProps.error && this.props.error !== null) {
            this.props.updateLoadingIcon(false)

            let err = this.props.error
            this.setState({
                fatalFormError: err,
                isProcessing: false
            })

            this.props.verifyToken()
        }
    }

    render() {

        const {intl, formSyncErrors}=this.props;

        return (
            <div className='feedback-container'>
                {/*<form onSubmit={e => {e.preventDefault();}}>*/}
                <form onSubmit={this.props.handleSubmit}>

                    <div className='feedback-intro-text margin-bottom-24-px'>
                        <FormattedMessage id='app.action-plan-feedback.feedback-prompt-1'/>
                    </div>

                  <div className='feedback-intro-text feedback-intro-text-mobile margin-bottom-24-px'>
                    <FormattedMessage id='app.action-plan-feedback.feedback-prompt-2'/>
                    <a href="tel:212-927-5771">212-927-5771</a>
                    <FormattedMessage id='app.action-plan-feedback.feedback-prompt-3'/>
                  </div>

                  <div className='my-account-container'>

                    <div className='my-account-input-field margin-bottom-16-px'>
                        <label htmlFor='description'>
                            <FormattedMessage id='app.action-plan-feedback.comments'/>
                        </label>
                        <Field name='feedbackDescription' type='text' id='feedbackDescription' component={renderTextArea}/>
                    </div>

                    <div>
                        <FormUserError validationErrors={formSyncErrors} intl={intl}/>
                        <FormError error={this.state.fatalFormError}/>
                    </div>

                    <div className='submit-button-round margin-bottom-24-px'>
                        <button id='submit'
                                type={'submit'}
                                className='btn btn-primary btn-lg btn-tat-finish-creating'
                                disabled={isSubmitDisabled(this.props, this.state)}>
                            <FormattedMessage id='app.appointment.submit.button'/>
                        </button>
                    </div>
                  </div>
                </form>
            </div>
        )
    }
}
