import {ENGLISH_CURRENTLY_WORKING_COUNSELORS} from '../actions/counselor-actions'

const englishCurrentlyWorkingCounselors = (state = null, action) => {
    switch (action.type) {
        case ENGLISH_CURRENTLY_WORKING_COUNSELORS:
            return action.englishCurrentlyWorkingCounselors
        default:
            return state
    }
}

export default englishCurrentlyWorkingCounselors