import axios from "axios";

export const CREATE_LEAD = 'CREATE_LEAD'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const FETCH_FILES = 'FETCH_FILES'
export const DELETE_FILE = 'DELETE_FILE'

export const createLead = data => {

        const lead = Object.assign({}, data)

        return (dispatch) => {
            return axios
                .post('/api/customers/create-lead', lead)
                .then(response => {
                    let res = response.data
                    dispatch({
                        type: CREATE_LEAD,
                        lead: res
                    })
                })
                .catch(() => {
                })
        }
}

export const fetchFiles = () => {
    return (dispatch, getState) => {
      const employer = getState().employer;
        return axios
            .post('api/customers/get-files', employer)
            .then(response => {
                let res = response.data
                dispatch({
                    type: FETCH_FILES,
                    employer: res
                })
            })
            .catch(() => {
            })
    }
}