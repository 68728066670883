import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import FooterActionPlan from '../footer-action-plan'
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../../actions/login-actions";
import AppointmentDetail from "../appointment-detail";
import {
    setActiveCounselor
} from "../../actions/counselor-actions";
import ActionPlanNav from "./action-plan-nav";
import ActionPlanMessagesTabBar from './action-plan-messages-tab-bar';
import ActionPlanMessagesSubheader from './action-plan-messages-subheader';
import ActionPlanMessagesNew from './action-plan-messages-new';
import {useHistory} from "react-router-dom";
import {getBookedAppointments, getCompletedAttendedAppointments} from "../../actions/calendly-actions";
import {getEmployerFromContactId} from "../../actions/employer-actions";
import {employerLoadedForNav} from "../../actions/nav-actions";
import {doesContactHaveCreditReportOnFile} from "../../actions/trust-plus-credit-actions";

export default function ActionPlanMessages(props) {

    const activeCounselor = useSelector((state) =>state.activeCounselor)
    const login = useSelector((state) => state.login)
    const registration = useSelector((state) => state.registration)
    const dispatch = useDispatch();
    const history = useHistory();
    const tab_NEW = "new";
    const [selectedTab, setSelectedTab] = useState(tab_NEW)


    return (
        <div id='ap-messages'>
            <ActionPlanNav />
            <div id='action-plan-content'>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <h1 className='action-plan-title margin-bottom-8-px'>
                        <FormattedMessage id='app.action-plan-messages.page-title'/>
                    </h1>
                </div>
                <ActionPlanMessagesSubheader
                    tab_NEW={tab_NEW}
                    selectedTab={selectedTab}
                    />
                <div className='customer-portal-dashboard-container'>
                    <div className='messaging-box-container'>
                        <ActionPlanMessagesTabBar
                            tab_NEW={tab_NEW}
                            selectedTab={selectedTab}
                            clearMessageId={() => setMessageId()}
                            />
                        <ActionPlanMessagesNew/>
                    </div>
                </div>
                <FooterActionPlan />
            </div>
        </div>
        )

    }