import React, {useEffect, useState} from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import FooterCalendlyContainer from '../containers/footer-calendly-container'
import HeaderContainer from "../containers/header-container";
import CalendlyWidgetHelperRoundRobin from "./helpers/CalendlyWidgetHelperRoundRobin";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../actions/login-actions"
import {
    calendlyEventRoundRobin, calendlyEventRoundRobinSaml,
    setCurrentCalendlyConfirmationPath
} from "../actions/calendly-actions";
import {displayLoadingIcon} from "../actions/loading-icon-action";
import {getOrCreateLoginRecord} from "../actions/b-flow-actions";
import Icon from "../../resources/icons";
import DclAccepted from "./dcl-accepted";
import DclNotAccepted from "./dcl-not-accepted";

export default function RoundRobinPickATime() {

    const [appointmentSubmitted, setValue] = useState(null);
    const [displayProduct, setDisplayProduct] = useState(false);

    const registration = useSelector(state => state.registration);
    const login = useSelector(state => state.login);
    const locale = useSelector(state => state.locale);
    const employer = useSelector(state => state.employer)
    const calendlyRoundRobinUrl = useSelector(state => state.calendlyRoundRobinUrl);
    const calendlyRoundRobinUrlSpanish = useSelector(state => state.calendlyRoundRobinUrlSpanish);
    const dcl = useSelector(state => state.dcl);

    const dispatch = useDispatch();
    let history = useHistory();

    useEffect( () => {
        window.scrollTo(0, 0)
    }, [])

    const handleAppointmentSubmit = () => {
      setValue(true);
    }

    const onEventScheduled = (event) => {
        handleAppointmentSubmit()
        createCalendlyEvent(event)
    }

    const createCalendlyEvent = (event) => {
      dispatch(displayLoadingIcon(true))
        if(login != null && login.samlEntity != null){
            dispatch(calendlyEventRoundRobinSaml(event.data.payload.event.uri, event.data.payload.invitee.uri)).then(() => {
                dispatch(verifyToken(login)).then(() => {
                    dispatch(setCurrentCalendlyConfirmationPath('/your-session-is-scheduled'))
                    dispatch(displayLoadingIcon(false))
                    history.push('/action-plan')
                })
            })
        } else {
            dispatch(calendlyEventRoundRobin(event.data.payload.event.uri, event.data.payload.invitee.uri)).then(() => {
                dispatch(setCurrentCalendlyConfirmationPath('/your-session-is-scheduled'))
                dispatch(displayLoadingIcon(false))
                history.push('/your-session-is-scheduled')
            })
        }
    }

    useEffect(() => {
        if (dcl !== null  && dcl.onboardingProductName !== '') {
            setDisplayProduct(true)
        }
    });

    const chooseProduct = () => {
        if (dcl !== null && dcl.onboardingProductStatus === 'Accepted') {
            return <DclAccepted calendlyWidget={displayCalendlyWidget()} />
        } else {
            return <DclNotAccepted calendlyWidget={displayCalendlyWidget()} />
        }
    }

    const displayCalendlyWidget = () => {
        return <div className='container-ap'>
            <div className='select-by-availability-container'>
                <CalendlyWidgetHelperRoundRobin
                    locale={locale}
                    registration={registration}
                    onEventScheduled={(event) => onEventScheduled(event)}
                    calendlyRoundRobinUrl={calendlyRoundRobinUrl}
                    calendlyRoundRobinUrlSpanish={calendlyRoundRobinUrlSpanish}
                    employer={employer}
                    aria-label='A calendar widget to schedule an appointment'
                />
            </div>
        </div>
    }

    return (
        calendlyRoundRobinUrl && <div>
            <HeaderContainer displayAlert={null}
                             alertName={null}/>
            {appointmentSubmitted ? '' :
                <main>
                        {displayProduct ? chooseProduct()
                            :
                            <div className='container-ap ptxxl'>
                                <div className='select-by-availability-intro'>
                                      <h1 className='round-robin-title'>
                                          <FormattedMessage id='app.round-robin.pick-a-time.title'/>
                                      </h1>
                                      <p>
                                          <FormattedMessage id='app.round-robin.pick-a-time.instruction1'/>
                                      </p>
                                      <p>
                                          <FormattedMessage id='app.round-robin.pick-a-time.instruction2'/>
                                      </p>
                                      <p>&nbsp;</p>
                                      <p className="tpc-triangle-red">
                                          <FormattedMessage id='app.round-robin.pick-a-time.select-by-availability'/>
                                      </p>
                                      <p>
                                          <FormattedMessage id='app.round-robin.pick-a-time.select-date-instruction'/>
                                      </p>
                                </div>

                                {displayCalendlyWidget()}

                            </div>
                          }

                      <FooterCalendlyContainer/>
                  </main>
              }
          </div>
    )
}
