import axios from 'axios'
import {SubmissionError} from 'redux-form'
import 'moment-timezone'

export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const GET_OR_CREATE_LOGIN_RECORD = 'GET_OR_CREATE_LOGIN_RECORD'
export const CHECK_CONTACT_RECORD = 'CHECK_CONTACT_RECORD'
export const CPLOGOUT = 'CPLOGOUT';
export const GET_EDS_CONTRACT = 'GET_EDS_CONTRACT';
export const UPDATE_EDS_CONTRACT = 'UPDATE_EDS_CONTRACT'
export const PAGE_COMPLETED = 'PAGE_COMPLETED'

export function cpLogout(){
    return (dispatch, getState) =>{
        dispatch({
            type: CPLOGOUT
        })
    }
}

export const checkContactRecord = data => {
    return (dispatch, getState) => {
        console.log("cp flow actions checkContactRecord");
        const username = data;

        return axios
            .post('/api/checkContact', "", {params: {username}})
            .then((response) => {
              console.log("checkContactRecord: registration is")
              console.log(response.data)
                dispatch({
                    type: CHECK_CONTACT_RECORD,
                    registration: response.data,
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const updateCPContact = data => {
  return (dispatch, getState) => {

    const contact = Object.assign({}, getState().registration)
    contact.firstName = data.firstName
    contact.lastName = data.lastName
    contact.agreedToTerms = data.agreedToTerms

    return axios
      .post('/api/contact/updateCPContact', contact)
      .then((response) => {
        dispatch({
          type: UPDATE_CONTACT,
          registration: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export const getOrCreateLoginRecord = data => {
  return (dispatch, getState) => {

    const registration = Object.assign({}, getState().registration)

    if (registration.id == null || registration.id === '' ) {
      console.log("NO REGISTRATION PRESENT WHAT TO DO");
    } else {
      return axios
        .post('/api/login/getCPLoginRecord', registration)
        .then((response) => {
          dispatch({
            type: GET_OR_CREATE_LOGIN_RECORD,
            login: response.data
          })
        })
        .catch(error => {
          throw new SubmissionError({_error: error.response.data.message})
        })
    }
  }
}

export const resetPassword = data => {
    return (dispatch, getState) => {
        const login = Object.assign({}, data)

        login.username = getState().login.username;
        login.language = getState().locale;

        return axios
            .post('/api/login/cpResetPassword', login)
            .then((response) => {
                console.log("password reset email has been sent")
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export function getEdsContract(organization) {
    return (dispatch, getState) => {

        return axios
            .get('/api/eds-contract/find-by-organization', {params: {organization}})
            .then(response => {
            dispatch({
                type: GET_EDS_CONTRACT,
                edsContract: response.data
            })
        })
    .catch(() => {})
    }
}

export function pageCompleted(val) {
  let pageNumber = val
  return {
    type: PAGE_COMPLETED,
    pageCompleted: pageNumber
  }
}

export const updateEdsContract = data => {
    return (dispatch, getState) => {
      const edsContract = Object.assign({}, getState().edsContract)
      edsContract.extraWebinars = data.extraWebinars
      edsContract.pageCompleted = getState().pageCompleted
      edsContract.customerSignedName = data.customerSignedName
      edsContract.customerSignedDate = data.customerSignedDate
      edsContract.customerTitle = data.customerTitle
      edsContract.status = data.status

      if (data.customerAuthorization === true) {
        edsContract.customerAuthorization = "Yes"
      }
      if (data.customerAuthorization === false) {
        edsContract.customerAuthorization = "No"
      }


      console.log("updateEdsContract" + edsContract );

      return axios
      .patch('/api/eds-contract/update', edsContract)
      .then((response) => {
        dispatch({
          type: UPDATE_EDS_CONTRACT,
          edsContract: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })

    }
}

export function generateEdsContractPdf() {
  return (dispatch, getState) => {
    let edsContractId = getState().edsContract.id

    return axios
      .get('/api/eds-contract/generate-pdf', {params: {edsContractId}})
      .then(response => {
        dispatch({
          type: GET_EDS_CONTRACT,
          edsContract: response.data
        })
      })
      .catch(() => {})
  }
}

export const updateCPAccountName = data => {
  return (dispatch, getState) => {

    const contact = Object.assign({}, getState().registration)
    contact.firstName = data.firstName
    contact.lastName = data.lastName

    return axios
      .post('/api/contact/updateCPContact', contact)
      .then((response) => {
        dispatch({
          type: UPDATE_CONTACT,
          registration: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export const updateCPAccountEmail = data => {
  return (dispatch, getState) => {

    const contact = Object.assign({}, getState().registration)
    contact.email = data.email

    return axios
      .post('/api/contact/updateCPContact', contact)
      .then((response) => {
        dispatch({
          type: UPDATE_CONTACT,
          registration: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export const updateCPAccountPhone = data => {
  return (dispatch, getState) => {

    const contact = Object.assign({}, getState().registration)
    contact.preferredPhone = data.preferredPhone
    // contact.mobilePhone = data.mobilePhone

    return axios
      .post('/api/contact/updateCPContact', contact)
      .then((response) => {
        dispatch({
          type: UPDATE_CONTACT,
          registration: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}
