import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function HomePageChecklist(props) {

  return (
    <div className='intro-text-landing-page-wrapper'>
      <div className='landing-container intro-text'>
        <h1>
          <FormattedMessage id='app.landing-page.introduction.dreams-1'/><br />
          <FormattedMessage id='app.landing-page.introduction.debt'/>
        </h1>
        <div className='checklist-landing-page-wrapper'>
          <FormattedMessage id='app.landing-page.introduction.intro-blurb'/>
        </div>

        <div className='checklist-tagline'>
          <FormattedMessage id='app.landing-page.introduction.intro-tagline'/>
        </div>

        <div className='submit-button-round landing-submit-button-desktop'>
          <button className='btn btn-primary btn-block btn-intro'
                  id='submit-next-location'
                  onClick={()=>props.determineNextLocation()}>
            {props.employerDefaultsToAskACoach() ?
              <FormattedMessage id='app.landing-page.introduction.first-step.button'/>
              :
              <FormattedMessage id='app.landing-page.introduction.first-step.button'/>
            }
          </button>
        </div>
        <div className='submit-button-round landing-submit-button-mobile'>
          <button className='btn btn-primary btn-block btn-intro'
                  id='mobile-submit-next-location'
                  onClick={()=>props.determineNextLocation()}>
            {props.employerDefaultsToAskACoach() ?
              <FormattedMessage id='app.landing-page.introduction.first-step.button'/>
              :
              <FormattedMessage id='app.landing-page.introduction.first-step.button'/>
            }
          </button>
        </div>
      </div>
    </div>
  )
}
