import {ACTIVE_TAB} from "../actions/debt-summary-account-detail-actions";

const activeTab = (state = 'recommendations', action) => {
    switch (action.type) {
        case ACTIVE_TAB:
            return action.activeTab
        default:
            return state
    }
}

export default activeTab