import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

function DclFaqItem(props){

    const [open, setOpen] = useState(false);

    function toggle(e) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        e.preventDefault()
        setOpen(!open)
    }

    useEffect(() => {
        if (props.contactUsClicked === true) {
            setOpen(true)
        }

    }, [props.contactUsClicked]);

    return (
      <div className={`dcl-faq-item ${open ? 'show-answer' : ''}`}>
          {props.num === 5 ? <a id='contact-us'></a> : ''}
          <div className='dcl-faq-question-wrapper'>
              <div className='dcl-question-icon'>
                  <div className='dcl-question-icon-plus'>
                      <Link to='' onClick={e => toggle(e)}>
                          <FontAwesomeIcon icon={['fass', 'square-plus']}/>
                      </Link>
                  </div>
                  <div className='dcl-question-icon-minus'>
                      <Link to='' onClick={e => toggle(e)}>
                          <FontAwesomeIcon icon={['fass', 'square-minus']}/>
                      </Link>
                  </div>
              </div>
              <div className='dcl-question-text'>
                  <FormattedMessage id={`app.dcl-landing-page.faq.question-${props.num}`}/>
              </div>
          </div>
          <div className='dcl-faq-answer-wrapper'>
              <div className='dcl-answer-empty-icon'>

              </div>
              <div className='dcl-answer-text'>
                  <p>
                      { props.num === 5 ?
                          <div>
                              <FormattedMessage id='app.dcl-landing-page.faq.answer-5.text-1'/>
                                <a href='mailto:support@mytrustplus.org'>
                                  <FormattedMessage id='app.dcl-landing-page.faq.answer-5.email'/>
                                </a>
                              <FormattedMessage id='app.dcl-landing-page.faq.answer-5.text-2'/>
                          </div>
                         :
                      <FormattedMessage id={`app.dcl-landing-page.faq.answer-${props.num}`}/>
                      }
                  </p>
              </div>
          </div>
      </div>)
}


export default DclFaqItem