import en from './en-US'
import es from './es-US'
import en_jpmc from './en-US_JPMC'

const messages = {
  'en-US': en,
  'es-US': es,
  'en-US_JPMC': en_jpmc
}

export default messages
