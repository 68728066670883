import {connect} from 'react-redux'
import LandingPropel from '../components/landing-propel'
import {toggleLocale} from '../actions/locale-actions'
import {injectIntl} from 'react-intl'


function mapStateToProps(state) {
  return {
    locale: state.locale,
    employer: state.employer,
    activeCounselors: state.activeCounselors
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle(currentLocale) {
      dispatch(toggleLocale(currentLocale))
    },
  }
}

const LandingPropelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LandingPropel))

export default LandingPropelContainer
