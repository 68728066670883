import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'

import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ForgotpasswordForm from '../components/forgotpassword-form'
import {passwordReset} from "../actions/b-flow-actions";

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('login')(state),
    login: state.login,
  }
}

const ForgotpasswordFormContainer = connect(
    mapStateToProps,
)(reduxForm({
  form: 'ForgotpasswordForm',
  onSubmit: (values, dispatch, props) => {
    return dispatch(passwordReset(values))
  },
  destroyOnUnmount: false
})(injectIntl(ForgotpasswordForm))
)

export default ForgotpasswordFormContainer

