import React from 'react'

export const FineEmoji = ({
                           style = {},
                           fill = '#000000',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 460 460',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-4" transform="translate(120.500000, 114.600000)">
        <circle id="Oval" stroke="#AEE0E8" stroke-width="16" fill="#FFFFFF" cx="109" cy="109" r="101"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="80" cy="82" r="16"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="138" cy="82" r="16"></circle>
        <path d="M59,152 C69.6060606,142.666667 86.2727273,138 109,138 C131.727273,138 148.393939,142.666667 159,152" id="Line" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square" transform="translate(109.000000, 145.000000) scale(1, -1) translate(-109.000000, -145.000000) "></path>
      </g>
    </g>
  </svg>;

export default FineEmoji