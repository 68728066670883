import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Route, Switch} from "react-router";

import CustomerPortalDashboard from "./customer-portal-dashboard"
import CustomerPortalPrivacyPolicy from "./customer-portal-privacy-policy";
import CustomerPortalTermsOfService from "./customer-portal-terms-of-service";
import CustomerPortalFaq from "./customer-portal-faq";
import CustomerPortalReports from "./customer-portal-reports";
import CustomerPortalMyAccount from "./customer-portal-my-account";
import CustomerPortalContracts from "./customer-portal-contract-landing";
import CustomerPortalContractAbout from "./customer-portal-contract-about";
import CustomerPortalContractEmployees from "./customer-portal-contract-employees";
import CustomerPortalContractDetailsOne from "./customer-portal-contract-details-one";
import CustomerPortalContractDetailsTwo from "./customer-portal-contract-details-two";
import CustomerPortalContractDetailsThree from "./customer-portal-contract-details-three";
import CustomerPortalContractAllSteps from "./customer-portal-contract-all-steps";
import CustomerPortalContractPreview from "./customer-portal-contract-preview";
import CustomerPortalContractSign from "./customer-portal-contract-sign";
import CustomerPortalContractSubmit from "./customer-portal-contract-submit";
import {getEmployerFromAccountId} from "../../actions/employer-actions";
import {getEdsContract} from "../../actions/cp-flow-actions";
import {useHistory} from "react-router-dom";

export default function CustomerPortal() {

  const contact = useSelector(state => state.registration);
  const login = useSelector(state => state.login);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (login !== null || login !== '') {
      if (contact && login.isCustomersContact === true) {

        dispatch(getEmployerFromAccountId(contact.accountId)).then(() => {
          dispatch(getEdsContract(contact.accountId))
         })

      } else if (contact && login.isCustomersContact === false) {
        history.push(`/action-plan`)
      }

    } else {
      history.push(`/customers/enter-your-email-address`)
    }
  },[login, contact])

  return (
    <Switch>
      <Route exact path="/customer-portal"> <CustomerPortalDashboard /></Route>
      <Route exact path="/customer-portal/privacy-policy"><CustomerPortalPrivacyPolicy /></Route>
      <Route exact path="/customer-portal/terms-of-service"><CustomerPortalTermsOfService /></Route>
      <Route exact path="/customer-portal/faqs"><CustomerPortalFaq /></Route>
      <Route exact path="/customer-portal/reports"><CustomerPortalReports /></Route>
      <Route exact path="/customer-portal/my-account"><CustomerPortalMyAccount /></Route>

      <Route exact path="/customer-portal/contracts"><CustomerPortalContracts /></Route>
      <Route exact path="/customer-portal/contracts/about"><CustomerPortalContractAbout /></Route>
      <Route exact path="/customer-portal/contracts/employees"><CustomerPortalContractEmployees /></Route>
      <Route exact path="/customer-portal/contracts/details-one"><CustomerPortalContractDetailsOne /></Route>
      <Route exact path="/customer-portal/contracts/details-two"><CustomerPortalContractDetailsTwo /></Route>
      <Route exact path="/customer-portal/contracts/details-three"><CustomerPortalContractDetailsThree /></Route>
      <Route exact path="/customer-portal/contracts/view-all-steps"><CustomerPortalContractAllSteps /></Route>
      <Route exact path="/customer-portal/contracts/preview"><CustomerPortalContractPreview /></Route>
      <Route exact path="/customer-portal/contracts/sign"><CustomerPortalContractSign /></Route>
      <Route exact path="/customer-portal/contracts/submit"><CustomerPortalContractSubmit /></Route>
    </Switch>
  )

}
