import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function DebtTradeAccounts(props) {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  function renderAccount(account, index) {
    return <div>
      {
        index > 0 ?
          <hr /> :
          <p />
      }
      <p className='collection-info'>
          <span className='bold'>
            {account.name}
          </span>
        <FormattedMessage id='app.debt-report-recommendations.collections-text-3'/>
        <span className='bold'>
            {formatter.format(account.balance)}
          </span>
        <FormattedMessage id='app.debt-report-recommendations.collections-text-4'/>
        <span className='bold'>
            {account.originalCreditor}
          </span>.
      </p>

    </div>
  }

  return (
    <div className='collection-action-wrapper'>
      <h3>
        <FormattedMessage id='app.debt-report-recommendations.collections'/>
      </h3>
      {
        props.accounts.map((account, index) => (
          renderAccount(account, index)
        ))
      }

      {
        props.type === "dispute"  ?
          <div className='submit-button-round sixty-percent-btn'>
            <button className='btn btn-primary btn-block'
                    id='debt-report-work-on-this'
                    onClick={props.disputeOnline}>
              <FormattedMessage id='app.debt-report-reviewed-collections.btn-text-1'/>
            </button>
            <p className='collections-dispute-offline'>
              <Link to='' onClick={(e)=>props.disputeOffline(e)} >
                <FormattedMessage id='app.debt-report-reviewed-collections.dispute-offline'/>
              </Link>
            </p>
          </div> :
          <div className='collections-session-scheduled flex'>
              <div className='collections-session-schedule-icon-circle'>
                <FontAwesomeIcon icon={['fas', 'circle']} aria-label='An icon of a circle' />
                <div className='collections-session-scheduled-icon'>
                  <FontAwesomeIcon icon={['far', 'calendar-alt']} aria-label='An icon of a calendar on a circle' />
                </div>
              </div>
            <div className='collections-session-scheduled-text'>
              <span className='bold'>
                <FormattedMessage id='app.debt-report-reviewed-collections.text-4'/>
              </span>
              <br />
              {props.firstBookedAppointmentStr}
            </div>
          </div>
      }
    </div>
  )

}