import React from 'react'

export const AchievedPlantMobile = ({
                            style = {},
                            fill = '',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 44 50',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TAT&lt;650" transform="translate(-305.000000, -149.000000)" fill="#48BFAF" fillRule="nonzero">
        <path d="M321.093846,149 C326.207692,150.384615 331.141538,151.635385 336.047692,153.02 C337.143993,153.337067 338.173181,153.851661 339.084615,154.538462 C342.056923,156.776923 342.213846,160.007692 339.587692,162.62 C339.153846,163.049231 338.738462,163.496923 338.424615,163.82 C339.896923,166.169231 341.567692,168.375385 342.753846,170.816923 C344.535385,174.481538 344.346154,178.316923 341.581538,181.372308 C337.556923,185.821538 336.536923,190.644615 338.48,196.22 C338.558415,196.550789 338.610883,196.887196 338.636923,197.226154 L335.050769,197.563077 C333.966154,194.281538 333.532308,191.055385 334.46,187.672308 C335.503077,183.846154 334.589231,182.133846 330.883077,180.555385 C330.573846,184.330769 329.129231,185.203077 325.266154,184.709231 C320.318462,184.076923 315.296923,182.692308 310.261538,184.076923 C308.544615,184.538462 306.956923,185.503077 305,186.384615 C305.103931,185.732666 305.250398,185.088213 305.438462,184.455385 C307.487692,179.032308 310.293846,174.167692 315.767692,171.532308 C319.856923,169.561538 323.087692,170.230769 326.096923,173.544615 C327.332444,174.932064 328.899812,175.983328 330.652308,176.6 C333.144615,177.523077 335.346154,179.253846 337.829231,180.753846 C342.850769,176.701538 340.381538,170.6 335.147692,166.090769 C334.686154,166.349231 334.141538,166.644615 333.601538,166.903077 C329.909231,168.656923 326.678462,167.761538 324.578462,164.133846 C321.818462,159.435385 321.306154,154.210769 321.093846,149 Z" id="Path"></path>
      </g>
    </g>

  </svg>;

export default AchievedPlantMobile