import React from 'react'
import Field from './helpers/Field'
import FormattedMessage from './helpers/FormattedMessage'
import FormUserError from './form-user-error'
import FormError from './form-error'
import NormalizationService from '../services/normalization-service'
import HeaderContainer from '../containers/header-container'
import UploadFileInput from './upload-file-input'

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
    props.submitting ||
    !props.valid ||
    state.isProcessing;
}

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

const renderTextArea = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <Field name='comment' component='textarea' type={type} placeholder={placeholder}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

const renderFileInput = ({input, type, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <Field name='attachment' component={UploadFileInput} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}
           dataAllowedFileExtensions="jpg png bmp"/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>

)

export default class ClientStoriesForm extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      fatalFormError: null,
      showThankYouHeading: false,
      showForm: true,
      showContainer: true
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, state) {

    if (this.props.error !== prevProps.error && this.props.error !== null) {

      let err = this.props.error
      this.setState({
        isProcessing: false,
        fatalFormError: err
      })
    }
  }

  shouldFieldsBeDisabled (error) {
    return ""
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.props.handleSubmit();
    this.setState({
      showThankYouHeading: true,
      showForm: false,
      showContainer: false
    })
  }

  displayFormHeading() {
    return (
      <div>
        <div className='client-stories-subtitle'>
         <FormattedMessage id='app.client-stories.heading'/>
        </div>

        <div className='heading-description margin-bottom-16-px'>
         <FormattedMessage id='app.client-stories.description-1'/>
        </div>

        <div className='heading-description margin-bottom-32-px'>
         <FormattedMessage id='app.client-stories.description-2'/>
        </div>
      </div>
    )
  }

  displayForm() {
    return (
      <div>
        <form onSubmit={this.handleFormSubmit}>
          <div className='client-stories-heading margin-bottom-16-px'>
            <FormattedMessage id='app.client-stories.form-heading'/>
          </div>

          <div className='form-group'>
            <div className='label-text'>
              <FormattedMessage id='app.client-stories.input.preferred-name'/>
            </div>
            <Field name='preferredName' type='text' id='preferred-name' component={renderField} isDisabled={this.shouldFieldsBeDisabled(this.state.fatalFormError)}/>
          </div>


          <div className="form-group">
            <div className='label-text'>
              <FormattedMessage id='app.client-stories.input.phone'/>
            </div>
            <Field name='successStoryPhone' type='text' id='successStoryPhone' component={renderField} isDisabled={this.shouldFieldsBeDisabled(this.state.fatalFormError)}
                   normalize={NormalizationService.mobilePhone}/>
          </div>

          <div className="form-group margin-bottom-24-px">
            <div className='label-text'>
              <FormattedMessage id='app.client-stories.input.comment'/>
            </div>
            <Field name='comment' component={renderTextArea} placeholder='' />
          </div>

          <div className="form-group margin-bottom-40-px">
            <div className='client-stories-disclosure margin-bottom-24-px'>
              <FormattedMessage id='app.client-stories.disclosure'/>
            </div>
            <div className='label-text'>
              <FormattedMessage id='app.client-stories.input.signature'/>
            </div>
            <Field name='signature' type='text' id='signature' component={renderField} isDisabled={this.shouldFieldsBeDisabled(this.state.fatalFormError)}/>
          </div>

          <div className='submit-button-round margin-bottom-24-px'>
            <button id='submit'
                    type={'submit'}
                    className='btn btn-primary btn-block btn-lg'
                    disabled={isSubmitDisabled(this.props, this.state)}>
              <FormattedMessage id='app.client-stories.submit.button'/>
            </button>
          </div>
        </form>
      </div>
    )
  }

  displaySucessHeading() {
    return (
      <div>
        <div className='client-stories-subtitle client-stories-thanks-heading margin-bottom-24-px'>
         <FormattedMessage id='app.client-stories.thanks-heading'/>
        </div>
        <div className='margin-bottom-16-px'>
          <FormattedMessage id='app.client-stories.thanks-body-1'/>
        </div>
        <div className='margin-bottom-16-px'>
          <FormattedMessage id='app.client-stories.thanks-body-2'/>
        </div>
      </div>
    )
  }

  render() {

    const {intl, formSyncErrors}=this.props;

    return(

      <div id='client-stories'>

        <div>
          <HeaderContainer displayAlert={this.props.showAlert}
                           alertName={this.props.nameOfAlert}/>
        </div>

        <div className='container client-stories'>

          { this.state.showThankYouHeading ? this.displaySucessHeading() : this.displayFormHeading() }

            <div className={ this.state.showContainer ? 'client-stories-your-story-container' : 'client-stories-thanks-container' }>

            <div className='client-stories-your-story-wrapper'>

              <div>
                <FormUserError validationErrors={formSyncErrors} intl={intl}/>
                <FormError error={this.state.fatalFormError}/>
              </div>

              { this.state.showForm ? this.displayForm() : '' }

            </div>
          </div>

        </div>
      </div>
      )
  }

}
