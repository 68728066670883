import {NAV_ENTERED} from '../actions/nav-actions'

export default function navEntered(state = false, action) {
  switch (action.type) {
    case NAV_ENTERED:
      return action.navEntered
    default:
      return state
  }
}
