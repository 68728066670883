import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import DebtSummaryWaiting from '../debt-summary-waiting'
import DebtSummaryPermission from '../debt-summary-permission'
import DebtSummaryCreditReportPageContainer from "../../containers/debt-summary-credit-report-page-container";
import FooterActionPlan from '../footer-action-plan';
import DebtReportRecommendations from "../debt-report/debt-report-recommendations";
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../../actions/login-actions";
import {isCreditFormOpen} from "../../actions/credit-form-actions";
import {debtSummaryAccountDetail} from "../../actions/debt-summary-account-detail-actions";
import {debtSummaryCategoriesExpanded} from "../../actions/debt-summary-categories-expanded-actions";
import {
  accountNotRecognized,
  accountRecognized,
  resetFlaggedInOnboarding,
  updateTradeDebtAccountNoFlagInformationCorrect,
  updateTradeDebtAccountYesFlagInformationIncorrect
} from "../../actions/trade-debt-account-actions";
import {getDebtReport} from "../../actions/debt-report-actions";
import {createCollectionTAT, markCollectionTATCreated} from "../../actions/take-action-today-actions";
import activeTab from "../../reducers/active-tab";
import ActionPlanNav from "./action-plan-nav";
import {displayLinkMoneyModal} from "../../actions/link-money-actions";

export default function ActionPlanDebtSummary(props) {

  const debtReportDummyData = {
    id:'abcDebtReportId',
    contactId:'abcContactId',
    createdDate:'2019-04-22T16:14:43.000+0000',
    creditPullDate:'2019-04-20',
    totalDebt: 225400.0,
    totalMonthlyPayment: 0.0,
    debtCategories: [
      {
        id:'credit',
        name: 'Credit/ Charge Card',
        totalBalance: 8400.0,
        totalMonthlyPayment: 84.0,
        tradeDebtAccounts:[
          {
            id: 'cc1',
            name: 'Visa',
            debtCategoryId: 'credit',
            accountType: 'Credit/ Charge Card',
            type: 'CreditCard',
            balance: 4600.0,
            creditLimit: 12000.0,
            monthlyPayment: 46.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: "BankruptcyOrWageEarnerPlan",
            ratingCode: "7",
            triangle: true,
            interestRate: '5.00'
          },
          {
            id: 'cc2',
            name: 'MasterCard',
            debtCategoryId: 'credit',
            accountType: 'Credit/ Charge Card',
            type: 'CreditCard',
            balance: 3400.0,
            creditLimit: 7000.0,
            monthlyPayment: 34.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: null,
            ratingCode: null,
            triangle: false,
            interestRate: '12.00'
          },
          {
            id: 'cc3',
            name: 'DSNB Macys',
            debtCategoryId: 'credit',
            accountType:'Credit/ Charge Card',
            type: 'CreditCard',
            balance: 400.0,
            creditLimit: 1000.0,
            monthlyPayment: 40.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: "BankruptcyOrWageEarnerPlan",
            ratingCode: "7",
            triangle: true,
            interestRate: '9.00'
          }
        ]
      },
      {
        id:'student',
        name: 'Student Loan',
        totalBalance: 46000.0,
        totalMonthlyPayment: 460.0,
        tradeDebtAccounts:[
          {
            id: 'student1',
            name: 'Navient',
            debtCategoryId: 'student',
            accountType: 'Student Loan',
            type: 'Educational',
            balance: 40000.0,
            creditLimit: 50000.0,
            monthlyPayment: 400.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: null,
            ratingCode: null,
            triangle: false,
            interestRate: '5.00'
          },
          {
            id: 'student2',
            name: 'Mohelia',
            debtCategoryId: 'student',
            accountType: 'Student Loan',
            type: 'Educational',
            balance: 6000.0,
            creditLimit: 5000.0,
            monthlyPayment: 60.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: "BankruptcyOrWageEarnerPlan",
            ratingCode: "7",
            triangle: true,
            interestRate: '6.00'

          }
        ]
      },
      {
        id:'mortgage',
        name: 'Mortgage',
        totalBalance: 138000.0,
        totalMonthlyPayment: 690.0,
        tradeDebtAccounts:[
          {
            id: 'mortgage1',
            name: 'Bank of America',
            debtCategoryId: 'mortgage',
            accountType: 'Mortgage',
            type: 'Mortgage',
            balance: 138000.0,
            creditLimit: 150000.0,
            monthlyPayment: 690.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: null,
            ratingCode: null,
            triangle: false,
            interestRate: '4.00'
          }
        ]
      },
      {
        id:'auto',
        name: 'Auto Loan',
        totalBalance: 15000.0,
        totalMonthlyPayment: 150.0,
        tradeDebtAccounts:[
          {
            id: 'auto1',
            name: 'GM Car Loan',
            debtCategoryId: 'auto',
            accountType: 'Auto Loan',
            type: 'Automobile',
            balance: 7000.0,
            creditLimit: 9000.0,
            monthlyPayment: 70.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: null,
            ratingCode: null,
            triangle: false,
            interestRate: '8.00'
          },
          {
            id: 'auto2',
            name: 'Bank of America',
            debtCategoryId: 'auto',
            accountType: 'Auto Loan',
            type: 'Automobile',
            balance: 8000.0,
            creditLimit: 15000.0,
            monthlyPayment: 80.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: "BankruptcyOrWageEarnerPlan",
            ratingCode: "7",
            triangle: true,
            interestRate: '6.00'
          }
        ]
      },
      {
        id:'personal',
        name: 'Personal Loan',
        totalBalance: 6000,
        totalMonthlyPayment: 60.0,
        tradeDebtAccounts:[
          {
            id: 'personal1',
            name: 'Parents',
            debtCategoryId: 'personal',
            accountType: 'Personal Loan',
            type: 'Personal',
            balance: 6000.0,
            creditLimit: 0.0,
            monthlyPayment: 900.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: null,
            ratingCode: null,
            triangle: false,
            interestRate: '1.00'
          }
        ]
      },
      {
        id:'collection',
        name: 'Collection',
        totalBalance: 4000.0,
        totalMonthlyPayment: 400.0,
        tradeDebtAccounts:[
          {
            id: 'collection1',
            name: 'Chase Bank collection',
            debtCategoryId: 'collection',
            accountType: 'Collection',
            type: 'Collection',
            balance: 4000.0,
            creditLimit: 0.0,
            monthlyPayment: 400.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: 'Original Creditor Name',
            accountOwnershipType: null,
            ratingType: null,
            ratingCode: null,
            triangle: false,
            interestRate: '15.00'
          }
        ]
      },
      {
        id:'other',
        name: 'Other',
        totalBalance: 8000.0,
        totalMonthlyPayment: 80.0,
        tradeDebtAccounts:[
          {
            id: 'other1',
            name: 'My Broker',
            debtCategoryId: 'other',
            accountType: 'Other',
            type: 'Other',
            balance: 8000.0,
            creditLimit: 16000.0,
            monthlyPayment: 1000.0,
            openDate: '2018-04-02',
            lastActivityDate: null,
            originalCreditor: null,
            accountOwnershipType: null,
            ratingType: null,
            ratingCode: null,
            triangle: false,
            interestRate: '5.00'
          }
        ]
      }
    ]
  }

  const locale = useSelector(state => state.locale)
  const login = useSelector(state => state.login)
  const registration = useSelector(state => state.registration)
  const debtReport = useSelector(state => state.debtReport)
  //const debtReport = debtReportDummyData
  const tab = useSelector(state => state.activeTab)

  const navCurrentComponent = useSelector(state => state.currentComponent)

  const activeCounselor = useSelector(state => state.activeCounselor)
  const employer = useSelector(state => state.employer)
  const creditFormOpen = useSelector(state => state.isCreditFormOpen)
  const accountDetail = useSelector(state => state.debtSummaryAccountDetail)
  const bookedAppointments = useSelector(state => state.bookedAppointments)
  const categoriesExpanded = useSelector(state => state.debtSummaryCategoriesExpanded)
  const collectionTATCreated = useSelector(state => state.collectionTATCreated)

  const dispatch = useDispatch();

  useEffect( () => {
    if (registration.creditReportAuthorizationReceived === 'Yes') {
      dispatch(getDebtReport())
    }
    dispatch(displayLinkMoneyModal(false))
  }, [])

  function toggleCreditFormOpen(truthy) {
    dispatch(isCreditFormOpen(truthy))
    dispatch(verifyToken(login))
  }

  function accountUpdateRecognized(id, status) {
    dispatch(accountRecognized(id)).then(() => {
      dispatch(getDebtReport()).then(() => {
        if(status === false){
          dispatch(createCollectionTAT())
          dispatch(markCollectionTATCreated())
        }
      })
    })
  }

  function accountUpdateNotRecognized(id, status) {
    dispatch(accountNotRecognized(id)).then(() => {
      dispatch(getDebtReport()).then(() => {
        if(status === false){
          dispatch(createCollectionTAT())
          dispatch(markCollectionTATCreated())
        }
      })
    })
  }

  function debtReportCollectionUpdate(account, know) {
    if(collectionTATCreated === false) {
      if (know) {
        accountUpdateRecognized(account.id, this.props.collectionTATCreated)
      } else {
        accountUpdateNotRecognized(account.id, this.props.collectionTATCreated)
      }
    } else {
      if (know) {
        accountUpdateRecognized(account.id, this.props.collectionTATCreated)
      } else {
        accountUpdateNotRecognized(account.id, this.props.collectionTATCreated)
      }
    }
  }


    function pageRecommendations() {
      return <DebtReportRecommendations activeCounselor={activeCounselor}
                                        debtReport={debtReport}
                                        employer={employer}
                                        fromActionPlan={true}
                                        locale={locale}
                                        accountDetail={accountDetail}
                                        debtSummaryAccountDetail={(accountDetail) => dispatch(debtSummaryAccountDetail(accountDetail))}
                                        categoriesExpanded={categoriesExpanded}
                                        debtSummaryCategoriesExpanded={(categoriesExpanded) => dispatch(debtSummaryCategoriesExpanded(categoriesExpanded))}
                                        verifyToken={() => dispatch(verifyToken(login))}
                                        updateTradeDebtAccountYesFlagInformationIncorrect={() => dispatch(updateTradeDebtAccountYesFlagInformationIncorrect())}
                                        updateTradeDebtAccountNoFlagInformationCorrect={() => dispatch(updateTradeDebtAccountNoFlagInformationCorrect())}
                                        getDebtReport={() => dispatch(getDebtReport())}
                                        bookedAppointments={bookedAppointments}
                                        resetFlaggedInOnboarding={() => dispatch(resetFlaggedInOnboarding())}
                                        debtReportCollectionUpdate={(account, know) => debtReportCollectionUpdate(account, know)}
                                        activeTab={tab}
                                        updateActiveTab={(tab) => dispatch(activeTab(tab))}
      />
    }

    function pagePermission() {
      return <DebtSummaryPermission
        contactId={registration.id}
        employer={employer}
        creditReportAuthorizationReceived={registration.creditReportAuthorizationReceived}
        isCreditFormOpen={creditFormOpen}
        navCurrentComponent={navCurrentComponent}
        toggleCreditFormOpen={(truthy) => toggleCreditFormOpen(truthy)}/>
    }

    function pageDebtSummaryCreditReportContainer() {
      return <DebtSummaryCreditReportPageContainer contactId={registration.id}
                                                   navCurrentComponent={navCurrentComponent}/>
    }

    function pageSection() {
      let auth = registration.creditReportAuthorizationReceived
      if (debtReport != null) {
        return pageRecommendations()
      }
      else if ((auth === null || auth === "No") && creditFormOpen === false) {
        return pagePermission()
      }
      else if ((auth === null || auth === "No") && creditFormOpen) {
        return pageDebtSummaryCreditReportContainer()
      }
      else {
        return <DebtSummaryWaiting/>
      }
    }

    return (
      <div id='action-plan-debt-summary'>
        <ActionPlanNav />
        <div id='action-plan-content'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <h1 className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.action-plan-debt-summary.page-title'/>
            </h1>
          </div>

          {pageSection()}
          <FooterActionPlan/>
        </div>
      </div>)
}
