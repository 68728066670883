import React from 'react'

export const AchievedPlantMobile = ({
                                  style = {},
                                  fill = '',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 39 49',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TAT&lt;650" transform="translate(-299.000000, -446.000000)" fillRule="nonzero">
        <g id="Asset-3" transform="translate(323.857150, 474.857150) rotate(-45.000000) translate(-323.857150, -474.857150) translate(301.357150, 448.061696)">
          <path d="M19.5381818,53.415 C19.5627273,51.345 19.0022727,50.0563636 16.9486364,49.5081818 C16.4065793,49.2973797 15.8915825,49.0227147 15.4145455,48.69 L15.5168182,48.015 L19.1209091,48.3463636 C19.2189347,47.6114902 19.2626894,46.8703937 19.2518182,46.1290909 C18.675,39.4936364 20.3195455,33.1363636 21.6081818,26.7668182 C21.7309091,26.1695455 22.2790909,25.6581818 22.6309091,25.1059091 L23.1586364,25.3881818 C22.8313636,31.2463636 20.8636364,37.0063636 22.0909091,43.56 L25.6090909,37.7386364 L26.0181818,37.9881818 L24.0709091,42.6027273 L28.1904545,44.0304545 C28.1413636,44.2513636 28.0881818,44.4722727 28.035,44.6931818 L21.9845455,44.6931818 C22.4672727,53.1245455 21.9845455,44.5909091 22.4672727,53.1245455" id="Path" fill="#690B33"></path>
          <path d="M15.7704545,39.6368182 C14.6618182,38.2295455 13.4836364,36.8795455 12.2481818,35.415 L10.0554545,36.2618182 L6.78272727,35.9509091 C3.16227273,34.1631818 1.32954545,31.0418182 0,26.9509091 L7.36363636,26.0222727 C6.46772727,23.8786364 5.52272727,21.6245455 4.45909091,19.0677273 C8.88545455,17.7259091 12.7104545,18.4131818 16.4904545,21.0640909 C16.6990909,20.1477273 16.8668182,19.4277273 17.0509091,18.6095455 C16.6466665,18.5280119 16.2359362,18.4828316 15.8236364,18.4745455 C10.6772727,19.0145455 8.05090909,15.2754545 8.38636364,10.6772727 C8.46550978,9.7993976 8.59389372,8.92665991 8.77090909,8.06318182 C9.47045455,4.53272727 9.99818182,4.11545455 13.0172727,5.95227273 C15.0259091,7.17954545 16.5763636,9.14318182 18.0940909,10.5586364 C17.685,7.02409091 19.1413636,3.60409091 21.5631818,0.421363636 C26.3495455,2.86363636 27.6504545,7.00772727 27.8386364,11.8636364 C27.6259091,5.60454545 32.1177273,2.96590909 36.4745455,0 C39.8659091,5.31818182 37.8081818,9.96954545 34.9690909,14.7272727 L43.56,14.7272727 C42.1363636,20.7327273 37.8,23.3181818 32.9277273,25.2040909 C33.1527273,26.5663636 33.6027273,26.9222727 34.8709091,26.3209091 C37.9554545,24.8563636 41.2445455,24.8236364 44.7790909,25.2 C44.7259091,27.9818182 43.3636364,29.9659091 41.5595455,31.7454545 L30.5140909,36.9245455 L27.6872727,40.0418182 C26.3740909,37.9963636 24.9095455,39.3136364 23.4368182,39.7840909 C22.2954545,40.1522727 21.15,40.4959091 19.9595455,40.8640909 L15.7704545,39.6368182 Z M26.7913636,26.5459091 C29.1231818,25.4781818 28.305,23.6413636 28.3663636,21.8536364 L24.8031818,23.5718182 L26.7913636,26.5459091 Z" id="Shape" fill="#48BFAF"></path>
        </g>
      </g>
    </g>

  </svg>;

export default AchievedPlantMobile