import React from 'react'
import {injectIntl} from "react-intl";
import {useDispatch} from "react-redux";
import Icon from "../../../resources/icons"
import FormattedMessage from "../helpers/FormattedMessage";

function ActionPlanMessagesTabBar(props) {

    const dispatch = useDispatch();

    const handleClick = (e, tab) => {
        e.preventDefault();

        props.setSelectedTab(tab);
        props.clearMessageId();
    }

    return (<div className='messaging-header-wrapper'>

        <div className={props.selectedTab === props.tab_NEW ? 'messaging-heading-wrapper active' : 'messaging-heading-wrapper'}>
            <div className='messaging-header'>
                <FormattedMessage id='app.action-plan-messaging.new'/>
            </div>
            <div className='messaging-icon'>
                <Icon name="messaging-new"/>
            </div>
        </div>
    </div>)

};

export default injectIntl(ActionPlanMessagesTabBar);