import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Icon from "../../../resources/icons";
import {Link} from 'react-router-dom'
import {CounselorBubbleDebtSummary} from '../helpers/CounselorBubbleDebtSummary'
import SimpleDebtSummaryTable from './simple-debt-summary-table'
import TradeDebtAccountFormContainer from '../../containers/debt-report/trade-debt-account-form-container'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

export default class DebtSummaryTradeAccount extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      blueBarLeft: 0,
      blueBarTop: 0,
      blueBarHeight: 0,
      blueBoxTop: 0,
      flaggedInOnboarding: this.props.accountDetail.flaggedInOnboarding 
    }
  }

  componentDidMount() {
    //this.props.accountDetail
  }

  componentDidUpdate(prevProps, prevState, state) {
    if (this.state.flaggedInOnboarding != prevState.flaggedInOnboarding) {
      this.setState({
        flaggedInOnboarding: this.state.flaggedInOnboarding
      })

      this.flaggingAccountsSection();
    }
  }

  placeBlue(tableRect, blueBarRect) {
    let blueBarLeft = blueBarRect.left + 30 + window.pageXOffset
    let blueBarTop = blueBarRect.top + window.pageYOffset

    let blueBarTopBound = tableRect.top + window.pageYOffset
    let blueBarBottomBound = blueBarTopBound + tableRect.height

    let blueBoxTop = blueBarTopBound

    if (blueBoxTop < blueBarTop && blueBarTop - blueBoxTop > 150) {
      blueBoxTop = blueBarTop - 150
    }

    let blueBoxRect = this.tradeAccountBoxWrapper.getBoundingClientRect()
    let blueBoxHeight = blueBoxRect.height + 1

    if (blueBoxTop + blueBoxHeight > blueBarBottomBound) {
      blueBoxTop = blueBarBottomBound - blueBoxHeight
    }

    if (blueBoxTop === 187) {
      blueBoxTop = blueBoxTop + 34
     }

    this.setState({
      blueBarLeft: blueBarLeft,
      blueBarTop: blueBarTop,
      blueBarHeight: blueBarRect.height,
      blueBoxTop: blueBoxTop
    })
  }

  toggleCategoriesExpanded(categoryId) {
    this.props.toggleCategoriesExpanded(categoryId)
  }

  setAccountDetail(accountDetail) {
    this.props.setAccountDetail(accountDetail)
  }

  updateTradeDebtAccountYesFlagInformationIncorrect() {
    this.props.updateTradeDebtAccountYesFlagInformationIncorrect()
  }

  updateTradeDebtAccountNoFlagInformationCorrect() {
    this.props.updateTradeDebtAccountNoFlagInformationCorrect()
  }

  resetFlaggedInOnboarding() {
    this.props.resetFlaggedInOnboarding()
  }

  getDebtReport() {
    this.props.getDebtReport()
  }

  backToOverview() {
    this.props.setAccountDetail({
      id: null,
      name: null,
      balance: null,
      creditLimit: null,
      monthlyPayment: null,
      accountType: null,
      type: null,
      openDate: null,
      lastActivityDate: null,
      accountStatusType: null,
      accountOwnershipType: null,
      originalCreditor: null,
      ratingType: null,
      triangle: false,
      flaggedByClient: null,
      interestRate: null,
      flaggedInOnboarding: null 
    })
  }


  unflaggedAccount() {
    return(
      <div className='trade-account-detail-flagged-item margin-bottom-24-px'>
          <FormattedMessage id='app.action-plan-debt-summary.detail.information-correct'/>

          <div className='trade-account-detail-flagged-item-buttons-wrapper'>

            <div className='submit-button-round flagged-item-yes margin-bottom-16-px'>
              <button id='flagged-item-yes'
                    type={''}
                    onClick={this.onYesInformationIsCorrect.bind(this)}
                    className='btn btn-default btn-lg'>
                    <FormattedMessage id='app.action-plan-debt-summary.detail.information-correct-yes'/>
              </button>
            </div>

            <div className='submit-button-round flagged-item-no margin-bottom-16-px'>
              <button id='flagged-item-no'
                  type={''}
                  onClick={this.onNoInformationIsIncorrect.bind(this)}
                  className='btn btn-default btn-lg'>
                  <FormattedMessage id='app.action-plan-debt-summary.detail.information-correct-no'/>
              </button>
            </div>

          </div>
        </div>
        )
  }


  notFlaggedCorrectAccount() {
    return(
      <div className='trade-account-detail-flagged-item margin-bottom-24-px'>
        <div className='flag-icon-wrapper'>
          <div className='flag-icon flag-icon-yes'>
            <FontAwesomeIcon icon={['fas', 'thumbs-up']} />
          </div>
          <div className='flag-icon-text'>
            <FormattedMessage id='app.action-plan-debt-summary.detail.confirmed-account'/>
          </div>
        </div>
        <div className='trade-account-detail-link'>
          <Link to="#" onClick={this.onUndoConfirmation.bind(this)} id='undo-confirmation'>
            <FormattedMessage id='app.action-plan-debt-summary.detail.undo-confirmation-link'/>
          </Link>
        </div>
      </div>
      )
  }

  yesFlaggedIncorrectAccount() {
    return(
      <div className='trade-account-detail-flagged-item margin-bottom-24-px'>
        <div className='flag-icon-wrapper'>
          <div className='flag-icon flag-icon-no'>
            <FontAwesomeIcon icon={['fas', 'flag']} />
          </div>
          <div className='flag-icon-text'>
            <FormattedMessage id='app.action-plan-debt-summary.detail.flagged-account'/>
          </div>
        </div>
        <div className='trade-account-detail-link'>
          <Link to="#" onClick={this.onRemove.bind(this)} id='remove'>
            <FormattedMessage id='app.action-plan-debt-summary.detail.remove-flag-link'/>
          </Link>
        </div>
      </div>
      )
  }


  flaggingAccountsSection() {
    if (this.state.flaggedInOnboarding === null) {
      return this.unflaggedAccount(); 
    }
    else if (this.state.flaggedInOnboarding === "No") {
      return this.notFlaggedCorrectAccount();
    }
    else if (this.state.flaggedInOnboarding === "Yes") {
      return this.yesFlaggedIncorrectAccount();
    }
  }

  onYesInformationIsCorrect() {
    this.updateTradeDebtAccountNoFlagInformationCorrect()
    this.setState({
      flaggedInOnboarding: 'No'
    })
  }

  onNoInformationIsIncorrect() {
    this.setState({
      flaggedInOnboarding: 'Yes'
    })
    this.updateTradeDebtAccountYesFlagInformationIncorrect()
  }

  onUndoConfirmation() {
    this.resetFlaggedInOnboarding()
    this.setState({
      flaggedInOnboarding: null
    })
  }

  onRemove() {
    this.resetFlaggedInOnboarding()
    this.setState({
      flaggedInOnboarding: null
    })
  }

  render() {
    console.log("Trade Debt Account Details & State");
    console.log(this.props.accountDetail);
    console.log(this.state);

    let accountType = this.props.accountDetail.type ? 'app.action-plan-debt-summary.detail.' + this.props.accountDetail.type : 'app.common.punctuation.space';
    let ratingType = this.props.accountDetail.ratingType ? 'app.action-plan-debt-summary.detail.' + this.props.accountDetail.ratingType : 'app.common.punctuation.space';
    let accountStatusType = this.props.accountDetail.accountStatusType ? 'app.action-plan-debt-summary.detail.' + this.props.accountDetail.accountStatusType : 'app.common.punctuation.space';
    let accountOwnershipType = this.props.accountDetail.accountOwnershipType ? 'app.action-plan-debt-summary.detail.' + this.props.accountDetail.accountOwnershipType : 'app.common.punctuation.space';

    return (
      <div className='trade-account-detail-page'>
        <div className='trade-account-row-wrapper blue' style={{"top" : this.state.blueBarTop, "left" : this.state.blueBarLeft, height: this.state.blueBarHeight}}>
        </div>

        <div className='trade-account-box-wrapper blue'
             ref={ (tradeAccountBoxWrapper) => this.tradeAccountBoxWrapper = tradeAccountBoxWrapper}
             style={{"top": this.state.blueBoxTop}}>
          <div className='trade-account-box-text blue'>
            <div className='back-link-trade-account-page  margin-bottom-24-px'>
              <a onClick={() => this.backToOverview()}>  &lt; <FormattedMessage id='app.action-plan-debt-summary.detail.back-to-overview'/></a>
            </div>
            <div className='trade-account-page-content'>

              <div className='trade-account-title margin-bottom-8-px'>
                {this.props.accountDetail.name}
              </div>
              <div className='action-plan-divide trade-account-divide margin-bottom-8-px'>
              </div>

              <div className='trade-account-exclamation-triangles'>

                {
                  this.props.accountDetail.triangle ?
                    <div>
                      <div className='exclamation-triangle-2 margin-bottom-8-px'>
                        <div className='exclamation-triangle'>
                          <div className='exclamation-triangle-wrapper'>
                            <div className='icon-legend'>
                              <Icon name='exclamation-triangle' fill="#ba0c2f" />
                            </div>
                          </div>
                        </div>
                        <div className='trade-account-exclamation-text'>
                          <FormattedMessage id={ratingType}/>
                        </div>
                      </div>
                      <div className='action-plan-divide trade-account-divide'>
                      </div>
                    </div>
                  : this.props.accountDetail.ratingType ?
                    <div>
                      <div className='trade-account-exclamation-text margin-bottom-8-px'>
                        <FormattedMessage id={ratingType}/>
                      </div>
                      <div className='action-plan-divide trade-account-divide'>
                      </div>
                    </div>
                  : ''
                }
              </div>

              <table className='trade-account-detail margin-bottom-8-px'>
                <tbody>
                <tr>
                  <td><FormattedMessage id='app.action-plan-debt-summary.detail.type-of-account'/></td>
                  <td><FormattedMessage id={accountType}/></td>
                </tr>
                <tr>
                  <td><FormattedMessage id='app.action-plan-debt-summary.detail.status-of-account'/></td>
                  <td><FormattedMessage id={accountStatusType}/></td>
                </tr>
                <tr>
                  <td><FormattedMessage id='app.action-plan-debt-summary.detail.balance'/></td>
                  <td>{formatter.format(this.props.accountDetail.balance)}</td>
                </tr>
                <tr>
                  <td><FormattedMessage id='app.action-plan-debt-summary.detail.credit-limit'/></td>
                  <td>{formatter.format(this.props.accountDetail.creditLimit)}</td>
                </tr>
                <tr>
                  <td><FormattedMessage id='app.action-plan-debt-summary.detail.min-payment'/></td>
                  <td>{formatter.format(this.props.accountDetail.monthlyPayment)} </td>
                </tr>
                <tr>
                  <td><FormattedMessage id='app.action-plan-debt-summary.detail.open-date'/></td>
                  <td>{this.props.accountDetail.openDate}</td>
                </tr>
                <tr>
                  <td><FormattedMessage id='app.action-plan-debt-summary.detail.last-activity-date'/></td>
                  <td>{this.props.accountDetail.lastActivityDate}</td>
                </tr>
                { this.props.accountDetail.type == 'Collection' && this.props.accountDetail.originalCreditor &&
                <tr>
                  <td><FormattedMessage id='app.action-plan-debt-summary.detail.original-creditor'/></td>
                  <td>{this.props.accountDetail.originalCreditor}</td>
                </tr>
                }
                { this.props.accountDetail.accountOwnershipType &&
                  <tr>
                    <td><FormattedMessage id='app.action-plan-debt-summary.detail.account-ownership-type'/></td>
                    <td><FormattedMessage id={accountOwnershipType}/></td>
                  </tr>
                }
                  <tr>
                    <td><FormattedMessage id='app.action-plan-debt-summary.detail.account-interest-rate'/></td>
                    <td>
                      <TradeDebtAccountFormContainer
                        interestRate={this.props.accountDetail.interestRate}
                        debtSummaryAccountDetail={this.state.debtSummaryAccountDetail}/>
                    </td>
                  </tr>
                </tbody>
              </table>


              <div className='trade-account-detail-interest-rate-wrapper margin-bottom-8-px'>
                <div className='trade-account-detail-interest-message'>
                  <FormattedMessage id='app.action-plan-debt-summary.detail.account-interest-rate-message'/>
                </div>
                <div className='trade-account-detail-interest-arrow'>
                  <Icon name="interest-rate-arrow"/>
                </div>
            </div>

              <div className='action-plan-divide trade-account-divide'>
                </div>

               { this.flaggingAccountsSection() } 


            </div>

         </div>
        </div>

        <div className='trade-account-detail-debt-summary-info margin-bottom-32-px'>
          <SimpleDebtSummaryTable
            debtReport={this.props.debtReport}
            showTradeAccounts={this.props.showTradeAccounts}
            imageId={this.props.imageId}
            categoriesExpanded={this.props.categoriesExpanded}
            toggleCategoriesExpanded={(categoryId) => this.toggleCategoriesExpanded(categoryId)}
            currentAccountDetailId={this.props.accountDetail.id}
            setAccountDetail={(accountDetail) => this.setAccountDetail(accountDetail)}
            placeBlue={(tableRect, blueBarRect) => this.placeBlue(tableRect, blueBarRect)}
          />
        </div>


        <div className='trade-account-detail-debt-summary-bubble'>
          <CounselorBubbleDebtSummary i18n={'app.action-plan-debt-summary.counselor.unfamiliar-advice'}
                                      imageId={this.props.imageId}
                                      wrapper={'unfamiliar-advice-wrapper margin-bottom-24-px'}/>
        </div>
      </div>
      )
  }
}
