import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";
import Icon from "../../../resources/icons";
import {Field, reduxForm} from "redux-form";
import {changePassword} from "../../actions/login-actions";
import ValidationService from "../../services/validation-service";

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

function CustomerPortalAccountSettingsSecurity(props) {
  const { error, handleSubmit, pristine, reset, submitting } = props

  const isSubmitDisabled = (props) => {
    return props.pristine ||
      props.submitting ||
      !props.valid;
  }

  useEffect(() => {
    if (props.submitSucceeded) {
      const timeout = setTimeout(() => {
        props.reset();
      }, 3000);

      return () => clearTimeout(timeout)
    }
  }, [props.submitSucceeded]);

  return(
      <div>
        <div className='customer-portal-back-link' onClick={((e) => {
          props.onSimpleClick(e, props.pageAccLanding)
        })}>
          <FormattedMessage id='app.customer-portal-common.back-link'/>
        </div>
        <h2><FormattedMessage id='app.customer-portal.my-account.security'/></h2>
        <div className='content-text'>
          <FormattedMessage id='app.customer-portal.my-account.security-text-1'/>
        </div>
        <ul>
          <li><FormattedMessage id='app.customer-portal.my-account.security-text-2'/></li>
          <li><FormattedMessage id='app.customer-portal.my-account.security-text-3'/></li>
        </ul>
        <div className='content-text header'>
          <FormattedMessage id='app.customer-portal.my-account.security-text-4'/>
        </div>
        <ul>
          <li><FormattedMessage id='app.customer-portal.my-account.security-text-5'/></li>
          <li><FormattedMessage id='app.customer-portal.my-account.security-text-6'/></li>
          <li><FormattedMessage id='app.customer-portal.my-account.security-text-7'/></li>
        </ul>

        <form className='margin-top-50-px' onSubmit={props.handleSubmit}>
          <label><FormattedMessage id='app.customer-portal.my-account.security-current-password'/></label>
          <Field name='password' type='password' id='password'
                 autocomplete='password' component={renderField}/>
          <label><FormattedMessage id='app.customer-portal.my-account.security-new-password'/></label>
          <Field name='newpassword' type='password' id='newpassword'
                 autocomplete='newpassword' component={renderField}/>
          <label><FormattedMessage id='app.customer-portal.my-account.security-confirm-password'/></label>
          <Field name='confirmpassword' type='password' id='confirmpassword'
                 autocomplete='confirmpassword' component={renderField}/>

          {error && <div className='customer-portal-account-error'><FormattedMessage id={error} /></div>}

          {!props.submitSucceeded ? <>
              {props.dirty ? <>
                <div className='submit-button-round continue'>
                  <button
                    className="btn btn-primary btn-block"
                    id="submit"
                    type={'submit'}
                    disabled={isSubmitDisabled(props)}>
                    <FormattedMessage id='app.common.save.button'/>
                  </button>
                </div>
              </> :<div/>
              }

            </>:
            <div className='w-50'>
              <div className='big-check-circle'>
                <Icon name="checkmark"/>
              </div>
              <div className='big-check-text'>
                <FormattedMessage id='app.customer-portal.my-account.security-text-8'/>
              </div>
            </div>
          }
        </form>
      </div>
    )
}

export default reduxForm({
  form: 'CustomerPortalAccountSettingsSecurityForm',
  validate: ValidationService.changePassword,
  onSubmit: (values, dispatch, props) => {
    return dispatch(changePassword(values))
  },
  destroyOnUnmount: false
})(injectIntl(CustomerPortalAccountSettingsSecurity))
