import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import {useSelector} from "react-redux";

const usePageTracking = () => {
  const login = useSelector(state => state.login)
  const employer = useSelector(state => state.employer)

  useEffect(() => {
    ReactGA.initialize("UA-35686382-11");
    ReactGA.set({ dimension2: employer ? employer.code : '' })
    if (login == null) {
      ReactGA.set({ dimension1: 'Visitor' });
    } else {
      ReactGA.set({ userId: login.id });
      ReactGA.set({ dimension1: 'Logged-In User' });
    }
  }, []);

  useEffect(() => {
    let d1 = "Visitor"
    let d2 = employer ? employer.code : ''
    let userId = ''
    if (login != null) {
      d1 = "Logged-In User"
      userId = login.id
    }
    ReactGA4.initialize("G-S3H1C7X1YT");
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname, dimension1: d1, userId: userId, dimension2: d2 });
  }, []);
};

export default usePageTracking;