import React, {useEffect} from 'react'
import Icon from "../../../resources/icons";
import FormattedMessage from "../helpers/FormattedMessage";

function LandingPagesHeader(props) {

  return (
    <div id='landing-pages-header'>
      <div className={`propel-header-wrapper ${props.displayBlueHeader ? 'scroll' : ''}`}>
        <div className='propel-landing-header-links-wrapper propel-links-wrapper'>
          <div className='nav-logo-landing-page'>
            <div className='nav-logo-landing-page-desktop'>
              <Icon name="tp-full-logo"/>
            </div>
            <div className='nav-logo-landing-page-mobile'>
              <Icon name="tp-logo"/>
            </div>
          </div>
          <div className='landing-header-links-propel'>
            { props.displayLocaleToggle ?
              <button className={`landing-spanish-link ${props.displayBlueHeader ? 'white' : ''}`}
                      onClick={ e => props.onLocaleToggle(e) }>
                <FormattedMessage id='app.introduction.locale-link'/>
              </button> :
              <div/>
            }
            <button className='landing-spanish-link'
                    onClick={props.goToWelcomePage}>
              <FormattedMessage id='app.landing-page.introduction.log-on.button'/>
            </button>
            <button className='landing-spanish-link sign-up-link'
                    onClick={props.goToAppointmentSchedule}>
              <FormattedMessage id='app.landing-page.introduction.sign-up.button-white'/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default LandingPagesHeader
