import React from 'react';
import Icon from "../../resources/icons";
import FormattedMessage from './helpers/FormattedMessage'


const options = {
  year: 'numeric', month: 'long', day: '2-digit', timeZone: 'UTC'};

export default class TakeActionTodayPendingForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      displayTat: true
    }
  }

  onRowClick(e) {
    this.props.handleUpdatedStatus(this.props.item)
    // console.log("Clicked on -> " + this.props.item.id);
    this.setState({
      displayTat: false
    })
  }

  howToAccomplish(tatHowToAccomplish) {
    return <div>
      <div className='take-action-today-second-heading margin-bottom-4-px'>
        <FormattedMessage id='app.action-plan-take-action-today.how-to-achieve'/>
      </div>
      <div className='take-action-today-details'>
        <div dangerouslySetInnerHTML={{
          __html: tatHowToAccomplish}} />
      </div>
    </div>
  }

  render() {
    return (
      <div className={`take-action-today-to-do-wrapper ${this.state.displayTat ? '' : 'hide'}`}>
        <div className='take-action-today-item-wrapper'>
          <div className='take-action-today-column-1'>
            <div className='take-action-today-sub-heading margin-bottom-4-px'>
              { this.props.item.tatGoalComment }
            </div>
            <div className='take-action-today-due-date'>
              <FormattedMessage id='app.action-plan-take-action-today.due-date'/>
             {
                new Intl.DateTimeFormat(this.props.locale, options).format(
                  new Date(this.props.item.tatGoalDueDate))
              }
            </div>
            <div className='margin-top-16-px take-action-today-how-to-achieve-column-1'>
              { this.howToAccomplish(this.props.item.tatHowToAccomplish)}
            </div>
            <div className='form-group form-group-take-action-today'>
              <div className='submit-button-round'>
                <button type='button'
                        className='btn btn-default btn-lg'
                        onClick={e => this.onRowClick(e) }>
                  <FormattedMessage id='app.action-plan-take-action-today.mark-as-achieved'/>
                </button>
              </div>
            </div>
          </div>


          <div className='take-action-today-column-2 take-action-today-how-to-achieve-column-2'>
            { this.howToAccomplish(this.props.item.tatHowToAccomplish)}
          </div>

          <div className='take-action-today-column-3'>
            <div className='to-do-plant-wrapper'>
              <div className='to-do-plant-svg-desktop'>
                  <Icon name="to-do-plant"/>
              </div>
              <div className='to-do-plant-svg-mobile'>
                <Icon name="to-do-plant-mobile"/>
              </div>
            </div>
          </div>
        </div>
      {this.props.item.lastItem ? '' :
        <div className='action-plan-divide take-action-today-divide'></div>
      }
      </div>
      )
  }

}
