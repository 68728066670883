import {GET_CLIENT} from '../actions/client-actions'

const client = (state = null, action) => {
  switch (action.type) {
    case GET_CLIENT:
      return action.client
    default:
      return state
  }
}

export default client
