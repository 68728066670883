import React, {useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import CustomerPortalFooter  from './customer-portal-footer';
import {injectIntl} from "react-intl";
import CustomerPortalAccountHeader from "./customer-portal-account-header";

function CustomerPortalAccountLanding(props) {

    return(
      <div>
        <h2><FormattedMessage id='app.customer-portal.my-account-heading-1'/></h2>
        <hr />
        <div className='account-block'>
          <h3>
            <a href="#" onClick={((e) => { props.onSimpleClick(e, props.pageAccSettings) })}>
              <FormattedMessage id='app.customer-portal.my-account.settings'/>
            </a>
          </h3>
          <div className='content-text content-text-home'>
            <FormattedMessage id='app.customer-portal.my-account.text-1'/>
          </div>
        </div>
        <hr />
        <div className='account-block'>
          <h3>
            <a href="#" onClick={((e) => { props.onSimpleClick(e, props.pageAccSecurity) })}>
              <FormattedMessage id='app.customer-portal.my-account.security'/>
            </a>
          </h3>
          <div className='content-text content-text-home'>
            <FormattedMessage id='app.customer-portal.my-account.text-2'/>
          </div>
        </div>
        <hr />
      </div>
    )
}

export default (injectIntl(CustomerPortalAccountLanding))