import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from "../footer-action-plan";
import {getLinkedAccounts, getTransactions} from "../../actions/link-money-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormattedMessage from "../helpers/FormattedMessage";
import {Field, reduxForm} from "redux-form";
import {injectIntl} from "react-intl";

function ActionPlanLinkMoneyTransactions(props) {
    const dispatch = useDispatch();

    const linkMoneyAccounts = useSelector(state => state.linkMoneyAccounts)
    const linkMoneyTransactions = useSelector(state => state.linkMoneyTransactions)

    const [accountId, setAccountId] = useState('')

    useEffect(() => {
        dispatch(getLinkedAccounts())
        dispatch(getTransactions({"accountId": accountId}))
    }, [])

    const renderSelect = ({input, type, id, name, normalize, props, meta: {touched, error}}) => (
        <div>
            <select {...input} name={name} type={type} id={id} className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}>
                <option value=''>All Accounts</option>
                {linkMoneyAccounts.map((account) =>
                    <option value={account.accountId}>{account.accountName}</option>
                )}
            </select>
            {
                touched && (error &&
                    <div className='label-text red'>
                        <FormattedMessage id={error}/>
                    </div>
                )
            }
        </div>
    )

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleAccountIdChange = (value) => {
        setAccountId(value)
    }

    return (
        linkMoneyAccounts && <div id='action-plan-link-money-category-spending-chart'>
            <ActionPlanNav />
            <div id='action-plan-content'>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <h1 className='action-plan-title margin-bottom-8-px'>
                        Transactions
                    </h1>
                </div>

                <div className='cat-filters'>
                    <p>Choose account:</p>
                    <form onSubmit={props.handleSubmit}>
                        <div className='form-field'>
                            <Field component={renderSelect}
                                   type='text'
                                   name='accountId'
                                   id='accountId'
                                   onChange={e => handleAccountIdChange(e.target.value)}
                            />
                        </div>

                        <div className='submit-button-round form-field'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-primary btn-block btn-lg'>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>

                { linkMoneyTransactions.length > 0 ?
                    linkMoneyTransactions.map((item) => {
                            return <div className='account-row'>
                                <div className='col account-row-logo'>
                                    <div className='img-wrapper'>
                                        <img src={item.logo} width="auto" height="auto" alt={item.merchantName} />
                                    </div>
                                </div>
                                <div className='col account-row-details'>
                                    <div className='account-type'>
                                        {item.merchantName}
                                    </div>
                                    <div className='account-bank'>
                                        {item.transactionId}
                                    </div>
                                    {item.enrichedData ?
                                        <div className='account-row-category'>
                                            <div className='account-category'>
                                                {item.enrichedData.categoryIdName}
                                            </div>
                                            <div className='account-detail-category'>
                                                {item.enrichedData.detailCategoryIdName}
                                            </div>
                                            <div className='account-high-level-category'>
                                                {item.enrichedData.highLevelCategoryIdName}
                                            </div>
                                        </div>
                                        : null}
                                </div>

                                <div className='col account-row-details align-right'>
                                    <div className='account-type'>
                                        {USDollar.format(item.amount)}
                                    </div>
                                    <div className='account-bank'>
                                        {item.date}
                                    </div>
                                </div>
                            </div>
                        })
                        :
                        <div className="link-money-transactions-none">No transactions to display.</div>
                }

                <FooterActionPlan />

                </div>
        </div>
    )
}

const linkMoneyTransactionsForm = reduxForm({
    form: 'ActionPlanLinkMoneyTransactions',
    onSubmit: (values, dispatch, props) => {
        return dispatch(getTransactions(values)).then(() => {
        })
    },
    destroyOnUnmount: false
})(injectIntl(ActionPlanLinkMoneyTransactions))

export default linkMoneyTransactionsForm
