import FormattedMessage from "./helpers/FormattedMessage";
import Icon from "../../resources/icons";
import React, {useEffect} from "react";

export default function DclAccepted(props) {

    return (
        <div className='dcl-round-robin landing-container'>
            <div className='select-by-availability-intro'>
                <h1 className='round-robin-dcl-title'>
                    <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.title'/>
                </h1>
                <div className='dcl-round-robin-container'>
                  <div className='dcl-accepted-wrapper'>
                    <div className='dcl-accepted-content'>
                      <div className='round-robin-dcl-icon'>
                          <Icon name='dcl-fit'/>
                      </div>
                      <p>
                          <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.text-1'/>
                          <span className='strong'>
                            <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.text-2'/>
                          </span>
                          <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.text-3'/>
                      </p>
                      <div className='dcl-number-list'>
                        <div className='dcl-number'>
                          <p className='strong'>
                            1.
                          </p>
                        </div>
                        <p>
                          <span className='strong'>
                            <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.text-4'/>
                          </span>
                          <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.text-5'/>
                        </p>
                      </div>
                      <div className='dcl-number-list'>
                        <div className='dcl-number'>
                          <p className='strong'>
                            2.
                          </p>
                        </div>
                        <p>
                        <span className='strong'>
                          <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.text-6'/>
                        </span>
                        <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.text-7'/>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='dcl-col-2'>
                    <h3 className='round-robin-dcl-subtitle'>
                      <FormattedMessage id='app.round-robin.pick-a-time.dcl.description'/>
                    </h3>
                    <p>
                      <FormattedMessage id='app.round-robin.pick-a-time.dcl.accepted.text-8'/>
                    </p>
                    {props.calendlyWidget}
                </div>
              </div>
            </div>
        </div>
    )

}