import React from 'react'

export const NavComponentTitles = {
  'ActionPlanTakeActionToday': 'app.action-plan-nav.take-action-today',
  'ActionPlanDebtSummary': 'app.action-plan-nav.debt-summary',
  'ActionPlanFicoScore': 'app.action-plan-nav.fico-score',
  'ActionPlanSessions': 'app.action-plan-nav.sessions',
  'ActionPlanBudget': 'app.action-plan-nav.budget',
  'ActionPlanMessages': 'app.action-plan-nav.messages'
}

export default NavComponentTitles
