import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'

import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import {verifyUser} from '../actions/login-actions'
import NotificationForm from '../components/notification-form'

function mapStateToProps(state) {
  return {
    login: state.login,
    registration: state.registration,
    formSyncErrors: getFormSyncErrors('login')(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

function goToNextPage(props) {
  props.history.push(`/temporary-code`);
}

const NotificationFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
  form: 'NotificationForm',
  onSubmit: (values, dispatch, props) => {
    return dispatch(verifyUser(props.registration.email)).then(() => {
      goToNextPage(props)
    })
  },
  destroyOnUnmount: false
})(injectIntl(NotificationForm))
)

export default NotificationFormContainer

