import React from 'react'

export const TPCLogo = ({
                         style = {},
                         fill = '#fff',
                         width = '100%',
                         className = '',
                         height = '100%',
                         viewBox = '0 0 369.29 135.95',
                       }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon className="tpc-logo cls-1" points="64.55 48.53 64.55 91.09 53.28 91.09 53.28 48.53 37.69 48.53 37.69 37.69 80.14 37.69 80.14 48.53 64.55 48.53"/>
        <path className="tpc-logo cls-1" d="M247.61,43.26h-13v23.5h12.65c8.49,0,14.05-4.73,14.05-11.83,0-7.7-5.48-11.67-13.75-11.67m-.51,29H234.66V91.09h-5.79V37.69h19.18c11.56,0,19.17,6.41,19.17,17,0,11.6-9.22,17.55-20.12,17.55"/>
        <path className="tpc-logo cls-1" d="M107,61.57c-7.39,0-11.92,4.65-11.92,14.42v15.1H83.93V50.2H95.06v8.25c2.26-5.65,5.92-8.55,12.51-8.25V61.57Z"/>
        <path className="tpc-logo cls-1" d="M139.76,91.09v-5.8a13.16,13.16,0,0,1-11.48,5.8c-8.42,0-13.32-5-13.32-14.41V50.2h11.12V73c0,5.49,2.49,8.32,6.73,8.32s6.95-2.83,6.95-8.32V50.2h11.13V91.09Z"/>
        <path className="tpc-logo cls-1" d="M175.32,91.09c-5.48,0-11.63-1.14-16.83-5.41l4.76-7.63c4.25,3.2,8.71,4.76,12.37,4.76,3.22,0,4.68-1.1,4.68-2.93v-.15c0-2.52-3.8-3.36-8.12-4.73-5.49-1.68-11.71-4.35-11.71-12.29v-.15c0-8.32,6.44-12.36,14.34-12.36,5,0,10.4,1.15,14.64,4.12l-4.24,8c-3.88-2.36-7.76-3.81-10.62-3.81-2.7,0-4.09,1.22-4.09,2.82v.15c0,2.29,3.73,3.36,8,4.89C184,68.28,190.33,71,190.33,78.5v.16c0,9.08-6.51,12.43-15,12.43"/>
        <path className="tpc-logo cls-1" d="M208.75,91.09c-6.8,0-11.42-2.14-11.42-11.59V37.69h11.13V50.2h9.22v9.92h-9.22V77.59c0,2.67,1.1,4,3.59,4a11.07,11.07,0,0,0,5.49-1.45v9.3c-2.34,1.45-5.06,1.68-8.79,1.68"/>
        <path className="tpc-logo cls-1" d="M324.37,50.2h5.56V91.09h-5.56V84.22c-2.57,4.28-6.44,6.87-13,6.87-9.15,0-14.49-5.57-14.49-15V50.2h5.64V74.69c0,7.33,3.8,11,10.46,11,6.44,0,11.35-4.06,11.35-11.45Z"/>
        <path className="tpc-logo cls-1" d="M355.83,91.09c-5.42,0-11.42-1.45-15.66-5l2.85-4.2a20.64,20.64,0,0,0,13.1,4.34c4.54,0,7.83-1.74,7.83-5.56v-.15c0-4-4.46-5.49-9.44-6.94C348.58,71.79,342,69.66,342,62.41v-.15c0-6.79,5.41-12.06,12.88-12.06a25.43,25.43,0,0,1,13.61,4.61l-2.56,4.49c-3.52-2.36-7.54-4.49-11.2-4.49a7.14,7.14,0,0,0-7.32,6.84v.15c0,3.74,4.68,5.19,9.74,6.79,5.85,1.83,12.14,4.19,12.14,11.29V80c0,7.48-5.92,11.06-13.46,11.06"/>
        <rect className="tpc-logo cls-1" x="278.04" y="37.69" width="5.63" height="53.4"/>
        <path className="tpc-logo cls-2" d="M37.69,0h0A26.86,26.86,0,0,1,64.55,26.86v0a0,0,0,0,1,0,0H37.69a0,0,0,0,1,0,0V0A0,0,0,0,1,37.69,0Z"/>
        <path className="tpc-logo cls-2" d="M26.86,0h0a0,0,0,0,1,0,0V26.86a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A26.86,26.86,0,0,1,26.86,0Z"/>
        <polygon className="tpc-logo cls-2" points="0 37.69 26.86 37.69 26.86 64.55 0 37.69"/>
        <path className="tpc-logo cls-2" d="M228.87,119.14v-.09a16.62,16.62,0,0,1,17-16.9c6.16,0,9.85,2,12.88,5l-4.57,5.27c-2.52-2.28-5.09-3.68-8.36-3.68-5.51,0-9.48,4.57-9.48,10.17v.1c0,5.6,3.88,10.27,9.48,10.27,3.73,0,6-1.49,8.59-3.83l4.58,4.62a16.71,16.71,0,0,1-13.4,5.84A16.5,16.5,0,0,1,228.87,119.14Z"/>
        <path className="tpc-logo cls-2" d="M264,110.36h7.1v5c1.45-3.46,3.78-5.7,8-5.51v7.42h-.37c-4.71,0-7.61,2.85-7.61,8.82v9.25H264Z"/>
        <path className="tpc-logo cls-2" d="M281.64,123v-.1c0-7.14,5.09-13,12.37-13,8.36,0,12.19,6.49,12.19,13.58,0,.56-.05,1.22-.1,1.87H288.69a5.8,5.8,0,0,0,6.11,4.9,8.2,8.2,0,0,0,6.07-2.56l4.07,3.59A12.52,12.52,0,0,1,294.71,136C287.19,136,281.64,130.67,281.64,123Zm17.65-2.1c-.42-3.18-2.29-5.33-5.28-5.33s-4.86,2.1-5.42,5.33Z"/>
        <path className="tpc-logo cls-2" d="M310.35,122.92v-.09c0-8.36,5.47-12.93,11.4-12.93a9.59,9.59,0,0,1,7.79,3.73V101.31h7.1v34.08h-7.1v-3.6a9.17,9.17,0,0,1-7.79,4.07C315.91,135.86,310.35,131.28,310.35,122.92Zm19.29,0v-.09c0-4.16-2.76-6.91-6.07-6.91s-6.12,2.71-6.12,6.91v.09c0,4.16,2.8,6.91,6.12,6.91S329.64,127.08,329.64,122.92Z"/>
        <path className="tpc-logo cls-2" d="M343.13,101.31h7.47v6.3h-7.47Zm.19,9h7.1v25h-7.1Z"/>
        <path className="tpc-logo cls-2" d="M356.31,110.36V104h7.1v6.39h5.88v6.07h-5.88v10.7c0,1.63.7,2.42,2.29,2.42a7.24,7.24,0,0,0,3.5-.88v5.69a10.42,10.42,0,0,1-5.6,1.45c-4.35,0-7.29-1.73-7.29-7.52V116.43"/>
      </g>
    </g>
  </svg>

export default TPCLogo