import React from 'react'
import FormattedMessage from './FormattedMessage'

function getDayofWeek(s) {
    return 'app.shift-table.dayname.' + s;
}

function getMonth(s) {
    return 'app.shift-table.monthname.' + s;
}

export const CounselorSessionBox = ({wrapper, apptDetail, renderFromDashboard}) => (
    <div>
        <div className='individual-session-wrapper'>
            <div className={wrapper}>
                <div className='counselor-picture-sessions'>
                    {
                        apptDetail.activeCounselor ?
                            <img src={`/api/counselor/${apptDetail.activeCounselor.userId}/image`} className='img-circle'/>
                            :
                            (
                                apptDetail.apptStatus !== "Attended" && apptDetail.activeCounselor !== null ?
                                    <img src={`/api/counselor/${apptDetail.activeCounselor.userId}/image`} className='img-circle'/>
                                    :
                                    <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/placeholder-logo.png`} className='img-circle' />
                            )
                    }
                </div>
                <div className='counselor-info-sessions'>
                    <div className='counselor-name-sessions'>
                        {
                            apptDetail.activeCounselor !== null ?
                                <div>{apptDetail.activeCounselor.firstName} {apptDetail.activeCounselor.lastName}</div> : null

                        }
                    </div>
                    {
                        apptDetail.activeCounselor ?
                            <div>
                                <div className='time-sessions'>{apptDetail.apptTime} {apptDetail.apptTimeZone != null ? apptDetail.apptTimeZone[0] : ''}T
                                    <FormattedMessage id='app.action-plan-sessions.on'/>
                                </div>
                                <div className='time-sessions'>
                                    <FormattedMessage id={getDayofWeek(apptDetail.apptDayofWeekShort)}/>, <FormattedMessage id={getMonth(apptDetail.apptMonthLong)}/> {apptDetail.apptDayofMonth}, {apptDetail.apptYear}
                                </div>
                                {
                                    apptDetail.calendlyEventId != null && apptDetail.rescheduleUrl != null && apptDetail.apptType != "Previous" && apptDetail.apptStatus == "Booked" ?
                                      <div className='time-sessions'>
                                          <a href={apptDetail.rescheduleUrl} target="_blank">Reschedule this session</a>
                                      </div>: null
                                }
                            </div>
                            :null
                    }
                    {
                        apptDetail.activeCounselor === null && apptDetail.apptStatus === "Attended" ?
                            <div>
                                <div className='time-sessions'>{apptDetail.apptTime} {apptDetail.apptTimeZone != null ? apptDetail.apptTimeZone[0] : ''}T
                                    <FormattedMessage id='app.action-plan-sessions.on'/>
                                </div>
                                <div className='time-sessions'>
                                    <FormattedMessage id={getDayofWeek(apptDetail.apptDayofWeekShort)}/>, <FormattedMessage id={getMonth(apptDetail.apptMonthLong)}/> {apptDetail.apptDayofMonth}, {apptDetail.apptYear}
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>
        {renderFromDashboard ? '' : <div className='action-plan-divide multiple-session-divide'></div>}
    </div>
)

export default CounselorSessionBox
