import {CALENDLY_ROUND_ROBIN_URL_SPANISH} from '../actions/calendly-actions'

export default function calendlyRoundRobinUrlSpanish(state = null, action) {
  switch (action.type) {
    case CALENDLY_ROUND_ROBIN_URL_SPANISH:
      return action.calendlyRoundRobinUrlSpanish
    default:
      return state
  }
}
