const parseParams = (qs) => {
  let obj = {}

  let ary = qs.split("&")

  for (let part of ary) {
    let a = part.split("=")
    obj[a[0]] = a[1]
  }

  return obj;
}

const trim = (str, ch) => {
  let start = 0,
    end = str.length;

  while(start < end && str[start] === ch)
    ++start;

  while(end > start && str[end - 1] === ch)
    --end;

  return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}

export default class QueryStringService {

  static getValue(queryString, key) {

    if (!queryString) {
      return null
    }

    let params = parseParams(trim(queryString, "?"))

    return params[key]

  }
}