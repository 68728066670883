import React from 'react'
import Field from './helpers/Field'
import FormattedMessage from './helpers/FormattedMessage'
import FormUserError from './form-user-error'
import FormError from './form-error'

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
    props.submitting ||
    !props.valid ||
    state.isProcessing;
}

const renderField = ({input, autocomplete, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type} autocomplete={autocomplete}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default class ChangePassForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isProcessing: false,
            fatalFormError: null
        }
    }

    componentDidUpdate(prevProps, nextProps, state) {
        if (this.props.error !== prevProps.error && this.props.error !== null) {
            this.props.updateLoadingIcon(false)

            let err = this.props.error
            this.setState({
                fatalFormError: err,
                isProcessing: false
            })

            this.props.verifyToken()
        }
    }

    editAction() {
        this.props.setMyAccountComponent("ChangePassword")
    }

    cancelAction() {
        this.props.setMyAccountComponent("Account")
    }

    render() {

        const {intl, formSyncErrors}=this.props;

        return (
            <div className='my-account-wrapper padding-bottom-16-px'>
                {
                    (this.props.currentMyAccountComponent === 'ChangePassword' ? (<div className='my-account-active-wrapper'>
                        <div className='whitespace-8px-top'></div>
                        <div className='my-account-active my-account-active-middle'>
                        {/*<form onSubmit={e => {e.preventDefault();}}>*/}
                        <form onSubmit={this.props.handleSubmit}>
                          <div className='my-account-reg-view-field margin-bottom-8-px'>
                                <FormattedMessage id='app.action-plan-account.change-password.page-title'/>
                            </div>
                          <div className='my-account-personal-detail margin-bottom-24-px'>
                            <div className='step-bold'>
                              <FormattedMessage id='app.action-plan-account.change-password.step-one'/>
                            </div>
                                <FormattedMessage id='app.action-plan-account.change-password.instruction1'/>
                            </div>

                            <div className='my-account-input-field margin-bottom-24-px'>
                                <Field name='password' type='password' id='password' 
                                    autocomplete='password' component={renderField}/>
                            </div>

                            <div>
                                <FormUserError validationErrors={formSyncErrors} intl={intl}/>
                                <FormError error={this.state.fatalFormError}/>
                            </div>

                          <div className='my-account-personal-detail margin-bottom-24-px'>
                            <div className='step-bold'>
                            <FormattedMessage id='app.action-plan-account.change-password.step-two'/>
                            </div>
                            <div className='my-account-personal-detail margin-bottom-24-px my-account-display-inline'>
                                <FormattedMessage id='app.action-plan-account.change-password.instruction2'/>
                                <li><FormattedMessage id='app.create-pass.rules1'/></li>
                                <li><FormattedMessage id='app.create-pass.rules2'/></li>
                                <li><FormattedMessage id='app.create-pass.rules3'/></li>
                                <li><FormattedMessage id='app.create-pass.rules4'/></li>
                                <li><FormattedMessage id='app.create-pass.rules5'/></li>
                            </div>
                          </div>

                            <div className='my-account-input-field margin-bottom-24-px'>
                                <Field name='newpassword' type='password' id='newpassword' 
                                    autocomplete='newpassword' component={renderField}/>
                            </div>
                          <div className='my-account-personal-detail margin-bottom-24-px'>
                            <div className='step-bold'>
                              <FormattedMessage id='app.action-plan-account.change-password.step-three'/>
                            </div>
                            <FormattedMessage id='app.action-plan-account.change-password.reenter-new-password'/>
                          </div>

                            <div className='my-account-input-field margin-bottom-24-px'>
                                <Field name='confirmpassword' type='password' id='confirmpassword'
                                       autocomplete='confirmpassword' component={renderField}/>
                            </div>

                            <div className='submit-button-round margin-bottom-24-px'>
                                <button id='cancel'
                                        type={'button'}
                                        className='btn btn-default btn-lg my-account-btn-active my-account-btn-cancel'
                                        onClick={() => this.cancelAction()}>
                                    <FormattedMessage id='app.common.cancel.button'/>
                                </button>
                                <button id='submit'
                                        type={'submit'}
                                        className='btn btn-primary btn-lg my-account-btn-active my-account-btn-save'
                                        disabled={isSubmitDisabled(this.props, this.state)}>
                                    <FormattedMessage id='app.common.save.button'/>
                                </button>
                            </div>
                         </form>
                        </div>
                    </div>)
                        :
                    (<div>
                      <div className='my-account-reg-view-field margin-bottom-8-px'>
                            <FormattedMessage id='app.action-plan-account.change-password.page-title'/>
                      </div>
                      <div className='submit-button-round my-account-edit-button'>
                                <button id='edit-password'
                                        type={'button'}
                                        className='btn btn-default btn-lg my-account-btn'
                                        onClick={() => this.editAction()}>
                                    <FormattedMessage id='app.common.edit.button'/>
                                </button>

                        </div>
                      <div className='my-account-personal-detail margin-bottom-8-px'>
                            ********
                       </div>
                    </div>))
                }
                <div className='action-plan-divide my-account-divide'></div>
            </div>
        )
    }
}
