import React from 'react'

export const TPCPieChartActive = ({
                              style = {},
                              fill = '#fff',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 293 147',
                            }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g id="Layer_2_2_">
      <g id="Layer_1-2_1_">
        <path className="tpc-pie-chart st0 position-1" d="M146,146L4.3,145.8c-0.5-30,9-59.2,26.9-83.2L146,146z"/>
        <path className="tpc-pie-chart st1 position-5" d="M146,146l114.5-82.9c18,24,27.6,53.3,27.2,83.2L146,146z"/>
        <path className="tpc-pie-chart st2 position-4" d="M146,146l43.5-134.5c28.7,8.9,53.6,27,71,51.6L146,146z"/>
        <path className="tpc-pie-chart st3 position-3" d="M146,146L101.9,11.3c28.4-9.6,59.2-9.6,87.6,0.2L146,146z"/>
        <path className="tpc-pie-chart st4 position-2" d="M146,146L31.2,62.6c17.2-24.4,42.1-42.5,70.8-51.3L146,146z"/>
      </g>
    </g>
    <path className="tpc-pie-chart-selector st5" d="M46,146C46,90.8,90.8,46,146,46s100,44.8,100,100H46z"/>
    <path className="tpc-pie-chart-selector st6 selector-position-3" d="M150,43.1l-4.2-4.2l-4.2,4.2c-8.9,0.4-17.6,1.9-26,4.5l1.8,5.7c9.2-2.8,18.9-4.3,28.6-4.3
	c9.6,0,19.1,1.4,28.3,4.2l1.7-5.7C167.6,44.9,158.9,43.4,150,43.1z"/>
    <path className="tpc-pie-chart-selector st6 selector-position-2" d="M112.3,48.7c-8.6,3-16.7,7-24.1,12.1l-5.3-0.8l-0.9,5.4c-6.8,5.4-13,11.7-18.3,18.8l4.8,3.6
	c11.7-15.5,27.5-27,45.8-33.3L112.3,48.7z"/>
    <path className="tpc-pie-chart-selector st6 selector-position-1 " d="M61.7,86.9c-5.1,7.3-9.3,15.2-12.3,23.5l-0.1-0.1l-5.3,2.7l2.7,5.3l0.1-0.1c-2.3,8.3-3.6,17-3.8,25.7l6,0.1
	c0.4-19.4,6.4-38,17.5-53.8L61.7,86.9z"/>
    <path className="tpc-pie-chart-selector st6 selector-position-4 " d="M210.7,65.8l-0.9-5.7l-5.5,0.9c-7.6-5.2-16-9.4-24.8-12.5l-1.9,5.7c18.4,6.3,34.4,17.9,46.1,33.5l4.8-3.6
	C223.2,77.3,217.2,71.1,210.7,65.8z"/>
    <path className="tpc-pie-chart-selector st6 selector-position-5" d="M249,144.5c-0.1-9-1.4-17.8-3.8-26.3l0.5,0.3l2.7-5.3l-5.3-2.7l-0.2,0.5c-3-8.4-7.2-16.4-12.4-23.8l-4.9,3.4
	c11.1,15.9,17.2,34.6,17.5,54.1L249,144.5z"/>
  </svg>

export default TPCPieChartActive