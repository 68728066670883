import React from 'react'

export const Contracts = ({
                            style = {},
                            fill = '#000000',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 20 26',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M19.658 22.747V6.61c0-.618-.247-1.211-.686-1.647L14.895.885A2.333 2.333 0 0 0 13.248.2H3.33A2.333 2.333 0 0 0 .998 2.532v20.215A2.333 2.333 0 0 0 3.33 25.08h13.996a2.333 2.333 0 0 0 2.332-2.332zm-4.665-10.496v1.361a.585.585 0 0 1-.583.583H6.246a.585.585 0 0 1-.583-.583v-1.36a.585.585 0 0 1 .583-.584h8.163c.322 0 .583.261.584.583zm-.583 3.5H6.246a.585.585 0 0 0-.583.583v1.361a.585.585 0 0 0 .583.583h8.163a.585.585 0 0 0 .583-.583v-1.362a.585.585 0 0 0-.583-.583l.001.001zm-.972-13.029v3.7h3.7l-3.7-3.7zm3.887 6.03v13.995H3.33V2.532h7.775v5.054a1.163 1.163 0 0 0 1.166 1.166h5.054z" fill="#000"/>

  </svg>;

export default Contracts