import {CLEAR_REDUX_STORE} from '../actions/clear-redux-store-actions'

export default function clearReduxStore(state = null, action) {
  switch (action.type) {
    case CLEAR_REDUX_STORE:
      return state
    default:
      return state
  }
}
