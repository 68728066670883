import axios from 'axios'
import 'moment-timezone'
export const SUBMIT_REGISTRATION = 'SUBMIT_REGISTRATION'
export const GET_BOOKED_APPOINTMENTS = 'GET_BOOKED_APPOINTMENTS'
export const GET_COMPLETED_ATTENDED_APPOINTMENTS = 'GET_COMPLETED_ATTENDED_APPOINTMENTS'
export const CURRENT_CALENDLY_CONFIRMATION_PATH = 'CURRENT_CALENDLY_CONFIRMATION_PATH'
export const CALENDLY_ROUND_ROBIN_URL = 'CALENDLY_ROUND_ROBIN_URL'
export const CALENDLY_ROUND_ROBIN_URL_SPANISH = 'CALENDLY_ROUND_ROBIN_URL_SPANISH'

const localeToPreferredLanguage = {
    'en-US': 'English',
    'es-US': 'Spanish'
}

// as of 2023 Hawaii no longer observes DST and uses HST all year

const timezoneConversion = {
    'est': 'Eastern Standard Time',
    'cst': 'Central Standard Time',
    'mst': 'Mountain Standard Time',
    'pst': 'Pacific Standard Time',
    'ast': 'Alaska Standard Time',
    'hst': 'Hawaii-Aleutian Standard Time',
    'edt': 'Eastern Daylight Time',
    'cdt': 'Central Daylight Time',
    'mdt': 'Mountain Daylight Time',
    'pdt': 'Pacific Daylight Time',
    'adt': 'Alaska Daylight Time',
    'hdt': 'Hawaii-Aleutian Standard Time',
    'gmt': 'Greenwich Mean Time',
    'bst': 'British Summer Time'
}

export function getCalendlyRoundRobinUrl() {
  return (dispatch, getState) => {

    return axios
      .get('/api/calendly/round-robin-url')
      .then(response => {
        dispatch({
          type: CALENDLY_ROUND_ROBIN_URL,
          calendlyRoundRobinUrl: response.data
        })
      })
      .catch(() => {})
  }
}

export function getCalendlyRoundRobinUrlSpanish() {
  return (dispatch, getState) => {

    return axios
      .get('/api/calendly/round-robin-url-spanish')
      .then(response => {
        dispatch({
          type: CALENDLY_ROUND_ROBIN_URL_SPANISH,
          calendlyRoundRobinUrlSpanish: response.data
        })
      })
      .catch(() => {})
  }
}


export function setCurrentCalendlyConfirmationPath(path) {
  let tmpPath = path
  return {
    type: CURRENT_CALENDLY_CONFIRMATION_PATH,
    currentCalendlyConfirmationPath: tmpPath
  }
}

export function getBookedAppointments() {
    return (dispatch, getState) => {
      let contactId = getState().login.contactId
      let email = getState().registration.email
      let phone = getState().registration.mobilePhone

      return axios
        .get('/api/booked-appointments-from-contact', {params: {contactId, email, phone}})
        .then(response => {
            dispatch({
                type: GET_BOOKED_APPOINTMENTS,
                bookedAppointments: response.data
            })
        })
        .catch(() => {})
    }
}

export function getCompletedAttendedAppointments() {
  return (dispatch, getState) => {
    let contactId = getState().login.contactId
    let email = getState().registration.email
    let phone = getState().registration.mobilePhone

    return axios
      .get('/api/completed-attended-appointments-from-contact', {params: {contactId, email, phone}})
      .then(response => {
        dispatch({
          type: GET_COMPLETED_ATTENDED_APPOINTMENTS,
          completedAttendedAppointments: response.data
        })
      })
      .catch(() => {})
  }
}



export function calendlyEventRoundRobin(eventUri, inviteeUri) {
  return (dispatch, getState) => {

    const date = new Date();
    const dateAsString = date.toString();
    const zoneName = dateAsString.match(/\(([^\)]+)\)$/)[1];

    let preferredLanguage = localeToPreferredLanguage[getState().locale];
    let employerId = getState().employer && getState().employer.id ? getState().employer.id : "";
    let employerCode = getState().employer ? getState().employer.code : '0000';
    let contactId = getState().login ? getState().login.contactId : "";
    let dclCriteria = getState().dcl && getState().dcl.onboardingProductStatus ? getState().dcl.onboardingProductStatus : "";

      return axios
      .get('/api/create-calendly-event-round-robin', {params: {eventUri, inviteeUri, preferredLanguage, employerId, employerCode, zoneName, contactId, dclCriteria}})
      .then(response => {
        dispatch({
          type: SUBMIT_REGISTRATION,
          registration: response.data
        })
      })
      .catch(() => {})
  }
}

export function calendlyEventRoundRobinSaml(eventUri, inviteeUri) {
    return (dispatch, getState) => {

        const date = new Date();
        const dateAsString = date.toString();
        const zoneName = dateAsString.match(/\(([^\)]+)\)$/)[1];

        let preferredLanguage = localeToPreferredLanguage[getState().locale];
        let employerId = getState().employer && getState().employer.id ? getState().employer.id : "";
        let employerCode = getState().employer ? getState().employer.code : '0000';
        let contactId = getState().registration.id;

        return axios
            .get('/api/create-calendly-event-round-robin-saml', {params: {eventUri, inviteeUri, preferredLanguage, employerId, employerCode, zoneName, contactId}})
            .then(response => {
                dispatch({
                    type: SUBMIT_REGISTRATION,
                    registration: response.data
                })
            })
            .catch(() => {})
    }
}

// This is from action plan
export function calendlyEvent(eventUri, inviteeUri) {
    return (dispatch, getState) => {

      const date = new Date();
      const dateAsString = date.toString();
      const zoneName = dateAsString.match(/\(([^\)]+)\)$/)[1];

        let activeCounselorContactId = getState().activeCounselor.contactId;
        let contactId = getState().login.contactId;

        let preferredLanguage = localeToPreferredLanguage[getState().locale];
        let employerId = getState().employer && getState().employer.id ? getState().employer.id : "";
        let employerCode = getState().employer ? getState().employer.code : '0000';

        return axios
          .get('/api/create-calendly-event', {params: {eventUri, inviteeUri, activeCounselorContactId, preferredLanguage, employerId, employerCode, zoneName, contactId}})
          .then(response => {
              dispatch({
                  type: SUBMIT_REGISTRATION,
                  registration: response.data
              })
          })
          .catch(() => {})
    }
}

// Calendly event creation specific action for follow up appointments
export function calendlyFollowUpEvent(eventUri, inviteeUri) {
    return (dispatch, getState) => {
        console.log("calendly-actions.js -> calendlyFollowUpEvent method")
        console.log(getState().client.preferredLanguage)

        let activeCounselorContactId = getState().activeCounselor.contactId;
        let clientId = getState().client.id;

        let preferredLanguage = getState().client.preferredLanguage;

        let zoneName = ''
        if (getState().timezone["clientTimezone"] !== "" && getState().timezone["clientTimezone"] !== undefined) {
            zoneName = timezoneConversion[getState().timezone["clientTimezone"]]
        } else {
          const date = new Date();
          const dateAsString = date.toString();
          zoneName = dateAsString.match(/\(([^\)]+)\)$/)[1];
        }

        return axios
            .get('/api/create-calendly-follow-up-event', {params: {eventUri, inviteeUri, activeCounselorContactId, preferredLanguage, zoneName, clientId}})
            .then(response => {
                dispatch({
                    type: SUBMIT_REGISTRATION,
                    registration: response.data
                })
            })
            .catch(() => {})
    }
}