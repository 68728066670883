import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from "../containers/footer-container-ap";
import Field from "./helpers/Field";


const isSubmitDisabled = (props, state) => {
    return props.pristine ||
        props.submitting ||
        !props.valid
}

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

export default class StandaloneCreditReportSubmittedPage extends React.Component {

    constructor(props){
        super(props);
    }

    render() {

        return (
            <div>
                <HeaderContainerAP/>

                <div className='container-ap welcome-form'>

                    <div className='page-title'>
                        <FormattedMessage id='app.standalone-credit-report.title'/>
                    </div>
                    <div className='text-ap margin-bottom-32-px'>
                        <FormattedMessage id='app.standalone-credit-report.text'/>
                    </div>
                    <form onSubmit={this.props.handleSubmit}>
                        <div className='form-group margin-bottom-32-px'>
                            <div className='label-text'>
                                <FormattedMessage id='app.welcome.input.email'/>
                            </div>
                            <Field name='username' type='text' id='username' component={renderField} />
                        </div>

                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-primary btn-block btn-lg'
                                    disabled={isSubmitDisabled(this.props, this.state)}>
                                <FormattedMessage id='app.common.continue.button'/>
                            </button>
                        </div>
                    </form>
                </div>
                <FooterContainerAp/>
            </div>)
    }
}
