import axios from 'axios'
export const GET_FICO_SCORE = 'GET_FICO_SCORE'

export function getFicoScore() {
  return (dispatch, getState) => {
    let contactId = getState().registration.id

    return axios
      .get('/api/fico-score', {params: {contactId}})
      .then(response => {
        dispatch({
          type: GET_FICO_SCORE,
          ficoScore: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: GET_FICO_SCORE,
          ficoScore: null
        })
      })
  }
}

