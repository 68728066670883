import React from 'react'
import {useCalendlyEventListener, InlineWidget} from "react-calendly";

function stripPhone(phone) {
  let trimmedPhone = phone.trim();
  let one = trimmedPhone.substring(0, 1);
  let two = trimmedPhone.substring(0, 2);
  if (one === '1') {
    return trimmedPhone.slice(1).trim();
  } else if (two == "+1") {
    return trimmedPhone.slice(2).trim();
  } else {
    return trimmedPhone;
  }
}


function baseCalendlyUrl(locale, counselor, landingPage) {
  let storedUrl = locale === "en-US" ? counselor.englishCalendly : counselor.spanishCalendly
  return landingPage ? storedUrl.substring(0, storedUrl.lastIndexOf("/")) : storedUrl
}


export const CalendlyWidgetAdminHelper = props => {

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => { props.onEventScheduled(e) },
      });

    console.log("CalendlyWidgetAdminHelper -> " + JSON.stringify(props.client) );
    let a1 = props.client.mobilePhone != null ? stripPhone(props.client.mobilePhone) : ''
    let a3 = props.client.zipCode !=null ? props.client.zipCode : ''
    let a4 = props.client.employerName !=null ? props.client.employerName : ''

    return (
        <InlineWidget
            url={baseCalendlyUrl(props.locale, props.counselor, props.landingPage)}
            prefill={{
              email: props.client.email,
              firstName: props.client.firstName,
              lastName: props.client.lastName ,
              name: props.client.firstName + ' ' + props.client.lastName,
              customAnswers: {
                a1: '1' + a1,
                a3: a3,
                a4: a4
              }
            }}/>
        )
    }

export default CalendlyWidgetAdminHelper