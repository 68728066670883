import React from 'react'

export const TrustEdLogo = ({
                             style = {},
                             fill = '#fff',
                             width = '100%',
                             className = '',
                             height = '100%',
                             viewBox = '0 0 80.14 91.09',
                           }) =>
  <svg
    width="194px"
    height="51px"
    viewBox="0 0 194 51"
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <title>Group 32</title>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="newlanding_w1440" transform="translate(-466.000000, -2475.000000)">
        <g id="Group-32" transform="translate(466.000220, 2475.050910)">
          <polygon id="Fill-1" fill="#002E5A" points="35.4744663 26.7101265 35.4744663 50.1374162 29.2802057 50.1374162 29.2802057 26.7101265 20.7145286 26.7101265 20.7145286 20.7469365 44.0417921 20.7469365 44.0417921 26.7101265"/>
          <g id="Group-31">
            <path d="M58.7943654,33.8880812 C54.7330057,33.8880812 52.2395836,36.4508108 52.2395836,41.824507 L52.2395836,50.138517 L46.1250113,50.138517 L46.1250113,27.6331715 L52.2395836,27.6331715 L52.2395836,32.1685865 C53.4865694,29.0603962 55.496915,27.4647447 59.1180652,27.6326211 L59.1180652,33.8880812 L58.7943654,33.8880812 Z" id="Fill-2" fill="#002E5A"/>
            <path d="M76.8111949,50.1376914 L76.8111949,46.9469389 C75.4020844,48.8365117 73.5939824,50.1387922 70.4976765,50.1387922 C65.8730023,50.1387922 63.176787,47.3669051 63.176787,42.2034673 L63.176787,27.6317955 L69.2908096,27.6317955 L69.2908096,40.1861984 C69.2908096,43.2090745 70.658702,44.7639953 72.9910986,44.7639953 C75.3234952,44.7639953 76.8111949,43.2090745 76.8111949,40.1861984 L76.8111949,27.6317955 L82.9252176,27.6317955 L82.9252176,50.1376914 L76.8111949,50.1376914 Z" id="Fill-4" fill="#002E5A"/>
            <path d="M96.3538096,50.1387372 C93.3377416,50.1387372 89.9595037,49.5079622 87.1028125,47.1565918 L89.7165915,42.9585809 C92.0511864,44.7199071 94.5039399,45.5807552 96.5148351,45.5807552 C98.2844669,45.5807552 99.0884952,44.9736481 99.0884952,43.9663897 L99.0884952,43.8821763 C99.0884952,42.4945815 96.9990108,42.0327838 94.6248465,41.2792664 C91.6065802,40.3540197 88.1887728,38.8866149 88.1887728,34.5185259 L88.1887728,34.4348629 C88.1887728,29.8565156 91.7291354,27.6322909 96.0724272,27.6322909 C98.8071127,27.6322909 101.78471,28.2614146 104.117107,29.9005488 L101.78471,34.3082676 C99.6534584,33.0070878 97.5200079,32.2095373 95.9520703,32.2095373 C94.463821,32.2095373 93.6999116,32.8810429 93.6999116,33.7628069 L93.6999116,33.8464699 C93.6999116,35.1058181 95.7503762,35.6942111 98.0827728,36.5335931 C101.101039,37.5826831 104.599634,39.0946715 104.599634,43.208469 L104.599634,43.2926824 C104.599634,48.2926472 101.020252,50.1387372 96.3538096,50.1387372" id="Fill-6" fill="#002E5A"/>
            <path d="M114.72539,50.1387372 C110.984982,50.1387372 108.449792,48.9608503 108.449792,43.7550306 L108.449792,20.7466063 L114.564364,20.7466063 L114.564364,27.6328413 L119.633095,27.6328413 L119.633095,33.0902004 L114.564364,33.0902004 L114.564364,42.7059406 C114.564364,44.1771984 115.167798,44.891086 116.534591,44.891086 C117.66067,44.891086 118.666393,44.5955134 119.552308,44.0935354 L119.552308,49.2129401 C118.265752,50.011041 116.775854,50.1387372 114.72539,50.1387372" id="Fill-8" fill="#002E5A"/>
            <path d="M35.4749609,14.7827007 L20.7144737,14.7827007 L20.7144737,0.000220165775 C28.8663207,0.000220165775 35.4749609,6.61840336 35.4749609,14.7827007" id="Fill-10" fill="#47BFAF"/>
            <path d="M14.7601575,0 L14.7601575,0 L14.7601575,14.7824805 L0.000219830028,14.7824805 C0.000219830028,6.61818319 6.60831048,0 14.7601575,0" id="Fill-12" fill="#47BFAF"/>
            <polygon id="Fill-14" fill="#47BFAF" points="0 20.7464411 14.7599377 20.7464411 14.7599377 35.5294721"/>
            <polygon id="Fill-28" fill="#002E5A" points="148.590316 21.9364922 168.630021 21.9364922 168.630021 24.7920423 151.719046 24.7920423 151.719046 34.2712797 166.847199 34.2712797 166.847199 37.1273802 151.719046 37.1273802 151.719046 46.8454975 168.827318 46.8454975 168.827318 49.7004972 148.590316 49.7004972"/>
            <path d="M190.765916,39.4680176 L190.765916,39.3876571 C190.765916,34.6684037 187.162352,31.4952645 183.360392,31.4952645 C179.399604,31.4952645 176.152165,34.4306247 176.152165,39.3876571 L176.152165,39.4680176 C176.152165,44.3067109 179.518312,47.4000401 183.360392,47.4000401 C187.162352,47.4000401 190.765916,44.187271 190.765916,39.4680176 M173.023984,39.5070971 L173.023984,39.4283878 C173.023984,32.7248904 177.894867,28.7580535 182.80587,28.7580535 C186.607831,28.7580535 189.062782,30.8210068 190.646658,33.1217392 L190.646658,20.746221 L193.69625,20.746221 L193.69625,49.7007724 L190.646658,49.7007724 L190.646658,45.5759666 C188.984193,48.0341175 186.567712,50.1372511 182.80587,50.1372511 C177.894867,50.1372511 173.023984,46.2496739 173.023984,39.5070971" id="Fill-29" fill="#002E5A"/>
            <polygon id="Fill-30" fill="#030100" points="131.448794 40.7666654 122.047763 40.7666654 122.047763 37.4911491 131.448794 37.4911491 131.448794 28.1203433 134.855061 28.1203433 134.855061 37.4911491 144.257191 37.4911491 144.257191 40.7666654 134.855061 40.7666654 134.855061 50.1369208 131.448794 50.1369208"/>
          </g>
        </g>
      </g>
    </g>
  </svg>

export default TrustEdLogo