import React from 'react'
import Icon from "../../resources/icons";
import FormattedMessage from './helpers/FormattedMessage'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

export default class DebtSummaryDebtCategoryRow extends React.Component {

  constructor(props) {
    super(props);
  }

  plusSign() {
    return (
      <div className='icon-legend plus-icon-legend'>
        <FontAwesomeIcon icon={['fal', 'plus-circle']} />
        <div className='more-less-link'>
          <FormattedMessage id='app.action-plan-debt-summary.more.link'/>
        </div>
      </div>
      );
    }

   minusSign() {
     return (
       <div className='icon-legend plus-icon-legend'>
         <FontAwesomeIcon icon={['fal', 'minus-circle']} />
         <div className='more-less-link'>
           <FormattedMessage id='app.action-plan-debt-summary.less.link'/>
         </div>
       </div>
      );
   }

   //needs to handle the correct category and happen only on - signs
   //at the moment this fires on any click on a category's +/- sign
   clearDisplayedTradeAccountRow() {
     this.props.setAccountDetail(this.props.accountDetail)
   }

  onRowClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.props.toggleCategoriesExpanded(this.props.id)
    this.clearDisplayedTradeAccountRow();
  }

  render() {

    return (
        <tr id={this.props.id} key={this.props.id} className='main-category-legend main-category-legend-expanded'>
          <td className='key-column'>
            <div className={`legend-table-circle ${this.props.color}`} >
            </div>
          </td>
          <td className='main-category-name'>
            <FormattedMessage id={`app.debt-report.total-debt-chart.category.${this.props.id}`}/>
          </td>
          <td className='balance'>
            { this.props.totalBalance > 0 ? formatter.format(this.props.totalBalance) : '$0'}
          </td>
          <td className='monthly-payment'>
            {this.props.totalMonthlyPayment > 0 ? formatter.format(this.props.totalMonthlyPayment) : ''}
          </td>
          <td className='plus-minus-column'>
            <div className='icon-wrapper-legend plus-minus-icon-wrapper'>
              { this.props.categoryToggleVisible === true ?
                <div className='icon-legend'>
                  <a href='#' onClick={ e => this.onRowClick(e) }>
                    {this.props.categoryExpanded ? this.minusSign() : this.plusSign()}
                  </a>
                </div>
                : <div/>
              }

            </div>
          </td>
        </tr>
          )
      }
    }
