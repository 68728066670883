import React from 'react'

export const Clipboard = ({
                           style = {},
                           fill = '#000000',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 18 24',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TAT_1200+" transform="translate(-68.000000, -202.000000)" fill="#000000" fillRule="nonzero">
        <g id="clipboard-check" transform="translate(68.000000, 202.000000)">
          <path d="M15.75,3 L12,3 C12,1.3453125 10.6546875,0 9,0 C7.3453125,0 6,1.3453125 6,3 L2.25,3 C1.0078125,3 0,4.0078125 0,5.25 L0,21.75 C0,22.9921875 1.0078125,24 2.25,24 L15.75,24 C16.9921875,24 18,22.9921875 18,21.75 L18,5.25 C18,4.0078125 16.9921875,3 15.75,3 Z M9,1.875 C9.6234375,1.875 10.125,2.3765625 10.125,3 C10.125,3.6234375 9.6234375,4.125 9,4.125 C8.3765625,4.125 7.875,3.6234375 7.875,3 C7.875,2.3765625 8.3765625,1.875 9,1.875 Z M14.68125,12.740625 L7.978125,19.3875 C7.7578125,19.6078125 7.4015625,19.603125 7.18125,19.3828125 L3.309375,15.478125 C3.0890625,15.2578125 3.09375,14.9015625 3.3140625,14.68125 L4.6453125,13.359375 C4.865625,13.1390625 5.221875,13.14375 5.4421875,13.3640625 L7.5984375,15.5390625 L12.5671875,10.6078125 C12.7875,10.3875 13.14375,10.3921875 13.3640625,10.6125 L14.6859375,11.94375 C14.90625,12.16875 14.9015625,12.5203125 14.68125,12.740625 Z" id="Shape" fill={fill}></path>
        </g>
      </g>
    </g>

  </svg>;

export default Clipboard