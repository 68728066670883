import React from 'react'
import FormUserError from './form-user-error'
import FormError from './form-error'
import NormalizationService from '../services/normalization-service'
import Field from './helpers/Field'
import FormattedMessage from './helpers/FormattedMessage'

const renderField = ({input, type, placeholder, normalize, props, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
      className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')} />
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

const renderSelect = ({input, type, id, name, normalize, props, meta: {touched, error}}) => (
  <div>
    <select {...input} name={name} type={type} id={id} className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}>
      <option value=''></option>
      <option value='AL'>Alabama</option>
      <option value='AK'>Alaska</option>
      <option value='AZ'>Arizona</option>
      <option value='AR'>Arkansas</option>
      <option value='CA'>California</option>
      <option value='CO'>Colorado</option>
      <option value='CT'>Connecticut</option>
      <option value='DE'>Delaware</option>
      <option value='DC'>District of Columbia</option>
      <option value='FL'>Florida</option>
      <option value='GA'>Georgia</option>
      <option value='HI'>Hawaii</option>
      <option value='ID'>Idaho</option>
      <option value='IL'>Illinois</option>
      <option value='IN'>Indiana</option>
      <option value='IA'>Iowa</option>
      <option value='KS'>Kansas</option>
      <option value='KY'>Kentucky</option>
      <option value='LA'>Louisiana</option>
      <option value='ME'>Maine</option>
      <option value='MD'>Maryland</option>
      <option value='MA'>Massachusetts</option>
      <option value='MI'>Michigan</option>
      <option value='MN'>Minnesota</option>
      <option value='MS'>Mississippi</option>
      <option value='MO'>Missouri</option>
      <option value='MT'>Montana</option>
      <option value='NE'>Nebraska</option>
      <option value='NV'>Nevada</option>
      <option value='NH'>New Hampshire</option>
      <option value='NJ'>New Jersey</option>
      <option value='NM'>New Mexico</option>
      <option value='NY'>New York</option>
      <option value='NC'>North Carolina</option>
      <option value='ND'>North Dakota</option>
      <option value='OH'>Ohio</option>
      <option value='OK'>Oklahoma</option>
      <option value='OR'>Oregon</option>
      <option value='PA'>Pennsylvania</option>
      <option value='RI'>Rhode Island</option>
      <option value='SC'>South Carolina</option>
      <option value='SD'>South Dakota</option>
      <option value='TN'>Tennessee</option>
      <option value='TX'>Texas</option>
      <option value='UT'>Utah</option>
      <option value='VT'>Vermont</option>
      <option value='VA'>Virginia</option>
      <option value='WA'>Washington</option>
      <option value='WV'>West Virginia</option>
      <option value='WI'>Wisconsin</option>
      <option value='WY'>Wyoming</option>
    </select>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default class CreditReportForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false
    }
  }

  render() {
    const {intl, formSyncErrors}=this.props;

    return (
      <div>
        <div className='address form-group'>

          <div className='form-group'>
            <div className='label-text'>
              <FormattedMessage id='app.credit-report-form.input.street'/>
            </div>
            <Field name='street' type='text'
                   component={renderField}/>
          </div>

          <div className='form-group'>
            <div className='label-text'>
              <FormattedMessage id='app.credit-report-form.input.city'/>
            </div>
            <Field component={renderField} type='text' name='city'/>
          </div>

          <div className='form-group row'>

            <div className='col-xs-6'>
              <div className='label-text'>
                <FormattedMessage id='app.credit-report-form.input.state'/>
              </div>
              <Field component={renderSelect} type='text' name='state' id='state'/>
            </div>

            <div className='col-xs-6'>
              <div className='label-text'>
                <FormattedMessage id='app.credit-report-form.input.zip-code'/>
              </div>
              <Field component={renderField} type='text' name='zipCode'
                     normalize={NormalizationService.zipcode}/>
            </div>

          </div>
        </div>

        <div className='date-of-birth form-group'>
          <div className="label-text">
            <FormattedMessage id='app.credit-report-form.date-of-birth.title'/>
          </div>
          <Field component={renderField}
                 id="date-of-birth"
                 name="birthdate"
                 placeholder={intl.formatMessage({id: 'app.credit-report-form.date-of-birth.placeholder'})}
                 normalize={NormalizationService.date}
          />
        </div>

        <div className='ssn form-group has-feedback-left'>
          <div className='label-text'>
            <FormattedMessage id='app.credit-report-form.input.ssn'/>
          </div>
          <Field component={renderField} type='password' name='ssn'
                 normalize={NormalizationService.ssn}
                 placeholder='XXX-XX-XXX'
          />
          <span className="glyphicon glyphicon-lock form-control-feedback-left"/>
          <div>
            <p className='small-text credit-report'>
              <FormattedMessage id='app.credit-report-form.input.ssn.text'/>
            </p>
            <p className='small-text credit-report-link'>
              <a data-toggle='modal' href='#ssn-modal'>
                <FormattedMessage id='app.credit-report-form.input.ssn.link'/>
              </a>
            </p>
          </div>
        </div>

        <FormUserError validationErrors={formSyncErrors} intl={intl}/>
        <FormError error={this.props.error}/>

        <div className="modal fade" id="ssn-modal" tabIndex="-1" role="dialog" aria-labelledby="ssn-info">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="ssn-info">
                  <FormattedMessage id='app.credit-report-form.ssn-modal.title'/>
                </h4>
              </div>
              <div className="modal-body">
                <p>
                  <FormattedMessage id='app.credit-report-form.ssn-modal.body.1'/>
                </p>
                <p>
                  <FormattedMessage id='app.credit-report-form.ssn-modal.body.2'/>
                </p>
                <p>XXX-XX-1234</p>
                <p>
                  <FormattedMessage id='app.credit-report-form.ssn-modal.body.3'
                                    values={{
                                      link: <a href="https://trust.salesforce.com"
                                               target="_blank">trust.salesforce.com</a>
                                    }}/>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
