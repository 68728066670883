import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import Field from './helpers/Field'
import FormUserError from './form-user-error'
import FormError from './form-error'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'

const rethrowIfFailed = error => {
    if (error && error._error) {
        throw new Error(error);
    }
}

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
    props.submitting ||
    !props.valid ||
    state.isProcessing;
}

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default class TempcodeForm extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isProcessing: false,
            fatalFormError: null
        }
    }

    componentDidMount() {
        if (this.props.login == null) {
            this.props.history.push(`/new-user`)
        } else {
            if (this.props.login.nextPage === 'CREATEPASSWORD') {
                this.props.history.push('/make-a-password')
            } else if (this.props.login.nextPage === 'PASSWORD') {
                 this.props.history.push('/login')
            } else if(this.props.login.nextPage === 'DUPLICATECONTACT') {
                this.props.history.push('/account-error')
            } else if(this.props.login.nextPage === 'DUPLICATELOGIN') {
                this.props.history.push('/account-error')
            } else if(this.props.login.nextPage === 'TEMPPASSWORD') {
                this.props.createEnrollment()
                this.props.history.push('/enter-one-time-code')
            }
        }
    }

    componentDidUpdate(prevProps, nextProps, state) {
        if (this.props.login === null) {
            this.props.history.push(`/welcome`)
        }

        if (this.props.error !== prevProps.error && this.props.error === 'app.message.tempcode.unsuccessful') {

            let err = this.props.error
            this.setState({
                fatalFormError: err,
                isProcessing: false
            })
        }
    }

    resend(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();

        this.state = {
            isProcessing: true
        }

        this.ensureResend()
            .then(() => this.goToTempCodePage());
    }

    ensureResend() {
        return this.props.ensureResend
            ? Promise.resolve()
            : this.props.createEnrollment().then(rethrowIfFailed)
    }

    goToTempCodePage() {

        this.props.history.push(`/temporary-code`);

        this.state = {
            isProcessing: false
        }
    }

    render() {
        const {intl, formSyncErrors}=this.props;

        return (
            <div>
                <HeaderContainerAP/>

                <div className='container-ap tempcode-form'>

                    <div className='page-title'>
                      <FormattedMessage id='app.tempcode.title'/>
                    </div>
                    <div className='text-ap margin-bottom-32-px'>
                      {
                        this.props.login && this.props.login.nextPage === 'TEMPPASSWORD' ?
                        <FormattedMessage id='app.tempcode.resent.code.message'/>
                        :
                        <FormattedMessage id='app.tempcode.instruction1'/>
                      }
                    </div>
                    <form onSubmit={this.props.handleSubmit}>
                        <div className='form-group margin-bottom-32-px'>
                            <div className='label-text'>
                                <FormattedMessage id='app.tempcode.input.tempcode'/>
                            </div>
                            <Field name='singleUseCode' type='tel' component={renderField} />
                        </div>

                        <div className='margin-bottom-32-px'>
                            <FormUserError validationErrors={formSyncErrors} intl={intl}/>
                            <FormError error={this.state.fatalFormError} />
                        </div>

                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-primary btn-block btn-lg'
                                    disabled={isSubmitDisabled(this.props, this.state)}>
                                <FormattedMessage id='app.common.continue.button'/>
                            </button>
                        </div>

                        <div className='text-ap additional-links'>
                            <Link to="" className='back-link' onClick={(e) => this.resend(e)} id='resend'>
                                <FormattedMessage id='app.tempcode.alt-link'/>
                            </Link>
                        </div>
                    </form>
                </div>
                <FooterContainerAp/>
            </div>)
    }
}
