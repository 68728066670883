import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import ValidationService from '../services/validation-service'
import {createPassword} from '../actions/login-actions'
import {getContact} from '../actions/registration-actions'
import {displayLoadingIcon} from '../actions/loading-icon-action'
import CreatePassForm from '../components/create-pass-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'

import {doesContactHaveCreditReportOnFile} from "../actions/trust-plus-credit-actions";
import {getEmployer, getEmployerFromContactId} from "../actions/employer-actions";
import {setActiveCounselor} from "../actions/counselor-actions";
import {getBookedAppointments, getCompletedAttendedAppointments} from "../actions/calendly-actions";
import {createEnrollment} from "../actions/enrollment-actions";

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('login')(state),
    login: state.login,
    employer: state.employer,
    registration: state.registration,
    activeCounselors: state.activeCounselors,
    bookedAppointments: state.bookedAppointments,
    completedAttendedAppointments: state.completedAttendedAppointments
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveCounselor(counselor) {
      dispatch(setActiveCounselor(counselor))
    }
  }
}

function goToNextPage(props) {
  console.log("CreatePassFormContainer goToNextPage")
  props.history.push(`/action-plan`);
}

function goToCustomerPortal(props) {
  console.log("CreatePassFormContainer goToCustomerPortal")
  props.history.push(`/customer-portal`);
}

function getCounselor(contactId, counselors) {
  let counselor = null;
  for (let i=0; i<counselors.length; i++) {
    let counselorTmp = counselors[i];
    if (counselorTmp.contactId === contactId) {
      counselor = counselorTmp;
    }
  }
  return counselor;
}

const CreatePassFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
  form: 'create-pass-form',
  validate: ValidationService.createPassword,
  onSubmit: (values, dispatch, props) => {
    return dispatch(createPassword(values)).then(() => {
      dispatch(createEnrollment())
      return dispatch(getContact(props.login.contactId)).then(() => {
        return dispatch(getBookedAppointments()).then(() => {
          if (props.bookedAppointments.length > 0) {
            props.setActiveCounselor(getCounselor(props.bookedAppointments[0].coach, props.activeCounselors))
          }
          return dispatch(getCompletedAttendedAppointments()).then(() => {
            return dispatch(getEmployerFromContactId(props.login.contactId)).then(() => {
              return dispatch(doesContactHaveCreditReportOnFile()).then(() => {
                goToNextPage(props)
                  // if (props.login.isCustomersContact === true) {
                  //    goToCustomerPortal(props)
                  //  } else {
                  //     goToNextPage(props)
                  //   }
              })
            })
          })
        })
      })
    })
  },
  destroyOnUnmount: false
})(injectIntl(CreatePassForm))
)

export default CreatePassFormContainer
