import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";
import CustomerPortalNav from "./customer-portal-nav";

function CustomerTermsOfService(props) {

    return(
      <div id='customer-portal'>
        <CustomerPortalNav />
        <div id='customer-portal-content'>
          <div id='customer-portal-dashboard' className='customer-portal-pages'>

            <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title-background-gradient'></div>
              <div className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.customer-portal.dashboard-header'/>
              </div>
            </div>

            <div className='customer-portal-dashboard-container'>
              CP Terms Of Service

            </div>
          </div>
        </div>
      </div>
    )

}

export default (injectIntl(CustomerTermsOfService))
