import {connect} from 'react-redux'
import {injectIntl} from "react-intl";
import {toggleLocale} from "../../actions/locale-actions";
import HumanityForwardPlaceholder from "../../components/humanity-forward/humanity-forward-placeholder";

function mapStateToProps(state) {
  return {
    locale: state.locale,
    displayIcon: state.displayLoadingIcon,
    employer: state.employer
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle(currentLocale) {
      dispatch(toggleLocale(currentLocale))
    },
  }
}

const HumanityForwardPlaceholderContainer = connect(
  mapStateToProps,
    mapDispatchToProps
)(injectIntl(HumanityForwardPlaceholder))

export default HumanityForwardPlaceholderContainer