import React , { useState } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormattedMessage from "../helpers/FormattedMessage";

export default function DisputeStep1(props) {

  return (
    <div>
      <div className='recommendations-gradient-color'>
        <div className='top-priority'>
          <FormattedMessage id='app.debt-report-recommendations.top-priority'/>
        </div>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.collections'/>
        </h2>
      </div>

      <div className='recommendations-page-wrapper'>
        <div className='work-on-this-page-container dispute-steps'>
          <h1 className='action-plan-subtitle margin-bottom-24-px'>
            <FormattedMessage id='app.debt-report-collections.dispute-step-1-title'/>
          </h1>
          <div className='collections-dispute-steps-wrapper'>
            <h2 className='collections-dispute-instructions-step-heading'>
              <FormattedMessage id='app.debt-report-collections.dispute-step-1-subtitle'/>
            </h2>

            <p>
              <FormattedMessage id='app.debt-report-collections.dispute-step-1-text-1'/>
              <a href='http://service.transunion.com/' target='_blank'><FormattedMessage id='app.debt-report-collections.dispute-step-1-text-2'/></a>
              <FormattedMessage id='app.debt-report-collections.dispute-step-1-text-3'/>
            </p>
            <div className='collections-dispute-note-notice-wrapper'>
              <div className='collections-dispute-note-notice-img'>
                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} aria-label='An exclamation point in an orange circle' />
              </div>
              <p>
                <FormattedMessage id='app.debt-report-collections.dispute-step-1-text-4'/>
              </p>
            </div>
            <div className='dispute-button-next'>
              <button className='btn btn-primary btn-block btn-work-on-this'
                      id='debt-report-reviewed-collections-yes'
                      onClick={()=>props.setCollectionPage("disputeStep2")}>
                <FormattedMessage id='app.debt-report-collections.dispute-next-step-btn'/>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}