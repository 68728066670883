import React from 'react'
import {intl} from 'react-intl'
import FormattedMessage from './helpers/FormattedMessage'
import BudgetIncomeField from './helpers/BudgetIncomeField'
import CounselorBubbleBudget from './helpers/CounselorBubbleBudget'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const SHOW_THREE = 'showThree'
const SHOW_ALL = 'showAll'
const SHOW_NONE = 'showNone'

export default class BudgetIncomeForm extends React.Component {

  constructor(props){
    super(props);

    this.budgetIncomeFormRef = null;

    this.setBudgetIncomeFormRef = element => {
      this.budgetIncomeFormRef = element;
    };

    this.state = {
      displayFieldsState: SHOW_THREE,
      showHideText: 'app.action-plan-budget.button.hide-details',
    }

  }

  handleClick() {
    let event;
    if(typeof(Event) === 'function') {
      // updates to bubble the event up to root, https://github.com/facebook/react/issues/20151#issuecomment-721000418
      event = new Event('submit', {cancelable: true, bubbles:true} );
    }else{
      event = document.createEvent('Event');
      event.initEvent('submit', true, true);
    }

    this.budgetIncomeFormRef.dispatchEvent(event);

    this.props.verifyToken()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.submitIncome != prevProps.submitIncome &&
      this.props.submitIncome === true) {

      this.handleClick( { capture: true } );
    }

    if (this.props.formTotalAvg != prevProps.formTotalAvg && prevProps.formTotalAvg != 0) {
      this.props.updateIncomeBudgetFormPristine(false)
      this.props.verifyToken()
    }
  }

  decrement(e, id, prop) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    if (_.isNaN(prop) || parseInt(prop) < 1) {
      // do nothing
    } else {
      if (parseInt(prop) - 100 > 0)
        this.props.change(id, parseInt(prop) - 100)
      else
        this.props.change(id, 0)
    }
  }

  increment(e, id, prop) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.props.change(id, (_.isNaN(parseInt(prop)) ? 0 : parseInt(prop)) + 100)
  }

  minusClass(prop) {
    if (_.isNaN(parseInt(prop)) || parseInt(prop) < 1) {
      return 'budget-minus-inactive'
    } else {
      return ''
    }
  }

  updateDisplayFieldState(val) {
    this.setState({
      displayFieldsState: val,
    });

    this.props.verifyToken()
  }

  toggleHideShow() {
    if (this.state.displayFieldsState === SHOW_NONE) {
      this.updateDisplayFieldState(SHOW_THREE)
      this.setState({
        showHideText: 'app.action-plan-budget.button.hide-details'
      })
    } else {
      this.updateDisplayFieldState(SHOW_NONE)
      this.setState({
        showHideText: 'app.action-plan-budget.button.show-details'
      })
    }

    this.props.verifyToken()
  }



  render() {

    return (
      <form onSubmit={this.props.handleSubmit} ref={this.setBudgetIncomeFormRef}>
        <div className='budget-income-form'>
          <div className='action-plan-sub-heading margin-bottom-16-px'>
            <FormattedMessage id='app.action-plan-budget.income.title'/>
          </div>
          <div className='budget-expense-income-minus-title-wrapper '>
            <div className='budget-total-avg-wrapper margin-bottom-16-px'>
              <div className='budget-total-avg-heading'>
                <FormattedMessage id='app.action-plan-budget.income.total'/>
              </div>
              <div className='budget-total-avg-number'>
                {
                  _.isNaN(this.props.formTotalAvg) ?
                    ''
                    : formatter.format(this.props.formTotalAvg)
                }
              </div>
            </div>
            <div className='budget-hide-details-wrapper margin-bottom-16-px'>
              <div className='submit-button-round'>
                  <button id='hide-details'
                          type={'button'}
                          onClick={() => this.toggleHideShow()}
                          className='btn btn-default btn-lg'>
                      <FormattedMessage id={this.state.showHideText}/>
                  </button>
              </div>
            </div>
          <div className='budget-show-details-wrapper'>
            <div className='submit-button-round margin-bottom-24-px'>
                <button id='show-details'
                        type={'button'}
                        onClick={() => this.showDetails()}
                        className='btn btn-default btn-lg'>
                    <FormattedMessage id='app.action-plan-budget.button.show-details'/>
                </button>
            </div>
          </div>


          <div className='budget-income-fields-wrapper'>
            {
              this.state.displayFieldsState != SHOW_NONE ?
               <div>
                 <BudgetIncomeField name='salaryNet1' id='salaryNet1' i18n='app.action-plan-budget.income.my-pay'
                                    increment={(e) => this.increment(e, 'salaryNet1', this.props.formSalaryNet1)}
                                    decrement={(e) => this.decrement(e, 'salaryNet1', this.props.formSalaryNet1)}
                                    minus={this.minusClass(this.props.formSalaryNet1)}/>
                 <BudgetIncomeField name='salaryNet2' id='salaryNet2' i18n='app.action-plan-budget.income.my-side-gig'
                                    increment={(e) => this.increment(e, 'salaryNet2', this.props.formSalaryNet2)}
                                    decrement={(e) => this.decrement(e, 'salaryNet2', this.props.formSalaryNet2)}
                                    minus={this.minusClass(this.props.formSalaryNet2)}/>
                 <BudgetIncomeField name='partnerIncome1' id='partnerIncome1' i18n='app.action-plan-budget.income.my-partner-pay'
                                    increment={(e) => this.increment(e, 'partnerIncome1', this.props.formPartnerIncome1)}
                                    decrement={(e) => this.decrement(e, 'partnerIncome1', this.props.formPartnerIncome1)}
                                    minus={this.minusClass(this.props.formPartnerIncome1)}/>
               </div> : <div />
            }

            {
              this.state.displayFieldsState == SHOW_THREE ?
                <div className='show-more-fewer-sources submit-button-round margin-bottom-16-px'>
                  <button id='show-more'
                          type={'button'}
                          onClick={() => this.updateDisplayFieldState(SHOW_ALL)}
                          className='btn btn-default btn-lg'>
                    <FormattedMessage id='app.action-plan-budget.button.show-more'/>
                  </button>
                </div> : <div/>
            }

            {
              this.state.displayFieldsState == SHOW_ALL ?
                <div>
                  <BudgetIncomeField name='partnerIncome2' id='partnerIncome2' i18n='app.action-plan-budget.income.my-partner-side-gig'
                                     increment={(e) => this.increment(e, 'partnerIncome2', this.props.formPartnerIncome2)}
                                     decrement={(e) => this.decrement(e, 'partnerIncome2', this.props.formPartnerIncome2)}
                                     minus={this.minusClass(this.props.formPartnerIncome2)}/>
                  <BudgetIncomeField name='myBusinessIncome' id='myBusinessIncome' i18n='app.action-plan-budget.income.my-business'
                                     increment={(e) => this.increment(e, 'myBusinessIncome', this.props.formMyBusinessIncome)}
                                     decrement={(e) => this.decrement(e, 'myBusinessIncome', this.props.formMyBusinessIncome)}
                                     minus={this.minusClass(this.props.formMyBusinessIncome)}/>
                  <BudgetIncomeField name='unemployment' id='unemployment' i18n='app.action-plan-budget.income.unemployment'
                                     increment={(e) => this.increment(e, 'unemployment', this.props.formUnemployment)}
                                     decrement={(e) => this.decrement(e, 'unemployment', this.props.formUnemployment)}
                                     minus={this.minusClass(this.props.formUnemployment)}/>
                  <BudgetIncomeField name='foodStamps' id='foodStamps' i18n='app.action-plan-budget.income.snap'
                                     increment={(e) => this.increment(e, 'foodStamps', this.props.formFoodStamps)}
                                     decrement={(e) => this.decrement(e, 'foodStamps', this.props.formFoodStamps)}
                                     minus={this.minusClass(this.props.formFoodStamps)}/>
                  <BudgetIncomeField name='retirementSocialSecurity' id='retirementSocialSecurity' i18n='app.action-plan-budget.income.ssr'
                                     increment={(e) => this.increment(e, 'retirementSocialSecurity', this.props.formRetirementSocialSecurity)}
                                     decrement={(e) => this.decrement(e, 'retirementSocialSecurity', this.props.formRetirementSocialSecurity)}
                                     minus={this.minusClass(this.props.formRetirementSocialSecurity)}/>
                  <BudgetIncomeField name='childSupport' id='childSupport' i18n='app.action-plan-budget.income.child-support'
                                     increment={(e) => this.increment(e, 'childSupport', this.props.formChildSupport)}
                                     decrement={(e) => this.decrement(e, 'childSupport', this.props.formChildSupport)}
                                     minus={this.minusClass(this.props.formChildSupport)}/>
                  <BudgetIncomeField name='rentalIncome' id='rentalIncome' i18n='app.action-plan-budget.income.rental-income'
                                     increment={(e) => this.increment(e, 'rentalIncome', this.props.formRentalIncome)}
                                     decrement={(e) => this.decrement(e, 'rentalIncome', this.props.formRentalIncome)}
                                     minus={this.minusClass(this.props.formRentalIncome)}/>
                  <BudgetIncomeField name='familyOrFriends' id='familyOrFriends' i18n='app.action-plan-budget.income.help-fam-friends'
                                     increment={(e) => this.increment(e, 'familyOrFriends', this.props.formFamilyOrFriends)}
                                     decrement={(e) => this.decrement(e, 'familyOrFriends', this.props.formFamilyOrFriends)}
                                     minus={this.minusClass(this.props.formFamilyOrFriends)}/>
                  <BudgetIncomeField name='wic' id='wic' i18n='app.action-plan-budget.income.wic'
                                     increment={(e) => this.increment(e, 'wic', this.props.formWic)}
                                     decrement={(e) => this.decrement(e, 'wic', this.props.formWic)}
                                     minus={this.minusClass(this.props.formWic)}/>
                  <BudgetIncomeField name='ssdi' id='ssdi' i18n='app.action-plan-budget.income.disability'
                                     increment={(e) => this.increment(e, 'ssdi', this.props.formSsdi)}
                                     decrement={(e) => this.decrement(e, 'ssdi', this.props.formSsdi)}
                                     minus={this.minusClass(this.props.formSsdi)}/>
                  <BudgetIncomeField name='publicAssistance' id='publicAssistance' i18n='app.action-plan-budget.income.pa-tanf'
                                     increment={(e) => this.increment(e, 'publicAssistance', this.props.formPublicAssistance)}
                                     decrement={(e) => this.decrement(e, 'publicAssistance', this.props.formPublicAssistance)}
                                     minus={this.minusClass(this.props.formPublicAssistance)}/>
                  <BudgetIncomeField name='otherIncome' id='otherIncome' i18n='app.action-plan-budget.income.other'
                                     increment={(e) => this.increment(e, 'otherIncome', this.props.formOtherIncome)}
                                     decrement={(e) => this.decrement(e, 'otherIncome', this.props.formOtherIncome)}
                                     minus={this.minusClass(this.props.formOtherIncome)}/>

                  <div className='show-more-fewer-sources submit-button-round margin-bottom-16-px'>
                    <button id='show-fewer'
                            type={'button'}
                            onClick={() => this.updateDisplayFieldState(SHOW_THREE)}
                            className='btn btn-default btn-lg'>
                      <FormattedMessage id='app.action-plan-budget.button.show-fewer'/>
                    </button>

                  </div>
                </div> : <div />
            }
          </div>

          <div className='income-coins-container'>
            <div className='margin-bottom-32-px'>
              <div className='budget-remaining-wrapper'>
              {
                this.props.formTotalAvg - this.props.totalExpense >= 0 ?
                  <div className='budget-remaining-heading'>
                    <FormattedMessage id='app.action-plan-budget.income.remaining'/>
                    <span className='budget-remaining-number'>
                    {
                      formatter.format(this.props.formTotalAvg - this.props.totalExpense)
                    }
                    </span>
                  </div> :
                  <div className='budget-remaining-heading'>
                      <FormattedMessage id='app.action-plan-budget.income.over-budget'/>
                      <span className='budget-remaining-number budget-over-budget-number'>
                    {
                      formatter.format(this.props.totalExpense - this.props.formTotalAvg)
                    }
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

          <CounselorBubbleBudget i18n={'app.action-plan-budget.counselor.advice'}
                                 imageId={this.props.imageId}
                                 wrapper={'budget-advice-wrapper budget-advice-wrapper-income'}/>
        </div>
      </form>
    )
  }
}
