import React from 'react'
import FormattedMessage from "../helpers/FormattedMessage";
import EmergencyResponseHeaderContainer from "../../containers/emergency-response/emergency-response-header-container";

export default class EmergencyResponsePlaceholder extends React.Component {
  constructor(props){
    super(props);
  }

  renderEmergencyResponseHeadline(){
    return this.props.locale === 'en-US' ? this.englishHeadline() : this.spanishHeadline();
  }

  englishHeadline(){
    return this.props.employer.headline !== null ? this.props.employer.headline : <FormattedMessage id='app.emergency-response.saverlife-emergency-response-fund.title' />
  }

  spanishHeadline(){
    return this.props.employer.headlineSpanish !== null ? this.props.employer.headlineSpanish : <FormattedMessage id='app.emergency-response.saverlife-emergency-response-fund.title' />
  }

  render() {
    return (
        <div>
          <EmergencyResponseHeaderContainer/>
          <div className='emergency-response-title-wrapper margin-bottom-24-px'>
            <div className='emergency-response-title-text'>
              {this.renderEmergencyResponseHeadline()}
            </div>
          </div>
          <div className='container ptxxl'>
            <div className='container er-smaller-container registration-form'>
              <div className='emergency-response-subtitle'>
                <FormattedMessage id='app.opportunity-fund.go-to-saver-life.title'/>
              </div>
                <div className='emergency-response-text margin-bottom-16-px'>
                    <FormattedMessage id='app.emergency-response.placeholder.redirect-to-SaverLife'/>
                </div>
              <div className='submit-button-round margin-bottom-24-px'>
                <a target='_blank'
                   href='https://help.saverlife.org/hc/en-us/requests/new'
                   id='pull-report'
                   className='btn btn-primary btn-block btn-lg' alt='Take Me To Saver Life'>
                  <FormattedMessage id='app.opportunity-fund.go-to-saver-life.take-me-to-saver-life.button'/>
                </a>
              </div>
            </div>
          </div>
        </div>)
  }
}
