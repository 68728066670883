export const DISPLAY_ALERT = 'DISPLAY_ALERT'
export const ALERT_NAME = 'ALERT_NAME'

export function displayAlert(truthy) {
  let displayValue = truthy === true ? true : false
  return {
    type: DISPLAY_ALERT,
    displayAlert : displayValue
  }
}

export function alertName(val) {
  let alertValue = val
  return {
    type: ALERT_NAME,
    alertName : alertValue
  }
}
