import {CREATE_LEAD} from '../actions/customer-actions'

const lead = (state = null, action) => {
    switch (action.type) {
        case CREATE_LEAD:
            return action.lead
        default:
            return state
    }
}

export default lead
