import React from 'react'
import FormattedMessage from "../helpers/FormattedMessage";
import NtfcuSupportHeaderContainer from "../../containers/ntfcu-support/ntfcu-support-header-container";

export default class NtfcuSupportPlaceholder extends React.Component {
  constructor(props){
    super(props);
  }

    renderNtfcuHeadline(){
        return this.props.locale === 'en-US' ? this.englishHeadline() : this.spanishHeadline();
    }

    englishHeadline(){
        return this.props.employer.headline !== null ? this.props.employer.headline : <FormattedMessage id='app.ntfcu-support.title'/>
    }

    spanishHeadline(){
        return this.props.employer.headlineSpanish !== null ? this.props.employer.headlineSpanish : <FormattedMessage id='app.ntfcu-support.title'/>
    }

    onLocalToggle(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        this.props.onLocaleToggle(this.props.locale);
    }

  render() {
    return (
        <div>
            <div className='landing-header-links-right'>
                <div className='spanish-link-landing-page'>
                    <button className='locale-link-onboarding-home'
                            onClick={e => this.onLocalToggle(e)}>
                        <FormattedMessage id='app.introduction.locale-link'/>
                    </button>
                </div>
            </div>
            <NtfcuSupportHeaderContainer/>
            <div className='emergency-response-title-wrapper margin-bottom-24-px'>
            <div className='emergency-response-title-text'>
                {this.renderNtfcuHeadline()}
            </div>
          </div>
          <div className='container ptxxl'>
            <div className='container er-smaller-container registration-form'>
                <div className='emergency-response-text margin-bottom-16-px'>
                    <FormattedMessage id='app.ntfcu-support.placeholder.text.1'/>
                </div>
                <div className='emergency-response-text margin-bottom-16-px'>
                    <FormattedMessage id='app.ntfcu-support.placeholder.text.2'/>
                </div>
                <div className='emergency-response-text margin-bottom-16-px'>
                    <FormattedMessage id='app.ntfcu-support.placeholder.text.3'/>
                </div>
                <div className='emergency-response-text margin-bottom-16-px'>
                    <FormattedMessage id='app.ntfcu-support.placeholder.text.4'/>
                </div>
                <div className='emergency-response-text margin-bottom-16-px'>
                    <FormattedMessage id='app.ntfcu-support.placeholder.text.5'/>
                </div>
            </div>
          </div>
        </div>)
  }
}
