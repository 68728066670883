import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";
import LandingFooter from "../landing-footer";
import IntroductionTagline from "./introduction-tagline";
import IntroductionHeader from "./introduction-header";
import HomePageChecklist from "../home-page/home-page-checklist";
import IntroductionHowItWorks from "./introduction-how-it-works";
import IntroductionTestimonials from "./introduction-testimonials";
import IntroductionPageCounselors from "../introduction-page-counselors";

function IntroductionDefault(props) {

  return(

    <div className='intro-container'>
      <IntroductionHeader onLocaleToggle={(locale) => props.onLocaleToggle(locale)}
                          locale={props.locale}
                          history={props.history}
                          employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
      />

      <main>
        <div>
          <div className={`intro-hero ${props.locale === 'es-US' ? 'spanish' : ''}`}>
            <div className='intro-hero-label'><FormattedMessage id='app.landing-page.introduction.hero-label-mobile'/></div>
            <span role="img" aria-label={`${props.locale === 'es-US' ? 'Los clientes de TrustPlus como Renee y Luis pueden hablar con sus entrenadores financieros expertos a través de chat de video en sus computadoras o teléfonos inteligentes. Elayna, la entrenadora financiera dedicada de TrustPlus, comparte consejos financieros útiles durante una sesión de entrenamiento remoto.' : 'TrustPlus clients like Renee and Luis can speak to their expert financial coaches via video chat on their computers or smartphones. Dedicated TrustPlus financial coach Elayna shares helpful financial tips during a remote coaching session.'}`}></span>
            <div className='landing-page-nav-wrapper'>
              <div className='landing-container'>
                <div className='landing-page-nav flex'>
                  <div className='landing-page-nav-item'>
                    <a href='#how-it-works'>
                      <FormattedMessage id='app.landing-page.how-it-works.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a href='#testimonials'>
                      <FormattedMessage id='app.landing-page.testimonials.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a href='#our-coaches'>
                      <FormattedMessage id='app.landing-page.coaches.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a href='/0000/learn-more' target='_blank'>
                      <FormattedMessage id='app.footer-link-landing-page.faq'/>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='intro-content-landing-page-wrapper intro-content-landing-page-wrapper-desktop'>
              <HomePageChecklist determineNextLocation={()=> props.determineNextLocation()}
                                 employerDefaultsToAskACoach={()=>props.employerDefaultsToAskACoach()}
              />
              {
                props.employer && props.employer.code && props.employer.code !== "0000" ?
                  <div className='landing-container intro-hero-logo'>
                    <div className='intro-hero-logo-title'>
                      <FormattedMessage id='app.landing-page.company-logo.title'/>
                    </div>
                    {props.employerImage()}
                  </div> : <div/>
              }

            </div>
          </div>

          <div className='intro-content-landing-page-wrapper-mobile'>
            <HomePageChecklist determineNextLocation={()=> props.determineNextLocation()}
                               employerDefaultsToAskACoach={()=>props.employerDefaultsToAskACoach()}
            />
            {
              props.employer && props.employer.code && props.employer.code !== "0000" ?
                <div className='landing-container intro-hero-logo'>
                  <div className='intro-hero-logo-title'>
                    <FormattedMessage id='app.landing-page.company-logo.title'/>
                  </div>
                  {props.employerImage()}
                </div> : <div/>
            }

          </div>


          <a id='how-it-works'></a>
          <IntroductionHowItWorks />

          <a id='testimonials'></a>
          <IntroductionTestimonials intl={props.intl} />

          {props.activeCounselors != null && props.activeCounselors.length !== 0 ?
            <div className='coaches-landing-page'>
              <a id='our-coaches'></a>
              <div className='landing-container'>
                <h2><FormattedMessage id='app.landing-page.coaches.title'/></h2>
                <IntroductionPageCounselors
                  activeCounselors={props.activeCounselors}
                  locale={props.locale}
                  employer={props.employer}/>
              </div>
            </div>
            : ''
          }

          <IntroductionTagline determineNextLocation={() => props.determineNextLocation()}
                               employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
          />

          <LandingFooter />
        </div>
      </main>
    </div>



    )
}
export default IntroductionDefault