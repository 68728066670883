import React from 'react'

export const InterestRateArrow = ({
                        style = {},
                        fill = '#141006',
                        width = '100%',
                        className = '',
                        height = '100%',
                        viewBox = '0 0 21 29',
                      }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Page-6" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="debt_w_morelessupdate_w1200+" transform="translate(-1098.000000, -478.000000)" fill="#EF3E42">
        <g id="Group-3" transform="translate(1107.132106, 494.131305) scale(-1, 1) rotate(-30.000000) translate(-1107.132106, -494.131305) translate(1100.132106, 479.131305)">
          <path d="M6.00705056,28.4829195 C2.34091533,25.5408143 -0.00928961979,21.0217423 -0.000244930576,15.971114 C0.00743248878,11.6956946 1.69220668,7.81388817 4.43104539,4.9480172 L2.12685184,2.64382366 C1.15143249,1.66833978 1.8422712,0.000533333333 3.22175507,0.000533333333 L11.8707228,0.000533333333 C12.7258841,0.000533333333 13.4191099,0.69375914 13.4191099,1.54892043 L13.4191099,10.1978882 C13.4191099,11.5773075 11.7513035,12.2681462 10.7758196,11.2927914 L8.0822712,8.59924301 C6.21781959,10.5904688 5.18504539,13.1670495 5.16143249,15.9044043 C5.12824669,19.7468453 7.1208631,23.2003059 10.1909741,25.1559247 C11.8837803,26.2342192 8.7478341,30.682422 6.00705056,28.4829195 Z" id="Fill-1"></path>
        </g>
      </g>
    </g>

  </svg>;

export default InterestRateArrow