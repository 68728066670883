import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'autotrack/autotrack'

import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client';
import {Provider, useSelector, useDispatch} from 'react-redux'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from '@reduxjs/toolkit'
import {Router, useParams} from 'react-router-dom'
import { Route, Redirect, Switch } from 'react-router'
import history from './browserHistory'
import appReducer from './reducers/combined-reducer'
import WelcomeFormContainer from './containers/welcome-form-container'
import ClearContainer from './containers/clear-container'
import TempCodeFormContainer from './containers/tempcode-form-container'
import ForgotPasswordFormContainer from './containers/forgotpass-form-container'
import NotificationFormContainer from './containers/notification-form-container'
import ClientStoriesFormContainer from './containers/client-stories-form-container'
import IntroductionContainer from "./containers/introduction-container";
import AdminTimezoneContainer from "./containers/admin-timezone-container";
import StandaloneCreditReportFormPageContainer from "./containers/standalone-credit-report-form-page-container";
import StandaloneCreditReportSubmittedPageContainer
  from "./containers/standalone-credit-report-submitted-page-container";
import AdminPickACoachContainer from "./containers/admin-pick-a-coach-container";
import AdminScheduleContainer from "./containers/admin-schedule-container";

import BFlowEmailForm from "./components/b-flow/b-flow-email-form";
import BFlowFullNameForm from "./components/b-flow/b-flow-full-name-form";
import BFlowTempCode from "./components/b-flow/b-flow-temp-code-form";
import BFlowNewPasswordForm from "./components/b-flow/b-flow-new-password-form";
import BFlowPickACoach from "./components/b-flow/b-flow-pick-a-coach";
import BFlowPickATime from "./components/b-flow/b-flow-pick-a-time";
import BFlowCalendlyConfirmation from "./components/b-flow/b-flow-calendly-confirmation";
import BFlowLogin from "./components/b-flow/b-flow-login";
import BFlowSessionScheduled from "./components/b-flow/b-flow-session-scheduled";
import BFlowCreditReportFormPage from "./components/b-flow/b-flow-credit-report-form-page";
import BFlowForgotPasswordForm from "./components/b-flow/b-flow-forgot-password-form";
import BFlowResendTempCode from "./components/b-flow/b-flow-resend-temp-code";
import BFlowForgotPasswordConfirmation from "./components/b-flow/b-flow-forgot-password-confirmation";

import PostgresResetPasswordPageContainer from "./containers/postgres-reset-password-page-container";
import PostgresResetPasswordFormContainer from "./containers/postgres-reset-password-form-container";
import ForgotpasswordConfirmationContainer from "./containers/forgotpassword-confirmation-container";
import SamlRelay from "./components/saml-relay";

import {library} from '@fortawesome/fontawesome-svg-core'
import { faSave as farSave } from '@fortawesome/free-regular-svg-icons'
import { faPlusSquare as fasPlusSquare} from '@fortawesome/free-solid-svg-icons'
import { faMinusSquare as fasMinusSquare} from '@fortawesome/free-solid-svg-icons'
import { faCircle as fasCircle} from '@fortawesome/free-solid-svg-icons'
import { faCheck as fasCheck} from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleLeft as fasChevronCircleLeft} from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleRight as fasChevronCircleRight} from '@fortawesome/free-solid-svg-icons'
import {faFlag as fasFlag } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp as fasThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle as farTimesCircle} from '@fortawesome/free-regular-svg-icons'
import { faPlusCircle as falPlusCircle} from '@fortawesome/pro-light-svg-icons'
import { faMinusCircle as falMinusCircle} from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle as fasPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { faLock as fasLock} from '@fortawesome/free-solid-svg-icons'
import { faChevronDoubleDown as farChevronDoubleDown} from '@fortawesome/pro-regular-svg-icons'
import { faTwitterSquare as fabTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faFacebookSquare as fabFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faComments as fasComments } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight as fasChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft as fasChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleRight as falAngleDoubleRight} from '@fortawesome/pro-light-svg-icons'
import { faPencilAlt as fasPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown as fasAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faPiggyBank as fasPiggyBank } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight as farAngleRight} from '@fortawesome/pro-regular-svg-icons'
import { faAngleLeft as farAngleLeft} from '@fortawesome/pro-regular-svg-icons'
import { faArrowLeft as fasArrowLeft} from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle as fasExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import { faClock as falClock} from '@fortawesome/pro-light-svg-icons'
import { faCalendarAlt as farCalendarAlt} from '@fortawesome/free-regular-svg-icons'
import { faChevronUp as fasChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown as fasChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faSquarePlus as fasSquarePlus } from '@fortawesome/sharp-solid-svg-icons'
import { faSquareMinus as fasSquareMinus } from '@fortawesome/sharp-solid-svg-icons'

import messages from "./i18n/messages";
import {IntlProvider } from "react-intl";
import Spinner from "./components/spinner"
require('@formatjs/intl-pluralrules/locale-data/en');
require('@formatjs/intl-pluralrules/locale-data/es');
require('@formatjs/intl-relativetimeformat/locale-data/en');
require('@formatjs/intl-relativetimeformat/locale-data/es');
import {
  getEnglishCurrentlyWorkingCounselors,
  getEnglishSpeakers, getSpanishCurrentlyWorkingCounselors,
  getSpanishSpeakers, setActiveCounselors, setActiveCurrentlyWorkingCounselors, setRandomCounselor
} from "./actions/counselor-actions"
import Employers from "./components/employers"
import ActionPlan from "./components/action-plan/action-plan"

import CreatePassFormContainer from "./containers/create-pass-form-container";
import NewUserForm from "./components/new-user-form"
import Terms from "./components/terms";
import AccountError from "./components/account-error";
import PrivacyPolicy from "./components/privacy-policy";

import Customers from "./components/customers";
import CustomerPortal from "./components/customer-portal/customer-portal";
import RoundRobinPickATime from "./components/round-robin";
import RoundRobinConfirmationAndCreditReportForm from "./components/round-robin-confirmation-and-credit-report-form";
import Nudge from "./components/nudge";

import ReactGA from 'react-ga';
import usePageTracking from "./usePageTracking";
import {toggleLocale} from "./actions/locale-actions";
import {getAllActiveEmployersWithSSO, getEmployer} from "./actions/employer-actions";
import {getCalendlyRoundRobinUrl, getCalendlyRoundRobinUrlSpanish} from "./actions/calendly-actions";
import LoginForm from "./components/login-form";
import LoginFormContainer from "./containers/login-form-container";
import DclRedirect from "./components/dcl-redirect";

ReactGA.initialize('UA-35686382-11');
ReactGA.pageview(window.location.pathname + window.location.search);

require('autotrack');

library.add(farSave)
library.add(fasPlusSquare)
library.add(fasMinusSquare)
library.add(fasCircle)
library.add (fasCheck)
library.add (fasChevronCircleLeft)
library.add (fasChevronCircleRight)
library.add (fasFlag)
library.add (fasThumbsUp)
library.add (farTimesCircle)
library.add (falPlusCircle)
library.add (falMinusCircle)
library.add (fasLock)
library.add (fasPlusCircle)
library.add (farChevronDoubleDown)
library.add (fabTwitterSquare)
library.add (fabFacebookSquare)
library.add (fabLinkedin)
library.add (fasComments)
library.add (farChevronRight)
library.add (farChevronLeft)
library.add (fasChevronRight)
library.add (fasChevronLeft)
library.add (falAngleDoubleRight)
library.add (fasPencilAlt)
library.add (fasAngleDown)
library.add (fasPiggyBank)
library.add (farAngleRight)
library.add (farAngleLeft)
library.add (fasArrowLeft)
library.add (fasExclamationCircle)
library.add (falClock)
library.add (farCalendarAlt)
library.add (fasChevronDown)
library.add (fasChevronUp)
library.add (fasSquarePlus)
library.add (fasSquareMinus)

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['displayLoadingIcon'],
  // timeout: null
};

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_REDUX_STORE') {
    let locale = 'en-US'
    let employer = {code:'0000', name:'No Employer'}

    storage.removeItem('persist:root')
    state = {locale: locale, employer: employer};
  }

  if (action.type === 'LOGOUT') {

    console.log("CLEARING CACHY STUFF")
    let locale = 'en-US'
    let counselors = state.activeCounselors
    let activeCounselors = state.activeCurrentlyWorkingCounselors
    let employer = {code:'0000', name:'No Employer'}
    storage.removeItem('persist:root')
    state = {employer: employer, locale: locale, activeCounselors: counselors, activeCurrentlyWorkingCounselors: activeCounselors};
  }

  if (action.type === 'CPLOGOUT') {
    if (state.login == null) {
      history.push(`/customers`);
    }
    else {
      history.push(`/customers`);
    }
    let locale = 'en-US'
    storage.removeItem('persist:root')
    state = {locale: locale};
  }

  return appReducer(state, action);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export const persistor = persistStore(store)

const App = () => {
  usePageTracking()

  const locale = useSelector(state => state.locale)
  const msgFile = useSelector(state => state.msgFile)
  const displayLoadingIcon = useSelector(state => state.displayLoadingIcon)
  const registration = useSelector(state => state.registration)
  const login = useSelector(state => state.login)
  const employerDisplayName = useSelector(state => state.employer.displayName);
  const employerDisplayBranding = useSelector(state => state.employer.displayLogoInTrustPlusApp)
  const displayLinkMoneyModal = useSelector(state => state.displayLinkMoneyModal)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllActiveEmployersWithSSO())
    dispatch(getEnglishSpeakers()).then(()=>{
      dispatch(getSpanishSpeakers()).then(()=>{
        dispatch(getEnglishCurrentlyWorkingCounselors()).then(()=>{
          dispatch(getSpanishCurrentlyWorkingCounselors()).then(()=>{
            dispatch(setActiveCurrentlyWorkingCounselors())
            dispatch(setActiveCounselors())
            dispatch(setRandomCounselor())
            dispatch(getCalendlyRoundRobinUrl())
            dispatch(getCalendlyRoundRobinUrlSpanish())
          })
        })
      })
    })
  }, [])

  useEffect(() => {
    dispatch(setActiveCurrentlyWorkingCounselors())
    dispatch(setActiveCounselors())
    dispatch(setRandomCounselor())
  }, [locale])

  useEffect(() => {
    if (login && login.samlEntity && registration && registration.preferredLanguage) {
      if ((registration.preferredLanguage === "English" && locale !== "en-US") || (registration.preferredLanguage === "Spanish" && locale !== "es-US") ) {
        dispatch(toggleLocale(locale))
      }
    }
  }, [])

  useEffect(() => {
    if (employerDisplayBranding) {
      document.title = `TrustPlus by ${employerDisplayName}`;
    } else {
      document.title = 'TrustPlus';
    }
  }, [employerDisplayBranding, employerDisplayName]);

  return (
    <IntlProvider locale={locale}
                key={locale}
                messages={messages[msgFile]}>
      <div id='app'>
        {displayLoadingIcon && <Spinner/>}
        <div className={`body ${displayLoadingIcon ? 'dimmer' : displayLinkMoneyModal ? 'dimmer-link-money' : ''}`}>
          <Switch>
            <Route exact path="/" component={IntroductionContainer}/>
            <Route exact path="/clear" component={ClearContainer}/>

            <Route exact path="/enter-your-email-address" component={BFlowEmailForm}/>
            <Route exact path="/tell-us-your-name" component={BFlowFullNameForm} />
            <Route exact path="/enter-one-time-code" component={BFlowTempCode} />
            <Route exact path="/make-a-password" component={BFlowNewPasswordForm}/>
            <Route exact path="/enter-your-password" component={BFlowLogin}/>
            <Route exact path="/forgot-your-password" component={BFlowForgotPasswordForm}/>
            <Route exact path="/select-a-financial-coach" component={BFlowPickACoach}/>
            <Route exact path="/pick-a-time" component={BFlowPickATime}/>
            <Route exact path="/great-your-session-is-scheduled" component={BFlowSessionScheduled}/>
            <Route exact path="/pull-your-credit-report" component={BFlowCreditReportFormPage}/>
            <Route exact path="/calendly-confirmation" component={BFlowCalendlyConfirmation}/>
            <Route exact path="/forgot-your-password-confirmation" component={BFlowForgotPasswordConfirmation} />
            <Route exact path="/resend-temp-code" component={BFlowResendTempCode} />

            <Route exact path="/welcome" component={WelcomeFormContainer}/>
            <Route exact path="/new-user"><NewUserForm/></Route>
            <Route exact path="/temporary-code" component={TempCodeFormContainer}/>
            <Route exact path="/create-password" component={CreatePassFormContainer}/>
            <Route exact path="/login" component={LoginFormContainer}/>
            <Route exact path="/notification" component={NotificationFormContainer}/>
            <Route exact path="/forgot-password" component={ForgotPasswordFormContainer}/>
            <Route exact path="/forgot-password-confirmation" component={ForgotpasswordConfirmationContainer}/>
            <Route exact path="/reset-password" component={PostgresResetPasswordPageContainer}/>
            <Route path="/reset-password-2" component={PostgresResetPasswordFormContainer}/>
            <Route exact path="/account-error"><AccountError/></Route>

            <Route path="/customer-portal"><CustomerPortal/></Route>
            <Route path="/customers"><Customers/></Route>

            <Route exact path="/terms"><Terms/></Route>
            <Route exact path="/privacy-policy"><PrivacyPolicy/></Route>

            <Route path="/saml-relay" component={SamlRelay}/>

            <Route exact path="/coaches-calendly-form" component={AdminTimezoneContainer}/>
            <Route exact path="/coaches-calendly-selection" component={AdminPickACoachContainer}/>
            <Route exact path="/coaches-calendly-schedule" component={AdminScheduleContainer}/>

            <Route exact path="/select-by-availability" component={RoundRobinPickATime}/>
            <Route exact path="/your-session-is-scheduled" component={RoundRobinConfirmationAndCreditReportForm}/>

            <Route path="/action-plan" ><ActionPlan /></Route>

            <Route exact path="/client-stories" component={ClientStoriesFormContainer}/>
            <Route exact path="/credit-auth-form" component={StandaloneCreditReportFormPageContainer}/>
            <Route exact path="/credit-auth-submitted" component={StandaloneCreditReportSubmittedPageContainer}/>

            <Route exact path="/debtconsolidation" component={DclRedirect} />

            <Route path="/nudge" component={Nudge} />
            <Route path="/:employerCode"><Employers/></Route>

          </Switch>
        </div>
      </div>
    </IntlProvider>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>
  );
