import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Field from '../helpers/Field'
import HeaderContainerAP from '../../containers/header-container-ap'
import {reduxForm} from "redux-form";
import {injectIntl} from "react-intl";
import {checkContactRecord, checkLoginRecord, setTemporaryEmail} from "../../actions/b-flow-actions";
import ValidationService from "../../services/validation-service";
import {useSelector} from "react-redux";


const renderField = ({input, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

function BFlowEmailForm(props){

    const login = useSelector(state => state.login);
    const activeEmployers = useSelector(state => state.activeEmployers);

    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid
    }

    useEffect(() => {
        if (props.submitSucceeded) {
            const timeout = setTimeout(() => {
                props.reset();
            }, 3000);

            return () => clearTimeout(timeout)
        }
    }, [props.submitSucceeded]);

    useEffect(() => {
        if (login != null && login !== '') {
            if (login.status === 'Active' && login.token != null) {
                props.history.push('/select-a-financial-coach')
            } else if (login.nextPage === 'TEMPPASSWORD') {
                props.history.push('/enter-one-time-code')
            } else if (login.nextPage === 'CREATEPASSWORD') {
                props.history.push('/make-a-password')
            } else if (login.nextPage === 'PASSWORD') {
                props.history.push('/enter-your-password')
            }
        }
    }, [])

    const buildSSOAltCompanyLogin = () => {
        if (activeEmployers && activeEmployers.length > 0) {
            let displaySsoLink = activeEmployers.filter(employer => employer.displaySsoServiceProviderLink === "Yes").map((employer, index) => {
                return <div key={index} className='company-login-link'>
                    <a href={`/api/saml-login/${employer.samlEntity}`}><FormattedMessage
                        id='app.alt-company-login.login-text'/> {employer.displayName}</a>
                </div>
            })

            if (displaySsoLink.length > 0) {
                return <div><FormattedMessage id='app.alt-company-login.text-1'/>
                    <div className='company-login-links-wrapper'>{displaySsoLink} </div>
                </div>
            } else {
                return null
            }

        } else {
            return null
        }
    }

    const {intl} = props;

    return (
      <div className='b-flow b-flow-background'>
          <HeaderContainerAP/>
          <div className='b-flow-body'>
              <div className='container-ap welcome-form'>
                  <h1>
                      <FormattedMessage id='app.b-flow.email-form.title'/>
                  </h1>
                  <form onSubmit={props.handleSubmit}>
                      <div className='form-group margin-bottom-24-px'>
                          <Field name='username' type='text' id='username' component={renderField} placeholder={intl.formatMessage({ id: 'app.b-flow.email-form.placeholder.email'})} />
                      </div>

                      <div className='submit-button-round'>
                          <button id='submit'
                                  type={'submit'}
                                  className='btn btn-red'
                                  disabled={isSubmitDisabled(props)}>
                              <FormattedMessage id='app.b-flow.email-form.button.continue'/>
                          </button>
                      </div>
                  </form>
                <div className='alt-company-login-container dark-background'>
                  <div className='company-login-links-wrapper'>
                    {buildSSOAltCompanyLogin()}
                  </div>
                </div>
              </div>
          </div>
      </div>)
}

const goToNextPage = (props) => {
    props.history.push('/tell-us-your-name')
}

export default reduxForm({
    form: 'BFlowEmailForm',
    validate: ValidationService.welcome,
    onSubmit: (values, dispatch, props) => {
        return dispatch(checkContactRecord(props.values.username)).then(() => {
            dispatch(checkLoginRecord(props.values.username)).then(() => {
                dispatch(setTemporaryEmail(props.values.username))
                goToNextPage(props)
            })
        })
    },
    destroyOnUnmount: false
})(injectIntl(BFlowEmailForm))