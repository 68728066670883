import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'

export default function TradeDebtTable(props) {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <div className='work-on-this-table-container'>
      <div className='work-on-this-table-row-1'>
        { props.account.name }
      </div>
      <div className='work-on-this-table'>
        <div>
          <FormattedMessage id='app.debt-report-work-on-this.table-row-1'/>
        </div>
        <div className='row-2'>
          {props.account.originalCreditor}
        </div>
        <div>
          <FormattedMessage id='app.debt-report-work-on-this.table-row-2'/>
        </div>
        <div className='row-2'>
          <FormattedMessage id='app.debt-report-work-on-this.table-row-2-text'/>
        </div>
        <div>
          <FormattedMessage id='app.debt-report-work-on-this.table-row-3'/>
        </div>
        <div className='row-2'>
          {formatter.format(props.account.balance)}
        </div>
        <div>
          <FormattedMessage id='app.debt-report-work-on-this.table-row-4'/>
        </div>
        <div className='row-2'>
          {props.account.openDate}
        </div>
      </div>
    </div>


  )
}