import React, {useEffect, useState} from 'react'

import FormattedMessage from '../helpers/FormattedMessage'
import Field from '../helpers/Field'
import HeaderContainerAP from '../../containers/header-container-ap'
import FooterContainerAp from '../../containers/footer-container-ap'
import FormUserError from "../form-user-error";
import FormError from "../form-error";
import {reduxForm} from "redux-form";
import ValidationService from "../../services/validation-service";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {verifyEnrollmentCode} from "../../actions/enrollment-actions";


const renderField = ({input, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type} maxLength={6}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

const rethrowIfFailed = error => {
    if (error && error._error) {
        throw new Error(error);
    }
}

function BFlowResendTempCode(props){

    const [isProcessing, setIsProcessing] = useState(false)
    const [fatalFormError, setFatalFormError] = useState(null)
    const [displayResendMessage, setDisplayResendMessage] = useState(false)
    const [resetError, setResetError] = useState(false);

    const login = useSelector((state) => state.login)
    const registration = useSelector((state) => state.registration)

    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid ||
            isProcessing
    }

    useEffect(() => {
        if (login === null) {
            props.history.push(`/enter-your-email-address`)
        } else if (props.login != null && login !== '') {
            if (login.nextPage === 'CREATEPASSWORD') {
                props.history.push('/make-a-password')
            } else if (login.nextPage === 'PASSWORD') {
                props.history.push('/enter-your-password')
            }
        }

        if (props.error && props.error === 'app.b-flow.temp-code-form.unsuccessful') {
            let err = props.error
            setFatalFormError(err);
            setIsProcessing(false);
            document.getElementsByName('singleUseCode')[0].style.border = 'solid 3px #EE3E41';
        }

        if (resetError === true){
            setFatalFormError('');
            for (let i = 0; i <= 5; i++) {
                document.getElementsByName('singleUseCode')[0].style.border = 'solid 1px white';
            }
        }
    })


    const {intl, formSyncErrors} = props;

    return (
        <div className='b-flow b-flow-background'>
            <HeaderContainerAP/>
            <div className='b-flow-body'>
                <div className='container-ap'>
                    <h1>
                        <FormattedMessage id='app.b-flow.temp-code-form.instruction-resent.text-1'/>
                    </h1>
                    <h1>
                        <FormattedMessage id='app.b-flow.temp-code-form.instruction-resent.text-2'/>
                    </h1>
                    <form onSubmit={props.handleSubmit}>
                        <div className='temp-code'>
                            <Field name='singleUseCode' type='tel' component={renderField}/>
                        </div>

                        <div className='margin-bottom-32-px'>
                            <FormUserError validationErrors={formSyncErrors} intl={intl}/>
                            <FormError error={fatalFormError}/>
                        </div>

                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-red'
                                    disabled={isSubmitDisabled(props)}>
                                <FormattedMessage id='app.b-flow.temp-code-form.continue.button'/>
                            </button>
                        </div>

                    </form>
                </div>
                <FooterContainerAp/>
            </div>
        </div>)
}

function goToNextPage(props) {
    props.history.push('/make-a-password');
}

export default reduxForm({
    form: 'BFlowResendTempCodeForm',
    validate: ValidationService.verifyTempcode,
    onSubmit: (values, dispatch, props) => {
        return dispatch(verifyEnrollmentCode(values)).then(() => {
            goToNextPage(props)
        })
    },
    destroyOnUnmount: false
})(injectIntl(BFlowResendTempCode))
