import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'

import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../services/validation-service'
import {debtReportAttempt} from '../actions/debt-report-actions'
import StandaloneCreditReportFormPage from "../components/standalone-credit-report-form-page";
import {initiateStandaloneCrUpdate} from "../actions/standalone-cr-actions";
import {getClient} from "../actions/client-actions";
import {clearReduxStore} from "../actions/clear-redux-store-actions";

function mapStateToProps(state) {
  return {
    employer: state.employer,
    registration: state.form.registration && state.form.registration.values,
    client: state.client,
    showAlert: state.displayAlert,
    nameOfAlert: state.alertName,
    attempt: state.debtReportAttempt,
    formSyncErrors: getFormSyncErrors('registration')(state)//gets user validation errors on form level
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getClient(id) {
      dispatch(getClient(id))
    },
    clearReduxStore() {
      dispatch(clearReduxStore())
    }
  }
}

function goToNextPage(props) {
    props.history.push(`/credit-auth-submitted`)
}

const StandaloneCreditReportFormPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm({
    form: 'registration',
    validate:ValidationService.validateStandaloneCreditReportValues,
    onSubmit: (values, dispatch, props) => {
        dispatch(debtReportAttempt(true))
        return dispatch(initiateStandaloneCrUpdate(values)).then(()=>{
            goToNextPage(props)
        })
    },
    destroyOnUnmount: false
})
(injectIntl(StandaloneCreditReportFormPage))
)

export default StandaloneCreditReportFormPageContainer
