import axios from 'axios'

export const GET_DEBT_REPORT = 'GET_DEBT_REPORT'
export const GET_DEBT_REPORT_FAILED = 'GET_DEBT_REPORT_FAILED'
export const GETTING_DEBT_REPORT = 'GETTING_DEBT_REPORT'
export const RETRY_GET_DEBT_REPORT = 'RETRY_GET_DEBT_REPORT'
export const GET_DEBT_REPORT2 = 'GET_DEBT_REPORT2'
export const GETTING_DEBT_REPORT2 = 'GETTING_DEBT_REPORT2'
export const GET_DEBT_REPORT_FAILED2 = 'GET_DEBT_REPORT_FAILED2'

export const DEBT_REPORT_ATTEMPT = 'DEBT_REPORT_ATTEMPT'

export function debtReportAttempt(truthy) {
  let attempted = truthy === true
  return {
    type: DEBT_REPORT_ATTEMPT,
    debtReportAttempt: attempted
  }
}

export function startingDebtReport() {
  return {
    type: GETTING_DEBT_REPORT
  }
}

export function getDebtReport() {
  return (dispatch, getState) => {
    let contactId = getState().registration.id

    return axios
      .get('/api/debt-report', {params: {contactId}})
      .then(response => {
        dispatch({
          type: GET_DEBT_REPORT,
          debtReport: response.data
        })
      })
      .catch(() => {
        dispatch({type: GET_DEBT_REPORT_FAILED})
      })
  }
}

export function getDebtReportOnboarding() {
  return (dispatch, getState) => {
    let contact = getState().registration
    if (contact.mobilePhone === "000-000-0000"){
      contact.id = getState().login.contactId
      contact.email = getState().login.username
    }

    return axios
      .post('/api/debt-report', contact)
      .then(response => {
        dispatch({
          type: GET_DEBT_REPORT,
          debtReport: response.data
        })
      })
      .catch(() => {
        dispatch({type: GET_DEBT_REPORT_FAILED})
      })
  }
}
