import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import ActionPlanNavItem from './action-plan-nav-item'
import Icon from '../../../resources/icons'
import {useDispatch, useSelector} from "react-redux";
import {logout} from '../../actions/logout-actions'
import {toggleLocale} from '../../actions/locale-actions'
import {navCurrentComponent} from "../../actions/nav-actions";
import {useHistory} from "react-router-dom";
import history from "../../browserHistory";
import {displayTimeoutModal} from "../../actions/display-timeout-action";
import {expenseBudgetFormPristine, incomeBudgetFormPristine} from "../../actions/budget-actions";
import InactivityWarningModal from "../modals/inactivity-warning-modal";
import {verifyToken} from "../../actions/login-actions";

export default function ActionPlanNav(props) {

  const registration = useSelector((state) => state.registration)
  const locale = useSelector((state) => state.locale)
  const employer = useSelector((state) => state.employer)
  const login = useSelector(state => state.login)
  const displayModal = useSelector(state => state.displayTimeoutModal)
  const employerLoaded = useSelector(state => state.employerLoadedForNav)

  const dispatch = useDispatch();
  const history = useHistory();

  function onLogout(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    history.push(`/welcome?logout=true`);
  }

  function onLocaleToggle(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    dispatch(toggleLocale(locale))
  }

  function cancelModal(e) {
    dispatch(displayTimeoutModal(false))
    dispatch(verifyToken(login))
  }

  return (
    login && <div className='container-nav'>

      {displayModal ? <div onClick={() => cancelModal()} className="back-drop"></div> : null }
      {displayModal ?
        <InactivityWarningModal /> : <div/>}

      <div className='login-landing-page'>
        <div className='nav-header'>
          <div className='nav-logo-wrapper margin-bottom-32-px'>
            <div className='nav-logo'>
              <a href='/action-plan'>
                <Icon name="tp-full-logo"/>
              </a>
            </div>
          </div>
          <div className='nav-divide margin-bottom-24-px'></div>
          <div className='nav-name margin-bottom-24-px'>
            <FormattedMessage id='app.action-plan-nav.welcome-name'/> {registration.firstName}!
          </div>
        </div>


        <div className='nav-pages-container'>

          <ActionPlanNavItem
            key={'ActionPlanTakeActionToday'}
            componentActive={window.location.pathname.startsWith('/action-plan/take-action-today')}
            title={'app.action-plan-nav.take-action-today'}
            icon={'clipboard'}
            navItemClick={(e) => history.push('/action-plan/take-action-today')} />

          <ActionPlanNavItem
            key={'ActionPlanDebtSummary'}
            componentActive={window.location.pathname.startsWith('/action-plan/debt-summary')}
            title={'app.action-plan-nav.debt-summary'}
            icon={'chartpie'}
            navItemClick={(e) => history.push('/action-plan/debt-summary')} />

          { employerLoaded && employer.code !== 'h310' ?
              <ActionPlanNavItem
                key={'ActionPlanFicoScore'}
                componentActive={window.location.pathname.startsWith('/action-plan/fico-score')}
                title={'app.action-plan-nav.fico-score'}
                icon={'tachometer'}
                navItemClick={() => history.push('/action-plan/fico-score')}/>
              : ''
          }

          { registration.latestServiceProgram === "Getting Ahead" ?
              '' :
              <ActionPlanNavItem
                key={'ActionPlanSessions'}
                componentActive={window.location.pathname.startsWith('/action-plan/sessions')}
                title={'app.action-plan-nav.sessions'}
                icon={'calendar'}
                navItemClick={(e) => history.push('/action-plan/sessions')} />
          }

          <ActionPlanNavItem
            key={'ActionPlanBudget'}
            componentActive={window.location.pathname.startsWith('/action-plan/budget')}
            title={'app.action-plan-nav.budget'}
            icon={'money-bill'}
            navItemClick={(e) => history.push('/action-plan/budget')} />

          {/*TODO: removed messaging tab for release 116 */}
          {/*<ActionPlanNavItem*/}
          {/*  key={'ActionPlanMessages'}*/}
          {/*  componentActive={window.location.pathname.startsWith('/action-plan/messages')}*/}
          {/*  title={'app.action-plan-nav.messages'}*/}
          {/*  icon={'messaging-plain'}*/}
          {/*  navItemClick={(e) => history.push('/action-plan/messages')} />*/}

          { employerLoaded && employer.code !== 'h310' ?
              <ActionPlanNavItem
                  key={'ActionPlanLinkMoneyLanding'}
                  componentActive={window.location.pathname.startsWith('/action-plan/link-money/get-started')}
                  title={'app.action-plan-nav.link-money'}
                  icon={'piggy-bank'}
                  navItemClick={(e) => history.push('/action-plan/link-money/get-started')}/>
              : ''
          }

          {/*TODO: for future Link Money data display*/}
          {/*<ActionPlanNavItem*/}
          {/*    key={'ActionPlanLinkMoneyAccounts'}*/}
          {/*    componentActive={window.location.pathname.startsWith('/action-plan/link-money/accounts')}*/}
          {/*    title={'app.action-plan-nav.link-money-accounts'}*/}
          {/*    icon={'money-bill'}*/}
          {/*    navItemClick={(e) => history.push('/action-plan/link-money/accounts')} />*/}

          {/*<ActionPlanNavItem*/}
          {/*    key={'ActionPlanLinkMoneyTransactions'}*/}
          {/*    componentActive={window.location.pathname.startsWith('/action-plan/link-money/transactions')}*/}
          {/*    title={'app.action-plan-nav.link-money-transactions'}*/}
          {/*    icon={'money-bill'}*/}
          {/*    navItemClick={(e) => history.push('/action-plan/link-money/transactions')} />*/}

          {/*<ActionPlanNavItem*/}
          {/*    key={'ActionPlanLinkMoneyCategoryChart'}*/}
          {/*    componentActive={window.location.pathname.startsWith('/action-plan/link-money/category-chart')}*/}
          {/*    title={'app.action-plan-nav.link-money-category-chart'}*/}
          {/*    icon={'money-bill'}*/}
          {/*    navItemClick={(e) => history.push('/action-plan/link-money/category-chart')} />*/}



          <div className='nav-page-blue-box-wrapper'>
            <div className='nav-page-box-blue'>
              <a href='#' onClick={ e => onLocaleToggle(e) }>
                <div className='nav-page-white-box-container'>
                  <div className='nav-page-logo-wrapper'>
                    <div className='nav-page-logo'>
                      <Icon fill="#ffffff " name="globe-americas"/>
                    </div>
                  </div>
                  <div className='nav-page-name-wrapper'>
                    <div className='nav-page-name-utility'>
                      <FormattedMessage id='app.action-plan-nav.espanol'/>
                    </div>
                  </div>
                </div>
              </a>
            </div>

              <div className='nav-page-box-blue'>
                  <a href='/action-plan/feedback'>
                      <div className='nav-page-white-box-container'>
                          <div className='nav-page-logo-wrapper'>
                              <div className='nav-page-logo'>
                                  <Icon fill="#ffffff " name="speech-bubble"/>
                              </div>
                          </div>
                          <div className='nav-page-name-wrapper'>
                              <div className='nav-page-name-utility'>
                                  <FormattedMessage id='app.action-plan-nav.feedback'/>
                              </div>
                          </div>
                      </div>
                  </a>
              </div>

            <div className='nav-page-box-blue'>
              <a href='/action-plan/account'>
                <div className='nav-page-white-box-container'>
                  <div className='nav-page-logo-wrapper'>
                    <div className='nav-page-logo'>
                      <Icon fill="#ffffff " name="cog"/>
                    </div>
                  </div>
                  <div className='nav-page-name-wrapper'>
                    <div className='nav-page-name-utility'>
                      <FormattedMessage id='app.action-plan-nav.my-account'/>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className='nav-page-box-blue'>
              <a href='#' onClick={ e => onLogout(e) }>
                <div className='nav-page-white-box-container'>
                  <div className='nav-page-logo-wrapper'>
                    <div className='nav-page-logo'>
                      <Icon fill="#ffffff " name="sign-out"/>
                    </div>
                  </div>
                  <div className='nav-page-name-wrapper'>
                    <div className='nav-page-name-utility'>
                      <FormattedMessage id='app.action-plan-nav.sign-out'/>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
