import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'

export default function FormUserError({validationErrors, intl}) {

  if (!validationErrors) return null

  let under18 = (validationErrors.birthdateNotice ==='app.errors.birthdate.notOfAge.notice');

  if (under18) {
    let externalTermsOfSvc = 'https://' + intl.formatMessage({id: 'app.common.link.external.terms'});
    let mailToSupportAction = 'mailto:' + intl.formatMessage({id: 'app.common.email.support'});
    let tollFreePhoneAction = 'tel:' + intl.formatMessage({id: 'app.common.phone.tollfree'});

    return (
      <div className="validation-error alert alert-warning bold small-text">
        <FormattedMessage id={validationErrors.birthdateNotice}
                          values={{
                            externalTermsOfSvc: (
                              <a target='_blank' href={externalTermsOfSvc} id='external-terms'>
                                <FormattedMessage id='app.registration.agreement.terms-link'/>
                              </a>),
                            mailToSupport: (
                              <a href={mailToSupportAction}>
                                <FormattedMessage id='app.common.email.support'/>
                              </a>),
                            tollfreePhone: (
                              <a href={tollFreePhoneAction}>
                                <FormattedMessage id='app.common.phone.tollfree'/>
                              </a>)
                          }}/>
      </div>
    )
  }
  return null;
}
