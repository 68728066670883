import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import PrivacyText from "../helpers/PrivacyText";
import ActionPlanNav from "./action-plan-nav";

export default class ActionPlanPrivacyPolicy extends React.Component {

  constructor(props){
      super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { intl } = this.props

    return (
      <div id='ap-tos'>
        <ActionPlanNav />
        <div id='action-plan-content'>
          <div className='action-plan-breadcrumbs'>
          </div>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title margin-bottom-8-px'>
                  <FormattedMessage id='app.action-plan-nav.privacy-policy'/>
              </div>
          </div>
          <p><b><FormattedMessage id='app.privacy-policy.last-revised'/></b></p>

          <PrivacyText intl={intl} />
        </div>

      </div>)
  }
}
