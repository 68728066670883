import React from 'react'

import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'


export default function PostgresResetPasswordPage() {

        return (
            <div>
                <HeaderContainerAP/>

                <div className='container-ap welcome-form'>

                    <div className='page-title'>
                        <FormattedMessage id='app.reset-password.title'/>
                    </div>
                    <div className='text-ap margin-bottom-32-px'>
                        <FormattedMessage id='app.reset-password.instructions'/>
                    </div>
                </div>
                <FooterContainerAp/>
            </div>
        )
}


