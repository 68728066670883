import {GET_FICO_SCORE} from '../actions/fico-score-actions'

const ficoScore = (state = null, action) => {
  switch (action.type) {
    case GET_FICO_SCORE:
      return action.ficoScore
    default:
      return state
  }
}

export default ficoScore
