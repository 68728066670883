import {CURRENT_MY_ACCOUNT_COMPONENT} from '../actions/nav-actions'

export default function currentMyAccountComponent(state = 'Account', action) {
  switch (action.type) {
    case CURRENT_MY_ACCOUNT_COMPONENT:
      return action.currentMyAccountComponent
    default:
      return state
  }
}
