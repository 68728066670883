import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Field from '../helpers/Field'
import HeaderContainerAP from '../../containers/header-container-ap'
import FooterContainerCp from '../../containers/footer-container-cp'
import {reduxForm} from "redux-form";
import ValidationService from "../../services/validation-service";
import {checkContactRecord} from "../../actions/b-flow-actions";
import {injectIntl} from "react-intl";
import {verifyUser} from "../../actions/login-actions";
import {getOrCreateLoginRecord} from "../../actions/cp-flow-actions";

const renderField = ({input, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

function CustomersLoginForm(props){

    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid
    }

  useEffect(() => {
    if (props.submitSucceeded) {
      goToNextPage(props)
    }
  }, [props.submitSucceeded]);

    const {intl} = props;

    return (
      <div className='b-flow b-flow-background'>
          <HeaderContainerAP/>
          <div className='b-flow-body'>
              <div className='container-ap customer-login-form'>

                  <h1>
                      <FormattedMessage id='app.cp-flow.login-form.title'/>
                  </h1>
                  <h1>
                      <FormattedMessage id='app.cp-flow.login-form.intro'/>
                  </h1>
                  <form onSubmit={props.handleSubmit}>
                      <div className='form-group margin-bottom-24-px'>
                          <Field name='username' type='text' id='username' component={renderField} placeholder={intl.formatMessage({ id: 'app.b-flow.email-form.placeholder.email'})} />
                      </div>

                      <div className='submit-button-round'>
                          <button id='submit'
                                  type={'submit'}
                                  className='btn btn-red'
                                  disabled={isSubmitDisabled(props)}>
                              <FormattedMessage id='app.b-flow.email-form.button.continue'/>
                          </button>
                      </div>
                  </form>
              </div>
          </div>
          <FooterContainerCp/>
      </div>)
}

function goToNextPage(props) {
    props.history.push('/customers/enter-one-time-code');
}

export default reduxForm({
    form: 'CustomersLoginForm',
    validate: ValidationService.welcome,
    onSubmit: (values, dispatch, props) => {
        return dispatch(verifyUser(props.values.username, "Customers")).then(() => {
            dispatch(checkContactRecord(props.values.username)).then(() => {
              dispatch(getOrCreateLoginRecord())
            })
        })
    },
    destroyOnUnmount: false
})(injectIntl(CustomersLoginForm))