import React from 'react'

export const ProgressLittleCheck = ({
                       style = {},
                       fill = '#141006',
                       width = '100%',
                       className = '',
                       height = '100%',
                       viewBox = '0 0 15 11',
                     }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Intro/Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="TPC---MyProgress---320-649" transform="translate(-53.000000, -322.000000)" fill="#00C7B1" fill-rule="nonzero">
        <path d="M67.3839286,323.732143 C67.5505961,323.89881 67.6339286,324.101189 67.6339286,324.339286 C67.6339286,324.577382 67.5505961,324.779761 67.3839286,324.946429 L59.7053572,332.625 C59.5386897,332.791668 59.3363107,332.875 59.0982143,332.875 C58.8601179,332.875 58.657739,332.791668 58.4910715,332.625 L54.0446429,328.178571 C53.8779754,328.011904 53.7946429,327.809525 53.7946429,327.571429 C53.7946429,327.333332 53.8779754,327.130953 54.0446429,326.964286 L55.2589286,325.75 C55.4255961,325.583332 55.627975,325.5 55.8660715,325.5 C56.1041679,325.5 56.3065468,325.583332 56.4732143,325.75 L59.0982143,328.383929 L64.9553572,322.517857 C65.1220247,322.35119 65.3244036,322.267857 65.5625,322.267857 C65.8005965,322.267857 66.0029754,322.35119 66.1696429,322.517857 L67.3839286,323.732143 Z" id=""></path>
      </g>
    </g>

  </svg>;

export default ProgressLittleCheck