import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Icon from "../../../resources/icons";
import CustomerPortalFooter  from './customer-portal-footer';
import {injectIntl} from "react-intl";

import {useDispatch, useSelector} from "react-redux";
import CustomerPortalProgressBar from "../helpers/CustomerPortalProgressBar";
import {fetchFiles} from "../../actions/customer-actions";
import {useHistory} from "react-router-dom";
import CustomerPortalNav from "./customer-portal-nav";
import {pageCompleted} from "../../actions/cp-flow-actions";
import CustomerPortalContact from "./customer-portal-contact";

function CustomerPortalContractSubmit(props) {

  const login = useSelector(state => state.login);
  const employer = useSelector(state => state.employer);
  const edsContract = useSelector(state => state.edsContract);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect( () => {
    window.scrollTo(0, 0)
  })

  const goBackToSignPage = (componentName) => {
    history.push('/customer-portal/contracts/sign')
  }

  const goToDashboard = (componentName) => {
    dispatch(pageCompleted(6))
    history.push('/customer-portal')
  }

  const handleViewSteps = (e) => {
    e.preventDefault()
    history.push('/customer-portal/contracts/view-all-steps')
  }

  const displayContract = () => {
    let filename = "signed_contract_" + edsContract.opportunityId + ".pdf"
    if (employer != null && employer.files != null) {
      if (employer.files.length > 0 && employer.files.filter(file => file.title === filename).length > 0) {
        const files = employer.files.filter(file => file.title === filename)
        const latestFile = files.sort((x,y) => new Date(x.lastModifiedDate) - new Date(y.lastModifiedDate)).pop()
        if (latestFile) {
          return <div className='submit-button-round continue'>
            <a href='#' onClick={(e) => downloadFilePath(e, latestFile)}>
              <button className="btn btn-primary btn-block" id="visit-onboarding"><FormattedMessage id='app.customer-portal.contracts-download'/></button>
            </a>
          </div>
        }
      }
    }
  }

  // FIXME duplicate code with customer-portal-onbaording.jsx
  const downloadFilePath = (e, file) => {
    e.preventDefault()
    let bytes = base64ToArrayBuffer(file.versionData)
    saveByteArray(file.title, bytes)
  }

  const base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    for (let i = 0; i < binaryLength; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const saveByteArray = (reportName, byte) => {
    let blob = new Blob([byte.buffer], {type: "application"});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    const fileName = reportName;
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    if (login != null && login !== '') {
      dispatch(fetchFiles())
    }
  },[])

    return(
      <div id='customer-portal'>
        <CustomerPortalNav />
        <div id='customer-portal-content'>
        <div id='customer-portal-contracts' className='customer-portal-pages'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <div className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.customer-portal.contracts-header'/>
            </div>
          </div>

          <div className='customer-portal-dashboard-container'>
            <div className='customer-portal-back-link' onClick={goBackToSignPage}>
              <FormattedMessage id='app.customer-portal-common.back-link'/>
            </div>
            <h2><FormattedMessage id='app.customer-portal-contracts.about-you.heading'/></h2>
            <div className='contract-content-wrapper'>
              <div className='contract-content-intro-wrapper'>
                <div className='contract-content-intro-text'>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you.text-1'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you-text-2'/>
                  </div>
                </div>
                <CustomerPortalProgressBar pageNum={6}
                                           handleViewSteps={(e) => handleViewSteps(e)}
                />
              </div>

              <div className='contracts-form-container no-background'>
                <div className='contracts-form-wrapper'>
                  <div className='big-check-circle'>
                    <Icon name="checkmark"/>
                  </div>
                  <div className='big-check-text'>
                    <FormattedMessage id='app.customer-portal.contracts.submit-thank-you'/>
                  </div>
                </div>
                { displayContract() }
                <div className='miss-something-small-text submit-contract-small-text'>
                  <FormattedMessage id='app.customer-portal.contracts.download-text'/>
                </div>
                <div className='contracts-form-container download'>
                  <div className='contracts-form-title'>
                    <FormattedMessage id='app.customer-portal-contracts.submit-title-1'/>
                  </div>
                  <div className='content-text-heading'>
                    <FormattedMessage id='app.customer-portal-contracts.submit-subtitle-1'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.submit.text-1'/>
                  </div>
                  <div className='content-text-heading'>
                    <FormattedMessage id='app.customer-portal-contracts.submit-subtitle-2'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.submit.text-2'/>
                  </div>
                </div>
                <div className='submit-button-round continue'>
                  <button
                    className="btn btn-primary btn-block"
                    id="visit-onboarding"
                    onClick={goToDashboard}>
                    <FormattedMessage id='app.customer-portal.contracts.go-to-onboarding'/>
                  </button>
                </div>
              </div>
            </div>

            <CustomerPortalFooter/>
            <CustomerPortalContact />

          </div>
        </div>
        </div>
      </div>
    )
}

export default (injectIntl(CustomerPortalContractSubmit))