import React from 'react'

export const WorriedEmoji = ({
                               style = {},
                               fill = '#000000',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 460 460',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-4" transform="translate(120.500000, 114.600000)">
        <circle id="Oval" stroke="#AEE0E8" stroke-width="16" fill="#FFFFFF" cx="109" cy="109" r="101"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="80" cy="102" r="16"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="138" cy="102" r="16"></circle>
        <path d="M69,162 C77.4848485,152.666667 90.8181818,148 109,148 C127.181818,148 140.515152,152.666667 149,162" id="Line" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square"></path>
      </g>
      <path d="M255.738759,164.169663 C274.238759,181.763413 283.488759,190.560288 283.488759,190.560288" id="Line-2" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square" transform="translate(269.613759, 177.364976) rotate(-30.000000) translate(-269.613759, -177.364976) "></path>
      <path d="M173.738759,164.169663 C192.238759,181.763413 201.488759,190.560288 201.488759,190.560288" id="Line-2" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square" transform="translate(187.613759, 177.364976) scale(1, -1) rotate(-30.000000) translate(-187.613759, -177.364976) "></path>
    </g>
  </svg>;

export default WorriedEmoji