import React from 'react'
import {Field} from 'redux-form'
import FormattedMessage from './FormattedMessage'

export const CheckboxContracts = ({name, onChange}) => (
  <div className='contracts-checkbox-container'>
    <div className='checkbox'>
      <label className='checkbox-inline'>
        <Field name={name} component='input' type='checkbox' onChange={onChange} />
        <span className='contracts-checkmark'></span>
      </label>
    </div>
  </div>
)

export default CheckboxContracts
