import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class PickACoachBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayModal: false,
      bio: null,
      shortBio: null,
    }
  }

  componentDidMount() {
    let counselor = this.props.counselor
    let bio = this.props.locale === 'en-US' ? counselor.aboutMe : counselor.aboutMeSpanish
    let shortBio = this.props.locale === 'en-US' ? counselor.aboutMeSmall : counselor.aboutMeSpanishSmall

    this.setState({
      bio: bio === null ? '' : bio,
      shortBio: shortBio === null ? '' : shortBio,
      name: `${counselor.firstName} ${counselor.lastName}`,
    })
  }

  showModal(e) {
    e.preventDefault()
    this.setState({
      displayModal: true
    })
    this.props.openLearnMore(this.props.counselor)
  }



  render() {
    return (
        <div className='pick-a-counselor-wrapper'>
          <div className='counselor-image-name-wrapper'>
            <div className='counselor-bubble-image'>
              {
                this.props.counselor.imageUrl ?
                    <img src={`${this.props.counselor.imageUrl}`} className='img-circle'/>
                    :
                    <div className='counselor-image-default img-circle'></div>
              }
            </div>
          </div>
          <div className='counselor-info-wrapper'>
            <div className='counselor-name'>{`${this.props.counselor.firstName} ${this.props.counselor.lastName}`}</div>
            <div className='counselor-bio'>
              {`${this.state.shortBio}...`}
            </div>
            <div className='more-link'>
              <a href="#" onClick={(e) => this.showModal(e)}><FormattedMessage id='app.shift-desktop-and-mobile-provider.view-bio'/> </a>
            </div>
          </div>
          <div className='clear-both'></div>
          <div className='pick-a-counselor-btn-wrapper'>
            <button onClick={()=>this.props.selectCounselor(this.props.counselor)}>
              <FormattedMessage id='app.appointment.pick-a-time-with'/> {this.props.counselor.firstName}
            </button>
          </div>
          <div className='pick-a-counselor-arrow-wrapper' onClick={()=>this.props.selectCounselor(this.props.counselor)}>
            <FontAwesomeIcon icon={['far', 'angle-right']} />
          </div>
        </div>
    )
  }
}
