import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'

import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../services/validation-service'
import TempcodeForm from '../components/tempcode-form'
import {createEnrollment, verifyEnrollmentCode} from "../actions/enrollment-actions";

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('login')(state),
    login: state.login
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createEnrollment() {
      return dispatch(createEnrollment())
    }
  }
}

function goToNextPage(props) {
  props.history.push(`/create-password`);
}

const TempcodeFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
  form: 'TempcodeForm',
  validate: ValidationService.verifyTempcode,
  onSubmit: (values, dispatch, props) => {
    return dispatch(verifyEnrollmentCode(values)).then(() => {
      goToNextPage(props)
    })
  },
  destroyOnUnmount: false
})(injectIntl(TempcodeForm))
)

export default TempcodeFormContainer
