import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import ActionPlanFeedback from "../../components/action-plan/action-plan-feedback";
import {displayLinkMoneyModal} from "../../actions/link-money-actions";

function mapStateToProps(state) {
  return {
    locale: state.locale,
    login: state.login,
    currentComponent: state.navCurrentComponent,
    registration: state.registration,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displayLinkMoneyModal() {
      dispatch(displayLinkMoneyModal(false))
    }
  }
}

const ActionPlanFeedbackContainer = connect(
    mapStateToProps, mapDispatchToProps
)(injectIntl(ActionPlanFeedback))

export default ActionPlanFeedbackContainer
