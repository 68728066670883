import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../services/validation-service'
import CreditReportForm from '../components/credit-report-form'

function mapStateToProps(state) {
  return {
    employer: state.employer,
    registration: state.form.registration && state.form.registration.values,
    showAlert: state.displayAlert,
    nameOfAlert: state.alertName,
    progressBarPercentage: state.progressPercentage,
    displayProgressBar: true,
    progressBarCondensed: true,
    debtReportAttempt: state.debtReportAttempt,
    formSyncErrors: getFormSyncErrors('registration')(state)//gets user validation errors on form level
  }
}

const CreditReportFormContainer = connect
(
  mapStateToProps
)(
  reduxForm
  (
    {
      form: 'registration',
      validate:ValidationService.validateCreditReportFormFields,// <--- validation function given to redux-form
      destroyOnUnmount: false
    }
  )(injectIntl(CreditReportForm))
)

export default CreditReportFormContainer
