import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'

import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import {resetPassword} from '../actions/login-actions'
import ForgotpasswordConfirmation from "../components/forgotpassword-confirmation";

function mapStateToProps(state) {
    return {
        formSyncErrors: getFormSyncErrors('login')(state),
        login: state.login,
    }
}

function goToNextPage(props) {
    props.history.push(`/create-password`);
}

const ForgotpasswordConfirmationContainer = connect(
    mapStateToProps,
)(reduxForm({
        form: 'ForgotpasswordConfirmation',
        onSubmit: (values, dispatch, props) => {
            return dispatch(resetPassword(values)).then(() => {
                goToNextPage(props)
            })
        },
        destroyOnUnmount: false
    })(injectIntl(ForgotpasswordConfirmation))
)

export default ForgotpasswordConfirmationContainer

