import React from 'react'

export const Celebrate = ({
                                       style = {},
                                       fill = '',
                                       width = '100%',
                                       className = '',
                                       height = '100%',
                                       viewBox = '0 0 72 72',
                                     }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="End" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="TPC---Congrats_Start_-Max-widths-of-various-elements" transform="translate(-545.000000, -149.000000)" fill-rule="nonzero">
        <g id="celebrate" transform="translate(545.000000, 149.000000)">
          <g id="Group" transform="translate(0.251163, 0.586047)">
            <path d="M65.3023256,31.7302326 L66.7255814,35.7488372 C66.7255814,35.7488372 53.9162791,32.2325581 47.5534884,34.744186 C40.4372093,37.5069767 42.8651163,44.455814 42.8651163,44.455814 L41.4418605,40.4372093 C41.4418605,40.4372093 39.0139535,33.2372093 46.2139535,30.6418605 C53.4139535,28.1302326 65.3023256,31.7302326 65.3023256,31.7302326 Z" id="Path" fill="#8CDBED"></path>
            <path d="M29.8046512,24.6976744 L25.7860465,26.1209302 C25.7860465,26.1209302 29.3023256,13.3116279 26.7906977,6.94883721 C24.027907,-0.16744186 17.0790698,2.26046512 17.0790698,2.26046512 L21.0976744,0.837209302 C21.0976744,0.837209302 28.2976744,-1.59069767 30.8930233,5.60930233 C33.4883721,12.8093023 29.8046512,24.6976744 29.8046512,24.6976744 Z" id="Path" fill="#EA0029"></path>
            <polygon id="Path" fill="#FF9E1B" points="7.2 70.0744186 0.334883721 64.2976744 19.172093 13.8139535 54.6697674 45.544186"></polygon>
            <polygon id="Path" fill="#B0008E" points="14.5674419 66.3069767 11.0511628 68.1488372 1.75813953 60.3627907 3.01395349 57.0139535"></polygon>
            <polygon id="Path" fill="#231F20" opacity="0.2" points="3.93488372 67.227907 0.334883721 64.2976744 19.172093 13.8139535 32.3162791 25.6186047"></polygon>
            <polygon id="Path" fill="#B0008E" points="28.6325581 59.0232558 25.2837209 60.7813953 7.2 45.7116279 8.53953488 42.3627907"></polygon>
            <polygon id="Path" fill="#B0008E" points="44.5395349 50.8186047 41.1069767 52.5767442 13.3116279 29.4697674 14.5674419 26.1209302"></polygon>
            <rect id="Rectangle" fill="#FF9E1B" x="50.9860465" y="21.4325581" width="4.26976744" height="4.26976744"></rect>
            <rect id="Rectangle" fill="#8CDBED" x="36" y="12.3906977" width="4.10232558" height="4.10232558"></rect>
            <rect id="Rectangle" fill="#B0008E" x="48.3906977" y="5.5255814" width="3.93488372" height="3.93488372"></rect>
            <rect id="Rectangle" fill="#00C7B1" x="55.7581395" y="41.1906977" width="3.6" height="3.6"></rect>
            <rect id="Rectangle" fill="#B0008E" x="19.3395349" y="7.70232558" width="3.76744186" height="3.76744186"></rect>
            <rect id="Rectangle" fill="#00C7B1" x="38.9302326" y="0.669767442" width="3.43255814" height="3.43255814"></rect>
            <rect id="Rectangle" fill="#FF9E1B" x="64.8837209" y="43.8697674" width="3.43255814" height="3.43255814"></rect>
            <path d="M69.655814,13.8139535 L71.0790698,17.8325581 C71.0790698,17.8325581 66.6418605,22.1023256 61.5348837,21.6 C58.1860465,21.2651163 54.4186047,15.7395349 51.9069767,16.744186 C44.7906977,19.5069767 47.2186047,26.455814 47.2186047,26.455814 L45.7953488,22.4372093 C45.7953488,22.4372093 43.3674419,15.2372093 50.5674419,12.6418605 C53.6651163,11.5534884 57.7674419,17.1627907 61.2837209,17.4976744 C65.972093,18.1674419 69.655814,13.8139535 69.655814,13.8139535 Z" id="Path" fill="#EA0029"></path>
            <rect id="Rectangle" fill="#B0008E" x="59.4418605" y="49.8139535" width="3.6" height="3.6"></rect>
            <rect id="Rectangle" fill="#8CDBED" x="13.0604651" y="4.35348837" width="3.76744186" height="3.76744186"></rect>
            <rect id="Rectangle" fill="#00C7B1" x="10.6325581" y="14.3162791" width="3.76744186" height="3.76744186"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>;

export default Celebrate