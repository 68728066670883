import {LINK_MONEY_TRANSACTIONS} from "../actions/link-money-actions";

const linkMoneyTransactions = (state = [], action) => {
    switch (action.type) {
        case LINK_MONEY_TRANSACTIONS:
            return action.linkMoneyTransactions;
        default:
            return state
    }
}

export default linkMoneyTransactions
