import React from 'react'

export const MessagingNew = ({
                                 style = {},
                                 fill = '#000000',
                                 width = '100%',
                                 className = '',
                                 height = '100%',
                                 viewBox = '0 0 21 18',
                               }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <path d="M11.11 3.182H1.903v13.513h13.967v-6.188" stroke="#003057" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="m18.75 4.795 1.004-1.003a1.046 1.046 0 0 0-.003-1.479l-1.312-1.312a1.05 1.05 0 0 0-1.482 0l-1.003 1.003a.262.262 0 0 0 0 .372l2.425 2.42a.262.262 0 0 0 .372 0zM9.357 8.602l5.733-5.733a.265.265 0 0 1 .373 0l2.419 2.42a.262.262 0 0 1 0 .37l-5.733 5.733-2.652.465a.525.525 0 0 1-.602-.602l.462-2.653zm2.239-.492a.301.301 0 0 1 0-.432l3.361-3.362a.306.306 0 1 1 .433.432L12.027 8.11a.301.301 0 0 1-.432 0zm.26 1.836h-1.048l.003-1.044h-.793l-.246 1.404.679.68 1.405-.247v-.793z" fill="#003057"/>
  </svg>;

export default MessagingNew