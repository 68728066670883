import React from 'react'

export const CustomerHome = ({
                            style = {},
                            fill = '#000000',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 26 24',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M24.4858 11.7803C24.4858 12.4718 23.9273 13.0335 23.2358 13.0375H21.9858L22.0128 19.2938C22.012 19.3994 22.0054 19.5049 21.993 19.6099V20.2384C21.993 21.1009 21.2939 21.8 20.4314 21.8H19.8028C19.7597 21.8 19.713 21.8 19.6735 21.8C19.634 21.8 19.564 21.8 19.5092 21.8H17.3082C16.4458 21.8 15.7466 21.1009 15.7466 20.2384V16.7991C15.7471 16.4675 15.6155 16.1493 15.381 15.9147C15.1465 15.6802 14.8283 15.5487 14.4966 15.5491H11.9975C11.6658 15.5487 11.3476 15.6802 11.1131 15.9147C10.8786 16.1493 10.747 16.4675 10.7475 16.7991V20.2358C10.7473 20.6501 10.5824 21.0473 10.2892 21.34C9.99594 21.6327 9.59841 21.7969 9.18409 21.7965H7.00288C6.94451 21.7965 6.88614 21.7965 6.82328 21.7884C6.76042 21.7803 6.73348 21.7965 6.6823 21.7965H6.05371C5.19126 21.7965 4.4921 21.0973 4.4921 20.2349V15.8616C4.49015 15.8252 4.49015 15.7886 4.4921 15.7521V13.0339H3.23492C2.90415 13.0339 2.5872 12.9012 2.35509 12.6656C2.12299 12.4299 1.99513 12.111 2.00018 11.7803C1.99419 11.427 2.13576 11.0873 2.39081 10.8428L12.4034 2.11523C12.6328 1.89431 12.945 1.78078 13.2628 1.80273C13.5588 1.79629 13.8481 1.89136 14.0826 2.07213L24.0557 10.8392C24.3514 11.058 24.5138 11.4135 24.4858 11.7803Z" stroke="black" strokeWidth="2.5" fill="#ffffff"/>

  </svg>;

export default CustomerHome