import React, {useEffect} from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from "../containers/footer-container-ap";


export default function StandaloneCreditReportThankYouPage(props) {

    const goHome = () => {
        props.history.push('/')
    }

    return (
        <div className='new-login standalone-thank-you'>
            <HeaderContainerAP/>
            <div className='container-ap ptxxl'>
                <div className='standalone-page-title'>
                    <FormattedMessage id='app.standalone-credit-report-thank-you.title'/>
                </div>
                <button type={'submit'}
                        id="submit-home"
                        className='standalone-btn-red'
                        onClick={() => goHome()}>
                    <FormattedMessage id='app.common.button.gohome'/>
                </button>
            </div>
        </div>
    )
}
