import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";
import {reduxForm} from "redux-form";
import {connect} from 'react-redux'
import Field from './../helpers/Field'

import {useDispatch, useSelector} from "react-redux";
import {updateEdsContract, getEdsContract, pageCompleted} from '../../actions/cp-flow-actions'
import CustomerPortalProgressBar from "../helpers/CustomerPortalProgressBar";
import CustomerPortalNav from "./customer-portal-nav";
import CustomerPortalFooter  from './customer-portal-footer';
import CustomerPortalContact from "./customer-portal-contact";
import {useHistory} from "react-router-dom";

const renderField = ({input, autocomplete, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type} autocomplete={autocomplete}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});


function CustomerPortalContractDetailsOne(props) {

    const [updatedWebinarValue, setValue] = useState(null);

    const dispatch = useDispatch();
    const currentWebinars = useSelector(state => state.edsContract.extraWebinars)
    const costPerWebinar = useSelector(state => state.edsContract.costPerExtraWebinar)
    const employer = useSelector(state => state.employer)

    let currentWebinarCount = currentWebinars;
    let currentWebinarCost = formatter.format(currentWebinars * costPerWebinar);

    const history = useHistory();

    useEffect( () => {
        window.scrollTo(0, 0)
    }, [])

    const goBackToEmployeesPage = () => {
        history.push('/customer-portal/contracts/employees')
    }

    const ask = (e) => {
        e.preventDefault()
        history.push('/customer-portal/messaging')
    }

    const webinarCountChange = (event) => {
      let amount = formatter.format(event.target.value * costPerWebinar);
      setValue(amount);
      props.handleChange;
      dispatch(getEdsContract(employer.id))
    }

    const displayWebinarCost = () => {
      return updatedWebinarValue === null ? <div>
        {currentWebinarCost}
      </div> :
      <div>
        {updatedWebinarValue}
      </div>
    }

    const handleViewSteps = (e) => {
        e.preventDefault()
        history.push('/customer-portal/contracts/view-all-steps')
    }

    const formSubmit = (values) => {
        console.log("CustomerPortalContractDetailsOne Submit");
        console.log(values)
        dispatch(updateEdsContract(values)).then(() => {
            dispatch(pageCompleted(3))
            dispatch(getEdsContract(employer.id)).then(() => {
                history.push('/customer-portal/contracts/details-two')
            })
        })
    }

    return(
        <div id='customer-portal'>
            <CustomerPortalNav />
        <div id='customer-portal-content'>
          <div id='customer-portal-contracts' className='customer-portal-pages'>
            <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title-background-gradient'></div>
              <div className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.customer-portal.contracts-header'/>
              </div>
            </div>

            <div className='customer-portal-dashboard-container'>

              <div className='customer-portal-back-link' onClick={goBackToEmployeesPage}>
                <FormattedMessage id='app.customer-portal-common.back-link'/>
              </div>



              <h2><FormattedMessage id='app.customer-portal-contracts.about-you.heading'/></h2>
              <div className='contract-content-wrapper'>
                <div className='contract-content-intro-wrapper'>
                  <div className='contract-content-intro-text'>
                    <div className='content-text'>
                      <FormattedMessage id='app.customer-portal-contracts.about-you.text-1'/>
                    </div>
                    <div className='content-text'>
                      <FormattedMessage id='app.customer-portal-contracts.about-you-text-2'/>
                    </div>
                  </div>
                <CustomerPortalProgressBar pageNum={3}
                                           handleViewSteps={(e) => handleViewSteps(e)}
                />
                </div>

                <div className='contracts-form-container'>
                  <div className='contracts-form-title-container'>
                    <div className='contracts-form-title'>
                      <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3a'/>
                      <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3b'/>
                    </div>
                    <div className='content-text'>
                      <FormattedMessage id='app.customer-portal-contracts.form.details.intro-text-1'/>
                    </div>
                  </div>

                  <div className='contracts-form-wrapper'>

                    <form onSubmit={props.handleSubmit(formSubmit)}>
                      <div className='contracts-details-container'>
                        <div className='contracts-col-wrapper'>
                          <div className='contracts-details-col'>
                            <div className='contracts-details-header'>
                              <FormattedMessage id='app.customer-portal-contracts.form.details.table-header-left'/>
                            </div>
                            <div className='contracts-details-info input-number-entry'>
                              <Field
                                name='extraWebinars'
                                type='input'
                                id='extraWebinars'
                                component={renderField}
                                onChange={webinarCountChange}
                              />
                            </div>
                          </div>
                          <div className='contracts-details-col'>
                            <div className='contracts-details-header padding-left-30px'>
                              <FormattedMessage id='app.customer-portal-contracts.form.details.table-header-right-1'/>
                              { costPerWebinar }
                              <FormattedMessage id='app.customer-portal-contracts.form.details.table-header-right-2'/>
                            </div>
                            <div className='contracts-details-info padding-left-30px'>
                              { displayWebinarCost() }
                            </div>
                          </div>
                        </div>

                        <div className='submit-button-round continue'>
                          <button
                            className="btn btn-primary btn-block"
                            id="submit"
                            type={'submit'}>
                            <FormattedMessage id='app.common.continue.button'/>
                          </button>
                        </div>

                      </div>

                    </form>

                    <div className='contracts-contact-us-text'>
                      <FormattedMessage id='app.customer-portal-contracts.form.footer-text-1'/>
                      <a href="#" onClick={(e) => ask(e)}><FormattedMessage id='app.customer-portal-contracts.form.footer-text-2'/></a>
                      <FormattedMessage id='app.customer-portal-contracts.form.footer-text-3'/>
                    </div>

                  </div>


                </div>
              </div>

                <CustomerPortalFooter />
                <CustomerPortalContact />

            </div>
          </div>
        </div>
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      extraWebinars: state.edsContract.extraWebinars
    }
  }
}

const UpdateEdsContract = reduxForm({
    form: 'CustomerPortalContractDetailsOne',
    onChange: (values, dispatch, props) => {
      dispatch(updateEdsContract(values))
    },
    destroyOnUnmount: false
})(injectIntl(CustomerPortalContractDetailsOne))

export default connect(mapStateToProps)(UpdateEdsContract)
