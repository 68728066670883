import {CREATE_INCOME_BUDGET, GET_INCOME_BUDGET} from '../actions/budget-actions'

const budgetIncome = (state = null, action) => {
  switch (action.type) {
    case CREATE_INCOME_BUDGET:
      return action.budgetIncome
    case GET_INCOME_BUDGET:
      return action.budgetIncome
    default:
      return state
  }
}

export default budgetIncome