import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import DebtSummaryTradeAccountRow from './debt-summary-trade-account-row'
import DebtSummaryDebtCategoryRow from '../debt-summary-debt-category-row'
import {CounselorBubbleDebtSummary} from '../helpers/CounselorBubbleDebtSummary'

const categoryColors = {
  credit: 'graph-color-4',
  student: 'graph-color-2',
  mortgage: 'graph-color-5',
  auto: 'graph-color-3',
  personal: 'graph-color-6',
  collection: 'graph-color-7',
  other: 'graph-color-1'
}

export default class SimpleDebtSummaryTable extends React.Component {

  constructor(props) {
    super(props);

    this.placedBlue = false

    this.state = {
      blueBarRect: null,
    }
  }

  componentDidUpdate(prevState) {
    if (this.placedBlue === false && this.state.blueBarRect !== prevState.blueBarRect && this.state.blueBarRect != null) {
      this.placedBlue = true
      this.props.placeBlue(this.debtReportTableWrapper.getBoundingClientRect(), this.state.blueBarRect)
    }
  }

  placeBlue(blueBarRect) {
    this.setState({
      blueBarRect: blueBarRect
    })
    this.placedBlue = false;
  }

  setAccountDetail(accountDetail) {
    this.props.setAccountDetail(accountDetail)
  }

  toggleCategoriesExpanded(categoryId) {
    this.props.toggleCategoriesExpanded(categoryId)
  }

  categoryRow(category) {
    return <DebtSummaryDebtCategoryRow
      id={category.id}
      key={category.id}
      name={category.name}
      color={category.color}
      totalBalance={category.totalBalance}
      totalMonthlyPayment={category.totalMonthlyPayment}
      categoryToggleVisible={this.props.showTradeAccounts}
      categoryExpanded={this.props.showTradeAccounts===true ? this.props.categoriesExpanded[category.id] : true}
      toggleCategoriesExpanded={(categoryId) => this.toggleCategoriesExpanded(categoryId)}
      setAccountDetail={(accountDetail) => this.setAccountDetail(accountDetail)}
    />
  }

  tradeAccountRow(row, categoryId) {
    return <DebtSummaryTradeAccountRow
      key={row.id}
      hidden={this.props.categoriesExpanded[categoryId] == true ? false : true}
      accountDetail={{
        id: row.id,
        key: row.id,
        name: row.name,
        balance: row.balance,
        creditLimit: row.creditLimit,
        monthlyPayment: row.monthlyPayment,
        accountType: row.accountType,
        type: row.type,
        openDate: row.openDate,
        lastActivityDate: row.lastActivityDate,
        accountStatusType: row.accountStatusType,
        accountOwnershipType: row.accountOwnershipType,
        originalCreditor: row.originalCreditor,
        ratingType: row.ratingType,
        triangle: row.triangle,
        flaggedByClient: row.flaggedByClient,
        interestRate: row.interestRate,
        flaggedInOnboarding: row.flaggedInOnboarding
      }}
      setAccountDetail={(accountDetail) => this.setAccountDetail(accountDetail)}
      currentAccountDetailId={this.props.currentAccountDetailId}
      placeBlue={(blueBarRect) => this.placeBlue(blueBarRect)}
    />
  }

    render() {

      let rows = []

      {this.props.debtReport.debtCategories.map(category => {
        category.color = categoryColors[category.id]
          if (category.tradeDebtAccounts[0]) {
              rows.push(this.categoryRow(category))
              this.props.showTradeAccounts === true ? category.tradeDebtAccounts.map(row => (
                  rows.push(this.tradeAccountRow(row, category.id)))
              ) : ''
          }
      })}

      let bubble = '';
      if (rows[0]) {
          bubble = 'app.action-plan-debt-summary.counselor.unfamiliar-advice'
      }
      else {
          bubble =  'app.action-plan-debt-summary.counselor.no-score'
      }

      return (
        <div id='debt-report'>
          <div></div>
          <div className='debt-table-wrapper margin-bottom-32-px'
               ref={ (debtReportTableWrapper) => this.debtReportTableWrapper = debtReportTableWrapper}>
            <table className='legend-table'>
              <thead>
                <tr>
                  <th className='key-heading'><FormattedMessage id='app.action-plan-debt-summary.key'/></th>
                  <th><FormattedMessage id='app.action-plan-debt-summary.category'/></th>
                  <th className='balance-heading'><FormattedMessage id='app.action-plan-debt-summary.balance'/></th>
                  <th className='monthly-payment'><FormattedMessage id='app.action-plan-debt-summary.monthly-payment'/></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {rows}
              {
                  rows[0] ? '' :
                  <tr>
                    <td colSpan={5}>
                      <div className='debt-summary-no-debt margin-bottom-24-px'>
                        <FormattedMessage id='app.action-plan-debt-summary.no-data'/>
                      </div>
                    </td>
                  </tr>
              }
              </tbody>
            </table>
          </div>

          <div>
            <CounselorBubbleDebtSummary i18n={bubble}
                                        imageId={this.props.imageId}
                                        wrapper={'unfamiliar-advice-wrapper'}/>
          </div>
        </div>
      )}
}
