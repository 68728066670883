import {CHECK_CONTACT_EXISTS} from "../actions/b-flow-actions";

const contactCreated = (state = false, action) => {
    switch (action.type) {
        case CHECK_CONTACT_EXISTS:
            return action.contactCreated
        default:
            return state
    }
}

export default contactCreated
