import React from "react";

import FormattedMessage from "./helpers/FormattedMessage";
import HeaderContainerAP from "../containers/header-container-ap";
import FooterContainerAp from "../containers/footer-container-ap";

export default class ForgotpasswordConfirmation extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isProcessing: false
        }
    }

    componentDidUpdate(prevProps, nextProps, state) {
        if (this.props.login === null) {
            this.props.history.push(`/welcome`);
        }
    }

    render() {

        return (
            <div>
                <HeaderContainerAP/>
                    <div className='container-ap forgotpassword-confirmation'>

                        <div className='page-title'>
                            <FormattedMessage id='app.forgotpassword-confirmation.title'/>
                        </div>
                        <div className='text-ap margin-bottom-32-px'>
                            <FormattedMessage id='app.forgotpassword-confirmation.instruction'/>
                        </div>
                        <form onSubmit={this.props.handleSubmit}>
                            <div className='submit-button-round margin-bottom-24-px'>
                                <button id='submit'
                                        type={'submit'}
                                        className='btn btn-primary btn-block btn-lg'>
                                    <FormattedMessage id='app.forgotpassword-confirmation.continue.button'/>
                                </button>
                            </div>
                        </form>
                    </div>
                <FooterContainerAp/>
            </div>)
    }
}