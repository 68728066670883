import React from 'react'

export const SmallLogo = ({
                           style = {},
                           fill = '#fff',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 24 48',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Neighborhood Trust</title>
    <desc>Neighborhood Trust Logo</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Now-enter-your-password" transform="translate(-16.000000, -8.000000)">
        <g id="Group" transform="translate(16.000000, 8.000000)">
          <g id="Group-3">
            <polygon id="Fill-848" fill="#FFFFFF" points="0 24.0760292 7.89617681 24.0760292 7.89617681 12.9249635 15.7927034 24.0760292 23.6888803 24.0760292 23.6888803 0.350364964 15.7927034 0.350364964 15.7927034 11.9758248 7.89617681 0.350364964 0 0.350364964"></polygon>
            <polygon id="Fill-849" fill="#1A1919" points="23.6888803 32.0836204 23.6888803 24.1751825 0 24.1751825 0 32.0836204 7.89617681 32.0836204 7.89617681 47.9008467 15.7927034 47.9008467 15.7927034 32.0836204"></polygon>
          </g>
        </g>
      </g>
    </g>

  </svg>;

export default SmallLogo