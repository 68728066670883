import FormattedMessage from "./helpers/FormattedMessage";
import Icon from "../../resources/icons";
import React from "react";

export default function DclNotAccepted(props) {

    return (
        <div className='dcl-round-robin landing-container'>
            <div className='select-by-availability-intro'>
                <h1 className='round-robin-title'>
                    <FormattedMessage id='app.round-robin.pick-a-time.dcl.not-accepted.title'/>
                </h1>
              <div className='dcl-round-robin-container'>
                <div className='dcl-accepted-wrapper'>
                  <div className='dcl-accepted-content'>
                    <div className='round-robin-dcl-icon'>
                        <Icon name='dcl-not-fit'/>
                    </div>
                    <p className='heading'>
                      <FormattedMessage id='app.round-robin.pick-a-time.dcl.not-accepted.text-1'/>
                    </p>

                    <p>
                        <FormattedMessage id='app.round-robin.pick-a-time.dcl.not-accepted.text-2'/>
                        <span className='strong'>
                          <FormattedMessage id='app.round-robin.pick-a-time.dcl.not-accepted.text-3'/>
                        </span>
                      <FormattedMessage id='app.round-robin.pick-a-time.dcl.not-accepted.text-4'/>
                    </p>
                    <p>
                        <FormattedMessage id='app.round-robin.pick-a-time.dcl.not-accepted.text-5'/>
                    </p>
                  </div>
                </div>
                <div className='dcl-col-2'>
                    <h3>
                        <FormattedMessage id='app.round-robin.pick-a-time.dcl.not-accepted.description'/>
                    </h3>
                    <p>
                        <FormattedMessage
                            id='app.round-robin.pick-a-time.dcl.not-accepted.text-6'/>
                    </p>
                   {props.calendlyWidget}
                </div>
              </div>
            </div>

        </div>
    )

}