import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import CustomerPortalFooter  from './customer-portal-footer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {injectIntl} from "react-intl";
import {Link} from 'react-scroll'

import {useDispatch, useSelector} from "react-redux";
import {pageCompleted} from '../../actions/cp-flow-actions'
import CustomerPortalProgressBar from "../helpers/CustomerPortalProgressBar";
import CustomerPortalNav from "./customer-portal-nav";
import {useHistory} from "react-router-dom";
import CustomerPortalContact from "./customer-portal-contact";


function CustomerPortalContractPreview(props) {

    const edsContract = useSelector(state => state.edsContract)

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect( () => {
      window.scrollTo(0, 0)
    }, [])

    const goBackToDetailsThreePage = (componentName) => {
      history.push('/customer-portal/contracts/details-three')
    }

    const goToSignPage = (componentName) => {
      dispatch(pageCompleted(4))
      history.push('/customer-portal/contracts/sign')
    }

    const handleViewSteps = (e) => {
      e.preventDefault()
      history.push('/customer-portal/contracts/view-all-steps')
    }

    return(
      <div id='customer-portal'>
        <CustomerPortalNav />
        <div id='customer-portal-content'>
        <div id='customer-portal-contracts' className='customer-portal-pages'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <div className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.customer-portal.contracts-header'/>
            </div>
          </div>

          <div className='customer-portal-dashboard-container'>

            <div className='customer-portal-back-link' onClick={goBackToDetailsThreePage}>
              <FormattedMessage id='app.customer-portal-common.back-link'/>
            </div>
            <h2><FormattedMessage id='app.customer-portal-contracts.about-you.heading'/></h2>
            <div className='contract-content-wrapper'>
              <div className='contract-content-intro-wrapper'>
                <div className='contract-content-intro-text'>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you.text-1'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you-text-2'/>
                  </div>
                </div>
                <CustomerPortalProgressBar pageNum={4}
                                           handleViewSteps={(e) => handleViewSteps(e)}
                  />
              </div>

              <div className='jump-to-section' id='contract-top'>
                <div className='jump-to-caret'><FontAwesomeIcon icon={['fas', 'chevron-down']} />
                  </div>
                <div className='jump-to-text'>

                  <Link  to="contract-bottom" spy={true} smooth={true}>
                    <FormattedMessage id='app.customer-portal-home.contracts.jump-to-bottom'/>
                  </Link>

                </div>
                <div className='jump-to-caret'><FontAwesomeIcon icon={['fas', 'chevron-down']} /></div>
              </div>
              <div className='no-background'>
                <div className='contracts-form-wrapper'>
                  <div className='contract-item'>
                    <div className='contracts-preview-page-block'>
                      <div dangerouslySetInnerHTML={{__html: edsContract.contractHtml}} />
                    </div>
                  </div>
                </div>
                <div className='submit-button-round continue'>
                  <button
                    className="btn btn-primary btn-block"
                    id="visit-onboarding"
                    onClick={goToSignPage}>
                    <FormattedMessage id='app.common.continue.button'/>
                  </button>
                </div>
                <div className='jump-back-to-top-container' id='contract-bottom'>
                  <div className='miss-something'>
                    <FormattedMessage id='app.customer-portal-home.contracts.miss-something'/>
                  </div>
                  <div className='jump-to-section back-to-top'>
                    <div className='jump-to-caret'><FontAwesomeIcon icon={['fas', 'chevron-up']} /></div>
                    <div className='jump-to-text'>
                      <Link  to="contract-top" spy={true} smooth={true}>
                        <FormattedMessage id='app.customer-portal-home.contracts.jump-to-top'/>
                      </Link>
                    </div>
                    <div className='jump-to-caret'><FontAwesomeIcon icon={['fas', 'chevron-up']} /></div>

                  </div>
                  <div className='miss-something-small-text'>
                    <FormattedMessage id='app.customer-portal-home.contracts.miss-something-2'/>
                  </div>

                </div>
              </div>
            </div>

            <CustomerPortalFooter/>
            <CustomerPortalContact />

          </div>
        </div>
        </div>
      </div>
    )
}

export default (injectIntl(CustomerPortalContractPreview))