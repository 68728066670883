import {CREATE_SUCCESS_STORY} from '../actions/success-stories-actions'

function createSuccessStory(state = [], action) {
  switch (action.type) {
    case CREATE_SUCCESS_STORY:
      return action.createSuccessStory
    default:
      return state
  }
}

export default createSuccessStory
