import React from 'react'
import Icon from '../../../resources/icons'
import FormattedMessage from '../helpers/FormattedMessage'
import {CSSTransition} from 'react-transition-group'

export default class ActionPlanMobileHome extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      startTextFadeTransition: false,
      displayText: true,
      startNavTransition: false,
    }
  }

  onLogout(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.props.logout();
  }

  onLocalToggle(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.props.localeToggle();
  }

  employerImage(code) {
    return (
        <div className='action-plan-nav-employer-logo'>
          <img
              src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/Customer_Logos/${this.props.registration.employerCode}.png`}
              width="auto" height="auto"
              alt={`${this.props.employer.displayName}`}
          />
        </div>
    )
  }

  render() {
      return (
        <div className='container-mobile-home'>
          <CSSTransition
            in={this.state.startNavTransition}
            timeout={500}
            classNames="nav-transition"
            onEntered={() => {
            }}
          >
              <div >
                <div className='login-landing-page'>
                  <div className='nav-header'>
                    <div className='nav-logo-wrapper margin-bottom-8-px'>
                      <div className='nav-logo margin-bottom-8-px'>
                        <a href='/action-plan'>
                          <Icon name="tp-full-logo"/>
                        </a>
                      </div>
                    </div>
                    <div className='nav-name margin-bottom-16-px'>
                      <FormattedMessage id='app.action-plan-nav.welcome-name'/> {this.props.firstName}!
                    </div>
                    <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                      <CSSTransition
                        in={this.state.startTextFadeTransition}
                        timeout={500}
                        classNames="nav-fade"
                        onEntered={() => {
                          this.setState({
                            startTextFadeTransition: false,
                            displayText: false,
                            startNavTransition: true
                          });
                        }}
                      >
                        <div>
                        </div>
                      </CSSTransition>
                    </div>
                  </div>


                  <div className='nav-pages-container'>

                    <div className='nav-page-box' ref='ActionPlanTakeActionToday'>
                      <a href='/action-plan/take-action-today' >

                        <div className='nav-page-white-box-container'>

                          <div className='nav-page-logo-wrapper'>

                            <div className='nav-page-logo'>
                              <Icon fill="#000000" name='clipboard'/>
                            </div>

                          </div>
                          <div className='nav-page-name-wrapper'>
                            <div className='nav-page-name'>
                              <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                <CSSTransition
                                in={this.state.startTextFadeTransition}
                                timeout={500}
                                classNames="nav-fade"
                                onEntered={() => {
                                  this.setState({
                                    startTextFadeTransition: false,
                                    displayText: false
                                  });
                                }}
                                >
                                  <FormattedMessage id='app.action-plan-nav.take-action-today'/>
                                </CSSTransition>
                              </div>
                            </div>
                          </div>

                        </div>
                      </a>
                    </div>

                    <div className='nav-page-box' ref='ActionPlanDebtSummary'>
                      <a href='/action-plan/debt-summary' >

                        <div className='nav-page-white-box-container'>

                          <div className='nav-page-logo-wrapper'>

                            <div className='nav-page-logo'>
                              <Icon fill="#000000" name='chartpie'/>
                            </div>

                          </div>
                          <div className='nav-page-name-wrapper'>
                            <div className='nav-page-name'>
                              <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                <CSSTransition
                                in={this.state.startTextFadeTransition}
                                timeout={500}
                                classNames="nav-fade"
                                onEntered={() => {
                                  this.setState({
                                    startTextFadeTransition: false,
                                    displayText: false
                                  });
                                }}
                                >
                                  <FormattedMessage id='app.action-plan-nav.debt-summary'/>
                                </CSSTransition>
                              </div>
                            </div>
                          </div>

                        </div>
                      </a>
                    </div>

                    { this.props.employer.code === 'h310' ?
                      '' :
                      <div className='nav-page-box' ref='ActionPlanFicoScore'>
                        <a href='/action-plan/fico-score'>

                          <div className='nav-page-white-box-container'>

                            <div className='nav-page-logo-wrapper'>

                              <div className='nav-page-logo'>
                                <Icon fill="#000000" name='tachometer'/>
                              </div>

                            </div>
                            <div className='nav-page-name-wrapper'>
                              <div className='nav-page-name'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                  in={this.state.startTextFadeTransition}
                                  timeout={500}
                                  classNames="nav-fade"
                                  onEntered={() => {
                                    this.setState({
                                      startTextFadeTransition: false,
                                      displayText: false
                                    });
                                  }}
                                  >
                                    <FormattedMessage id='app.action-plan-nav.fico-score'/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>

                          </div>
                        </a>
                      </div>
                    }

                    {this.props.latestServiceProgram === "Getting Ahead" ?
                      '' :
                      <div className='nav-page-box' ref='ActionPlanSessions'>
                        <a href='/action-plan/sessions'>

                          <div className='nav-page-white-box-container'>

                            <div className='nav-page-logo-wrapper'>

                              <div className='nav-page-logo'>
                                <Icon fill="#000000" name='calendar'/>
                              </div>

                            </div>
                            <div className='nav-page-name-wrapper'>
                              <div className='nav-page-name'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                    in={this.state.startTextFadeTransition}
                                    timeout={500}
                                    classNames="nav-fade"
                                    onEntered={() => {
                                      this.setState({
                                        startTextFadeTransition: false,
                                        displayText: false
                                      });
                                    }}
                                  >
                                    <FormattedMessage id='app.action-plan-nav.sessions'/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>

                          </div>
                        </a>
                      </div>
                    }

                    <div className='nav-page-box' ref='ActionPlanBudget'>
                      <a href='/action-plan/budget'>

                        <div className='nav-page-white-box-container'>

                          <div className='nav-page-logo-wrapper'>

                            <div className='nav-page-logo'>
                              <Icon fill="#000000" name='money-bill'/>
                            </div>

                          </div>
                          <div className='nav-page-name-wrapper'>
                            <div className='nav-page-name'>
                              <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                <CSSTransition
                                in={this.state.startTextFadeTransition}
                                timeout={500}
                                classNames="nav-fade"
                                onEntered={() => {
                                  this.setState({
                                    startTextFadeTransition: false,
                                    displayText: false
                                  });
                                }}
                                >
                                  <FormattedMessage id='app.action-plan-nav.budget'/>
                                </CSSTransition>
                              </div>
                            </div>
                          </div>

                        </div>
                      </a>
                    </div>
                  </div>
                  {this.props.employer.displayLogoInTrustPlusApp && this.employerImage(this.props.employer.code)}
                  <div className='footer-ap footer-nav-style'>
                    <div className='nav-page-blue-box-wrapper'>

                      <div className='nav-page-box-blue'>
                        <a href='#' onClick={ e => this.onLocalToggle(e) }>
                          <div className='nav-page-white-box-container'>
                            <div className='nav-page-logo-wrapper'>
                              <div className='nav-page-logo'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                    in={this.state.startTextFadeTransition}
                                    timeout={500}
                                    classNames="nav-fade"
                                    onEntered={() => {
                                      this.setState({
                                        startTextFadeTransition: false,
                                        displayText: false
                                      });
                                    }}>
                                    <Icon fill="#ffffff " name="globe-americas"/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>
                            <div className='nav-page-name-wrapper'>
                              <div className='nav-page-name-utility'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                    in={this.state.startTextFadeTransition}
                                    timeout={500}
                                    classNames="nav-fade"
                                    onEntered={() => {
                                      this.setState({
                                        startTextFadeTransition: false,
                                        displayText: false
                                      });
                                    }}>
                                    <FormattedMessage id='app.action-plan-nav.espanol'/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className='nav-page-box-blue'>
                        <a href='/action-plan/feedback'>
                          <div className='nav-page-white-box-container'>
                            <div className='nav-page-logo-wrapper'>
                              <div className='nav-page-logo'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                      in={this.state.startTextFadeTransition}
                                      timeout={500}
                                      classNames="nav-fade"
                                      onEntered={() => {
                                        this.setState({
                                          startTextFadeTransition: false,
                                          displayText: false
                                        });
                                      }}>
                                    <Icon fill="#ffffff " name="speech-bubble"/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>
                            <div className='nav-page-name-wrapper'>
                              <div className='nav-page-name-utility'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                      in={this.state.startTextFadeTransition}
                                      timeout={500}
                                      classNames="nav-fade"
                                      onEntered={() => {
                                        this.setState({
                                          startTextFadeTransition: false,
                                          displayText: false
                                        });
                                      }}>
                                    <FormattedMessage id='app.action-plan-nav.feedback'/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className='nav-page-box-blue'>
                        <a href='/action-plan/account'>
                          <div className='nav-page-white-box-container'>
                            <div className='nav-page-logo-wrapper'>
                              <div className='nav-page-logo'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                    in={this.state.startTextFadeTransition}
                                    timeout={500}
                                    classNames="nav-fade"
                                    onEntered={() => {
                                      this.setState({
                                        startTextFadeTransition: false,
                                        displayText: false
                                      });
                                    }}>
                                    <Icon fill="#ffffff " name="cog"/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>
                            <div className='nav-page-name-wrapper'>
                              <div className='nav-page-name-utility'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                    in={this.state.startTextFadeTransition}
                                    timeout={500}
                                    classNames="nav-fade"
                                    onEntered={() => {
                                      this.setState({
                                        startTextFadeTransition: false,
                                        displayText: false
                                      });
                                    }}>
                                    <FormattedMessage id='app.action-plan-nav.my-account'/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className='nav-page-box-blue'>
                        <a href='#' onClick={ e => this.onLogout(e) }>
                          <div className='nav-page-white-box-container'>
                            <div className='nav-page-logo-wrapper'>
                              <div className='nav-page-logo'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                    in={this.state.startTextFadeTransition}
                                    timeout={500}
                                    classNames="nav-fade"
                                    onEntered={() => {
                                      this.setState({
                                        startTextFadeTransition: false,
                                        displayText: false
                                      });
                                    }}>
                                    <Icon fill="#ffffff " name="sign-out"/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>
                            <div className='nav-page-name-wrapper'>
                              <div className='nav-page-name-utility'>
                                <div className={`${this.state.displayText ? 'opacity-all' : 'opacity-none'}`}>
                                  <CSSTransition
                                    in={this.state.startTextFadeTransition}
                                    timeout={500}
                                    classNames="nav-fade"
                                    onEntered={() => {
                                      this.setState({
                                        startTextFadeTransition: false,
                                        displayText: false
                                      });
                                    }}>
                                    <FormattedMessage id='app.action-plan-nav.sign-out'/>
                                  </CSSTransition>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <p>
                      <a href='/action-plan/terms-of-service'>
                        <FormattedMessage id='app.action-plan-nav.terms-of-service'/>
                      </a>
                    </p>
                    <p>
                      <a href='/action-plan/privacy-policy'>
                        <FormattedMessage id='app.action-plan-nav.privacy-policy'/>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
          </CSSTransition>
        </div>)
    }
  }
