import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import FormError from './form-error'
import HeaderContainerAP from '../containers/header-container-ap'

const alertMessage = (alertName) => {
  return <FormattedMessage id={alertName}/>
}

// const titleMessage = (title) => {
//   return <FormattedMessage id={title}/>
// }

const giveMeNumber = (progressPercentages) => {
  let val = progressPercentages.schedule_appointment + progressPercentages.pull_credit_report
    + progressPercentages.additional_questions_1 + progressPercentages.additional_questions_2 +
    progressPercentages.additional_questions_3 + progressPercentages.additional_questions_4 +
    progressPercentages.additional_questions_5 + progressPercentages.additional_questions_6 +
    progressPercentages.additional_questions_7
  return val
}

export default function Header({title, displayProgressBar=false, progressBarPercentage, progressBarCondensed=true, displayAlert=false, alertName=''}) {
  return (
    <div className='header'>

        <HeaderContainerAP/>
      {/*<div className='page-header'>*/}
        {/*<div className='container'>*/}
          {/*<h3>*/}
            {/*{titleMessage(title)}*/}
          {/*</h3>*/}
        {/*</div>*/}
      {/*</div>*/}

      {
        displayProgressBar == true && progressBarCondensed == true ?
          <div className='progress-bar-wrapper'>
            <div className={`rectangle-blue progress-width-${giveMeNumber(progressBarPercentage)}`}></div>
            <div className='rectangle-gray'></div>
          </div> :
          <div/>
      }

      {
        displayAlert == true ?
          <div className='header-alert'>
            <div className='container ptxxl'>
              <FormError error={alertMessage(alertName)}/>
            </div>
          </div> :
          <div/>
      }

      {
        displayProgressBar == true && progressBarCondensed == false ?
          <div className='container'>
            <h4>
              <FormattedMessage id='app.schedule-confirmation.continue.title'/>
            </h4>
            <div className='progress-bar-wrapper'>
              <div className={`rectangle-blue-round progress-width-${giveMeNumber(progressBarPercentage)}`}></div>
              <div className='rectangle-gray-round'></div>
            </div>
            <p>
              <br/>
            </p>
            <div className={`percent-complete-wrapper progress-margin-left-${giveMeNumber(progressBarPercentage)}`}>
              <div className='triangle-adjust'>
                <div className='triangle'></div>
                <div className='percent-complete-text'>
                  <h5>
                  {`${giveMeNumber(progressBarPercentage)}`}% <FormattedMessage id='app.schedule-confirmation.percent.complete-text'/>
                  </h5>
                </div>
              </div>
            </div>
          </div> :
          <div/>
      }
    </div>
  )
}
