import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";
import Icon from "../../../resources/icons";

function IntroductionHowItWorks(props) {

  return(
    <div className='how-it-works'>
      <div className='landing-container'>
        <h2><FormattedMessage id='app.landing-page.how-it-works.title'/></h2>
        <div className='how-it-works-container'>
          <div className='how-it-works-icon-container'>
            <div className='how-it-works-icon-wrapper'>
              <Icon name="how-it-works-phone"/>
            </div>
            <div className='how-it-works-text-wrapper'>
              <FormattedMessage id='app.landing-page.how-it-works.text-1'/>
            </div>
          </div>
          <div className='how-it-works-icon-container how-it-works-reverse'>
            <div className='how-it-works-icon-wrapper'>
              <Icon name="how-it-works-goals"/>
            </div>
            <div className='how-it-works-text-wrapper'>
              <FormattedMessage id='app.landing-page.how-it-works.text-2'/>
            </div>
          </div>
          <div className='how-it-works-icon-container'>
            <div className='how-it-works-icon-wrapper'>
              <Icon name="how-it-works-talk-with-coach"/>
            </div>
            <div className='how-it-works-text-wrapper'>
              <FormattedMessage id='app.landing-page.how-it-works.text-3'/>
            </div>
          </div>
          <div className='how-it-works-icon-container how-it-works-reverse'>
            <div className='how-it-works-icon-wrapper'>
              <Icon name="how-it-works-confidential"/>
            </div>
            <div className='how-it-works-text-wrapper'>
              <FormattedMessage id='app.landing-page.how-it-works.text-4'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default IntroductionHowItWorks