import {RANDOM_COUNSELOR} from '../actions/counselor-actions'
import {CHANGE_LOCALE} from "../actions/locale-actions";

const randomCounselor = (state = null, action) => {
  switch (action.type) {
    case RANDOM_COUNSELOR:
      return action.randomCounselorPick
    default:
      return state
  }
}

export default randomCounselor
