import {
  SUBMIT_LOGIN,
  SUBMIT_EMAIL,
  SUBMIT_TEMPCODE,
  SUBMIT_CREATE_PASS,
  SUBMIT_RESET_PASS,
  SUBMIT_RESEND_TEMPCODE,
  SUBMIT_CHANGE_PASS,
  SUBMIT_CHANGE_PHONE,
  SUBMIT_CHANGE_EMAIL,
  VERIFY_TOKEN,
  SUBMIT_EMAIL_TEMPCODE,
  CREATE_POSTGRES_RECORD,
  SAML_RELAY
} from '../actions/login-actions'
import {CHECK_LOGIN_RECORD, SUBMIT_NEW_TEMPCODE, GET_OR_CREATE_LOGIN_RECORD, RESET_LOGIN} from "../actions/b-flow-actions";
import {UPLOAD_FILE} from "../actions/customer-actions";
import {
  SUBMIT_CREATE_ENROLLMENT,
  SUBMIT_NEW_ENROLLMENT_CODE,
  VERIFY_ENROLLMENT_CODE
} from "../actions/enrollment-actions";

const login = (state = null, action) => {
  switch (action.type) {
    case SUBMIT_LOGIN:
      return action.login
    case SUBMIT_EMAIL:
      return action.login
    case SUBMIT_TEMPCODE:
      return action.login
    case SUBMIT_CREATE_PASS:
      return action.login
    case SUBMIT_RESET_PASS:
      return action.login
    case SUBMIT_RESEND_TEMPCODE:
      return action.login
    case SUBMIT_CHANGE_PASS:
      return action.login
    case SUBMIT_CHANGE_PHONE:
      return action.login
    case SUBMIT_CHANGE_EMAIL:
      return action.login
    case VERIFY_TOKEN:
      return action.login
    case SUBMIT_EMAIL_TEMPCODE:
      return action.login
    case CHECK_LOGIN_RECORD:
      return action.login
    case SUBMIT_NEW_TEMPCODE:
      return action.login
    case GET_OR_CREATE_LOGIN_RECORD:
      return action.login
    case RESET_LOGIN:
      return action.login
    case CREATE_POSTGRES_RECORD:
      return action.login
    case SAML_RELAY:
      return action.login
    case UPLOAD_FILE:
      return action.login
    case SUBMIT_CREATE_ENROLLMENT:
      return action.login
    case VERIFY_ENROLLMENT_CODE:
      return action.login
    case SUBMIT_NEW_ENROLLMENT_CODE:
      return action.login
    default:
      return state
  }
}

export default login
