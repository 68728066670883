import React from 'react'

export const TrustEd = ({
                          style = {},
                          fill = '#fff',
                          width = '100%',
                          className = '',
                          height = '100%',
                          viewBox = '0 0 388 102',
                        }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g id="Page-13" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="trusted-Wide" transform="translate(-228.000000, -3269.000000)">
        <g id="Group-32" transform="translate(228.000000, 3269.000000)">
          <polygon className="trust-ed-logo-cls-1" id="Fill-1" fill="#002E5A" points="70.9493722 53.5220726 70.9493722 100.376652 58.560851 100.376652 58.560851 53.5220726 41.4294969 53.5220726 41.4294969 41.5956926 88.0840238 41.5956926 88.0840238 53.5220726"></polygon>
          <g id="Group-31" transform="translate(0.000000, 0.045787)">
            <path className="trust-ed-logo-cls-2" d="M117.589171,67.8321946 C109.466451,67.8321946 104.479607,72.9576538 104.479607,83.7050461 L104.479607,100.333066 L92.2504623,100.333066 L92.2504623,55.3223752 L104.479607,55.3223752 L104.479607,64.3932052 C106.973578,58.1768245 110.99427,54.9855216 118.23657,55.3212744 L118.23657,67.8321946 L117.589171,67.8321946 Z" id="Fill-2" fill="#002E5A"></path>
            <path className="trust-ed-logo-cls-3" d="M153.622829,100.331415 L153.622829,93.94991 C150.804608,97.7290555 147.188405,100.333617 140.995793,100.333617 C131.746444,100.333617 126.354014,94.7898425 126.354014,84.4629668 L126.354014,55.3196232 L138.582059,55.3196232 L138.582059,80.428429 C138.582059,86.4741811 141.317844,89.5840227 145.982637,89.5840227 C150.64743,89.5840227 153.622829,86.4741811 153.622829,80.428429 L153.622829,55.3196232 L165.850875,55.3196232 L165.850875,100.331415 L153.622829,100.331415 Z" id="Fill-4" fill="#002E5A"></path>
            <path className="trust-ed-logo-cls-4" d="M192.708059,100.333507 C186.675923,100.333507 179.919447,99.0719567 174.206065,94.3692157 L179.433623,85.9731939 C184.102812,89.4958463 189.00832,91.2175427 193.03011,91.2175427 C196.569373,91.2175427 198.17743,90.0033284 198.17743,87.9888116 L198.17743,87.8203848 C198.17743,85.0451952 193.998461,84.1215997 189.250133,82.614565 C183.2136,80.7640717 176.377985,77.8292619 176.377985,69.093084 L176.377985,68.925758 C176.377985,59.7690634 183.45871,55.3206139 192.145294,55.3206139 C197.614665,55.3206139 203.569861,56.5788613 208.234654,59.8571297 L203.569861,68.6725673 C199.307356,66.0702079 195.040456,64.4751068 191.90458,64.4751068 C188.928082,64.4751068 187.400263,65.8181181 187.400263,67.5816459 L187.400263,67.7489719 C187.400263,70.2676684 191.501192,71.4444544 196.165985,73.1232185 C202.202518,75.2213983 209.199708,78.2453752 209.199708,86.4729702 L209.199708,86.641397 C209.199708,96.6413265 202.040943,100.333507 192.708059,100.333507" id="Fill-6" fill="#002E5A"></path>
            <path className="trust-ed-logo-cls-5" d="M229.451219,100.333507 C221.970403,100.333507 216.900024,97.9777328 216.900024,87.5660933 L216.900024,41.5492447 L229.129168,41.5492447 L229.129168,55.3217147 L239.26663,55.3217147 L239.26663,66.236433 L229.129168,66.236433 L229.129168,85.4679135 C229.129168,88.410429 230.336035,89.8382041 233.069622,89.8382041 C235.32178,89.8382041 237.333225,89.247059 239.105055,88.2431031 L239.105055,98.4819124 C236.531944,100.078114 233.552148,100.333507 229.451219,100.333507" id="Fill-8" fill="#002E5A"></path>
            <path className="trust-ed-logo-cls-6"d="M70.9503615,29.6214336 L41.429387,29.6214336 L41.429387,0.0564725212 C57.733081,0.0564725212 70.9503615,13.2928389 70.9503615,29.6214336" id="Fill-10" fill="#47BFAF"></path>
            <path className="trust-ed-logo-cls-7" d="M29.5207547,0.0560321897 L29.5207547,0.0560321897 L29.5207547,29.6209933 L0.000879320113,29.6209933 C0.000879320113,13.2923986 13.2170606,0.0560321897 29.5207547,0.0560321897" id="Fill-12" fill="#47BFAF"></path>
            <polygon className="trust-ed-logo-cls-8"  id="Fill-14" fill="#47BFAF" points="0.000439660057 41.5489144 29.520315 41.5489144 29.520315 71.1149764"></polygon>
            <polygon className="trust-ed-logo-cls-9"  id="Fill-28" fill="#002E5A" points="297.181071 43.9290166 337.260482 43.9290166 337.260482 49.6401168 303.438533 49.6401168 303.438533 68.5985916 333.694839 68.5985916 333.694839 74.3107927 303.438533 74.3107927 303.438533 93.7470273 337.655076 93.7470273 337.655076 99.4570266 297.181071 99.4570266"></polygon>
            <path className="trust-ed-logo-cls-10" d="M381.532271,78.9920674 L381.532271,78.8313464 C381.532271,69.3928397 374.325143,63.0465612 366.721223,63.0465612 C358.799648,63.0465612 352.304769,68.9172816 352.304769,78.8313464 L352.304769,78.9920674 C352.304769,88.6694541 359.037064,94.8561123 366.721223,94.8561123 C374.325143,94.8561123 381.532271,88.4305742 381.532271,78.9920674 M346.048407,79.0702263 L346.048407,78.9128078 C346.048407,65.5058129 355.790175,57.5721392 365.61218,57.5721392 C373.216101,57.5721392 378.126005,61.6980458 381.293755,66.2995105 L381.293755,41.5484741 L387.392939,41.5484741 L387.392939,99.457577 L381.293755,99.457577 L381.293755,91.2079655 C377.968826,96.1242672 373.135863,100.330534 365.61218,100.330534 C355.790175,100.330534 346.048407,92.55538 346.048407,79.0702263" id="Fill-29" fill="#002E5A"></path>
            <polygon className="trust-ed-logo-cls-11"  id="Fill-30" fill="#030100" points="262.898028 81.5893631 244.095966 81.5893631 244.095966 75.0383305 262.898028 75.0383305 262.898028 56.2967189 269.710561 56.2967189 269.710561 75.0383305 288.514822 75.0383305 288.514822 81.5893631 269.710561 81.5893631 269.710561 100.329874 262.898028 100.329874"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>

export default TrustEd