import {SPANISH_CURRENTLY_WORKING_COUNSELORS_RETURNED} from '../actions/counselor-actions'

function spanishCurrentlyWorkingCounselorsReturned(state = false, action) {
    switch (action.type) {
        case SPANISH_CURRENTLY_WORKING_COUNSELORS_RETURNED:
            return action.spanishCurrentlyWorkingCounselorsReturned
        default:
            return state
    }
}

export default spanishCurrentlyWorkingCounselorsReturned