import React, {useEffect} from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import CreditReportFormContainer from '../../containers/credit-report-form-container'
import HeaderContainer from '../../containers/header-container'
import FooterContainer from "../../containers/footer-container";
import {reduxForm} from "redux-form";
import {connect} from 'react-redux'
import ValidationService from "../../services/validation-service";
import {debtReportAttempt} from "../../actions/debt-report-actions";
import {update, getContact, getContactWithEmail} from "../../actions/registration-actions";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

const isSubmitDisabled = (props) => {
    return props.pristine ||
        props.submitting ||
        !props.valid ||
        props.error === 'app.errors.contact.update'
}

function BFlowCreditReportFormPage (props) {

    const login = useSelector(state => state.login);
    const registration = useSelector(state => state.registration);
    const showAlert = useSelector(state => state.displayAlert);
    const nameOfAlert = useSelector(state => state.alertName);
    const employer = useSelector(state => state.employer);
    const updateRegistrationCompleted = useSelector(state => state.updateRegistrationCompleted)

    const dispatch = useDispatch();

    useEffect(() => {
        if (registration === null){
            props.history.push(`/select-by-availability`)
        }
    })

    useEffect(() =>{
      if (registration !== null && registration.clientSmLink === null)  {
        dispatch(getContact(registration.id));
      }
    })

    useEffect(() => {
      if (updateRegistrationCompleted === true) {
        goToSurveyMonkey()
      }
    }, [updateRegistrationCompleted]);

    const showSkipLink = () => {
      return employer.code === 'h310' && registration.clientSmLink !== null ? <div className='text-ap additional-links margin-bottom-16-px'>
        <Link to='#'
              id='skip'
              onClick={ (e)=> goToSurveyMonkeyLink(e) }>
          <FormattedMessage id='app.b-flow.pull-credit-report.text.skip'/>
        </Link>
      </div> : null
    }

  function goToSurveyMonkeyLink(e) {
    e.preventDefault()
    e.stopPropagation()
    goToSurveyMonkey()
  }

    function goToSurveyMonkey() {
      let link = registration.clientSmLink
      if (link !== null) {
        window.location.href = link;
      }
    }

    return (
      <div className='b-flow'>
          <HeaderContainer
            displayAlert={showAlert}
            alertName={nameOfAlert}/>

          <div className='container-ap ptxxl'>

              <h1 className='action-plan-subtitle margin-bottom-24-px'>
                  <FormattedMessage id='app.b-flow.credit-report-form-page.title'/>
              </h1>

              <p>
                  <FormattedMessage id='app.b-flow.credit-report-form-page.text'/>
              </p>
            <p>
              <FormattedMessage id='app.b-flow.credit-report-form-page.text-2'/>
            </p>

              <form onSubmit={props.handleSubmit}>

                  <CreditReportFormContainer/>

                  <div className='submit-button-round margin-bottom-24-px'>
                      <button id='pull-report'
                              type={'submit'}
                              className='btn btn-primary btn-block btn-lg'
                              disabled={isSubmitDisabled(props)}>
                          <FormattedMessage id='app.b-flow.credit-report-form-page.button.continue'/>
                      </button>
                  </div>

                  { showSkipLink() }

                  <div className='verified-and-secured'>
                      <div className='goDaddySecuritySeal'/>
                  </div>
              </form>


          </div>
          <FooterContainer/>
      </div>
    )
}

const mapStateToProps = (state) => {
  return {
    registration: state.registration
  }
}



const UpdateContact =  reduxForm({
    form: 'registration',
    validate:ValidationService.validateDebtSummaryCreditReportFormFields,
    onSubmit: (values, dispatch, props) => {
        dispatch(debtReportAttempt(true))
        dispatch(update(values))
    },
    destroyOnUnmount: false
})(injectIntl(BFlowCreditReportFormPage))

export default connect(mapStateToProps)(UpdateContact)
