import React from 'react'

export const FinancialEmpowermentLogo = ({
                             style = {},
                             fill = '#fff',
                             width = '100%',
                             className = '',
                             height = '100%',
                             viewBox = '0 0 80.14 91.09',
                           }) =>
<svg
    width="244px"
    height="38px"
    viewBox="0 0 244 38"
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
>
  <title>THE ROAD TO FINANCIA</title>
  <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="newlanding_w1440" transform="translate(-1116.000000, -2484.000000)" fill-rule="nonzero">
      <g id="THE-ROAD-TO-FINANCIA" transform="translate(1116.480000, 2484.456000)">
        <polygon id="Path" fill="#141006" points="2.664 8.544 4.152 8.544 4.152 1.512 6.816 1.512 6.816 0.144 0 0.144 0 1.512 2.664 1.512"/>
        <polygon id="Path" fill="#141006" points="12.376 8.544 13.852 8.544 13.852 5.004 17.86 5.004 17.86 8.544 19.336 8.544 19.336 0.144 17.86 0.144 17.86 3.636 13.852 3.636 13.852 0.144 12.376 0.144"/>
        <polygon id="Path" fill="#141006" points="25.496 8.544 31.784 8.544 31.784 7.224 26.972 7.224 26.972 4.968 31.184 4.968 31.184 3.648 26.972 3.648 26.972 1.464 31.724 1.464 31.724 0.144 25.496 0.144"/>
        <path d="M46.4186667,8.544 L47.8946667,8.544 L47.8946667,5.616 L49.7426667,5.616 L51.8066667,8.544 L53.5586667,8.544 L51.2906667,5.364 C52.4546667,5.028 53.2826667,4.2 53.2826667,2.82 L53.2826667,2.796 C53.2826667,2.064 53.0306667,1.44 52.5866667,0.984 C52.0466667,0.456 51.2186667,0.144 50.1626667,0.144 L46.4186667,0.144 L46.4186667,8.544 Z M47.8946667,4.308 L47.8946667,1.488 L50.0426667,1.488 C51.1346667,1.488 51.7826667,1.98 51.7826667,2.88 L51.7826667,2.904 C51.7826667,3.756 51.1106667,4.308 50.0546667,4.308 L47.8946667,4.308 Z" id="Shape" fill="#141006"/>
        <path d="M63.1026667,8.688 C65.6826667,8.688 67.5066667,6.708 67.5066667,4.344 L67.5066667,4.32 C67.5066667,1.956 65.7066667,0 63.1266667,0 C60.5466667,0 58.7226667,1.98 58.7226667,4.344 L58.7226667,4.368 C58.7226667,6.732 60.5226667,8.688 63.1026667,8.688 Z M63.1266667,7.32 C61.4586667,7.32 60.2706667,5.976 60.2706667,4.344 L60.2706667,4.32 C60.2706667,2.688 61.4346667,1.356 63.1026667,1.356 C64.7706667,1.356 65.9586667,2.712 65.9586667,4.344 L65.9586667,4.368 C65.9586667,6 64.7946667,7.32 63.1266667,7.32 Z" id="Shape" fill="#141006"/>
        <path d="M72.0466667,8.544 L73.5586667,8.544 L74.4226667,6.516 L78.3946667,6.516 L79.2466667,8.544 L80.8066667,8.544 L77.1106667,0.084 L75.7426667,0.084 L72.0466667,8.544 Z M74.9626667,5.208 L76.4026667,1.848 L77.8546667,5.208 L74.9626667,5.208 Z" id="Shape" fill="#141006"/>
        <path d="M86.2466667,8.544 L89.3786667,8.544 C92.0186667,8.544 93.8426667,6.708 93.8426667,4.344 L93.8426667,4.32 C93.8426667,1.956 92.0186667,0.144 89.3786667,0.144 L86.2466667,0.144 L86.2466667,8.544 Z M89.3786667,1.488 C91.1426667,1.488 92.2946667,2.7 92.2946667,4.344 L92.2946667,4.368 C92.2946667,6.012 91.1426667,7.2 89.3786667,7.2 L87.7226667,7.2 L87.7226667,1.488 L89.3786667,1.488 Z" id="Shape" fill="#141006"/>
        <polygon id="Path" fill="#141006" points="110.577333 8.544 112.065333 8.544 112.065333 1.512 114.729333 1.512 114.729333 0.144 107.913333 0.144 107.913333 1.512 110.577333 1.512"/>
        <path d="M123.841333,8.688 C126.421333,8.688 128.245333,6.708 128.245333,4.344 L128.245333,4.32 C128.245333,1.956 126.445333,0 123.865333,0 C121.285333,0 119.461333,1.98 119.461333,4.344 L119.461333,4.368 C119.461333,6.732 121.261333,8.688 123.841333,8.688 Z M123.865333,7.32 C122.197333,7.32 121.009333,5.976 121.009333,4.344 L121.009333,4.32 C121.009333,2.688 122.173333,1.356 123.841333,1.356 C125.509333,1.356 126.697333,2.712 126.697333,4.344 L126.697333,4.368 C126.697333,6 125.533333,7.32 123.865333,7.32 Z" id="Shape" fill="#141006"/>
        <polygon id="Path" fill="#141006" points="142.916 8.544 144.392 8.544 144.392 5.124 148.628 5.124 148.628 3.78 144.392 3.78 144.392 1.488 149.168 1.488 149.168 0.144 142.916 0.144"/>
        <polygon id="Path" fill="#141006" points="154.872 8.544 156.348 8.544 156.348 0.144 154.872 0.144"/>
        <polygon id="Path" fill="#141006" points="162.592 8.544 164.044 8.544 164.044 2.568 168.676 8.544 169.912 8.544 169.912 0.144 168.46 0.144 168.46 5.952 163.96 0.144 162.592 0.144"/>
        <path d="M175.352,8.544 L176.864,8.544 L177.728,6.516 L181.7,6.516 L182.552,8.544 L184.112,8.544 L180.416,0.084 L179.048,0.084 L175.352,8.544 Z M178.268,5.208 L179.708,1.848 L181.16,5.208 L178.268,5.208 Z" id="Shape" fill="#141006"/>
        <polygon id="Path" fill="#141006" points="189.552 8.544 191.004 8.544 191.004 2.568 195.636 8.544 196.872 8.544 196.872 0.144 195.42 0.144 195.42 5.952 190.92 0.144 189.552 0.144"/>
        <path d="M206.932,8.688 C208.504,8.688 209.464,8.112 210.34,7.2 L209.392,6.24 C208.672,6.912 208.012,7.32 206.98,7.32 C205.372,7.32 204.208,5.988 204.208,4.344 L204.208,4.32 C204.208,2.676 205.384,1.356 206.98,1.356 C207.94,1.356 208.66,1.776 209.332,2.4 L210.28,1.308 C209.476,0.54 208.54,0 206.992,0 C204.448,0 202.66,1.956 202.66,4.344 L202.66,4.368 C202.66,6.78 204.484,8.688 206.932,8.688 Z" id="Path" fill="#141006"/>
        <polygon id="Path" fill="#141006" points="215.972 8.544 217.448 8.544 217.448 0.144 215.972 0.144"/>
        <path d="M222.972,8.544 L224.484,8.544 L225.348,6.516 L229.32,6.516 L230.172,8.544 L231.732,8.544 L228.036,0.084 L226.668,0.084 L222.972,8.544 Z M225.888,5.208 L227.328,1.848 L228.78,5.208 L225.888,5.208 Z" id="Shape" fill="#141006"/>
        <polygon id="Path" fill="#141006" points="237.172 8.544 243.064 8.544 243.064 7.2 238.648 7.2 238.648 0.144 237.172 0.144"/>
        <polygon id="Path" fill="#EF3E42" points="1.68 36.544 14.256 36.544 14.256 33.904 4.632 33.904 4.632 29.392 13.056 29.392 13.056 26.752 4.632 26.752 4.632 22.384 14.136 22.384 14.136 19.744 1.68 19.744"/>
        <polygon id="Path" fill="#EF3E42" points="21.74 36.544 24.644 36.544 24.644 24.544 29.9 32.416 29.996 32.416 35.3 24.496 35.3 36.544 38.252 36.544 38.252 19.744 35.108 19.744 29.996 27.688 24.884 19.744 21.74 19.744"/>
        <path d="M46.552,36.544 L49.504,36.544 L49.504,31.144 L52.84,31.144 C56.536,31.144 59.56,29.176 59.56,25.408 L59.56,25.36 C59.56,21.976 57.112,19.744 53.176,19.744 L46.552,19.744 L46.552,36.544 Z M49.504,28.48 L49.504,22.432 L52.936,22.432 C55.144,22.432 56.56,23.464 56.56,25.432 L56.56,25.48 C56.56,27.232 55.168,28.48 52.936,28.48 L49.504,28.48 Z" id="Shape" fill="#EF3E42"/>
        <path d="M74.58,36.832 C79.74,36.832 83.388,32.872 83.388,28.144 L83.388,28.096 C83.388,23.368 79.788,19.456 74.628,19.456 C69.468,19.456 65.82,23.416 65.82,28.144 L65.82,28.192 C65.82,32.92 69.42,36.832 74.58,36.832 Z M74.628,34.096 C71.292,34.096 68.916,31.408 68.916,28.144 L68.916,28.096 C68.916,24.832 71.244,22.168 74.58,22.168 C77.916,22.168 80.292,24.88 80.292,28.144 L80.292,28.192 C80.292,31.456 77.964,34.096 74.628,34.096 Z" id="Shape" fill="#EF3E42"/>
        <polygon id="Path" fill="#EF3E42" points="94.568 36.664 97.112 36.664 101.24 24.592 105.368 36.664 107.936 36.664 113.768 19.744 110.672 19.744 106.664 32.2 102.536 19.696 100.04 19.696 95.912 32.2 91.904 19.744 88.712 19.744"/>
        <polygon id="Path" fill="#EF3E42" points="120.772 36.544 133.348 36.544 133.348 33.904 123.724 33.904 123.724 29.392 132.148 29.392 132.148 26.752 123.724 26.752 123.724 22.384 133.228 22.384 133.228 19.744 120.772 19.744"/>
        <path d="M140.832,36.544 L143.784,36.544 L143.784,30.688 L147.48,30.688 L151.608,36.544 L155.112,36.544 L150.576,30.184 C152.904,29.512 154.56,27.856 154.56,25.096 L154.56,25.048 C154.56,23.584 154.056,22.336 153.168,21.424 C152.088,20.368 150.432,19.744 148.32,19.744 L140.832,19.744 L140.832,36.544 Z M143.784,28.072 L143.784,22.432 L148.08,22.432 C150.264,22.432 151.56,23.416 151.56,25.216 L151.56,25.264 C151.56,26.968 150.216,28.072 148.104,28.072 L143.784,28.072 Z" id="Shape" fill="#EF3E42"/>
        <polygon id="Path" fill="#EF3E42" points="162.164 36.544 165.068 36.544 165.068 24.544 170.324 32.416 170.42 32.416 175.724 24.496 175.724 36.544 178.676 36.544 178.676 19.744 175.532 19.744 170.42 27.688 165.308 19.744 162.164 19.744"/>
        <polygon id="Path" fill="#EF3E42" points="186.976 36.544 199.552 36.544 199.552 33.904 189.928 33.904 189.928 29.392 198.352 29.392 198.352 26.752 189.928 26.752 189.928 22.384 199.432 22.384 199.432 19.744 186.976 19.744"/>
        <polygon id="Path" fill="#EF3E42" points="207.036 36.544 209.94 36.544 209.94 24.592 219.204 36.544 221.676 36.544 221.676 19.744 218.772 19.744 218.772 31.36 209.772 19.744 207.036 19.744"/>
        <polygon id="Path" fill="#EF3E42" points="234.104 36.544 237.08 36.544 237.08 22.48 242.408 22.48 242.408 19.744 228.776 19.744 228.776 22.48 234.104 22.48"/>
      </g>
    </g>
  </g>
</svg>

export default FinancialEmpowermentLogo