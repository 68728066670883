import React from 'react';
import FormattedMessage from './helpers/FormattedMessage'
import Field from './helpers/Field'
import FormUserError from './form-user-error'
import FormError from './form-error'
import NormalizationService from '../services/normalization-service'

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
    props.submitting ||
    !props.valid ||
    state.isProcessing;
}

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

const renderTextArea = ({input, placeholder, isDisabled, meta: { touched, error, warning }}) => (
    <div>
      <textarea {...input} placeholder={placeholder} rows="10" cols="40"
      className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
         disabled={isDisabled}/>
      {
        touched && (error &&
          <div className='label-text red'>
            <FormattedMessage id={error}/>
          </div>
        )
      }
    </div>
);

const options = {
  year: 'numeric', month: 'long', day: '2-digit', timeZone: 'UTC'};

export default class TakeActionTodayCreateForm extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      fatalFormError: null
    }
  }

  onCancelClick() {
    this.props.onCancelClick()
  }

  render() {
    const {intl, formSyncErrors}=this.props;

    return (
      <div className='take-action-today-create-container margin-bottom-48-px'>
        <div className='action-plan-sub-heading margin-bottom-8-px'>
          <FormattedMessage id='app.action-plan-take-action-today.create-title'/>
        </div>
        <div className='take-action-today-create-wrapper'>
          <div>
            <FormUserError validationErrors={formSyncErrors} intl={intl}/>
            <FormError error={this.state.fatalFormError}/>
          </div>


          <form onSubmit={this.props.handleSubmit}>

            <div className='form-group'>
              <div className='label-text'>
                <FormattedMessage id='app.action-plan-take-action-today.input.goal-comment'/>
              </div>
              <div className='tat-info'>
                <Field name='tatGoalComment'
                  id='tatGoalComment'
                  component={renderTextArea}
                  placeholder={intl.formatMessage({id: 'app.action-plan-take-action-today.input.goal-comment.placeholder'})}
                  isDisabled={this.state.fatalFormError}/>
                </div>
            </div>

            <div className='form-group'>
              <div className='label-text'>
                <FormattedMessage id='app.action-plan-take-action-today.input.due-date'/>
              </div>
              <div className='tat-info'>
                <Field name='tatGoalDueDate'
                  type='text'
                  id='tatGoalDueDate'
                  component={renderField}
                  placeholder='MM/DD/YYYY'
                  normalize={NormalizationService.date}
                  isDisabled={this.state.fatalFormError}/>
                </div>
            </div>

            <div className='form-group'>
              <div className='label-text'>
                <FormattedMessage id='app.action-plan-take-action-today.input.how-accomplish'/>
              </div>
              <div className='tat-info'>
                <Field name='tatHowToAccomplish'
                  id='tatHowToAccomplish'
                  component={renderTextArea}
                  placeholder={intl.formatMessage({id: 'app.action-plan-take-action-today.input.how-accomplish.placeholder'})}
                  isDisabled={this.state.fatalFormError}/>
                  </div>
            </div>

            <div className='form-group'>
              <div className='label-text'>
                <FormattedMessage id='app.action-plan-take-action-today.input.motivation'/>
              </div>
              <div className='tat-info'>
                <Field name='tatMotivation'
                  id='tatMotivation'
                  component={renderTextArea}
                  placeholder={intl.formatMessage({id: 'app.action-plan-take-action-today.input.motivation.placeholder'})}
                  isDisabled={this.state.fatalFormError}/>
                  </div>
            </div>

            <div className='submit-button-round margin-bottom-24-px'>

              <button id='submit'
                      type={'submit'}
                      className='btn btn-primary btn-lg btn-tat-finish-creating'
                      disabled={isSubmitDisabled(this.props, this.state)}>
                <FormattedMessage id='app.action-plan-take-action-today.create-submit'/>
              </button>
              <br/>
              <button id='submit'
                type={'submit'}
                className='btn btn-default btn-lg btn-tat-finish-cancel'
                onClick={e => this.onCancelClick(e) }>
                <FormattedMessage id='app.action-plan-take-action-today.cancel'/>
              </button>

            </div>


        </form>


        </div>
      </div>
      )
  }

}
