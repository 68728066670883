import React from 'react'

import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'


export default function AccountError()  {


      return (
          <div>
              <HeaderContainerAP/>

              <div className='container-ap welcome-form'>
                <div className='page-title'>
                  <FormattedMessage id='app.account-error.title'/>
                </div>
                <div className='text-ap margin-bottom-32-px'>
                  <FormattedMessage id='app.account-error.instructions'/>
                </div>
              </div>
              <FooterContainerAp/>
          </div>
          )
}
