import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import Footer from '../components/footer'

function mapStateToProps(state) {
  return {
    employer: state.employer
  }
}

const FooterContainer = connect(
  mapStateToProps,
  () => ({})
)(injectIntl(Footer))

export default FooterContainer
