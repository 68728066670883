import React from 'react'

export const CovidBrown = ({
                              style = {},
                              fill = '#fff',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 64 64',
                            }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-1" points="0 0.0539282869 16.153192 0.0539282869 16.153192 15.8621833 0 15.8621833"></polygon>
    </defs>
    <g id="Page-5" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <g id="Meet_w650+-(maxwidths)" transform="translate(-914.000000, -396.000000)">
        <g id="Group-16" transform="translate(914.000000, 396.000000)">
          <path d="M41.7233594,54.6709418 L22.0279331,54.6709418 C19.4207618,54.6709418 17.2116143,52.9720096 16.4436143,50.6210932 L4.94732749,48.9372048 C10.6004908,57.8413641 20.5475187,63.7512924 31.8755187,63.7512924 C43.1604271,63.7512924 53.0755825,57.8870056 58.7399649,49.0397068 L47.2745307,50.7192606 C46.4777179,53.0189259 44.2940685,54.6709418 41.7233594,54.6709418" id="Fill-1" fill="#A97852"></path>
          <g id="Group-5" transform="translate(0.000000, 31.623904)">
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-4"></g>
            <path d="M16.153192,7.48328287 L0.00249880478,0.0539282869 C0.00198884462,0.119968127 -5.09960159e-05,0.185752988 -5.09960159e-05,0.251792829 C-5.09960159e-05,5.15480478 1.10809243,9.79850199 3.08571793,13.9480478 L16.153192,15.8621833 L16.153192,7.48328287 Z" id="Fill-3" fill="#A97852" mask="url(#mask-2)"></path>
          </g>
          <path d="M12.6794199,26.342247 C12.3762486,26.6981992 11.9438024,26.8820398 11.5093163,26.8820398 C11.1617785,26.8820398 10.8124558,26.764494 10.5263681,26.5235378 C9.88254343,25.9811952 9.79508526,25.024 10.3341131,24.3773705 C10.4338104,24.2572749 12.8362327,21.4229163 17.5309259,20.0146614 C22.0950693,18.6451633 25.760153,19.0811793 25.913651,19.1005578 C26.7520255,19.2050996 27.346639,19.9697849 27.2420972,20.8081594 C27.1375554,21.6455139 26.3756749,22.2408924 25.5373004,22.1368606 C25.4710056,22.1292112 22.3023681,21.7778486 18.4100972,22.9454024 C14.6427665,24.0754741 12.6985434,26.3198088 12.6794199,26.342247 L12.6794199,26.342247 Z M37.8377944,19.1005578 C37.9915474,19.0811793 41.6566311,18.6454183 46.2205195,20.0146614 C50.9152127,21.4229163 53.3176351,24.2572749 53.4175873,24.3773705 C53.9584,25.0265498 53.8706869,25.9911394 53.2215076,26.5319522 C52.9356749,26.7703586 52.588392,26.8866295 52.242894,26.8866295 C51.8045833,26.8868845 51.3693323,26.6992191 51.0669259,26.3361275 C51.052902,26.3198088 49.1086789,24.0754741 45.3416032,22.9454024 C41.388647,21.75949 38.2488223,22.1327809 38.2172048,22.1366056 C37.3770454,22.2426773 36.6144,21.6465339 36.5093482,20.8081594 C36.4048064,19.9697849 36.9994199,19.2050996 37.8377944,19.1005578 L37.8377944,19.1005578 Z M16.1532685,35.1314104 C16.1532685,31.8865339 18.783388,29.2566693 22.0280096,29.2566693 L41.7234359,29.2566693 C44.9680574,29.2566693 47.5981769,31.8865339 47.5981769,35.1314104 L47.5981769,36.1495458 L63.6020016,28.787761 C62.0486629,12.6322231 48.4380813,0 31.8755952,0 C15.4472287,0 1.92359522,12.428749 0.189730677,28.3958566 L16.1532685,35.7392829 L16.1532685,35.1314104 Z" id="Fill-6" fill="#A97852"></path>
          <path d="M47.5981004,47.5795633 L60.6186582,45.6723124 C62.5975586,41.5574438 63.7169211,36.9519936 63.7485386,32.0882486 L47.5981004,39.5176032 L47.5981004,47.5795633 Z" id="Fill-8" fill="#A97852"></path>
          <path d="M63.6019761,28.78771 L47.5981514,36.1494948 L47.5981514,35.1313594 C47.5981514,31.8864829 44.9680319,29.2566183 41.7234104,29.2566183 L22.0279841,29.2566183 C18.7833625,29.2566183 16.153243,31.8864829 16.153243,35.1313594 L16.153243,35.7392319 L0.189705179,28.3958056 C0.0726693227,29.4746263 0.00917928287,30.5695108 0.0025498008,31.6779092 L16.153243,39.1072637 L16.153243,47.4861641 L3.08576892,45.5720287 C3.63958566,46.7342279 4.26173705,47.8576701 4.94712351,48.9370008 L16.4436653,50.6211442 C17.2116653,52.9720606 19.4208127,54.6709928 22.0279841,54.6709928 L41.7234104,54.6709928 C44.2941195,54.6709928 46.4777689,53.0189769 47.2743267,50.7193116 L58.7400159,49.0397578 C59.4312669,47.9599171 60.0592829,46.835455 60.6187092,45.6722359 L47.5981514,47.5794869 L47.5981514,39.5175267 L63.7485896,32.0881721 C63.7490996,32.0172876 63.7513944,31.9469131 63.7513944,31.8755187 C63.7513944,30.8336701 63.6996335,29.8038056 63.6019761,28.78771" id="Fill-10" fill="#FEFEFE"></path>
          <path d="M38.2172558,22.1365801 C38.2488733,22.1327554 41.388698,21.7594645 45.3413992,22.9453769 C49.1087299,24.0754486 51.052953,26.3197833 51.0669769,26.336102 C51.3693833,26.6994486 51.8046343,26.886859 52.242945,26.886604 C52.588188,26.886604 52.9357259,26.7703331 53.2215586,26.5319267 C53.8707378,25.9911139 53.958196,25.0265243 53.4173833,24.377345 C53.3176861,24.2572494 50.9152637,21.4228908 46.2205705,20.0146359 C41.6564271,18.6456478 37.9915984,19.0814088 37.8378454,19.1005323 C36.9994709,19.2050741 36.4048574,19.9697594 36.5093992,20.8081339 C36.614451,21.6465084 37.3770964,22.2426518 38.2172558,22.1365801" id="Fill-12" fill="#060000"></path>
          <path d="M25.5372239,22.1368351 C26.3755984,22.2408669 27.1374789,21.6454884 27.2422757,20.8081339 C27.3468175,19.9697594 26.752204,19.2050741 25.9138295,19.1005323 C25.7600765,19.0814088 22.0949928,18.6453928 17.5311044,20.0146359 C12.8361562,21.4228908 10.4339888,24.2572494 10.3340367,24.377345 C9.79526375,25.0239745 9.88246693,25.9811697 10.5262916,26.5235124 C10.8123793,26.7644685 11.161702,26.8820143 11.5092398,26.8820143 C11.9437259,26.8820143 12.3761721,26.6984287 12.6793434,26.3422215 C12.6984669,26.3197833 14.64269,24.0757036 18.4100207,22.9453769 C22.3022916,21.7778231 25.4709291,22.1291857 25.5372239,22.1368351" id="Fill-14" fill="#060000"></path>
        </g>
      </g>
    </g>
  </svg>

export default CovidBrown