import {UPDATE_SUCCESS_STORY} from '../actions/success-stories-actions'

const updateSuccessStory= (state = null, action) => {
  switch (action.type) {
    case UPDATE_SUCCESS_STORY:
      return action.updateSuccessStory
    default:
      return state
  }
}

export default updateSuccessStory
