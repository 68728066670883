import React from 'react'
import Field from '../helpers/Field'
import FormattedMessage from '../helpers/FormattedMessage'
import FormUserError from '../form-user-error'
import FormError from '../form-error'

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type} 
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default class TradeDebtAccountForm extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      interestRate: this.props.interestRate,
      fatalFormError: null
    }
  }

  componentDidMount() {
    this.props.initialize({interestRate: this.props.interestRate})
  }

  componentDidUpdate(prevProps, prevState, state) {

    if (this.props.error !== prevProps.error && this.props.error !== null) {

      let err = this.props.error
      this.setState({
        isProcessing: false,
        fatalFormError: err
      })
    }
  }



  render() {

    const {intl, formSyncErrors}=this.props;


    return(
      <div className='trade-account-detail-interest'>
        <div>
          <FormUserError validationErrors={formSyncErrors} intl={intl}/>
          <FormError error={this.state.fatalFormError}/>
        </div>

        <form onSubmit={this.props.handleSubmit}> 
            <div className='trade-account-detail-interest-input'>
              <Field name='interestRate' 
                type='text' 
                id='interestRate' 
                component={renderField}
                onChange={this.props.handleChange}
                placeholder='%'
                isDisabled={this.state.fatalFormError}/>
            </div>

        </form>

      </div>
      )
  }

}