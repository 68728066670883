import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import CustomerPortalFooter  from './customer-portal-footer';
import {injectIntl} from "react-intl";
import {reduxForm} from "redux-form";
import Field from './../helpers/Field'
import CheckboxContracts from "../helpers/CheckboxContracts";
import moment from 'moment'
import ValidationService from "../../services/validation-service";

import {useDispatch, useSelector} from "react-redux";
import {updateEdsContract, pageCompleted, generateEdsContractPdf} from '../../actions/cp-flow-actions'
import {getEmployerFromAccountId} from '../../actions/employer-actions'
import CustomerPortalProgressBar from "../helpers/CustomerPortalProgressBar";
import CustomerPortalNav from "./customer-portal-nav";
import {useHistory} from "react-router-dom";
import CustomerPortalContact from "./customer-portal-contact";

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

const checkboxWidget = ({input, placeholder, type, value, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type}/>
        <span className='contracts-checkmark'></span>
        <div className='customer-authorization-text'>
        </div>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

const options = {
  year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC'};

const today = moment().format('YYYY-MM-DD');


function CustomerPortalContractSign(props) {

  const [isProcessing, setIsProcessing] = useState(false);

  const employer = useSelector(state => state.employer)

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect( () => {
    window.scrollTo(0, 0)
  })

  const isSubmitDisabled = (props) => {
    return props.pristine ||
      props.submitting ||
      !props.valid ||
      isProcessing;
  }

    const goBackToPreviewPage = () => {
      history.push('/customer-portal/contracts/preview')
    }

    const handleViewSteps = (e) => {
      e.preventDefault()
      history.push('/customer-portal/contracts/view-all-steps')
    }

    const formSubmit = (values) => {
        values.customerSignedDate = today
        values.status = "Complete"
        dispatch(updateEdsContract(values)).then(() => {
          dispatch(getEmployerFromAccountId(employer.id)).then(() => {
            dispatch(generateEdsContractPdf()).then(() => {
              dispatch(pageCompleted(5))
              history.push('/customer-portal/contracts/submit')
            })
          })
        })
    }

    return(
      <div id='customer-portal'>
        <CustomerPortalNav />
        <div id='customer-portal-content'>
        <div id='customer-portal-contracts' className='customer-portal-pages'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <div className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.customer-portal.contracts-header'/>
            </div>
          </div>

          <div className='customer-portal-dashboard-container'>

            <div className='customer-portal-back-link' onClick={goBackToPreviewPage}>
              <FormattedMessage id='app.customer-portal-common.back-link'/>
            </div>
            <h2><FormattedMessage id='app.customer-portal-contracts.about-you.heading'/></h2>
            <div className='contract-content-wrapper'>
              <div className='contract-content-intro-wrapper'>
                <div className='contract-content-intro-text'>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you.text-1'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you-text-2'/>
                  </div>
                </div>
                <CustomerPortalProgressBar pageNum={5}
                                           handleViewSteps={(e) => handleViewSteps(e)}
                />
              </div>

              <div className='contracts-form-container'>
                <div className='contracts-form-title-container'>
                  <div className='contracts-form-title'>
                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-5a'/>
                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-5b'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.form.step-5.intro-text'/>
                  </div>
                </div>
                <div className='contracts-form-wrapper'>
                  <form onSubmit={props.handleSubmit(formSubmit)}>

                    <label htmlFor='customerSignedName'>
                      Type Your Name Below to Sign
                    </label>
                    <div className='contracts-input margin-bottom-8-px'>
                      <Field
                        name='customerSignedName'
                        type='input'
                        id='customerSignedName'
                        component={renderField}
                        />
                    </div>
                    <label htmlFor='customerTitle'>
                      Type Your Position Below
                    </label>
                    <div className='contracts-input margin-bottom-8-px'>
                      <Field
                        name='customerTitle'
                        type='input'
                        id='customerTitle'
                        component={renderField}
                        />
                    </div>

                    <div className='customer-authorization-container'>

                      <div className='customer-authorization-checkbox contracts-input margin-bottom-8-px'>

                        <div className='contracts-checkbox-container'>
                          <div className='checkbox'>
                            <label className='checkbox-inline'>
                              <Field
                                type='checkbox'
                                name='customerAuthorization'
                                id='customerAuthorization'
                                component={checkboxWidget} />
                            </label>
                          </div>
                        </div>

                      </div>
                      <div className='customer-authorization-text'>
                      <label htmlFor='customerAuthorization'>
                        I am authorized to sign this contract on behalf of {employer.displayName}
                      </label>
                      </div>

                    </div>



                    <div className='submit-button-round continue'>
                      <button
                        className="btn btn-primary btn-block"
                        id="submit"
                        type={'submit'}
                        disabled={isSubmitDisabled(props, isProcessing)}>
                        <FormattedMessage id='app.customer-portal.contracts-submit-contract'/>
                      </button>
                    </div>

                  </form>
                </div>

                <div className='miss-something-small-text submit-contract-small-text'>
                  <FormattedMessage id='app.customer-portal-home.contracts.sign-contracts'/>
                </div>
              </div>
            </div>

            <CustomerPortalFooter />
            <CustomerPortalContact />

          </div>
        </div>
        </div>
      </div>
    )
}



const UpdateEdsContract = reduxForm({
    form: 'CustomerPortalContractSign',
    validate: ValidationService.cpValidateContractSubmission,
    destroyOnUnmount: false
})(injectIntl(CustomerPortalContractSign))

export default UpdateEdsContract

