import axios from 'axios'
import {SubmissionError} from 'redux-form'

export const GET_TRADE_DEBT_ACCOUNT = 'GET_TRADE_DEBT_ACCOUNT'

export function getTradeDebtAccount() {
  return (dispatch, getState) => {
    let id = getState().debtSummaryAccountDetail.id
    return axios
      .get('/api/getTradeDebtAccount/{id}', {params: {id}})
      .then(response => {
        dispatch({
          type: GET_TRADE_DEBT_ACCOUNT,
          tradeDebtAccounts: response.data
        })
      })
      .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export function updateTradeDebtAccountYesFlagInformationIncorrect() {
  return (dispatch, getState) => {
    let id = getState().debtSummaryAccountDetail.id
    return axios
        .patch('/api/updateTradeDebtAccount',
          {
            id: id,
            flaggedByClient: true,
            flaggedInOnboarding: 'Yes'
          })
        .then((response) => {})
        .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
  }
}

export function updateTradeDebtAccountNoFlagInformationCorrect() {

  return (dispatch, getState) => {
    let id = getState().debtSummaryAccountDetail.id
    return axios
        .patch('/api/updateTradeDebtAccount',
          {
            id: id,
            flaggedByClient: false,
            flaggedInOnboarding: 'No'
          })
        .then((response) => {})
        .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
  }
}

export const updateTradeDebtAccount = data => {
  return (dispatch, getState) => {
    let id = getState().debtSummaryAccountDetail.id;
    let interestRate = data.interestRate ? data.interestRate : 0;
    return axios
        .patch('/api/updateTradeDebtAccount', {
          id: id,
          interestRate: interestRate
        })
        .then((response) => {})
        .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
  }
}

export function resetFlaggedInOnboarding() {
  return (dispatch, getState) => {
    let id = getState().debtSummaryAccountDetail.id
    return axios
        .patch('/api/updateTradeDebtAccount',
          {
            id: id,
            flaggedInOnboarding: ''
          })
        .then((response) => {})
        .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
  }
}

export function accountNotRecognized(id) {
  return (dispatch, getState) => {
    return axios
      .patch('/api/updateTradeDebtAccount',
        {
          id: id,
          flaggedInOnboarding: 'Yes',
          disputeLetterReason: 'Collection: Not Recognized'
        })
      .then((response) => {})
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export function accountRecognized(id) {
  return (dispatch, getState) => {
    console.log("accountRecognized: "+id)
    return axios
      .patch('/api/updateTradeDebtAccount',
        {
          id: id,
          flaggedInOnboarding: 'Yes',
          notes: 'I know this account, and I understand that I owe this balance.'
        })
      .then((response) => {})
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}
