import React from 'react'

export default class FicoMeterImage extends React.Component {

    constructor(props){
        super(props);
    }

    ficoMeterImage(position) {
        let extension = position == '00' ? '.png' : '.jpg'
        return (
            <img
                src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/fico/Score_Meter_300_850_4C_Position_${position}${extension}`}
            />
            )
    }

    render() {

        return (

            this.props.ficoScore == 1 ? this.ficoMeterImage('01') :
            this.props.ficoScore == 2 ? this.ficoMeterImage('02') :
            this.props.ficoScore == 3 ? this.ficoMeterImage('03') :
            this.props.ficoScore == 4 ? this.ficoMeterImage('04') :
            this.props.ficoScore == 5 ? this.ficoMeterImage('05') :
            this.props.ficoScore == 6 ? this.ficoMeterImage('06') :
            this.props.ficoScore == 7 ? this.ficoMeterImage('07') :
            this.props.ficoScore == 8 ? this.ficoMeterImage('08') :
            this.props.ficoScore == 9 ? this.ficoMeterImage('09') :
            this.props.ficoScore == 10 ? this.ficoMeterImage('10') :
            this.props.ficoScore == 11 ? this.ficoMeterImage('11') :
            this.props.ficoScore == 12 ? this.ficoMeterImage('12') :
            this.props.ficoScore == 13 ? this.ficoMeterImage('13') :
            this.props.ficoScore == 14 ? this.ficoMeterImage('14') :
            this.props.ficoScore == 15 ? this.ficoMeterImage('15') :
            this.props.ficoScore == 16 ? this.ficoMeterImage('16') :
            this.props.ficoScore == 17 ? this.ficoMeterImage('17') :
            this.props.ficoScore == 18 ? this.ficoMeterImage('18') :
            this.props.ficoScore == 19 ? this.ficoMeterImage('19') :
            this.props.ficoScore == 20 ? this.ficoMeterImage('20') :
            this.props.ficoScore == 21 ? this.ficoMeterImage('21') :
            this.props.ficoScore == 22 ? this.ficoMeterImage('22') :
            this.props.ficoScore == 23 ? this.ficoMeterImage('23') :
            this.props.ficoScore == 24 ? this.ficoMeterImage('24') :
            this.props.ficoScore == 25 ? this.ficoMeterImage('25') :
            this.props.ficoScore == 26 ? this.ficoMeterImage('26') :
            this.props.ficoScore == 27 ? this.ficoMeterImage('27') :
            this.props.ficoScore == 28 ? this.ficoMeterImage('28') :
            this.props.ficoScore == 29 ? this.ficoMeterImage('29') :
            this.props.ficoScore == 30 ? this.ficoMeterImage('30') :
            this.props.ficoScore == 31 ? this.ficoMeterImage('31'):
            this.props.ficoScore == 32 ? this.ficoMeterImage('32') :
            this.props.ficoScore == 33 ? this.ficoMeterImage('33') :
            this.props.ficoScore == 34 ? this.ficoMeterImage('34') :
            this.props.ficoScore == 35 ? this.ficoMeterImage('35') :
            this.props.ficoScore == 36 ? this.ficoMeterImage('36') :
            this.props.ficoScore == 37 ? this.ficoMeterImage('37') :
            this.props.ficoScore == 38 ? this.ficoMeterImage('38') :
            this.props.ficoScore == 39 ? this.ficoMeterImage('39') :
            this.props.ficoScore == 40 ? this.ficoMeterImage('40') :
            this.props.ficoScore == 41 ? this.ficoMeterImage('41') :
            this.props.ficoScore == 42 ? this.ficoMeterImage('42') :
            this.props.ficoScore == 43 ? this.ficoMeterImage('43') :
            this.props.ficoScore == 44 ? this.ficoMeterImage('44') :
            this.props.ficoScore == 45 ? this.ficoMeterImage('45') :
            this.props.ficoScore == 46 ? this.ficoMeterImage('46') :
            this.props.ficoScore == 47 ? this.ficoMeterImage('47') :
            this.props.ficoScore == 48 ? this.ficoMeterImage('48') :
            this.props.ficoScore == 49 ? this.ficoMeterImage('49') :
            this.ficoMeterImage('00')
        )
    }
}
