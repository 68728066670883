import React from 'react'

import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import ActionPlanPrivacyPolicyContainer from "../containers/action-plan/action-plan-privacy-policy-container";
import {change} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export default function PrivacyPolicy() {

  const employer = useSelector(state => state.employer)
  const dispatch = useDispatch()
  let history = useHistory()

  const accept = () => {
    dispatch(change('registration', 'agreedToTerms', true))
    history.push(`/${employer.code}`)
  }
  const decline = () => {
    dispatch(change('registration', 'agreedToTerms', false))
    history.push(`/${employer.code}`)
  }

  return (
    <div id='terms'>

      <HeaderContainerAP/>

      <div className='container'>
        <div className=''>

          <ActionPlanPrivacyPolicyContainer wrapper={' '}/>

          <br/>

          <div className='submit-button-round margin-bottom-24-px'>
            <button className='btn btn-primary btn-block btn-lg' id='agree' onClick={accept}>
              <FormattedMessage id='app.terms.agree'/>
            </button>
          </div>
          <div className='submit-button-round margin-bottom-24-px'>
            <button className='btn btn-default btn-block btn-lg' id='go-back' onClick={decline}>
              <FormattedMessage id='app.terms.go-back'/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
