import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from "../footer-action-plan";
import FormattedMessage from "../helpers/FormattedMessage";
import PieChartComponent from "../helpers/PieChartComponent";
import {Field, reduxForm} from "redux-form";
import {injectIntl} from "react-intl";
import {getCategories, getLinkedAccounts} from "../../actions/link-money-actions";
import moment from "moment";
import {Link} from "react-router-dom";

// First and last days of current month
const defaultStartDate = (new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)).toISOString().slice(0,10)
const defaultEndDate = (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)).toISOString().slice(0,10)

function ActionPlanLinkMoneyCategoryChart (props) {

  const linkMoneyCategoryData = useSelector(state => state.linkMoneyCategoryData);
  const linkMoneyAccountsData = useSelector(state => state.linkMoneyAccounts)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories({"startDate": defaultStartDate, "endDate": defaultEndDate}))
    dispatch(getLinkedAccounts())
  }, [])

  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [accountId, setAccountId] = useState('')
  const [categorySelected, setCategorySelected] = useState(null)

  const submitFilterForm = (categorySelectedValue) => {
    dispatch(getCategories({"startDate": startDate, "endDate": endDate, "categorySelected": categorySelectedValue, "accountId": accountId}))
  }

  const handleAccountIdChange = (value) => {
    setAccountId(value)
  }

  const handleStartDateChange = (value) => {
    setStartDate(value)
  }

  const handleEndDateChange = (value) => {
    setEndDate(value)
  }

  const handlePieClick = (value) => {
    setCategorySelected(value)
    submitFilterForm(value)
  }

  const renderDate = ({input, type, id, name, normalize, props, meta: {touched, error}}) => (
    <div>
      <input
        {...input}
        name={name}
        id={id}
        className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
        type={type}
      />
      {
        touched && (error &&
          <div className='label-text red'>
            <FormattedMessage id={error}/>
          </div>
        )
      }
    </div>
  )

  const renderSelect = ({input, type, id, name, normalize, props, meta: {touched, error}}) => (
    <div>
      <select {...input} name={name} type={type} id={id} className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}>
        <option value=''>All Accounts</option>
        {linkMoneyAccountsData.map((account) =>
            <option value={account.accountId}>{account.accountName}</option>
        )}
      </select>
      {
        touched && (error &&
          <div className='label-text red'>
            <FormattedMessage id={error}/>
          </div>
        )
      }
    </div>
  )

  const dateRange = () => {
    if (linkMoneyCategoryData) {
      let s = linkMoneyCategoryData.linkMoneyCategoryFiltersView.startDate
      let e = linkMoneyCategoryData.linkMoneyCategoryFiltersView.endDate

      let sd = moment(s, "YYYY-MM-DD").format("MMMM D, YYYY")
      let ed = moment(e, "YYYY-MM-DD").format("MMMM D, YYYY")

      return "From "+sd + " to " +ed
    } else {
      return ''
    }
  }

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const catSum = () => {
    let sum = 0;

    linkMoneyCategoryData.data.forEach( v => {
      sum += v.value;
    })

    return sum;
  };

  const catSumCredits = () => {
    let sum = 0;

    linkMoneyCategoryData.credits.forEach( v => {
      sum += v.value;
    })

    return sum;
  };

  const isCategoryChart = () => {
    return linkMoneyCategoryData &&
      linkMoneyCategoryData.linkMoneyCategoryFiltersView &&
      linkMoneyCategoryData.linkMoneyCategoryFiltersView.categorySelected &&
      linkMoneyCategoryData.linkMoneyCategoryFiltersView.categorySelected != "null"
  }

  const handleClick = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()

    setCategorySelected(null)
    submitFilterForm(null)
  }

    return (
        linkMoneyCategoryData && <div id='action-plan-link-money-category-spending-chart'>
            <ActionPlanNav />
            <div id='action-plan-content'>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <h1 className='action-plan-title margin-bottom-8-px'>
                      Spending By Category
                    </h1>
                </div>

              <p>
                {dateRange()}
              </p>

              <div className='cat-filters'>
                <p>Show the transactions that match:</p>
                <form onSubmit={props.handleSubmit}>
                  <div className='form-field'>
                    During
                    <Field component={renderSelect}
                           type='text'
                           name='accountId'
                           id='accountId'
                           onChange={e => handleAccountIdChange(e.target.value)}
                    />
                  </div>
                  <div className='form-field'>
                    From date
                    <Field component={renderDate}
                           type='date'
                           name='startDate'
                           id='startDate'
                           onChange={e => handleStartDateChange(e.target.value)}
                    />
                  </div>
                  <div className='form-field'>
                    To date
                    <Field component={renderDate}
                           type='date'
                           name='endDate'
                           id='endDate'
                           onChange={e => handleEndDateChange(e.target.value)}
                    />
                  </div>

                  <div className='submit-button-round form-field'>
                    <button id='submit'
                            type={'submit'}
                            className='btn btn-primary btn-block btn-lg'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              { isCategoryChart() ?
                <div className='cat-back-link'>
                  <Link to='' onClick={e => handleClick(e) }>
                    &laquo; Back to full spending
                  </Link>
                </div> : <div/>
              }

              <div className='cat-chart'>
                <PieChartComponent data={linkMoneyCategoryData.data} onClick={(value) => handlePieClick(value)}/>
              </div>

              <h3>
                Your Spending
                { isCategoryChart() ?
                  <span>
                    <strong>&nbsp;&raquo; {linkMoneyCategoryData.linkMoneyCategoryFiltersView.categorySelected}</strong>
                  </span> : <span />
                }
              </h3>
              <div className='cat-chart-spending'>
                {linkMoneyCategoryData.data.map(function(d){
                  return (
                    <div className='cat-chart-spending-row'>
                      <img src={d.logo} className="cat-chart-logo"/>
                      <div className='col'>{d.name}</div>
                      <div className='col'>{USDollar.format(d.value)}</div>
                    </div>
                  )
                })}
                <div className='cat-chart-spending-row bold'>
                  <div className='col'>Total</div>
                  <div className='col'>{USDollar.format(catSum())}</div>
                </div>
              </div>

              {linkMoneyCategoryData.credits.length > 0 ?
                  <div class="negatives-cat-chart">
                    <h3>Your Credits</h3>
                    <div className='cat-chart-spending'>
                      {linkMoneyCategoryData.credits.map(function(d){
                        return (
                            <div className='cat-chart-spending-row'>
                              <img src={d.logo} className="cat-chart-logo"/>
                              <div className='col'>{d.name}</div>
                              <div className='col'>{USDollar.format(d.value)}</div>
                            </div>
                        )
                      })}
                      <div className='cat-chart-spending-row bold'>
                        <div className='col'>Total</div>
                        <div className='col'>{USDollar.format(catSumCredits())}</div>
                      </div>
                    </div>
                  </div>
                  : null}

              <FooterActionPlan />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {

  return {
    initialValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate
    }
  }
}

const linkMoneyCategoryFiltersForm = reduxForm({
  form: 'ActionPlanLinkMoneyCategoryChart',
  onSubmit: (values, dispatch, props) => {
    return dispatch(getCategories(values)).then(() => {
      //dispatch(reset('ActionPlanLinkMoneyCategoryChart'))
    })
  },
  destroyOnUnmount: false
})(injectIntl(ActionPlanLinkMoneyCategoryChart))

export default connect(mapStateToProps)(linkMoneyCategoryFiltersForm)