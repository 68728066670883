import React from 'react'
import moment from "moment";
import CounselorSessionBox from "./helpers/CounselorSessionBox";

export default function AppointmentDetail(props) {
  let appt = props.appt
  let renderFromDashboard = props.renderFromDashboard
  let currentlyWorkingCounselors = props.activeCurrentlyWorkingCounselors

  let utcMoment = moment(appt.startDateTime)
  let localMoment = utcMoment.tz(appt.startDateTime, appt.timezone);

  function getCurrentlyWorkingCounselor(coach) {
    let counselors = currentlyWorkingCounselors
    let counselor = null;
      if (counselors != null) {
          for (let i = 0; i < counselors.length; i++) {
              let counselorTmp = counselors[i];
              if (counselorTmp.contactId === coach) {
                  counselor = counselorTmp;
              }
          }
      }
    return counselor;
  }

  return (
    <CounselorSessionBox
      key={appt.startDateTime}
      wrapper={''}
      apptDetail={{
        key: appt.startDateTime,
        contactId: appt.client,
        apptDateTime: appt.startDateTime,
        apptDayofMonth: localMoment.format('D'),
        apptDayofWeekLong: localMoment.format('dddd'),
        apptDayofWeekShort: localMoment.format('ddd'),
        apptMonthLong: localMoment.format('MMMM'),
        apptMonthShort: localMoment.format('MMM'),
        apptStatus: appt.status,
        apptTime: localMoment.format('h:mm A'),
        apptYear: localMoment.format('YYYY'),
        apptTimeZone: appt.timezone,
        apptType: appt.apptType,
        activeCounselor: getCurrentlyWorkingCounselor(appt.coach),
        rescheduleUrl: appt.rescheduleUrl,
        calendlyEventId: appt.calendlyEventId
      }}
      renderFromDashboard={renderFromDashboard}
    />
  )
}

