import React, {useEffect, useState} from 'react'

import FormattedMessage from './helpers/FormattedMessage'
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {samlRelay} from "../actions/login-actions";
import Icon from "../../resources/icons";

export default function SamlRelay(props) {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(null);

    const samlToken = useLocation().hash;

    const login = useSelector(state => state.login)

    useEffect(() => {
        if (samlToken != null) {
            setLoading("started")
            callSamlRelay().then(() => {
                setLoading("finished")
            })
        }
    }, [samlToken])

    useEffect(() => {
       if(loading === "finished"){
            if (login !== null){
                if (login.bookedOrCompletedAppointment == true) {
                    props.history.push(`/action-plan`)
                } else {
                    props.history.push('/select-by-availability')
                }
            } else {
                props.history.push('/')
            }
        }
    })

    async function callSamlRelay() {
        await dispatch(samlRelay(samlToken));
    }

    return (
      <div className='b-flow-body'>

        <div className='container-ap saml-relay'>
          <div className='saml-logo-wrapper'>
            <div className='saml-logo'>
              <Icon name="tp-full-logo"/>
            </div>
          </div>
          <div className='saml-loading-icon-wrapper'>
            <div className='saml-loading-icon'>
            </div>
          </div>
            <div className='saml-title'>
              <FormattedMessage id='app.saml-relay.title'/>
            </div>
        </div>
      </div>)

}