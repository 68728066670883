import React, {useEffect} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import FormattedMessage from "../helpers/FormattedMessage";

function LandingPagesHowHelped(props) {

  return (
    <div id='landing-pages-help-you'>
      <div className='how-we-have-helped bluebg'>
        <div className='landing-propel-container'>
          <div className='propel-section-header how-we-have-helped-header-1'>
            How We Have Helped
          </div>
          <div className='how-we-have-helped-wrapper'>
            <div className='how-we-have-helped-col-1'>
              <div className='testimonial-video propel-testimonial-video'>
                <iframe title="Video preview of a testimonial from a client" src="https://player.vimeo.com/video/389022339?byline=false&title=false&portrait=false" width="640" height="360"
                        frameBorder="0" allowFullScreen></iframe>
              </div>
            </div>
            <div className='how-we-have-helped-col-2'>
              <div className='how-we-have-helped-content-wrapper'>
                <div className='propel-section-header how-we-have-helped-header-2'>
                  How We Have Helped
                </div>
                <div className='propel-section-text'>
                  <p>
                    Hear how we helped one of our clients lower debt and enable their personal financial freedom in his own words. TrustPlus can help clear up debt collections and credit card debt.
                  </p>
                  <p>
                    Our expert financial coaches are ready to listen and help you as well!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default LandingPagesHowHelped
