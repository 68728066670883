import {compose} from 'redux'
import {connect} from 'react-redux'
import {reduxForm, formValueSelector} from 'redux-form'
import {injectIntl} from 'react-intl'
import {createBudgetExpense} from '../actions/budget-actions'
import BudgetExpenseForm from '../components/budget-expense-form'

const selector = formValueSelector('budget-expense-form')

const submitBudgetExpense = (values, dispatch) => new Promise((resolve, reject) => {
  dispatch(createBudgetExpense(values));
  resolve();
})

const BudgetExpenseFormContainer = compose(
  connect(
    state => ({
      initialValues: state.budgetExpense,
      enableReinitialize: false,
    }),
  ),
  reduxForm({
    form: 'budget-expense-form',
    onSubmit: (values, dispatch, props) => {
      submitBudgetExpense(values, dispatch).then(() => {
        props.notifyExpenseBudgetSubmitted()
      })
    },
    destroyOnUnmount: false
  }),
  connect(
    state => {
      const formHome = selector(state, 'home')
      const formFood = selector(state, 'food')
      const formUtilities = selector(state, 'utilities')
      const formTransportation = selector(state, 'transportation')
      const formEntertainment = selector(state, 'entertainment')
      const formEducationChildCare= selector(state, 'educationChildCare')
      const formPersonalCare = selector(state, 'personalCare')
      const formOtherExpenses = selector(state, 'otherExpenses')

      return {
        formHome: formHome,
        formFood: formFood,
        formUtilities: formUtilities,
        formTransportation: formTransportation,
        formEntertainment: formEntertainment,
        formEducationChildCare: formEducationChildCare,
        formPersonalCare: formPersonalCare,
        formOtherExpenses: formOtherExpenses,
        formTotalExp: (_.isNaN(parseInt(formHome)) ? 0 : parseInt(formHome)) +
          (_.isNaN(parseInt(formFood)) ? 0 : parseInt(formFood)) +
          (_.isNaN(parseInt(formUtilities)) ? 0 : parseInt(formUtilities)) +
          (_.isNaN(parseInt(formTransportation)) ? 0 : parseInt(formTransportation)) +
          (_.isNaN(parseInt(formEntertainment)) ? 0 : parseInt(formEntertainment)) +
          (_.isNaN(parseInt(formEducationChildCare)) ? 0 : parseInt(formEducationChildCare)) +
          (_.isNaN(parseInt(formPersonalCare)) ? 0 : parseInt(formPersonalCare)) +
          (_.isNaN(parseInt(formOtherExpenses)) ? 0 : parseInt(formOtherExpenses))
      }
    },
  )
)

export default BudgetExpenseFormContainer(injectIntl(BudgetExpenseForm))

