import React from 'react'

export const SpeechBubble = ({
                      style = {},
                      fill = '#fff',
                      width = '100%',
                      className = '',
                      height = '100%',
                      viewBox = '0 0 24 22',
                    }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TAT_1200+" transform="translate(-66.000000, -488.000000)" fill="#FFFFFF" fillRule="nonzero">
        <path d="M88.3928571,493.129464 C89.4642911,494.441971 90,495.874992 90,497.428571 C90,498.982151 89.4642911,500.417404 88.3928571,501.734375 C87.3214232,503.051346 85.8660806,504.091514 84.0267857,504.854911 C82.1874908,505.618307 80.1785823,506 78,506 C77.3749969,506 76.7276819,505.964286 76.0580357,505.892857 C74.2901697,507.455365 72.2366188,508.535711 69.8973214,509.133929 C69.4598192,509.258929 68.9508958,509.357143 68.3705357,509.428571 C68.2187492,509.446429 68.0825899,509.40625 67.9620536,509.308036 C67.8415173,509.209821 67.763393,509.080358 67.7276786,508.919643 L67.7276786,508.90625 C67.7008927,508.870536 67.6986606,508.816965 67.7209821,508.745536 C67.7433037,508.674107 67.7522322,508.629464 67.7477679,508.611607 C67.7433035,508.59375 67.7633926,508.55134 67.8080357,508.484375 L67.8883929,508.363839 C67.8973215,508.350446 67.9285712,508.3125 67.9821429,508.25 C68.0357146,508.1875 68.0714285,508.147322 68.0892857,508.129464 C68.151786,508.058035 68.2901775,507.904019 68.5044643,507.667411 C68.7187511,507.430802 68.8727674,507.261161 68.9665179,507.158482 C69.0602683,507.055803 69.1986598,506.879466 69.3816964,506.629464 C69.5647331,506.379463 69.7098209,506.151787 69.8169643,505.946429 C69.9241077,505.74107 70.0446422,505.47768 70.1785714,505.15625 C70.3125007,504.83482 70.4285709,504.495538 70.5267857,504.138393 C69.124993,503.343746 68.0200933,502.361613 67.2120536,501.191964 C66.4040138,500.022316 66,498.767864 66,497.428571 C66,496.267851 66.3169611,495.158487 66.9508929,494.100446 C67.5848246,493.042405 68.4374946,492.129468 69.5089286,491.361607 C70.5803625,490.593746 71.8571354,489.984377 73.3392857,489.533482 C74.821436,489.082587 76.3749919,488.857143 78,488.857143 C80.1785823,488.857143 82.1874908,489.238835 84.0267857,490.002232 C85.8660806,490.765629 87.3214232,491.808029 88.3928571,493.129464 Z" id=""></path>
      </g>
    </g>
  </svg>;

export default SpeechBubble