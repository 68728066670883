import React from 'react'
import {injectIntl} from "react-intl";

function CustomerPortalFooter (props) {

  return (
      <div className='customer-portal-footer'>

        <div className='customer-portal-footer-container'>
          <div className='customer-portal-logo-container'>
            <div className='customer-portal-company-logo-wrapper'>
              <img
                src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/Customer_Logos/${props.employerCode}.png`}
                width="auto" height="auto"
                alt={`${props.employerName}`}
              />
            </div>
          </div>
        </div>
      </div>
    )
}

export default (injectIntl(CustomerPortalFooter))
