import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import TradeDebtWorkOnThis from "./trade-debt-work-on-this";
import TradeDebtCoach from "./trade-debt-coach";
import TradeDebtHelpDispute from "./trade-debt-help-dispute";
import TradeDebtAll from "./trade-debt-all";
import DisputeOffline from "./dispute-offline";
import DisputeStep1 from "./dispute-step-1";
import DisputeStep2 from "./dispute-step-2";
import DisputeStep3 from "./dispute-step-3";
import DisputeStep4 from "./dispute-step-4";
import ToQuestionnaire from "./to-questionnaire";

export default function DebtReportCollections(props) {

  const [currentAccount, setCurrentAccount] = useState(null)

  const workOnThis = <TradeDebtWorkOnThis account={currentAccount}
                                          knowCollection={(account, know) => knowCollection(account, know)}
                                          backToList={() => backToList()}
                                          backToListWithoutRefresh={(e) => backToListWithoutRefresh(e)} />

  const all = <TradeDebtAll cat={props.cat}
                            view={(account) => view(account)}
                            firstBookedAppointmentStr={props.firstBookedAppointmentStr}
                            setCollectionPage={(pageName) => props.setCollectionPage(pageName)} />

  const coach = <TradeDebtCoach account={currentAccount}
                                backToList={() => backToList()} />

  const helpDispute = <TradeDebtHelpDispute account={currentAccount}
                                            backToList={() => backToList()} />

  const disputeOffline = <DisputeOffline account={currentAccount}
                                         firstBookedAppointmentStr={props.firstBookedAppointmentStr}
                                         nextPage={() => props.nextPage()} />

  const disputeStep1 = <DisputeStep1 account={currentAccount}
                                     setCollectionPage={(pageName) => props.setCollectionPage(pageName)} />

  const disputeStep2 = <DisputeStep2 account={currentAccount}
                                     setCollectionPage={(pageName) => props.setCollectionPage(pageName)} />

  const disputeStep3 = <DisputeStep3 cat={props.cat}
                                     setCollectionPage={(pageName) => props.setCollectionPage(pageName)} />

  const disputeStep4 = <DisputeStep4 account={currentAccount}
                                     setCollectionPage={(pageName) => props.setCollectionPage(pageName)} />

  const toQuestionnaire = <ToQuestionnaire nextPage={() => props.nextPage()}
                                           history={props.history}/>

  function knowCollection(account, know) {
    if (know) {
      props.setCollectionPage("coach")
    } else {
      props.setCollectionPage("helpDispute")
    }
    props.debtReportCollectionUpdate(account, know)
  }

  function backToList() {
    props.setCollectionPage("all")
  }

  function backToListWithoutRefresh(e) {
    e.preventDefault()
    props.setCollectionPage("all")
  }

  function view(account) {
    setCurrentAccount(account)
    props.setCollectionPage("view")
  }

  return (
    <div className='recommendations-page-container collections'>
      { props.collectionPage === "all" ? all : <div /> }
      { props.collectionPage === "view" ? workOnThis : <div /> }
      { props.collectionPage === "coach" ? coach : <div /> }
      { props.collectionPage === "helpDispute" ? helpDispute : <div /> }
      { props.collectionPage === "disputeOffline" ? disputeOffline : <div /> }
      { props.collectionPage === "disputeStep1" ? disputeStep1 : <div /> }
      { props.collectionPage === "disputeStep2" ? disputeStep2 : <div /> }
      { props.collectionPage === "disputeStep3" ? disputeStep3 : <div /> }
      { props.collectionPage === "disputeStep4" ? disputeStep4 : <div /> }
      { props.collectionPage === "toQuestionnaire" ? toQuestionnaire : <div /> }
    </div>

  )
}