import {CALENDLY_ROUND_ROBIN_URL} from '../actions/calendly-actions'

export default function calendlyRoundRobinUrl(state = null, action) {
  switch (action.type) {
    case CALENDLY_ROUND_ROBIN_URL:
      return action.calendlyRoundRobinUrl
    default:
      return state
  }
}
