import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import CreditReportFormContainer from '../containers/credit-report-form-container'

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
  props.submitting ||
  !props.valid ||
  props.error === 'app.errors.contact.update'
}

export default class DebtSummaryCreditReportPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id='debt-report'>{
        <form onSubmit={this.props.handleSubmit}>
          <CreditReportFormContainer />

          <div className='submit-button-round margin-bottom-24-px'>
            <button id='pull-report'
                    type={'submit'}
                    className='btn btn-primary btn-block btn-lg'
                    disabled={isSubmitDisabled(this.props, this.state)}>
              <FormattedMessage id='app.credit-report-form.go-to-credit-report'/>
            </button>
          </div>

          <div className='verified-and-secured'>
            <div className='goDaddySecuritySeal'/>
          </div>
        </form>
      }
      </div>
    )
  }
}