import axios from 'axios'

export const GET_EMPLOYER = 'GET_EMPLOYER'
export const GET_ACTIVE_EMPLOYERS_WITH_SSO = 'GET_ACTIVE_EMPLOYERS_WITH_SSO'
export const UPDATE_CP_ORGANIZATION = 'UPDATE_CP_ORGANIZATION'

export function getEmployer(code) {
  return (dispatch) => {
    return axios
      .get('/api/employer', {params: {code}})
      .then(response => {
        let res = response.data
        if (res === "") {
          res = {code:'err', name:'Bad Employer'}
        }
        dispatch({
          type: GET_EMPLOYER,
          employer: res
        })
      })
      .catch(() => {})
  }
}

export function getEmployerFromContactId(contactId) {
  return (dispatch, getState) => {

    return axios
      .get('/api/employer-contact', {params: {contactId}})
      .then((response) => {
        let res = response.data
        dispatch({
          type: GET_EMPLOYER,
          employer: res
        })

      })
  }
}

export function getEmployerFromAccountId(accountId) {
  return (dispatch, getState) => {

    return axios
      .get('/api/customer-employer', {params: {accountId}})
      .then((response) => {
        let res = response.data
        dispatch({
          type: GET_EMPLOYER,
          employer: res
        })

      })
  }
}

export function getAllActiveEmployersWithSSO() {
    return (dispatch, getState) => {

        return axios
            .get('/api/employer-sso')
            .then((response) => {
                let employers = response.data

                dispatch({
                    type: GET_ACTIVE_EMPLOYERS_WITH_SSO,
                    activeEmployers: employers
                })
            })
    }
}

export const updateCPOrganization = data => {
    return (dispatch, getState) => {
      const organization = Object.assign({}, getState().employer)
      organization.organizationType = data.organizationType
      organization.billingStreet = data.billingStreet
      organization.billingCity = data.billingCity
      organization.billingState = data.billingState
      organization.billingPostalCode = data.billingPostalCode
      organization.billingCountry = data.billingCountry
      organization.phone = data.phone

      console.log("updateCPOrganization" + organization );

      return axios
      .post('/api/employer/updateCPOrganization', organization)
      .then((response) => {
        dispatch({
          type: UPDATE_CP_ORGANIZATION,
          organization: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })

    }
}