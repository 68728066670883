import React, {useEffect, useState} from "react";
import IntroductionHeader from "./introduction-header";
import FormattedMessage from "../helpers/FormattedMessage";
import LandingFooter from "../landing-footer";
import Icon from "../../../resources/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import DclPrescreener1 from "./dcl-prescreener-1";
import DclPrescreener2 from "./dcl-prescreener-2";
import DclPrescreener3 from "./dcl-prescreener-3";
import {
  submitPrescreenerAnswer1,
  submitPrescreenerAnswer2,
  submitPrescreenerAnswer3, updateOnboardingProductStatus
} from "../../actions/dcl-actions";
import DclFaqItem from "./dcl-faq-item";

function IntroductionDcl(props) {

  const dispatch = useDispatch();

  const dclPrescreenerAnswer1 = useSelector(state => state.dcl.prescreenerAnswer1);
  const dclPrescreenerAnswer2 = useSelector(state => state.dcl.prescreenerAnswer2);
  const dclPrescreenerAnswer3 = useSelector(state => state.dcl.prescreenerAnswer3);
  const locale = useSelector(state => state.locale);

  const [step, setStep] = useState(0);
  const [contactUsClicked, setContactUsClicked] = useState(false);

  useEffect(() => {
    dispatch(submitPrescreenerAnswer1(''))
    dispatch(submitPrescreenerAnswer2(''))
    dispatch(submitPrescreenerAnswer3(''))
  }, [])

  useEffect(() => {
    if (step > 0 && dclPrescreenerAnswer1 !== '' && dclPrescreenerAnswer2 === '' && dclPrescreenerAnswer3 === '') {
      setStep(2)
    } else if (step > 0 && dclPrescreenerAnswer1 !== '' && dclPrescreenerAnswer2 !== '' && dclPrescreenerAnswer3 === '') {
      setStep(3)
    } else if (step > 0 && dclPrescreenerAnswer1 !== '' && dclPrescreenerAnswer2 !== '' && dclPrescreenerAnswer3 !== '') {
      let result = acceptanceCheck();
      dispatch(updateOnboardingProductStatus(result))
      props.history.push(`/select-by-availability`)
    }
  }, [dclPrescreenerAnswer1, dclPrescreenerAnswer2, dclPrescreenerAnswer3])

  function onStartSurveyClick(e) {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()
    signUpClicked()
  }

  function scrollWithOffset(id, yOffset) {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
    if (id == 'prescreener'){
      signUpClicked()
    }
  }

  function signUpClicked() {
    if (step === 0) {setStep(1)}
  }

  function acceptanceCheck(){
    if (dclPrescreenerAnswer1 !== 'unemployed') {
      if (dclPrescreenerAnswer3 !== "yes-under-620") {
        if (dclPrescreenerAnswer2 === '60+' || dclPrescreenerAnswer2 === '40-60') {
          return 'Accepted';
        }
      }
    }
    return 'Not Accepted';
  }

  function onContactUsClick() {
    setContactUsClicked(true)
    const element = document.getElementById('contact-us');
    setTimeout(function () {
      window.scrollTo({top: element.getBoundingClientRect().top + window.scrollY + -110, behavior: 'smooth'});
    }, 2)
  }

  return(
    <div className='intro-container dcl-flow'>
      <IntroductionHeader onLocaleToggle={(locale) => props.onLocaleToggle(locale)}
                          locale={props.locale}
                          history={props.history}
                          employerDefaultsToAskACoach={() => props.employerDefaultsToAskACoach()}
                          productName="DCL"
                          scrollWithOffset={(id, yOffset) => scrollWithOffset(id, yOffset)}
      />

      <main>
        <div>
          <div className='dcl-intro-landing-container'>

            <div className='landing-page-nav-wrapper'>
              <div className='landing-container'>
                <div className='landing-page-nav flex'>
                  <div className='landing-page-nav-item'>
                    <a onClick={() => scrollWithOffset('how-it-works', -75)}>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a onClick={() => scrollWithOffset('testimonials', -75)}>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a onClick={() => scrollWithOffset('our-impact', -75)}>
                      <FormattedMessage id='app.dcl-landing-page.our-impact.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a onClick={() => scrollWithOffset('who-we-are', -75)}>
                     <FormattedMessage id='app.dcl-landing-page.who-we-are.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                    <a onClick={() => scrollWithOffset('faq', -75)}>
                      <FormattedMessage id='app.dcl-landing-page.faq.title'/>
                    </a>
                  </div>
                  <div className='landing-page-nav-item'>
                  <a onClick={() => onContactUsClick()}>
                      <FormattedMessage id='app.dcl-landing-page.contact.title'/>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='dcl-into-box-wrapper'>

                <div className='intro-content-landing-page-wrapper dcl-intro-content-landing-page-wrapper-desktop'>
                    <div className='intro-text-landing-page-wrapper'>

                      <div className='dcl-landing-container intro-text'>
                        <h1>
                          <FormattedMessage id='app.dcl-landing-page.introduction.title-1'/><br />
                          <FormattedMessage id='app.dcl-landing-page.introduction.title-2'/>
                        </h1>
                        <div className='dcl-checklist-landing-page-wrapper'>
                          <FormattedMessage id='app.dcl-landing-page.introduction.intro-blurb'/>
                        </div>
                        <div className='dcl-checklist-landing-page-wrapper last-in-list'>
                          <a onClick={() => scrollWithOffset('prescreener', -150)}>
                            <FormattedMessage id='app.dcl-landing-page.introduction.intro-blurb-2'/>
                          </a>
                          <FormattedMessage id='app.dcl-landing-page.introduction.intro-blurb-3'/>
                        </div>

                        <div className='submit-button-round landing-submit-button-desktop dcl-landing-button'>
                          <button className='btn btn-primary btn-block btn-intro'
                            onClick={() => scrollWithOffset('prescreener', -100)}>
                              <FormattedMessage id='app.dcl-landing-page.introduction.first-step.button'/>
                          </button>
                        </div>
                    </div>
                  </div>
              </div>
              <div className='dcl-intro-hero'>
                  <img className='dcl-intro-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.hero'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/dcl-landing-desktop.png` }/>
              </div>
            </div>
          </div>

          <div className='intro-content-landing-page-wrapper-mobile'>
            <div className='landing-container'>
                <div className='dcl-landing-container intro-text'>
                  <h1>
                    <FormattedMessage id='app.dcl-landing-page.introduction.title-1'/><br/>
                    <FormattedMessage id='app.dcl-landing-page.introduction.title-2'/>
                  </h1>
                  <div className='dcl-checklist-landing-page-wrapper'>
                    <FormattedMessage id='app.dcl-landing-page.introduction.intro-blurb'/>
                  </div>
                  <div className='dcl-checklist-landing-page-wrapper last-in-list'>
                    <a onClick={() => scrollWithOffset('prescreener', -150)}>
                      <FormattedMessage id='app.dcl-landing-page.introduction.intro-blurb-2'/>
                    </a>
                    <FormattedMessage id='app.dcl-landing-page.introduction.intro-blurb-3'/>
                  </div>

                  <div className='submit-button-round landing-submit-button-mobile dcl-landing-button'>
                    <button className='btn btn-primary btn-block btn-intro'
                            onClick={() => scrollWithOffset('prescreener', -150)}>
                      <FormattedMessage id='app.dcl-landing-page.introduction.first-step.button'/>
                    </button>
                  </div>
                </div>
            </div>
          </div>

          <a id='benefits'></a>
          <div className="benefits-section">
            <div className='landing-container'>
              <h2>
                <FormattedMessage id='app.dcl-landing-page.benefits.title-1'/>
              </h2>
              <p className='benefits-intro-desktop'>
                <FormattedMessage id='app.dcl-landing-page.benefits.text-1'/>
              </p>

              <div className='eliminate-debt-boxes-wrapper'>
                <div className={`eliminate-debt-box eliminate-debt-video-wrapper ${step > 0 ? 'survey-visible' : ''}`}>
                  <h3>
                    <FormattedMessage id='app.dcl-landing-page.benefits.subtitle-1'/>
                  </h3>
                  <div className='coach-landing-video'>
                    <iframe width="560" height="315" src={`${props.intl.formatMessage({id: 'app.dcl-landing-page.youtube'})}`}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                  <p className='benefits-video-desktop'>
                  </p>
                  <p className='benefits-video-mobile'>
                  </p>
                </div>
                <div className={`eliminate-debt-box eliminate-debt-survey-wrapper ${step > 0 ? 'survey-visible' : ''}`}>
                  <a id='prescreener'></a>
                  <h3>
                    <FormattedMessage id='app.dcl-landing-page.benefits.subtitle-2'/>
                  </h3>
                  <div>
                    {step === 0 ?
                      <div>
                        <div className='survey-image-desktop'>
                          <div className='survey-start-image'>
                            <Link to='' onClick={e => onStartSurveyClick(e)}>
                              <Icon name={`${locale == 'en-US' ? 'dcl-survey-start' : 'dcl-survey-start-spanish'}`}
                                    desc={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.dcl-survey-start'})}`}
                              />
                            </Link>
                          </div>
                          <p>
                            <FormattedMessage id='app.dcl-landing-page.benefits.text-5'/>
                          </p>
                        </div>
                        <div className='survey-image-mobile'>
                          <div className='survey-start-image'>
                            <Icon name={`${locale == 'en-US' ? 'dcl-survey-start-mobile' : 'dcl-survey-start-mobile-spanish'}`}
                                  desc={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.dcl-survey-start'})}`}
                            />
                          </div>
                          <p>
                            <FormattedMessage id='app.dcl-landing-page.benefits.text-5'/>
                          </p>
                          <div className='submit-button-round landing-submit-button-mobile dcl-landing-button'>
                            <button className='btn btn-primary btn-block'
                                    onClick={() => signUpClicked()}>
                              <FormattedMessage id='app.dcl-landing-page.introduction.first-step.button'/>
                            </button>
                          </div>
                        </div>
                      </div> : <div/>
                    }

                    { step === 1 ?
                      <div role="region" aria-label="Pre-Screener Question 1">
                        <h4>
                          <FormattedMessage id='app.dcl-landing-page.survey.question1-header'/>
                        </h4>
                        <h4 class='question'>
                          <FormattedMessage id='app.dcl-landing-page.survey.question1'/>
                        </h4>
                        <DclPrescreener1/>
                      </div> : <div />
                    }

                    { step === 2 ?
                      <div role="region" aria-label="Pre-Screener Question 2">>
                        <h4>
                          <FormattedMessage id='app.dcl-landing-page.survey.question2-header'/>
                        </h4>
                        <h4 className='question'>
                          <FormattedMessage id='app.dcl-landing-page.survey.question2'/>
                        </h4>
                        <DclPrescreener2/>
                      </div> : <div />
                    }

                    { step === 3 ?
                      <div role="region" aria-label="Pre-Screener Question 3">>
                        <h4>
                          <FormattedMessage id='app.dcl-landing-page.survey.question3-header'/>
                        </h4>
                        <h4 className='question'>
                          <FormattedMessage id='app.dcl-landing-page.survey.question3'/>
                        </h4>
                        <DclPrescreener3/>
                      </div> : <div />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a id='how-it-works'></a>
          <div className="how-it-works">
            <div className='landing-container'>
              <h2>
                <FormattedMessage id='app.dcl-landing-page.how-it-works.title'/>
              </h2>
              <div className='how-it-works-container'>
                <div className='reason reason-1'>
                  <div className='reason mobile'>
                    <div className='how-it-works-icon mobile'>
                      <Icon name="dcl-save-money"/>
                    </div>
                    <div className='how-it-works-content mobile'>
                      <h3>
                        <FormattedMessage id='app.dcl-landing-page.how-it-works.subtitle-1'/>
                      </h3>
                      <p>
                        <FormattedMessage id='app.dcl-landing-page.how-it-works.text-1'/>
                      </p>
                    </div>
                  </div>
                  <div className='reason desktop'>
                    <h3>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.subtitle-1'/>
                    </h3>
                    <div className='how-it-works-icon desktop'>
                      <Icon name="dcl-save-money"/>
                    </div>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.text-1'/>
                    </p>
                  </div>
                </div>
                <div className='reason reason-2'>
                  <div className='reason mobile'>
                    <div className='how-it-works-icon mobile'>
                      <Icon name="dcl-one-bill"/>
                    </div>
                    <div className='how-it-works-content mobile'>
                      <h3>
                        <FormattedMessage id='app.dcl-landing-page.how-it-works.subtitle-2'/>
                      </h3>
                      <p>
                        <FormattedMessage id='app.dcl-landing-page.how-it-works.text-2'/>
                      </p>
                    </div>
                  </div>
                  <div className='reason desktop'>
                    <h3>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.subtitle-2'/>
                    </h3>
                    <div className='how-it-works-icon desktop'>
                      <Icon name="dcl-one-bill"/>
                    </div>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.text-2'/>
                    </p>
                  </div>
                </div>
                <div className='reason reason-3'>
                  <div className='reason mobile'>
                    <div className='how-it-works-icon mobile'>
                      <Icon name="dcl-coaching"/>
                    </div>
                    <div className='how-it-works-content mobile'>
                      <h3>
                        <FormattedMessage id='app.dcl-landing-page.how-it-works.subtitle-3'/>
                      </h3>
                      <p>
                        <FormattedMessage id='app.dcl-landing-page.how-it-works.text-3'/>
                      </p>
                    </div>
                  </div>
                  <div className='reason desktop'>
                    <h3>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.subtitle-3'/>
                    </h3>
                    <div className='how-it-works-icon desktop'>
                      <Icon name="dcl-coaching"/>
                    </div>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.text-3'/>
                    </p>
                  </div>
                </div>
                <div className='reason reason-4'>
                  <div className='reason mobile'>
                    <div className='how-it-works-icon mobile'>
                      <Icon name="dcl-products"/>
                    </div>
                    <div className='how-it-works-content mobile'>
                      <h3>
                        <FormattedMessage id='app.dcl-landing-page.how-it-works.subtitle-4'/>
                      </h3>
                      <p>
                        <FormattedMessage id='app.dcl-landing-page.how-it-works.text-4'/>
                      </p>
                    </div>
                  </div>
                  <div className='reason desktop'>
                    <h3>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.subtitle-4'/>
                    </h3>
                    <div className='how-it-works-icon desktop'>
                      <Icon name="dcl-products"/>
                    </div>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.how-it-works.text-4'/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a id='testimonials'></a>
          <div className='dcl-testimonials-section'>
            <div className='landing-container'>
              <h2>
                <FormattedMessage id='app.dcl-landing-page.testimonials.title'/>
              </h2>
              <div className='dcl-testimonials-container'>
                <div className='dcl-testimonial testimonial-1'>
                  <div className='dcl-testimonial-image'>
                    <img className='dcl-testimonial-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.testimonial-1'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/Yakaira-Desktop.png`}/>
                  </div>
                  <div className='dcl-testimonial-text'>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-1'/>
                    </p>
                    <div className='dcl-testimonial-author'>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-author-1'/>
                    </div>
                  </div>
                </div>
                <div className='dcl-testimonial testimonial-2'>
                  <div className='dcl-testimonial-image'>
                    <img className='dcl-testimonial-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.testimonial-2'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/Edward-Desktop.png`}/>
                  </div>
                  <div className='dcl-testimonial-text'>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-2'/>
                    </p>
                    <div className='dcl-testimonial-author'>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-author-2'/>
                    </div>
                  </div>
                </div>
                <div className='dcl-testimonial testimonial-3'>
                  <div className='dcl-testimonial-image'>
                    <img className='dcl-testimonial-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.testimonial-3'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/Annette-Desktop.png`}/>
                  </div>
                  <div className='dcl-testimonial-text'>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-3'/>
                    </p>
                    <div className='dcl-testimonial-author'>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-author-3'/>
                    </div>
                  </div>
                </div>
                <div className='dcl-testimonial testimonial-4'>
                  <div className='dcl-testimonial-image'>
                    <img className='dcl-testimonial-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.testimonial-4'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/James-Desktop.png`}/>
                  </div>
                  <div className='dcl-testimonial-text'>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-4'/>
                    </p>
                    <div className='dcl-testimonial-author'>
                      <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-author-4'/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a id='our-impact'></a>
          <div className='dcl-our-impact'>
            <div className='landing-container'>
              <h2>
                <FormattedMessage id='app.dcl-landing-page.our-impact.title'/>
              </h2>
              <div className='dcl-our-impact-container'>
                <div className='dcl-our-impact-wrapper'>
                  <div className='dcl-impact-svg impact-1'>
                    <Icon name='dcl-impact-1' />
                  </div>
                  <h3>
                    <FormattedMessage id='app.dcl-landing-page.our-impact.text-1'/>
                  </h3>
                  <div className='dcl-our-impact-number'>
                    $4,848
                  </div>
                </div>
                <div className='dcl-our-impact-wrapper'>
                  <div className='dcl-impact-svg impact-2'>
                    <Icon name='dcl-impact-2' />
                  </div>
                  <h3>
                    <FormattedMessage id='app.dcl-landing-page.our-impact.text-2'/>
                  </h3>
                  <div className='dcl-our-impact-number'>
                    $27,442,280
                  </div>
                </div>
                <div className='dcl-our-impact-wrapper'>
                  <div className='dcl-impact-svg impact-3'>
                    <Icon name='dcl-impact-3' />
                  </div>
                  <h3>
                    <FormattedMessage id='app.dcl-landing-page.our-impact.text-3'/>
                  </h3>
                  <div className='dcl-our-impact-number'>
                    $4,663,503
                  </div>
                </div>

              </div>
            </div>
          </div>

          <a id='who-we-are'></a>
          <div className="who-we-are">
            <div className='landing-container'>
              <h2>
                <FormattedMessage id='app.dcl-landing-page.who-we-are.title'/>
              </h2>
              <div className='who-we-are-container'>
                <div className='who-we-are-text'>
                  <div className='who-we-are-text-group-1'>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.who-we-are.text-1'/>
                    </p>
                  </div>
                  <div className='who-we-are-text-group-2 desktop-view'>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.who-we-are.text-2'/>
                    </p>
                    <p>
                      <FormattedMessage id='app.dcl-landing-page.who-we-are.text-3'/>
                    </p>
                  </div>
                </div>
                <div className='who-we-are-image-wrapper'>
                  <div className='who-we-are-image'>
                    <img className='dcl-who-we-are' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.who-we-wre-photo'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/dcl-who-we-are-elise.jpg`}/>
                  </div>
                  <div className='who-we-are-image-caption'>
                    <FormattedMessage id='app.dcl-landing-page.who-we-are.image-caption'/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a id='faq'></a>
          <div className='dcl-faq'>
            <div className='landing-container'>
              <h2>
                <FormattedMessage id='app.dcl-landing-page.faq.title'/>
              </h2>
              <div className='dcl-faqs-wrapper'>

                <DclFaqItem num={1} />
                <DclFaqItem num={2} />
                <DclFaqItem num={3} />
                <DclFaqItem num={4} />
                <DclFaqItem num={5} contactUsClicked={contactUsClicked} />

              </div>
            </div>
          </div>

          <div className='dcl-get-started'>
            <div className='landing-container'>
              <div className='submit-button-round dcl-prescreener-button'>
                <button className='btn btn-primary btn-block btn-intro'
                        onClick={() => scrollWithOffset('prescreener', -150)}>
                    <FormattedMessage id='app.dcl-landing-page.tagline.take-prescreener.button'/>
                </button>
              </div>
            </div>
          </div>


          <LandingFooter />

        </div>
      </main>
    </div>



    )
}
export default IntroductionDcl