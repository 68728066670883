import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";
import Icon from "../../../resources/icons";

function IntroductionHeader(props) {

  function onLocalToggle(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    props.onLocaleToggle(props.locale);
  }

  function goToWelcomePage(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    props.history.push(`/welcome`)
  }

  function signUp(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    if (props.productName && props.productName === 'DCL') {
      dclSignUp()
    } else {
      regularSignUp()
    }
  }

  function dclSignUp() {
    props.scrollWithOffset("prescreener", -150)
  }

  function regularSignUp() {
    props.history.push(`/select-by-availability`)
  }

  return(
    <header className='intro-header'>
      <div className='landing-header-ntfp-logo-container'>
        <div className='landing-header-ntfp-logo-wrapper'>
          <div className='landing-container'>
            <img className='landing-ntfp-logo' src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/logo_NT.png`} />
          </div>
        </div>
      </div>

      <div className='landing-header-links-container landing-heading-links-desktop'>
        <div className='landing-header-links-wrapper'>
          <div className='landing-container flex'>

            <div className='nav-logo-landing-page'>
              <div className='nav-logo-landing-page-desktop'>
                <Icon name="tp-full-logo"/>
              </div>
              <div className='nav-logo-landing-page-mobile'>
                <Icon name="tp-logo"/>
              </div>
            </div>

            <div className='landing-header-links-right'>
              <div className='spanish-link-landing-page'>
                <button className='locale-link-onboarding-home'
                        onClick={e => onLocalToggle(e)}>
                  <FormattedMessage id='app.introduction.locale-link'/>
                </button>
              </div>
              <div className='log-on-landing-page'>
                <button className='login-link-onboarding-home'
                        onClick={e => goToWelcomePage(e)}>
                  <FormattedMessage id='app.landing-page.introduction.log-on.button'/>
                </button>
              </div>
              <div className='sign-up-landing-page'>
                <button className='btn-red'
                        onClick={e => signUp(e)}>
                  {props.productName && props.productName === 'DCL' ?
                    <FormattedMessage id='app.dcl-landing-page.introduction.first-step.button'/>
                    :
                    <FormattedMessage id='app.landing-page.introduction.first-step.button'/>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='landing-header-links-container landing-heading-links-mobile'>
        <div className='landing-header-links-wrapper'>
          <div className='landing-container flex'>

            <div className='nav-logo-landing-page'>
              <div className='nav-logo-landing-page-mobile'>
                <Icon name="tp-logo"/>
              </div>
            </div>

            <div className='landing-header-links-right'>
              <div className='sign-up-landing-page'>
                <button className='btn-red'
                        onClick={e => signUp(e)}>
                  {props.productName && props.productName === 'DCL' ?
                    <FormattedMessage id='app.dcl-landing-page.introduction.first-step.button'/>
                    :
                    <FormattedMessage id='app.landing-page.introduction.first-step.button'/>
                  }
                </button>
              </div>
              <div className='mobile-header-sublinks'>
                <div className='spanish-link-landing-page'>
                  <button className='locale-link-onboarding-home'
                          onClick={e => onLocalToggle(e)}>
                    <FormattedMessage id='app.introduction.locale-link'/>
                  </button>
                </div>
                <div className='log-on-landing-page'>
                  <button className='login-link-onboarding-home'
                          onClick={e => goToWelcomePage(e)}>
                    <FormattedMessage id='app.landing-page.introduction.log-on.button'/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
)
}
export default IntroductionHeader