import {IS_CREDIT_FORM_OPEN} from '../actions/credit-form-actions'

export default function isCreditFormOpen(state = false, action) {
  switch (action.type) {
    case IS_CREDIT_FORM_OPEN:
      return action.isCreditFormOpen
    default:
      return state
  }
}
