import React from 'react'


export const ExternalLinkButton = ({ onClick, children, as: Component = 'button', ...rest }) => {
  return (
    <Component onClick={onClick} className="btn btn-primary btn-block btn-lg" {...rest}>
      {children}
    </Component>
  );
};

export default ExternalLinkButton



