import {
    PRESCREENER_ANSWER_1,
    PRESCREENER_ANSWER_2, PRESCREENER_ANSWER_3,
    UPDATE_ONBOARDING_PRODUCT_NAME, UPDATE_ONBOARDING_PRODUCT_STATUS
} from '../actions/dcl-actions'

const dcl = (state = null, action) => {
    switch (action.type) {
        case PRESCREENER_ANSWER_1:
            return {
                ...state, prescreenerAnswer1: action.dcl.prescreenerAnswer1
            }
        case PRESCREENER_ANSWER_2:
            return {
                ...state, prescreenerAnswer2: action.dcl.prescreenerAnswer2
            }
        case PRESCREENER_ANSWER_3:
            return {
                ...state, prescreenerAnswer3: action.dcl.prescreenerAnswer3
            }
        case UPDATE_ONBOARDING_PRODUCT_NAME:
            return {
                ...state, onboardingProductName: action.dcl.onboardingProductName
            }
        case UPDATE_ONBOARDING_PRODUCT_STATUS:
            return {
                ...state, onboardingProductStatus: action.dcl.onboardingProductStatus
            }
        default:
            return state
    }
}

export default dcl