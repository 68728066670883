import axios from 'axios'
import moment from 'moment'
import {SubmissionError} from 'redux-form'


export const CREATE_FEEDBACK_CASE = 'CREATE_FEEDBACK_CASE'
export const FEEDBACK_CASE_CREATED = 'FEEDBACK_CASE_CREATED'


export const submitClientFeedback = data => {
    return (dispatch, getState) => {
      const feedbackCase = {
          contactId: getState().login.contactId,
          feedbackDescription: data.feedbackDescription.toString()
      }

      return axios
        .post('/api/feedback-cases', feedbackCase)
        .then((response) => {
            //do we need to save the response anywhere?
        })
        .catch(error => {
          throw new SubmissionError({_error: error.response.data.message})
        })
    }
}
