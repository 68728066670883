import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import NoEmployerCode from '../components/no-employer-code'
import IntroductionPageCounselors from '../components/introduction-page-counselors'
import HomePageChecklist from "./home-page/home-page-checklist";
import _ from "lodash";
import LandingFooter from "./landing-footer";
import IntroductionTagline from "./introduction/introduction-tagline";
import IntroductionTestimonials from "./introduction/introduction-testimonials";
import IntroductionHowItWorks from "./introduction/introduction-how-it-works";
import IntroductionHeader from "./introduction/introduction-header";
import IntroductionDefault from "./introduction/introduction-default";
import IntroductionDcl from "./introduction/introduction-dcl";

export default class Introduction extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showCoachPrompt: true,
      showAskQuestion: false,
      showAskEmail: false,
      showDone: false,
      randomNumber: 0,
    }
  }

  componentDidMount() {
    let randomNum = Math.floor(Math.random() * 2);
    this.setState({
      showCoachPrompt: true,
      showAskQuestion: false,
      showAskEmail: false,
      showDone: false,
      randomNumber: randomNum,
    })
  }

  employerImage() {
    return (
        <div className='company-logo-wrapper'>
          <img
                  src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/Customer_Logos/${this.props.employer.code}.png`}
                  width="auto" height="auto"
                  alt={`${this.props.employer.displayName}`}
              />
        </div>
    )
  }

  determineNextLocation(){
    if (this.employerDefaultsToAskACoach()){
      this.props.history.push(`/ask-question`)
    } else {
      this.goToAppointmentSchedule()
    }
  }

  goToAppointmentSchedule() {
    this.props.history.push('/select-by-availability')
  }

  onLocalToggle(locale) {
    this.props.onLocaleToggle(locale);
  }

  employerDefaultsToAskACoach(){
    return (!_.isEmpty(this.props.match.params.employerCode) && (
    this.props.match.params.employerCode.toLowerCase() === 'u628' ||
        this.props.match.params.employerCode.toLowerCase() === 'nz37'))
  }

  render() {

    return (
      this.props.employer && <div id='introduction'>
        {this.props.employer.code === 'err' || this.props.employer.customerStatus === 'Inactive' ?
            <NoEmployerCode/>
            :
          (this.props.employer.code === 'NTFP' && this.props.dcl.onboardingProductName === 'DebtConsolidationLoan' ?
              <IntroductionDcl onLocaleToggle={(locale) => this.onLocalToggle(locale)}
                                   locale={this.props.locale}
                                   history={this.props.history}
                                   activeCounselors={this.props.activeCounselors}
                                   employer={this.props.employer}
                                   employerDefaultsToAskACoach={() => this.employerDefaultsToAskACoach()}
                                   determineNextLocation={() => this.determineNextLocation()}
                                   employerImage={() => this.employerImage()}
                                   intl={this.props.intl}
              />
          :
              <IntroductionDefault onLocaleToggle={(locale) => this.onLocalToggle(locale)}
                                   locale={this.props.locale}
                                   history={this.props.history}
                                   activeCounselors={this.props.activeCounselors}
                                   employer={this.props.employer}
                                   employerDefaultsToAskACoach={() => this.employerDefaultsToAskACoach()}
                                   determineNextLocation={() => this.determineNextLocation()}
                                   employerImage={() => this.employerImage()}
                                   intl={this.props.intl}
              />
          )

        }
      </div>
    )
  }
}
