import React from 'react'

export const Onboarding = ({
                          style = {},
                          fill = '#000000',
                          width = '100%',
                          className = '',
                          height = '100%',
                          viewBox = '0 0 27 28',
                        }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <path d="M17.023 5.966c-2.159 0-4.1-1.407-7.115-1.407a9.753 9.753 0 0 0-3.547.667A2.111 2.111 0 0 0 4.5 2.455a2.11 2.11 0 0 0-1.195 3.93v17.862c0 .388.314.703.703.703h.703a.703.703 0 0 0 .703-.703V20.58c1.664-.76 3.132-1.255 5.617-1.255 2.16 0 4.1 1.406 7.116 1.406 2.57 0 4.48-.994 5.649-1.757.596-.389.954-1.053.954-1.765V6.666c0-1.514-1.55-2.539-2.94-1.939-1.578.681-3.228 1.239-4.787 1.239zm5.618 11.25c-.958.677-2.673 1.406-4.494 1.406-2.635 0-4.483-1.406-7.116-1.406-1.905 0-4.235.413-5.617 1.054V8.075c.957-.677 2.673-1.406 4.494-1.406 2.634 0 4.482 1.406 7.115 1.406 1.902 0 4.233-.763 5.618-1.406v10.547z" fill="#000"/>

  </svg>;

export default Onboarding