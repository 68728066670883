import React from 'react'
import FormattedMessage from "./FormattedMessage";
import TermsMessages from "./TermsMessages";

function renderPrivacyPolicyLink(employer, employerCode) {
    let code = employer == null ? '0000' : employerCode
    return `${code}/privacy-policy`
}

export const TermsText = props => (
    <div>
        <p><FormattedMessage id='app.terms.terms.intro.1'/></p>
        <p><FormattedMessage id='app.terms.terms.intro.2'
                             values={{
                                 neighborhoodTrustLink: (<a target='_blank' href='https://www.neighborhoodtrust.org/'>
                                     www.neighborhoodtrust.org
                                 </a>)
                             }}
        />
        </p>
        <p>
            <FormattedMessage id='app.terms.terms.intro.3a'/>
            <span className='tpc-emmas-story-bold'>
        <FormattedMessage id='app.terms.terms.intro.3b'/>
      </span>
            <FormattedMessage id='app.terms.terms.intro.3c'/>
        </p>
        <p>
      <span className='tpc-emmas-story-bold'>
        <FormattedMessage id='app.terms.terms.intro.4'/>
      </span>
        </p>
        <p><FormattedMessage id='app.terms.terms.intro.5'/></p>
        <p><FormattedMessage id='app.terms.terms.intro.6'/></p>
        <p><FormattedMessage id='app.terms.terms.intro.7'/></p>

        <h3 className='ptl'><FormattedMessage id='app.terms.terms.section.1.header'/></h3>
        <p><FormattedMessage id='app.terms.terms.section.1.text.1'/></p>

        <h4 className='ptl'><FormattedMessage id='app.terms.terms.section.1b.header'/></h4>
        <p><FormattedMessage id='app.terms.terms.section.1b.text.1'/></p>
        <p><FormattedMessage id='app.terms.terms.section.1b.text.2'/></p>
        <p><FormattedMessage id='app.terms.terms.section.1b.text.3'/></p>
        <p><FormattedMessage id='app.terms.terms.section.1b.text.4'/></p>
        <p><FormattedMessage id='app.terms.terms.section.1b.text.5'/></p>

        <h3 className='ptl'><FormattedMessage id='app.terms.terms.section.2.header'/></h3>
        <p><FormattedMessage id='app.terms.terms.section.2.text.1'/></p>

        <h3 className='ptl'><FormattedMessage id='app.terms.terms.section.3.header'/></h3>
        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.1'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.1'/>
        </p>

        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.2'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.2'/>
        </p>

        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.3'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.3'/>
        </p>

        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.4'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.4'/>
        </p>

        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.5'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.5'/>
        </p>

        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.6'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.6'/>
            <ul>
                <li><FormattedMessage id='app.terms.terms.section.3.text.6a'/></li>
                <li><FormattedMessage id='app.terms.terms.section.3.text.6b'/></li>
                <li><FormattedMessage id='app.terms.terms.section.3.text.6c'/></li>
                <li><FormattedMessage id='app.terms.terms.section.3.text.6d'/></li>
            </ul>
        </p>
        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.7'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.7'
                              values={{
                                  mailToSupportAction: (
                                      <a href={'https://' + props.intl.formatMessage(TermsMessages.mailtoEmailAddress)}>
                                          <FormattedMessage id='app.common.email.support'/>
                                      </a>),
                              }}/>
        </p>
        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.8'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.8'/>
        </p>

        <p>
            <b><FormattedMessage id='app.terms.terms.section.3.subheader.9'/></b>
            <FormattedMessage id='app.terms.terms.section.3.text.9'/>
        </p>

        <h3 className='ptl'><FormattedMessage id='app.terms.terms.section.4.header'/></h3>
        <p>
            <b><FormattedMessage id='app.terms.terms.section.4.subheader.1'/></b>
            <FormattedMessage id='app.terms.terms.section.4.text.1'/>
        </p>
        <p><FormattedMessage id='app.terms.terms.section.4.text.1a'/></p>
        <p><FormattedMessage id='app.terms.terms.section.4.text.1b'/></p>
        <p><FormattedMessage id='app.terms.terms.section.4.text.1c'/></p>
        <p><FormattedMessage id='app.terms.terms.section.4.text.1d'
                             values={{
                                 mailToSupportAction: (
                                     <a href={'https://' + props.intl.formatMessage(TermsMessages.mailtoEmailAddress)}>
                                         <FormattedMessage id='app.common.email.support'/>
                                     </a>),
                             }}/>
        </p>

        <p>
            <b><FormattedMessage id='app.terms.terms.section.4.subheader.2'/></b>
            <FormattedMessage id='app.terms.terms.section.4.text.2'
                              values={{
                                  zoomUrl: (<a target='_blank'
                                               href={'https://' + props.intl.formatMessage(TermsMessages.externalZoomLegalLink)}
                                               id='external-zoom'>
                                          {'https://' + props.intl.formatMessage(TermsMessages.externalZoomLegalLink)}
                                      </a>
                                  )
                              }}
            />
        </p>
        <p>
            <b><FormattedMessage id='app.terms.terms.section.4.subheader.2a'/></b>
            <FormattedMessage id='app.terms.terms.section.4.text.2a'
                              values={{
                                  googleUrl: (<a target='_blank'
                                                 href={'https://' + props.intl.formatMessage(TermsMessages.externalGoogleLegalLink)}
                                                 id='external-google'>
                                          {'https://' + props.intl.formatMessage(TermsMessages.externalGoogleLegalLink)}
                                      </a>
                                  )
                              }}
            />
        </p>
        <p>
            <b><FormattedMessage id='app.terms.terms.section.4.subheader.2b'/></b>
            <FormattedMessage id='app.terms.terms.section.4.text.2b'/>
        </p>

        <h4 className='ptl'><FormattedMessage id='app.terms.terms.section.4a.header'/></h4>
        <p><FormattedMessage id='app.terms.terms.section.4.text.3'/></p>

        <p><FormattedMessage id='app.terms.terms.section.4.text.3b'/></p>

        <h4 className='ptl'><FormattedMessage id='app.terms.terms.section.4b.header'/></h4>
        <p><FormattedMessage id='app.terms.terms.section.4b.text.1'/></p>
        <p><FormattedMessage id='app.terms.terms.section.4b.text.2'/></p>
        <p><FormattedMessage id='app.terms.terms.section.4b.text.3'/>
            <ul>
                <li><FormattedMessage id='app.terms.terms.section.4b.3.bulletpoints.a'/></li>
                <li><FormattedMessage id='app.terms.terms.section.4b.3.bulletpoints.b'/></li>
                <li><FormattedMessage id='app.terms.terms.section.4b.3.bulletpoints.c'/></li>
            </ul>
        </p>
        <p><FormattedMessage id='app.terms.terms.section.4b.text.4'/>
            <ul>
                <li><FormattedMessage id='app.terms.terms.section.4b.4.bulletpoints.a'/></li>
                <li><FormattedMessage id='app.terms.terms.section.4b.4.bulletpoints.b'/></li>
            </ul>
        </p>
        <p><FormattedMessage id='app.terms.terms.section.4b.text.5'/></p>

        <p>
      <span style={{textDecoration: 'underline'}}>
        <FormattedMessage id='app.terms.terms.section.4b.subheader.1'/>
      </span>
            <FormattedMessage id='app.terms.terms.section.4b.text.6'/>
        </p>
        <p><FormattedMessage id='app.terms.terms.section.4b.text.7'/></p>

        <h4 className='ptl'><FormattedMessage id='app.terms.terms.section.4c.header'/></h4>
        <p>
            <FormattedMessage id='app.terms.terms.section.4c.text.1'/>
            <ul>
                <li><FormattedMessage id='app.terms.terms.section.4c.1.bulletpoints.a'/></li>
                <li><FormattedMessage id='app.terms.terms.section.4c.1.bulletpoints.b'/></li>
                <li><FormattedMessage id='app.terms.terms.section.4c.1.bulletpoints.c'/></li>
                <li><FormattedMessage id='app.terms.terms.section.4c.1.bulletpoints.d'/></li>
            </ul>
        </p>
        <p><FormattedMessage id='app.terms.terms.section.4c.text.2'/></p>
        <p><FormattedMessage id='app.terms.terms.section.4c.text.3'/></p>

        <h4 className='ptl'><FormattedMessage id='app.terms.terms.section.4d.header'/></h4>
        <p><FormattedMessage id='app.terms.terms.section.4d.text.1'
                             values={{
                                 privacyPolicyUrl: (
                                     <a target='_blank'
                                        href={renderPrivacyPolicyLink(props.employer, props.employerCode)}>
                                         <FormattedMessage id='app.terms.terms.section.4d.text.2'/>
                                     </a>
                                 )
                             }}
        /></p>

        <h4 className='ptl'><FormattedMessage id='app.terms.terms.section.4e.header'/></h4>
        <p><FormattedMessage id='app.terms.terms.section.4e.text.1'
                             values={{
                                 privacyPolicyUrl: (
                                     <a target='_blank'
                                        href={renderPrivacyPolicyLink(props.employer, props.employerCode)}>
                                         <FormattedMessage id='app.terms.terms.section.4d.text.2'/>
                                     </a>
                                 )
                             }}
        /></p>

        <h4 className='ptl'><FormattedMessage id='app.terms.terms.section.4f.header'/></h4>
        <p><FormattedMessage id='app.terms.terms.section.4f.text.1'/></p>

        <h3 className='ptl'><FormattedMessage id='app.terms.terms.section.5.header'/></h3>
        <p><FormattedMessage id='app.terms.terms.section.5.text.1'/></p>

        <h3 className='ptl'><FormattedMessage id='app.terms.terms.section.6.header'/></h3>
        <p><FormattedMessage id='app.terms.terms.section.6.text.1'/></p>
        <p><FormattedMessage id='app.terms.terms.section.6.text.2'/></p>
        <h4 className='ptl'><FormattedMessage id='app.terms.terms.section.6.subheader.1'/></h4>
        <p><FormattedMessage id='app.terms.terms.section.6.text.3'/></p>
        <p><b><FormattedMessage id='app.terms.terms.section.6.subheader.2'/></b>
            <FormattedMessage id='app.terms.terms.section.6.text.4'/>
        </p>
        <p><b><FormattedMessage id='app.terms.terms.section.6.subheader.3'/></b>
            <FormattedMessage id='app.terms.terms.section.6.text.5'/>
        </p>
        <p><FormattedMessage id='app.terms.terms.section.6.text.6'/></p>
        <p><b><FormattedMessage id='app.terms.terms.section.6.subheader.4'/></b>
            <FormattedMessage id='app.terms.terms.section.6.text.7'/>
        </p>
        <p><b><FormattedMessage id='app.terms.terms.section.6.subheader.5'/></b>
            <FormattedMessage id='app.terms.terms.section.6.text.8'/>
        </p>


        <h3><FormattedMessage id='app.terms.privacy.section.6.header'/></h3>
        <p><FormattedMessage id='app.terms.privacy.section.6.text.1a'
                             values={{
                                 mailToSupportAction: (
                                     <a href={'mailto:' + props.intl.formatMessage(TermsMessages.mailtoEmailAddress)}>
                                         <FormattedMessage id='app.common.email.support'/>
                                     </a>),
                             }}
        /></p>
        <p>
            <FormattedMessage id='app.terms.privacy.section.6.text.1'/><br/>
            <FormattedMessage id='app.terms.privacy.section.6.text.2'/><br/>
            <FormattedMessage id='app.terms.privacy.section.6.text.3'/><br/>
            <FormattedMessage id='app.privacy-policy.how-to-contact.2'/><br/>
        </p>
        <p><FormattedMessage id='app.terms.privacy.section.6.text.4'
                             values={{
                                 externalTermsOfService: (
                                     <a target='_blank'
                                        href={'https://' + props.intl.formatMessage(TermsMessages.externalTermsOfService)}>
                                         <FormattedMessage id='app.common.link.external.terms'/>
                                     </a>)
                             }}
        /></p>
    </div>
)

export default TermsText