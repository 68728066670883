import {ALERT_NAME} from '../actions/alert-actions'

export default function alertName(state = '', action) {
  switch (action.type) {
    case ALERT_NAME:
      return action.alertName
    default:
      return state
  }
}
