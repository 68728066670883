import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../services/validation-service'
import {update} from '../actions/registration-actions'
import {getDebtReport} from '../actions/debt-report-actions'
import {getFicoScore} from '../actions/fico-score-actions'

import DebtSummaryCreditReportPage from '../components/debt-summary-credit-report-page'

function mapStateToProps(state) {
  return {
    registration: state.form.registration && state.form.registration.values,
    showAlert: state.displayAlert,
    nameOfAlert: state.alertName,
    progressBarPercentage: state.progressPercentage,
    displayProgressBar: true,
    progressBarCondensed: true,
    debtReportAttempt: state.debtReportAttempt,
    formSyncErrors: getFormSyncErrors('registration')(state)//gets user validation errors on form level
  }
}

const DebtSummaryCreditReportPageContainer = connect(
  mapStateToProps,
)(reduxForm({
  form: 'registration',
  validate:ValidationService.validateDebtSummaryCreditReportFormFields,
  onSubmit: (values, dispatch, props) => {
    return dispatch(update(values)).then(() => {
      // Wait 30 seconds then retrieve debt report and FICO data
      setTimeout(() => {
        dispatch(getFicoScore())
        dispatch(getDebtReport())
      }, 30000)
    })
  },
  destroyOnUnmount: false
})
(injectIntl(DebtSummaryCreditReportPage))
)

export default DebtSummaryCreditReportPageContainer
