import {UPDATED_PENDING_TAKE_ACTION_TODAY} from '../actions/take-action-today-actions'

function updatedPendingTakeActionToday(state = [], action) {
  switch (action.type) {
    case UPDATED_PENDING_TAKE_ACTION_TODAY:
      return action.updatedPendingTakeActionToday
    default:
      return state
  }
}

export default updatedPendingTakeActionToday