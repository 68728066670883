import React from "react";

const es = {
  'app.common.email.support': 'support@mytrustplus.org',
  'app.common.email.support-text': 'Soporte por correo electrónico',
  'app.common.email.washUnivHRPO': 'hrpo@wustl.edu',
  'app.common.link.external.self-scheduler': 'http://bit.ly/2krEB4t',
  'app.common.link.external.skype': 'www.skype.com/es/legal/',
  'app.common.link.external.zoom': 'zoom.us/docs/en-us/privacy-and-security.html',
  'app.common.link.external.google': 'support.google.com/meet/answer/9852160',
  'app.common.link.external.nytimes': 'http://www.nytimes.com/2016/02/29/business/dealbook/overdraft-practices-continue-to-gut-bank-accounts-and-haunt-customers.html?_r=0',
  'app.common.link.external.vice': 'https://news.vice.com/story/how-to-establish-credit',
  'app.common.link.external.npr': 'http://www.npr.org/sections/money/2013/06/18/193176928/episode-466-diy-finance',
  'app.common.link.external.nycgov': 'http://www1.nyc.gov/site/dca/media/pr101816.page',
  'app.common.link.external.terms': 'www.neighborhoodtrust.org/terminos-de-servicio',
  'app.common.link.external.privacy': 'www.neighborhoodtrust.org/politica-de-privacidad/',
  'app.common.phone.tollfree': ' 1-800-927-8772',
  'app.common.emergency-covid-email.support': 'covidsupport@mytrustplus.org',

  'app.common.punctuation.period': '.',
  'app.common.punctuation.space': ' ',
  'app.common.punctuation.question-mark':'?',
  'app.common.punctuation.comma': ', ',

  'app.common.button.goback': 'Regresar',
  'app.common.continue.button': 'Continuar',
  'app.common.send-code.button': 'Enviar código',
  'app.common.got-it.button': '¡Lo tengo!',
  'app.common.save.button': 'Guardar',
  'app.common.cancel.button': 'Cancel',
  'app.common.edit.button': 'Edit',
  'app.common.next.button': 'Siguiente',
  'app.common.keep-going.button': 'Continuar',
  'app.common.maybe-later.button': 'Maybe later',
  'app.common.yes-please.button' : 'Yes, please',
  'app.common.change-coach.button': 'Cambiar de asesor',

  'app.message.login.unsuccessful': 'Su inicio de sesión no tuvo éxito. Por favor compruebe su contraseña y vuelva a intentarlo.',
  'app.message.tempcode.unsuccessful': 'Su inicio de sesión no tuvo éxito. Por favor compruebe su código temporal y vuelva a intentarlo.',
  'app.errors.tempcode.required': 'Código temporal es requerido.',
  'app.message.change-password.unsuccessful': 'Por favor compruebe su contraseña y vuelva a intentarlo.',
  'app.tempcode.resent.code.message' : '¿No recibió el código en su teléfono? ¡Lo siento por eso! Revise su correo electrónico ahora para el código.',

  'app.errors.password.required': 'La contraseña es requerida',
  'app.errors.password.length': 'La contraseña debe tener un mínimo de 8 caracteres',
  'app.errors.password.containupper': 'La contraseña debe contener una letra mayúscula',
  'app.errors.password.containlower': 'La contraseña debe contener una letra minúscula',
  'app.errors.password.containnumber': 'La contraseña debe contener un número',
  'app.errors.password.notcontainspecial': 'La contraseña no debe contener un carácter especial',
  'app.errors.password.containspecial': 'La contraseña debe contener un carácter especial',
  'app.errors.password.not-match': 'La contraseña no coincide',

  'app.welcome.title': 'Bienvenido!',
  'app.welcome.instruction1': 'Empiece hoy mismo ingresando su correo electrónico.',
  'app.welcome.input.email': 'Correo electrónico',

  'app.welcome.logout.title': 'Establecer nueva contraseña',
  'app.welcome.logout.instruction1': 'Vuelva a iniciar sesión introduciendo su correo electrónico.',

  'app.welcome.timeout.title': 'Desconectado debido a inactividad',
  'app.welcome.timeout.instruction1': 'Vuelva a iniciar sesión introduciendo su correo electrónico.',

  'app.password.title': 'Escriba la contraseña',
  'app.password.instruction1': 'Ingrese su contraseña para completar su inicio de sesión',
  'app.password.input.password': 'Contraseña',
  'app.password.alt-link': '¿Ha olvidado su contraseña?',

  'app.reschedule.title': '¡Programar sesión de consejería!',
  'app.reschedule.instruction1': 'Haga clic en el enlace de abajo y programe una sesión de consejería.',

  'app.notification.title': 'Crear Cuenta',
  'app.notification.instruction1': 'El último paso es crear una contraseña para su cuenta. En la próxima página ingrese el código temporario que recibió a su cellular. Despues de crear su contraseña podrás analizar su resumido de deudas, ver su puntaje de credito, administrar tu próxima sesión, y crear un presupuesto para revisar con tu consejero.',
  'app.notification.instruction2': 'Ya hemos enviado un código temporario a su numero de teléfono.',
  'app.notification.alt-link': 'No he recibido mi código',

  'app.tempcode.title': 'Enviamos un código temporal',
  'app.tempcode.instruction1': 'Un código temporal fue enviado a su correo electrónico. Ingréselo abajo.',
  'app.tempcode.input.tempcode': 'Código temporal',
  'app.tempcode.alt-link': 'Reenviar codigo temporal',

  'app.newuser.title': 'Nuevo usuario',
  'app.newuser.instruction1': 'No reconocemos su correo electrónico, pero usted puede empezar hoy mismo programando una sesión con un consejero financiero.',
  'app.newuser.dcl.debtconsolidationloan.instruction1': 'No reconocemos su correo electrónico, ¡pero puedes comenzar hoy mismo ',
  'app.newuser.dcl.debtconsolidationloan.instruction2': 'completando nuestro preevaluador!',
  'app.newuser.alt-link': 'Intente un correo electrónico diferente.',

  'app.forgotpassword-form.title': '¿Ha olvidado su contraseña?',
  'app.forgotpassword-form.instruction': '¡No hay problema! Le enviaremos un mensaje a su correo electrónico con un enlace para restablecer su contraseña.',
  // 'app.forgotpassword.instruction1': '¡No hay problema! Le enviaremos un mensaje con un código temporal a su número de teléfono.',
  'app.forgotpassword-form.instruction1': 'Por favor, revise su correo electrónico para el enlace para restablecer su contraseña.',
  'app.forgotpassword-form.send.button': 'Enviar enlace',

  'app.forgotpassword-confirmation.title': 'Gracias por confirmar su correo electrónico.',
  'app.forgotpassword-confirmation.instruction': 'Ahora puede proceder a crear una nueva contraseña.',
  'app.forgotpassword-confirmation.continue.button': 'Continuar',

  'app.reset-password.title': 'Hola, parece que has estado aquí antes.',
  'app.reset-password.instructions': 'Hemos estado haciendo algunas actualizaciones de seguridad y tendrá que restablecer su contraseña para iniciar una sesión. Le hemos enviado un correo electrónico con instrucciones. Puede tardar unos minutos en aparecer en su cuenta.',

  'app.reset-new-password-form.title': 'Por favor, cree una nueva contraseña.',

  'app.account-error.title': 'Ha habido un error',
  'app.account-error.instructions': 'Algo salió mal al acceder a su cuenta. Hemos sido notificados y le informaremos cuándo puede iniciar sesión.',


  'app.create-pass.title': 'Cree una contraseña',
  'app.create-pass.instruction1': 'Contraseña debe: ',
  'app.create-pass.rules1': 'Tener un mínimo de 8 caracteres',
  'app.create-pass.rules2': 'Contener letras mayúsculas y minúsculas',
  'app.create-pass.rules3': 'Contener por lo menos un número',
  'app.create-pass.rules4': 'No puede contener caracteres especiales',
  'app.create-pass.rules5': 'Contraseñas deben coincidir',
  'app.create-password.password': 'Contraseña',
  'app.create-password.reenter-password': 'Escriba la contraseña otra vez',

  'app.inactivity.message.part1': 'Usted no ha hecho ningún movimiento en los últimos minutos.   La seguridad de su información es nuestra prioridad, es por ello que vamos a cerrar su sesión ',
  'app.inactivity.message.time': ' segundos',
  'app.inactivity.message.part2': ' si usted no hace ningún movimiento.',
  'app.inactivity.continue': 'Aún estoy aquí!',

  'app.introduction.locale-link': 'English',
  'app.landing-page.covid19.text1': 'Durante estos tiempos difíciles, ',
  'app.landing-page.covid19.text2': 'TrustPlus™ ',
  'app.landing-page.covid19.text3': 'le puede ayudar a crear un plan para conseguir control de sus gastos y facturas. Hable con uno de nosotros sobre cómo podemos {signUpUrl} o visite nuestra {resourcesUrl}.',
  'app.landing-page.covid19.text4': 'ayudar',
  'app.landing-page.covid19.text5': 'página de recursos',
  'app.landing-page.introduction.sign-up.button-white': 'Registrarse',
  'app.landing-page.introduction.first-step.button': 'Toma el 1er paso en tu camino',
  'app.landing-page.introduction.log-on.button': 'Iniciar Sesión',
  'app.landing-page.introduction.dreams-1': '¿Deseas alcanzar tus metas, pero tus deudas no te dejan?',
  'app.landing-page.introduction.debt': ' ',
  'app.landing-page.introduction.hero-label-mobile': 'Elayna, entrenadora financiera',
  'app.landing-page.introduction.intro-blurb': 'Nuestros consejeros han guiado a miles de personas como tú a empezar el camino para salir de sus deudas y lograr sus metas.',
  'app.landing-page.introduction.intro-tagline': 'Estamos aquí para ayudarte.',
  'app.landing-page.introduction.sign-up.button': '¡Registrarse!',
  'app.landing-page.how-it-works.title': 'Cómo funciona',
  'app.landing-page.how-it-works.text-1': 'Puede hablar con un entrenador por teléfono, video chat o mensaje de texto.',
  'app.landing-page.how-it-works.text-2': 'Se crearán planes financieros individualizados para satisfacer sus necesidades únicas.',
  'app.landing-page.how-it-works.text-3': 'Nuestros entrenadores brindan apoyo personalizado y orientación experta, sin juzgar.',
  'app.landing-page.how-it-works.text-4': 'Todas las conversaciones son 100% confidenciales.',
  'app.landing-page.how-it-works.text-5': 'Utilizamos los más altos estándares tecnológicos para proteger su información personal.',
  'app.landing-page.company-logo.title': 'Auspiciado por',
  'app.landing-page.company-logo.text-1': 'Este servicio es proporcionado por ',
  'app.landing-page.company-logo.text-2': ' sin costo para usted.',
  'app.landing-page.helping-you-succeed.title': 'Ayudándote a triunfar',
  'app.landing-page.helping-you-succeed.title-text-1': 'Nos Contactamos Contigo',
  'app.landing-page.helping-you-succeed.text-1-a': 'Después de elegir su tiempo, un asesor financiero lo simplifica al comunicarse con usted por teléfono o chat de video.',
  'app.landing-page.helping-you-succeed.title-text-2': 'Herramientas en el internet',
  'app.landing-page.helping-you-succeed.text-2': 'Obtenga acceso a su informe crediticio, puntaje FICO y presupuesto fácil.',
  'app.landing-page.helping-you-succeed.title-text-3': 'Estamos aquí para escuchar',
  'app.landing-page.helping-you-succeed.text-3': 'Somos una organización sin fines de lucro impulsada por una misión y nuestros entrenadores no reciben comisiones, por lo que podemos enfocarnos en un plan diseñado para su historial crediticio, su presupuesto y su historia. ',
  'app.landing-page.testimonials.title': 'Testimonios',
  'app.landing-page.testimonials.testimonial-1a': 'La manera en que yo manijo mi dinero ha cambiado completamente y he aprendido la importancia en usar las cuentas correcta y preservando mi crédito.',
  'app.landing-page.testimonials.testimonial-1-red': ' Estoy en una mejor posición financialmente ',
  'app.landing-page.testimonials.testimonial-1b': ' de lo que he estado.',
  'app.landing-page.testimonials.testimonial-author-1': 'Carlos',
  'app.landing-page.testimonials.testimonial-2a': 'Nadie me educo como usar crédito y la verdad es que nunca le puse mente para preguntar hasta que se hizo importante para saber.',
  'app.landing-page.testimonials.testimonial-2-red': ' Teniendo una persona que  te das consejo haces toda la differencia.',
  'app.landing-page.testimonials.testimonial-2b': ' He aprendido mucho.',
  'app.landing-page.testimonials.testimonial-author-2': 'Lundeen',
  'app.landing-page.testimonials.testimonial-3-red': 'Mi consejero financiero ha sido una fuerza de motivación y claridad en mi vida.',
  'app.landing-page.testimonials.testimonial-3a': ' Ha sido consistente, paciente y extremadamente útil. Durante esta pandemia, él me ha puesto en contacto con recursos en mi área y se ha mantenido diligente para asegurarse de que no me abrume con todo lo que está sucediendo financieramente en mi vida ... Estoy agradecida por los servicios de TrustPlus y espero continuar construir mi futuro financiero para mí y mi familia ... Gracias TrustPlus!',
  'app.landing-page.testimonials.testimonial-author-3': 'Arleena',
  'app.landing-page.in-the-press.title': 'En la prensa',
  'app.landing-page.financial-empowerment.button': 'Leer Ahora',
  'app.landing-page.coaches.title': 'Nuestros Consejeros',
  'app.landing-page.coaches.hello-1': '¡Hola, me llamo ',
  'app.landing-page.coaches.hello-2': '!',
  'app.landing-page.coaches.about-me': 'Sobre mí',
  'app.landing-page.coaches.bio.jonathan': 'Después de terminar mi servicio con el Cuerpo de Paz en la República Dominicana como un Voluntario del sector Desarrollo Económico Comunitario, empecé a trabajar como un Asesor Financiero con Neighborhood Trust Financial Partners, una organización sin fines de lucro. Desde mi tiempo en la universidad, me he gustado usar mis habilidades para ayudar otras personas a alcanzar sus metas. Mi parte favorita de mi rol es trabajar con mis clientes para buscar soluciones creativas para resolver sus asuntos. Soy licenciado en Economía desde la Universidad de Notre Dame; me gusta jugar videojuegos, cocinar y ser terrible en el golf.',
  'app.landing-page.coaches.bio.tiffany': 'Soy nativo de California y me mudé a la ciudad de Nueva York para trabajar con Neighborhood Trust. El fin de semana, disfruto yendo a un café donde los perros son bienvenidos, presupuestando y leyendo artículos de finanzas personales. ¡Creo que adoptar un enfoque activo de las finanzas le permite a alguien mantener el control cuando la vida arroja una bola curva (Vamos Dodgers)! Obtuve mi B.A. en Psicología de la Universidad de California en Los Ángeles.',
  'app.landing-page.coaches.bio.elise': 'Je parle français aussi! No hablo español. I am a New Jersey native who has also lived in California, France, Argentina, and Brooklyn. After years in the corporate world, I decided to follow my passion for personal finance and for guiding people through a system that can often feel confusing. I get great satisfaction from helping my clients tackle their financial issues with confidence and purpose. In my downtime, I love to bake cupcakes and solve crossword puzzles',
  'app.landing-page.coaches.bio.rosie': 'No hablo español. My passion for personal finance emerged out of my own experiences budgeting, building credit, saving, and managing student loans. This passion drew me to my current role as a Financial Counselor at Neighborhood Trust, where I can share all the tools I’ve learned with others and help them reach their financial goals. I was born and raised in Brooklyn, received my BA from Occidental College, and am currently pursuing an MPA from Baruch College. I hope to eventually use my understanding of how policies affect individuals to drive positive change in the broader field of financial empowerment. In my spare time I play soccer, explore new restaurants, and spend time with family.',
  'app.landing-page.coaches.bio.christina': 'Con la pasión de ayudar y servir a mi comunidad, decidí unirme a Neighborhood Trust Financial Partners como consejera financiera. Soy capaz de proporcionarles a las personas las herramientas que necesitan para adquirir poder financiero. Con experiencia en asesoría universitaria, pude ayudar a cientos de estudiantes con sus solicitudes de ayuda financiera y de universidad. Tengo una licenciatura en psicología y soy nativo de Nueva York. Mis pasatiempos son leer libros sobre finanzas personales, negocios, superación personal y liderazgo.',
  'app.landing-page.coaches.bio.chris': 'Me uní a Neighborhood Trust como Consejero Financiero en el 2018. Me apasiona ayudar a las personas y capacitarlas con la educación y las herramientas que necesitan para alcanzar sus objetivos financieros. Creo firmemente que el bienestar financiero es esencial para construir comunidades prósperas. Tengo una licenciatura en Inglés de Lehman College.',
  'app.landing-page.coaches.speak-with.button': 'Habla con un asesor',
  'app.landing-page.tagline.title': '¡Empiece hoy!',
  'app.landing-page.tagline.text': 'Es fácil comenzar con TrustPlus. Simplemente haga clic en "Registrarse" y seleccione su sesión de 30 minutos.',
  'app.landing-page.tagline.get-started.button': '¡Empezar!',
  //775 Landing Page
  'app.landing-page.775.intro-text-1': 'Consejería  financiera gratuita ',
  'app.landing-page.775.intro-text-2': 'y 100% ',
  'app.landing-page.775.intro-text-3': 'confidencial para ',
  'app.landing-page.775.intro-text-4': 'ayudarte ',
  'app.landing-page.775.intro-text-5': 'a ahorrar, reducir deudas ',
  'app.landing-page.775.intro-text-6': ' y ',
  'app.landing-page.775.intro-text-7': 'mejorar ',
  'app.landing-page.775.intro-text-8': 'tu ',
  'app.landing-page.775.intro-text-9': 'puntaje crediticio',
  'app.landing-page.775.intro-text-10': '.',
  'app.landing-page.775.intro-text-11': 'Programa una sesión hoy con un consejero financiero de TrustPlus por teléfono o chat de video.',
  'app.landing-page.775.intro-text-12': 'Programa tu sesión',
  'app.landing-page.775.how-we-help-1': 'Cómo hemos ayudado',
  'app.landing-page.775.how-we-help-2': 'Escuche a dos de nuestros clientes mientras comparten cómo TrustPlus los ayudó a lograr sus objetivos financieros, que van desde comprar una casa hasta pagar la deuda de un préstamo estudiantil. Dos de nuestros consejeros financieros expertos también explican su función y por qué les apasiona ayudar a sus clientes a alcanzar sus objetivos: ningún objetivo financiero es demasiado grande o demasiado pequeño.',
  'app.landing-page.775.how-we-help-3': '¡Nuestros consejeros financieros expertos están listos para escucharlo y ayudarlo también!',
  'app.landing-page.775.help-you-1': 'Cómo te podemos ayudar',
  'app.landing-page.775.help-you-2': 'Reúnase con un consejero financiero',
  'app.landing-page.775.help-you-3': 'Es gratis programar una sesión de 30 minutos con uno de nuestros consejeros financieros expertos.',
  'app.landing-page.775.help-you-4': 'Encuentra tiempo',
  'app.landing-page.775.help-you-5': 'Habla con nosotros',
  'app.landing-page.775.help-you-6': '¿No tienes tiempo para una sesión de 30 minutos? Envíanos un chat y lo resolveremos.',
  'app.landing-page.775.help-you-5-a': 'Chatear con nosotros',
  'app.landing-page.775.help-you-7': 'Aprende con nosotros',
  'app.landing-page.775.help-you-8': 'Hemos preparado materiales exclusivos para ayudarte a aprovechar al máximo tu dinero.',
  'app.landing-page.775.help-you-9': 'Aprende ahora',

  //DCL Landing page
  'app.dcl-landing-page.how-it-works.title': 'Cómo Funciona',
  'app.dcl-landing-page.testimonials.title': 'Testimonios',
  'app.dcl-landing-page.our-impact.title': 'Nuestro Impacto',
  'app.dcl-landing-page.who-we-are.title': 'Quiénes Somos',
  'app.dcl-landing-page.benefits.title': 'Benefits of Debt Consolidation',
  'app.dcl-landing-page.faq.title': 'Preguntas Frecuentes',
  'app.dcl-landing-page.contact.title': 'Contáctanos',
  'app.dcl-landing-page.introduction.title-1': 'Simplifica Tu',
  'app.dcl-landing-page.introduction.title-2': 'Deuda De Tarjetas de Crédito',
  'app.dcl-landing-page.introduction.intro-blurb': 'Nuestros expertos consejeros financieros encuentran los mejores préstamos de consolidación de deuda de cooperativas de crédito verificadas, ahorrándote tiempo y dinero.',
  'app.dcl-landing-page.introduction.intro-blurb-2': '¡Responde tres preguntas simples',
  'app.dcl-landing-page.introduction.intro-blurb-3': ' para comenzar!',
  'app.dcl-landing-page.introduction.first-step.button': 'Toma el preevaluador',
  'app.dcl-landing-page.benefits.title-1': 'Beneficios de un Préstamo de Consolidación de Deuda',
  'app.dcl-landing-page.benefits.text-1': 'Los préstamos de consolidación de deuda te permiten combinar múltiples deudas en un solo préstamo con un pago mensual y una tasa de interés más baja. Nuestros expertos consejeros  de TrustPlus buscan rigurosamente los mejores préstamos de consolidación de deuda. No ofrecemos los préstamos directamente, ni recibimos compensación por nuestras recomendaciones. Nuestro compromiso es contigo - el cliente.',
  'app.dcl-landing-page.benefits.subtitle-1': 'Cómo puedes beneficiarte de un préstamo de consolidación de deuda',
  'app.dcl-landing-page.benefits.subtitle-2': '¡Toma el rápido preevaluador para comenzar!',
  'app.dcl-landing-page.benefits.text-5': 'El preevaluador NO Afectará su puntaje de crédito.',
  'app.dcl-landing-page.how-it-works.subtitle-1': 'Ahorra dinero en intereses',
  'app.dcl-landing-page.how-it-works.text-1': 'Consolidando la deuda de tarjetas de crédito de alto interés a un préstamo de interés más bajo puede ayudarte a ahorrar dinero y liberarte de deudas más rápido.',
  'app.dcl-landing-page.how-it-works.subtitle-2': 'Una sola factura, menos estrés',
  'app.dcl-landing-page.how-it-works.text-2': '¡Simplifique el manejo de sus deudas combinando múltiples pagos en uno!',
  'app.dcl-landing-page.how-it-works.subtitle-3': 'Acceso a Consejeria Financiera',
  'app.dcl-landing-page.how-it-works.text-3': 'Nuestros consejeros ofrecen orientación personalizada y apoyo adaptado a tus necesidades.',
  'app.dcl-landing-page.how-it-works.subtitle-4': 'Acceso a Productos Verificados',
  'app.dcl-landing-page.how-it-works.text-4': 'Hemos hecho el trabajo pesado por ti, y no ganamos ni un centavo por nuestras recomendaciones. ¡Se trata de conseguirte el mejor producto!',
  'app.dcl-landing-page.eliminate-debt.title-1': 'Elimina tu deuda más rápido con una consolidación de deudas',
  'app.dcl-landing-page.testimonials.testimonial-1': '“Trabajar con Tiffany me quitó un gran peso de encima. Su apoyo y constencia fueron de gran ayuda. No podría haber logrado todo por mi propia cuenta”',
  'app.dcl-landing-page.testimonials.testimonial-author-1': '—Yakaira',
  'app.dcl-landing-page.testimonials.testimonial-2': '“Estaba al máximo con mis tarjetas de crédito. Los intereses me estaban matando. Mi consejero financiero me enderezó y ahora puedo ahorrar una pequeña cantidad cada mes”',
  'app.dcl-landing-page.testimonials.testimonial-author-2': '—Edward',
  'app.dcl-landing-page.testimonials.testimonial-3': '“Gracias a Elise, pude acceder a un préstamo que redujo mi pago mensual de deuda en $146, ayudándome a ahorrar más de $4,000 en intereses y a salir de deudas 8 meses más rápido”',
  'app.dcl-landing-page.testimonials.testimonial-author-3': '—Annette',
  'app.dcl-landing-page.testimonials.testimonial-4': '“Gracias a TrustPlus, estoy reduciendo mis deudas. Después de explorar varias opciones, encontré el mejor camino para mí. Me siento mucho más seguro de que alcanzaré mis objetivos mucho más antes de lo previsto.”',
  'app.dcl-landing-page.testimonials.testimonial-author-4': '—James',
  'app.dcl-landing-page.our-impact.text-1': 'Reducción Mediana de Deuda por Cliente',
  'app.dcl-landing-page.our-impact.text-2': 'Reducción Total Agregada de Deuda en Todos los Clientes',
  'app.dcl-landing-page.our-impact.text-3': 'Ahorro Total Acumulado',
  'app.dcl-landing-page.who-we-are.text-1': 'TrustPlus es un servicio de Neighborhood Trust Financial Partners, una organización sin fines de lucro dedicada a su misión, que se basa en 30 años de liderazgo y experiencia en servicios financieros.',
  'app.dcl-landing-page.who-we-are.text-2': 'En los Estados Unidos, los consejeros financieros de TrustPlus trabajan individualmente con sus clientes para ayudarlos a reducir sus deudas, fortalecer su perfil de crédito y aumentar sus ahorros. Estas relaciones  generan un impacto duradero en los clientes.',
  'app.dcl-landing-page.who-we-are.text-3': 'Nuestro compromiso es únicamente contigo. Verificamos las instituciones financieras para encontrar el mejor producto que ayude a nuestros clientes a manejar mejor sus deudas y alcanzar sus objetivos financieros. Y hacemos esto sin recibir ni un centavo por nuestras recomendaciones.',
  'app.dcl-landing-page.who-we-are.image-caption': 'Elise, TrustPlus Consejero Financiero',
  'app.dcl-landing-page.faq.question-1': '¿Qué es un préstamo de consolidación de deuda?',
  'app.dcl-landing-page.faq.answer-1': 'Un préstamo de consolidación de deuda combina múltiples deudas en un solo préstamo con una tasa de interés más baja, ayudándote a ahorrar dinero y a pagar tu deuda más rápido. ¡Es una forma conveniente de simplificar tus deudas y reducir el estrés financiero!',
  'app.dcl-landing-page.faq.question-2': '¿Cuál es la diferencia entre un préstamo de consolidación de deuda y un plan de manejo de deudas?',
  'app.dcl-landing-page.faq.answer-2': 'Un préstamo de consolidación de deuda combina múltiples deudas en un nuevo préstamo con una tasa de interés más baja, simplificando los pagos y ahorrándote dinero en intereses. Un plan de manejo de deudas reestructura tu deuda existente sin un nuevo préstamo. Aunque simplifica tus pagos, puede que no sea necesariamente la mejor solución.',
  'app.dcl-landing-page.faq.question-3': '¿Cómo funciona la consolidación de deuda en TrustPlus?',
  'app.dcl-landing-page.faq.answer-3': 'Sabemos lo estresante que puede ser encontrar el mejor producto financiero. ¡Por eso hacemos el trabajo por ti! Recomendamos a nuestros clientes solo los préstamos de consolidación de deuda de cooperativas de crédito verificadas y de confianza. Y no ganamos ni un centavo con estas recomendaciones. Nuestro compromiso es contigo, el cliente.',
  'app.dcl-landing-page.faq.question-4': '¿Qué sucede después de tomar el preevaluador?',
  'app.dcl-landing-page.faq.answer-4': 'Basado en tus respuestas, se te pedirá que programes una consulta con un consejero financiero de TrustPlus para explorar tus opciones.',
  'app.dcl-landing-page.faq.question-5': '¿Cómo puedo contactar a TrustPlus?',
  'app.dcl-landing-page.faq.answer-5.text-1': 'Puedes enviarnos un correo electrónico a ',
  'app.dcl-landing-page.faq.answer-5.email': 'support@mytrustplus.org',
  'app.dcl-landing-page.faq.answer-5.text-2': '. ¡Esperamos poder servirle!',
  'app.dcl-landing-page.get-started.title': '¡Empiece hoy!',
  'app.dcl-landing-page.tagline.take-prescreener.button': 'Toma el preevaluador',
  'app.dcl-landing-page.survey.question1-header': 'Pregunta 1 of 3: ',
  'app.dcl-landing-page.survey.question1': '¿Cuál es su estatus laboral?',
  'app.dcl-landing-page.survey.question2-header': 'Pregunta 2 of 3:',
  'app.dcl-landing-page.survey.question2': '¿Cuál es su ingreso anual?',
  'app.dcl-landing-page.survey.question3-header': 'Pregunta 3 of 3:',
  'app.dcl-landing-page.survey.question3': '¿Sabe su puntaje de crédito?',
  'app.dcl-landing-page.youtube': 'https://www.youtube.com/embed/EIa_O7CEMtk',
  'app.dcl-landing-page.aria-label.hero': 'Residentes de Baltimore, Maryland felices tras consolidar deudas de tarjetas de credito con préstamos evaluados por TrustPlus',
  'app.dcl-landing-page.aria-label.dcl-survey-start': 'El preevaluador de préstamos de consolidación de deudas tiene tres preguntas sobre el empleo, ingresos y puntaje de crédito',
  'app.dcl-landing-page.aria-label.testimonial-1': 'Yakaira, una cliente de TrustPlus, se siente esperanzada después de que su consejera financiera la ayudó a alcanzar sus metas financieras',
  'app.dcl-landing-page.aria-label.testimonial-2': 'Edward, cliente de TrustPlus, relajado al explicar cómo su consejero financiero lo ayudó a reducir su deuda y ahorrar dinero',
  'app.dcl-landing-page.aria-label.testimonial-3': 'Cliente de TrustPlus, Annette, relata cómo redujo sus pagos de deuda y ahorró miles en intereses con su consejero financiero',
  'app.dcl-landing-page.aria-label.testimonial-4': 'James, cliente de TrustPlus, dice con confianza cómo su consejero financiero lo ayudó a reducir su deuda y lograr sus metas',
  'app.dcl-landing-page.aria-label.who-we-wre-photo': 'Elise, una consejera financiera dedicada de TrustPlus, comparte su conocimiento durante una consulta remota con un cliente',


  // DCL Prescreener
  'app.dcl-prescreener.employment-status.answer.full-time': "Trabajo Tiempo Completo",
  'app.dcl-prescreener.employment-status.answer.part-time': "Trabajo Medio Tiempo",
  'app.dcl-prescreener.employment-status.answer.self-employed': "Trabajo Independiente",
  'app.dcl-prescreener.employment-status.answer.unemployed': "Desempleado",
  'app.dcl-prescreener.annual-income.answer.0-20': "$0 - $20,000",
  'app.dcl-prescreener.annual-income.answer.20-40': "$20,001 - $40,000",
  'app.dcl-prescreener.annual-income.answer.40-60': "$40,001 - $60,000",
  'app.dcl-prescreener.annual-income.answer.60+': " Más de $60,000",
  'app.dcl-prescreener.credit-score.answer.yes-under-620': "Sí - es 619 o menor",
  'app.dcl-prescreener.credit-score.answer.yes-620-or-over': "Sí - es 620 o superior",
  'app.dcl-prescreener.credit-score.answer.no': "No sé",
  'app.dcl-prescreener.placeholder': "Seleccione del menú desplegable",

  // Footer links
  'app.footer-link-landing-page.follow': 'Síguenos',
  'app.footer-link-landing-page.contact': 'Contacto',
  'app.footer-link-landing-page.join': 'Únase a nuestra lista',
  'app.footer-link-landing-page.copyright': 'Copyright ©',
  'app.footer-link-landing-page.neighborhood-trust': ' Neighborhood Trust',
  'app.footer-link-landing-page.all-rights-reserved': 'Todos los derechos reservados.',
  'app.footer-link-landing-page.tos' : 'Términos de Servicio',
  'app.footer-link-landing-page.privacy-policy' : 'Política de privacidad',
  'app.footer-link-landing-page.faq': 'FAQ',
  'app.footer-link-landing-page.pipe': '| ',



  'app.spinner.loading': 'Cargando',
  'app.shift-desktop-and-mobile-provider.more': 'más',
  'app.shift-desktop-and-mobile-provider.less': 'menos',
  'app.shift-desktop-and-mobile-provider.view-bio': 'Ver biografía completa',
  'app.shift-table.dayname.Sunday': 'Domingo',
  'app.shift-table.dayname.Monday': 'Lunes',
  'app.shift-table.dayname.Tuesday': 'Martes',
  'app.shift-table.dayname.Wednesday': 'Miércoles',
  'app.shift-table.dayname.Thursday': 'Jueves',
  'app.shift-table.dayname.Friday': 'Viernes',
  'app.shift-table.dayname.Saturday': 'Sábado',
  'app.shift-table.dayname.Sun': 'D.',
  'app.shift-table.dayname.Mon': 'L',
  'app.shift-table.dayname.Tue': 'M',
  'app.shift-table.dayname.Wed': 'X',
  'app.shift-table.dayname.Thu': 'J',
  'app.shift-table.dayname.Fri': 'V',
  'app.shift-table.dayname.Sat': 'S',
  'app.shift-table.monthname.January': 'Enero',
  'app.shift-table.monthname.February': 'Febrero',
  'app.shift-table.monthname.March': 'Marzo',
  'app.shift-table.monthname.April': 'Abril',
  'app.shift-table.monthname.May': 'Mayo',
  'app.shift-table.monthname.June': 'Junio',
  'app.shift-table.monthname.July': 'Julio',
  'app.shift-table.monthname.August': 'Agosto',
  'app.shift-table.monthname.September': 'Septiembre',
  'app.shift-table.monthname.October': 'Octubre',
  'app.shift-table.monthname.November': 'Noviembre',
  'app.shift-table.monthname.December': 'Diciembre',
  'app.registration.introduce-yourself': 'Preséntese.',
  'app.registration.back-link-text': '\<\< Regresar',
  'app.registration.session-time-heading': 'Fecha y hora de su cita: ',
  'app.registration.at': 'a las',
  'app.registration.with': 'con',
  'app.registration.more-info-required': 'Necesitamos saber un poco más sobre usted para confirmar tu cita.',
  'app.registration.input.first-name': 'Nombre',
  'app.registration.input.last-name': 'Apellido',
  'app.registration.input.phone-number': 'Número de teléfono',
  'app.registration.input.email': 'Correo electrónico',
  'app.registration.appointment-format-heading': 'Quisiera que mi primera sesión sea',
  'app.registration.gender.': 'Seleccione una de las siguientes',
  'app.registration.gender.optional': '(opcional)',
  'app.registration.gender.what-describes-you': '¿Qué describe más de cerca su género?',

  'app.registration.gender.answer.Female': 'Mujer',
  'app.registration.gender.answer.Male': 'Hombre',
  'app.registration.gender.answer.Non-Binary': 'No-binario',
  'app.registration.gender.answer.Agender': 'agénero',
  'app.registration.gender.answer.Man': 'Hombre transgénero',
  'app.registration.gender.answer.Transgender Woman': 'Mujer transgénero',
  'app.registration.gender.answer.Prefer Not To State': 'prefiero no decir',
  'app.registration.gender.answer.Other': 'déjame escribirlo',

  'app.registration.gender-pronouns.question': 'Para ayudar a entender cómo dirigirse mejor a usted, ¿qué pronombres le gustaría que usáramos?',
  'app.registration.gender-pronouns.answer.She/her': 'ella',
  'app.registration.gender-pronouns.answer.He/him': 'él',
  'app.registration.gender-pronouns.answer.They/Them': 'ellos',
  'app.registration.gender-pronouns.answer.Prefer not to state': 'prefiero no decir',
  'app.registration.gender-pronouns.answer.Other': 'déjame escribirlo',

  'app.registration.phone-appointment-radio.button': 'Teléfono',
  'app.registration.skype-appointment-radio.button': 'Skype',
  'app.registration.video-chat-appointment-radio.button': 'Vídeo Chat',
  'app.registration.video-chat': 'Servicio de video chat',
  'app.registration.video-chat.answer.Please select from the following':'Por favor seleccione de lo siguiente',
  'app.registration.video-chat.answer.Google Meet': 'Google Meet',
  'app.registration.video-chat.answer.Zoom': 'Zoom',
  'app.registration.tty-appointment-radio.button': 'TRS para las personas sorderas o duro de oído',
  'app.registration.agreement.description': 'Acepto los {termsLink} & {privacyLink}.',
  'app.registration.agreement.terms-link': 'términos de servicio',
  'app.registration.agreement.privacy-link': 'política de privacidad',
  'app.registration.submit.button': 'Confirmar',
  'app.registration.tos.accion': 'Al aceptar nuestras condiciones de servicio y política de privacidad, usted también acepta que TrustPlus comparta su nombre y nivel de participación con Accion, para propósitos del programa de evaluación. Toda su información financiera, incluyendo cualquier cosa que se discuta durante la sesión de consejería, va a permanecer confidencial.',
  'app.registration.tos.unitedway': 'Al aceptar nuestras condiciones de servicio y política de privacidad, usted también acepta que TrustPlus comparta su nombre y nivel de participación con Connecticut United Way\'s Alice Saves, para propósitos del programa de evaluación. Toda su información financiera, incluyendo cualquier cosa que se discuta durante la sesión de consejería, va a permanecer confidencial.',
  'app.registration.tos.start-small-think-big': 'Al aceptar nuestras condiciones de servicio y política de privacidad, usted también acepta que TrustPlus comparta su nombre y nivel de participación con Start Small Think Big, para propósitos del programa de evaluación. Toda su información financiera, incluyendo cualquier cosa que se discuta durante la sesión de consejería, va a permanecer confidencial.',
  'app.registration.tos.feeding-america': 'Al aceptar nuestras condiciones de servicio y política de privacidad, usted también acepta que TrustPlus comparta su nombre y nivel de participación con Feeding America, para propósitos del programa de evaluación. Toda su información financiera, incluyendo cualquier cosa que se discuta durante la sesión de consejería, va a permanecer confidencial.',
  'app.create-appointment.input.first-name': 'Nombre',
  'app.create-appointment.input.last-name': 'Apellido',
  'app.create-appointment.input.email': 'Correo electrónico',
  'app.create-appointment.input.phone-number' : 'Número Celular',
  'app.create-appointment.submit.button': 'Continuar',
  'app.registration.share-name-terms.consent-given': 'Al aceptar nuestras condiciones de servicio y política de privacidad, usted también acepta que TrustPlus comparta su nombre y nivel de participación con {orgName} para propósitos del programa de evaluación. Toda su información financiera, incluyendo cualquier cosa que se discuta durante la sesión de consejería, va a permanecer confidencial.',
  'app.registration.tos.agree': 'Acepto',
  'app.registration.tos.disagree': 'No acepto',


  //Sign up Beta Test flow
  'app.beta-test.sign-up-email.text.1': 'Regístrese con su correo electrónico',
  'app.beta-test.sign-up-email.text.2': 'Para comenzar el viaje hacia la libertad financiera.',
  'app.beta-test.sign-up-password.text.1': 'Las contraseñas deben contener todo lo siguiente: al menos 8 caracteres, letras mayúsculas y minúsculas, un número y ningún carácter especial.',
  'app.beta-test.sign-up-password.text.2': 'Ingrese la contraseña dos veces. Las contraseñas deben coincidir.',

  'app.terms.title': 'Términos de servicio',
  'app.terms.terms.title.revision.date': 'Última revisión: 10/2020',
  'app.terms.terms.intro.1': 'Gracias por usar los servicios de Neighborhood Trust Financial Partners, LLC y sus subsidiarios, socios y filiales (que pueden incluir denominaciones como “nosotros” o “Neighborhood Trust” en el presente documento y la aplicación, y en nuestro sitio web o materiales de marketing relacionados). Nuestros servicios (los “Servicios”) se proporcionan expresamente con los fines de crear capacidad financiera por parte de los empleados de nuestros socios y otros clientes inscritos directamente con nosotros.',
  'app.terms.terms.intro.2': 'Los términos contenidos en este documento (los “Términos de servicio”), que se modifican cada cierto tiempo, se aplican a todos los individuos que usan el sitio web o las aplicaciones web de Neighborhood Trust, que crean una cuenta con Neighborhood Trust o que utilizan de otro modo los Servicios de Neighborhood Trust (cada uno de ellos “Usuario”) y rigen el acceso de cada Usuario y su uso de este sitio web, en {neighborhoodTrustLink}, y otros sitios web que enlacen directamente con estos Términos de servicio, así como nuestro contenido, herramientas, funciones, productos o servicios accesibles desde dichos sitios web (colectivamente, el “Sitio”). ',
  'app.terms.terms.intro.3a': 'Por favor lea atentamente estos Términos de servicio al acceder o utilizar el Sitio web. ',
  'app.terms.terms.intro.3b': 'Al usar el Sitio [o al hacer clic para aceptar o admitir estos Términos cuando se le presente la opción], usted acepta y admite estar sujeto a estos Términos, y dichos Términos constituyen un contrato vinculante entre usted y Neighborhood Trust.',
  'app.terms.terms.intro.3c': 'Si usted, como individuo, actúa por o en nombre de una empresa, organización u otra entidad, como por ejemplo su empleador, entonces cuando accede o usa al Sitio web: (i) usted declara que está plenamente autorizado para acceder o utilizar el Sitio web; (ii) acepta estar sujeto a estos Términos de servicio en su propio nombre y en el de dicha entidad; y (iii) “usted” se referirá colectivamente a usted y dicha entidad.',
  'app.terms.terms.intro.4': 'Si usted no acepta o no admite estos Términos de servicio, no estará autorizado y no deberá acceder ni utilizar los Servicios ni el Sitio web.',
  'app.terms.terms.intro.5': 'Usted no puede usar ninguno de los Servicios y no puede aceptar estos Términos de servicio si no está legalmente autorizado para aceptar y estar sujeto a estos términos o no tiene al menos 18 años de edad y, en cualquier caso, de edad legal para formalizar un contrato vinculante con Neighborhood Trust.',
  'app.terms.terms.intro.6': 'Antes de continuar, debería imprimir o guardar una copia local de estos Términos de servicio para sus propios archivos.',
  'app.terms.terms.intro.7' : 'El único fin de nuestros Servicios es ayudar a los Usuarios a crear empoderamiento financiero, incluyendo pero sin estar limitados  la administración de su dinero, elección de productos financieros, análisis de presupuestos y gastos, y asesoramiento financiero general. Estos servicios solo están disponibles a los residentes de los Estados Unidos.',

  'app.terms.terms.section.1.header': 'Derecho de no participación',
  'app.terms.terms.section.1.text.1': 'La participación en estos Servicios es voluntaria y usted puede revocar cualquiera de los permisos de este documento en cualquier momento, incluido antes de que empiece su participación en estos Servicios, notificando a Neighborhood Trust por escrito. Consulte la información sobre cómo contactarse con nosotros al final de este documento.',
  'app.terms.terms.section.1b.header': 'Descripción de los Servicios',
  'app.terms.terms.section.1b.text.1': 'Los Servicios consisten en consejería financiera personal general y servicios de administración de información financiera que le permiten analizar su información financiera, mientras recibe ayuda y consejo sobre crédito, deudas, presupuestos y ahorros. Los Servicios de Neighborhood Trust le proporcionan a usted información que le permitirá manejar sus finanzas sin ningún costo para usted. ',
  'app.terms.terms.section.1b.text.2': 'Estos Servicios pueden estar disponibles para usted a través de su empleador como un beneficio voluntario para empleados o a través de una referencia por parte de socios terceros. Neighborhood Trust puede recibir remuneración de los empleadores y de los socios de referencia con el fin de proporcionar servicios de consejería financiera confidencial. Su empleador/patrocinador del plan determina su elegibilidad para cada beneficio. Si está inscrito en los Servicios bajo un empleador o un programa patrocinado por terceros, las disposiciones de estos Términos de servicio que se le aplican a usted pueden diferir en uno o varios modos de las establecidas arriba, pero solamente si se le notifica por escrito.',
  'app.terms.terms.section.1b.text.3': 'Los Servicios de Neighborhood Trust también pueden incluir un servicio que proporciona su resumen de crédito de Transunion y su calificación crediticia FICO.',
  'app.terms.terms.section.1b.text.4': 'El Sitio proporciona diversas herramientas y funciones a través del sitio web y de la aplicación web, incluido un servicio de administración de información personal que le permite consolidar y hacer seguimiento de cierta información personal. ',
  'app.terms.terms.section.1b.text.5': 'Los Servicios también pueden ofrecerle información relativa a productos o servicios de terceros en los que podría estar interesado. Los Servicios también pueden proporcionarle consejos generales, acciones a tomar, y material educativo, incluyendo el diseño de un plan financiero personal a la medida de su situación y necesidades específicas. A menos que se indique expresamente lo contrario, Neighborhood Trust no recibe compensación alguna por proporcionar enlaces y explicar los beneficios de cualquier servicio o producto financiero específico proporcionado por terceros. ',

  'app.terms.terms.section.2.header': 'Autorización de informe de crédito',
  'app.terms.terms.section.2.text.1': 'Cuando usted acepta los Términos de servicio y hace clic en “Obtener mi informe de crédito” le da permiso a Neighborhood Trust para obtener su informe de crédito de cualquiera de las tres agencias de informes de crédito durante el periodo de uso de nuestros Sitios o Servicios y durante hasta veintiséis (26) meses posteriores para fines de evaluación del programa. Su calificación crediticia no se verá afectada.',

  'app.terms.terms.section.3.header': 'Usos permitidos',
  'app.terms.terms.section.3.subheader.1': 'Licencia limitada: ',
  'app.terms.terms.section.3.text.1': 'Neighborhood Trust le proporciona a usted una licencia de uso revocable para utilizar el Sitio y los Servicios, y todos los términos, derechos de autor, marcas registradas, marcas de servicio, nombres de marcas, nombres comerciales, logotipos, diseños, gráficos, ilustraciones, imágenes, videos, fotografías y toda la propiedad intelectual de cualquier tipo utilizada en ellos, únicamente con el propósito de recibir y usar los Servicios y otros usos aprobados del Sitio. ',
  'app.terms.terms.section.3.subheader.2': 'Usos aprobados: ',
  'app.terms.terms.section.3.text.2': 'nuestros Servicios se proporcionan para ofrecer orientación financiera accionable. El Sitio solamente puede ser utilizado por usted para la obtención de los Servicios. El Sitio solamente puede ser utilizado por Usuarios aprobados e invitados a discreción de Neighborhood Trust. No puede usted registrarse con más de una cuenta ni registrar una cuenta en nombre de otro individuo distinto de usted o en nombre de un grupo o entidad. Su derecho de acceder y usar el Sitio y los Servicios es personal y usted no puede transferírselo a ninguna otra persona ni entidad. Solamente está autorizado para acceder y usar el Sitio y los Servicios con fines legales. La documentación integra hace posible que Neighborhood Trust pueda proporcionarle los Servicios. Usted debe  proporcionar información verdadera, precisa, actual y completa sobre las cuentas que mantiene en otros sitios web. No debe tergiversar su Contenido e Información de cuenta (ambos descritos posteriormente) proporcionados a Neighborhood Trust. Para que los Servicios funcionen eficazmente, debe mantener dicho Contenido e Información de cuenta actualizados y exactos. Si no lo hace, se verán afectadas la precisión y eficacia de los Servicios. Usted declara que es el propietario legal de la información de registro y está autorizado a proporcionarnos dicha  información, Información de cuenta y otra información necesaria para facilitarle el uso de los Servicios.',
  'app.terms.terms.section.3.subheader.3': 'Decencia: ',
  'app.terms.terms.section.3.text.3': 'usted no utilizará lenguaje profano, despectivo ni irrespetuoso en sus solicitudes y comunicaciones en el Sitio. Neighborhood Trust puede suspender o prohibir cualquier cuenta de Usuario que, a su entera discreción, no cumpla estos Términos de servicio.',
  'app.terms.terms.section.3.subheader.4': 'Legalidad: ',
  'app.terms.terms.section.3.text.4': 'usted no utilizará los Servicios ni el Sitio de tal modo que se violen leyes o tratados estatales, federales o internacionales.',
  'app.terms.terms.section.3.subheader.5': 'Uso comercial: ',
  'app.terms.terms.section.3.text.5': 'usted no utilizará los Servicios o el Sitio, ni ninguna de sus partes o funciones para generar ingresos comerciales sin el consentimiento expreso por escrito de Neighborhood Trust.',
  'app.terms.terms.section.3.subheader.6': 'Uso automático: ',
  'app.terms.terms.section.3.text.6': 'usted acepta no:',
  'app.terms.terms.section.3.text.6a': 'Usar ningún robot, spider, herramienta para extraer información web, enlace profundo ni otras herramientas, programas, algoritmos o metodologías automatizadas de extracción o recopilación de datos similares para acceder, adquirir, copiar o monitorear a Neighborhood Trust, el Sitio o los Servicios, o cualquier parte de ellos sin el consentimiento previo expreso por escrito de Neighborhood Trust, el que podrá ser denegado a la entera discreción de Neighborhood Trust;',
  'app.terms.terms.section.3.text.6b': 'Usar ni intentar usar ningún motor (engine), software, herramientas, agente u otro dispositivo o mecanismo (incluidos sin limitación navegadores, spiders, robots, avatares o agentes inteligentes) para navegar o buscar Neighborhood Trust, diferentes a los motores y agentes de búsqueda disponibles a través de los Servicios y otros navegadores web de terceros generalmente disponibles (como Microsoft Internet Explorer y Chrome);',
  'app.terms.terms.section.3.text.6c': 'Publicar o transmitir un archivo que contenga virus, gusanos (worms), troyanos o cualquier otra característica contaminante o destructiva, o que interfiera de cualquier otro modo con el funcionamiento correcto de los Sitios o de los Servicios de Neighborhood Trust; o',
  'app.terms.terms.section.3.text.6d': 'Intentar descifrar, descompilar, desensamblar o aplicar ingeniería inversa a cualquier software que compone o que de algún modo forma parte de Neighborhood Trust o de los Servicios.',
  'app.terms.terms.section.3.subheader.7': 'Uso seguro: ',
  'app.terms.terms.section.3.text.7': 'usted será responsable de mantener la privacidad y seguridad de sus contraseñas y otra información de seguridad utilizada en relación a los Servicios o el Sitio. Usted será también responsable de mantener la integridad de su hardware y sistema operativo para prevenir que virus, spyware u otro software malintencionado se apropien indebidamente de sus datos o información de contraseñas. Debería tomar precauciones al acceder a los Servicios o al Sitio desde computadoras públicas o compartidas para que los demás no puedan acceder a su información personal. Neighborhood Trust no se responsabiliza del acceso no autorizado a su cuenta de Neighborhood Trust como resultado de que usted no haya tomado las medidas de precaución de seguridad apropiadas. Los usuarios no compartirán, publicarán ni filtrarán de ningún otro modo sus credenciales de seguridad. Si usted descubre que se ha producido un uso no autorizado de su cuenta o información de usuario o si cree que su cuenta puede haberse visto comprometida de cualquier otro modo o que se ha presentado un incumplimiento de seguridad, usted accede a notificar inmediatamente a Neighborhood Trust enviando un correo electrónico a: {mailToSupportAction} .',
  'app.terms.terms.section.3.subheader.8': 'Restricciones de edad: ',
  'app.terms.terms.section.3.text.8': 'para poder usar el Sitio o Servicios debe usted tener 18 años de edad o más.',
  'app.terms.terms.section.3.subheader.9': 'Residencia: ',
  'app.terms.terms.section.3.text.9' : 'Para utilizar nuestros servicios, debe ser residente de los Estados Unidos.',

  'app.terms.terms.section.4.header': 'Comunicaciones',
  'app.terms.terms.section.4.subheader.1': 'Avisos, alertas y mensajes: ',
  'app.terms.terms.section.4.text.1': 'usted recibirá notificaciones voluntarias, alertas automáticas y otras comunicaciones por parte nuestra relacionadas con los fines institucionales del Sitio y los Servicios. Puede que se le envíen alertas automáticas después de determinados cambios en su cuenta o información, como por ejemplo, cambio en el Contenido (definido más abajo) o información de su cuenta proporcionados a Neighborhood Trust. Dichos avisos pueden ser proporcionados por correo electrónico, mensaje de texto (SMS), MMS, llamada telefónica u otros medios.',
  'app.terms.terms.section.4.text.1a': 'Puede que las notificaciones voluntarias estén activadas automáticamente como parte de los Servicios. Usted puede desactivar dichas notificaciones al comunicarse con su consejero de Neighborhood Trust. Neighborhood Trust puede añadir nuevas notificaciones de vez en cuando o dejar de enviar determinadas notificaciones en cualquier momento a su entera discreción.',
  'app.terms.terms.section.4.text.1b': 'Usted entiende y acepta que cualquier alerta o notificación que se le proporcione a través de los Servicios o el Sitio puede retrasarse o cancelarse por diversos motivos. Neighborhood Trust puede realizar los esfuerzos comerciales razonables para proporcionar notificaciones o alertas en el momento oportuno con información precisa, pero no puede garantizar la entrega, puntualidad ni precisión del contenido de cualquier alerta. Neighborhood Trust no será responsable de retrasos, incumplimientos de entregas o entregas equivocadas de alertas, de errores en el contenido de una alerta, o de acciones tomadas por usted o por un tercero como consecuencia de una alerta.',
  'app.terms.terms.section.4.text.1c': 'Se enviarán notificaciones electrónicas a la dirección de correo electrónico o número de celular que usted haya proporcionado para los Servicios. Si su dirección de correo electrónico o su número de celular cambian, tiene usted la responsabilidad de informarnos de tal cambio. También pueden enviarse alertas a dispositivos móviles que acepten mensajes de texto. Los cambios en la dirección de correo electrónico o número de celular se aplicarán a todas sus alertas.',
  'app.terms.terms.section.4.text.1d': 'Las notificaciones no están encriptadas. Pueden incluir su identificación de usuario de inicio de sesión y cierta información sobre sus cuentas. Si solicita un cambio de contraseña, puede que le enviemos una contraseña temporal por correo electrónico. Dichas peticiones no constituyen una alerta. Cualquiera que tenga acceso a la cuenta de correo electrónico que nos ha proporcionado será capaz de ver el contenido de dichas notificaciones. Puede usted desactivar las notificaciones en cualquier momento enviando un correo electrónico a {mailToSupportAction} .',
  'app.terms.terms.section.4.subheader.2': 'Zoom: ',
  'app.terms.terms.section.4.text.2': 'pueden proporcionarse determinadas partes de los Servicios a través de Zoom, y cualquier información personal comunicada por Zoom estará sujeta a la política de privacidad y controles de seguridad de la información de Zoom. Las políticas de Zoom pueden encontrarse en {zoomUrl} .',
  'app.terms.terms.section.4.subheader.2a': 'Google Meet: ',
  'app.terms.terms.section.4.text.2a': 'pueden proporcionarse determinadas partes de los Servicios a través de Google Meet, y cualquier información personal comunicada por Google Meet estará sujeta a la política de privacidad y controles de seguridad de la información de Google Meet. Las políticas de Google Meet pueden encontrarse en {googleUrl} .',
  'app.terms.terms.section.4.subheader.2b' : 'Recomendaciones: ',
  'app.terms.terms.section.4.text.2b': 'cualquier recomendación, preguntas, comentarios, sugerencias, ideas y similares que envíe a Neighborhood Trust (colectivamente “Recomendaciones”) se tratará como no confidencial y no sujeta a derechos de propiedad intelectual respecto a usted. Neighborhood Trust tendrá libertad para utilizar dicha información con cualquier fin, incluido el desarrollo y marketing de servicios o productos que incorporan esa información, sin pagarle a usted regalías ni ninguna otra retribución.',
  'app.terms.terms.section.4a.header': 'Su contenido',
  'app.terms.terms.section.4.text.3': 'Cuando usted use los Servicios o el Sitio, usted creará un perfil personal e introducirá información manualmente, incluida sin limitación información financiera (presupuesto, gastos, ingresos, etc.). Usted garantiza y declara que posee o controla de otra manera los derechos de toda la información proporcionada a Neighborhood Trust a través del uso de los Servicios o el Sitio (colectivamente, el “Contenido”). Usted es responsable de cualquier Contenido que se pierda o resulte irrecuperable a través de su uso de los Servicios. Usted acepta no usar los Servicios ni el Sitio para compartir, almacenar o distribuir el Contenido en un modo que no se ajuste a la ley. Neighborhood Trust no será responsable del Contenido ni de los datos que usted envíe en el sitio web.',
  'app.terms.terms.section.4.text.3b': 'Neighborhood Trust no siempre puede prever o anticipar dificultades técnicas o de otro tipo que puedan provocar la imposibilidad de obtener datos o pérdida de datos, ajustes personalizados u otras interrupciones del servicio. Neighborhood Trust no puede asumir la responsabilidad por la puntualidad, precisión, borrado, no entrega o no cumplimiento al almacenar cualquier dato de usuario, comunicaciones o ajustes personalizados. Por ejemplo, al mostrarse en el Sitio o Servicios de Neighborhood Trust, la Información de Cuenta es tan reciente como la hora mostrada, la cual refleja cuándo se obtuvo la información de dichos sitios. Dicha información puede estar más actualizada si se obtiene directamente de los bancos o cooperativas pertinentes.',
  'app.terms.terms.section.4b.header': 'Propiedad intelectual',
  'app.terms.terms.section.4b.text.1': 'El Sitio, los Servicios y todo su contenido (incluyendo, pero sin limitarse a, toda la información, software, texto, presentaciones, imágenes, videos y audio, así como el diseño, selección y disposición de los mismos) pertenecen a Neighborhood Trust, sus licenciantes u otros proveedores de dichos materiales y pueden estar protegidos por derechos de autor de los Estados Unidos o internacionales, marcas registradas, patentes, secretos comerciales y otras leyes de derechos de propiedad o de propiedad intelectual.',
  'app.terms.terms.section.4b.text.2': 'A usted no se le concede ni transfiere ningún derecho, título o interés sobre el Sitio, los Servicios ni sobre ningún contenido o material del Sitio o proporcionado a través de los Servicios, a excepción de lo establecido expresamente aquí y todos los derechos no concedidos expresamente aquí son derechos reservados de Neighborhood Trust. Cualquier uso del Sitio y los Servicios que no esté expresamente permitido en estos Términos de servicio constituye un incumplimiento de los Términos de servicio y puede violar las leyes de derechos de autor, marca registrada y otras leyes.',
  'app.terms.terms.section.4b.text.3': 'Estos Términos de servicio le permiten a usted utilizar el Sitio y los Servicios únicamente para uso privado o con fines empresariales internos. A excepción de lo establecido expresamente por el presente, usted no reproducirá, distribuirá, modificará, creará trabajos derivados, mostrará públicamente, reproducirá públicamente, republicará, descargará, almacenará ni transmitirá el Sitio o los Servicios ni ninguno de sus contenidos, excepto de la siguiente manera:',
  'app.terms.terms.section.4b.3.bulletpoints.a': 'Su computadora puede almacenar temporalmente copias de dichos contenidos del Sitio en la RAM como resultado de su acceso o visualización de los contenidos.',
  'app.terms.terms.section.4b.3.bulletpoints.b': 'Puede almacenar archivos que su navegador guarda en caché de manera automática con el objetivo de mejorar su visualización.',
  'app.terms.terms.section.4b.3.bulletpoints.c': 'Puede imprimir o descargar un número razonable de páginas del Sitio o una cantidad razonable de materiales del Sitio siempre y cuando sea exclusivamente para uso privado o con fines empresariales internos y no para su posterior reproducción, publicación ni distribución.',
  'app.terms.terms.section.4b.text.4': 'Usted no debe: ',
  'app.terms.terms.section.4b.4.bulletpoints.a': 'Modificar copias de ningún material del Sitio.',
  'app.terms.terms.section.4b.4.bulletpoints.b': 'Borrar o altear avisos de derechos de autor, marcas registradas u otros derechos de propiedad de las copias de los materiales del Sitio.',
  'app.terms.terms.section.4b.text.5': 'Si usted imprime, copia, modifica, descarga, muestra públicamente o distribuye, o usa de otra manera cualquier contenido o materiales del Sitio o de los Servicios incumpliendo estos Términos, podemos cancelar inmediatamente su derecho a usar el Sitio y los Servicios, y usted debe, a nuestra elección, devolver o destruir todas y cada una de las copias de contenidos o materiales del Sitio o los Servicios que estén en su poder.',
  'app.terms.terms.section.4b.subheader.1': 'Marcas registradas.',
  'app.terms.terms.section.4b.text.6': ' El nombre Neighborhood Trust, los logotipos de Neighborhood Trust y todos los nombres, logotipos, nombres de servicios y productos, diseños y eslóganes relacionados son marcas comerciales de Neighborhood Trust o de sus filiales o licenciantes.',
  'app.terms.terms.section.4b.text.7': 'No podrá usted usar dichas marcas sin nuestro permiso previo por escrito. Todos los demás nombres, logotipos, nombres de servicios y productos, diseños y eslóganes de este Sitio o Servicios son marcas registradas de sus respectivos propietarios.',

  'app.terms.terms.section.4c.header': 'Supervisión y cumplimiento',
  'app.terms.terms.section.4c.text.1': 'Nosotros tenemos derecho a: ',
  'app.terms.terms.section.4c.1.bulletpoints.a': 'Tomar cualquier medida respecto a cualquier conducta de usuario que consideremos necesaria o apropiada a nuestra entera discreción, incluyendo si creemos que dicha conducta de usuario viola estos Términos de servicio, incluidos los usos prohibidos descritos por el presente, o si la conducta de dicho usuario infringe cualquier derecho de propiedad intelectual u otros derechos de cualquier persona o entidad, amenaza la seguridad personal de los usuarios del Sitio o del público o si pudiera crear una responsabilidad para Neighborhood Trust.',
  'app.terms.terms.section.4c.1.bulletpoints.b': 'Revelar su identidad u otra información sobre usted a cualquier tercero que afirme que el uso que usted le da a los Servicios o el Sitio viola los derechos de dicho tercero, incluidos sus derechos de propiedad intelectual o su derecho a la privacidad.',
  'app.terms.terms.section.4c.1.bulletpoints.c': 'Tomar las medidas legales apropiadas, incluida sin limitación la remisión a las autoridades legales, ante el uso ilegal o no autorizado del Sitio o de los Servicios.',
  'app.terms.terms.section.4c.1.bulletpoints.d': 'Terminar o suspender su acceso a todo o parte del Sitio y de los Servicios por cualquier motivo o sin él, incluida sin limitación cualquier violación de estos Términos de servicio.',
  'app.terms.terms.section.4c.text.2': 'Sin perjuicio de lo anterior, nosotros tenemos derecho a cooperar totalmente con cualquier autoridad de cumplimiento de las leyes u orden judicial que nos solicite u ordene que revelemos la identidad de cualquier usuario del Sitio o de los Servicios, u otra información de cualquiera que publique material en el Sitio o a través de él. USTED RENUNCIA Y EXIME A NEIGHBORHOOD TRUST Y A SUS FILIALES, LICENCIATARIOS Y PROVEEDORES DE SERVICIOS DE CUALQUIER DEMANDA QUE RESULTE DE UNA ACCIÓN TOMADA POR NEIGHBORHOOD TRUST DURANTE, O COMO CONSECUENCIA DE, INVESTIGACIONES TANTO POR PARTE DE NEIGHBORHOOD TRUST COMO DE LAS AUTORIDADES DE CUMPLIMIENTO LEGAL.',
  'app.terms.terms.section.4c.text.3': 'No obstante, no efectuamos una revisión de todo el material antes de que se publique en el Sitio y no podemos asegurar que retiraremos rápidamente del Sitio el material ofensivo. En consecuencia, nosotros no asumimos ninguna responsabilidad por acciones u omisiones relacionadas con las transmisiones, comunicaciones o contenidos proporcionados por cualquier usuario o por terceros. Nosotros no asumimos responsabilidad ante nadie por el cumplimiento o incumplimiento de las actividades descritas en esta sección.',

  'app.terms.terms.section.4d.header': 'Confidencialidad',
  'app.terms.terms.section.4d.text.1': 'Neighborhood Trust y sus consejeros no usarán de ninguna manera y en ningún momento, directa o indirectamente para beneficio personal de Neighborhood Trust ni divulgarán, revelarán o comunicarán en ningún momento y de ninguna manera, tanto directa como indirectamente, información personal del Usuario. A excepción de lo establecido por el presente o por la Política de privacidad, Neighborhood Trust protegerá su información y la tratará como estrictamente confidencial. Esta disposición continuará vigente tras el fin de su participación en los Servicios por el tiempo exigido por la legislación aplicable. Su información financiera puede agregarse con otras y compartirse con terceros con fines de investigación, evaluación, marketing y otros fines razonables. Esta información agregada no identifica a Usuarios específicos ni permite que nadie más recupere información confidencial sobre los Usuarios individuales. Para obtener más información relacionada con la confidencialidad de la información proporcionada por usted, consulte nuestra Política de privacidad, disponible en {privacyPolicyUrl}.',
  'app.terms.terms.section.4d.text.2': 'este enlace',
  'app.terms.terms.section.4e.header': 'Privacidad y prácticas de información',
  'app.terms.terms.section.4e.text.1':'Valoramos la privacidad individual y nos comprometemos a proteger la información de los individuos. Toda la información recabada a través del Sitio y los Servicios está sujeta a nuestra Política de privacidad, disponible en {privacyPolicyUrl}. Al acceder o usar el Sitio o los Servicios, o al aceptar de otro modo estos Términos de Servicio, usted reconoce que ha leído y entendido los términos de la Política de Privacidad y que acepta el tratamiento de su información de conformidad con lo establecido en la Política de Privacidad.',
  'app.terms.terms.section.4f.header': 'Coordinación con terceros',
  'app.terms.terms.section.4f.text.1': 'Ocasionalmente nosotros le proporcionaremos enlaces a sitios, productos, servicios, funciones, comunidades online o solicitudes de terceros. Los usuarios son responsables de los términos propios de uso terceros con los que contactan o se conectan al usar nuestros Servicios. Si usted decide usar alguno de estos otros servicios, puede que se le apliquen términos y condiciones adicionales y cuotas independinetes. Si decide usted usar algún producto de terceros o acceder a sitios web de terceros, usted es responsable de revisar las términos de producto, términos del sitio web y políticas de privacidad propias de dichos terceros. Neighborhood Trust no certifica, autoriza ni garantiza los productos o servicios disponibles a través de dichas ofertas, y Neighborhood Trust no es ningún agente, corredor ni responsable de otro modo de las actividades o políticas de dichos sitios web. Neighborhood Trust no garantiza que la información de la oferta de terceros mostrada a través de los Servicios corresponda realmente con los términos que le serán ofrecidos a usted si acepta la oferta ni que sean los mejores términos, los precios más bajos del mercado ni apropiados para usted de otro modo. Usted deberá llevar a cabo su propia revisión y diligencia debida de cualquier oferta, producto o servicio de terceros, y Neighborhood Trust rechaza cualquier obligación expresa o implícita de hacerlo.',

  'app.terms.terms.section.5.header': 'Exención de garantías',
  'app.terms.terms.section.5.text.1': 'EN LA MEDIDA PERMITIDA POR LA LEGISLACIÓN APLICABLE, EL SITIO Y TODA LA INFORMACIÓN PRESENTADA A TRAVÉS DEL SITIO O DE LOS SERVICIOS SE PROPORCIONA “TAL CUAL” Y “CON TODAS LAS FALLAS”, Y NEIGHBORHOOD TRUST RECHAZA POR LA PRESENTE TODAS LAS REPRESENTACIONES Y GARANTÍAS DE CUALQUIER NATURALEZA, TANTO EXPRESAS COMO IMPLÍCITAS, LEGALES U OTRAS, EN RELACIÓN AL SITIO Y LOS SERVICIOS Y A TODA LA INFORMACIÓN PRESENTADA A TRAVÉS DEL SITIO O LOS SERVICIOS, Y NEIGHBORHOOD TRUST RECHAZA ESPECÍFICAMENTE TODAS LAS GARANTÍAS IMPLÍCITAS DE COMERCIALIZACIÓN, IDIONEIDAD PARA UN PROPÓSITO DETERMINADO Y NO INFRACCIÓN, Y TODAS LAS GARANTÍAS DERIVADAS DE UN ACUERDO, USO O PRÁCTICA COMERCIAL. SIN PERJUICIO DE LO ANTERIOR, NEIGHBORHOOD TRUST NO REALIZA REPRESENTACIONES NI GARANTÍAS DE NINGÚN TIPO RESPECTO A LA EXACTITUD O EXHAUSTIVIDAD DE LA INFORMACIÓN PRESENTADA A TRAVÉS DEL SITO O DE LOS SERVICIOS; DISPONIBILIDAD, SEGURIDAD O FUNCIONAMIENTO DEL SITIO O DE LOS SERVICIOS; EXISTENCIA O AUSENCIA DE DEFECTOS EN EL STIO O LOS SERVICIOS; O COMPATIBILIDAD DEL SITIO CON CUALQUIER EQUIPO O SOFTWARE.',

  'app.terms.terms.section.6.header': 'Limitación de responsabilidad',
  'app.terms.terms.section.6.text.1': 'EN LA MEDIDA PERMITIDA POR LA LEGISLACIÓN APLICABLE, NI NEIGHBORHOOD TRUST NI NINGUNO DE SUS REPRESENTANTES SERÁN EN NINGÚN CASO RESPONSABLES ANTE USTED O ANTE TERCEROS BAJO O EN RELACIÓN A ESTOS TÉRMINOS DE SERVICIO NI AL ASUNTO TRATADO, BAJO NINGUNA TEORÍA LEGAL, INCLUYENDO EL INCUMPLIMIENTO DE CONTRATO, AGRAVIO (INCLUIDA NEGLIGENCIA), RESPONSABILIDAD ESTRICTA U OTROS, POR CUALQUIER (A) DAÑO CONSECUENCIAL, INDIRECTO, INCIDENTAL, ESPECIAL, EJEMPLAR, PUNITIVO O INCREMENTADO; (B) SU USO DEL SITIO O LOS SERVICIOS, O CUALQUIER DEFICIENCIA, RETRASO U OTRA INTERRUPCIÓN DEL SITIO, DE LOS SERVICIOS O DE SU CAPACIDAD DE USARLOS; (C) PÉRDIDA DE OPORTUNIDADES DE NEGOCIO, INGRESOS O BENEFICIO, DISMINUCIÓN DEL VALOR U OTRAS PÉRDIDAS ECONÓMICAS; (D) DAÑO A LA REPUTACIÓN O AL FONDO DE COMERCIO; O (E) PÉRDIDA, DAÑO, CORRUPCIÓN O RECUPERACIÓN DE DATOS, O FILTRACIÓN DE DATOS O DEL SISTEMA DE SEGURIDAD; (F) CONTENIDO, EXACTITUD O EXHAUSTIVIDAD, Y CUALQUIER ERROR U OMISIÓN DE LOS MATERIALES INCLUIDOS O DISPONIBLES EN EL SITIO O SERVICIOS; O (G) CONTENIDO EXTRAÍDO DE INTERNET INCLUSO SI HA SIDO EXTRAÍDO O ENLAZADO A, DE O EN EL SITIO O SERVICIO, EN CADA CASO INDEPENDIENTEMENTE DE QUE NEIGHBORHOOD TRUST O CUALQUIERA DE SUS REPRESENTANTES HUBIERAN SIDO ADVERTIDOS DE LA POSIBILIDAD DE DICHAS PÉRDIDAS O DAÑOS O DE QUE LAS PÉRDIDAS O DAÑOS PUDIERAN SER PREVISIBLES DE OTRO MODO, Y PESE AL INCUMPLIMIENTO DEL PROPÓSITO ESENCIAL DE CUALQUIER METODO ACORDADO O NO.',
  'app.terms.terms.section.6.text.2': 'EN NINGÚN CASO LA RESPONSABILIDAD TOTAL Y AGREGADA DE NEIGHBORHOOD TRUST FRENTE A USTED EN RELACIÓN A ESTOS TÉRMINOS DE SERVICIO O AL ASUNTO TRATADO, BAJO NINGUNA TEORÍA LEGAL O EQUITATIVA, INCLUYENDO EL INCUMPLIMIENTO DE CONTRATO, AGRAVIO (INCLUIDA NEGLIGENCIA), RESPONSABILIDAD ESTRICTA U OTROS, EXCEDERÁ CINCUENTA DÓLARES ESTADOUNIDENSES ($50). TODAS LAS DEMANDAS CONTRA NEIGHBORHOOD TRUST RESPECTO A CUALQUIER RESPONSABILIDAD RELACIONADA CON ESTOS TÉRMINOS DE SERVICIO SE AGREGARÁ PARA DETERMINAR LA SATISFACCIÓN DE DICHO LÍMITE, Y LA EXISTENCIA DE MÁS DE UNA DEMANDA NO AMPLIARÁ NI EXTENDERÁ LA LIMITACIÓN ANTERIOR. LA LIMITACIÓN ANTERIOR SE APLICA PESE AL INCUMPLIMIENTO DEL PROPÓSITO ESENCIAL DE CUALQUIER METODO ACORDADO O NO.',
  'app.terms.terms.section.6.subheader.1': 'Indemnización',
  'app.terms.terms.section.6.text.3': 'Usted indemnizará, defenderá y eximirá a Neighborhood Trust y sus filiales, y a cada uno de sus respectivos responsables, directores, empleados, agentes, sucesores y cesionarios (cada uno de ellos “Indemnizado”) de cualquier pérdida sufrida por tal Indemnizado en relación a cualquier demanda o acción de un tercero derivada de o relacionada con cualquier: (i) contenido que usted cargue, publique, envíe por correo electrónico, transmita o facilite de cualquier otro modo a través del Sitio; (ii) incumplimiento por su parte de cualquiera de sus representaciones, garantías, pactos u obligaciones bajo estos Términos de servicio; o (iii) negligencia u otras acciones u omisiones más dolosas (incluidas imprudencia o mala conducta deliberada) por parte de usted en relación a estos Términos de servicio y al asunto tratado.',
  'app.terms.terms.section.6.subheader.2': 'No asesoramiento legal, fiscal o de inversiones: ',
  'app.terms.terms.section.6.text.4': ' los consejeros de Neighborhood Trust no son asesores de inversiones, corredores, asesores fiscales ni planificadores financieros certificados. Los Servicios y el Sitio pretenden únicamente proporcionarle ayuda y asistencia en su toma de decisiones financieras en un sentido amplio. Usted entiende que los Servicios, el Sitio y cualquier otro material que Neighborhood Trust le presente en relación a los Servicios o el Sitio no constituyen asesoramiento legal, fiscal ni de negocios o inversiones de ningún tipo. Ningún consejo que Neighborhood Trust le proporcione a usted como parte de los Servicios o del Sitio constituirá una oferta ni solicitud en ninguna jurisdicción para adquirir o vender valores (securities) de ningún tipo. Nada de lo establecido en estos Términos de servicio obligará ni permitirá a Neighborhood Trust proporcionar asesoramiento de inversiones o recomendaciones de inversiones a ningún Usuario. Recomendamos encarecidamente a los Usuarios a considerar obtener información adicional por parte de su contador o consultar a asesores legales, fiscales y de inversiones quienes tengan conocimiento pleno de sus circunstancias particulares. Usted también entiende que Neighborhood Trust no es una “organización de reparación de crédito” y que tampoco vendemos servicios destinados a mejorar o arreglar el informe de crédito, el historial crediticio ni la calificación crediticia del Usuario a cambio de dinero. Usted acepta usar los Sitios y Servicios bajo su propio riesgo. ',
  'app.terms.terms.section.6.subheader.3': 'Cambios y terminación: ',
  'app.terms.terms.section.6.text.5': 'Neighborhood Trust puede modificar o actualizar estos Términos de servicio como desee y sea necesario. Cualquier cambio de estos Términos de servicio se le puede proporcionar a usted por medios electrónicos (por ejemplo, por correo electrónico o publicando la información en los Sitios). Los Términos de servicio indicarán siempre la fecha de la última revisión. Su uso después de cualquier actualización constituye un acuerdo continuado.',
  'app.terms.terms.section.6.text.6': 'Neighborhood Trust se reserva el derecho de modificar o discontinuar en cualquier momento y ocasionalmente, de manera temporal o permanente, los Servicios o el Sitio (o parte de ellos) con o sin previo aviso. También podemos rescindir el acuerdo con usted en caso de que, a nuestro juicio, viole usted estos Términos de Servicio. ',
  'app.terms.terms.section.6.subheader.4': 'Uso de teléfono celular/plan de datos personal: ',
  'app.terms.terms.section.6.text.7': 'el uso de los Servicios o el Sitio puede estar disponible a través de un dispositivo móvil compatible, Internet y/o acceso de red y puede precisar software. Ni su empleador ni Neighborhood Trust reembolsarán el uso del teléfono celular/datos utilizados para interactuar con el programa. Usted acepta ser el único responsable de estos requisitos, incluidos los cargos aplicables, actualizaciones y cuotas, así como de los términos de su acuerdo con el proveedor de telecomunicaciones y de su dispositivo móvil.',
  'app.terms.terms.section.6.subheader.5': 'Legislación vigente: ',
  'app.terms.terms.section.6.text.8': 'usted acepta que todos los términos y disposiciones del presente se regirán, interpretarán y se harán cumplir de conformidad con las leyes del estado de Nueva York.',

  'app.terms.privacy.section.6.header': 'Cómo contactarse con nosotros ',
  'app.terms.privacy.section.6.text.1a': 'Si tiene preguntas, dudas o comentarios sobre estos Términos de servicio, puede enviarnos un correo electrónico a {mailToSupportAction} o correo postal a:',
  'app.terms.privacy.section.6.text.1': 'Neighborhood Trust Financial Partners',
  'app.terms.privacy.section.6.text.2': '530 West 166th St, 4th floor',
  'app.terms.privacy.section.6.text.3': 'New York, NY 10032',
  'app.terms.privacy.section.6.text.4': 'Los Términos de servicio de Neighborhood Trust también pueden encontrarse en {externalTermsOfService}',
  'app.terms.agree': 'Aceptar',
  'app.terms.go-back': 'Regresar',
  'app.terms.tos-pp-intro-text': 'Antes de proceder, por favor revisé nuestro términos de servicio y póliza de privacidad.',

  'app.privacy-policy.last-revised': 'Última revisión: Febrero 2020',
  'app.privacy-policy.overview.sub-header': '1. Descripción',
  'app.privacy-policy.overview.intro.1': 'La presente Política de Privacidad explica cómo Neighborhood Trust Financial Partners, LLC y sus subsidiarios, socios y filiales (“nosotros” o “Neighborhood Trust”) recaban, protegen, utilizan y comparten información sobre usted cuando usa {ntfpSite} (“Sitio web” o “Sitio”) y cualquiera de nuestros servicios (de manera conjunta con el Sitio web, los “Servicios”), tanto si accede a los Servicios directamente o a través de terceros, dichos terceros pueden incluir a su empleador. Esta Política de Privacidad está incorporada en referencia a, y forma parte de, nuestros Términos de Servicio, disponibles en {tosLink} (los “Términos de servicio”). En caso de conflicto entre las disposiciones de la presente Política de Privacidad y los Términos de Servicio, prevalecerán las disposiciones aplicables de la Política de Privacidad.',
  'app.privacy-policy.overview.intro.2': 'Neighborhood Trust se toma muy en serio su privacidad. Por favor, lea con atención esta nota para entender lo que hacemos. ',
  'app.privacy-policy.overview.intro.3': 'Si no quiere que se utilice información sobre usted como se describe en esta Política de Privacidad o si no está de acuerdo con alguna parte de esta Política de Privacidad o de nuestras prácticas de información, debe dejar, o abstenerse, de utilizar los Servicios.',
  'app.privacy-policy.overview.intro.4': 'Si tiene preguntas o dudas sobre nuestras prácticas de información, contáctese con nosotros en {mailToSupportAction} o de cualquiera de las maneras indicadas posteriormente en la sección “Cómo contactarse con nosotros”.',
  'app.privacy-policy.overview.scope.sub-title': 'Ámbito',
  'app.privacy-policy.scope.1': 'La presente Política de Privacidad se aplica a la información recabada a través del Sitio web y de cualquiera de nuestros otros sitios web que proporcionan un enlace a esta Política de Privacidad, así como a la información recabada a través de cualquiera de nuestros productos y servicios accesibles a través del Sitio web por parte de usted como usuario individual (“usted”) del Sitio web.',
  'app.privacy-policy.scope.2': 'Tenga en cuenta que, a excepción de lo que se describe específicamente más adelante, la presente Política de Privacidad ',
  'app.privacy-policy.scope.3': 'no ',
  'app.privacy-policy.scope.4': 'se aplica a la información recabada por terceros, tales como otras empresas, o aplicaciones móviles o sitios web de terceros, incluso si están vinculadas o accesibles a través de nuestros Servicios. Además, cualquier Servicio recibido en persona puede estar sujeto a términos y condiciones de privacidad adicionales, los cuales le serán proporcionados a usted en relación con dichos Servicios. En la medida en que existiera un conflicto entre las disposiciones de la presente Política de Privacidad y los términos de privacidad proporcionados en relación a los Servicios en persona, prevalecerán las disposiciones de dichos términos de privacidad.',
  'app.privacy-policy.information-we-collect.sub-header': '2. Información que recabamos',
  'app.privacy-policy.information-we-collect.information-you-provide.subtitle': 'Información que usted proporciona',
  'app.privacy-policy.information-we-collect.1': 'Durante el transcurso del uso de los Servicios, Neighborhood Trust puede recabar y almacenar información introducida por usted en nuestro Sitio web o información que nos haya sido proporcionada durante una sesión de consejería o de cualquier otra manera en relación a nuestra prestación de los Servicios. Esto incluye Información de identificación personal (“PII”), incluyendo pero sin limitarse a: ',
  'app.privacy-policy.pii-bulletpoints.1': 'Números del seguro social',
  'app.privacy-policy.pii-bulletpoints.2': 'Domicilio e información de contacto',
  'app.privacy-policy.pii-bulletpoints.3': 'Fecha de nacimiento',
  'app.privacy-policy.pii-bulletpoints.4': 'Información demográfica',
  'app.privacy-policy.pii-bulletpoints.5' : 'Empleador',
  'app.privacy-policy.pii-bulletpoints.6' : 'Ingresos y gastos',
  'app.privacy-policy.pii-bulletpoints.7' : 'Cuenta bancaria e información sobre el saldo',
  'app.privacy-policy.pii-bulletpoints.8' : 'Información de las agencias de reportes de crédito',
  'app.privacy-policy.information-we-collect.passive-data-collection.subtitle' : 'Recopilación pasiva de datos',
  'app.privacy-policy.passive-data-collection.1': 'En general, automáticamente colectamos determinada información suya según el uso y comportamiento en el Sitio web y en relación con los Servicios. Utilizamos esta información para analizar las características demográficas de nuestros clientes, sus intereses y su comportamiento con el fin del comprenderle y atenderle mejor a usted y a todos nuestros clientes. También analizamos esta información por motivos de seguridad para proteger los Servicios y la información de los clientes que tenemos.',
  'app.privacy-policy.passive-data-collection.2': 'Si la información que recabamos automáticamente le identifica a usted o podría razonablemente utilizarse para identificarle, entonces se tratará como PII. Asimismo, si combinamos directamente cualquier información recabada a través de medios pasivos con PII, tratamos la información combinada como PII conforme a la presente Política de Privacidad. No obstante, en muchos casos, la información que recabamos pasivamente solo se utiliza de forma desidentificada o agregada, que no identifica a ningún individuo.',
  'app.privacy-policy.passive-data-collection.3': 'Recabamos datos pasivos de las siguientes maneras:',
  'app.privacy-policy.passive-data-collection.4': 'Información del dispositivo móvil. Si descarga o utiliza nuestro Sitio web desde un dispositivo móvil, recibiremos información sobre su dispositivo móvil. Registramos su dispositivo y es posible que utilicemos el identificador para enviar notificaciones automáticas sobre sus actividades a través de los Servicios.',
  'app.privacy-policy.passive-data-collection.5': 'Archivos de registro. Como la mayoría de los sitios web, recibimos y registramos automáticamente determinada información sobre el comportamiento de los clientes en nuestro Sitio web y a la hora de usar nuestros Servicios. Nuestros servidores recaban automáticamente información técnica sobre el tráfico de nuestro Sitio web y la almacenan en archivos de registro. Esta información incluye direcciones del protocolo de internet (IP), tipo de navegador, proveedor de servicios de internet (ISP), páginas de referencia/salida, sistema operativo, registro de fecha/hora y datos adicionales del historial de clics. Agregamos esta información con otros datos para analizar tendencias, administrar y mejorar los Servicios, como por ejemplo, para entender el uso que hacen nuestros clientes del Sitio web y para tomar decisiones de inventario y adquisiciones a fin de servir mejor a nuestra base de clientes. La información de los archivos de registro puede utilizarse también para determinar la información de ubicación general, como la ciudad, estado o área geográfica.',
  'app.privacy-policy.passive-data-collection.6': 'Hotjar. De conformidad con los términos de esta Política de Privacidad, usamos un servicio de tecnología llamado Hotjar para comprender mejor las necesidades de nuestros usuarios y optimizar este servicio y experiencia. Nos ayuda a entender mejor la experiencia de nuestros usuarios (por ejemplo, cuánto tiempo pasan en qué páginas, en qué enlaces hacen clic, qué les gusta y no les gusta a los usuarios, etc.) y esto nos permite construir y mantener nuestro servicio con la retroalimentación de los usuarios. Hotjar utiliza cookies y otras tecnologías para recabar datos del comportamiento de nuestros usuarios y sus dispositivos incluyendo, pero sin limitarse a, la dirección IP del dispositivo (extraída y almacenada solamente de forma anónima), tamaño de la pantalla del dispositivo, tipo de dispositivo (identificadores de dispositivo únicos), información del navegador, ubicación geográfica (solamente país) e idioma preferido utilizado para mostrar nuestro sitio web. Hotjar almacena esta información en un perfil de usuario con un pseudónimo. Ni Hotjar ni nosotros utilizaremos dicha información para identificar a usuarios individuales ni para combinar la información con otros datos en relación a un usuario individual. Si desea obtener más información, haga clic {hotjarExternalLink} para consultar la Política de Privacidad de Hotjar.',
  'app.privacy-policy.passive-data-collection.7': 'en este enlace',
  'app.privacy-policy.passive-data-collection.8': 'Cookies, guías web (web beacons) y tecnologías similares. Una cookie es un pequeño archivo de texto que se guarda en una computadora con fines de registro o seguimiento. Las guías web (también conocidas como etiquetas de pixel o GIF transparentes), son gráficos que pueden registrar si una persona ha accedido a un contenido específico. Usamos estas tecnologías y otras similares en el Sitio web y en relación a nuestros Servicios. Las cookies que utilizamos pueden incluir cookies de sesión, cookies persistentes o tecnologías similares. Las cookies de sesión caducan al cerrar el navegador. Las cookies persistentes se almacenan en la computadora o dispositivo de uno durante un periodo de tiempo prolongado. Puede usted eliminar las cookies persistentes si sigue las instrucciones proporcionadas en el menú de "ayuda" de su navegador de internet. También puede configurar su navegador de tal modo que impida el almacenamiento de cookies en su computadora. (La documentación de su navegador debería proporcionar instrucciones al respecto.) Si usted rechaza las cookies, puede que su capacidad de uso del Sitio web se vea limitada, y quizá no pueda usar determinadas funciones del Sitio web o de los Servicios. Nosotros podemos utilizar cookies con los siguientes fines: ',
  'app.privacy-policy.passive-data-collection.cookies.1': 'Cookies esenciales. ',
  'app.privacy-policy.passive-data-collection.cookies.2': 'Las cookies esenciales son necesarias para proporcionarle las funciones y servicios que usted ha solicitado. Por ejemplo, hay determinadas cookies que le permiten iniciar sesión en zonas seguras de nuestros Servicios. Si se desactivan estas cookies, puede que ciertas funciones y servicios dejen de estar disponibles.',
  'app.privacy-policy.passive-data-collection.cookies.3': 'Cookies funcionales. ',
  'app.privacy-policy.passive-data-collection.cookies.4': 'Las cookies funcionales se utilizan para almacenar sus preferencias y ajustes relacionados con nuestros Servicios, mantener sus preferencias a lo largo del tiempo y reconocerle cuando regresa a nuestros Servicios. Estas cookies nos ayudan a personalizar el contenido para usted, saludarle por su nombre y recordar sus preferencias (por ejemplo, la elección de idioma o región).',
  'app.privacy-policy.passive-data-collection.cookies.5': 'Cookies de rendimiento/analíticas. ',
  'app.privacy-policy.passive-data-collection.cookies.6': 'Las cookies de rendimiento/analíticas nos permiten entender cómo usan los visitantes nuestros Servicios, por ejemplo, obteniendo información sobre el número de visitantes de los Servicios, qué páginas de nuestros Servicios ven los visitantes y durante cuánto tiempo visualizan esas páginas en los Servicios. Las cookies de rendimiento/analíticas también nos permiten medir el desempeño de nuestras campañas publicitarias para ayudarnos a mejorar las campañas y el contenido de los Servicios para quienes interactúan con nuestra publicidad.',
  'app.privacy-policy.passive-data-collection.9': 'Si desea más información sobre las cookies, incluyendo información sobre cómo administrarlas y eliminarlas, visite {allAboutCookiesLink} .',
  'app.privacy-policy.all-about-cookies-link' : 'https://www.allaboutcookies.org/es/',
  'app.privacy-policy.passive-data-collection.10': 'Puede que usemos guías web o tecnologías similares que ayudan a administrar el contenido del Sitio web y los Servicios haciendo un seguimiento al contenido que sea eficaz. Dichas tecnologías pueden utilizarse para hacer un seguimiento de los movimientos en línea de los clientes, pero a diferencia de las cookies, que se almacenan en el disco duro de la computadora, estas tecnologías están insertadas en el Sitio web.',
  'app.privacy-policy.passive-data-collection.11': 'Es posible que también usemos guías web en nuestros correos electrónicos basados en HTML para saber qué correos electrónicos han abierto los destinatarios. Esto nos permite evaluar la eficacia de determinadas comunicaciones y la eficacia de nuestras campañas de marketing.',
  'app.privacy-policy.information-we-collect.third-party-advertisers.subtitle': 'Terceros anunciantes',
  'app.privacy-policy.third-party-advertisers.1': 'Las compañías publicitarias de terceros también pueden usar cookies, guias web o tecnologías similares en el Sitio web para recabar información (como información del historial de clics) durante sus visitas a nuestro Sitio web y otros sitios web, así como durante su uso de nuestros Servicios, para ayudarnos a proporcionarle anuncios sobre nuestros Servicios que creemos que probablemente sean interesantes para usted.',
  'app.privacy-policy.third-party-advertisers.2': 'Si prefiere que no se utilice su información con el fin de proporcionarle publicidad personalizada, puede obtener más información sobre sus opciones o cambiar sus preferencias en {optOutAdsLink}, {optOutNetworkAdvertisingLink}, {trusteLink}, de Google en {googleAdsLink} o {googlePoliciesLink}, de Facebook en {facebookAdsLink} o {facebookPolicyLink}, o si se encuentra en la Unión Europea en {euroUnionOnlineChoicesLink}.',
  'app.privacy-policy.third-party-advertisers.opt-out-ads-link': 'optout.aboutads.info',
  'app.privacy-policy.third-party-advertisers.opt-out-networking-advertising-link': 'optout.networkadvertising.org',
  'app.privacy-policy.third-party-advertisers.truste-link': 'preferences-mgr.truste.com',
  'app.privacy-policy.third-party-advertisers.google-ads-link': 'www.google.com/settings/ads',
  'app.privacy-policy.third-party-advertisers.google-policies-link': 'policies.google.com/technologies/partner-sites',
  'app.privacy-policy.third-party-advertisers.facebook-ads-link': 'www.facebook.com/about/ads',
  'app.privacy-policy.third-party-advertisers.facebook-policy-link': '/www.facebook.com/policy.php',
  'app.privacy-policy.third-party-advertisers.euro-union-online-choices-link': 'www.youronlinechoices.eu/',
  'app.privacy-policy.how-we-use-your-information.sub-header': '3. Cómo utilizamos su información',
  'app.privacy-policy.how-we-use-your-information.we-may-use-info-to.subtitle': 'Podemos usar la información que recabamos sobre usted para:',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.1': 'Proporcionar los Servicios que usted ha solicitado;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.2': 'Gestionar su cuenta y proporcionarle atención al cliente;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.3' : 'Estudiar o analizar su uso de, o interés en, nuestros Servicios o el contenido disponible en ellos, o productos, servicios o contenido ofrecido por otros;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.4' : 'Agregar datos, tales como datos financieros personales anónimos que proporcione a través de nuestro Sitio web. Esta información agregada no identifica a los usuarios particulares ni permite que nadie recupere información confidencial sobre los usuarios individuales.',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.5': 'Comunicarnos con usted por correo electrónico, correo postal, teléfono y/o dispositivos móviles acerca de productos o servicios que puedan ser de su interés;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.6': 'Hacer cumplir nuestros términos y condiciones;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.7': 'Gestionar nuestro negocio; y',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.8': 'Realizar funciones que de otra manera sean descritas de otro modo en el momento de su obtención.',
  'app.privacy-policy.how-we-use-your-information.1': 'Además, puede que utilicemos datos agregados, estadísticos y desidentificados que no identifiquen específicamente a ningún individuo para cualquier fin.',
  'app.privacy-policy.how-we-disclose-your-information.sub-header': '4. Cómo revelamos su información',
  'app.privacy-policy.how-we-disclose-your-information.1' : 'Información a terceros: ',
  'app.privacy-policy.how-we-disclose-your-information.2': 'Aparte de las excepciones citadas más abajo, Neighborhood Trust se compromete a no transferir su PII a terceros. Compartimos PII y otra información recabada a través de nuestros Servicios con otras compañías o individuos de la siguiente manera:',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.1': 'Cuando nos ayudan a operar nuestro servicio, como distribuidores o proveedores de la nube que nos ayudan con el almacenamiento de datos, comunicación con clientes, seguimiento de resultados y análisis;',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.2': 'Con terceros si creemos de buena fe que el acceso, uso o revelación de dicha PII es razonablemente necesario para (a) cumplir la ley, regulación o proceso legal aplicables o un requerimiento gubernamental ejecutable, (b) hacer cumplir los Términos de Servicio o esta Política de Privacidad, incluida la investigación de posibles violaciones de los mismos, (c) detectar, evitar o combatir de otro modo el fraude o problemas de seguridad o técnicos, o (d) proteger para que no se vean afectados los derechos, la propiedad ni la seguridad de Neighborhood Trust, sus Usuarios o el público, incluido lo que exijan o permitan las leyes aplicables o el ordenamiento jurídico vigente de una autoridad gubernamental de jurisdicción competente.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.3': 'Con alguna de nuestras subsidiarias, sociedades conjuntas u otras compañías controladas conjuntamente con nosotros, en cuyo caso les exigiremos que respeten esta Política de Privacidad o términos que proporcionen términos y condiciones que no sean menos estrictos que los que contiene esta Política de Privacidad. Asimismo, en caso de que atravesemos una transición empresarial, como una fusión, adquisición por parte de otra empresa, o venta de todos o algunos de nuestros activos, su PII puede hallarse entre los activos transferidos.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.4': 'Si se nos solicita, podemos compartir su nombre con la organización que le puso a usted en contacto con los Servicios, pero no compartiremos su información financiera individual.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.5': 'Su información financiera puede agregarse con la información financiera de otras personas y compartirse con la organización que le puso en contacto con este servicio solo con fines de información y evaluación.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.6': 'La PII que se ha anonimizado y agregado puede compartirse con terceros con fines de investigación, evaluación y otros fines institucionales internos.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.7': 'No vendemos su PII a terceros.',
  'app.privacy-policy.how-we-disclose-your-information.3': 'Cuando usted deja de ser cliente nuestro, es posible que continuemos compartiendo su información como se describe en esta Política de Privacidad, incluido lo que sea necesario para cumplir las leyes aplicables o el ordenamiento jurídico vigente de una autoridad gubernamental de jurisdicción competente.',
  'app.privacy-policy.third-party-websites.sub-header': '5. Sitios web de terceros',
  'app.privacy-policy.third-party-websites.1': 'En el caso de que proporcionemos enlaces a otros sitios web que no están bajo nuestro control, tanto si dichos sitios web hacen referencia a nuestra marca como si no, la presente Política de Privacidad no se aplica a esos sitios web. Aconsejamos a quienes visiten esos sitios web que lean atentamente las políticas de cada uno de esos sitios web.',
  'app.privacy-policy.childrens-privacy.sub-header': '6. Privacidad infantil',
  'app.privacy-policy.childrens-privacy.1': 'Nuestros Servicios no están destinados ni dirigidos a niños menores de 13 años de edad, y los niños no deberían usar nuestros Servicios con ningún fin. Si llega a nuestro conocimiento que hemos recabado o recibido PII de un niño menor de 13 años de edad, borraremos dicha información.',
  'app.privacy-policy.childrens-privacy.2': 'Si usted es el padre, madre o tutor legal de un niño menor de 13 años de edad que se ha registrado en el Sitio web o que ha proporcionado de cualquier otro modo PII a Neighborhood Trust, favor de ponerse en contacto con nosotros a support@neighborhoodtrust.org para que cancelemos la cuenta y eliminemos la información.',
  'app.privacy-policy.childrens-privacy.3': 'Si cree usted que podemos haber recabado accidentalmente PII de un niño de 13 años o menor, por favor contáctenos a {mailToSupportAction} o como se indica posteriormente en la sección “Cómo contactarse con nosotros”.',
  'app.privacy-policy.your-choices.sub-header': '7. Sus decisiones',
  'app.privacy-policy.your-choices.accessing-requesting-changes.subtitle': 'Acceder y solicitar cambios de su PII',
  'app.privacy-policy.your-choices.accessing-requesting-changes.1': 'Si usted es cliente de Neighborhood Trust, puede revisar y cambiar su PII a través de su cuenta de cliente.',
  'app.privacy-policy.your-choices.accessing-requesting-changes.2': 'Si usted no tiene una cuenta de cliente con Neighborhood Trust o si no puede acceder o cambiar su PII a través de su cuenta, pero aún así desea consultar, acceder, corregir, actualizar o borrar PII que pudiéramos tener sobre usted, envíenos un correo electrónico a {mailToSupportAction}. Por favor tenga en cuenta que antes de responder a su petición, podríamos pedirle información adicional para verificar su identidad o realizar otras acciones que consideremos apropiadas con el fin de verificar su identidad.',
  'app.privacy-policy.your-choices.accessing-requesting-changes.3':'Por favor tenga en cuenta también que quizá no podamos modificar o borrar su PII y que conservemos información para proporcionarle los servicios solicitados o para cumplir nuestras obligaciones con usted, por motivos de seguridad, si nos lo exige la ley o regulación aplicable, para uso interno o por otros motivos válidos. Tampoco tenemos obligación de satisfacer peticiones que resulten excesivamente onerosas o costosas o peticiones que interfieran con los derechos de otro individuo. En determinadas circunstancias, es posible que cobremos un precio razonable para satisfacer su petición.',
  'app.privacy-policy.your-choices.communications-preferences.subtitle': 'Preferencias de comunicación:',
  'app.privacy-policy.your-choices.communications-preferences.1': 'Si desea modificar los tipos de comunicaciones que recibe de nosotros o cómo recibe dichas comunicaciones nuestras, o si quiere optar por no recibir comunicaciones promocionales, puede hacerlo en cualquier momento actualizando las preferencias de comunicación proporcionadas en su cuenta de cliente.',
  'app.privacy-policy.your-choices.communications-preferences.2':'Aunque no tenga una cuenta de cliente, puede utilizar los enlaces e instrucciones de no-suscripción que constan en todos los correos electrónicos promocionales y otras comunicaciones que enviamos.',
  'app.privacy-policy.your-choices.communications-preferences.3' : 'Por favor tenga en cuenta que, aunque opte por no recibir comunicaciones promocionales o de marketing, podríamos continuar enviándole comunicaciones no promocionales como confirmaciones de actividades, cambios de los Servicios u otra información sobre su uso de los Servicios.',
  'app.privacy-policy.your-choices.do-not-track.subtitle': 'Ajustes de “No rastrear”:',
  'app.privacy-policy.your-choices.do-not-track.1' : 'En este momento nuestro Sitio web y Servicios no están configurados para leer o responder a los ajustes de “no rastrear” o a los encabezados de su navegador.',
  'app.privacy-policy.notices-to-CA-residents.sub-header': '8. Avisos para los residentes de California',
  'app.privacy-policy.notices-to-CA-residents.1': 'No compartiremos su información personal con terceros para fines comerciales independientes ni fines de marketing directo sin darle antes a usted la capacidad de proporcionar su consentimiento para compartirla. Si usted ha proporcionado dicho consentimiento, la ley de California “Shine the Light” le permite solicitar información sobre la revelación de su Información personal por nuestra parte a terceros con fines de marketing directo de dichos terceros. Para realizar este tipo de solicitud, envíe un correo electrónico a {mailToSupportAction}.',
  'app.privacy-policy.notices-to-VT-residents.sub-header': '9. Avisos para los residentes de Vermont',
  'app.privacy-policy.notices-to-VT-residents.1': 'No revelaremos información sobre su capacidad crediticia a nuestros afiliados y no revelaremos su información personal, información financiera, informe de crédito o información de salud a terceros no afiliados con fines de mercadotecnia, más allá de lo permitido por la ley de Vermont, a menos que usted nos autorice para realizar dichas revelaciones. Puede encontrar más información sobre nuestra Política de Privacidad en  {externalTosLink} o llamando al {tollFreePhone}.',
  'app.privacy-policy.notices-to-VT-residents.2': 'Tiene usted derecho a optar porque no se revele su información personal no pública como se indica anteriormente notificándoselo a Neighborhood Trust por escrito. En algunos casos, es posible que no podamos seguir proporcionándole nuestros servicios.',
  'app.privacy-policy.notices-to-VT-residents.3': 'Por favor consulte la información de contacto al final de este documento para más información.',
  'app.privacy-policy.security.sub-header': '10. Seguridad',
  'app.privacy-policy.security.1': 'Seguridad de la información: ',
  'app.privacy-policy.security.2': 'Tomamos medidas de seguridad físicas y técnicas razonables para protegernos del acceso, modificación, revelación y destrucción no autorizados de datos. Dichas medidas incluyen encriptación, revisiones internas de la recopilación de datos, buenas prácticas y medidas de seguridad de almacenamiento y procesamiento digital y físico, actualización con parches del software pertinente, así como el trabajo con proveedores de la nube fiables para protegernos frente al acceso no autorizado a los sistemas.',
  'app.privacy-policy.security.3': 'Nuestra falta en hacer cumplir cualquiera de las disposiciones de estos Términos de Servicio no se interpretará como una renuncia a dicha disposición ni a ninguna otra disposición. Ninguna renuncia será efectiva contra nosotros a menos que se realicen por escrito, y dicha renuncia no será interpretada como renuncia en ninguna otra instancia. A excepción que usted y nosotros acordemos otra cosa expresamente y por escrito, estos Términos de Servicio y Política de Privacidad constituyen el acuerdo completo entre usted y nosotros con respecto al asunto tratado y sustituyen todos los acuerdos previos o actuales, tanto orales como escritos, entre las partes respecto al asunto tratado. Estos Términos de Servicio y Política de Privacidad redundarán en beneficio de nuestros sucesores, cesionarios, licenciatarios y sublicenciatarios.',
  'app.privacy-policy.changes-to-privacy-policy.sub-header': '11. Cambios de esta Política de Privacidad',
  'app.privacy-policy.changes-to-privacy-policy.1': 'Nosotros podríamos modificar esta Política de Privacidad ocasionalmente. La versión más reciente de la Política de Privacidad se publicará siempre en el Sitio web, con la fecha de entrada en vigor publicada en la parte superior de la Política de Privacidad. Puede que revisemos y actualicemos esta Política de Privacidad si cambian nuestras prácticas a medida que cambia la tecnología o si añadimos nuevos servicios o modificamos los existentes. Si realizamos cambios sustanciales en nuestra Política de Privacidad o en el modo de gestionar su PII, o si vamos a usar su PII de un modo sustancialmente diferente del establecido en nuestra Política de Privacidad en el momento de recabar dicha información, le notificaremos de los cambios con una antelación razonable. Al usar nuestro Sitio web o Servicios tras la fecha de entrada en vigor, se considera que usted acepta nuestra Política de Privacidad vigente en ese momento.',
  'app.privacy-policy.how-to-contact.sub-header': '12. Cómo contactarse con nosotros:',
  'app.privacy-policy.how-to-contact.1': 'Si tiene preguntas, dudas o comentarios sobre esta Política de Privacidad o sobre nuestras prácticas de información, puede enviarnos un correo electrónico a {mailToSupportAction} o enviar correo postal a:',
  'app.privacy-policy.how-to-contact.2': 'Atn: Departamento legal',
  'app.privacy-policy.how-to-contact.3': 'La Política de Privacidad de Neighborhood Trust también puede encontrarse en {externalPrivacyPolicyLink}.',

  'app.appointment.title': 'Elige tu asesor',
  'app.appointment.timezone': 'What time zone is this client’s appointment in?',
  'app.appointment.timezone-note': '(Note: please take standard and daylight savings time into effect when choosing from the following options):',
  'app.appointment.timezone.answer.none': 'Please select from the following',
  'app.appointment.timezone.answer.est': 'Eastern Standard Time, EST',
  'app.appointment.timezone.answer.cst': 'Central Standard Time, CST',
  'app.appointment.timezone.answer.mst': 'Mountain Standard Time, MST',
  'app.appointment.timezone.answer.pst': 'Pacific Standard Time, PST',
  'app.appointment.timezone.answer.ast': 'Alaska Standard Time, AST',
  'app.appointment.timezone.answer.hst': 'Hawaii-Aleutian Standard Time, HST',
  'app.appointment.timezone.answer.edt': 'Eastern Daylight Time, EDT',
  'app.appointment.timezone.answer.cdt': 'Central Daylight Time, CDT',
  'app.appointment.timezone.answer.mdt': 'Mountain Daylight Time, MDT',
  'app.appointment.timezone.answer.pdt': 'Pacific Daylight Time, PDT',
  'app.appointment.timezone.answer.adt': 'Alaska Daylight Time, ADT',
  'app.appointment.timezone.answer.hdt': 'Hawaii-Aleutian Standard Time, HST',
  'app.appointment.timezone-submit': 'Submit',
  'app.appointment-schedule.title': 'Calendario de Reuniones de Treinta Minutos',
  'app.appointment.pick-a-time-with': 'Elija una hora con ',
  'app.appointment.description': 'Todos nuestros consejeros están disponibles en estos tiempos. Cada sesión tomará 30 minutos. Todas las horas son locales en función de su localización.',
  'app.appointment.description2': 'Si no tiene 30 minutos que le sobra, trate ',
  'app.appointment.description.link': 'enviando un mensaje ',
  'app.appointment.description3': ' directamente a un consejero.',
  'app.appointment.ask-a-coach-heading': 'Preguntarle a un entrenador',
  'app.appointment.ask-a-coach-heading.default-to-ask-a-coach-orgs': 'Comience compartiendo sus preguntas con un entrenador',
  'app.appointment.ask-counselor.prompt': 'Hazme una pregunta!',
  'app.appointment.ask-counselor.ask-me': 'Preguntame',
  'app.appointment.ask-counselor.1': 'Qué pregunta tiene sobre tus/sus finanzas? Puedo ayudar!',
  'app.appointment.ask-counselor.2': 'Tu pregunta',
  'app.appointment.ask-counselor.3': 'Para enviar una respuesta, necesitaré su correo electrónico! ¡Me pondré en contacto con usted dentro de tres días laborables!',
  'app.appointment.ask-counselor.3.default-to-ask-a-coach-orgs': 'Proporcione su correo electrónico donde le gustaría recibir una respuesta: ',
  'app.appointment.ask-counselor.4': 'Correo Electrónico',
  'app.appointment.ask-counselor.5': 'Ok! Dejame pensar sobre esto y te enviaré una respuesta lo antes posible!',
  'app.appointment.ask-counselor.5.default-to-ask-a-coach-orgs': 'Ok!  Me pondré en contacto con usted dentro de tres días laborables. También puede continuar con el proceso de registro en las siguientes pantallas y elegir una hora de sesión cuando inicie sesión.',

  'app.appointment.ask-counselor.6': 'Podemos comenzar sacando su informe de crédito o respondiendo algunas preguntas.',
  'app.appointment.ask-counselor.7': ' ¡Siempre puedes programar una sesión de consejería individual conmigo o con cualquier consejero!',
  'app.appointment.ask-counselor.btn.schedule-now': 'Programar una sesión',
  'app.appointment.ask.button': 'Preguntar',
  'app.appointment.submit.button': 'Enviar/Someter',
  'app.appointment.close.button': 'Cerrar',
  'app.appointment.counselors.title': 'Sus consejeros.',
  'app.appointment.phone.placeholder': '(XXX) XXX-XXXX',
  'app.appointment.phone-explanation': 'Le enviaremos un mensaje de texto con un enlace a Skype antes de la cita o lo llamaremos a este número.',
  'app.appointment.submit.skype': 'Programar una cita por Skype',
  'app.appointment.submit.phone': 'Programar una cita por teléfono',
  'app.appointment.alert.start-over': 'No fue posible guardar su cita. Por favor solicite una nueva cita a continuación.',
  'app.no-appointments.title': 'Create a Login',
  'app.no-appointments.description.link': 'send a question',
  'app.no-appointments.description1': 'Our coached don\'t seem to have any available time slots right now. You can either ',
  'app.no-appointments.description2': ' directly to a coach or create a login to check back at any time. Enter your information below to get started.',
  'app.report-or-appointment.title': 'Empecemos.',
  'app.report-or-appointment.text-1': 'Antes de reunirse con su consejero, el primer paso hacia el logro de sus metas es saber su pasado y su presente financiero. Una manera fácil de hacer esto es revisar la información de su reporte de crédito.',
  'app.report-or-appointment.text-2': 'Con su permiso, podemos obtener su reporte de crédito de TransUnion y brindarle información que le ayudará a comprender mejor su situación financiera. La obtención de su reporte de crédito no afectará su puntaje de crédito.',
  'app.report-or-appointment.text-3': 'Este servicio ha sido proporcionado por {employer} y se ofrece sin costo para usted. Nunca compartiremos su información financiera con su empleador.',
  'app.report-or-appointment.agreement': 'Al hacer clic al botón azul de abajo Ud. autoriza a TrustPlus a obtener su reporte de crédito por las razones mencionadas en la parte superior. Asimismo, a fin de brindarle soporte y monitorear su progreso, le da permiso a TrustPlus a obtener su reporte de crédito de TransUnion® y de cualquiera de las tres agencias de informes de crédito mientras dure su participación en nuestros servicios  y hasta por 26 meses después.',
  'app.report-or-appointment.go-to-credit-report-form': 'Quiero mi reporte de crédito',
  'app.report.additional-questions': 'Continuar',
  'app.report-or-appointment.schedule-appointment': 'Brinca este paso',
  'app.credit-report-form.title': 'Obtenga su reporte de crédito.',
  'app.credit-report-form.text': 'Con su autorización, nosotros podemos obtener su reporte de crédito de TransUnion® para ver un resumen de sus deudas. Durante su sesión, exploraremos el reporte y haremos un plan que es adecuado para usted. Obtener este reporte no afectará su puntaje de crédito, esta consulta es considerada como una “consulta suave”.',
  'app.credit-report-form.authorization': 'Al hacer clic al botón azul de abajo Ud. autoriza a TrustPlus a obtener su reporte de crédito por las razones mencionadas en la parte superior. Asimismo, a fin de brindarle soporte y monitorear su progreso, le da permiso a TrustPlus a obtener su reporte de crédito de TransUnion® y de cualquiera de las tres agencias de informes de crédito mientras dure su participación en nuestros servicios y hasta por 26 meses después.',
  'app.credit-report-form.additional-questions': 'Brinca este paso',
  'app.credit-report-form.input.street': 'Dirección',
  'app.credit-report-form.input.city': 'Ciudad',
  'app.credit-report-form.input.state': 'Estado',
  'app.credit-report-form.input.zip-code': 'Código postal',
  'app.credit-report-form.date-of-birth.title': 'Fecha de nacimiento',
  'app.credit-report-form.date-of-birth.placeholder': 'MM/DD/AAAA',
  'app.credit-report-form.input.phone-number': 'Número de teléfono móvil',
  'app.credit-report-form.input.ssn': 'Número de seguro social o ITIN',
  'app.credit-report-form.input.ssn.text': 'Su número de seguro social o ITIN solo se utilizará para obtener su reporte de crédito y nada más, se lo prometemos.',
  'app.credit-report-form.input.ssn.link': '¿Por qué pedimos esta información?',
  'app.credit-report-form.go-to-credit-report': 'Obtener mi reporte de crédito',
  'app.credit-report-form.ssn-modal.title': 'Nos tomamos la seguridad en serio.',
  'app.credit-report-form.ssn-modal.body.1': 'Solamente usamos su número de seguro social/ITIN, fecha de nacimiento y su dirección de residencia para identificarlo. No podemos obtener su reporte de crédito sin esta información.',
  'app.credit-report-form.ssn-modal.body.2': 'Encriptamos su SSN/ITIN, almacenándolo en el siguiente formato: ',
  'app.credit-report-form.ssn-modal.body.3': 'Para ver más detalles sobre nuesta política de seguridad, visite {link}',
  'app.debt-report-interim.title': 'Un momento.',
  'app.debt-report-interim.explanation.title': 'Tardará un solo momento retirar su reporte de crédito de manera segura.',
  'app.debt-report-interim.try-again.title': 'Tuvimos un problema procesando su reporte de crédito.',
  'app.debt-report-interim.try-again.button': 'Intentar de nuevo',
  'app.debt-report-interim.skip.button': 'Vaya directamente al cuestionario',
  'app.debt-report-interim.continue.button': 'Continuar',
  'app.debt-report-recommendations.recommendations': 'Recomendaciones',
  'app.debt-report-recommendations.credit-report': 'Reporte de crédito',
  'app.debt-report-recommendations.none-header': 'No hay recomendaciones instantáneas',
  'app.debt-report-recommendations.none-text-1': 'No detectamos ningún señal de alerta inmediata para resolver, pero nuestros asesores expertos revisarán su informe de crédito cuidadosamente para ayudarlo a resolver cualquier problema de crédito y deuda que necesite en su próxima sesión.',
  'app.debt-report-recommendations.none-text-2': 'Puede explorar los detalles usted mismo en la pestaña de informe de crédito.',
  'app.debt-report-recommendations.debt-report-header': 'Informe de crédito',
  'app.debt-report-recommendations.debt-report-text-1': 'Deuda Total',
  'app.debt-report-recommendations.top-priority': 'Alto Prioridad',
  'app.debt-report-recommendations.new-top-priority': 'Nueva prioridad principal',
  'app.debt-report-recommendations.collections': 'Colecciones',
  'app.debt-report-recommendations.let-us-know-about-you': 'Déjanos saber de ti',
  'app.debt-report-recommendations.skip-these-questions': 'Omita estas preguntas',
  'app.debt-report-recommendations.go-to-questionnaire': 'Ir al cuestionario ',
  'app.debt-report-recommendations.collections-text-1a': 'Vaz a reclamar lo siguiente ',
  'app.debt-report-recommendations.collections-text-1b': ' colecciones:',
  'app.debt-report-recommendations.collections-text-1':'Encontramos ',
  'app.debt-report-recommendations.collections-text-2': ' colecciones en su informe de Transunion. Una cuenta de cobro representa una deuda que un acreedor original vendió a otro acreedor - una agencia de cobro - después de que la deuda no se pagó durante cierto tiempo. Las colecciones disminuyen tu puntaje de crédito, pero hay pasos que puedes tomar para remediarlas.',
  'app.debt-report-recommendations.collections-header': 'Detalles de las colecciones',
  'app.debt-report-recommendations.collections-text-3': ' busca colectar ',
  'app.debt-report-recommendations.collections-text-4': ' desde una deuda con ',
  'app.debt-report-recommendations.collections-text-6': 'No quiero trabajar en esto en este momento.',
  'app.debt-report-recommendations.collections-text-7': 'Ya haz visto a:',
  'app.debt-report-recommendations.collections-text-8': 'Esperando para reclamar',
  'app.debt-report-recommendations.collections-btn-text': 'Trabajar en esto',
  'app.debt-report-work-on-this.text-1': 'Así aparece este artículo en el informe de crédito:',
  'app.debt-report-work-on-this.table-row-1': 'Acreedor original',
  'app.debt-report-work-on-this.table-row-2': 'Tipo de cuenta',
  'app.debt-report-work-on-this.table-row-2-text': 'Coleccion',
  'app.debt-report-work-on-this.table-row-3': 'Saldo de cuenta',
  'app.debt-report-work-on-this.table-row-4': 'Fecha de apertura',
  'app.debt-report-work-on-this.header-text-1': '¿Qué significa esto?',
  'app.debt-report-work-on-this.text-2': 'Los informes crediticios pueden parecer confusos, pero vamos a descomponerlo. ',
  'app.debt-report-work-on-this.text-3': ' es una agencia de colección que informó a Transunion(R) que son los propietarios de una de sus deudas. Es muy probable que hayan intentado ponerse en contacto con usted, ya sea por correo o por teléfono.',
  'app.debt-report-work-on-this.text-4': 'El acreedor original aparece en la lista como ',
  'app.debt-report-work-on-this.text-5': ', lo que significa que originalmente se le debía la deuda. Después de no recibir el pago por un período de tiempo, vendieron la deuda a ',
  'app.debt-report-work-on-this.text-6': '. Es poco probable que pueda trabajar con ',
  'app.debt-report-work-on-this.text-7': ' en la deuda ahora que la agencia de colección ha reportado la deuda.',
  'app.debt-report-work-on-this.text-8': 'El saldo se reporta como',
  'app.debt-report-work-on-this.text-9': ', que es probablemente más de lo que debía ',
  'app.debt-report-work-on-this.text-10': '. Esto incluye la cantidad no pagada, más cargos y cargos de interés.',
  'app.debt-report-work-on-this.text-11': 'La “fecha de apertura” con la agencia de recogida se indica como ',
  'app.debt-report-work-on-this.text-12': '. Esto indica la fecha en que ',
  'app.debt-report-work-on-this.text-13': ' compró oficialmente la deuda y comenzó a cobrar la deuda.',
  'app.debt-report-work-on-this.header-text-2': '¿Que Puedo Hacer?',
  'app.debt-report-work-on-this.text-14': '¡Hay algunas opciones! Te ayudaremos a guiarte con algunas preguntas a continuación.',
  'app.debt-report-work-on-this.text-15': 'Primero, ',
  'app.debt-report-work-on-this.header-text-3': '¿Reconoce esta cuenta? ',
  'app.debt-report-work-on-this.btn-1': 'Conozco esta cuenta y entiendo que debo el saldo',
  'app.debt-report-work-on-this.btn-2': 'Conozco esta cuenta, pero pagué esta deuda y no debo pagar nada',
  'app.debt-report-work-on-this.btn-3': 'No reconozco esta cuenta',
  'app.debt-report-reviewed-collections.title': 'Colecciones',
  'app.debt-report-reviewed-collections.text-1': 'Disputará las siguientes ',
  'app.debt-report-reviewed-collections.text-2': ' transacciones:',
  'app.debt-report-reviewed-collections.btn-text-1': 'Disputar en línea',
  'app.debt-report-reviewed-collections.text-3': 'Has optado por hablar con un asesor sobre la siguiente colección:',
  'app.debt-report-reviewed-collections.text-4': 'Cita confirmada',
  'app.debt-report-reviewed-collections.recognize-account.title': 'Reconozco la deuda.',
  'app.debt-report-reviewed-collections.recognize-account.text-1': 'Ok, gracias por confirmar que reconoce la deuda.',
  'app.debt-report-reviewed-collections.recognize-account.text-2': 'Si está listo para tomar medidas para abordarlo, lo primero que hará con su asesor es validar la deuda para confirmar que la agencia de cobro que informa esta deuda está cumpliendo con sus obligaciones legales.',
  'app.debt-report-reviewed-collections.recognize-account.text-3': 'Su asesor le dará más detalles en su sesión y le ayudará a saber cómo priorizar este elemento entre sus otras cuentas y objetivos de su informe de crédito.',
  'app.debt-report-reviewed-collections.do-not-recognize-account.title': 'Qué hacer cuando no reconoces una cuenta.',
  'app.debt-report-reviewed-collections.do-not-recognize-account.text-1': 'No es raro que un informe de crédito tenga información inexacta. Un estudio de Consumer Reports en 2021 muestra que el 34 % de los consumidores encontraron errores en su informe de crédito.',
  'app.debt-report-reviewed-collections.do-not-recognize-account.text-2': 'Afortunadamente, TransUnion proporciona una herramienta en línea para disputar artículos en su informe de crédito. Sin embargo, antes de hacerlo, asegurémonos de que todo lo demás se ve bien.',
  'app.debt-report-collections.help-dispute-back-to-collections': 'Volver a todas las colecciones',
  'app.debt-report-collections.dispute-step-1-title': 'Instrucciones para la disputa',
  'app.debt-report-collections.dispute-step-1-subtitle': 'Primer paso',
  'app.debt-report-collections.dispute-step-1-text-1': 'Regístrese para obtener una cuenta en ',
  'app.debt-report-collections.dispute-step-1-text-2': 'ServiceTransunion.com',
  'app.debt-report-collections.dispute-step-1-text-3': '. Tendrá que introducir su nombre, dirección y los últimos cuatro dígitos de su número de seguro social. Además, deberá crear un nombre de usuario y una contraseña. Se le enviará un código a su correo electrónico para confirmar su cuenta.',
  'app.debt-report-collections.dispute-step-1-text-4': 'Nota: Es posible que se le solicite que verifique su identidad llamando al 866-744-8221. Siga las indicaciones, un representante de servicio recogerá la llamada y hará preguntas para verificar su identidad. Un representante le hará preguntas para confirmar su identidad.',
  'app.debt-report-collections.dispute-next-step-btn': 'Proximo paso',
  'app.debt-report-collections.dispute-previous-step-btn': 'Paso anterior',
  'app.debt-report-collections.dispute-step-2-subtitle': 'Segundo paso',
  'app.debt-report-collections.dispute-step-2-text-1': 'En el panel de control de TransUnion, busque la sección “Dispute” y haga clic en “Get Started”. Verá un panel informativo donde hará clic en “Start Request”. Tendrá que aceptar los términos y condiciones.',
  'app.debt-report-collections.dispute-step-3-subtitle': 'Tercer paso',
  'app.debt-report-collections.dispute-step-3-text-1': 'Localice los elementos incorrectos que identificó anteriormente:',
  'app.debt-report-collections.dispute-step-3-text-2': 'Necesitará pasar por cada uno y hacer clic en “Dispute”. Seleccione [No es mío] “It is not mine” o [Es inexacto] “It’s inaccurate” para ver razones más específicas. Seleccione la razon que mejor se ajuste a cada cuenta y suba cualquier documentación de apoyo si está disponible (por ejemplo, si tiene un aviso del prestamista que muestra que se ha pagado un saldo).',
  'app.debt-report-collections.dispute-step-3-text-3': 'Repita este proceso para cada uno de los elementos anteriores.',
  'app.debt-report-collections.dispute-step-3-text-4': 'Nota: No es necesario subir documentos de apoyo.',
  'app.debt-report-collections.dispute-step-4-subtitle': 'Cuarto paso',
  'app.debt-report-collections.dispute-step-4-text-1': 'Una vez que haya submitido su(s) disputa(s), será redirigido al panel de control donde podrá ver todo los elementos activos. También recibirá un correo electrónico de Transunion confirmando su disputa. Le enviarán un correo electrónico una vez que se haya resuelto una cuenta. Es probable que su estado de disputa tome un par de semanas para que se actualice.',
  'app.debt-report-collections.dispute-back-to-summary-btn': 'Volver al resumen',
  'app.debt-report-reviewed-collections.dispute-offline': 'Quiero disputar fuera de linea.',
  'app.debt-report-reviewed-collections.dispute-offline.title': 'Disputar fuera de linea',
  'app.debt-report-reviewed-collections.dispute-offline.text-1': 'Tu asesor financiero puede ayudarle disputar sus cuentas en colleciones. Los asesores pueden prepararle una carta que puede enviar a TransUnion o a cualquiera de las agencias de crédito que detalla las cuentas que está disputando, la razón por la que las está disputando, así como sus derechos como consumidor.',
  'app.debt-report-collections.to-questionnaire': 'You\'ll be able to see information on your disputes again once you log in. But to help us deliver the best service to you, please answer some basic questions about yourself.',


  'app.debt-report.title': 'Información de su reporte de crédito.',
  'app.debt-report.total-debt-subtitle': 'Esta es su deuda total según su reporte de crédito actual.',
  'app.debt-report.total-debt-chart.title': 'DEUDA TOTAL',
  'app.debt-report.total-debt-chart.category.mortgage': 'Bienes raíces',
  'app.debt-report.total-debt-chart.category.auto': 'Préstamo auto',
  'app.debt-report.total-debt-chart.category.student': 'Préstamo estudiantil',
  'app.debt-report.total-debt-chart.category.credit': 'Tarjeta de crédito',
  'app.debt-report.total-debt-chart.category.collection': 'Colección',
  'app.debt-report.total-debt-chart.category.personal': 'Préstamo personal',
  'app.debt-report.total-debt-chart.category.other': 'Otro',
  'app.debt-report.debt-pie-chart.total-debt': 'Deuda total',
  'app.footer.sponsored-by': 'Proporcionado por',
  'app.footer.terms': 'Terminos del Servicio',
  'app.footer.privacy':'Política de Privacidad',
  'app.footer.faq':'FAQ',
  'app.footer-ap.terms': 'los Términos de Servicio',
  'app.footer-ap.privacy':'la Política de Privacidad',
  'app.footer.calendly.intro1': 'Al programar una consulta, confirmas que has leído y aceptas ',
  'app.footer.calendly.intro2': ' de TrustPlus.',
  'app.footer.support': '¿Necesitas ayuda? Envíanos un correo electrónico a ',
  'app.schedule-confirmation.title': '¡Perfecto. Has programado tu consulta!',
  'app.schedule-confirmation.description-1': 'Recibirá una confirmación y mensaje de texto recordándole sobre su cita. ¡Por favor confirme! ',
  'app.schedule-confirmation.description-2': 'Mientras tanto, díganos un poco más sobre su situación financiera para mejor utilizar el tiempo durante su cita.',
  'app.schedule-confirmation.continue.title': 'Su perfil financiero: ',
  'app.schedule-confirmation.percent.complete-text': 'completado',
  'app.schedule-confirmation.counselor.bubble.text': 'El día de la cita, te enviaré un enlace de videollamada o te llamaré desde mi número dedicado, que tiene un código de área 929.',
  'app.schedule-confirmation.pull.report.button': 'Quiero mi reporte de crédito',
  'app.schedule-confirmation.question.button': 'Vaya al cuestionario',
  'app.thank-you.title': '¡Gracias!',
  'app.thank-you.summary': '¡Nos comunicaremos pronto con usted!',
  'app.account-confirmation.title': '¡Gracias por registrarte!',
  'app.account-confirmation.counselor.text': 'We can start by pulling your credit report or answering a few questions.',
  'app.additional-questions.goals.title': 'Additional Questions',
  'app.additional-questions.goals.question.sign-up': '¿Por qué se inscribió hoy?',
  'app.additional-questions.goals.answer.A life change': 'Un cambio de vida',
  'app.additional-questions.goals.answer.To build credit': 'Para construir crédito',
  'app.additional-questions.goals.answer.To help build savings': 'Aumentar los ahorros',
  'app.additional-questions.goals.answer.Struggling with debt': 'Lidiar con deudas',
  'app.additional-questions.goals.answer.Living paycheck to paycheck': 'Viviendo de cheque en cheque',
  'app.additional-questions.goals.answer.I was denied a loan due to bad credit': 'Me negaron un crédito',
  'app.additional-questions.goals.answer.To plan for a large purchase': 'Planear para una compra',
  'app.additional-questions.goals.answer.To save for a goal': 'Ahorrar para una meta',
  'app.additional-questions.goals.answer.To deal with other financial problems': 'Solucionar otros problemas financieros',
  'app.additional-questions.goals.answer.To make sure I\'m on the right path': 'Para asegurarme que estoy en el camino correcto',
  'app.additional-questions.goals.answer.Just curious': 'Simple curiosidad',
  'app.additional-questions.goals.question.confidence-of-goals': '¿Qué tan seguro está de que su hogar está haciendo lo que se necesita para alcanzar sus metas a largo plazo?',
  'app.additional-questions.goals.answer.': 'Seleccione una de las siguientes',
  'app.additional-questions.goals.answer.Very confident': 'Muy confiado',
  'app.additional-questions.goals.answer.Moderately confident': 'Moderadamente confiado',
  'app.additional-questions.goals.answer.Somewhat confident': 'Algo confiado',
  'app.additional-questions.goals.answer.Slightly confident': 'Un poco confiado',
  'app.additional-questions.goals.answer.Not at all confident': 'No del todo confiado',
  'app.additional-questions.goals.question.plans-ahead-financially': 'En qué medida está de acuerdo o en desacuerdo con lo siguiente: “Mi hogar planea con anticipación financieramente.”',
  'app.additional-questions.goals.answer.Agree strongly': 'Fuertemente de acuerdo',
  'app.additional-questions.goals.answer.Agree somewhat': 'Un poco de acuerdo',
  'app.additional-questions.goals.answer.Neither agree or disagree': 'Ni de acuerdo ni desacuerdo',
  'app.additional-questions.goals.answer.Disagree somewhat': 'Algo en desacuerdo',
  'app.additional-questions.goals.answer.Disagree strongly': 'Totalmente en desacuerdo',
  'app.additional-questions.goals.question.tell-counselor': '¿Específicamente, que quisiera que sui consejero supiera antes de su sesión?',
  'app.additional-questions.goals.question.which-santander-branch': '¿Qué sucursal de Santander lo refirió (si corresponde)?',
  'app.additional-questions.banking.title': 'Cuéntenos cómo utiliza su banco.',
  'app.additional-questions.banking.question.savings-amount': '¿Cuánto ha reservado en efectivo o en ahorros líquidos?',
  'app.additional-questions.banking.answer.': 'Seleccione una de las siguientes',
  'app.additional-questions.banking.answer.$0-99': '$0-99',
  'app.additional-questions.banking.answer.$100-399': '$100-399',
  'app.additional-questions.banking.answer.$400-1000': '$400-1000',
  'app.additional-questions.banking.answer.$1000-2000': '$1000-2000',
  'app.additional-questions.banking.answer.$2000+': '$2000+',
  'app.additional-questions.banking.question.checking-account': '¿Tiene una cuenta corriente?',
  'app.additional-questions.banking.question.savings-account': '¿Tiene una cuenta de ahorros?',
  'app.additional-questions.banking.question.direct-deposit': '¿Usa depósito directo?',
  'app.additional-questions.banking.answer.yes': 'Sí',
  'app.additional-questions.banking.answer.no': 'No',
  'app.additional-questions.banking.answer.Yes - Checking': 'Sí, a mi cuenta corriente',
  'app.additional-questions.banking.answer.Yes - Savings': 'Sí, a mi cuenta de ahorros',
  'app.additional-questions.banking.answer.Yes - Split': 'Sí, dividido entre cuenta corriente y cuenta de ahorros',
  'app.additional-questions.banking.answer.Yes - Other': 'Sí, a otra cuenta',
  'app.additional-questions.banking.answer.Unsure': 'No estoy seguro/a',
  'app.additional-questions.banking.answer.No': 'No, yo no utilizo depósito directo',
  'app.additional-questions.banking.question.current-expenses-time-length': '¿Por cuánto tiempo podría su hogar permitirse cubrir los gastos actuales, sin retirar dinero de las cuentas de jubilación o pedir préstamos?',
  'app.additional-questions.banking.answer.6 months or more': '6 meses o más',
  'app.additional-questions.banking.answer.3-5 months': '3-5 meses',
  'app.additional-questions.banking.answer.1-2 months': '1-2 meses',
  'app.additional-questions.banking.answer.1-3 weeks': '1-3 semanas',
  'app.additional-questions.banking.answer.Less than 1 week': 'Menos de 1 semana',
  'app.additional-questions.banking.question.confident-in-emergency': '¿Qué tan seguro está de que las pólizas de seguro que usted y otras personas en su hogar tienen proporcionarán suficiente apoyo en una emergencia?',
  'app.additional-questions.banking.answer.Very confident': 'Muy confiado',
  'app.additional-questions.banking.answer.Moderately confident': 'Moderadamente confiado',
  'app.additional-questions.banking.answer.Somewhat confident': 'Algo confiado',
  'app.additional-questions.banking.answer.Slightly confident': 'Un poco confiado',
  'app.additional-questions.banking.answer.Not at all confident': 'No del todo confiado',
  'app.additional-questions.banking.answer.No one in my household has any insurance': 'Nadie en mi casa tiene seguro',
  'app.additional-questions.employment.title': 'Cuéntenos sobre su trabajo.',
  'app.additional-questions.employment.question.annual-income': '¿Cuánto gana al año?\n',
  'app.additional-questions.employment.question.payment-frequency': '¿Cada cuánto le pagan?',
  'app.additional-questions.employment.answer.Daily': 'Diario',
  'app.additional-questions.employment.answer.Weekly': 'Semanalmente',
  'app.additional-questions.employment.answer.Every other week or twice per month': 'Cada 2 semanas o 2 veces al mes',
  'app.additional-questions.employment.answer.Monthly': 'Mensualmente',
  'app.additional-questions.employment.answer.It varies/Other': 'Es variable',
  'app.additional-questions.employment.question.payment-same-amount': '¿Su cheque es normalmente el mismo valor?',
  'app.additional-questions.employment.answer.Yes': 'Sí',
  'app.additional-questions.employment.answer.No': 'No',
  'app.additional-questions.employment.question.last-paycheck-amount': '¿Cuánto fue su último pago?',
  'app.additional-questions.employment.question.paycheck-amounts': '¿Cuánto fue el valor sus últimos 2 cheques?',
  'app.additional-questions.employment.question.employment-status': '¿Hay algo más que nosotros debiéramos saber?',
  'app.additional-questions.employment.answer.I work full time': 'Yo trabajo tiempo completo',
  'app.additional-questions.employment.answer.I work part time': 'Yo trabajo medio tiempo',
  'app.additional-questions.employment.answer.I have a side gig': 'Yo tengo un ingreso adicional',
  'app.additional-questions.employment.answer.I am self-employed': 'Soy trabajador por cuenta propia',
  'app.additional-questions.employment.answer.I often work overtime': 'Usualmente trabajo horas extras',
  'app.additional-questions.employment.answer.I work two jobs': 'Yo tengo 2 trabajos',
  'app.additional-questions.employment.answer.I am not working right now': 'No estoy trabajando en este momento',
  'app.additional-questions.employment.answer.No earned income at this time': 'Sin ingresos del trabajo en este momento',
  'app.additional-questions.employment.answer.Someone else contributes to my family\'s finances': 'Alguien más contribuye con las finanzas familiares\n(Esposa(o), padres, amiga(o), etc.)',
  'app.additional-questions.employment.answer.staples.site-type.': 'Por favor elija su clase de sitio (de la lista siguiente)',
  'app.additional-questions.employment.answer.staples.site-type.FC': 'FC',
  'app.additional-questions.employment.answer.staples.site-type.DC': 'DC',
  'app.additional-questions.employment.answer.staples.site-type.SDO': 'SDO',
  'app.additional-questions.employment.answer.staples.site-type.Furniture': 'Furniture',
  'app.additional-questions.employment.answer.staples.city.': 'Por favor elija su ciudad',
  'app.additional-questions.employment.question.unemployed-2020': '¿Estabas desempleado en 2020?',
  'app.additional-questions.employment.question.caregiver-775': 'Eres un cuidador?',
  'app.additional-questions.employment.question.company-775': 'Para qué compañía trabaja usted?',
  'app.additional-questions.employment.answer.company-775.Addus Healthcare': 'Addus Healthcare',
  'app.additional-questions.employment.answer.company-775.Amicable Healthcare': 'Amicable Healthcare',
  'app.additional-questions.employment.answer.company-775.Catholic Community Service': 'Catholic Community Service',
  'app.additional-questions.employment.answer.company-775.Chesterfield Service': 'Chesterfield Service',
  'app.additional-questions.employment.answer.company-775.Concerned Citizen': 'Concerned Citizen',
  'app.additional-questions.employment.answer.company-775.First Choice': 'First Choice',
  'app.additional-questions.employment.answer.company-775.Full Life Elder Care': 'Full Life Elder Care',
  "app.additional-questions.employment.answer.company-775.Korean Womens Association (KWA)": "Korean Women's Association (KWA)",
  'app.additional-questions.employment.answer.company-775.Millennia Healthcare': 'Millennia Healthcare',
  'app.additional-questions.employment.answer.company-775.Always Caring (also known as ResCare or Bright Spring)': 'Always caring (también reconocido como ResCare o Bright Spring)',
  'app.additional-questions.employment.answer.company-775.CDWA, DSHS, IP, or independent contractor': 'CDWA, DSHS, IP, o contratista independiente',
  'app.additional-questions.employment.question.how-long-unemployed': '¿Cuánto tiempo estuviste desempleado?',
  'app.additional-questions.employment.answer.1-3 months' : '1-3 meses',
  'app.additional-questions.employment.answer.4-6 months' : '4-6 meses',
  'app.additional-questions.employment.answer.More than 6 months' : 'más de 6 meses',
  'app.additional-questions.employment.question.filed-2020-taxes': '¿Presentó sus impuestos?',
  'app.additional-questions.employment.answer.Yes, and I received a refund + EITC (earned income tax credit)' : 'Sí, y recibí un reembolso + EITC',
  'app.additional-questions.employment.answer.Yes, and I received a refund' : 'Sí, y recibí un reembolso',
  'app.additional-questions.employment.answer.Yes, and I owed taxes' : 'Sí, y debo impuestos',
  'app.additional-questions.employment.answer.Not yet' : 'Todavía no',
  'app.additional-questions.employment.answer.I do not file taxes' : 'No presento mis impuestos',
  'app.additional-questions.owner-or-employee.question.owner-or-employee': '¿Eres propietario de una pequeña empresa o empleado?',
  'app.additional-questions.owner-or-employee.answer.An employee at a small business': 'Empleado de un pequeño negocio',
  'app.additional-questions.owner-or-employee.answer.A small business owner': 'Dueño de un pequeño negocio',
  // onboarding employment tax tooltips
  'app.additional-questions.owner-or-employee.tax-tooltip': 'Owner or employee tax tool tip.',
  'app.emergency-questions.what-is-your-annual-income.tax-tooltip': 'Annual income tax tool tip',
  'app.additional-questions.covid-questions.describe-current-or-most-recent-industry.tax-tooltip': 'Current or recent industry tax tool tip.',
  'app.additional-questions.covid-questions.describe-family-earnings.tax-tooltip': 'Describe family earnings tax tool tip.',
  'app.additional-questions.employment.question.how-long-unemployed.tax-tooltip': 'How long unemployed tax tool tip.',
  'app.additional-questions.employment.question.filed-2020-taxes.tax-tooltip': '¿Sabías que el nuevo “método retroactivo" significa que puedes usar tus ingresos de 2019 o 2020 para obtener un reembolso de impuestos mas grande? Tenga estos listos cuando presente: ',
  'app.additional-questions.employment.question.filed-2020-taxes.tax-tooltip-one': '1. Formulario 1099 G, (Está forma dice cuanto ha recibido desde el fondo de des empleo. Tendrá qué descargar lo desde el sit-up web del empleo de su estado.)',
  'app.additional-questions.employment.question.filed-2020-taxes.tax-tooltip-two': '2. Tu declaración de impuestos 2019',
  'app.additional-questions.covid-questions.what-assistance-have-you-received.tax-tooltip':'Assistance received tax tool tip.',

  'app.additional-questions.expenses.title': 'Cuéntenos sobre sus gastos.',
  'app.additional-questions.household.title': 'Cuéntenos acerca de su familia: ',
  'app.additional-questions.household.question.living-situation': 'Cuéntenos acerca de su situación actual:\n',
  'app.additional-questions.household.answer.I pay rent': 'Yo pago renta',
  'app.additional-questions.household.answer.I pay a mortgage': 'Yo tengo una hipoteca',
  'app.additional-questions.household.answer.I live with a family member or friend': 'Yo vivo con un familiar o amigo',
  'app.additional-questions.household.answer.Other': 'déjame escribirl',
  'app.additional-questions.household.question.monthly-rent': '¿Cuánto es su renta mensual?',
  'app.additional-questions.household.question.monthly-mortgage': '¿Cuánto es su hipoteca al mes?',
  'app.additional-questions.household.question.monthly-family-friend': '¿Cuál es su contribución mensual al hogar?',
  'app.additional-questions.household.question.monthly-other': '¿Cuáles son sus gastos mensuales de vivienda?',
  'app.additional-questions.household.question.additional-monthly-costs': '¿Tiene algún gasto adicional al mes?',
  'app.additional-questions.household.answer.Maintenance': 'Mantenimiento',
  'app.additional-questions.household.answer.Utilities': 'Servicios públicos',
  'app.additional-questions.household.answer.Insurance': 'Seguros',
  'app.additional-questions.household.question.household-spending': '¿Cuál de las siguientes opciones describe mejor los gastos de su hogar en los últimos 12 meses?',
  'app.additional-questions.household.answer.': 'Seleccione una de las siguientes',
  'app.additional-questions.household.answer.Much less than income': 'Menos que el ingreso',
  'app.additional-questions.household.answer.A little less than income': 'Un poco menos que el ingreso',
  'app.additional-questions.household.answer.About equal to income': 'Casi igual al ingreso',
  'app.additional-questions.household.answer.A little more than income': 'Un poco más que el ingreso',
  'app.additional-questions.household.answer.Much more than income': 'Mucho más que el ingreso',
  'app.additional-questions.household.question.household-bill-payment': '¿Cuál de las siguientes opciones describe mejor el pago las facturas de su hogar en los últimos 12 meses? Mi hogar ha podido: ',
  'app.additional-questions.household.answer.Pay all of our bills on time': 'Pagar todas nuestras facturas a tiempo',
  'app.additional-questions.household.answer.Pay nearly all of our bills on time': 'Pagar casi todas nuestras facturas a tiempo',
  'app.additional-questions.household.answer.Pay most of our bills on time': 'Pagar la mayoría de nuestras facturas a tiempo',
  'app.additional-questions.household.answer.Pay some of our bills on time': 'Pagar algunas de nuestras facturas a tiempo',
  'app.additional-questions.household.answer.Pay very few of our bills on time': 'Pagar muy pocas de nuestras facturas a tiempo',
  'app.additional-questions.feelings.title': 'Cuéntenos cómo se siente.',
  'app.additional-questions.feelings.question.stress': '¿Qué tan estresado/a se siente por sus finanzas y deudas?',
  'app.additional-questions.feelings.question.stress.help': '(1 es bajo estrés, 5 es alto estrés)',
  'app.additional-questions.feelings.answer.1': '1',
  'app.additional-questions.feelings.answer.2': '2',
  'app.additional-questions.feelings.answer.3': '3',
  'app.additional-questions.feelings.answer.4': '4',
  'app.additional-questions.feelings.answer.5': '5',
  'app.additional-questions.feelings.question.challenge': '¿Qué tan difícil le es administrar sus finanzas?',
  'app.additional-questions.feelings.question.challenge.help': '(1 es fácil, 5 es muy difícil)',
  'app.additional-questions.demographics.title': 'Cuéntenos más sobre usted.',
  'app.additional-questions.demographics.question.marital-status': '¿Cuál es su estado civil?',
  'app.additional-questions.demographics.question.partner-contribution': '¿Tiene una pareja o ex pareja que contribuye a sus finanzas (alquiler, alimentación, cuidado de niños, una cuenta compartida, etc.)?',
  'app.additional-questions.demographics.question.household-size': '¿Cuántas personas hay en su hogar, incluyendo a usted?',
  'app.additional-questions.demographics.question.number-dependents': '¿Cuántos dependientes (menores de 18 años) tiene?',
  'app.additional-questions.demographics.question.race': '¿Cuál es su raza?',
  'app.additional-questions.demographics.question.education': '¿Cuál es su nivel más alto de educación?',
  'app.additional-questions.demographics.answer.': 'Seleccione una de las siguientes',
  'app.additional-questions.demographics.answer.Yes, and we live together': 'Sí, y vivimos juntos',
  'app.additional-questions.demographics.answer.Yes, but we do not live together': 'Sí, pero no vivimos juntos',
  'app.additional-questions.demographics.answer.No': 'No',
  'app.additional-questions.demographics.answer.Single': 'Soltero/a',
  'app.additional-questions.demographics.answer.Domestic Partner/ unmarried': 'Pareja doméstica / convivente',
  'app.additional-questions.demographics.answer.Married': 'Casado/a',
  'app.additional-questions.demographics.answer.Divorced': 'Divorciado/a',
  'app.additional-questions.demographics.answer.Separated': 'Separado/a',
  'app.additional-questions.demographics.answer.Widowed': 'Viudo/a',
  'app.additional-questions.demographics.answer.1': '1',
  'app.additional-questions.demographics.answer.2': '2',
  'app.additional-questions.demographics.answer.3': '3',
  'app.additional-questions.demographics.answer.4': '4',
  'app.additional-questions.demographics.answer.5': '5',
  'app.additional-questions.demographics.answer.6': '6',
  'app.additional-questions.demographics.answer.7': '7',
  'app.additional-questions.demographics.answer.8': '8',
  'app.additional-questions.demographics.answer.9': '9',
  'app.additional-questions.demographics.answer.10': '10',
  'app.additional-questions.demographics.answer.10+': '10+',
  'app.additional-questions.demographics.answer.Some high school': 'Algo de escuela secundaria',
  'app.additional-questions.demographics.answer.High school diploma/ GED': 'Diploma de escuela secundaria / GED',
  'app.additional-questions.demographics.answer.Some college': 'Algo de pregrado',
  'app.additional-questions.demographics.answer.College degree': 'Título de pregrado',
  'app.additional-questions.demographics.answer.Postgraduate studies': 'Estudios de posgrado',
  'app.additional-questions.demographics.answer.Black or African American': 'Afroamericano/a',
  'app.additional-questions.demographics.answer.Latino/Latina/Latinx': 'Latino/Latina/Latinx',
  'app.additional-questions.demographics.answer.Asian American': 'Asiático/a americano/a',
  'app.additional-questions.demographics.answer.Pacific Islander': 'Isleño/a del Pacífico',
  'app.additional-questions.demographics.answer.American Indian': 'Indio/a americano/a',
  'app.additional-questions.demographics.answer.White': 'Blanco/a',
  'app.additional-questions.demographics.answer.Alaska Native' : 'Nativo/a de Alaska',
  'app.additional-questions.demographics.answer.Other': 'Otra',
  'app.additional-questions.advocacy.title': 'Cuéntenos cómo hacer una diferencia.',
  'app.additional-questions.advocacy.text': 'TrustPlus le permite tomar el control de sus finanzas, pero cada día vemos gente trabajadora que toma las decisiones correctas y que todavía encuentra sistemas que hacen difícil apoyar a sus familias y vivir una buena vida. Nos gustaría abogar por nuestros clientes y cambiar esos sistemas.',
  'app.additional-questions.advocacy.question.advocacy-question': '¿Alguna cosa fuera de su control le ha retenido de alcanzar sus metas financieras? Cuéntenos.\n',
  'app.additional-questions.advocacy.answer.My wages were higher':'Salarios más altos',
  'app.additional-questions.advocacy.answer.My work schedule and pay were more predictable':'Un sueldo y un horario de trabajo más predecibles',
  'app.additional-questions.advocacy.answer.I could borrow money from someone at a reasonable rate':'La capacidad de pedir dinero prestado a una tasa razonable',
  'app.additional-questions.advocacy.answer.I had better employee benefits (insurance, paid time off, retirement, etc.)':'Mejores beneficios para los empleados (seguro, tiempo libre pagado, jubilación, etc.).',
  'app.additional-questions.advocacy.answer.I had access to more affordable child care or other dependent care':'Mejor acceso a un cuidado infantil más asequible u otro cuidado de dependientes',
  'app.additional-questions.advocacy.answer.I had more help with my student loans':'Asistencia con préstamos estudiantiles',
  'app.additional-questions.advocacy.answer.Public benefits were more generous':'Beneficios públicos más generosos',
  'app.additional-questions.advocacy.continue-to-debt-report': 'Continuar',

  'app.additional-questions.continue': 'Continuar',

  'app.debt-credit-self-evaluation.title':'Cuéntenos sobre su crédito.',
  'app.debt-credit-self-evaluation-with-credit-report.details':'Antes de mostrarles un imagen de su crédito y deuda, nos encantaría saber cómo ve usted su situación de crédito y deuda a partir de hoy.',
  'app.debt-credit-self-evaluation-without-credit-report.details': 'No es un problema que no hayas sacado tu informe de crédito, pero saber cómo ve su situación de crédito y deuda nos ayudará en su sesión de asesoría.',
  'app.household-debt-self-evaluation.question': '¿Cuál de las siguientes declaraciones describe qué manejable su deuda familiar (incluyendo hipotecas, préstamos estudiantiles, préstamos bancarios, dinero debido a la gente, deuda médica, tarjetas de crédito y cuentas atrasadas)?',
  'app.household-debt-self-evaluation.answer.': 'Seleccione una de las siguientes',
  'app.household-debt-self-evaluation.answer.Do not have any debt': 'No tengo ninguna deuda',
  'app.household-debt-self-evaluation.answer.Have a manageable amount of debt': 'Tengo una cantidad manejable de deuda',
  'app.household-debt-self-evaluation.answer.Have a bit more debt than is manageable': 'Tengo un poco más de deuda de lo que es manejable',
  'app.household-debt-self-evaluation.answer.Have far more debt than is manageable': 'Tengo mucho más deuda de lo que es manejable',
  'app.credit-self-evaluation.question': '¿Cómo calificaría su puntaje de crédito?Su puntuación de crédito es un número que le dice a los prestamistas lo arriesgado o seguro que es como un prestatario.',
  'app.credit-self-evaluation.answer.': 'Seleccione una de las siguientes',
  'app.credit-self-evaluation.answer.Excellent': 'Excelente',
  'app.credit-self-evaluation.answer.Very Good': 'Muy bien',
  'app.credit-self-evaluation.answer.Good': 'Bueno',
  'app.credit-self-evaluation.answer.Fair': 'Justo',
  'app.credit-self-evaluation.answer.Poor':'Pobre',
  'app.credit-self-evaluation.answer.I do not know':'No lo sé',

  'app.additional-questions.covid-questions.describe-your-savings' : '¿Cómo ha afectado Covid 19 a sus ahorros?',
  'app.additional-questions.covid-questions.do-you-agree.i-have-a-plan' : '¿Hasta qué punto está de acuerdo con la siguiente declaración: Tengo un plan para navegar el impacto financiero que el Coronavirus ha tenido en mí.',
  'app.additional-questions.covid-questions.describe-typical-annual-income': '¿Individualmente cuanto gana al año?',
  'app.additional-questions.covid-questions.describe-family-earnings': '¿Cuál de las siguientes describe los ingresos de su familia?',
  'app.additional-questions.covid-questions.describe-current-or-most-recent-industry': '¿Cuál de los siguientes describe la industria de su trabajo actual o más reciente?',
  'app.additional-questions.covid-questions.what-assistance-have-you-received': '¿Qué fuentes de asistencia ha recibido?',

  'app.second-chance-report.title': 'Falta poco para terminar.',
  'app.second-chance-report.continue.title': 'Su perfil financiero: ',
  'app.second-chance-report.percent.complete-text': 'completado',
  'app.second-chance-report.counselor.bubble.text': 'Ya casi termina. Sólo un paso más. Mire su reporte de crédito e identifique el resumen de sus deudas y hágale saber a su consejero financiero las preguntas que tenga.',
  'app.second-chance-report.pull.report.button': 'Quiero mi reporte de crédito',
  'app.second-chance-report.skip.button': 'Vaya a la siguiente sección',
  'app.second-chance-report.skip.credit.report.forever': 'No quiero mi reporte de crédito. Por favor no me muestre este mensaje otra vez.',
  'app.no-employer-code.title': 'TrustPlus',
  'app.no-employer-code.message.english': 'Thank you for accessing TrustPlus. The URL you entered is either missing a code for your employer, or incorrect. Please contact your employer\'s administrator or email {email} to receive the correct link.',
  'app.no-employer-code.message.spanish': 'Gracias por acceder a TrustPlus. La dirección que usted a ingresado no tiene el código para su empleador o es incorrecta. Por favor comuníquese con el administrador de su empleador o envíe un correo electrónico a {email} para recibir el enlace correcto.',
  'app.message.contact.create': 'Al parecer aún no ha tenido una cita con uno de nuestros consejeros financieros. Le recomendamos que haga una cita para discutir sus metas financieras y crear un plan personalizado de acción financiera. Haga clic a {alternativeSchedulerLink} o envíe un correo electrónico a {supportEmail} en caso de tener alguna duda o pregunta.',
  'app.errors.contact.create': 'Una cuenta con esta información ya existe. Si necesita más información llame al {tollfreePhone} o envíe un correo electrónico a {supportEmail}.',
  'app.errors.contact.create.link.external.self-scheduler.display': 'este link',
  'app.errors.contact-exists': 'Ya existe una cuenta con esta información. Por favor {loginLink} para continuar o contáctenos para obtener más ayuda: {tollfreePhone} o {supportEmail}.',
  'app.errors.contact-exists.login' : 'iniciar sesión',
  'app.errors.contact-not-found': 'No reconocemos su número de teléfono. Volver a intentar o consulte su consejero financiero',
  //error displays:
  'app.errors.provide.info': 'Por favor proporcione la siguiente información: ',
  'app.errors.firstName.required': 'Por favor llene su nombre',
  'app.errors.firstName.required.notice': 'Nombre',
  'app.errors.lastName.required': 'Por favor llene su apellido',
  'app.errors.lastName.required.notice': 'Apellidos',
  'app.errors.email.required': 'Por favor llene un correo electrónico válido (nombre@ejemplo.com)',
  'app.errors.email.required.notice': 'Correo Electrónico',
  'app.errors.inquiry.required': 'Porfavor ingrese su pregunta',
  'app.errors.inquiry.required.notice': 'Porfavor ingrese su pregunta',
  'app.errors.email.invalidFormat': 'Por favor llene un correo electrónico válido (nombre@ejemplo.com)',
  'app.errors.email.invalidFormat.notice': 'Correo Electrónico',
  'app.errors.agreedToTerms.required': 'Por favor acepte a continuación',
  'app.errors.agreedToTerms.required.notice': 'Aceptar los términos de servicio & política de privacidad.',
  'app.errors.videoChatService.required': 'Se requiere un servicio de video chat',
  'app.errors.videoChatService.required.notice': 'Por favor seleccione un servicio de video chat',
  //appointment-schedule.jsx
  'app.errors.mobilePhone.required': 'Por favor llene su número de teléfono móvil',
  'app.errors.mobilePhone.required.notice': 'número de teléfono móvil',
  'app.errors.mobilePhone.invalidFormat': 'Formato de número de teléfono móvil no es válido',
  'app.errors.mobilePhone.invalidFormat.notice': 'número de teléfono móvil',
  //credit-report-form.jsx:
  'app.errors.street.required': 'Por favor llene su dirección (calle)',
  'app.errors.street.required.notice': 'su dirección(calle)',
  'app.errors.city.required': 'Por favor llene su ciudad',
  'app.errors.city.required.notice': 'ciudad',
  'app.errors.state.required': 'Por favor llene su estado',
  'app.errors.state.required.notice': 'estado',
  'app.errors.state.invalidFormat': 'Formato de estado no es válido',
  'app.errors.state.invalidFormat.notice': 'estado',
  'app.errors.zipCode.required': 'Por favor llene su código postal',
  'app.errors.zipCode.required.notice': 'código postal',
  'app.errors.zipCode.invalidFormat': 'Formato de código postal no es válido',
  'app.errors.zipCode.invalidFormat.notice': 'código postal',
  'app.errors.birthdate.required': 'Por favor llene su fecha de nacimiento',
  'app.errors.birthdate.required.notice': 'fecha de nacimiento',
  'app.errors.birthdate.invalidFormat': 'Formato de fecha de nacimiento no es válido',
  'app.errors.birthdate.invalidFormat.notice': 'fecha de nacimiento',
  'app.errors.birthdate.notOfAge': ' ',
  'app.errors.birthdate.notOfAge.notice': 'Neighborhood Trust requiere que los usuarios de nuestros servicios tengan una edad mínima de 18 años. Para obtener más detalles, visita nuestros {externalTermsOfSvc}. Si tiene preguntas, por favor escriba un correo electrónico a {mailToSupportAction} o llame al {tollFreePhoneAction}.',
  'app.errors.birthdate.isToOld': 'La fecha de nacimiento ingresada es más de 110 años en el pasado. Por favor revise y corrija el año de nacimiento.',
  'app.errors.birthdate.isToOld.notice': 'La fecha de nacimiento ingresada es más de 110 años en el pasado.',
  'app.errors.ssn.required': 'Por favor llene su número de seguro social o ITIN',
  'app.errors.ssn.required.notice': 'número de seguro social o ITIN',
  'app.errors.ssn.invalidFormat': 'Formato de número de seguro social o ITIN no es válido',
  'app.errors.ssn.invalidFormat.notice': 'número de seguro social o ITIN',
  'app.errors.interestRate' : 'Interest Rate Error',
  'app.errors.interestRate.notice' : 'The interest rate must be a number',
  'app.errors.contact.update': 'No fue posible obtener su reporte de credito. Su consejero le hará seguimiento durante su cita.',

  //client-stories.jsx form
  'app.errors.preferredName.required': 'Por favor, introduzca el nombre preferido',
  'app.errors.preferredName.required.notice': 'Nombre Preferido',
  'app.errors.signature.required': 'Por favor ingrese una firma',
  'app.errors.signature.required.notice': 'Firma',


  'app.learn-more.title': 'Preguntas Frecuentes',
  'app.learn-more.question.1': '¿Qué es TrustPlus?',
  'app.learn-more.answer.1a': 'TrustPlus es un beneficio de bienestar financiero que alivia sus preocupaciones económicas diarias. Somos asesores financieros que lo ayudan a entender las finanzas personales, sin juzgar, brindando apoyo personalizado y bajo demanda y orientación experta.',
  'app.learn-more.answer.1b': 'TrustPlus es un servicio de Neighborhood Trust Financial Partners, una organización sin fines de lucro. Durante más de 20 años, hemos ayudado a más de 60,000 trabajadores estadounidenses a mejorar sus puntajes crediticios, reducir sus deudas y adoptar hábitos financieros más inteligentes. Neighborhood Trust brinda a los trabajadores una variedad de opciones de empoderamiento financiero, integrando nuestras ofertas en entornos y programas en los que la gente confía. Puede obtener más información sobre Neighborhood Trust y sus servicios {neighborhoodtrusthome}.',
  'app.learn-more.question.2': '¿Cómo puede ayudar TrustPlus?',
  'app.learn-more.answer.2': 'TrustPlus puede ayudar a resolver problemas tales como:',
  'app.learn-more.answer.2a': 'Crear crédito para que pueda calificar para las mejores tarjetas de crédito, préstamos o hipotecas',
  'app.learn-more.answer.2b': 'Reducir la deuda problemática desde préstamos de día de pago y cuentas de cobro hasta deudas médicas, préstamos estudiantiles y tarjetas de crédito',
  'app.learn-more.answer.2c': 'Presupuestar y construir un fondo de seguridad para emergencias',
  'app.learn-more.question.3': '¿Cómo funciona el asesoriamiento financiero?',
  'app.learn-more.answer.3': 'El asesoriamento financiero le proporciona un espacio seguro para trabajar hacia sus objetivos financieros. Durante su sesión de asesoramiento, conocerá a su asesor financiero, compartirá su historia, proporcionará algunos detalles más sobre su situación financiera y trabajará junto con su entrenador para construir un plan de acción. También revisará su informe de crédito y puntuación. Su entrenador también puede proporcionar herramientas y apoyo para reducir el estrés sobre sus finanzas o abordar su preguntas financieras. Las sesiones se pueden realizar por teléfono o videochat.',
  'app.learn-more.question.4': '¿La autorización de TrustPlus para sacar mi reporte de crédito perjudicará mi puntaje de crédito?',
  'app.learn-more.answer.4': 'No, haremos un “tirón suave”, que no tiene ningún efecto en su informe de crédito y puntuación.',
  'app.learn-more.question.5': '¿Qué sucede después de reunirme con mi consejero financiero?',
  'app.learn-more.answer.5': 'Con TrustPlus, obtendrá 12 meses de acceso a la ayuda que necesita, sin costo para usted. A medida que trabajas para alcanzar tus metas, tu entrenador te seguirá ofreciendo recursos y orientación para apoyarte, y  tambien puedes ponerte en contacto con ellos en cualquier momento. Conéctese tan a menudo o tan poco como sea necesario a través del teléfono, vídeo, chat o texto.',
  'app.learn-more.question.6': 'Una vez que me inscriba para el asesoriamiento, ¿seguiré teniendo el mismo asesor financiero?',
  'app.learn-more.answer.6': 'Como cliente, tendrá acceso a un equipo de asesores financieros dedicados a ayudar a mejorar su bienestar financiero y el de sus colegas. Si el entrenador que elijas no está disponible cuando lo necesites, nuestro equipo de entrenadores estará allí para apoyarte. Todos ellos están entrenados en nuestros métodos únicos para asegurarte de que puedas alcanzar tus metas. Haremos todo lo posible para respertar sus preferencias, pero la disponibilidad del asesor variará.',
  'app.learn-more.question.7': '¿Qué hace que el asesoramiento financiero de TrustPlus distinto a otros servicios de consejería y asesoría financiera?',
  'app.learn-more.question.7.1-heading': 'Un Servicio Personalizado',
  'app.learn-more.answer.7.1': 'A diferencia de otras agencias de consejería financiera, los consejeros de Trustplus trabajarán con usted individualmente para aprender sobre sus metas financieras y su situación financiera para crear un plan de acción específico y alcanzable para usted. En otras agencias, es posible que le enseñen un montón de temas financieros o le den una lista general de tareas pendientes. Y con otras aplicaciones financieras, su consejo puede provenir de algoritmos e inteligencia artificial. Una combinación de personas y tecnología, TrustPlus refuerza nuestra guía humana con productos y herramientas financieros relevantes y seguros para hacer que nuestras recomendaciones sean reales, más fáciles de adoptar y para ayudarlo a aprovechar al máximo cada cheque de pago que tanto le costó ganar.',
  'app.learn-more.question.7.2-heading': 'Confiable',
  'app.learn-more.answer.7.2': 'A diferencia de muchos asesores financieros, TrustPlus tiene su mejor interés en el corazón. Los consejeros le escucharán y harán recomendaciones basadas en lo que es correcto para usted y su familia. No vendemos productos ni recibimos comisiones. Como organización sin fines de lucro, somos imparciales y estamos completamente motivados por su éxito y los cambios que experimentará en su vida después de trabajar con nuestros asesores.',
  'app.learn-more.question.7.3-heading': 'Tecnología y Conveniencia',
  'app.learn-more.answer.7.3': 'Los consejeros de TrustPlus entienden que está ocupado y tiene tiempo y energía limitado para dedicarse a trabajar en sus finanzas. Por lo tanto, TrustPlus utiliza una tecnología sencilla y fácil de usar que le permitirá hacer todo lo que necesita en un momento y lugar que sea conveniente para usted. Por ejemplo, las sesiones individuales de asesoramiento se realizan a través de Skype y son personalizadas. Los Planes de Acción Financiera se entregan a través de una plataforma interactiva en línea que puede usar las 24 horas del día. Además, para ayudarle a manejar todas sus responsabilidades de la vida diaria, TrustPlus le enviará recordatorios basados en su plan de acción que creó con su asesor financiero.',
  'app.learn-more.question.8': '¿En qué se diferencia TrustPlus de los servicios de reparación de crédito o liquidación de deudas?',
  'app.learn-more.answer.8': 'Las compañías de reparación de crédito cobran más de $100/mes para disputar errores en su informe de crédito. Esto es algo que podrías hacer tú mismo gratis, y nuestros entrenadores te arman con las herramientas necesarias para hacerlo. Las compañías de liquidación de deudas prometen eliminar la carga de su deuda, pero tiene un precio alto ya que su crédito a menudo se daña durante el proceso. Por lo general, cobran una tarifa del 25% de la deuda liquidada. Al igual que con la disputa de errores en su informe de crédito, esto es algo que puede hacer usted mismo de forma gratuita, y nuestros entrenadores están aquí para brindarle orientación y apoyo durante todo el proceso.',
  'app.learn-more.question.9': '¿Es segura mi información personal?',
  'app.learn-more.answer.9': 'Sabemos que la seguridad y la privacidad son importantes para usted, y también son importantes para nosotros. Le damos prioridad a proporcionar una seguridad fuerte y le damos confianza en que su información está segura en todo momento. Utilizamos tecnología de cifrado de extremo a extremo, como el cifrado Secure Sockets Layer (SSL), que ayuda a proteger la información personal en todas las áreas de nuestro sitio web. Su información cuenta con la certificación GoDaddy SSL, una de las mayores garantías de protección de la privacidad en la industria. Y no compartimos su información financiera personal con su empleador o con compañías terceros.',
  'app.learn-more.question.10': '¿Pueden mis familiares y amigos usar el servicio?',
  'app.learn-more.answer.10': 'Los servicios de asesoría financiera de TrustPlus están disponibles como un beneficio de su lugar de trabajo o como membresía en una de nuestras organizaciones asociadas. Su pareja que participa en la toma de decisiones financieras en su hogar puede ser parte de su sesión de coaching de TrustPlus por teléfono o skype. Para otros amigos y familiares, nos complace ayudarlo a encontrar los servicios de asesoría financiera adecuados. Pídale más información a su asesor financiero.',
  'app.learn-more.question.11': '¿Puedo descargar la aplicación TrustPlus en mi teléfono?',
  'app.learn-more.answer.11': 'En este momento no tenemos una aplicación descargable. Para asegurarnos de que se pueda acceder al servicio donde se sienta más cómodo, hemos diseñado una aplicación basada en la web a la que se puede acceder con cualquier teléfono inteligente, computadora o tableta, y con la mayoría de los navegadores.',
  'app.learn-more.question.12': '¿Cuáles son las horas que los entrenadores están disponibles? ',
  'app.learn-more.answer.12a': '9:00am - 9:00pm ET de Lunes a Jueves ',
  'app.learn-more.answer.12b': '9:00am- 5:00pm ET Viernes & Sábado ',
  'app.learn-more.question.13': '¿A quién debo contactar si tengo problemas con mi cuenta?',
  'app.learn-more.answer.13': 'Si tiene problemas o necesita más ayuda, póngase en contacto con nosotros en {supportEmail}.',
  'app.learn-more.question.14': '¿Cómo accederá TrustPlus a mi informe de crédito si mis archivos de crédito están congelados?',
  'app.learn-more.answer.14a': 'Si sus archivos de crédito están congelados, TrustPlus puede que no tenga acceso a su informe crediticio. Un congelamiento de crédito impide que las agencias de informes crediticios divulguen su informe crediticio a posibles acreedores y agencias de asesoramiento que incluyen a TrustPlus.',
  'app.learn-more.answer.14b': 'Sin embargo, si desea revisar su informe crediticio con TrustPlus mientras sus archivos de crédito están congelados, puede levantar temporalmente el congelamiento con las agencias de informes crediticios. Deberá comunicarse con cada oficina de crédito individualmente para solicitar una suspensión temporal del congelamiento, y es posible que le soliciten que proporcione información para verificar su identidad. Una vez que se levante el congelamiento, TrustPlus podrá acceder a su informe crediticio.',
  'app.learn-more.question.15': '¿Qué debo hacer si tengo problemas para iniciar sesión en mi cuenta?',
  'app.learn-more.answer.15a': 'Si tiene problemas para iniciar sesión en su cuenta, aquí hay algunos pasos que puede seguir para intentar resolver el problema:',
  'app.learn-more.answer.15b': '1. Verifique sus credenciales de inicio de sesión: asegúrese de ingresar el nombre de usuario y la contraseña correctos. Verifique si hay errores tipográficos, espacios adicionales o letras en mayúscula que no deberían estar.',
  'app.learn-more.answer.15c': '2. Restablecer su contraseña: si no puede recordar su contraseña, utilice nuestra opción “Contraseña olvidada” que lo guiará en el proceso de restablecimiento.',
  'app.learn-more.answer.15d': '3. Borre la memoria caché y las cookies de su navegador: a veces los problemas de inicio de sesión pueden ser causados por datos antiguos o corruptos almacenados en la memoria caché y las cookies de su navegador. Borrarlos a menudo puede ayudar a resolver el problema.',
  'app.learn-more.answer.15e': '4. Pruebe con un navegador diferente: si borrar la memoria caché y las cookies no funciona, intente usar un navegador diferente para ver si el problema es específico del que estaba usando.',
  'app.learn-more.answer.15f': '5. Contacte al servicio al cliente: si ha intentado todos los pasos anteriores y aún no puede iniciar sesión, contacte a nuestro equipo de soporte al cliente para obtener ayuda adicional en support@mytrustplus.org. Pueden identificar y resolver el problema.',
  'app.learn-more.question.16': '¿Cómo puedo asegurarme de que la llamada de mi entrenador financiero pasará los filtros de spam?',
  'app.learn-more.answer.16a': 'Si estás esperando una llamada de tu entrenador financiero y te preocupa que pueda ser marcada como spam o bloqueada por los filtros de tu teléfono, hay algunas cosas que puedes hacer para asegurarte de que la llamada se realice:',
  'app.learn-more.answer.16b': '1. Agrega el número de teléfono del entrenador a tus contactos: Al agregar el número de teléfono de tu entrenador a tus contactos, le estás diciendo a tu teléfono que este es un número de confianza y que las llamadas de este número deben ser permitidas.',
  'app.learn-more.answer.16c': '2. Revisa la lista de números bloqueados de tu teléfono: asegúrate de que el número de teléfono de tu entrenador no esté en la lista de números bloqueados de tu teléfono. Si es así, elimínelo de la lista para permitir que llegue la llamada.',
  'app.learn-more.answer.16d': '3. Desactive el bloqueo de llamadas de spam: Algunos teléfonos tienen funciones integradas de bloqueo de llamadas de spam que a veces pueden bloquear llamadas legítimas. Compruebe la configuración del teléfono para ver si esta función está activada y desactívela temporalmente si es necesario.',
  'app.learn-more.answer.16e': '4.Comuníquese con su operador telefónico: Si continúa teniendo problemas para recibir llamadas de su entrenador financiero, comuníquese con su operador telefónico para ver si están bloqueando las llamadas. Es posible que puedan agregar el número a una lista blanca para permitir que pase.',
  'app.learn-more.answer.16f': '5. Proporcione un número alternativo: Si todo lo demás falla, considere proporcionar un número alternativo a su asesor financiero, como un número de trabajo o el teléfono de un amigo, para asegurarse de recibir la llamada.',
  'app.learn-more.answer.16g': 'Al seguir estos pasos, puede ayudar a asegurarse de recibir la llamada importante de su asesor financiero y evitar cualquier problema potencial con los filtros de spam o el bloqueo de llamadas.',
  'app.learn-more.question.17': '¿Qué debo hacer si no puedo encontrar un entrenador disponible?',
  'app.learn-more.answer.17': 'Considere usar nuestra función "Pregúntele a un entrenador" o envíe un correo electrónico a nuestro equipo de soporte en support@mytrustplus.org para hablar con alguien que pueda ayudarlo.',
  'app.learn-more.neighborhoodtrusthome':'aquí',
  'app.learn-more.nytimes': 'The New York Times',
  'app.learn-more.vice': 'Vice',
  'app.learn-more.nprplanetmoney': 'NPR Planet Money',
  'app.learn-more.nycgov': 'NYC.gov',

  'app.action-plan-nav.welcome-name': '¡Hola, ',
  'app.action-plan-nav.take-action-today': 'Actúa Hoy',
  'app.action-plan-nav.debt-summary': 'Resumen de la deuda',
  'app.action-plan-nav.fico-score': 'Puntaje de crédito',
  'app.action-plan-nav.budget': 'Presupuesto',
  'app.action-plan-nav.sessions': 'Sesiones',
  'app.action-plan-nav.messages': 'Mensajes',
  'app.action-plan-nav.link-money': 'Vincular cuentas',
  'app.action-plan-nav.link-money-category-chart': 'Link Money Category Chart',
  'app.action-plan-nav.link-money-accounts': 'Link Money Accounts',
  'app.action-plan-nav.link-money-transactions': 'Link Money Transactions',
  'app.action-plan-nav.espanol': 'English',
  'app.action-plan-nav.my-account': 'Mi Cuenta',
  'app.action-plan-nav.sign-out': 'Salir',
  'app.action-plan-nav.terms-of-service': 'Terminos del Servicio',
  'app.action-plan-nav.and': ' y ',
  'app.action-plan-nav.privacy-policy': 'Política de Privacidad',
  'app.action-plan-nav.feedback': 'Observaciones',
  'app.action-plan.questions.save-and-next': 'Guardar y siguiente',

  'app.action-plan-dashboard.my-sessions':'Mis sesiones',
  'app.action-plan-dashboard.button.view-sessions': 'Visitar mis sesiones',
  'app.action-plan-dashboard.settings':'Configuración',
  'app.action-plan-dashboard.my-profile':'Mi perfil',
  'app.action-plan-dashboard.status': 'Estado:',
  'app.action-plan-dashboard.status-incomplete': 'Incompleto',
  'app.action-plan-dashboard.status-complete': 'Completo',
  'app.action-plan-dashboard.status-review-your-responses': 'Completo',
  'app.action-plan-dashboard.button.complete-profile': 'Editar su perfil',
  'app.action-plan-dashboard.button.see-profile': 'Ver mi configuración',
  'app.action-plan-dashboard.button.see-dashboard': 'Ver mi tablero',
  'app.action-plan-dashboard.link-accounts.unlinked':'¡NUEVO! Conecte sus cuentas',
  'app.action-plan-dashboard.link-accounts.description-1.unlinked':'Cuando ',
  'app.action-plan-dashboard.link-accounts.description-link.unlinked': 'conecta tus cuentas',
  'app.action-plan-dashboard.link-accounts.description-2.unlinked': ', su consejero financiero puede brindarle un mejor apoyo en su camino de salir de sus deudas y lograr sus metas.',
  'app.action-plan-dashboard.link-accounts.linked': 'Tus cuentas conectadas',
  'app.action-plan-dashboard.link-accounts.description-1.linked':'¡Gracias por conectar tus cuentas! Para conectar cuentas adicionales visita la sección "',
  'app.action-plan-dashboard.link-accounts.description-link.linked': 'Conectar cuentas.',
  'app.action-plan-dashboard.link-accounts.description-2.linked': '"',
  'app.action-plan-dashboard.link-accounts.button.linked': 'Conecte más cuentas',
  'app.action-plan-dashboard.dcl.title': 'Consolidación de deuda: Próximos Pasos',
  'app.action-plan-dashboard.dcl.description': 'En tu primera consulta con el consejero financiero, revisarás tus respuestas del preevaluador, tu report de crédito; y luego explorarás opciones para un préstamo de consolidación de deudas. A partir de ahí, crearán juntos un plan de acción.',
  'app.action-plan-dashboard.dcl.header-title': 'Tu camino hacia la consolidación de deudas comienza aquí',
  'app.action-plan-dashboard.dcl.header-description': '¡Felicidades por tomar los primeros pasos! ¡Tu consejero financiero espera trabajar con usted!',

  'app.action-plan-take-action-today.page-title': 'Actúa Hoy',
  'app.action-plan-take-action-today.to-do': 'Que Hacer',
  'app.action-plan-take-action-today.achieved': 'Logrado',
  'app.action-plan-take-action-today.no-current-to-do-items': 'No hay elementos actuales de Tareas pendientes.',
  'app.action-plan-take-action-today.no-completed-items': 'No hay elementos completados.',
  'app.action-plan-take-action-today.overview': 'Visión general:',
  'app.action-plan-take-action-today.work-goals': 'Trabajar hacia mis metas',
  'app.action-plan-take-action-today.how-to-achieve': 'Como alcanzar:',
  'app.action-plan-take-action-today.due-date' : 'Fecha de vencimiento: ',
  'app.action-plan-take-action-today.achieved-date' : 'Logrado en: ',
  'app.action-plan-take-action-today.mark-as-achieved' : 'Marcar como logrado',
  'app.action-plan-take-action-today.create' : 'Crea un paso de acción',
  'app.action-plan-take-action-today.create-submit' : 'Terminar de crear',
  'app.action-plan-take-action-today.cancel' : 'Cancel',
  'app.action-plan-take-action-today.create-title': 'Nuevo paso de acción',
  'app.action-plan-take-action-today.input.goal-comment' : 'Tu paso de acción',
  'app.action-plan-take-action-today.input.goal-comment.placeholder': 'Una breve frase para ti sobre lo que debes hacer. Te lo enviaremos como recordatorio.',
  'app.action-plan-take-action-today.input.due-date' : 'Fecha de vencimiento',
  'app.action-plan-take-action-today.input.how-accomplish' : 'Como alcanzar',
  'app.action-plan-take-action-today.input.how-accomplish.placeholder': 'Agregue algunos detalles más sobre cómo planea lograr esta acción. ¿Qué pasos más pequeños darás?',
  'app.action-plan-take-action-today.input.motivation' : 'Motivación (opcional)',
  'app.action-plan-take-action-today.input.motivation.placeholder': '¿Qué te ayudará a lograr esta acción? ¿Cómo afectará esto a su objetivo a largo plazo?',
  'app.errors.tatGoalComment.required' : 'Se requiere un paso de acción',
  'app.errors.tatGoalDueDate.required' : 'Se requiere una fecha de vencimiento',
  'app.errors.tatHowToAccomplish.required' : 'Cómo lograrlo es obligatorio',
  'app.errors.tatGoalDueDate.invalidFormat': 'La fecha de vencimiento es un formato no válido',
  'app.errors.tatGoalDueDate.invalidFormat.notice': 'Fecha de vencimiento',
  'app.errors.tatGoalDueDate.inThePast': 'La fecha de vencimiento debe ser en el futuro',
  'app.errors.tatGoalDueDate.inThePast.notice': 'Fecha de vencimiento',

  'app.take-action-today-tat-goal-purpose.Establish Credit': 'Establecer crédito',
  'app.take-action-today-tat-goal-purpose.Manage My Debt': 'Administrar mi deuda',
  'app.take-action-today-tat-goal-purpose.Organize My Spending': 'Organiza mi gasto',
  'app.take-action-today-tat-goal-purpose.Use Financial Services Wisely': 'Use los servicios financieros con prudencia',
  'app.take-action-today-tat-goal-purpose.Work Towards My Goals': 'Trabajar hacia mis metas',
  'app.take-action-today-tat-goal-purpose.Consider Bankruptcy': 'Considerar bancarrota',
  'app.take-action-today-tat-goal-purpose.Improve My Credit': 'Mejorar mi crédito',
  'app.take-action-today-tat-goal-purpose.Increase My Savings': 'Aumentar mis ahorros',
  'app.take-action-today-tat-goal-purpose.Find Money By Spending Less': 'Encuentre dinero gastando menos',

  'app.take-action-today-tat-goal-action.Apply for a credit builder product':'Solicite un producto para mejorar su credito',
  'app.take-action-today-tat-goal-action.Apply for an ITIN':'Solicite un número ITIN',
  'app.take-action-today-tat-goal-action.Apply for a Self Lender account':'Solicite una cuenta en Self Lender',
  'app.take-action-today-tat-goal-action.Apply for the Trust Card':'Apply for the Trust Card',
  'app.take-action-today-tat-goal-action.Close high-cost account/financial service':'Cerrar cuenta financiera de alto gastos de servicio',
  'app.take-action-today-tat-goal-action.Collect necessary documentation':'Collectar documentos necesarios',
  'app.take-action-today-tat-goal-action.Complete my budget':'Completar mi prosupuesto',
  'app.take-action-today-tat-goal-action.Contact bankruptcy professional':'Contacte al especialista de la Bancarrota',
  'app.take-action-today-tat-goal-action.Contact creditor or service provider to gather more information':'Contacte al acreedor para más información',
  'app.take-action-today-tat-goal-action.Contact creditor to gather more information':'Contacte al acreedor para más información',
  'app.take-action-today-tat-goal-action.Contact creditor to negotiate better terms on my debt':'Contacte al acreedor para negociar mejores terminos para mi deuda',
  'app.take-action-today-tat-goal-action.Cut back on specific expenses':'Reducir gastos específicos',
  'app.take-action-today-tat-goal-action.Enroll in 401k/403b/ IRA/ retirement savings':'Inscríbase en 401k / 403b / IRA / ahorros para la jubilación',
  'app.take-action-today-tat-goal-action.Enroll in a debt management plan':'Inscribase en un programa de manejo de deuda',
  'app.take-action-today-tat-goal-action.Enroll in Direct Deposit':'Inscribase en deposito directo',
  'app.take-action-today-tat-goal-action.Evaluate bankruptcy as an option for you':'Evaluar la bancarrota como una opción para usted',
  'app.take-action-today-tat-goal-action.Follow-up with my financial advisor':'Seguimiento con mi asesor financiero',
  'app.take-action-today-tat-goal-action.Gather information or materials to satisfy service provider or creditor':'Reunir información o materiales para satisfacer al proveedor de servicios o al acreedor',
  'app.take-action-today-tat-goal-action.Gather my bills and statements':'Reunir mis facturas y estado de cuentas',
  'app.take-action-today-tat-goal-action.Learn more about my goal':'Aprender más sobre mi meta',
  'app.take-action-today-tat-goal-action.Mail letters':'Enviar cartas',
  'app.take-action-today-tat-goal-action.Make a $ payment to a specific debt':'Hacer un pago de $ a una deuda específica',
  'app.take-action-today-tat-goal-action.Make changes to the way I pay bills':'Hacer cambios en la forma en que pago las facturas',
  'app.take-action-today-tat-goal-action.Meet with a specialist':'Reúnase con un especialista',
  'app.take-action-today-tat-goal-action.Meet with my financial advisor':'Reúnase con mi asesor/a financiero/a',
  'app.take-action-today-tat-goal-action.Negotiate better terms on my debt':'Negociar mejores términos sobre mi deuda',
  'app.take-action-today-tat-goal-action.Open a 529 College Savings Account':'Abra una cuenta 529 de ahorros para la educación',
  'app.take-action-today-tat-goal-action.Open an account with a trusted financial institution':'Abra una cuenta con una institución financiera confiable',
  'app.take-action-today-tat-goal-action.Open a savings account or product':'Abrir una cuenta de ahorro ',
  'app.take-action-today-tat-goal-action.Other':'Otro',
  'app.take-action-today-tat-goal-action.Put away $ on a regular basis':'Guardar $ regularmente',
  'app.take-action-today-tat-goal-action.Refinance my debt - consolidation loan, balance transfer card, etc.':'Refinanciar mi deuda: préstamo de consolidación, tarjeta de transferencia de saldo, etc.',
  'app.take-action-today-tat-goal-action.Register for a class or seminar':'Registrarse para una clase o seminario',
  'app.take-action-today-tat-goal-action.Request additional reports (i.e. ACR)':'Solicitar reportes addicionales (i.e. ACR)',
  'app.take-action-today-tat-goal-action.Save $ in order to negotiate a settlement':'Ahorrar $ para negociar un acuerdo',
  'app.take-action-today-tat-goal-action.Save for associated costs':'Ahorrar para costos associados',
  'app.take-action-today-tat-goal-action.Save up $ for deposit on a secured loan or card':'Ahorre $ para el depósito de un préstamo o tarjeta asegurado',
  'app.take-action-today-tat-goal-action.Seek additional assistance from a specialist':'Busque asistencia adicional de un especialista',
  'app.take-action-today-tat-goal-action.Separate my business and personal expenses':'Separar gastos personales y gastos de mi negocio',
  'app.take-action-today-tat-goal-action.Set up auto bill-pay':'Configurar el pago automático de facturas',
  'app.take-action-today-tat-goal-action.Set up automatic transfer of funds':'Configurar transferencia automática de fondos',
  'app.take-action-today-tat-goal-action.Sign up for automatic transfer of funds':'Regístrarse para la transferencia automática de fondos',
  'app.take-action-today-tat-goal-action.Sign up for FREE credit monitoring service (credit karma, etc.)':'Registrarse para monitoreo de crédito GRATUITO',
  'app.take-action-today-tat-goal-action.Sign up for online banking':'Registrarse para banca en línea',
  'app.take-action-today-tat-goal-action.Split my direct deposit':'Dividir mi depósito directo',
  'app.take-action-today-tat-goal-action.Take advantage of free or low-cost services':'Aprovechar los servicios gratuitos o de bajo costo.',
  'app.take-action-today-tat-goal-action.Track my spending':'Monitorear mis gastos',
  'app.take-action-today-tat-goal-action.Use in-network ATMs to reduce fees':'Usar cajeros automáticos dentro de la red para reducir las tarifas',

  'app.action-plan-debt-summary.page-title': 'Resumen de la deuda',
  'app.action-plan-debt-summary.subtitle': 'Deuda total en su reporte de credito ordenado en ',
  'app.action-plan.debt-summary.trade-account-counselor-text': 'Podemos ayudarle a entender lo que estos detalles significan, y juntos haremos un plan para enfrentar sus deudas.',
  'app.action-plan-debt-summary.waiting-subtitle': '¡Denos tiempo!',
  'app.action-plan-debt-summary.waiting-text': 'Si nos dio permiso para acceder a su crédito, en la mayoria de los casos tomará 1 o 2 minutos para que su información aparezca aquí',
  'app.action-plan-debt-summary.permission-subtitle': '¡Te lo estás perdiendo!',
  'app.action-plan-debt-summary.permission-text': 'No podemos solicitar su resumen de deuda ni mostrar su puntaje FICO® sin obtener permiso para obtener su reporte de crédito.',
  'app.action-plan-debt-summary.permission-text-transunion': 'No podemos solicitar su resumen de deuda sin obtener permiso para obtener su reporte de crédito.',
  'app.action-plan-debt-summary.permission-button': 'Denos permiso',
  'app.action-plan-debt-summary.permission-text-2': 'Tenga en cuenta que pueden transcurrir hasta 48 horas después de darnos permiso para que su información aparezca.',
  'app.action-plan-debt-summary.key': 'GUIA',
  'app.action-plan-debt-summary.category': 'CATEGORIA',
  'app.action-plan-debt-summary.balance': 'BALANCE',
  'app.action-plan-debt-summary.monthly-payment': 'PAGO MÍNIMO',
  'app.action-plan-debt-summary.detail.back-to-overview': 'Ver Página Ante',
  'app.action-plan-debt-summary.detail.link': 'Detalles',
  'app.action-plan-debt-summary.more.link': 'Más',
  'app.action-plan-debt-summary.less.link': 'Menos',
  'app.action-plan-debt-summary.detail.type-of-account': 'Tipo de Cuenta',
  'app.action-plan-debt-summary.detail.status-of-account': 'Estado de la cuenta',
  'app.action-plan-debt-summary.detail.balance': 'Saldo',
  'app.action-plan-debt-summary.detail.credit-limit': 'Límite de Crédito',
  'app.action-plan-debt-summary.detail.min-payment': 'Pago Mínimo',
  'app.action-plan-debt-summary.detail.open-date': 'Fecha de abrir',
  'app.action-plan-debt-summary.detail.last-activity-date': 'Fecha de último movimiento',
  'app.action-plan-debt-summary.detail.original-creditor': 'Acreedor original',
  'app.action-plan-debt-summary.detail.account-ownership-type': 'Propietario de la cuenta',
  'app.action-plan-debt-summary.detail.account-interest-rate' : 'Tasa de interés',
  'app.action-plan-debt-summary.detail.account-interest-rate-message' : 'Las tasas de interés no están en los informes de crédito. ¡Podemos aconsejarle mejor cómo pagar deudas con él!',
  'app.action-plan-debt-summary.detail.Automobile': 'Carro',
  'app.action-plan-debt-summary.detail.Collection': 'Colección',
  'app.action-plan-debt-summary.detail.CreditCard': 'Tarjeta de Crédito',
  'app.action-plan-debt-summary.detail.Educational': 'Educativas',
  'app.action-plan-debt-summary.detail.InstallmentLoan': 'Préstamo Fraccionado',
  'app.action-plan-debt-summary.detail.Other' : 'Otro',
  'app.action-plan-debt-summary.detail.Lease': 'Arrendamiento',
  'app.action-plan-debt-summary.detail.Mortgage': 'Hipoteca',
  'app.action-plan-debt-summary.detail.UnknownLoanType': 'Cuenta Desconocida',
  'app.action-plan-debt-summary.detail.AsAgreed': 'De Acuerdo',
  'app.action-plan-debt-summary.detail.ChargeOff': 'Descargado',
  'app.action-plan-debt-summary.detail.Late30Days': 'Atrasado 30 Dias',
  'app.action-plan-debt-summary.detail.Late60Days': 'Atrasado 60 Dias',
  'app.action-plan-debt-summary.detail.Late90Days': 'Atrasado 90 Dias',
  'app.action-plan-debt-summary.detail.LateOver120Days': 'Atrasado 120 Dias',
  'app.action-plan-debt-summary.detail.BankruptcyOrWageEarnerPlan': 'Bancarrota',
  'app.action-plan-debt-summary.detail.Repossession': 'Reposesión',
  'app.action-plan-debt-summary.detail.NoDataAvailable': 'Datos no Disponible',
  'app.action-plan-debt-summary.detail.Paid': 'PAGADA',
  'app.action-plan-debt-summary.detail.Closed': 'CERRADA',
  'app.action-plan-debt-summary.detail.Open': 'ABIERTA',
  'app.action-plan-debt-summary.detail.Frozen': 'CONGELADA',
  'app.action-plan-debt-summary.detail.Refinanced': 'REFINANCIADA',
  'app.action-plan-debt-summary.detail.Transferred': 'TRANSFERIDA',
  'app.action-plan-debt-summary.detail.Individual': 'Individual',
  'app.action-plan-debt-summary.detail.AuthorizedUser': 'Usuario autorizado',
  'app.action-plan-debt-summary.detail.JointContractualLiability': 'Cosignatario',
  'app.action-plan-debt-summary.Paid': 'PAGADA',
  'app.action-plan-debt-summary.Closed': 'CERRADA',
  'app.action-plan-debt-summary.counselor.unfamiliar-advice': 'Si no reconoce algo aquí, podemos ayudar a investigar la cuenta y a clarificar cómo enfrentarlo.',
  'app.action-plan-debt-summary.counselor.no-score': 'No tener un puntaje no significa que tenga un mal crédito. Es posible que sea nuevo en los EE. UU., Que sea joven o simplemente nuevo en general, y eso está bien. Pero sin un historial de crédito, podría ser un desafío obtener una tarjeta de crédito, un préstamo y, en algunos casos, incluso un apartamento o un contrato telefónico. Puede tomar algún tiempo construir su puntaje de crédito, pero con un uso responsable y consistente del crédito, podrá construir su puntaje. Podemos ayudarlo a comprender las mejores opciones que lo ayudarán a establecer un crédito.',
  'app.action-plan-debt-summary.no-data': 'Nosotros no vemos tarjeta de credito ni prestamos en su reporte.',
  'app.action-plan-debt-summary.detail.information-correct': '¿La información anterior parece correcta?',
  'app.action-plan-debt-summary.detail.information-correct-no': 'No',
  'app.action-plan-debt-summary.detail.information-correct-yes': 'Si',
  'app.action-plan-debt-summary.detail.confirmed-account': '¡Gracias por confirmar esta información!',
  'app.action-plan-debt-summary.detail.undo-confirmation-link' : 'Undo confirmation',
  'app.action-plan-debt-summary.detail.flagged-account' : 'Has marcado esta cuenta porque algo era incorrecto.',
  'app.action-plan-debt-summary.detail.remove-flag-link' : 'Remove flag',
  'app.action-plan-debt-summary.detail.balance-exceeds-limit': 'Balance exceeds limit',

  'app.action-plan-fico-score.page-title': 'Puntaje de crédito',
  'app.action-plan-fico-score.transunion1': 'Su FICO® Score 4 basado en datos de TransUnion® es el mismo puntaje que TrustPlus obtuvo ',
  'app.action-plan-fico-score.transunion2': ' y se proporciona para su propio beneficio y revisión personal no para uso comercial.',
  'app.action-plan-fico-score.keep-in-mind': 'Tenga en cuenta: ',
  'app.action-plan-fico-score.credit-education-link': 'Haga clic para más educación crediticia.',
  'app.action-plan-fico-score.desc-kim-title': 'Factores clave que afectan su FICO® Score',
  'app.action-plan-fico-score.desc-insufficient-title': 'El reporte de crédito no contiene información de crédito suficiente',
  'app.action-plan-fico-score.desc-insufficient-text': 'Desafortunadamente, no tenemos un puntaje FICO® para mostrar en este momento porque su reporte no contiene la información mínima necesaria. Su reporte de crédito debe contener información de al menos una cuenta que haya estado abierta durante un período de 6 meses',
  'app.action-plan-fico-score.disclaimer-title': 'Descargo de responsabilidad de FICO® Score: ',
  'app.action-plan-fico-score.disclaimer1': 'El FICO® Score que proporcionamos puede ser diferente de otros puntajes de crédito. Los FICO® Scores y el contenido educativo están destinados solamente para fines de revisión educativa personal. Los prestamistas pueden usar varias fuentes de información, como un FICO® Score, otros puntajes de crédito y otro tipo de información, en las decisiones sobre créditos. El archivo de crédito usado para crear su FICO® Score se actualiza continuamente y es posible que su FICO® Score anterior no refleje los datos más actuales de su archivo de crédito. Para solicitar una copia de su informe de crédito, visite: ',
  'app.action-plan-fico-score.disclaimer2': 'http://www.annualcreditreport.com',
  'app.action-plan-fico-score.disclaimer3': 'Fair Isaac y la persona u organización que proporciona este informe no son organizaciones de “reparación de crédito” tal y como se define en la ley federal o estatal, incluida la Credit Repair Organizations Act (CROA, Ley de Organizaciones de Reparación de Crédito).',
  'app.action-plan-fico-score.disclaimer4': 'FICO® y “The score lenders use” son marcas comerciales registradas de Fair Isaac Corporation en Estados Unidos y en otros países.',
  'app.action-plan-fico-score.disclaimer5': 'Fair Isaac no garantiza la exactitud de la información de entrada por parte del asesor.',

  'app.action-plan-sessions.page-title': 'Mis sesiones',
  'app.action-plan-sessions.upcoming': 'Próximas',
  'app.action-plan-sessions.previous': 'Citas previas',
  'app.action-plan-sessions.no-upcoming': 'Sin cita programada',
  'app.action-plan-sessions.no-previous': 'Sin cita previa',
  'app.action-plan-sessions.on': ' en ',
  'app.action-plan-sessions.selected-session': 'Su sesión seleccionada.',
  'app.action-plan-sessions.my-session': 'Me gustaria tener mi sesion',
  'app.action-plan-nav.button.schedule': 'programe su cita ahora',
  'app.action-plan-sessions.no-appointments.description' : 'No hay horarios disponibles en este momento. Por favor, vuelva en breve.',
  'app.action-plan-nav.button.go-back': '< Regresa',

  'app.action-plan-messages.page-title': 'Mensajes',
  'app.action-plan-messaging.new': 'New',
  'app.action-plan-messaging.compose': 'Compose New Message',

  'app.action-plan-budget.page-title': 'Mi Presupuesto',
  'app.action-plan-budget.button.save': 'Guardar',
  'app.action-plan-budget.button.saved-message': 'Salvado!',
  'app.action-plan-budget.button.show-details': 'Mostrar detalles',
  'app.action-plan-budget.button.hide-details': 'Esconder detalles',
  'app.action-plan-budget.button.show-fewer': 'Mostrar menos fuentes',
  'app.action-plan-budget.button.show-more': 'Mostrar más fuentes',
  'app.action-plan-budget.income.title': 'Ingresos Mensuales',
  'app.action-plan-budget.income.total': 'TOTAL (PROMEDIO)',
  'app.action-plan-budget.income.remaining': 'RESANTE: ',
  'app.action-plan-budget.income.over-budget': 'SOBRE PRESUPUESTO: ',
  'app.action-plan-budget.income.my-pay': 'Mi Salario',
  'app.action-plan-budget.income.my-side-gig': 'Mi Ingreso Adicional',
  'app.action-plan-budget.income.my-partner-pay': 'Pago de Mi Pareja',
  'app.action-plan-budget.income.my-partner-side-gig': 'Ingreso Extra de Mi Pareja',
  'app.action-plan-budget.income.my-business': 'Mi Negocio',
  'app.action-plan-budget.income.unemployment': 'Desempleo',
  'app.action-plan-budget.income.snap': 'SNAP / Cupones de Alimentos',
  'app.action-plan-budget.income.ssr': 'Retiro (Seguro Social, Pensión, etc.)',
  'app.action-plan-budget.income.child-support': 'Manutención de Menor',
  'app.action-plan-budget.income.rental-income': 'Ingreso por Alquileres',
  'app.action-plan-budget.income.help-fam-friends': 'De Otros Amigos / Familiares',
  'app.action-plan-budget.income.wic': 'WIC',
  'app.action-plan-budget.income.disability': 'SSI / SSD',
  'app.action-plan-budget.income.pa-tanf': 'Asistencia Pública / TANF',
  'app.action-plan-budget.income.other': 'Otro',
  'app.action-plan-budget.expense.title': 'Gastos Mensuales',
  'app.action-plan-budget.expense.home': 'Vivienda',
  'app.action-plan-budget.expense.food': 'Comida',
  'app.action-plan-budget.expense.utilities': 'Servicios Públicos',
  'app.action-plan-budget.expense.transportation': 'Transportación',
  'app.action-plan-budget.expense.entertainment': 'Entretenimiento',
  'app.action-plan-budget.expense.education-child-care': 'Educación y Cuidado de Niños',
  'app.action-plan-budget.expense.personal-care': 'Cuidado Personal',
  'app.action-plan-budget.expense.other': 'Otro',
  'app.action-plan-budget.counselor.advice': 'Al completar un presupuesto, podemos comprender mejor el flujo de dinero que ingresa y sale cada mes. Luego, podemos trabajar juntos para ver si hay algo que pueda aportar para reducir la deuda o incrementar el ahorro para sus metas.',
  'app.action-plan-budget.warning.wait-a-second': '¡Espere un segundo!',
  'app.action-plan-budget.warning.notice-changes': '¡Noté que hizo algunos cambios en su presupuesto sin guardarlos!',
  'app.action-plan-budget.warning.dont-save': 'No guardar',
  'app.action-plan-budget.warning.save': 'Guardar',

  'app.action-plan-account.page-title': 'Mi Cuenta',
  'app.action-plan-account.change-phone.page-title': 'Número de teléfono móvil',
  'app.action-plan-account.change-phone.instruction1': 'Enter new cell phone number',
  'app.action-plan-account.change-email.page-title': 'Correo electrónico',
  'app.action-plan-account.change-email.instruction1': 'Enter new email. This will change your login.',
  'app.action-plan-account.change-password.page-title': 'Contraseña',
  'app.action-plan-account.change-password.step-one': 'Paso uno: ',
  'app.action-plan-account.change-password.instruction1': 'Introduzca su contraseña actual.',
  'app.action-plan-account.change-password.step-two': 'Paso dos: ',
  'app.action-plan-account.change-password.instruction2': 'Crear una nueva contraseña. Su nueva contraseña debe contener: ',
  'app.action-plan-account.change-password.step-three': 'Paso tres: ',
  'app.action-plan-account.change-password.reenter-new-password': 'Re-ingrese nueva contraseña',
  'app.action-plan-account.change-password.confirmation': '¡Su contraseña ha sido cambiada!',

  'app.action-plan-feedback.page-title': 'Observaciones',
  'app.action-plan-feedback.feedback-prompt-1': 'Agradecemos sus comentarios! ¿Algo te dio problemas? ¿Está buscando una nueva función? Déjenos saber a continuación. Sus comentarios serán enviados a nuestro equipo técnico y experiencia del cliente.',
  'app.action-plan-feedback.feedback-prompt-2': '¿ Tiene un mensaje importante para su asesor? Mande un mensaje de texto al ',
  'app.action-plan-feedback.feedback-prompt-3':' para ponerse en contacto más rápidamente.',
  'app.action-plan-feedback.thank-you': 'Gracias por enviar sus comentarios.',
  'app.action-plan-feedback.comments.required': 'Si quiere enviar sus comentarios, por favor entre sus comentarios arriba.',
  'app.action-plan-feedback.comments.required.notice': 'Comentarios',
  'app.action-plan-feedback.comments': 'Comentarios',

  'app.action-plan-link-money.title': 'Vincular cuentas',
  'app.action-plan-link-money.welcome': 'Da el siguiente paso en tu viaje',
  'app.action-plan-link-money.welcome.description-1': 'Cuantos más datos tengamos, mejor podremos apoyarte en tu viaje para salir de la deuda y lograr tus sueños.',
  'app.action-plan-link-money.welcome.description-2': 'Bríndenos acceso confidencial y seguro a los datos de su cuenta bancaria para que podamos hacer nuestro mejor trabajo.',
  'app.action-plan-link-money.welcome.description-3': 'Su privacidad y seguridad de datos son importantes para nosotros.',
  'app.action-plan-link-money.welcome.description-4': 'Toda su información está encriptada de forma segura.',
  'app.action-plan-link-money.button.link-accounts': 'Vincular tus cuentas',
  'app.action-plan-link-money.button.cancel': 'Recordarme más tarde',
  'app.action-plan-link-money.instructions': 'Vincula tus cuentas para un análisis más rápido y profundo de tus deudas',
  'app.action-plan-link-money-confirmation.header': '¡Felicidades!',
  'app.action-plan-link-money-confirmation.description': 'Su cuenta ha sido verificada y vinculada exitosamente.',
  'app.action-plan-link-money-confirmation.button': 'Vincular otra cuenta',

  'app.action-plan-link-money-modal.title': '¡Nueva caracteristica!',
  'app.action-plan-link-money-modal.link': 'Conecta tus cuentas',
  'app.action-plan-link-money-modal.description': ' para aprovechar al máximo tu sesión de asesoramiento financiero.',

  //Client Stories

  'app.client-stories.heading': 'Comparte su historia',
  'app.client-stories.description-1': '¿Le ha ayudado TrustPlus en un momento de necesidad? ¿Tuvo problemas para llenar sus impuestos este año o para recibir su reembolso completo? ¿Hay algo fuera de su control que se interpuso en el camino para lograr sus metas financieras? ¿O simplemente quiere decir "gracias" a su entrenador financiero?',
  'app.client-stories.description-2': '¡Nos encantaría saber de usted, ya sea unas pocas frases o solo unas pocas palabras! Las respuestas se pueden usar en materiales promocionales junto con el nombre preferido que proporciona a continuación, y uno de los miembros de nuestro equipo puede comunicarse con usted para conocer más detalles sobre su experiencia.',
  'app.client-stories.thanks-heading': 'Gracias',
  'app.client-stories.thanks-body-1': 'Gracias por compartir su historia! Estamos orgullosos de nuestros clientes y el trabajo que hemos hecho con ustedes.  Apreciamos su voluntad a compartir!',
  'app.client-stories.thanks-body-2': 'Un miembro de nuestro equipo se pondrá en contacto con usted en la próximas semanas para aprender más sobre su historia.',
  'app.client-stories.form-heading': 'Su historia',
  'app.client-stories.input.upload-heading': 'Al subir su vídeo o foto usted acepta que puede ser accesible al público a través de nuestra pagina web, redes sociales y los medios de comunicación o compartido con empresas de desarrollo y socios, incluyendo agencias de la ciudad y organizaciones sin fines de lucro.',
  'app.client-stories.input.upload-text': 'Subir Video o Foto (opcional)',
  'app.client-stories.input.preferred-name': 'Nombre Preferido (puede ser un seudónimo)',
  'app.client-stories.input.phone': 'Numero celular (en caso de que necesitemos comunicarse)',
  'app.client-stories.input.comment': 'Comentario',
  'app.client-stories.disclosure': 'Al ingresar mi nombre legal completo a continuación como firma electrónica, confirmo que tengo al menos 18 años de edad y que soy competente para contratar en mi propio nombre. He revisado este formulario antes de firmarlo a continuación y he revisado completamente el contenido, el significado y el impacto.',
  'app.client-stories.input.signature': 'Su nombre legal completo/Firma electrónica',
  'app.client-stories.submit.button': 'Enviar su historia',

  //  Standalone CR
  'app.standalone-credit-report.title': 'Inicie sesión para ver su reporte de crédito',
  'app.standalone-credit-report.text': 'Gracias. Su asesor hará un seguimiento con usted sobre los artículos de su reporte de crédito. Si desea ver lo que hay en su reporte de crédito, por favor inicie sesión! Comience por ingresando su correo electrónico.',

  // Emoji Tooltips - TrustPlus Credit, Emergency Response, Opportunity Fund
  'app.emoji-tooltip.shocked': 'Alarmado',
  'app.emoji-tooltip.sad': 'Triste',
  'app.emoji-tooltip.overwhelmed': 'Abrumado',
  'app.emoji-tooltip.neutral': 'Neutral',
  'app.emoji-tooltip.fine': 'Bien',
  'app.emoji-tooltip.stressed': 'Stressed',
  'app.emoji-tooltip.anxious': 'Anxious',
  'app.emoji-tooltip.panicked': 'Panicked',
  'app.emoji-tooltip.embarrassed':'Embarrassed',
  'app.emoji-tooltip.nervous': 'Nervous',
  'app.emoji-tooltip.worried': 'Worried',
  'app.emoji-tooltip.surprised': 'Surprised',
  'app.emoji-tooltip.scared': 'Scared',

  //Mental Effort Managing Finances - COVID flows and Additional Questions - Shared
  'app.managing-finances.Very very low': 'Muy muy bajo',
  'app.managing-finances.Very low' : 'Muy bajo',
  'app.managing-finances.Low': 'Bajo',
  'app.managing-finances.Rather low': 'Bastante bajo',
  'app.managing-finances.Neither low nor high': 'Ni bajo ni alto',
  'app.managing-finances.Rather high': 'Bastante alto',
  'app.managing-finances.High': 'Alto',
  'app.managing-finances.Very high': 'Muy alto',
  'app.managing-finances.Very very high': 'Muy muy alto',

  //  Emergency Response
  'app.emergency-response.saverlife-emergency-response-fund.title': 'Fondo de Respuesta de Emergencia de SaverLife',
  'app.emergency-response.hablas-espanol.link': 'Speak English?',
  'app.emergency-response.saver-life-trust-plus-are-here.text': 'SaverLife y TrustPlus están aquí para apoyarlo durante esta crisis.',
  'app.emergency-response.who-we-are.title': '¿Quienes somos?',
  'app.emergency-response.trust-plus-who-we-are.text': 'TrustPlus™ un servicio de Neighborhood Trust es una empresa social sin fines de lucro y es el proveedor líder nacional de servicios de educación y consejería financiera efectiva y de alta calidad para los trabajadores. Durante más de 20 años, Neighborhood Trust ha ayudado a más de 60,000 trabajadores estadounidenses a mejorar sus puntajes de crédito, reducir su deuda y adoptar hábitos financieros más inteligentes.',
  'app.emergency-response.saver-life-who-we-are.text': 'SaverLife es una organización sin fines de lucro con la misión de inspirar, informar y recompensar a los millones de estadounidenses que necesitan ayuda para ahorrar dinero, a través de socios y tecnología.',
  'app.emergency-response.how-we-can-help.title': 'Cómo podemos ayudar?',
  'app.emergency-response.how-we-can-help.text': 'Queremos apoyarlo mediante un pago de ayuda de $500, junto con orientación financiera para las próximas semanas y meses.  Además, tiene la oportunidad de trabajar individualmente con un asesor financiero de TrustPlus.',
  'app.emergency-response.we-can-help-you.list-header': 'Podemos ayudarte a:',
  'app.emergency-response.we-can-help-you.list.understand-benefits': 'Comprender mejor los beneficios disponibles y otros programas de ayuda',
  'app.emergency-response.we-can-help-you.list.help-negotiate': 'Asistencia en la negociación con acreedores',
  'app.emergency-response.we-can-help-you.list.apply-for-forbearance': 'Apoyo en la aplicación a programas que permiten posponer pagos',
  'app.emergency-response.we-can-help-you.list.and-more': 'Y mas',
  'app.emergency-response.we-know-you-have-questions.text': 'Sabemos que tiene preguntas y que se enfrenta a un montón de decisiones abrumadoras. No tienes que hacerlo solo. Estamos aquí para usted: por teléfono, Skype o mensaje de texto.',
  'app.emergency-response.after-reviewing-your-finances.text': 'Después de revisar sus finanzas con TrustPlus, será conectado al sitio web de SaverLife Relief Fund, donde puede elegir el método para recibir su pago.',
  'app.emergency-response.no-responses-impact-payment.text': 'Ninguna de sus respuestas a ninguna de estas preguntas tendrá un impacto sobre sí recibe un pago.',
  'app.emergency-response.in-the-press.title': 'En la prensa',
  'app.emergency-response.in-the-press.text-1':'Para leer más sobre la asociación de Neighborhood Trust con SaverLife:',
  'app.emergency-response.in-the-press.text-2': 'CNN',
  'app.emergency-response.in-the-press.text-3': ': “Andrew Yang\'s non-profit to spend more than $1 million to aid working families impacted by coronavirus”',
  'app.emergency-response.in-the-press.text-4': 'CRS Wire',
  'app.emergency-response.in-the-press.text-5': ': “Wells Fargo Invests $1M in SaverLife and Neighborhood Trust Financial Partners to Disperse Financial Aid to Small Businesses Affected by COVID-19”',
  'app.emergency-response.in-the-press.text-6': 'Si tiene alguna pregunta sobre este programa o formulario, envíe un correo electrónico a',
  'app.emergency-response.in-the-press.text-7': 'covidsupport@mytrustplus.org',
  'app.emergency-response.in-the-press.text-8': ' o llame al ',
  'app.emergency-response.in-the-press.text-9': '(929) 335-3189',
  'app.emergency-response.tell-us-about-you.title': 'Empezar',
  'app.emergency-response.why-we-need-this-information.title': '*Por qué necesitamos esta información:',
  'app.emergency-response.why-we-need-this-information.text': ' esta oferta solo es elegible para empresas y empleados preseleccionados específicamente. Utilizaremos su información de contacto para confirmar su participación. También podemos comunicarnos con usted sobre su participación y brindarle más apoyo.',
  'app.emergency-response.registration.mobile-phone': 'Número Celular',
  'app.emergency-response.number-of-characters-left' : '{numOfCharacters} caracteres restantes.',
  'app.emergency-response.saver-life-trust-plus-agree-terms': 'Acepto que mi participación, incluido mi nombre, correo electrónico, dirección y teléfono, se compartirá con SaverLife y TrustPlus con el fin de confirmar su identidad y participación en este proyecto. Su información de contacto no se utilizará para ningún otro propósito que no sea el de apoyarlo con sus finanzas, si así lo solicita.',
  'app.emergency-response.footer.col-1-header': 'Contacto',
  'app.emergency-response.footer.col-2-header': 'Conéctese',
  'app.emergency-response.footer.copyright-message': 'TrustPlus | Derechos reservados.',

  //Emergency Response Errors
  'app.errors.emergency-response.mobilePhone.required': 'Please enter your mobile number',
  'app.errors.emergency-response.mobilePhone.required.notice': 'Número de teléfono móvil',
  'app.errors.emergency-response.contact-already-exists': 'Una cuenta con esta información ya existe. Si necesita más información llame al {tollfreePhone} o envíe un correo electrónico a {supportEmail}.',
  'app.errors.emergency-response.lastPaycheckAmount.invalidNumber' : 'Ingrese un número válido, o ingrese una respuesta sin el signo de dólar ("$"). Las comas (",") y los decimales (".") están bien.',

  //Emergency Response - Questions One
  'app.emergency-response.tell-us-more-about-your-finances.title': 'Como le ha afectado el Coronavirus?',
  'app.emergency-response.but-first-how-are-you.text': '¿Cómo te va? Como consejeros financieros, además de preocupamos por las finanzas de nuestros clientes, también nos interesamos por tu bienestar en general, como tu su salud física y mental. Sabemos que todos están conectados.',
  'app.emergency-questions.emoji-select.how-are-you-feeling' : 'Seleccione un emoticon',
  'app.emergency-questions.do-you-agree.i-have-a-plan': '¿Hasta qué punto está de acuerdo con la siguiente declaración: Tengo un plan para navegar el impacto financiero que el Coronavirus ha tenido en mí.',
  'app.emergency-responses.common-dropdown-select.answer.':'Seleccione una de las siguientes',
  'app.emergency-responses.i-have-a-plan.answer.Disagree strongly':'Totalmente en desacuerdo',
  'app.emergency-responses.i-have-a-plan.answer.Disagree somewhat':'Un poco en desacuerdo',
  'app.emergency-responses.i-have-a-plan.answer.Neither agree nor disagree':'Ni de acuerdo ni de desacuerdo',
  'app.emergency-responses.i-have-a-plan.answer.Agree somewhat':'Un poco de acuerdo',
  'app.emergency-responses.i-have-a-plan.answer.Agree strongly':'Totalmente de acuerdo',
  'app.emergency-questions.describe-current-situation': '¿Cuál de las siguientes opciones describe mejor su situación actual?',
  'app.emergency-responses.current-situation.answer.earnings-not-interrupted' : 'Mis ingresos y los de mi familia no han sido interrumpidas por la crisis del coronavirus',
  'app.emergency-responses.current-situation.answer.earnings-slightly-less': 'Los ingresos de mi familia son ligeramente menores que antes de la crisis del coronavirus',
  'app.emergency-responses.current-situation.answer.earnings-somewhat-less': 'Los ingresos de mi familia son algo menores que antes de la crisis del coronavirus',
  'app.emergency-responses.current-situation.answer.earnings-significantly-less': 'Los ingresos de mi familia son significativamente menores que antes de la crisis del coronavirus',
  'app.emergency-responses.current-situation.answer.lost-all-earnings' : 'Mi familia ha perdido todas los ingresos',
  'app.emergency-questions.last-paycheck-amount' : '¿Cuál fue el monto de su último cheque de pago?',
  'app.emergency-questions.paycheck-frequency' : 'Frecuencia de pago',
  'app.emergency-responses.paycheck-frequency.answer.Weekly' : 'Semanal',
  'app.emergency-responses.paycheck-frequency.answer.Bi-weekly' : 'Cada dos semanas',
  'app.emergency-responses.paycheck-frequency.answer.Semi-monthly' : 'Quincenal',
  'app.emergency-questions.financial-changes' : '¿Qué otros cambios financieros en su presupuesto y flujo de efectivo estás experimentando? ',
  'app.emergency-questions.check-all-that-apply' : ' (Marque todas las opciones que apliquen)',
  'app.emergency-responses.financial-changes.answer.more-mouths': 'Alimento más bocas en casa',
  'app.emergency-responses.financial-changes.answer.supporting-older-parents' : 'Apoyo económicamente a mis padres mayores y/u otros miembros de la familia',
  'app.emergency-responses.financial-changes.answer.saving-on-eating-out' : 'Ahorro en comer afuera, entretenimiento y otras actividades',
  'app.emergency-responses.financial-changes.answer.spending-more-shopping': 'Gasto más comprando en línea',
  'app.emergency-responses.financial-changes.answer.paying-more': 'Pagar más por programas de educación en línea o Internet más rápido en casa',
  'app.emergency-responses.financial-changes.answer.other': 'Otro',
  'app.emergency-questions.describe-your-savings': '¿Cuál de los siguientes describe sus ahorros?',
  'app.emergency-responses.savings.answer.i-had-none':'No tenía ahorros cuando comenzó la crisis',
  'app.emergency-responses.savings.answer.i-have-used-all-savings':'He usado todos mis ahorros',
  'app.emergency-responses.savings.answer.i-have-used-some-savings':'He usado parte de mis ahorros',
  'app.emergency-responses.savings.answer.i-have-not-used-any-savings':'No he tenido que tocar mis ahorros',
  'app.emergency-responses.savings.we-know-it-must-have-been-hard-to-use-savings': 'Sabemos que debe haber sido difícil para usted el tener que haber usado sus ahorros para esta crisis inesperada. Se necesita de mucho esfuerzo el desarrollar ese hábito. Pero este es exactamente el tipo de emergencia para el que tenía sus ahorros. No se desespere, SaverLife y su consejero de TrustPlus lo ayudarán a reconstruir y restablecer sus ahorros.',
  'app.emergency-questions.how-much-do-you-have-set-aside': '¿Cuánto ha ahorrado en efectivo o tiene en ahorros líquidos ahora?',
  'app.emergency-questions.what-assistance-have-you-received': '¿Qué fuentes de asistencia ha recibido desde el comienzo de la crisis del Coronavirus?',
  'app.emergency-responses.assistance.answer.unemployment' : 'Desempleo',
  'app.emergency-responses.assistance.answer.CAREact':'Cheque del pago del estímulo de la ley Care',
  'app.emergency-responses.assistance.answer.SNAP':'SNAP (cupones de alimentos)',
  'app.emergency-responses.assistance.answer.foodBank': 'Asistencia del banco de alimentos o donaciones en especie',
  'app.emergency-responses.assistance.answer.smallBusiness': 'Préstamos y subvenciones de continuidad para pequeñas empresas',
  'app.emergency-responses.assistance.answer.emergencyHealthCoverage': 'Cobertura de salud de emergencia',
  'app.emergency-responses.assistance.answer.noneOfTheAbove': 'Ninguna de las anteriores',
  'app.emergency-response.text.unemployment-expanded-under-CARES': 'El desempleo se ha expandido bajo la Ley CARES y se han eliminado algunos requisitos para recibir asistencia SNAP (cupones de alimentos). Esperamos que pueda acceder a los beneficios que pueden complementar sus ingresos y ayudar a satisfacer sus necesidades, pero para algunos puede que no sea suficiente. Su asesor financiero de TrustPlus puede ayudarle a hacer un plan para llegar a fin de mes.',
  'app.emergency-response.text.it-will-be-a-challenge':'Será un desafío sino puede obtener ningún beneficio complementario a sus ingresos, pero todavía hay programas disponibles en bancos de alimentos locales u organizaciones sin fines de lucro que pueden garantizar que su familia satisfaga sus necesidades básicas. Además, su entrenador de TrustPlus puede ayudarlo a hacer un plan para llegar a fin de mes.',
  'app.emergency-questions.have-you-spoken-with-landlord':'¿Ha hablado o planea hablar con el arrendador para saber si puede posponer (saltarse) el pago del alquiler o pagar un monto menor del alquiler?',
  'app.emergency-responses.landlord.answer.yes-rent-forgiven':'Sí, mi renta o una parte de mi renta está siendo perdonada por este mes',
  'app.emergency-responses.landlord.answer.yes-lower-amount':'Sí, y pagare una cantidad menor.',
  'app.emergency-responses.landlord.answer.yes-not-sure': 'Sí, pero no estoy seguro de cuánto pagaré.',
  'app.emergency-responses.landlord.answer.no-not-an-option': 'No, esa no es una opción para mí',
  'app.emergency-responses.landlord.answer.not-applicable':'N/A- No alquilo',
  'app.emergency-response.landlord.text.helpful-to-ask-landlord-support': 'Algunas ciudades han detenido los desalojos, lo que significa que si no puede pagar el alquiler, no correrá el riesgo de perder su vivienda por el momento. Si decide hacer un pago parcial a su renta, considere cómo los próximos meses se verán afectados por una renta más alta y los ajustes que tendrá que hacer.',
  'app.emergency-questions.have-you-filed-taxes':'¿Ha presentado sus impuestos recientemente (en esta temporada de impuestos)?',
  'app.emergency-responses.filed-taxes.answer.Yes, and I received a refund + EITC (earned income tax credit)': 'Sí, y recibí un reembolso + EITC (crédito tributario por ingreso del trabajo)',
  'app.emergency-responses.filed-taxes.answer.Yes, and I received a refund': 'Sí, y recibí un reembolso.',
  'app.emergency-responses.filed-taxes.answer.Yes, and I owed taxes':'Sí, y tengo deuda de impuestos',
  'app.emergency-responses.filed-taxes.answer.Not yet':'Aún no',
  'app.emergency-responses.filed-taxes.answer.I do not file taxes':'Yo no declaro impuestos',
  'app.emergency-response.yes-owed-taxes.text.tax-payments-postponed':'Afortunadamente, el pago de impuestos se ha pospuesto hasta Julio.',
  'app.emergency-response.not-filed-yet.text.we-strongly-encourage-to-file':'Si aún no ha presentado sus impuestos, es posible que reciba un reembolso de impuestos para ayudar con el flujo de caja. Si debe impuestos, afortunadamente los pagos de impuestos se posponen hasta Julio. La Ley CARES depositará directamente pagos de estímulo de hasta $1200 para personas calificadas que hayan presentado impuestos para 2019 o 2018, con pagos adicionales de $ 500 si tiene hijos. Si no ha presentado sus impuestos en los últimos años, le recomendamos que lo haga lo antes posible para que pueda aprovechar los pagos de estímulo cuando estén disponibles.',

  //  Emergency Response - Questions Two
  'app.emergency-response.questions-two.lets-make-a-plan.subtitle': 'Hagamos un plan',
  'app.emergency-response.review-priorities.text': 'Ahora que hemos revisado algunos de los sistemas de soporte, queremos ayudarlo a revisar sus prioridades. Piense en los gastos que puede tener en las próximas 4-6 semanas.',
  'app.emergency-questions.priorities.what-comes-first': '¿Qué es lo primero para ti? ¿Qué necesita tu atención inmediata? ¿O qué está cubierto por otros sistemas de ayuda y se puede dejar al final de su lista?',
  'app.emergency-questions.priorities.take-a-moment-to-prioritize':' Tómese un momento para priorizar estas categorías de gastos. Marque cada categoría en una escala del 1 (la prioridad más importante) al 10 (la menos importante).',
  'app.emergency-responses.priority.label.food-groceries':'Comida',
  'app.emergency-responses.priority.label.rent-mortgage':'Alquiler/hipoteca',
  'app.emergency-responses.priority.label.car-transportation':'Vehiculo/Transporte',
  'app.emergency-responses.priority.label.gas-electric':'Gas/electricidad',
  'app.emergency-responses.priority.label.mobile-phone':'Teléfono móvil',
  'app.emergency-responses.priority.label.credit-card':'Facturas de tarjetas de crédito / préstamos personales',
  'app.emergency-responses.priority.label.health-care':'Cuidado de la salud',
  'app.emergency-responses.priority.label.daycare-education':'Guardería o educación',
  'app.emergency-responses.priority.label.student-loans':'Préstamos estudiantiles',
  'app.emergency-responses.priority.label.maintaining-small-business':'Mantener los gastos de mi pequeña empresa',
  'app.emergency-responses.priority.answer.1': '1',
  'app.emergency-responses.priority.answer.2': '2',
  'app.emergency-responses.priority.answer.3': '3',
  'app.emergency-responses.priority.answer.4': '4',
  'app.emergency-responses.priority.answer.5': '5',
  'app.emergency-responses.priority.answer.6': '6',
  'app.emergency-responses.priority.answer.7': '7',
  'app.emergency-responses.priority.answer.8': '8',
  'app.emergency-responses.priority.answer.9': '9',
  'app.emergency-responses.priority.answer.10': '10',
  'app.emergency-questions.what-is-biggest-financial-challenge': '¿Cuál es el mayor desafío o preocupación financiera que tienes en este momento?',
  'app.emergency-questions.how-worried-are-you':'¿Qué tan preocupado está por sus gastos principales y cómo llegar a fin de mes?',
  'app.emergency-responses.worried.answer.Extremely worried':'Extremadamente preocupado',
  'app.emergency-responses.worried.answer.Very worried':'Muy preocupado',
  'app.emergency-responses.worried.answer.Somewhat worried':'Algo preocupado',
  'app.emergency-responses.worried.answer.Not worried':'No estoy preocupado',
  'app.emergency-questions.consider-other-options-for-cash-payment': 'Cuando reciba su pago de emergencia de $500, es posible que quiera pagar la factura de mayor prioridad. Sin embargo, ¿cuál de los siguientes opciones podría considerar también:',
  'app.emergency-responses.other-options-cash-payment.answer.Make partial payments on large bills': 'Hacer pagos parciales más pequeños en facturas grandes, como el alquiler',
  'app.emergency-responses.other-options-cash-payment.answer.Stabilize utility - cell phone and gas':'Estabilizar utilidades como teléfono celular y ConEd',
  'app.emergency-responses.other-options-cash-payment.answer.Set aside cash for food/medical expenses':'Reservar algo de efectivo para gastos futuros relacionados con alimentos y gastos médicos',
  'app.emergency-responses.other-options-cash-payment.answer.Prioritize credit card payments':'Dar prioridad a los pagos de las tarjetas de crédito de acuerdo con el plan de dificultad financiera y las tasas de interés que tengan',
  'app.emergency-responses.other-options-cash-payment.answer.Put your student loan into forbearance':'Si corresponde, trabaje con su proveedor de préstamos estudiantiles para poner su préstamo en libertad sin intereses',
  'app.emergency-responses.other-options-cash-payment.answer.None of the above apply to me':'Ninguno de los anteriores se aplica a mí',
  'app.emergency-response.message.reflect-on-finances':'Si se pregunta por dónde comenzar, lo alentamos a reflexionar sobre sus finanzas y su comodidad personal.',
  'app.emergency-response.message.ask-yourself': 'Pregúntese:',
  'app.emergency-response.message.are-certain-bills-more-stressful':'¿Ciertas facturas o deudas son más estresantes para usted que otras?',
  'app.emergency-response.message.will-cash-bring-peace-of-mind': '¿El efectivo disponible en sus manos le dará tranquilidad?',
  'app.emergency-response.message.six-months-from-now': 'Dentro de 6 meses, cree que los intereses acumulados o deudas adicionales adquiridas serán dinero bien gastado que lo ayudarán a superar la crisis o será un gran revés?',
  'app.emergency-response.message.no-one-size-fits-all': 'No existe una estrategia específica que funcione para todos. En última instancia, lo alentamos a que haga un plan que minimice el impacto en sus necesidades actuales (como alimentos, servicios públicos y la salud de su familia), sin aumentar su carga futura.',
  'app.emergency-response.message.trustplus-coaches-can-help': 'Los consejeros financieros de TrustPlus pueden ayudarlo a hacer ese plan.',
  'app.emergency-questions.what-are-your-initial-thoughts-emergency-payment':'¿Qué ideas tiene inicialmente de como usar su pago de emergencia? De nuevo, no hay una respuesta incorrecta aquí. (Limitada a 250 caracteres)',
  'app.emergency-questions.additional-recommendations':'Además de utilizar su pago Fondo de Respuesta de Emergencia para estabilizarse, también le recomendamos que considere lo siguiente. Marque una o todas las opciones que consideraria:',
  'app.emergency-responses.additional-recommendations.answer.proactively-communicate': 'Comunicar de manera proactiva sus planes a sus acreedores, si es posible',
  'app.emergency-responses.additional-recommendations.answer.request-to-change-due-dates': 'Solicitar cambios en las fechas de vencimiento',
  'app.emergency-responses.additional-recommendations.answer.request-to-waive-late-fees': 'Solicitud de exención de recargos',
  'app.emergency-responses.additional-recommendations.answer.document': 'Documentar las interrupciones en sus ingresos y presupuesto, así como todos sus esfuerzos para cumplir sus obligaciones',
  'app.emergency-responses.additional-recommendations.answer.earmark-expenses': 'Asignación de cualquier gasto que haya aplazado o pospuesto',

  // Emergency Response Find A Time - Meet Your Coach
  'app.emergency-response.find-a-time.meet-with-your-coach.title': 'Todos necesitamos una cosa menos de que preocuparnos en estos momentos.',
  'app.emergency-response.find-a-time.text-1': 'TrustPlus está aquí con consejería financiera gratis para ayudarte con las decisiones abrumadoras que tengas que tomar.',
  'app.emergency-response.find-a-time.text-2': 'Podemos ayudarte a',
  'app.emergency-response.find-a-time.how-we-help.bullet-1': 'Comprender mejor los beneficios disponibles y otros programas de ayuda',
  'app.emergency-response.find-a-time.how-we-help.bullet-2' : 'Asistencia en la negociación con acreedores',
  'app.emergency-response.find-a-time.how-we-help.bullet-3': 'Apoyo en la aplicación a programas que permiten posponer pagos',
  'app.emergency-response.find-a-time.how-we-help.bullet-4': 'Trabajar con usted para planificar las mejores maneras para utilizar su pago de alivio.',
  'app.emergency-response.find-a-time.text-3': 'Los consejeros de TrustPlus están aquí para usted: por teléfono, Skype omensaje de texto.',
  'app.emergency-response.find-a-time.text-4': 'Tenga en cuenta que este programa es opcional, pero altamente recomendado. Treinta minutos pueden marcar la diferencia',
  'app.emergency-response.find-a-time.saverlife-trustplus-wells-fargo-agree-terms': 'Acepto que mi participación, incluido mi nombre, correo electrónico, dirección y teléfono, se compartirá con SaverLife, TrustPlus, y Wells Fargo, con el fin de confirmar su identidad y participación en este proyecto. Su información de contacto no se utilizará para ningún otro propósito que no sea el de apoyarlo con sus finanzas, si así lo solicita.',
  'app.emergency-response.find-a-time.btn': 'Elija una hora',
  'app.emergency-response.skip-this-step.link': 'Brinca este paso',
  'app.emergency-response.not-right-now.btn': 'No Ahora',

  // Emergency Reponse - Other

  'app.emergency-response.your-credit-report.your-credit-report': 'Su reporte de crédito.',
  'app.emergency-response.placeholder.redirect-to-SaverLife': 'We\'re sorry, but this program is no longer available. Please contact SaverLife support with any questions.',

  //  Opportunity Fund
  'app.opportunity-fund.trust-plus-who-we-are.text': 'TrustPlus™, un servicio de Neighborhood Trust es una empresa social sin fines de lucro y es el proveedor líder nacional de servicios de educación y consejería financiera efectiva y de alta calidad para los trabajadores. Durante más de 20 años, Neighborhood Trust ha ayudado a más de 60,000 trabajadores estadounidenses a mejorar sus puntajes de crédito, reducir su deuda y adoptar hábitos financieros más.',
  'app.opportunity-fund.saver-life-united-way-trust-plus-agree-terms': 'Acepto que mi participación, incluido mi nombre, correo electrónico, dirección y teléfono, se compartirá con SaverLife, {orgName}, {orgPartner},  y TrustPlus, con el fin de confirmar su identidad y participación en este proyecto. Su información de contacto no se utilizará para ningún otro propósito que no sea el de apoyarlo con sus finanzas, si así lo solicita.',
  'app.opportunity-fund.saver-life-opportunity-fund-trust-plus-agree-terms': 'Acepto que mi participación, incluido mi nombre, correo electrónico, dirección y teléfono, se compartirá con SaverLife, {orgName}, y TrustPlus, con el fin de confirmar su identidad y participación en este proyecto. Su información de contacto no se utilizará para ningún otro propósito que no sea el de apoyarlo con sus finanzas, si así lo solicita.',
  'app.opportunity-fund.saver-life-trust-plus-united-way-are-here.text': 'SaverLife y TrustPlus están aquí para apoyarlo durante esta crisis. Se han asociados con {orgName} con el apoyo de {orgPartner} para proporcionar pagos de emergencia de $500 a personas preseleccionadas.',
  'app.opportunity-fund.saver-life-trust-plus-opportunity-fund-are-here.text': 'SaverLife y TrustPlus están aquí para apoyarlo durante esta crisis. Se han asociados con {orgName} para proporcionar pagos de emergencia de $500 a personas preseleccionadas.',
  'app.opportunity-fund.how-we-can-help.title': 'Opportunity Fund',
  'app.opportunity-fund.opportunity-fund-who-we-are.text': 'Opportunity Fund es una organización sin fines de lucro cuya misión es impulsar la movilidad económica mediante la entrega de capital asequible y soluciones financieras responsables a determinados empresarios y comunidades.',
  'app.opportunity-fund.tell-us-about-you.title': 'Cuéntenos acerca de tí',

  //Opportunity Fund - Questions One
  'app.opportunity-fund.questions-one.title': 'Cuéntenos acerca de tí',
  'app.emergency-questions.owner-or-employee.I am': 'Yo soy:',
  'app.emergency-responses.owner-or-employee.answer.An employee at a small business': 'Empleado de un pequeño negocio',
  'app.emergency-responses.owner-or-employee.answer.A small business owner': 'Dueño de un pequeño negocio',
  'app.emergency-questions.business-name': 'Nombre del negocio: ',
  'app.emergency-questions.industry': 'Industria:',
  'app.emergency-responses.industry.answer.Accounting/Bookkeeping':	'Contabilidad',
  'app.emergency-responses.industry.answer.Apparel':	'Vestimenta',
  'app.emergency-responses.industry.answer.Auto Repair':	'Reparación de Autos',
  'app.emergency-responses.industry.answer.Auto Sales':	'Venta de Autos',
  'app.emergency-responses.industry.answer.Childcare':	'Cuidado de Niños (guarderías)',
  'app.emergency-responses.industry.answer.Construction':	'Construcción',
  'app.emergency-responses.industry.answer.Delivery':	'Servicio de Entrega',
  'app.emergency-responses.industry.answer.Direct Sales':	'Ventas Directas',
  'app.emergency-responses.industry.answer.Dry Cleaning':	'Limpieza en seco (Dry Cleaning)',
  'app.emergency-responses.industry.answer.Electronic Sales':	'Ventas electrónicas',
  'app.emergency-responses.industry.answer.Financial Services':	'Servicios Financieros',
  'app.emergency-responses.industry.answer.Flea Market/Swap Meet':	'Mercado de Pulgas',
  'app.emergency-responses.industry.answer.Flower Sales':	'Venta de Flores',
  'app.emergency-responses.industry.answer.Food Catering':	'Preparación de alimentos para eventos (catering)',
  'app.emergency-responses.industry.answer.Food services': 'Servicios de comida',
  'app.emergency-responses.industry.answer.Food Truck':	'Camión de comida',
  'app.emergency-responses.industry.answer.Gas Station':	'Gasolinera',
  'app.emergency-responses.industry.answer.Hair Salon':	'Peluquería',
  'app.emergency-responses.industry.answer.Healthcare':	'Cuidado de la salud',
  'app.emergency-responses.industry.answer.Home Care/Senior':	'Servicio de cuidados en el hogar (adulto mayor)',
  'app.emergency-responses.industry.answer.Home Repair Services':	'Servicios de reparación de viviendas',
  'app.emergency-responses.industry.answer.Insurance':	'Seguros',
  'app.emergency-responses.industry.answer.Janitorial Services':	'Servicio de Limpieza',
  'app.emergency-responses.industry.answer.Mini Market / 99 cent':	'Mini Mercado / 99 centavos',
  'app.emergency-responses.industry.answer.Pet Service':	'Servicio para Mascotas',
  'app.emergency-responses.industry.answer.Printing and Publishing':	'Imprenta y Editorial',
  'app.emergency-responses.industry.answer.Professional Service':	'Servicios Profesionales',
  'app.emergency-responses.industry.answer.Real Estate':	'Bienes raíces',
  'app.emergency-responses.industry.answer.Restaurant':	'Restaurante',
  'app.emergency-responses.industry.answer.Retail':	'Ventas al por menor',
  'app.emergency-responses.industry.answer.Taxi/Limo':	'Taxi/Limosina',
  'app.emergency-responses.industry.answer.Towing':	'Remolque',
  'app.emergency-responses.industry.answer.Transportation':	'Transporte',
  'app.emergency-responses.industry.answer.Trucking/Long Haul':	'Manejo de Camiones/Larga distancia',
  'app.emergency-responses.industry.answer.Web Info Service':	'Servicio de Información Web',
  'app.emergency-responses.industry.answer.Other':	'Otro',
  'app.emergency-responses.industry.answer.Entertainment':	'Entretenimiento',
  'app.emergency-responses.industry.answer.Agriculture':	'Agricultura',
  'app.emergency-responses.industry.answer.Hospitality':	'Hoteleria',
  'app.emergency-responses.industry.answer.Manufacturing':	'Fabricas de Manufactura',
  'app.emergency-responses.industry.answer.Education':	'Educación',
  'app.emergency-responses.industry.answer.Accommodation':	'Alojamiento',
  'app.emergency-responses.industry.answer.Government':	'Gobierno',
  'app.emergency-responses.industry.answer.Non-Profit':	'Organización sin fines de lucro',
  'app.emergency-questions.how-would-you-describe-your-job': '¿Cómo describirías tu trabajo en el negocio / cuál es el título de tu trabajo?: ',

//  Opportunity Fund - Questions Two
  'app.opportunity-fund.how-has-coronavirus-affected-you.title': 'Como le ha afectado el Coronavirus?',
  'app.emergency-questions.emoji-select.select-how-you-are-feeling': 'Seleccione cómo se siente:',
  'app.emergency-questions.emoji-select.how-are-you-doing': '¿Cómo te va? Como consejeros financieros, además de preocupamos por las finanzas de nuestros clientes, también nos interesamos por tu bienestar en general, como tu su salud física y mental. Sabemos que todos están conectados.',
  'app.emergency-questions.describe-current-earnings': '¿Cuál de las siguientes opciones describe mejor los ingresos de su familia actuales?',
  'app.emergency-responses.current-earnings.answer.earnings-not-interrupted' : 'No han sido interrumpidos por la crisis del coronavirus',
  'app.emergency-responses.current-earnings.answer.earnings-slightly-less': 'Son ligeramente menores que antes de la crisis del coronavirus',
  'app.emergency-responses.current-earnings.answer.earnings-somewhat-less': 'Son algo menores que antes de la crisis del coronavirus',
  'app.emergency-responses.current-earnings.answer.earnings-significantly-less': 'Son significativamente menores que antes de la crisis del coronavirus',
  'app.emergency-responses.current-earnings.answer.lost-all-earnings' : 'Ha perdido todas los ingresos.',
  'app.opportunity-fund.savings.we-know-it-must-have-been-hard-to-use-savings': 'Sabemos que debe haber sido difícil usar sus ahorros para afrontar esta crisis inesperada. Se necesita de mucho trabajo y esfuerzo para acostumbrarse a ahorrar.  Pero una crisis, como ésta, es exactamente el tipo de emergencia para el que tenía sus ahorros. SaverLife y su consejero financiero de TrustPlus pueden ayudarlo a reconstruir y restablecer sus ahorros en el futuro.',
  'app.opportunity-fund.consider-options.we-encourage-you-to-make-a-plan': 'No existe una estrategia específica que funcione para todos. En última instancia, lo alentamos a que haga un plan que minimice el impacto en sus necesidades actuales (como alimentos, servicios públicos y la salud de su familia), sin aumentar su carga futura.',
  'app.opportunity-fund.consider-options.trust-plus-coaches-can-help-you': 'Los consejeros financieros de TrustPlus pueden ayudarlo a hacer ese plan.',

//  Opportunity Fund - Questions Three
  'app.opportunity-fund.your-emergency-fund-payment.title': '¿Cómo obtener tu pago?',
  'app.emergency-questions.do-you-have-a-bank-account': '¿Tienes una cuenta bancaria?',
  'app.emergency-responses.have-a-bank-account.answer.Yes': 'Sí',
  'app.emergency-responses.have-a-bank-account.answer.No': 'No',
  'app.opportunity-fund.yes-bank-account.we-will-direct-you-to-Saver-Life': ' Al completar este formulario lo guiaremos a SaverLife Emergency Relief website donde completará una aplicación y deberá vincular a su cuenta bancaria utilizando su nombre de usuario y la contraseña que usa para acceder a su banco.',
  'app.opportunity-fund.yes-bank-account.if-your-bank-isnt-supported': 'Si su banco no es compatible con la plataforma SaverLife, necesitará su número de ruta (código de nueve dígitos), número de cuenta bancaria y una  prueba de dirección para subir a la página.  A partir de ahí, su pago de emergencia de $500 relief payment se depositará directamente en su cuenta.',
  'app.opportunity-fund.no-bank-account.no-problem': 'Okay. SaverLife puede entregarle su pago a través de enviar una tarjeta prepagada a la dirección que proporcionó. La tarjeta prepagada demorará de 4 a 6 semanas. Sin embargo, si desea recibir el dinero antes, puede obtener una cuenta bancaria en línea ahora e ingresar sus datos bancarios para recibir el dinero de esa manera. Para recomendaciones de cuentas bancarias adaptadas a usted, puede reunirse con un asesor financiero para obtener orientación. ',
  'app.opportunity-fund.no-bank-account.venmo-and-paypal' : 'Nota: Venmo y PayPal también son opciones que le entregarán su dinero rápidamente, pero deben estar vinculados a una cuenta bancaria.',
  'app.opportunity-fund.no-bank-account.upon-completion-will-send-to-saverlife' : 'Al completar este formulario, lo redirigiremos the Emergency Relief Payment site donde completará una solicitud y proveerá la información necesaria para recibir su pago.',


//  Opportunity Fund - Find A Time
  'app.opportunity-fund.find-a-time.meet-with-a-coach.title' : 'Meet With A TrustPlus Coach',
  'app.opportunity-fund.find-a-time.you-can-use-trust-plus-today': 'Puede empezar a usar TrustPlus desde hoy mismo sin costo para usted!',
  'app.opportunity-fund.find-a-time.trust-plus-coaches-are-here-for-you': 'Los consejeros de TrustPlus están aquí para ayudarlo. A continuación, elija la hora más conveniente para usted y comience su sesión de consejería financiera.',
  'app.opportunity-fund.find-a-time.no-thanks.link': 'No gracias',
  'app.opportunity-fund.session-confirmed.text': 'El próximo paso es revisar el resumen de sus deudas.',

  //  Opportunity Fund and Partner Crisis Response Shared -- Go To Saverlife
  'app.opportunity-fund.go-to-saver-life.title': 'Llévame a SaverLife',
  'app.opportunity-fund.go-to-saverlife.youre-not-done.title': 'Importante: Aún no ha terminado',
  'app.opportunity-fund.go-to-saver-life.we-have-included-a-short-video.prompt': 'Hemos incluido un vídeo corto para dirigirle a través de la creación de una cuenta de SaverLife. Cuando se sienta listo, por favor continúe a SaverLife.',
  'app.opportunity-fund.go-to-saver-life.take-me-to-saver-life.button': 'Llévame a SaverLife',
  'app.opportunity-fund.go-to-saverlife.no-appointment.before-you-receive-your-deposit.text': 'Antes de recibir su depósito, tendrá que crear una cuenta en SaverLife a fin de unir su cuenta bancaria y coleccionar su dinero.',
  'app.opportunity-fund.go-to-saverlife.thank-you-for-scheduling-your-appointment': 'Gracias por finalizar su sesión de asesoría financiera con {trustPlusCoach} el {appointmentDate} a las {appointmentTime}. ¡Esperamos poder ayudarle!',
  'app.opportunity-fund.go-to-saverlife.with-appointment.before-you-receive-your-deposit.text': 'Tenga en cuenta de que antes de recibir sú deposito, tendrá que crear una cuenta en SaverLife a fin de unir su cuenta bancaria y coleccionar su dinero.',

  //  United Way
  'app.united-way.how-we-can-help.title': 'United Way of Greater Newark',
  'app.united-way.how-we-can-help.text': 'United Way of Greater Newark existe para mejorar la vida de las personas, los niños y las familias a fin de fortalecer la comunidad en su conjunto. A través de diversos programas e iniciativas de servicio abordamos la raíz de las causas de las preocupaciones de la comunidad con énfasis en: educación, ingresos y salud.',

//Partner Crisis Response

//  Partner Crisis Response: Verify contact
  'app.partner-crisis-response.title': 'El Fondo de Respuesta a Emergencias',
  'app.partner-crisis-response.subheader': 'TrustPlus, un servicio de Neighborhood Trust, está aquí para apoyarlo a través de esta crisis. Se han asociado con SaverLife para proporcionar pagos de emergencia de $500 a individuos preseleccionados.',
  'app.partner-crisis-response.trustplus-who-we-are': '{trustPlusLink}™, un servicio de Neighborhood Trust es una empresa social sin fines de lucro y es el proveedor líder nacional de servicios de educación y consejería financiera efectiva y de alta calidad para los trabajadores. Durante más de 20 años, Neighborhood Trust ha ayudado a más de 60,000 trabajadores estadounidenses a mejorar sus puntajes de crédito, reducir su deuda y adoptar hábitos financieros más.',
  'app.partner-crisis-response.trustplus.link': 'TrustPlus',
  'app.partner-crisis-response.saverlife-who-we-are': '{saverLifeLink} es una organización sin fines de lucro con la misión de inspirar, informar y recompensar a los millones de estadounidenses que necesitan ayuda para ahorrar dinero, a través de socios y tecnología.',
  'app.partner-crisis-response.saverlife.link': 'SaverLife',
  'app.partner-crisis-response.how-trustplus-coaches-can-help.title': 'Cómo los entrenadores de TrustPlus pueden ayudar',
  'app.partner-crisis-response.how-trustplus-coaches-can-help.along-with-your-emergency-relief-payment': 'Junto con su pago de ayuda de emergencia de $500, estamos aquí para servir de guía sobre lo que usted puede estar haciendo para sus finanzas en estos tiempos inciertos. Nuestro objetivo ahora mismo es iluminar los recursos disponibles para usted, pero también ayudar en hacer un plan para las próximas semanas y meses.',
  'app.partner-crisis-response.how-trustplus-coaches-can-help.we-know-you-have-questions': 'Sabemos que tienen preguntas y están enfrentando muchas decisiones difíciles. No tienes que hacerlo solo. Los entrenadores de TrustPlus están aquí para usted por teléfono, Skype o texto.',
  'app.partner-crisis-response.verify-your-identity.title': 'Verifique Su Identidad',
  'app.partner-crisis-response.verify-your-identity.why-we-need-this-information': '*Por qué necesitamos esta información: esta oferta solo es elegible para empresas y empleados preseleccionados específicamente. Utilizaremos su información de contacto para confirmar su participación. También podemos comunicarnos con usted sobre su participación y brindarle más apoyo.',
  'app.partner-crisis-response.agree-to-not-share-terms': 'Confirmo que estoy utilizando el enlace que me fue enviado exclusivamente, y que no comparto el enlace con nadie. Compartir este enlace puede hacer que pierda mi participación.',
  'app.partner-crisis-response.saverlife-trustplus-tos': 'Acepto que mi participación, incluyendo mi nombre, correo electrónico y teléfono se compartirá con SaverLife y TrustPlus con el propósito de confirmar su identidad y participación en este programa. Su información de contacto se utilizará para ningún otro propósito que no sea apoyarle con sus finanzas, si se solicita.',

//  Partner Crisis Response: First attempt
  'app.partner-crisis-response.first-attempt.something-went-wrong.title': 'Algo salió mal',
  'app.partner-crisis-response.first-attempt.the-information-you-entered-doesnt-line-up': 'La información que ingresó no está en línea en lo que está en nuestro sistema. Si crees que es posible que hayas cometido un error tipográfico o otro error, te daremos un cambio para intentarlo de nuevo.',
  'app.partner-crisis-response.first-attempt.if-you-are-sure-the-information-is-correct': 'Si está seguro que la información es correcta, por favor ponte en contacto con nosotros por correo electrónico o por teléfono.',
  'app.partner-crisis-response.try-again.button': 'Volver a intentar',
  'app.partner-crisis-response.phoneNumber': '(646) 760-6810',

//  Partner Crisis Response: Final attempt
  'app.partner-crisis-response.final-attempt.something-is-still-wrong': 'Algo todavía no parece estar funcionando bien',
  'app.partner-crisis-response.final-attempt.if-you-are-sure-the-information-is-correct': 'Si está seguro que la información es correcta, por favor póngase en contacto con nosotros por correo electrónico, {supportEmail}, o por teléfono, {tollFreePhone}. Haremos todo lo posible para ayudar!',

//  Partner Crisis Response - Questions 1
  'app.partner-crisis-response.owner-or-employee.answer.An employee at a small business': 'Empleado',

//  Partner Crisis Response - Questions 3

  'app.partner-crisis-response.unbanked-user' : 'Una cuenta bancaria puede ayudarte a obtener tu pago más rápido y a administrar tus finanzas. Un entrenador de TrustPlus puede ayudarle a encontrar una cuenta bancaria de bajo costo que sea adecuada para usted. ¡Programa una sesión para obtener más información!',

//  Partner Crisis Response: Confirm email
  'app.partner-crisis-response.confirm-email.update-your-email.title': '¿Actualice su correo electrónico?',
  'app.partner-crisis-response.confirm-email.we-noticed-your-past-email-address': 'Hemos observado en el pasado que utilizó un correo electrónico con las siguientes propiedades (por seguridad, no podemos proporcionar la dirección completa):',
  'app.partner-crisis-response.confirm-email.starts-with': 'Comienza con ',
  'app.partner-crisis-response.confirm-email.is-at-the': 'Está en el dominio de ',
  'app.partner-crisis-response.confirm-email.domain': '',
  'app.partner-crisis-response.confirm-email.do-you-want-to-update-your-email': '¿Desea actualizar su correo electrónico a ',
  'app.partner-crisis-response.confirm-email.updating-your-email-will-change-contact-and-login': '?  La actualización cambiará la dirección de correo electrónico que utilizamos para ponerse en contacto con usted, así como el correo electrónico que utiliza para iniciar sesión en cualquiera de nuestros sitios.',
  'app.partner-crisis-response.confirm-email.or-you-can-keep-using': 'O puede seguir usando ',
  'app.partner-crisis-response.confirm-email.as-your-email': ' como su correo electrónico.',
  'app.partner-crisis-response.confirm-email.update-email.btn': 'Actualizar correo electrónico',
  'app.partner-crisis-response.confirm-email.keep-old-email.btn': 'Mantener correo electrónico antiguo',

  //Partner Crisis Response: Inactive/Placeholder
  'app.partner-crisis-response.program-inactive.title': 'Este programa no está disponible.',
  'app.partner-crisis-response.program-inactive.text': 'Nuestra asociación para el programa de alivio en efectivo ha terminado por ahora. Sin embargo, TrustPlus todavía está disponible para ayudarlo a administrar sus finanzas. Regrese a casa para inscribirse para una sesión gratuita de asesoria financiero.',
  'app.partner-crisis-response.program-inactive.btn.return-to-home': 'Regresar a la página de inicio',

  // Emergency Flow Added On Questions
  'app.emergency-responses.current-situation.answer.earnings-increased' : 'Mis ingresos familiares han aumentado',
  'app.emergency-questions.increased-earnings-due-to' : 'Los ingresos de mi familia han aumentado debido a:',
  'app.emergency-responses.increased-earnings-reason.answer.New sources of assistance/benefits': 'Nuevas fuentes de asistencia/beneficios',
  'app.emergency-responses.increased-earnings-reason.answer.Increase in pay or hours' : 'Aumento de salarios u horas',
  'app.emergency-questions.decreased-earnings-due-to' : 'Mis ingresos han disminuido debido a: ',
  'app.emergency-responses.decreased-earnings-reason.answer.reduced my work hours involuntarily': 'Reducción de mis horas de trabajo involuntariamente',
  'app.emergency-responses.decreased-earnings-reason.answer.unpaid leave to care for a sick or immunocompromised member of my household': 'Un Descanso sin pago para cuidar a un familiar enfermo o con enfermedades autoinmunes',
  'app.emergency-responses.decreased-earnings-reason.answer.had to take personal sick leave': 'Tuve que tomar un tiempo de Descanso para enfermedad personal',
  'app.emergency-responses.decreased-earnings-reason.answer.unpaid leave to comply with stay at home orders or self-isolate': 'Un Descanso sin pago para cumplir con las órdenes de permanencia en casa o auto-aislamiento',
  'app.emergency-responses.decreased-earnings-reason.answer.unpaid leave to take care of my kids who cannot go to school': 'Un descanso sin pago para cuidar a mis hijos que no pueden ir a la escuela',
  'app.emergency-questions.financial-situation-before-pandemic': '¿Cómo se sintió con su situación financiera antes de la pandemia?',
  'app.emergency-responses.financial-situation-before-pandemic.answer.I was financially secure': 'Yo estaba financieramente seguro',
  'app.emergency-responses.financial-situation-before-pandemic.answer.I had some breathing room in my budget': 'Tenía un poco de margen en mi presupuesto',
  'app.emergency-responses.financial-situation-before-pandemic.answer.I was living paycheck to paycheck': 'Estaba viviendo de cheque a cheque de pago',
  'app.emergency-responses.financial-situation-before-pandemic.answer.I was behind on my bills and other payments': 'Estaba atrasado en mis facturas y otros pagos',
  'app.emergency-questions.what-is-your-annual-income': '¿Cuál es su ingreso anual?',
  'app.emergency-responses.annual-income.answer.0-15k': '0-15k',
  'app.emergency-responses.annual-income.answer.15-25k': '15-25k',
  'app.emergency-responses.annual-income.answer.25-35k': '25-35k',
  'app.emergency-responses.annual-income.answer.35-45k': '35-45k',
  'app.emergency-responses.annual-income.answer.45-60k': '45-60k',
  'app.emergency-responses.annual-income.answer.60-75k': '60-75k',
  'app.emergency-responses.annual-income.answer.75-100k': '75-100k',
  'app.emergency-responses.annual-income.answer.100+': '100+',

  // Emergency Flow Added On Expenses Questions
  'app.emergency-questions.covid-effect-on-expenses': '¿Cómo describiría sus gastos desde el comienzo de la pandemia?',
  'app.emergency-responses.covid-effect-on-expenses.answer.My expenses have increased': 'Mis gastos han subido',
  'app.emergency-responses.covid-effect-on-expenses.answer.My expenses have decreased': 'Mis gastos han bajado',
  'app.emergency-responses.covid-effect-on-expenses.answer.My expenses have not changed': 'Mis gastos no han cambiado',
  'app.emergency-questions.increased-expenses-due-to':'Mis gastos han aumentado por:',
  'app.emergency-questions.increased-expenses-reason.answer.More mouths to feed at home': 'Tener más bocas que alimentar en casa',
  'app.emergency-questions.increased-expenses-reason.answer.Supporting older parents or other family members':'Tener que apoyar a padres mayores u otros miembros de familia',
  'app.emergency-questions.increased-expenses-reason.answer.Spending more on online shopping': 'Gastar más en compras en línea',
  'app.emergency-questions.increased-expenses-reason.answer.Paying more for online education programs or faster internet at home': 'Pagar más por programas de educación en línea o Internet más rápido en casa',
  'app.emergency-questions.increased-expenses-reason.answer.Other expenses': 'Otros gastos',
  'app.emergency-questions.decreased-expenses-due-to':'Mis gastos han bajado debido a',
  'app.emergency-responses.decreased-expenses-reason.answer.Lower transportation costs': 'Bajo costos de transportacion',
  'app.emergency-responses.decreased-expenses-reason.answer.Lower childcare costs': 'Bajo costos de cuidado infantile',
  'app.emergency-responses.decreased-expenses-reason.answer.Shopping less': 'A comprar menos',
  'app.emergency-responses.decreased-expenses-reason.answer.Negotiated lower rent or bills': 'Negociado la renta o facturas más bajas',
  'app.emergency-responses.decreased-expenses-reason.answer.Saving on eating out and other entertainment and activities': 'Ahorrando en no comiendo afuera y otro actividades y entretenimiento',
  'app.emergency-questions.how-cover-shortfall': '¿Sus gastos han excedido sus ingresos? ¿Cómo ha cubierto algún déficit de presupuesto que haya sufrido?',
  'app.emergency-responses.how-cover-shortfall-reason.answer.noShortfalls': 'No he tenido un déficit',
  'app.emergency-responses.how-cover-shortfall-reason.answer.bankAccounts': 'Cuenta corriente/Ahorros',
  'app.emergency-responses.how-cover-shortfall-reason.answer.borrowed': 'Prestamos familiares o de amigos',
  'app.emergency-responses.how-cover-shortfall-reason.answer.creditCard': 'Tarjeta de credito',
  'app.emergency-responses.how-cover-shortfall-reason.answer.advanceLoan': 'Anticipos de dinero/Préstamos de día de pago',
  'app.emergency-responses.how-cover-shortfall-reason.answer.cashRelief': 'Alivio Effectivo',
  'app.emergency-responses.how-cover-shortfall-reason.answer.otherWays': 'Otro',
  'app.emergency-responses.how-cover-shortfall-reason.answer.unableToCover': 'No he podido cubrirlos',
  'app.emergency-questions.how-managing-finances': '¿Cuánto esfuerzo mental utiliza para administrar sus gastos?',



  //Emergency/Crisis Response/Relief Flows - Pull Credit Report
  'app.emergency-response.credit-report-form.understand-past-and-present': 'Al tomar decisiones en las próximas semanas, es importante entender su pasado financiero y presente. Una manera fácil de hacerlo es ver la información de su informe de crédito.',
  'app.emergency-response.credit-report-form.with-your-permission': 'Con su permiso, podemos obtener su informe de crédito TransUnion® y mostrarle información que le ayudará a comprender su vida financiera. Esto será un "tirón suave", lo que significa que no afectará su puntuación.',

  //NTFCU Support Flow
  'app.ntfcu-support.title': 'Fondo de Ayuda de Emergencia COVID-19',
  'app.ntfcu-support.subheader': 'Neighborhood Trust FCU y TrustPlus están aquí para apoyarle a través de esta crisis! Las dos organizaciones sin fines de lucro se han agrupado para otorgar donaciones de emergencia de $500 a miembros preseleccionados de la cooperativa Neighborhood Trust.',
  'app.ntfcu-support.ntfcu': 'Neighborhood Trust Federal Credit Union (NTFCU)',
  'app.ntfcu-support.ntfcu-who-we-are': 'Neighborhood Trust Federal Credit Union es una cooperativa de crédito para el desarrollo comunitario (CDCU); con la misión de proporcionar acceso a servicios financieros asequibles a las comunidades marginadas de Nueva York.',
  'app.ntfcu-support.how-we-can-help': 'Como podemos ayudar?',
  'app.ntfcu-support.how-trustplus-coaches-can-help.along-with-your-emergency-relief-payment': 'Nuestra misión como organizaciones sin fines de lucro es la de brindarle orientación sobre lo que puede hacer por sus finanzas en estos tiempos inciertos. Nuestro objetivo ahora es ayudarle a hacer un plannpara las próximas semanas y meses.',
  'app.ntfcu-support.how-trustplus-coaches-can-help.we-know-you-have-questions': 'Sabemos que tiene preguntas y enfrenta muchas decisiones abrumadoras, pero sepa que no debe de hacerlo solo. Neighborhood Trust FCU y TrustPlus están aquí para ayudarle.',
  'app.ntfcu-support.ntfcu-deposit-terms': 'Por este medio autorizo Neighborhood Trust FCU (NTFCU) a depositar $500.00 a la cuenta de acciones que actualmente tengo en NTFCU.',
  'app.ntfcu-support.ntfcu-participation-terms': 'Acepto que mi participación, incluido mi nombre, correo electrónico, dirección y teléfono, se compartirá con Neighborhood Trust FCU, y TrustPlus, con el fin de confirmar su identidad y participación en este proyecto. Su información de contacto no se utilizará para ningún otro propósito que no sea el de apoyarlo con sus finanzas, si así lo solicita.',
  'app.ntfcu-support.thank-you.subtitle': '¡Gracias! ¡Su solicitud ha sido enviada!',
  'app.ntfcu-support.you-will-be-notified-within-seven-days': 'Recibirá una notificación dentro de 7 días si se acepta su solicitud y el pago se realizará 2 días laborables después.',
  'app.ntfcu-support.we-understand-finances-may-be-hard': 'Entendemos que sus finanzas pueden estar en un momento difícil ahora y en los próximos meses. Esperamos que los $ 500 puedan ayudar de alguna manera. Si desea el apoyo de la entrenadora financiera de Neighborhood Trust FCU, Ana Paez, envíe un mensaje de texto al (256) 867-1593 para programar una llamada telefónica.',
  'app.ntfcu-support.phone': '(256) 867-1593',
  'app.ntfcu-support.placeholder.text.1': 'Debido al gran número de respuestas nuestra plataforma no recibirá nuevas solicitudes.',
  'app.ntfcu-support.placeholder.text.2': 'Le informaremos a nuestra membresía tan pronto podamos abrir una nueva recepción de solicitudes.',
  'app.ntfcu-support.placeholder.text.3': 'Por favor, sepa que informaremos en siete días a aquellos miembros que recibiran las donaciones.',
  'app.ntfcu-support.placeholder.text.4': 'Si usted aplico, pero no su nombre no entró en nuestra lista, lo invitamos a aplicar de nuevo tan pronto abramos la siguiente fase.',
  'app.ntfcu-support.placeholder.text.5' : 'Gracias!',

  //Emergency Response/Emergency Relief/Crisis Response/NTFCU Support - common validation error
  'app.emergency-response-flows.common.submission-too-long': 'Por favor acorte su respuesta (limitada a 250 caracteres).',
  'app.emergency-response-flows.common.error.selection-required': 'Por favor haz una selección.',
  'app.emergency-response-flows.common.error.multi-select-required': 'Seleccione al menos una opción.',
  'app.emergency-response-flows.common.error.text-entry-required': 'Por favor complete el campo requerido.',
  'app.emergency-response-flows.agreedToAuthorization.required': 'Por favor acepte a continuación',
  'app.emergency-response-flows.agreedToAuthorization.required.notice': 'Acepta la autorización del depósito de US Alliance',

  //Humanity Forward
  'app.humanity-forward.how-trustplus-coaches-can-help.along-with-your-emergency-relief-payment': 'Nuestra misión como organizaciones sin fines de lucro es la de brindarle orientación sobre lo que puede hacer por sus finanzas en estos tiempos inciertos. Nuestro objetivo ahora es ayudarle a hacer un plan para las próximas semanas y meses.',
  'app.humanity-forward.who-we-are.usalliance-financial': '{usallianceFinancial} es una institución financiera sin fines de lucro que ofrece una gama completa de productos como chequeras, cuentas de ahorro y prestamos con alcance nacional y con más de 120,000 miembros. Nuestra meta principal es darle el mejor servicio a nuestros miembros y superar las expectativas financieras únicas de nuestros miembros ofreciendo una cartera de productos que son fáciles de usar. También ayudan a nuestros miembros ahorrar, planificar para el futuro, conducir transacciones de día a día y para cada etapa de la vida.',
  'app.humanity-forward.usalliance-financial': 'USALLIANCE Financial',
  'app.humanity-forward.verify-your-identity.why-we-need-this-information': '*Por qué necesitamos esta información: esta oferta solo es elegible para miembros preseleccionados de la cooperativa. Utilizaremos su información de contacto para confirmar su participación. También podemos comunicarnos con usted sobre su participación y brindarle más apoyo.',
  'app.humanity-forward.questions.1.employment-status': '¿Cuál es su situación laboral?',
  'app.humanity-forward.questions.2.consider-other-options-for-cash-payment': 'Cuando reciba su pago de emergencia, es posible que quiera pagar la factura de mayor prioridad. Sin embargo, ¿cuál de los siguientes opciones podría considerar también:',
  'app.humanity-forward.questions.2.additional-recommendations':'Además de utilizar su pago Fondo de Ayuda de Vacaciones para estabilizarse, también le recomendamos que considere lo siguiente. Marque una o todas las opciones que consideraria:',
  'app.humanity-forward.authorize.title': '¡Ya casi terminas!',
  'app.humanity-forward.authorize.text': 'Autorice su pago a continuación',
  'app.humanity-forward.authorize.authorize-us-alliance-deposit': 'Por este medio autorizo US Alliance a depositar un pago de ayuda de vacaciones a la cuenta de acciones que actualmente tengo en US Alliance.',
  'app.humanity-forward.authorize.submit.button': 'Enviar/Someter',
  'app.humanity-forward.conclusion.subtitle': 'Su solicitud ha sido enviada',
  'app.humanity-forward.conclusion.text': '¡Gracias! Recibirá una notificación dentro de 5 días cuando se acepta su solicitud. Su efectivo se depositará en su cuenta.',
  'app.humanity-forward.placeholder.text.1': 'Gracias a todos nuestros miembros que ya han solicitado el Fondo de Ayuda de Vacaciones de Humanity Forward Foundation.' ,
  'app.humanity-forward.placeholder.text.2': 'Debido al gran número de respuestas nuestra plataforma no recibirá nuevas solicitudes.',

  //Alt Company login
  'app.alt-company-login.text-1': '¿Normalmente accede a TrustPlus a través del sitio web de su empleador? Puede iniciar sesión aquí:',
  'app.alt-company-login.login-text': "Inicio de sesión ",

  // B-flow
  'app.b-flow.email-form.title': 'Comience con ingresando su dirección de correo electrónico',
  'app.b-flow.email-form.instruction': ' ',
  'app.b-flow.email-form.logout.title': 'Establecer nueva contraseña',
  'app.b-flow.email-form.logout.instruction': 'Vuelva a iniciar sesión introduciendo su correo electrónico.',
  'app.b-flow.email-form.timeout.title': 'Desconectado debido a inactividad',
  'app.b-flow.email-form.timeout.instruction': 'Vuelva a iniciar sesión introduciendo su correo electrónico.',
  'app.b-flow.email-form.placeholder.email': 'correo electrónico',
  'app.b-flow.email-form.button.continue': 'Continuar',
  'app.b-flow.email-form.button.sso-employer': 'Login with your {employer} account',

  'app.b-flow.full-name-form.title': '¡Pareces nuevo aquí!',
  'app.b-flow.full-name-form.instruction': 'Ingrese su nombre y apellido.',
  'app.b-flow.full-name-form.placeholder.first-name': 'nombre',
  'app.b-flow.full-name-form.placeholder.last-name': 'apellidos',
  'app.b-flow.full-name-form.agreement.description': 'Acepto los {termsLink} & {privacyLink}.',
  'app.b-flow.full-name-form.agreement.terms-link': 'términos de servicio',
  'app.b-flow.full-name-form.agreement.privacy-link': 'política de privacidad',
  'app.b-flow.full-name-form.not-new': 'No soy nuevo. Pruebe con otro correo electrónico.',
  'app.b-flow.full-name-form.continue.button': 'Continuar',

  'app.b-flow.temp-code-form.title': '¡Gracias ',
  'app.b-flow.temp-code-form.instruction-1': 'Por favor verifique sus mensajes de texto para obtener un código de 6 dígitos e ingréselo a continuación.',
  'app.b-flow.temp-code-form.instruction-2': 'Quiero usar un número de teléfono móvil diferente.',
  'app.b-flow.temp-code-form.instruction-3': 'No recibí un código.',
  'app.b-flow.temp-code-form.instruction-resent.text-1': '¡Hemos reenviado un nuevo código de 6 dígitos a su número de teléfono móvil. Puede tomar unos momentos en llegar.',
  'app.b-flow.temp-code-form.instruction-resent.text-2': 'Cuando esté listo, ingrese el código de 6 dígitos a continuación.',
  'app.b-flow.temp-code-form.unsuccessful': 'Su inicio de sesión no tuvo éxito. Por favor compruebe su código temporal y vuelva a intentarlo.',
  'app.b-flow.temp-code-form.continue.button': 'Continuar',
  'app.b-flow.errors.tempcode.required': 'Código temporal es requerido.',
  // 'app.message.change-password.unsuccessful': 'Por favor compruebe su contraseña y vuelva a intentarlo.',
  // 'app.tempcode.resent.code.message' : '¿No recibió el código en su teléfono? ¡Lo siento por eso! Revise su correo electrónico ahora para el código.',
  'app.b-flow.temp-code-form.modal.title': 'El código que ingresó no es válido o ha caducado. ',
  'app.b-flow.temp-code-form.modal.link': 'Obtener código nuevo.',

  'app.b-flow.new-password-form.title': 'Ahora crea una contraseña.',
  'app.b-flow.new-password-form.instruction': 'Contraseña debe: ',
  'app.b-flow.new-password-form.rules-1': 'Ser al menos 8 caractere',
  'app.b-flow.new-password-form.rules-2': 'Contener letras mayúsculas y minúsculas',
  'app.b-flow.new-password-form.rules-3': 'No puede contener caracteres especiales',
  'app.b-flow.new-password-form.rules-4': 'Contener por lo menos un número',
  'app.b-flow.new-password-form.rules-5': 'Contraseñas deben coincidir',
  'app.b-flow.new-password-form.placeholder.password': 'contraseña',
  'app.b-flow.new-password-form.placeholder.re-enter-password': 're-escriba su contraseña',
  'app.b-flow.new-password-form.show-password': 'Mostrar contraseña',
  'app.b-flow.new-password-form.continue.button': 'Continuar',

  'app.b-flow.login-password-form.title': '¡Bienvenido de nuevo!',
  'app.b-flow.login-password-form.instruction': 'Ingresa su contraseña.',
  'app.b-flow.login-password-form.placeholder.password': 'contraseña',
  'app.b-flow.login-password-form.alt-link': '¿Ha olvidado su contraseña?',
  'app.b-flow.login-password-form.show-password': 'Mostrar contraseña',
  'app.b-flow.login-password-form.error.login-unsuccessful': 'Su inicio de sesión no tuvo éxito. Por favor compruebe su contraseña y vuelva a intentarlo.',
  'app.b-flow.login-password-form.continue.button': 'Continuar',

  'app.b-flow.forgot-password-form.title': '¿Ha olvidado su contraseña?',
  'app.b-flow.forgot-password-form.instruction': '¡No hay problema! We will send a message to your email address with a link to reset your password.',
  'app.b-flow.forgot-password-form.instruction2': 'Please check your email for the link to reset your password.',
  'app.b-flow.forgot-password-form.send.button': 'Send link',

  // 'app.b-flow.forgot-password-confirmation.instruction': '¡No hay problema! Le enviaremos un mensaje con un código temporal a su correo electrónico.',
  'app.b-flow.forgot-password-confirmation.instruction': 'Gracias por confirmar su correo electrónico. Ahora puede proceder a crear una nueva contraseña.',
  'app.b-flow.forgot-password-confirmation.continue.button': 'Continuar',

  'app.b-flow.appointment-select-coach.title': 'Comencemos seleccionando un consejero financiero para su sesión individual de 30 minutos.',
  'app.b-flow.appointment-select-coach.instruction.2': 'Seleccione un entrenador:',
  'app.b-flow.appointment-select-coach.text.skip': 'No deseo hablar con un entrenador en este momento.',
  'app.b-flow.appointment-select-coach.instruction.3': 'Seleccione un entrenador a la izquierda para leer más sobre ellos.',
  'app.b-flow.pull-credit-report.text.skip': 'Recuérdame más tarde',

  'app.b-flow.pick-a-time.title': 'Elige una hora',
  'app.b-flow.pick-a-time.instruction': 'Configura una sesión de 30 minutos con tu entrenador. Si su entrenador financiero seleccionado no está disponible, puede regresar y elegir uno diferente.',
  'app.b-flow.pick-a-time.change-coach.button': '< Volver a todos los entrenadores',
  'app.b-flow.pick-a-time.with': 'Elija una hora con ',

  'app.round-robin.pick-a-time.title': 'Comencemos seleccionando una sesión de entrenamiento financiero.',
  'app.round-robin.pick-a-time.instruction1': 'Un entrenador financiero será su guía a través de su viaje de bienestar financiero. Ellos podrán responder sus preguntas financieras.',
  'app.round-robin.pick-a-time.instruction2': 'Cada sesión durará 30 minutos. Todos los horarios son locales según su ubicación.',
  'app.round-robin.pick-a-time.select-by-availability': 'HORARIO POR DISPONIBILIDAD',
  'app.round-robin.pick-a-time.select-date-instruction': 'Seleccione una fecha específica en la que esté disponible y, a continuación, selecciónela de una lista de intervalos de tiempo.',
  'app.round-robin.pick-a-time.pull-cr': 'Puede aprovechar al máximo su sesión extrayendo su informe de crédito.',
  'app.round-robin.pick-a-time.dcl.accepted.title': 'Elimina tu deuda más rápido con una consolidación de deudas',
  'app.round-robin.pick-a-time.dcl.not-accepted.title': 'Explora la reducción de deuda con nuestros expertos consejeros financieros',
  'app.round-robin.pick-a-time.dcl.description': '¡Programa una consulta para comenzar tu camino hacia la consolidación de deudas!',
  'app.round-robin.pick-a-time.dcl.not-accepted.description': '¡Programa una consulta para comenzar con un experto consejero financiero!',
  'app.round-robin.pick-a-time.dcl.accepted.text-1': 'Basado en sus respuestas, ',
  'app.round-robin.pick-a-time.dcl.accepted.text-2': 'es posible que tengamos una solución ',
  'app.round-robin.pick-a-time.dcl.accepted.text-3': 'para tu deuda. Aquí están tus próximos pasos:',
  'app.round-robin.pick-a-time.dcl.accepted.text-4': ' Programa una consulta. ',
  'app.round-robin.pick-a-time.dcl.accepted.text-5': 'Tú y tu consejero financiero explorarán tu deuda en detalle y crearán un plan de acción.',
  'app.round-robin.pick-a-time.dcl.accepted.text-6': ' Obtén tu reporte de crédito. ',
  'app.round-robin.pick-a-time.dcl.accepted.text-7': 'Esto no afectará tu puntaje. Tu reporte de crédito es una herramienta esencial para determinar la mejor estrategia para reducir tus deudas.',
  'app.round-robin.pick-a-time.dcl.accepted.text-8': 'Nuestros expertos consejeros financieros le brindarán orientación y contestarán sus preguntas sobre consolidación de deudas.',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-1': '¡Gracias por responder las preguntas del preevaludaror!',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-2': 'Basado en tus respuestas, ',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-3': 'es posible que tenemos una solución ',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-4': 'para ti.',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-5': 'Programe una consulta hoy para explorar en más detalle y crear un plan de acción.',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-6': 'Al programar una consulta, tu consejero financiero responderá tus preguntas y trabajará contigo para que logres tus metas.',

  'app.b-flow.session-scheduled.additional-questions': 'Continuar',
  'app.b-flow.session-scheduled.pull-report.button': 'Quiero mi reporte de crédito',
  'app.b-flow.session-scheduled.title': 'Qué esperar en los siguientes pasos.',
  'app.b-flow.session-scheduled.description-1a': 'El día de la consulta, ',
  'app.b-flow.session-scheduled.description-1b': ' te enviará un mensaje de texto con el link de video o te llamará desde un número dedicado con el código de área 929. ',
  'app.b-flow.session-scheduled.description-2': 'Puede sacar más provecho de su sesión tomando algunos pasos adicionales para garantizar una experiencia completa y personalizada. Al proporcionar acceso a su informe de crédito y completar nuestro formulario de evaluación, nos permite obtener información valiosa sobre su perfil financiero.',
  'app.b-flow.session-scheduled.counselor-bubble.text': 'El día de la cita, te enviaré un enlace de videollamada o te llamaré desde mi número dedicado, que tiene un código de área 929.',
  'app.b-flow.session-scheduled.upcoming-session': 'Tu Próxima Consulta',
  'app.b-flow.session-scheduled.reschedule-link': 'Reprogramar esta sesión',

  'app.b-flow.credit-debt-self-evaluation.title':'Preguntas adicionales',
  'app.b-flow.credit-debt-self-evaluation.text.details-without-credit-report': 'Las siguientes preguntas no son necesarias, pero nos ayudarán a construir su perfil de crédito para proporcionar una mejor comprensión sobre su informe de crédito.',
  'app.b-flow.credit-debt-self-evaluation.text.details-with-credit-report': 'Antes de mostrarles un imagen de su crédito y deuda, nos encantaría saber cómo ve usted su situación de crédito y deuda a partir de hoy.',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.question': '¿Cuál de las siguientes declaraciones describe qué manejable su deuda familiar (incluyendo hipotecas, préstamos estudiantiles, préstamos bancarios, dinero debido a la gente, deuda médica, tarjetas de crédito y cuentas atrasadas)?',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.': 'Seleccione una de las siguientes',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.Do not have any debt': 'No tengo ninguna deuda',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.Have a manageable amount of debt': 'Tengo una cantidad manejable de deuda',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.Have a bit more debt than is manageable': 'Tengo un poco más de deuda de lo que es manejable',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.Have far more debt than is manageable': 'Tengo mucho más deuda de lo que es manejable',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.question': '¿Cómo calificaría su puntaje de crédito?Su puntuación de crédito es un número que le dice a los prestamistas lo arriesgado o seguro que es como un prestatario.',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.': 'Seleccione una de las siguientes',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Excellent': 'Excelente',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Very Good': 'Muy bien',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Good': 'Bueno',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Fair': 'Justo',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Poor': 'Pobre',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.I do not know': 'No lo sé',
  'app.b-flow.credit-debt-self-evaluation.button.go-to-credit-report': 'Quiero mi reporte de crédito',
  'app.b-flow.credit-debt-self-evaluation.button.continue': 'Continuar',

  'app.b-flow.credit-report-form-page.title': 'Aprovecha al máximo tu primera consulta obteniendo tu reporte de crédito (no afectará tu puntaje de crédito).',
  'app.b-flow.credit-report-form-page.text': 'Al obtener tu reporte de crédito, tú y tu consejero financiero podrán hablar sobre tus finanzas en más detalle y crearán un plan de acción para que puedas reducir tus deudas.',
  'app.b-flow.credit-report-form-page.text-2': 'Todos los campos son obligatorios.',
  'app.b-flow.credit-report-form-page.button.continue': 'Continuar',
  'app.b-flow.credit-report-form-page.dcl.title': 'Último paso: Obtén tu reporte de crédito (no afectará tu puntaje)',
  'app.b-flow.credit-report-form-page.dcl.text': 'Al obtener tu reporte de crédito, tú y tu consejero financiero podrán hablar sobre tus finanzas en más detalle y crearán un plan de acción para que puedas reducir tus deudas.',

  'app.b-flow.additional-questions.title':'Preguntas adicionales',
  'app.b-flow.additional-questions.progress-bar': 'Progreso hacia el informe de crédito',

  'app.saml-relay.title': 'Logging you in...',

  // Customer Portal access items
  'app.customer-portal-common.back-link': "\< Back",

  'app.cp-flow.login-form.title': 'Welcome!',
  'app.cp-flow.login-form.intro': 'Log in by entering the email address you used when scheduling a demo.',
  'app.cp-flow.password-form.title': 'Welcome back!',
  'app.cp-flow.password-form.instruction': 'Enter your password.',
  'app.cp-flow.login-password-form.alt-link': 'Forgot your password?',
  'app.cp-flow.temp-code-form.title': 'Welcome!',
  'app.cp-flow.temp-code-form.instructions': 'Please verify your email address by entering the one-time code we just sent to it.',
  'app.cp-flow.temp-code-form.instructions-2': '(It may take a minute+ to arrive.)',
  'app.cp-flow.create-password-form.title': 'Finally, make a password.',
  'app.cp-flow.forgot-password-form.title': 'Forgot password?',
  'app.cp-flow.forgot-password-form.instruction': 'No problem! We will send a message to your email address with a link to reset your password.',
  'app.cp-flow.forgot-password-form.instruction2': 'Please check your email for the link to reset your password.',
  'app.cp-flow.forgot-password-form.send.button': 'Send link',
  'app.cp-flow.create-pass-form.agreement.description': 'I agree to the {termsLink} and {privacyLink}.',
  'app.cp-flow.create-pass-form.agreement.terms-link': 'Terms of Service',
  'app.cp-flow.create-pass-form.agreement.privacy-link': 'Privacy Policy',

  // Customer Portal Nav items
  'app.customer-portal.dashboard-header': 'Dashboard',
  'app.customer-portal-nav.contracts': 'Contratos ',
  'app.customer-portal-nav.dashboard': 'Página principal',
  'app.customer-portal-nav.invoices':'Facturas',
  'app.customer-portal-nav.messages':'Mensajes',
  'app.customer-portal-nav.account':'Mi Cuenta',
  'app.customer-portal-nav.reports':'Reports',
  'app.customer-portal-nav.onboarding':'Incorporar',
  'app.customer-portal-nav.faq':'FAQ/Preguntas Frecuentes',
  'app.customer-portal-nav.files': 'Files',

  // Customer Portal Page items
  'app.customer-portal-home.welcome-name': 'Welcome, ',
  'app.customer-portal-home.intro':'Congratulations on taking meaningful steps to maximize the financial health of your workers.',  'app.customer-portal-home.progress-heading': 'Your Sign-Up Progress',
  'app.customer-portal-home.progress-sub-heading': 'Just a Few More Steps!' ,
  'app.customer-portal-home.progress-view-steps': 'View all steps',
  'app.customer-portal-home.back-to-progress': 'Back to your progress',
  'app.customer-portal-home.progress-next-step': 'Next Step:',
  'app.customer-portal-home.progress-step-1': 'Step 1:',
  'app.customer-portal-home.progress-step-2': 'Step 2:',
  'app.customer-portal-home.progress-step-3': 'Step 3:',
  'app.customer-portal-home.progress-step-1-instructions': 'Create an account (Done)',
  'app.customer-portal-home.progress-step-2-instructions': 'Generate and sign contract',
  'app.customer-portal-home.progress-step-3-instructions': 'Onboard',
  'app.customer-portal-home.progress-step-4-instructions': 'Generate and sign contract (Done)',
  'app.customer-portal-home.progress-step-5-instructions': 'Onboard (Done) ',
  'app.customer-portal-home.progress-generate-contract': 'Generate Contract here',
  'app.customer-portal-home.onboarding-heading': 'What happens after TrustPlus sign-up is complete?',
  'app.customer-portal-home.onboarding-sub-heading': 'Employee Onboarding',
  'app.customer-portal-home.onboarding-text-1': 'Shortly after signing up, the onboarding process can begin for your employees. At this stage, we will put you in touch with one of our Relationship Managers (RM), who will be your primary TrustPlus contact. The RM will schedule a call with you so you can create a launch plan together!',
  'app.customer-portal-home.onboarding-btn-1': 'Visit Onboarding Page',
  'app.customer-portal-home.onboarding-heading-2': 'It’s time to onboard your team!',
  'app.customer-portal-home.onboarding-sub-heading-2a': 'Meet Your Relationship Manager',
  'app.customer-portal-home.onboarding-text-2': 'Meet your new Relationship Manager, Brendon Kinney! Brendon will be your primary TrustPlus contact. Keep an eye out for a message from him soon, where he will schedule a call to get you started!',
  'app.customer-portal-home.progress-sign-up-complete-header': 'Sign-up Is Complete',
  'app.customer-portal-home.progress-sign-up-complete-text': 'Your Relationship Manager will get in touch with you shortly to create a launch plan.',
  'app.customer-portal.contracts-header': 'Contracts',
  'app.customer-portal-contracts.heading-1': 'Start the seamless contract process below.',
  'app.customer-portal-contracts.text-1a': 'TrustPlus makes it easy to help your employees ease the stress of the everyday money worries that hamper wellness and productivity.',
  'app.customer-portal-contracts.text-1b': 'Ready to invest in the financial health of your workers?',
  'app.customer-portal-contracts.btn-1': 'Generate Your Contract',
  'app.customer-portal-contracts.heading-2': 'You’ve signed your contract look for an email regarding your invoice soon.',
  'app.customer-portal-contracts.text-2a': 'Milly has shared your contract with the Finance team so that they can process your invoice. Keep an eye out for an email from bill.com in about __________ days/weeks. That email will have a link to pay your invoice directly. If you have any questions in the meantime, please feel free to contact Milly.',
  'app.customer-portal-contracts.heading-3': 'You’re up to date!',
  'app.customer-portal-contracts.text-3a': 'You have already signed your contract and onboarded with TrustPlus—there’s nothing you need to do on this screen!',
  'app.customer-portal-contracts.text-3b': 'If you’d like to view your contract history, please navigate to My Accounts.',
  'app.customer-portal-contracts.btn-3': 'Go to My Accounts',
  'app.customer-portal-contracts.about-you.heading': 'Generate your contract',
  'app.customer-portal-contracts.about-you.text-1': 'Begin generating your TrustPlus contract by completing the fields below.',
  'app.customer-portal-contracts.about-you-text-2': 'Please note that some fields will be filled in already, based on the questionnaire answers you submitted before getting a demo.',
  'app.customer-portal-contracts.progress-indicator-heading': 'This progress indicator lets you know where you are in the contract process:',
  'app.customer-portal-contracts.progress-bar-step-title.title-1': 'About',
  'app.customer-portal-contracts.progress-bar-step-title.title-2': 'Your Employees',
  'app.customer-portal-contracts.progress-bar-step-title.title-3a': 'Contract ',
  'app.customer-portal-contracts.progress-bar-step-title.title-3b': 'Details',
  'app.customer-portal-contracts.progress-bar-step-title.title-4a': 'Preview ',
  'app.customer-portal-contracts.progress-bar-step-title.title-4b': 'Contract',
  'app.customer-portal-contracts.progress-bar-step-title.title-5a': 'Sign ',
  'app.customer-portal-contracts.progress-bar-step-title.title-5b': 'Contract',
  'app.customer-portal-contracts.progress-bar-step-title.title-6a': 'Submit ',
  'app.customer-portal-contracts.progress-bar-step-title.title-6b': 'Contract',
  'app.customer-portal-contracts.form.step-1.title': 'About the Primary Contact and Signor for the Organization',
  'app.customer-portal-contracts.form.step-1.intro-text': 'Please complete the following sections',
  'app.customer-portal-contracts.form.footer-text-1': 'If anything looks incorrect, please ',
  'app.customer-portal-contracts.form.footer-text-2': 'contact Milly',
  'app.customer-portal-contracts.form.footer-text-3': ' to make changes.',
  'app.customer-portal-contracts.form.common.intro-text-2': ' If anything looks incorrect, please ',
  'app.customer-portal-contracts.form.common.intro-text-3': 'contact Milly',
  'app.customer-portal-contracts.form.common.intro-text-4': ' to make changes.',
  'app.customer-portal-contracts.form.about.intro-text-1': 'Please complete the following sections, representing the primary contact and signor.',
  'app.customer-portal-contracts.form.about.select':'Please select',
  'app.customer-portal-contracts.form.employees.intro-text-1': 'This is our understanding of the number of employees who will participate.',
  'app.customer-portal-contracts.form.employees.table-header-left': 'Number of Employees',
  'app.customer-portal-contracts.form.employees.table-header-right': 'Cost per Employee',
  'app.customer-portal-contracts.form.details.intro-text-1': 'Your contract includes two webinars. Do you want to add any additional webinars?',
  'app.customer-portal-contracts.form.details.table-header-left': 'Your selection',
  'app.customer-portal-contracts.form.details.table-header-center': 'Number of Extra Webinars',
  'app.customer-portal-contracts.form.details.table-header-right-1': 'Cost: $',
  'app.customer-portal-contracts.form.details.table-header-right-2': '/each',
  'app.customer-portal-contracts.form.details.none': 'None',
  'app.customer-portal-contracts.form.details.contract-length': 'Length of Contract',
  'app.customer-portal-contracts.form.details.contract-length-unit': ' months',
  'app.customer-portal-contracts.form.details.contract-start-date': 'Contract Start Date',
  'app.customer-portal-contracts.detail.preview.contract.button':'Preview Contract',
  'app.customer-portal-contracts.about.organization-type.answer.Association': 'Association',
  'app.customer-portal-contracts.about.organization-type.answer.Corporation': 'Corporation',
  'app.customer-portal-contracts.about.organization-type.answer.Credit Union': 'Credit Union',
  'app.customer-portal-contracts.about.organization-type.answer.Employer Solution Organization': 'Employer Solution Organization',
  'app.customer-portal-contracts.about.organization-type.answer.Foundation': 'Foundation',
  'app.customer-portal-contracts.about.organization-type.answer.Government': 'Government',
  'app.customer-portal-contracts.about.organization-type.answer.Individual': 'Individual',
  'app.customer-portal-contracts.about.organization-type.answer.Media': 'Media',
  'app.customer-portal-contracts.about.organization-type.answer.Nonprofit': 'Nonprofit',
  'app.customer-portal-contracts.about.organization-type.answer.Small Business': 'Small Business',
  'app.customer-portal-contracts.about.organization-type.answer.Other': 'Other',
  'app.customer-portal-contracts.form.footer.contract-details': 'Our base implementation fee of $3,000 generally includes co-branded marketing, quarterly reporting, and our standard financial coaching services. This fee is subject to change if the employer requires service customizations (i.e. in-person presence or customized reporting).',
  'app.customer-portal-home.contracts.jump-to-bottom': 'Jump to Bottom',
  'app.customer-portal-home.contracts.jump-to-top': 'Jump Back to Top',
  'app.customer-portal-home.contracts.miss-something': 'Miss something?',
  'app.customer-portal-home.contracts.miss-something-2': 'and use the progress bar to navigate to previous screens.',
  'app.customer-portal-contracts.form.step-5.intro-text': 'By typing your name below, you are signing the contract and agreeing to the Terms of Service.',
  'app.customer-portal-home.contracts.sign-contracts': 'By clicking “Submit Contract” above, you are signing the contract and agreeing to the Terms of Service.',
  'app.customer-portal.contracts-submit-contract': 'Submit Contract',
  'app.customer-portal.contracts.submit-thank-you': 'Thank you for signing your TrustPlus contract!',
  'app.customer-portal.contracts-download': 'Download Contract',
  'app.customer-portal.contracts.download-text': 'Check the Downloads folder on your computer or device.',
  'app.customer-portal-contracts.submit-title-1': 'What happens next?',
  'app.customer-portal-contracts.submit-subtitle-1': 'Onboarding',
  'app.customer-portal-contracts.submit-subtitle-2': 'Invoicing',
  'app.customer-portal-contracts.submit.text-1': 'Navigate to Onboarding to begin uploading your documents. Starting this process now will allow your team to receive Financial Coaching as soon as the contract begins, if they would like to.',
  'app.customer-portal-contracts.submit.text-2': 'Our Finance team will reach out to you soon with an invoice. Please note that if your start date is not for a few months, you will receive the invoice closer to that date.',
  'app.customer-portal.contracts.go-to-onboarding': 'Go to Onboarding',
  'app.customer-portal-contracts.progress-bar-details.title': 'Progress Indicator Details',
  'app.customer-portal-contracts.progress-bar-details.title-2': 'Color Key',
  'app.customer-portal-contracts.progress-bar-details.text-1': 'This progress indicator lets you know where you are in the contract process.',
  'app.customer-portal-contracts.progress-bar-details.text-2': 'Not done',
  'app.customer-portal-contracts.progress-bar-details.text-3': 'Complete',
  'app.customer-portal-contracts.progress-bar-details.text-4': 'Each number represents the following:',
  'app.customer-portal-contracts.progress-bar-details.text-5': 'Select number of webinars',
  'app.customer-portal-contracts.progress-bar-details.text-6': 'Review contract offering/pricing',
  'app.customer-portal-contracts.progress-bar-details.text-7': 'Contract duration',
  'app.customer-portal-contracts.progress-bar-details.text-8': 'Contract start date',
  'app.customer-portal.messaging-header': 'Messaging',
  'app.customer-portal-messaging.inbox': 'Inbox',
  'app.customer-portal-messaging.inbox.text-1': 'Have a question? View and send messages to your Relationship Manager (RM) here!',
  'app.customer-portal-messaging.sent': 'Sent',
  'app.customer-portal-messaging.new': 'New',
  'app.customer-portal-messaging.compose': 'Compose New Message',
  'app.customer-portal-messaging.sent.sent-exclaim': 'Sent!',
  'app.customer-portal-messaging.btn-1': 'Send Message',
  'app.customer-portal.my-account-header':'My Account',
  'app.customer-portal.my-account-heading-1':'Use this page to manage your settings and security',
  'app.customer-portal.my-account.settings': 'Settings',
  'app.customer-portal.my-account.text-1': 'Update your name and contact information',
  'app.customer-portal.my-account.security': 'Security',
  'app.customer-portal.my-account.text-2': 'Change your password',
  'app.customer-portal.my-account.name': 'Name',
  'app.customer-portal.my-account.first-name': 'First Name',
  'app.customer-portal.my-account.last-name': 'Last Name',
  'app.customer-portal.my-account.phone': 'Phone Number',
  'app.customer-portal.my-account.email': 'Email Address',
  'app.customer-portal.my-account.name-confirmation': 'Your name has been updated.',
  'app.customer-portal.my-account.email-confirmation': 'Your email has been updated.',
  'app.customer-portal.my-account.name-text-1': 'Below is the contact information we have on file.',
  'app.customer-portal.my-account.name-text-2': 'To change it, please enter your updated info directly in the boxes.',
  'app.customer-portal.my-account.name-text-3': 'Your name has been updated.',
  'app.customer-portal.my-account.phone-text-1': 'Below is the phone number we have on file.',
  'app.customer-portal.my-account.phone-text-2': 'To change it, please enter an updated number directly in the box.',
  'app.customer-portal.my-account.phone-text-3': 'Your phone number has been updated.',
  'app.customer-portal.my-account.email-text-1': 'Below is the email address we have on file.',
  'app.customer-portal.my-account.email-text-2': 'To change it, please enter an updated email address diectly in the box.',
  'app.customer-portal.my-account.email-text-3': 'Your email address has been updated.',
  'app.customer-portal.my-account.security-text-1': 'Use this area to update your password.',
  'app.customer-portal.my-account.security-text-2': 'Confirm your current password in the first box.',
  'app.customer-portal.my-account.security-text-3': 'Add a new password in the second box.',
  'app.customer-portal.my-account.security-text-4': 'Passwords must:',
  'app.customer-portal.my-account.security-text-5': 'Be at least 8 characters',
  'app.customer-portal.my-account.security-text-6': 'Contain upper and lower case letters',
  'app.customer-portal.my-account.security-text-7': 'Have at least one special character (!, @, #, etc.)',
  'app.customer-portal.my-account.security-text-8': 'Your password has been updated.',
  'app.customer-portal.my-account.security-current-password': 'Current password',
  'app.customer-portal.my-account.security-new-password': 'New password',
  'app.customer-portal.my-account.security-confirm-password': 'Confirm password',

  //Customer Portal Footer
  'app.customer-portal-footer.text-1':'If you have questions at any time, you can contact Sales here!',
  'app.customer-portal-footer.text-2':'contact',

  //  Customer Portal Demo items
  'app.customers.welcome': 'Please provide the following information to schedule your demo:',
  'app.customers.schedule-a-demo.input-name': 'Name',
  'app.customers.schedule-a-demo.input-title': 'Title',
  'app.customers.schedule-a-demo.input-email': 'Email',
  'app.customers.schedule-a-demo.input-company-name': 'Employer name',
  'app.customers.schedule-a-demo.input-size-of-workforce': 'Number of employees',
  'app.errors.customers.schedule-a-demo.name': 'Name is required',
  'app.errors.customers.schedule-a-demo.employer-name': 'Employer name is required',
  'app.errors.customers.schedule-a-demo.number-of-employees': 'Number of employees is required',
  'app.customers.schedule-a-demo.button.continue': 'Continue',

  // Customer Portal Onboarding
  'app.customers.onboarding.title': 'Onboarding',
  'app.customers.onboarding.upload-documents.title': 'Begin uploading your documents',
  'app.customers.onboarding.upload-documents.description': 'By providing all of the recommended materials below you enable TrustPlus to provide you with the best possible experience including co-branded marketing materials and direct worker outreach to maximize engagement and impact.',
  'app.customers.onboarding.supported-file-types.title': 'Supported file types:',
  'app.customers.onboarding.supported-file-types.microsoft': 'Microsoft Office documents',
  'app.customers.onboarding.supported-file-types.adobe': 'Adobe PDFs',
  'app.customers.onboarding.supported-file-types.images': 'Images (.jpeg, .jpg, .png, .gif)',
  'app.customers.onboarding.button.upload': 'Upload',
  'app.customers.onboarding.button.replace': 'Replace',
  'app.customers.onboarding.logo-header': 'Your company logo (required)',
  'app.customers.onboarding.census-header': 'Employee census file',
  'app.customers.onboarding.census-description': 'An employee census file is a list of your workers\' and email addresses. By providing this list, TrustPlus can reach out with instructions about signing up, as well as provide tips from our financial coaches throughout the year.',
  'app.customers.onboarding.benefits-overview-header': 'Employee benefits overview or summary',
  'app.customers.onboarding.benefits-overview-description': 'An employee benefits overview or summary is a detailed file or brochure outlining the benefits and resources your company offers its workers. By uploading it here, our TrustPlus coaches can share these benefits and resources with your workers during a financial coaching session.',

}

export default es
