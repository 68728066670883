import React from 'react'

import NoEmployerCode from '../components/no-employer-code'
import LandingPagesHeader from "./landing-pages/landing-pages-header";
import LandingPagesHowHelped from "./landing-pages/landing-pages-how-helped";
import LandingPagesHelpYou from "./landing-pages/landing-pages-help-you";
import LandingPagesTagline from "./landing-pages/landing-pages-tagline";
import LandingPagesFooter from "./landing-pages/landing-pages-footer";

export default class LandingRent extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      displayBlueHeader: false
    }

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if (window.scrollY >= 50 && this.state.displayBlueHeader === false) {
      this.setState({displayBlueHeader: true})
    }
    if (window.scrollY < 50 && this.state.displayBlueHeader === true) {
      this.setState({displayBlueHeader: false})
    }
  };

  goToAppointmentSchedule() {
    this.props.history.push('/enter-your-email-address')
  }

  goToWelcomePage() {
    this.props.history.push(`/welcome`)
  }

  goToAskQuestion() {
    this.props.history.push('/ask-question')
  }

  goToTrustPlus() {
    this.props.history.push(`/${this.props.employer.code}/trustplus-credit`)
  }

  render(){
    return (
      this.props.employer && <div id='introduction-propel'>
        {this.props.employer.code === 'err' ?
          <NoEmployerCode/>
          :
          <div>

            <div className='intro-tax-hero intro-rent-hero'>
              <span role="img" aria-label='An image of an old New York building.'>
              </span>
              <LandingPagesHeader displayBlueHeader={this.state.displayBlueHeader}
                                  goToWelcomePage={() => this.goToWelcomePage()}
                                  goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
              />

              <div className='intro-content-landing-page-wrapper landing-page-propel-intro-wrapper landing-taxes-intro-wrapper landing-rent-intro-wrapper'>
                <div className='whitegrad-box taxes-box'>
                  <div className='welcome-landing-page welcome-landing-page-propel welcome-landing-page-taxes'>
                    Evictions are paused. The rent isn’t.
                  </div>
                  <div className='welcome-landing-page welcome-landing-page-propel-subtitle welcome-taxes-page-propel-subtitle'>
                    Make a plan to catch up on any unpaid rent or bills. Speak with a TrustPlus financial coach via phone or video chat.
                  </div>

                  <div className='submit-button-round btn-propel-landing'>
                    <button className='btn btn-primary btn-block btn-propel-landing'
                            id='submit'
                            onClick={this.goToAppointmentSchedule.bind(this)}>
                      Find a time
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <LandingPagesHowHelped/>

            <LandingPagesHelpYou goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
                                 goToAskQuestion={() => this.goToAskQuestion()}
                                 goToTrustPlus={() => this.goToTrustPlus()}
            />

            <LandingPagesTagline goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
            />

            <LandingPagesFooter/>

          </div>
        }
      </div>
    )
  }
}
