import React from 'react'

export const DclNotFit = ({
                              style = {},
                              fill = '#fff',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 420 435',
                          }) =>
    <svg
        width={width}
        height={height}
        viewBox="0 0 155 155"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
    >
        <ellipse cx="77.5" cy="77.6953" rx="77.5" ry="77" fill="#EF3E42"/>
        <path
            d="M112.413 36.8746V51.9721C112.413 60.1661 105.772 66.8702 97.6552 66.8702H97.4112L102.495 79.3693C102.611 79.6509 102.129 79.8562 101.856 79.645L85.3437 66.8702H66.9665C58.8499 66.8702 52.209 60.1661 52.209 51.9721V36.8746C52.209 28.6807 58.8499 21.9766 66.9665 21.9766H97.6552C101.879 21.9766 105.702 23.7948 108.398 26.6923C110.885 29.3611 112.413 32.9448 112.413 36.8746Z"
            fill="white" stroke="#EF3E42" stroke-width="2" stroke-linejoin="round"/>
        <path d="M65.9141 33.8516H99.9494" stroke="#EF3E42" stroke-width="2" stroke-miterlimit="10"
              stroke-linecap="round"/>
        <path d="M65.9141 43.1562H99.9494" stroke="#EF3E42" stroke-width="2" stroke-miterlimit="10"
              stroke-linecap="round"/>
        <path d="M65.9141 52.4531H99.9494" stroke="#EF3E42" stroke-width="2" stroke-miterlimit="10"
              stroke-linecap="round"/>
        <path
            d="M51.2613 103.207C56.3697 103.207 60.5109 99.026 60.5109 93.8689C60.5109 88.7119 56.3697 84.5312 51.2613 84.5312C46.1529 84.5312 42.0117 88.7119 42.0117 93.8689C42.0117 99.026 46.1529 103.207 51.2613 103.207Z"
            fill="white"/>
        <path
            d="M31.8906 124.877V121.241C31.8906 112.466 38.9382 105.352 47.6301 105.352H54.8926C63.5845 105.352 70.6321 112.466 70.6321 121.241V124.877C70.6321 126.35 69.4468 127.546 67.9885 127.546H34.5342C33.0759 127.546 31.8906 126.35 31.8906 124.877Z"
            fill="white"/>
        <path
            d="M101.908 103.207C107.016 103.207 111.157 99.026 111.157 93.8689C111.157 88.7119 107.016 84.5312 101.908 84.5312C96.7994 84.5312 92.6582 88.7119 92.6582 93.8689C92.6582 99.026 96.7994 103.207 101.908 103.207Z"
            fill="#EF3E42" stroke="white" stroke-width="2"/>
        <path
            d="M82.5371 124.877V121.241C82.5371 112.466 89.5847 105.352 98.2766 105.352H105.539C114.231 105.352 121.279 112.466 121.279 121.241V124.877C121.279 126.35 120.093 127.546 118.635 127.546H85.1865C83.7282 127.546 82.5429 126.35 82.5429 124.877H82.5371Z"
            fill="#EF3E42" stroke="white" stroke-width="2"/>
        <path
            d="M42.1406 51.0007V57.1887C42.1406 63.8459 47.5324 69.2889 54.1268 69.2889H54.3243L50.1934 79.4419C50.1004 79.6707 50.4897 79.8349 50.7105 79.6648L64.1201 69.2948H79.0461C85.6405 69.2948 91.0322 63.8517 91.0322 57.1945V51.0065C91.0322 44.3493 85.6405 38.9062 79.0461 38.9062H54.1268C50.693 38.9062 47.5905 40.3843 45.4001 42.7363C43.384 44.9065 42.1406 47.8158 42.1406 51.0065V51.0007Z"
            fill="#EF3E42" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M52.3672 49.5312H81.0631" stroke="white" stroke-width="2" stroke-miterlimit="10"
              stroke-linecap="round"/>
        <path d="M52.3672 58.1406H81.0631" stroke="white" stroke-width="2" stroke-miterlimit="10"
              stroke-linecap="round"/>
    </svg>

export default DclNotFit