import React from 'react'
import {Field} from 'redux-form'
import FormattedMessage from './FormattedMessage'

export const CheckboxOther = ({name, i18nPrefix, onChange}) => (
  <div className='tos-checkbox-container'>
    <div className='checkbox'>
      <label className='checkbox-inline'>
        <Field name={name} component='input' type='checkbox' onChange={onChange} />
        <span className='tos-checkmark'></span>
      </label>
      <span className='tos-checkmark-text'>
        <FormattedMessage id={i18nPrefix+name}/>
      </span>
    </div>
  </div>
)

export default CheckboxOther
