import React from 'react'
import FormattedMessage from "../helpers/FormattedMessage";

import Icon from "../../../resources/icons";
import _ from "lodash";

export default class OpportunityFundInactive extends React.Component {
  constructor(props){
    super(props);
  }

    goHome(){
      this.props.history.push('/0000')
    }

  goToWelcomePage() {
    this.props.history.push(`/welcome`)
  }

  determineNextLocation(){
    if (this.employerDefaultsToAskACoach()){
      this.props.history.push(`/ask-question`)
    } else {
      this.goToAppointmentSchedule().bind(this)
    }
  }

  goToAppointmentSchedule() {
    if (_.isEmpty(this.props.match.params.employerCode)) {
      return this.props.updateNoCodeEmployer("noCode").then(() => {
        this.props.history.push(`/err/appointment-schedule`)
      })
    } else {
      this.props.history.push(`/enter-your-email-address`)
    }
  }

  employerDefaultsToAskACoach(){
    return (!_.isEmpty(this.props.match.params.employerCode) && (
      this.props.match.params.employerCode.toLowerCase() === 'u628' ||
      this.props.match.params.employerCode.toLowerCase() === 'nz37'))
  }

  onLocalToggle(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.props.onLocaleToggle(this.props.locale);
    this.setState({
      changedLanguage: true
    })
  }

  render() {
    const name = this.props.employer.displayName
    return (
        <div className='partner-crisis-response-inactive'>
          <div className='landing-container landing-intro-container margin-bottom-16-px'>
            <div className='landing-header-links-wrapper'>

              <div className='nav-logo-landing-page'>
                <div className='nav-logo-landing-page-desktop'>
                  <Icon name="tp-full-logo"/>
                </div>
                <div className='nav-logo-landing-page-mobile'>
                  <Icon name="tp-logo"/>
                </div>
              </div>

              <div className='landing-header-links-right'>
                <div className='spanish-link-landing-page'>
                  <button className='locale-link-onboarding-home'
                          onClick={e => this.onLocalToggle(e)}>
                    <FormattedMessage id='app.introduction.locale-link'/>
                  </button>
                </div>
                <div className='log-on-landing-page'>
                  <button className='login-link-onboarding-home'
                          onClick={this.goToWelcomePage.bind(this)}>
                    <FormattedMessage id='app.landing-page.introduction.log-on.button'/>
                  </button>
                </div>
                <div className='sign-up-landing-page'>
                  <button className='btn-white-outline btn-black-outline'
                          onClick={()=>this.determineNextLocation()}>
                    {this.employerDefaultsToAskACoach() ?
                      <FormattedMessage id='app.landing-page.tagline.get-started.button'/>
                      :
                      <FormattedMessage id='app.landing-page.introduction.sign-up.button-white'/>
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='partner-crisis-response-inactive-background-container'>
            <div className='landing-container landing-intro-container'>
              <div className='container er-smaller-container registration-form'>
                <h3><FormattedMessage id='app.partner-crisis-response.program-inactive.title'/></h3>
                  <div className='emergency-response-text'>
                    <FormattedMessage id='app.partner-crisis-response.program-inactive.text' values={{ employerName: name != null ? name : 'NO DISPLAY NAME-0000'}}/>
                  </div>
                <div className='submit-button-round margin-bottom-24-px'>
                    <button id='return-home'
                            className='btn btn-primary btn-block btn-lg'
                            onClick={()=>this.goHome()}
                    >
                        <FormattedMessage id='app.partner-crisis-response.program-inactive.btn.return-to-home'/>
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>)
  }
}
