import {connect} from 'react-redux'
import {toggleLocale} from '../actions/locale-actions'
import {injectIntl} from 'react-intl'
import LandingSevenSevenFive from "../components/landing-seven-seven-five";


function mapStateToProps(state) {
  return {
    locale: state.locale,
    employer: state.employer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle(currentLocale) {
      dispatch(toggleLocale(currentLocale))
    },
  }
}

const LandingSevenSevenFiveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LandingSevenSevenFive))

export default LandingSevenSevenFiveContainer
