import React from 'react'

export const DclSaveMoney = ({
                                         style = {},
                                         fill = '#fff',
                                         width = '100%',
                                         className = '',
                                         height = '100%',
                                         viewBox = '0 0 200 200',
                                       }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <circle cx="100" cy="100" r="100" fill="#003057"/>
        <g>
          <g>
            <path d="M59.52,121.28c1.25-6.26,4.38-11.47,9.39-15.63,5.01-4.16,10.82-6.23,17.42-6.23h21.86c.8,0,1.99.11,3.59.34h.34c1.59-1.82,3.44-3.25,5.55-4.27,2.11-1.02,4.41-1.54,6.92-1.54h5.47l-3.25,12.98c2.73,2.5,4.9,5.47,6.49,8.88h4.95c.8,0,1.45.26,1.96.77s.77,1.17.77,1.96v21.86c0,.8-.26,1.45-.77,1.96-.51.51-1.17.77-1.96.77h-8.37c-1.48,2.05-3.25,3.81-5.29,5.29v13.83c0,.8-.26,1.45-.77,1.96-.51.51-1.17.77-1.97.77h-10.93c-.8,0-1.45-.26-1.96-.77-.51-.51-.77-1.17-.77-1.96v-8.2h-21.86v8.2c0,.8-.26,1.45-.77,1.96-.51.51-1.17.77-1.96.77h-10.93c-.8,0-1.45-.26-1.97-.77-.51-.51-.77-1.17-.77-1.96v-13.83c-3.42-2.51-6.09-5.66-8.03-9.48-1.94-3.81-2.9-7.89-2.9-12.21l.51-5.47ZM122.47,119.45c.8,0,1.45-.26,1.96-.77s.77-1.17.77-1.96-.26-1.45-.77-1.96c-.51-.51-1.17-.77-1.96-.77s-1.45.26-1.96.77c-.51.51-.77,1.17-.77,1.96s.26,1.45.77,1.96c.51.51,1.17.77,1.96.77" fill="none" stroke="#00c7b1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <g>
              <path d="M112.94,78.36c-.72,6.25-6.75,11.76-13.47,12.3-6.71.54-11.57-4.09-10.85-10.34.72-6.25,6.75-11.76,13.46-12.3,6.72-.54,11.57,4.09,10.85,10.34" fill="#fff"/>
              <path d="M112.94,78.36c-.72,6.25-6.75,11.76-13.47,12.3-6.71.54-11.57-4.09-10.85-10.34.72-6.25,6.75-11.76,13.46-12.3,6.72-.54,11.57,4.09,10.85,10.34Z" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
              <path d="M114.26,79.77c-.72,6.25-6.75,11.76-13.47,12.3-6.71.54-11.57-4.09-10.85-10.34.72-6.25,6.75-11.76,13.47-12.3,6.71-.54,11.57,4.09,10.85,10.34" fill="#00c7b1"/>
              <path d="M114.26,79.77c-.72,6.25-6.75,11.76-13.47,12.3-6.71.54-11.57-4.09-10.85-10.34.72-6.25,6.75-11.76,13.47-12.3,6.71-.54,11.57,4.09,10.85,10.34Z" fill="none" stroke="#003057" stroke-miterlimit="10" stroke-width="2"/>
              <path d="M84.81,82.41l-9.66-25.61s-3.57,1.58-4.2,2.24c-2.86,3.03-2.34,10.77,1.14,17.3,2.83,5.3,6.76,8.17,9.66,7.43.37-.1,3.05-1.36,3.05-1.36" fill="#fff"/>
              <path d="M84.81,82.41l-9.66-25.61s-3.57,1.58-4.2,2.24c-2.86,3.03-2.34,10.77,1.14,17.3,2.83,5.3,6.76,8.17,9.66,7.43.37-.1,3.05-1.36,3.05-1.36Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M86.14,81.47c-2.86,3.03-8,.2-11.48-6.33-3.49-6.52-4-14.27-1.14-17.3,2.85-3.03,8-.2,11.48,6.33,3.49,6.52,4,14.27,1.14,17.3" fill="#fff"/>
              <path d="M86.14,81.47c-2.86,3.03-8,.2-11.48-6.33-3.49-6.52-4-14.27-1.14-17.3,2.85-3.03,8-.2,11.48,6.33,3.49,6.52,4,14.27,1.14,17.3" fill="#00c7b1"/>
              <path d="M86.14,81.47c-2.86,3.03-8,.2-11.48-6.33-3.49-6.52-4-14.27-1.14-17.3,2.85-3.03,8-.2,11.48,6.33,3.49,6.52,4,14.27,1.14,17.3Z" fill="none" stroke="#003057" stroke-miterlimit="10" stroke-width="2"/>
              <path d="M105.05,49.03c.48,7.75-4.78,14.03-11.75,14.03-6.97,0-13-6.28-13.48-14.03-.48-7.75,4.78-14.03,11.75-14.03,6.97,0,13,6.28,13.48,14.03" fill="#00c7b1"/>
              <path d="M105.05,49.03c.48,7.75-4.78,14.03-11.75,14.03-6.97,0-13-6.28-13.48-14.03-.48-7.75,4.78-14.03,11.75-14.03,6.97,0,13,6.28,13.48,14.03Z" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
            </g>
            <path d="M94.36,46c-.66-.19-1.33-.28-1.94-.25l.68,2.05c2.23-.12,3.38.41,3.88,1.92v.03c.51,1.51-.33,2.87-1.98,3.6l.44,1.34-1.4.46-.45-1.36c-1.21.23-2.5.15-3.61-.23l.55-1.89c.91.31,1.71.47,2.56.37l-.71-2.15c-2.12.11-3.32-.3-3.84-1.91v-.03c-.5-1.49.31-2.84,1.96-3.58l-.26-.78,1.4-.46.27.82c1-.19,1.92-.11,2.84.16l-.38,1.88ZM91.72,47.89l-.6-1.84c-.64.3-.8.71-.66,1.14v.03c.15.43.43.64,1.26.67ZM93.76,49.8l.62,1.89c.6-.3.83-.69.67-1.18v-.03c-.16-.45-.48-.65-1.3-.68Z" fill="#003057"/>
            <path d="M103.9,78.33c-.63-.16-1.27-.23-1.84-.21l.66,1.73c2.05-.1,3.08.35,3.44,1.62v.02c.34,1.27-.46,2.41-1.84,3.03l.31,1.13-1.12.39-.35-1.15c-.97.2-2.01.12-2.93-.2l.34-1.59c.78.26,1.46.39,2.17.31l-.64-1.81c-1.85.1-2.94-.25-3.55-1.6v-.02c-.6-1.25.04-2.39,1.59-3.01l-.3-.66,1.38-.39.3.69c.98-.16,1.9-.09,2.79.13l-.41,1.58ZM101.45,79.92l-.63-1.55c-.59.25-.72.6-.56.96v.02c.16.36.43.54,1.19.56ZM103.31,81.54l.52,1.59c.52-.26.73-.58.6-.99v-.02c-.12-.37-.4-.55-1.12-.58Z" fill="#003057"/>
          </g>
          <path d="M80.01,66.77c-.44-.25-.88-.44-1.28-.51l.46,1.84c1.48.06,2.25.51,2.6,1.53v.02c.37.95-.18,1.95-1.28,2.77l.31.9-.95.73-.32-.99c-.82.47-1.69.68-2.44.54l.37-1.93c.61.18,1.15.22,1.72.02l-.49-1.79c-1.41.18-2.21-.2-2.56-1.9v-.03c-.32-1.64.21-2.95,1.29-3.34l-.17-.83.91-.05.18.82c.65.08,1.26.39,1.86.82l-.23,1.38ZM78.27,68.08l-.41-1.76c-.42.19-.53.58-.43,1.01v.03c.1.43.28.65.84.72ZM79.64,69.77l.43,1.46c.4-.3.55-.61.44-.97v-.02c-.11-.33-.33-.48-.87-.48Z" fill="#003057"/>
        </g>
      </g>
    </g>

  </svg>;

export default DclSaveMoney