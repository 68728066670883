import React, {useEffect} from 'react'
import Icon from "../../../resources/icons";
import IntroPageFooterLink from "../helpers/IntroPageFooterLink";
import FormattedMessage from "../helpers/FormattedMessage";
import {useParams} from "react-router-dom";
import LandingFooter from "../landing-footer";

function LandingPagesFooter() {
  const { employerCode } = useParams()

  function getYear() {
    return new Date().getFullYear();
  }

  return (
    <div id='landing-pages-footer'>
      <div className='footer-wrapper'>
        <LandingFooter />
      </div>
    </div>
  )

}

export default LandingPagesFooter
