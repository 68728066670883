import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'

export default class FicoScoreItem extends React.Component {

    constructor(props){
        super(props);
    }

    render() {

        return (
          <div className='action-plan-fico-score-item'>
            {
              this.props.description != null ?
                  (
                      <div className='fico-key-factors-block'>
                        <div className='fico-score-number-wrapper'>
                          <div className='fico-score-number-background'>
                            <div className='fico-score-number'>
                            {this.props.item}
                            </div>
                          </div>
                        </div>
                        <div className='fico-key-factors-title-mobile'>
                        {this.props.detail}
                        </div>
                        <div className='fico-score-key-factors-wrapper'>
                        <div className='fico-key-factors-title'>
                          {this.props.detail}
                        </div>
                          <div className='key-factors-text margin-bottom-16-px'>
                            {this.props.description}
                          </div>
                          <div className='fico-keep-in-mind-title margin-bottom-16-px'>
                            <FormattedMessage id='app.action-plan-fico-score.keep-in-mind'/>
                          </div>
                          <div className='fico-keep-in-mind-text margin-bottom-32-px'>
                            {this.props.keepinmind}
                          </div>
                          </div>
                      </div>
                  ) : (<div/>)
            }
        </div>)
    }
}
