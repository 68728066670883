import {LINK_MONEY_CATEGORY_DATA} from "../actions/link-money-actions";

const linkMoneyCategoryData = (state = null, action) => {
  switch (action.type) {
    case LINK_MONEY_CATEGORY_DATA:
      return action.linkMoneyCategoryData;
    default:
      return state
  }
}

export default linkMoneyCategoryData
