import {PROGRESS_PERCENTAGE} from '../actions/progress-percentage-actions'

export const initial_percentages = {
  'schedule_appointment': 25,
  'pull_credit_report': 0,
  'additional_questions_1': 0,
  'additional_questions_2': 0,
  'additional_questions_3': 0,
  'additional_questions_4': 0,
  'additional_questions_5': 0,
  'additional_questions_6': 0,
  'additional_questions_7': 0,
}

export default function progressPercentage(state = initial_percentages, action) {
  switch (action.type) {
    case PROGRESS_PERCENTAGE:
      return action.progressPercentage
    default:
      return state
  }
}
