import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function DebtReportNoCollections(props) {

  return (
    <div className='recommendations-page-container no-collections'>
      <div className='recommendations-gradient-color'>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.none-header'/>
        </h2>
      </div>
      <div className='recommendations-page-wrapper'>
        <p>
          <FormattedMessage id='app.debt-report-recommendations.none-text-1'/>
        </p>
        <p>
          <FormattedMessage id='app.debt-report-recommendations.none-text-2'/>
        </p>
      </div>
      <div className='collections-session-scheduled flex'>
        <div className='collections-session-schedule-icon-circle'>
          <FontAwesomeIcon icon={['fas', 'circle']} aria-label='An icon of a circle' />
          <div className='collections-session-scheduled-icon'>
            <FontAwesomeIcon icon={['far', 'calendar-alt']} aria-label='An icon of a calendar on a circle' />
          </div>
        </div>
        <div className='collections-session-scheduled-text'>
                  <span className='bold'>
                    <FormattedMessage id='app.debt-report-reviewed-collections.text-4'/>
                  </span>
          <br />
          {props.firstBookedAppointmentStr}
        </div>
      </div>
    </div>
  )
}