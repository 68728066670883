import React from 'react'

export const TPCPieChart670To739 = ({
                                     style = {},
                                     fill = '#fff',
                                     width = '100%',
                                     className = '',
                                     height = '100%',
                                     viewBox = '0 0 50 11',
                                   }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <text fill="#141006" fillRule="evenodd" fontFamily="Gotham-Medium, Gotham, Helvetica, Arial, sans-serif" fontSize="12" fontWeight="400" letterSpacing=".167" opacity=".3" transform="translate(-981 -242)">
      <tspan x="980.355" y="252">670-739</tspan>
    </text>
  </svg>

export default TPCPieChart670To739