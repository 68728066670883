import React from 'react'
import FormattedMessage from './FormattedMessage'

export const Option = ({value, i18nPrefix}) => (
  <FormattedMessage id={i18nPrefix + value}>
    {message => (<option value={value}>{message}</option>)}
  </FormattedMessage>
)

export default Option



