import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../services/validation-service'
import {changeEmail} from '../actions/login-actions'
import {displayLoadingIcon} from '../actions/loading-icon-action'
import {getContact} from '../actions/registration-actions'
import ChangeEmailForm from '../components/change-email-form'

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('login')(state),
    login: state.login
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoadingIcon(truthy) {
      dispatch(displayLoadingIcon(truthy))
    },
  }
}

function goToNextPage(props) {
  props.setMyAccountComponent("Account")
}

const ChangeEmailFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
  form: 'change-email-form',
  validate: ValidationService.changeEmail,
  onSubmit: (values, dispatch, props) => {
    dispatch(displayLoadingIcon(true))
    return dispatch(changeEmail(values)).then(() => {
      return dispatch(getContact(props.login.contactId)).then(() => {
        dispatch(displayLoadingIcon(false))
        goToNextPage(props)
      })
    })
  },
  destroyOnUnmount: false
})(injectIntl(ChangeEmailForm))
)

export default ChangeEmailFormContainer
