import {PAGE_COMPLETED} from '../actions/cp-flow-actions'

const pageCompleted = (state = null, action) => {
  switch (action.type) {
    case PAGE_COMPLETED:
      return action.pageCompleted;
    default:
      return state
  }
}

export default pageCompleted

