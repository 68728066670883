import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function DebtTradeAccount(props) {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <div className='collection-action-wrapper'>
      <h3>{props.account.type}</h3>
      <p className='collection-info'>
        <span className='bold'>
          {props.account.name}
        </span>
        <FormattedMessage id='app.debt-report-recommendations.collections-text-3'/>
        <span className='bold'>
          {formatter.format(props.account.balance)}
        </span>
        <FormattedMessage id='app.debt-report-recommendations.collections-text-4'/>
        <span className='bold'>
          {props.account.originalCreditor}
        </span>.
      </p>
      {
        props.account.flaggedInOnboarding === "Yes" ?
          <div className='submit-button-round sixty-percent-btn'>
            <button className='btn btn-primary btn-block'
                    id='debt-report-work-on-this' disabled>
              <div className='waiting-to-dispute-text-btn'>
                <div className='waiting-to-dispute-icon'>
                  <div className='collections-dispute-note-notice-img'>
                    <FontAwesomeIcon icon={['fal', 'clock']} aria-label='An icon of a clock' />
                  </div>
                </div>
                <div className='waiting-to-dispute-text'>
                  <FormattedMessage id='app.debt-report-recommendations.collections-text-8'/>
                </div>
              </div>
            </button>
          </div> :
          <div className='submit-button-round sixty-percent-btn'>
            <button className='btn btn-primary btn-block'
                    id='debt-report-work-on-this'
                    onClick={()=>props.view(props.account)}>
              <FormattedMessage id='app.debt-report-recommendations.collections-btn-text'/>
            </button>
          </div>
      }
    </div>
  )

}