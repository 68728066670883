import React from 'react'

export const Facebook = ({
                      style = {},
                      fill = '#fff',
                      width = '100%',
                      className = '',
                      height = '100%',
                      viewBox = '0 0 13 24',
                    }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="headerandfooter_w360-(displaywidths:-320-649)" transform="translate(-8.000000, -4042.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="facebook-f" transform="translate(8.000000, 4042.000000)">
          <path d="M10.115625,3.984375 L12.375,3.984375 L12.375,0.16875 C11.9859375,0.1171875 10.6453125,0 9.084375,0 C5.8265625,0 3.5953125,1.9875 3.5953125,5.6390625 L3.5953125,9 L0,9 L0,13.265625 L3.5953125,13.265625 L3.5953125,24 L8.0015625,24 L8.0015625,13.265625 L11.4515625,13.265625 L12,9 L8.0015625,9 L8.0015625,6.0609375 C8.0015625,4.828125 8.34375,3.984375 10.115625,3.984375 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </svg>;

export default Facebook