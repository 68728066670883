import React from 'react'

import NoEmployerCode from '../components/no-employer-code'
import LandingPagesHeader from "./landing-pages/landing-pages-header";
import LandingPagesHowHelped from "./landing-pages/landing-pages-how-helped";
import LandingPagesHelpYou from "./landing-pages/landing-pages-help-you";
import LandingPagesTagline from "./landing-pages/landing-pages-tagline";
import LandingPagesFooter from "./landing-pages/landing-pages-footer";

export default class LandingPropel extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      displayBlueHeader: false
    }

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if (window.scrollY >= 50 && this.state.displayBlueHeader === false) {
      this.setState({displayBlueHeader: true})
    }
    if (window.scrollY < 50 && this.state.displayBlueHeader === true) {
      this.setState({displayBlueHeader: false})
    }
  };

  goToAppointmentSchedule() {
    this.props.history.push('/enter-your-email-address')
  }


  goToWelcomePage() {
    this.props.history.push(`/welcome`)
  }

  goToAskQuestion() {
    this.props.history.push('/ask-question')
  }

  goToTrustPlus() {
    this.props.history.push(`/${this.props.employer.code}/trustplus-credit`)
  }

  displayThisServiceAtNoCostToYou(employerCode){
    if (employerCode.toLowerCase() !== 'mq19'){
      return (
        <div className='propel-org-name'>
          {`${this.props.employer.displayName}`}
        </div>

      )
    }
  }

  render(){
    return (
      this.props.employer && <div id='introduction-propel'>
        {this.props.employer.code === 'err' ?
          <NoEmployerCode/>
          :
          <div>

            <div className='intro-hero intro-propel-hero'>
              <span role="img" aria-label='A picture of a stimulus check in an envelope.'>
              </span>

              <LandingPagesHeader displayBlueHeader={this.state.displayBlueHeader}
                                  goToWelcomePage={() => this.goToWelcomePage()}
                                  goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
              />

                <div className='landing-propel-container'>
                  <div className='intro-content-landing-page-wrapper landing-page-propel-intro-wrapper'>
                    <div className='whitegrad-box'>
                      <div className='welcome-landing-page welcome-landing-page-propel'>
                        Is the extra stimulus enough?
                      </div>

                      {this.props.employer.code === 'BC45' ?
                       <div className='welcome-landing-page welcome-landing-page-propel-subtitle margin-bottom-40-px'>
                     FreshEBT users can ask a TrustPlus financial coach a question today to help that stimulus money go further.
                       </div>
                      :
                     <div className='welcome-landing-page welcome-landing-page-propel-subtitle margin-bottom-40-px'>
                       TrustPlus is partnering with {this.displayThisServiceAtNoCostToYou(this.props.employer.code)} to help you make the most of the new stimulus benefits.  Speak with a coach via phone or video call!
                     </div>
                          }

                      {this.props.employer.code === 'BC45' ?
                        <div className='submit-button-round btn-propel-landing'>
                          <button className='btn btn-primary btn-block btn-propel-landing'
                                  id='submit'
                                  onClick={this.goToAskQuestion.bind(this)}>
                            Ask for free now!
                          </button>
                        </div>
                        :
                        <div className='submit-button-round btn-propel-landing'>
                          <button className='btn btn-primary btn-block btn-propel-landing'
                                  id='submit'
                                  onClick={this.goToAppointmentSchedule.bind(this)}>
                            Sign up
                          </button>
                        </div>
                      }
                  </div>
                </div>
              </div>
            </div>


            <LandingPagesHowHelped/>

            <LandingPagesHelpYou goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
            goToAskQuestion={() => this.goToAskQuestion()}
            goToTrustPlus={() => this.goToTrustPlus()}
            />

            <LandingPagesTagline goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
            />

            <LandingPagesFooter/>
          </div>
        }
      </div>
    )
  }
}
