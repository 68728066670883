import React from 'react'

export const DclImpact1 = ({
                              style = {},
                              fill = '#fff',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 184.59 143.2',
                            }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <path d="M68.27,55.81c-2.49,0-4.67-1.84-5.03-4.37l-.52-3.7c-.19-1.34.16-2.68.97-3.76.82-1.08,2-1.78,3.35-1.97l10.34-1.45c2.78-.39,5.34,1.55,5.73,4.32l.52,3.7c.19,1.34-.16,2.68-.97,3.76-.82,1.08-2,1.78-3.35,1.97l-10.34,1.45c-.24.03-.47.05-.71.05ZM78.09,43.22c-.11,0-.22,0-.33.02l-10.34,1.45c-.62.09-1.18.41-1.56.92-.38.5-.54,1.13-.45,1.75h0l.52,3.7c.18,1.29,1.38,2.19,2.67,2.02l10.34-1.45c.62-.09,1.18-.41,1.56-.92.38-.5.54-1.13.45-1.75l-.52-3.7c-.17-1.18-1.18-2.04-2.34-2.04Z" fill="#003057"/>
        <path d="M56.69,51.84c-.67,0-1.25-.49-1.34-1.17-.1-.74.41-1.43,1.15-1.53l11.72-1.65c.73-.11,1.43.41,1.53,1.15.1.74-.41,1.43-1.15,1.53l-11.72,1.65c-.06,0-.13.01-.19.01Z" fill="#003057"/>
        <path d="M49.69,65.44c-1.18,0-2.36-.4-3.32-1.23l-2.84-2.44h0c-1.03-.89-1.65-2.12-1.75-3.47-.1-1.35.33-2.66,1.22-3.68l6.82-7.9c1.83-2.12,5.04-2.35,7.16-.52l2.84,2.44c1.03.89,1.65,2.12,1.75,3.47.1,1.35-.33,2.66-1.22,3.68l-6.82,7.9c-1,1.16-2.42,1.75-3.85,1.75ZM45.31,59.71l2.84,2.44c.99.85,2.49.74,3.34-.24l6.82-7.9c.41-.48.61-1.08.57-1.71-.05-.63-.34-1.2-.82-1.62l-2.84-2.44c-.99-.85-2.49-.74-3.34.24l-6.82,7.9c-.41.48-.61,1.08-.57,1.71.05.63.34,1.2.82,1.62h0Z" fill="#003057"/>
        <path d="M40.8,69.14c-.31,0-.63-.11-.89-.33-.57-.49-.63-1.35-.14-1.91l7.73-8.95c.49-.57,1.34-.63,1.91-.14.57.49.63,1.35.14,1.91l-7.73,8.95c-.27.31-.65.47-1.03.47Z" fill="#003057"/>
        <path d="M41.25,84.89c-2.08,0-4.04-1.29-4.79-3.36l-3.53-9.82c-.46-1.27-.39-2.65.19-3.87.58-1.23,1.6-2.15,2.88-2.61l3.52-1.26c2.64-.94,5.55.43,6.5,3.06l3.53,9.82c.46,1.27.39,2.65-.19,3.87-.58,1.23-1.6,2.16-2.88,2.61l-3.52,1.26c-.56.2-1.14.3-1.71.3ZM41.24,66.38c-.26,0-.53.04-.8.14l-3.52,1.26c-.6.21-1.07.65-1.34,1.22-.27.57-.3,1.21-.09,1.8l3.53,9.82c.44,1.23,1.8,1.86,3.03,1.42h0l3.52-1.26c.6-.21,1.07-.65,1.34-1.22.27-.57.3-1.21.09-1.8l-3.53-9.82c-.35-.96-1.26-1.56-2.23-1.56Z" fill="#003057"/>
        <path d="M41.29,107.67c-.85,0-1.71-.21-2.5-.66l-3.26-1.84c-1.18-.67-2.04-1.76-2.4-3.07-.36-1.3-.19-2.67.47-3.85l5.14-9.07c1.38-2.43,4.48-3.29,6.92-1.91l3.26,1.84c1.18.67,2.04,1.76,2.4,3.07.36,1.3.19,2.67-.47,3.85l-5.14,9.07c-.93,1.64-2.65,2.57-4.42,2.57ZM43.17,89.33c-.83,0-1.63.43-2.06,1.19l-5.14,9.07c-.31.55-.39,1.18-.22,1.78.17.61.57,1.12,1.12,1.43h0s3.26,1.84,3.26,1.84c1.14.64,2.59.25,3.23-.88l5.14-9.07c.31-.55.39-1.18.22-1.78-.17-.61-.57-1.12-1.12-1.43l-3.26-1.84c-.37-.21-.77-.31-1.17-.31Z" fill="#003057"/>
        <path d="M32.2,128.35c-.31,0-.63-.03-.95-.09l-3.68-.69c-1.33-.25-2.49-1-3.26-2.12-.77-1.12-1.05-2.47-.8-3.8l1.93-10.24c.52-2.75,3.18-4.56,5.94-4.04l3.68.69c2.75.52,4.58,3.18,4.06,5.92l-1.93,10.24c-.46,2.43-2.59,4.13-4.99,4.13ZM30.43,109.99c-1.12,0-2.11.79-2.33,1.92l-1.93,10.24c-.12.62.02,1.24.37,1.76.36.52.9.87,1.52.99h0l3.68.69c1.28.24,2.53-.6,2.77-1.88l1.93-10.24c.24-1.28-.61-2.51-1.89-2.75l-3.68-.69c-.15-.03-.3-.04-.44-.04Z" fill="#003057"/>
        <path d="M46.88,91.27c-.56,0-1.08-.34-1.28-.9l-4-11.12c-.25-.71.11-1.48.82-1.73.7-.25,1.48.11,1.73.82l4,11.12c.25.71-.11,1.48-.82,1.73-.15.05-.31.08-.46.08Z" fill="#003057"/>
        <path d="M34.04,112.97c-.23,0-.46-.06-.67-.18-.65-.37-.88-1.2-.51-1.85l5.83-10.28c.37-.65,1.2-.88,1.85-.51.65.37.88,1.2.51,1.85l-5.83,10.28c-.25.44-.71.69-1.18.69Z" fill="#003057"/>
        <path d="M28.28,135.29c-.11,0-.22-.01-.33-.04-.73-.18-1.17-.92-.98-1.65l2.91-11.45c.19-.73.92-1.16,1.65-.98.73.18,1.17.92.98,1.65l-2.91,11.45c-.16.61-.71,1.02-1.31,1.02Z" fill="#003057"/>
        <g>
          <path d="M44.06,136.78l.83-4.78c.46-2.63-2.56-5.34-6.74-6.06l-21.24-3.66c-4.18-.72-7.93.83-8.39,3.45l-.83,4.78c-.46,2.63,2.56,5.34,6.74,6.06l21.24,3.66c4.18.72,7.93-.83,8.39-3.45Z" fill="#003057"/>
          <path d="M37.73,141.77c-.74,0-1.5-.06-2.28-.2l-21.24-3.66c-2.96-.51-5.54-1.96-6.9-3.89-.84-1.19-1.17-2.48-.95-3.74l.83-4.78c.59-3.41,4.96-5.42,9.96-4.56l21.24,3.66c2.96.51,5.54,1.96,6.9,3.88.84,1.19,1.17,2.48.95,3.74l-.83,4.78h0c-.5,2.88-3.69,4.76-7.67,4.76ZM14.87,123.46c-2.77,0-4.78,1.17-5.02,2.51l-.83,4.78c-.09.54.07,1.12.49,1.71.94,1.33,2.91,2.39,5.15,2.78l21.24,3.66c3.67.63,6.54-.73,6.82-2.35l.83-4.78c.09-.54-.07-1.12-.49-1.71-.94-1.33-2.91-2.39-5.14-2.78l-21.24-3.66c-.63-.11-1.23-.16-1.81-.16ZM44.06,136.78h0,0Z" fill="#003057"/>
        </g>
        <path d="M77.43,48.92c-.67,0-1.25-.49-1.34-1.17-.1-.74.41-1.43,1.15-1.53l11.72-1.65c.73-.11,1.43.41,1.53,1.15.1.74-.41,1.43-1.15,1.53l-11.72,1.65c-.06,0-.13.01-.19.01Z" fill="#003057"/>
        <path d="M97.89,142.4c23.99,0,43.43-19.38,43.43-43.28s-19.44-43.28-43.43-43.28-43.43,19.38-43.43,43.28,19.44,43.28,43.43,43.28Z" fill="#003057"/>
        <path d="M97.88,69.76c-7.69,0-13.94-6.24-13.94-13.91s6.25-13.91,13.94-13.91,13.94,6.24,13.94,13.91-6.25,13.91-13.94,13.91ZM97.88,50.39c-3.03,0-5.5,2.45-5.5,5.46s2.47,5.46,5.5,5.46,5.5-2.45,5.5-5.46-2.47-5.46-5.5-5.46Z" fill="#003057"/>
        <path d="M156.93,114.74c-.81,0-1.62-.27-2.29-.83l-31.73-26.27-31.46-10.33c-.47-.16-.91-.41-1.28-.74l-27.49-24.68-33.01-12.46c-.85-.32-1.54-.95-1.94-1.75L15.14,12.47c-.89-1.77-.17-3.93,1.61-4.82,1.78-.89,3.93-.17,4.82,1.61l11.97,23.94,32.32,12.21c.42.16.8.39,1.13.69l27.43,24.63,31.39,10.31c.43.14.83.36,1.17.65l32.25,26.71c1.53,1.27,1.74,3.53.48,5.06-.71.86-1.74,1.3-2.77,1.3Z" fill="#00c7b1"/>
        <path d="M141.7,128.43l30.58-33.47,1.48,32.01-32.06,1.46Z" fill="#00c7b1"/>
        <path d="M182.8,143.2H1.8c-.99,0-1.8-.8-1.8-1.8V1.8C0,.8.8,0,1.8,0s1.8.8,1.8,1.8v137.81h179.2c.99,0,1.8.8,1.8,1.8s-.8,1.8-1.8,1.8Z" fill="#00c7b1"/>
      </g>
    </g>

  </svg>;

export default DclImpact1