import {DISPLAY_ALERT} from '../actions/alert-actions'

export default function displayAlert(state = false, action) {
  switch (action.type) {
    case DISPLAY_ALERT:
      return action.displayAlert
    default:
      return state
  }
}
