export const DEBT_SUMMARY_ACCOUNT_DETAIL = 'DEBT_SUMMARY_ACCOUNT_DETAIL'
export const ACTIVE_TAB = 'ACTIVE_TAB'

export function debtSummaryAccountDetail(accountDetail) {
  let newAccountDetail = Object.assign({}, accountDetail)
  return {
    type: DEBT_SUMMARY_ACCOUNT_DETAIL,
    debtSummaryAccountDetail: newAccountDetail
  }
}

export function activeTab(tab) {
  return {
    type: ACTIVE_TAB,
    activeTab: tab
  }
}