import React from 'react'
import FormattedMessage from "./FormattedMessage";
import PrivacyMessages from "./PrivacyMessages";

export const PrivacyText = props => (
  <div>
    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.overview.sub-header'/></h3>
    <p><FormattedMessage id='app.privacy-policy.overview.intro.1'
                         values={{
                           ntfpSite: (<a target='_blank' href='https://www.neighborhoodtrust.org/'>
                             www.neighborhoodtrust.org
                           </a>),
                           tosLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.externalTermsOfService)} id='external-terms'>
                             <FormattedMessage id='app.common.link.external.terms'/>
                           </a>)
                         }}
    /></p>
    <p>
      <FormattedMessage id='app.privacy-policy.overview.intro.2'/>
      <span className='tpc-emmas-story-bold' style={{textDecoration: 'underline'}}>
        <FormattedMessage id='app.privacy-policy.overview.intro.3'/>
      </span>
    </p>
    <p><FormattedMessage id='app.privacy-policy.overview.intro.4'
                         values={{
                           mailToSupportAction: (<a href={'mailto:' + props.intl.formatMessage(PrivacyMessages.mailtoEmailAddress)}>
                             <FormattedMessage id='app.common.email.support'/>
                           </a>),
                         }}
    /></p>

    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.overview.scope.sub-title' /></h4>
    <p><FormattedMessage id='app.privacy-policy.scope.1' /></p>
    <p>
      <FormattedMessage id='app.privacy-policy.scope.2' />
      <span style={{fontStyle: 'italic'}}>
        <FormattedMessage id='app.privacy-policy.scope.3'/>
      </span>
      <FormattedMessage id='app.privacy-policy.scope.4'/>
    </p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.information-we-collect.sub-header'/></h3>
    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.information-we-collect.information-you-provide.subtitle' /></h4>
    <p>
      <FormattedMessage id='app.privacy-policy.information-we-collect.1'/>
      <ul>
        <li><FormattedMessage id='app.privacy-policy.pii-bulletpoints.1' /></li>
        <li><FormattedMessage id='app.privacy-policy.pii-bulletpoints.2' /></li>
        <li><FormattedMessage id='app.privacy-policy.pii-bulletpoints.3' /></li>
        <li><FormattedMessage id='app.privacy-policy.pii-bulletpoints.4' /></li>
        <li><FormattedMessage id='app.privacy-policy.pii-bulletpoints.5' /></li>
        <li><FormattedMessage id='app.privacy-policy.pii-bulletpoints.6' /></li>
        <li><FormattedMessage id='app.privacy-policy.pii-bulletpoints.7' /></li>
        <li><FormattedMessage id='app.privacy-policy.pii-bulletpoints.8' /></li>
      </ul>
    </p>
    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.information-we-collect.passive-data-collection.subtitle'/></h4>
    <p>
      <FormattedMessage id='app.privacy-policy.passive-data-collection.1'/>
    </p>
    <p>
      <FormattedMessage id='app.privacy-policy.passive-data-collection.2'/>
    </p>
    <p>
      <FormattedMessage id='app.privacy-policy.passive-data-collection.3'/>
      <ul>
        <li><FormattedMessage id='app.privacy-policy.passive-data-collection.4'/></li>
        <li><FormattedMessage id='app.privacy-policy.passive-data-collection.5'/></li>
        <li><FormattedMessage id='app.privacy-policy.passive-data-collection.6'
                              values={{
                                hotjarExternalLink: (
                                  <a target='_blank' href='https://www.hotjar.com/legal/policies/privacy/' >
                                    <FormattedMessage id='app.privacy-policy.passive-data-collection.7'/>
                                  </a>
                                )
                              }}/></li>
        <li><FormattedMessage id='app.privacy-policy.passive-data-collection.8' />
          <ul>
            <li>
              <span style={{fontStyle: 'italic'}}>
                <FormattedMessage id='app.privacy-policy.passive-data-collection.cookies.1' />
              </span>
              <FormattedMessage id='app.privacy-policy.passive-data-collection.cookies.2' />
            </li>
            <li>
              <span style={{fontStyle: 'italic'}}>
                  <FormattedMessage id='app.privacy-policy.passive-data-collection.cookies.3' />
              </span>
              <FormattedMessage id='app.privacy-policy.passive-data-collection.cookies.4' />
            </li>
            <li>
              <span style={{fontStyle: 'italic'}}>
                  <FormattedMessage id='app.privacy-policy.passive-data-collection.cookies.5' />
              </span>
              <FormattedMessage id='app.privacy-policy.passive-data-collection.cookies.6' />
            </li>
          </ul>
        </li>
      </ul>
    </p>
    <p> <FormattedMessage id='app.privacy-policy.passive-data-collection.9'
                          values={{
                            allAboutCookiesLink: ( <a target='_blank' href={'https://' +  props.intl.formatMessage(PrivacyMessages.allAboutCookiesLink)}>
                              <FormattedMessage id='app.privacy-policy.all-about-cookies-link' />
                            </a>)
                          }}
    /></p>
    <p><FormattedMessage id='app.privacy-policy.passive-data-collection.10'/></p>
    <p><FormattedMessage id='app.privacy-policy.passive-data-collection.11'/></p>

    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.information-we-collect.third-party-advertisers.subtitle' /></h4>
    <p><FormattedMessage id='app.privacy-policy.third-party-advertisers.1' /></p>
    <p><FormattedMessage id='app.privacy-policy.third-party-advertisers.2'
                         values={{
                           optOutAdsLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.optOutAdsLink)}>
                             <FormattedMessage id='app.privacy-policy.third-party-advertisers.opt-out-ads-link' />
                           </a>),
                           optOutNetworkAdvertisingLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.optOutNetworkAdvertisingLink)}>
                             <FormattedMessage id='app.privacy-policy.third-party-advertisers.opt-out-networking-advertising-link' />
                           </a>),
                           trusteLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.trusteLink)}>
                             <FormattedMessage id='app.privacy-policy.third-party-advertisers.truste-link' />
                           </a>),
                           googleAdsLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.googleAdsLink)}>
                             <FormattedMessage id='app.privacy-policy.third-party-advertisers.google-ads-link' />
                           </a>),
                           googlePoliciesLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.googlePoliciesLink)}>
                             <FormattedMessage id='app.privacy-policy.third-party-advertisers.google-policies-link' />
                           </a>),
                           facebookAdsLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.facebookAdsLink)}>
                             <FormattedMessage id='app.privacy-policy.third-party-advertisers.facebook-ads-link' />
                           </a>),
                           facebookPolicyLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.facebookPolicyLink)}>
                             <FormattedMessage id='app.privacy-policy.third-party-advertisers.facebook-policy-link' />
                           </a>),
                           euroUnionOnlineChoicesLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.euroUnionOnlineChoicesLink)}>
                             <FormattedMessage id='app.privacy-policy.third-party-advertisers.euro-union-online-choices-link' />
                           </a>),
                         }}
    /></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.how-we-use-your-information.sub-header'/></h3>
    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.how-we-use-your-information.we-may-use-info-to.subtitle'/></h4>
    <p>
      <ul>
        <li><FormattedMessage id='app.privacy-policy.how-we-use-your-information.bulletpoints.1'/></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-use-your-information.bulletpoints.2'/></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-use-your-information.bulletpoints.3'/></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-use-your-information.bulletpoints.4'/></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-use-your-information.bulletpoints.5'/></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-use-your-information.bulletpoints.6'/></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-use-your-information.bulletpoints.7'/></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-use-your-information.bulletpoints.8'/></li>
      </ul>
    </p>
    <p><FormattedMessage id='app.privacy-policy.how-we-use-your-information.1'/></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.sub-header' /></h3>
    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.1'/></h4>
    <p>
      <FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.2'/>
      <ul>
        <li><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.bulletpoints.1' /></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.bulletpoints.2' /></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.bulletpoints.3' /></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.bulletpoints.4' /></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.bulletpoints.5' /></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.bulletpoints.6' /></li>
        <li><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.bulletpoints.7' /></li>
      </ul>
    </p>
    <p><FormattedMessage id='app.privacy-policy.how-we-disclose-your-information.3' /></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.third-party-websites.sub-header' /></h3>
    <p><FormattedMessage id='app.privacy-policy.third-party-websites.1' /></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.childrens-privacy.sub-header' /></h3>
    <p><FormattedMessage id='app.privacy-policy.childrens-privacy.1' /></p>
    <p><FormattedMessage id='app.privacy-policy.childrens-privacy.2'
                         values={{
                           mailToSupportAction:(<a href={'mailto:' + props.intl.formatMessage(PrivacyMessages.mailtoEmailAddress)}>
                             <FormattedMessage id='app.common.email.support'/>
                           </a>)
                         }}/></p>
    <p><FormattedMessage id='app.privacy-policy.childrens-privacy.3'
                         values={{
                           mailToSupportAction:(<a href={'mailto:' + props.intl.formatMessage(PrivacyMessages.mailtoEmailAddress)}>
                             <FormattedMessage id='app.common.email.support'/>
                           </a>)
                         }}/></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.your-choices.sub-header' /></h3>
    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.your-choices.accessing-requesting-changes.subtitle'/></h4>
    <p><FormattedMessage id='app.privacy-policy.your-choices.accessing-requesting-changes.1' /></p>
    <p><FormattedMessage id='app.privacy-policy.your-choices.accessing-requesting-changes.2'
                         values={{
                           mailToSupportAction:(<a href={'mailto:' + props.intl.formatMessage(PrivacyMessages.mailtoEmailAddress)}>
                             <FormattedMessage id='app.common.email.support'/>
                           </a>)
                         }}/></p>
    <p><FormattedMessage id='app.privacy-policy.your-choices.accessing-requesting-changes.3' /></p>

    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.your-choices.communications-preferences.subtitle'/></h4>
    <p><FormattedMessage id='app.privacy-policy.your-choices.communications-preferences.1' /></p>
    <p><FormattedMessage id='app.privacy-policy.your-choices.communications-preferences.2' /></p>
    <p><FormattedMessage id='app.privacy-policy.your-choices.communications-preferences.3' /></p>

    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.your-choices.do-not-track.subtitle'/></h4>
    <p><FormattedMessage id='app.privacy-policy.your-choices.do-not-track.1'/></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.notices-to-CA-residents.sub-header' /></h3>
    <p><FormattedMessage id='app.privacy-policy.notices-to-CA-residents.1'
                         values={{
                           mailToSupportAction:(<a href={'mailto:' + props.intl.formatMessage(PrivacyMessages.mailtoEmailAddress)}>
                             <FormattedMessage id='app.common.email.support'/>
                           </a>)
                         }}/></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.notices-to-VT-residents.sub-header' /></h3>
    <p><FormattedMessage id='app.privacy-policy.notices-to-VT-residents.1'
                         values={{
                           externalTosLink: (<a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.externalTermsOfService)} id='external-terms'>
                             <FormattedMessage id='app.common.link.external.terms'/>
                           </a>),
                           tollFreePhone: (
                             <a href={'tel:' + props.intl.formatMessage(PrivacyMessages.tollFreePhoneNumber)}>
                               <FormattedMessage id='app.common.phone.tollfree'/>
                             </a>),
                         }}/></p>
    <p><FormattedMessage id='app.privacy-policy.notices-to-VT-residents.2' /></p>
    <p><FormattedMessage id='app.privacy-policy.notices-to-VT-residents.3'/></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.security.sub-header' /></h3>
    <h4 className='ptl'><FormattedMessage id='app.privacy-policy.security.1' /></h4>
    <p><FormattedMessage id='app.privacy-policy.security.2'/></p>
    <p><FormattedMessage id='app.privacy-policy.security.3'/></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.changes-to-privacy-policy.sub-header'/></h3>
    <p><FormattedMessage id='app.privacy-policy.changes-to-privacy-policy.1' /></p>

    <h3 className='ptl'><FormattedMessage id='app.privacy-policy.how-to-contact.sub-header'/></h3>
    <p><FormattedMessage id='app.privacy-policy.how-to-contact.1'
                         values={{
                           mailToSupportAction:(<a href={'mailto:' + props.intl.formatMessage(PrivacyMessages.mailtoEmailAddress)}>
                             <FormattedMessage id='app.common.email.support'/>
                           </a>)
                         }}/></p>
    <p>
      <FormattedMessage id='app.terms.privacy.section.6.text.1'/><br/>
      <FormattedMessage id='app.terms.privacy.section.6.text.2'/><br/>
      <FormattedMessage id='app.terms.privacy.section.6.text.3'/><br/>
      <FormattedMessage id='app.privacy-policy.how-to-contact.2'/><br/>
    </p>
    <p><FormattedMessage id='app.privacy-policy.how-to-contact.3'
                         values={{
                           externalPrivacyPolicyLink: <a target='_blank' href={'https://' + props.intl.formatMessage(PrivacyMessages.externalPrivacyPolicy)} id='external-terms'>
                             <FormattedMessage id='app.common.link.external.privacy'/>
                           </a>
                         }}/></p>
  </div>
)

export default PrivacyText