import React, {useEffect} from 'react'

import FormattedMessage from '.././helpers/FormattedMessage'
import HeaderContainerAP from '../../containers/header-container-ap'
import FooterContainerAp from '../../containers/footer-container-ap'
import {reduxForm} from "redux-form";
import {resetPassword} from "../../actions/login-actions";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";

function BFlowForgotPasswordConfirmation(props) {

    const login = useSelector(state => state.login);
    const registration = useSelector(state => state.registration);

    useEffect(() => {
        if (login === null || registration === null) {
            props.history.push(`/enter-your-email-address`);
        }
    })

    return (
        <div className='b-flow b-flow-background'>
            <HeaderContainerAP/>
            <div className='b-flow-body'>

                <div className='container-ap forgotpassword-form'>

                    <h1>
                        <FormattedMessage id='app.b-flow.forgot-password-confirmation.instruction'/>
                    </h1>

                    <form onSubmit={props.handleSubmit}>
                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-red'>
                                <FormattedMessage id='app.b-flow.forgot-password-confirmation.continue.button'/>
                            </button>
                        </div>
                    </form>
                </div>
                <FooterContainerAp/>
            </div>
        </div>)
}

function goToNextPage(props) {
    props.history.push(`/make-a-password`);
}

export default reduxForm({
    form: 'BFlowForgotPasswordConfirmation',
    onSubmit: (values, dispatch, props) => {
        return dispatch(resetPassword(values)).then(() => {
            goToNextPage(props)
        })
    },
    destroyOnUnmount: false
})(injectIntl(BFlowForgotPasswordConfirmation))