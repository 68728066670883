import React from 'react'

export const CovidYellow = ({
                          style = {},
                          fill = '#fff',
                          width = '100%',
                          className = '',
                          height = '100%',
                          viewBox = '0 0 64 64',
                        }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-1" points="0 0.0539282869 16.153192 0.0539282869 16.153192 15.8621833 0 15.8621833"></polygon>
    </defs>
    <g id="Page-5" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <g id="Meet_w650+-(maxwidths)" transform="translate(-914.000000, -303.000000)">
        <g id="Group-16" transform="translate(914.000000, 303.000000)">
          <path d="M41.7233594,54.6709418 L22.0279331,54.6709418 C19.4207618,54.6709418 17.2116143,52.9720096 16.4436143,50.6210932 L4.94732749,48.9372048 C10.6004908,57.8413641 20.5475187,63.7512924 31.8755187,63.7512924 C43.1604271,63.7512924 53.0755825,57.8870056 58.7399649,49.0397068 L47.2745307,50.7192606 C46.4777179,53.0189259 44.2940685,54.6709418 41.7233594,54.6709418" id="Fill-1" fill="#F9C943"></path>
          <g id="Group-5" transform="translate(0.000000, 31.623904)">
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-4"></g>
            <path d="M16.153192,7.48328287 L0.00249880478,0.0539282869 C0.00198884462,0.119968127 -5.09960159e-05,0.185752988 -5.09960159e-05,0.251792829 C-5.09960159e-05,5.15480478 1.10809243,9.79850199 3.08571793,13.9480478 L16.153192,15.8621833 L16.153192,7.48328287 Z" id="Fill-3" fill="#F9C943" mask="url(#mask-2)"></path>
          </g>
          <path d="M12.6792159,26.342502 C12.3760446,26.6984542 11.9435984,26.8820398 11.5091124,26.8820398 C11.1615745,26.8820398 10.8122518,26.764494 10.5261641,26.5235378 C9.88233944,25.9811952 9.79488127,25.024 10.3339092,24.3773705 C10.4338614,24.2572749 12.8365386,21.4229163 17.5309769,20.0146614 C22.0951203,18.6454183 25.760204,19.0811793 25.913702,19.1005578 C26.7520765,19.2050996 27.34669,19.9697849 27.2421482,20.8081594 C27.1373514,21.645259 26.3726661,22.2396175 25.5383713,22.1371155 C25.5067538,22.1332908 22.3243474,21.7714741 18.4101482,22.9454024 C14.6428175,24.0754741 12.6983394,26.3198088 12.6792159,26.342502 L12.6792159,26.342502 Z M37.8375904,19.1005578 C37.9913434,19.0809243 41.6566821,18.6454183 46.2205705,20.0146614 C50.9152637,21.4229163 53.3176861,24.2572749 53.4176382,24.3773705 C53.958451,25.0265498 53.8707378,25.9911394 53.2215586,26.5319522 C52.9357259,26.7703586 52.588443,26.8866295 52.242945,26.8866295 C51.8046343,26.8868845 51.3693833,26.6992191 51.0669769,26.3361275 C51.052953,26.3198088 49.1087299,24.0754741 45.3416542,22.9454024 C41.427455,21.7712191 38.2450486,22.1330359 38.2131761,22.1371155 C37.3755665,22.2370677 36.6129211,21.6414343 36.5106741,20.8038247 C36.4084271,19.9667251 37.0007458,19.2050996 37.8375904,19.1005578 L37.8375904,19.1005578 Z M16.1533195,35.1314104 C16.1533195,31.8865339 18.783439,29.2566693 22.0280606,29.2566693 L41.7232319,29.2566693 C44.9678534,29.2566693 47.5982279,31.8865339 47.5982279,35.1314104 L47.5982279,36.1495458 L63.6020526,28.787761 C62.0487139,12.6322231 48.4381323,2.84217094e-14 31.8756462,2.84217094e-14 C15.4472797,2.84217094e-14 1.92364622,12.428749 0.189781673,28.3958566 L16.1533195,35.7392829 L16.1533195,35.1314104 Z" id="Fill-6" fill="#F9C943"></path>
          <path d="M47.5981004,47.5795633 L60.6186582,45.6723124 C62.5975586,41.5574438 63.7169211,36.9519936 63.7485386,32.0882486 L47.5981004,39.5176032 L47.5981004,47.5795633 Z" id="Fill-8" fill="#F9C943"></path>
          <path d="M63.6019506,28.78771 L47.5981259,36.1494948 L47.5981259,35.1313594 C47.5981259,31.8864829 44.9680064,29.2566183 41.7233849,29.2566183 L22.0279586,29.2566183 C18.7833371,29.2566183 16.1532175,31.8864829 16.1532175,35.1313594 L16.1532175,35.7392319 L0.189679681,28.3958056 C0.0726438247,29.4746263 0.00915378486,30.5695108 0.00252430279,31.6779092 L16.1532175,39.1072637 L16.1532175,47.4861641 L3.08574343,45.5720287 C3.63956016,46.7342279 4.26171155,47.8576701 4.94709801,48.9370008 L16.4436398,50.6211442 C17.2116398,52.9720606 19.4207873,54.6709928 22.0279586,54.6709928 L41.7233849,54.6709928 C44.294094,54.6709928 46.4777434,53.0189769 47.2743012,50.7193116 L58.7399904,49.0397578 C59.4312414,47.9599171 60.0592574,46.835455 60.6186837,45.6722359 L47.5981259,47.5794869 L47.5981259,39.5175267 L63.7485641,32.0881721 C63.7490741,32.0172876 63.7513689,31.9469131 63.7513689,31.8755187 C63.7513689,30.8336701 63.699608,29.8038056 63.6019506,28.78771" id="Fill-10" fill="#FEFEFE"></path>
          <path d="M38.2130741,22.1370645 C38.2449466,22.1329849 41.427353,21.7711681 45.3415522,22.9453514 C49.1086279,24.0756781 51.052851,26.3197578 51.0668749,26.3360765 C51.3695363,26.6994231 51.8047873,26.8868335 52.242843,26.8865785 C52.588341,26.8865785 52.9356239,26.7703076 53.2214566,26.5319012 C53.8706359,25.9910884 53.958349,25.0264988 53.4175363,24.3773195 C53.3175841,24.2572239 50.9151618,21.4228653 46.2204685,20.0146104 C41.6568351,18.6456223 37.9912414,19.0811283 37.8374884,19.1005068 C37.0006438,19.2050486 36.4083251,19.9666741 36.5105721,20.8037737 C36.6130741,21.6413833 37.3757195,22.2372717 38.2130741,22.1370645" id="Fill-12" fill="#060000"></path>
          <path d="M25.5382693,22.1370645 C26.3725641,22.2395665 27.1372494,21.645208 27.2423012,20.8081084 C27.346843,19.9697339 26.7522295,19.2050486 25.913855,19.1005068 C25.760102,19.0813833 22.0950183,18.6453673 17.5311299,20.0146104 C12.8366916,21.4228653 10.4340143,24.2572239 10.3338072,24.3773195 C9.79503426,25.023949 9.88249243,25.9811442 10.5260622,26.5234869 C10.8121498,26.764443 11.1614725,26.8819888 11.5090104,26.8819888 C11.9437514,26.8819888 12.3759426,26.6984032 12.6791139,26.342451 C12.6984924,26.3197578 14.6429705,24.0756781 18.4100462,22.9453514 C22.3245004,21.7714231 25.5066518,22.1332398 25.5382693,22.1370645" id="Fill-14" fill="#060000"></path>
        </g>
      </g>
    </g>
  </svg>

export default CovidYellow