import {GET_ACHIEVED_TAKE_ACTION_TODAY} from '../actions/take-action-today-actions'

const achievedTakeActionToday= (state = null, action) => {
  switch (action.type) {
    case GET_ACHIEVED_TAKE_ACTION_TODAY:
      return action.achievedTakeActionToday
    default:
      return state
  }
}

export default achievedTakeActionToday
