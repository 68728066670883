import {LOGOUT} from '../actions/logout-actions'
import {CPLOGOUT} from '../actions/cp-flow-actions'

export default function logout(state = null, action) {
  switch (action.type) {
    case CPLOGOUT:
      return state
    case LOGOUT:
      return state
    default:
      return state
  }
}

