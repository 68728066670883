import React from 'react'
import {Field} from 'redux-form'
import FormattedMessage from './FormattedMessage'

export const IntroPageFooterLink = ({url, altTag, title, onClick}) => (
  <div>
      <span>
         <a href={url} alt={altTag} target='_blank'>
          <FormattedMessage id={title}/>
        </a>
      </span>
  </div>
)

export default IntroPageFooterLink
