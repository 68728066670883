import {
  GET_PENDING_TAKE_ACTION_TODAY
  } from '../actions/take-action-today-actions'

const pendingTakeActionToday= (state = null, action) => {
  switch (action.type) {
    case GET_PENDING_TAKE_ACTION_TODAY:
      return action.pendingTakeActionToday
    default:
      return state
  }
}

export default pendingTakeActionToday
