import {defineMessages} from "react-intl";

export const TermsMessages = defineMessages({
  externalTermsOfService: {
    id: 'app.common.link.external.terms',
  },
  externalZoomLegalLink: {
    id: 'app.common.link.external.zoom',
  },
  tollFreePhoneNumber: {
    id: 'app.common.phone.tollfree',
  },
  mailtoEmailAddress: {
    id: 'app.common.email.support',
  },
  externalGoogleLegalLink: {
    id:'app.common.link.external.google'
  }
});

export default TermsMessages