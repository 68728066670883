export const CHANGE_LOCALE = 'CHANGE_LOCALE'
export const CHANGE_MSG_FILE = 'CHANGE_MSG_FILE'

export function toggleLocale(currentLocale) {
  return (dispatch, getState) => {
    const JPMC = "YQ58"
    const jpmcMsgFile = "en-US_JPMC"
    const {employer} = getState()

    let newLocale = currentLocale === 'en-US' ? 'es-US' : 'en-US'
    let newMsgFile = employer && JPMC === employer.code.toUpperCase() && currentLocale === 'en-US' ? jpmcMsgFile : newLocale

    dispatch({
      type: CHANGE_LOCALE,
      locale: newLocale,
    })

    dispatch({
      type: CHANGE_MSG_FILE,
      msgFile: newMsgFile
    })
  }
}

export function updateMsgFile() {
  return (dispatch, getState) => {
    const JPMC = "YQ58"
    const jpmcMsgFile = "en-US_JPMC"
    const {employer, locale, msgFile} = getState()

    let newMsgFile = employer && JPMC === employer.code.toUpperCase() && locale === 'en-US' ? jpmcMsgFile : locale

    if (msgFile !== newMsgFile) {
      dispatch({
        type: CHANGE_MSG_FILE,
        msgFile: newMsgFile
      })
    }
  }
}
