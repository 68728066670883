import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'

export default function Footer(props) {
  const {intl}=props;
  let supportEmail = intl.formatMessage({id: 'app.common.email.support-text'});
  let supportEmailAction = 'mailto:support@mytrustplus.org';
    let externalTermsOfSvc = 'https://' + intl.formatMessage({id: 'app.common.link.external.terms'});
    let externalPrivacy = 'https://' + intl.formatMessage({id: 'app.common.link.external.privacy'});

  return (
    <div className='footer-ap'>
        <div className='footer-ap-link link-1'>
            <a target="_blank" href={externalTermsOfSvc} id='app-terms'>
                <FormattedMessage id='app.footer.terms'/>
            </a>
            <div className='footer-pipe'>
              |
            </div>
        </div>
        <div className='footer-ap-link link-1'>
          <a target="_blank" href={externalPrivacy} id='app-privacy'>
              <FormattedMessage id='app.footer.privacy'/>
          </a>
          <div className='footer-pipe'>
            |
          </div>
        </div>
        <div className='footer-ap-link link-2'>
          <a href={supportEmailAction}>
            {supportEmail}
          </a>
        </div>
    </div>
  )
}
