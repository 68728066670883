import axios from 'axios'
import {SubmissionError} from 'redux-form'
import 'moment-timezone'

export const SUBMIT_NEW_TEMPCODE = 'SUBMIT_NEW_TEMPCODE'
export const SUBMIT_LOGIN = 'SUBMIT_LOGIN'

export const SET_TEMPORARY_EMAIL = 'SET_TEMPORARY_EMAIL'
export const RESET_TEMPORARY_EMAIL = 'RESET_TEMPORARY_EMAIL'
export const CHECK_CONTACT_RECORD = 'CHECK_CONTACT_RECORD'
export const CHECK_LOGIN_RECORD = 'CHECK_LOGIN_RECORD'
export const CREATE_OR_UPDATE_CONTACT = 'CREATE_OR_UPDATE_CONTACT'
export const GET_OR_CREATE_LOGIN_RECORD = 'GET_OR_CREATE_LOGIN_RECORD'
export const RESET_REGISTRATION = 'RESET_REGISTRATION'
export const RESET_LOGIN = "RESET_LOGIN"
export const UPDATE_REGISTRATION_INFORMATION = "UPDATE_REGISTRATION_INFORMATION"
export const CHECK_CONTACT_EXISTS = "CHECK_CONTACT_EXISTS"

const localeToPreferredLanguage = {
    'en-US': 'English',
    'es-US': 'Spanish'
}

export function setTemporaryEmail(data) {
    return (dispatch) => {

        dispatch({
            type: SET_TEMPORARY_EMAIL,
            temporaryEmail: data
        })
    }
}

export function resetTemporaryEmail() {
    return (dispatch) => {

        dispatch({
            type: RESET_TEMPORARY_EMAIL,
            temporaryEmail: ''
        })
    }
}

export function resetRegistration() {
    return (dispatch) => {

        dispatch({
            type: RESET_REGISTRATION,
            registration: null
        })
    }
}

export function resetLogin() {
    return (dispatch) => {

        dispatch({
            type: RESET_LOGIN,
            login: null
        })
    }
}

export const bFlowAuthenticate = data => {
    return (dispatch, getState) => {
        const authenticate = Object.assign({}, data)

        authenticate.username = getState().login.username;
        authenticate.recordType = "Client";

        return axios
          .post('/api/login', authenticate)
          .then((response) => {
              dispatch({
                  type: SUBMIT_LOGIN,
                  login: response.data
              })
          })
          .catch(error => {
              throw new SubmissionError({_error: error.response.data.message})
          })
    }
}

export const checkContactRecord = data => {
    return (dispatch, getState) => {
        console.log("Bflow checkContactRecord-> " + data);
        const username = data.username
        return axios
            .post('/api/checkContact', "", {params: {username}})
            .then((response) => {
              let val = response.data === "" ? null : response.data
                dispatch({
                    type: CHECK_CONTACT_RECORD,
                    registration: val,
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const checkLoginRecord = data => {
  return (dispatch, getState) => {
    const username = data

    return axios
      .post('/api/checkLoginRecord', "", {params: {username}})
      .then((response) => {
        dispatch({
          type: CHECK_LOGIN_RECORD,
          login: response.data,
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}


export const createOrUpdateContact = data => {
  return (dispatch, getState) => {
    const registration = Object.assign({}, getState().registration)

    registration.employerCode = getState().employer ? getState().employer.code : '0000';
    registration.employerId = getState().employer ? getState().employer.id : registration.employerId;
    registration.preferredLanguage = localeToPreferredLanguage[getState().locale];
    registration.email = getState().temporaryEmail
    registration.firstName = data.firstName
    registration.lastName = data.lastName

    return axios
      .post('/api/contact/createOrUpdateContact', registration)
      .then((response) => {
        dispatch({
          type: CREATE_OR_UPDATE_CONTACT,
          registration: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export const getOrCreateLoginRecord = data => {
  return (dispatch, getState) => {

    const registration = Object.assign({}, getState().registration)

    registration.recordTypeId = data;
    console.log("registration object before sending to backend for process in PostgresLoginRepository: ")
    console.log(registration)
    return axios
      .post('/api/login/getOrCreateLoginRecord', registration)
      .then((response) => {
        dispatch({
          type: GET_OR_CREATE_LOGIN_RECORD,
          login: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export const passwordReset = data => {
    return (dispatch, getState) => {
        const login = Object.assign({}, data)

        login.username = getState().login.username;

        return axios
            .post('/api/login/passwordReset', login)
            .then((response) => {
                console.log("password reset email has been sent")
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const checkContactExists = data => {
    return (dispatch, getState) => {
        const username = data
        return axios
            .post('/api/checkContactExists', "", {params: {username}})
            .then((response) => {
                dispatch({
                    type: CHECK_CONTACT_EXISTS,
                    contactCreated: response.data,
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}