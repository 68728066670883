import React , { useState } from 'react'
import FormattedMessage from "../helpers/FormattedMessage";

export default function DisputeStep4(props) {

  return (
    <div>
      <div className='recommendations-gradient-color'>
        <div className='top-priority'>
          <FormattedMessage id='app.debt-report-recommendations.top-priority'/>
        </div>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.collections'/>
        </h2>
      </div>

      <div className='recommendations-page-wrapper'>
        <div className='work-on-this-page-container dispute-steps'>
          <div className='action-plan-subtitle margin-bottom-24-px'>
            <FormattedMessage id='app.debt-report-collections.dispute-step-1-title'/>
          </div>
          <div className='collections-dispute-steps-wrapper'>
            <h2 className='collections-dispute-instructions-step-heading'>
              <FormattedMessage id='app.debt-report-collections.dispute-step-4-subtitle'/>
            </h2>

            <p>
              <FormattedMessage id='app.debt-report-collections.dispute-step-4-text-1'/>
            </p>
            <div className='dispute-buttons-wrapper'>
              <div className='dispute-button-previous'>
                <button className='btn btn-primary btn-block btn-work-on-this btn-previous'
                        id='debt-report-reviewed-collections-yes'
                        onClick={()=>props.setCollectionPage("disputeStep3")}>
                  <FormattedMessage id='app.debt-report-collections.dispute-previous-step-btn'/>
                </button>
              </div>
              <div className='dispute-button-next'>
                <button className='btn btn-primary btn-block btn-work-on-this'
                        id='debt-report-reviewed-collections-yes'
                        onClick={()=>props.setCollectionPage("toQuestionnaire")}>
                  <FormattedMessage id='app.debt-report-collections.dispute-back-to-summary-btn'/>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}