import axios from 'axios'

export const GET_ENGLISH_COUNSELORS = 'GET_ENGLISH_COUNSELORS'
export const RANDOM_COUNSELOR = 'RANDOM_COUNSELOR'
export const ENGLISH_COUNSELORS_RETURNED = 'ENGLISH_COUNSELORS_RETURNED'
export const SPANISH_COUNSELORS_RETURNED = 'SPANISH_COUNSELORS_RETURNED'
export const GET_SPANISH_COUNSELORS = 'GET_SPANISH_COUNSELORS'
export const SET_ACTIVE_COUNSELORS = 'SET_ACTIVE_COUNSELORS'
export const ACTIVE_COUNSELOR = 'ACTIVE_COUNSELOR';
export const ENGLISH_CURRENTLY_WORKING_COUNSELORS = 'ENGLISH_CURRENTLY_WORKING_COUNSELORS';
export const ENGLISH_CURRENTLY_WORKING_COUNSELORS_RETURNED = 'ENGLISH_CURRENTLY_WORKING_COUNSELORS_RETURNED';
export const SPANISH_CURRENTLY_WORKING_COUNSELORS = 'SPANISH_CURRENTLY_WORKING_COUNSELORS';
export const SPANISH_CURRENTLY_WORKING_COUNSELORS_RETURNED = 'SPANISH_CURRENTLY_WORKING_COUNSELORS_RETURNED';
export const ACTIVE_CURRENTLY_WORKING_COUNSELORS = 'ACTIVE_CURRENTLY_WORKING_COUNSELORS';

export function getEnglishSpeakers() {
  return (dispatch) => {
    return axios
      .get('/api/get-english-counselors')
      .then(response => {
            dispatch({
                type: GET_ENGLISH_COUNSELORS,
                englishCounselors: response.data
            })

            dispatch({
                type: ENGLISH_COUNSELORS_RETURNED,
                englishCounselorsReturned: true
            })
      })
      .catch(() => {})
  }
}

export function getSpanishSpeakers() {
  return (dispatch) => {
    return axios
      .get('/api/get-spanish-counselors')
      .then(response => {
            dispatch({
                type: GET_SPANISH_COUNSELORS,
                spanishCounselors: response.data
            })

            dispatch({
                type: SPANISH_COUNSELORS_RETURNED,
                spanishCounselorsReturned: true
            })
      })
      .catch(() => {})
  }
}

export function setRandomCounselor() {
  return (dispatch, getState) => {
    let counselors = getState().locale === 'en-US' ? getState().englishCounselors : getState().spanishCounselors
      if (counselors !== null && counselors.length !== 0){
          dispatch({
            type: RANDOM_COUNSELOR,
            randomCounselorPick: counselors[0]
            })
      }
  }
}

export function setActiveCounselors(){
    return (dispatch, getState) =>{
        const {locale, englishCounselors, spanishCounselors} = getState()
        dispatch({
            type: SET_ACTIVE_COUNSELORS,
            activeCounselors: locale === 'en-US' ? englishCounselors : spanishCounselors
        })
    }
}

export function setActiveCounselor(counselor) {
  return (dispatch, getState) => {
    dispatch({
      type: ACTIVE_COUNSELOR,
      activeCounselor: counselor
    })
  }
}

export function getEnglishCurrentlyWorkingCounselors() {
    return (dispatch) => {
        return axios
            .get('/api/get-currently-working-english-counselors')
            .then(response => {
                dispatch({
                    type: ENGLISH_CURRENTLY_WORKING_COUNSELORS,
                    englishCurrentlyWorkingCounselors: response.data
                })

                dispatch({
                    type: ENGLISH_CURRENTLY_WORKING_COUNSELORS_RETURNED,
                    englishCurrentlyWorkingCounselorsReturned: true
                })
            })
            .catch(() => {})
    }
}

export function getSpanishCurrentlyWorkingCounselors() {
    return (dispatch) => {
        return axios
            .get('/api/get-currently-working-spanish-counselors')
            .then(response => {
                dispatch({
                    type: SPANISH_CURRENTLY_WORKING_COUNSELORS,
                    spanishCurrentlyWorkingCounselors: response.data
                })

                dispatch({
                    type: SPANISH_CURRENTLY_WORKING_COUNSELORS_RETURNED,
                    spanishCurrentlyWorkingCounselorsReturned: true
                })
            })
            .catch(() => {})
    }
}

export function setActiveCurrentlyWorkingCounselors(){
    return (dispatch, getState) =>{
        const {locale, englishCurrentlyWorkingCounselors, spanishCurrentlyWorkingCounselors} = getState()
        dispatch({
            type: ACTIVE_CURRENTLY_WORKING_COUNSELORS,
            activeCurrentlyWorkingCounselors: locale === 'en-US' ? englishCurrentlyWorkingCounselors : spanishCurrentlyWorkingCounselors
        })
    }
}
