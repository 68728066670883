export const IS_CREDIT_FORM_OPEN = 'IS_CREDIT_FORM_OPEN'

export function isCreditFormOpen(truthy) {
  let val = truthy === true ? true : false
  return {
    type: IS_CREDIT_FORM_OPEN,
    isCreditFormOpen: val
  }
}

