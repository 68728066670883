import React from 'react'
import {Link} from 'react-router-dom'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'
import {useSelector} from "react-redux";

function NewUserForm() {

  const employer = useSelector(state => state.employer);
  const dcl = useSelector(state => state.dcl)

  const isDCL = () => {
    return !!(employer && employer.code === "NTFP" && dcl && dcl.onboardingProductName === "DebtConsolidationLoan");
  }

  const continueLink = () => {
    return isDCL() ? "/NTFP?ProductName=DebtConsolidationLoan#benefits" : "/select-by-availability"
  }

  return (
      <div>
          <HeaderContainerAP/>

          <div className='container-ap newuser-form'>

            <div className='page-title'>
              <FormattedMessage id='app.newuser.title'/>
            </div>
            <div className='text-ap margin-bottom-32-px'>

              { isDCL() ?
                <div>
                  <FormattedMessage id='app.newuser.dcl.debtconsolidationloan.instruction1'/>
                  <a href={continueLink()}>
                    <FormattedMessage id='app.newuser.dcl.debtconsolidationloan.instruction2'/>
                  </a>
                </div>
              :
                <div>
                  <FormattedMessage id='app.newuser.instruction1'/>
                </div>}
            </div>
            <div className='submit-button-round margin-bottom-24-px'>
              <a href={continueLink()} id='register' className='btn btn-primary btn-block btn-lg'>
                <FormattedMessage id='app.common.continue.button'/>
              </a>
            </div>

            <div className='text-ap additional-links'>
              <Link to={`welcome`} id='welcome'>
                  <FormattedMessage id='app.newuser.alt-link'/>
              </Link>
            </div>
          </div>
          <FooterContainerAp/>
      </div>)
}

export default NewUserForm