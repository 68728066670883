import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import SimpleDebtSummaryChart from './simple-debt-summary-chart'
import SimpleDebtSummaryTable from './simple-debt-summary-table'
import DebtSummaryTradeAccount from './debt-summary-trade-account'

const options = { year: 'numeric', month: 'long',day: '2-digit'};


export default class DebtSummaryReport extends React.Component {
  constructor(props) {
    super(props);
  }

  setAccountDetail(accountDetail) {
    this.props.debtSummaryAccountDetail(accountDetail)

    this.props.verifyToken()
    this.props.getDebtReport(this.props.debtReport.contactId)
  }

  toggleCategoriesExpanded(categoryId) {
    let tmp = this.props.categoriesExpanded
    tmp[categoryId] = !tmp[categoryId]
    this.props.debtSummaryCategoriesExpanded(tmp)

    // for now, if there is an accountDetail displaying, get rid of it, which will send
    // user back to the overview page if they are on the detail page
    if (this.props.accountDetail.id !== null) {
      this.props.debtSummaryAccountDetail(this.props.accountDetail)
    }

    this.props.verifyToken()
    this.props.getDebtReport(this.props.debtReport.contactId)
  }

  debtSummarySubtile() {
    return (
      <div className='action-plan-leadoff margin-bottom-24-px'>
                      <FormattedMessage id='app.action-plan-debt-summary.subtitle'/>
                      <span className='action-plan-date'>
                        {new Intl.DateTimeFormat(
                            this.props.locale, options).format(
                            new Date(this.props.debtReport.creditPullDate))}.
                      </span>
                      <div></div>
                    </div>
    );
  }


  render() {
    return(
      this.props.debtReport && <div id='debt-report'>
          { this.props.accountDetail.id == null ? <div className='report-chart'>
                  <div>
                    {this.debtSummarySubtile()}
                  </div>
                  <SimpleDebtSummaryChart
                    debtReport={this.props.debtReport}
                    imageId={this.props.imageId}
                    />
                </div>: <div>{this.debtSummarySubtile()}</div>}

        { this.props.accountDetail.id == null ? <div><div className='report-table'>

            <SimpleDebtSummaryTable
              debtReport={this.props.debtReport}
              showTradeAccounts={true}
              imageId={this.props.imageId}
              categoriesExpanded={this.props.categoriesExpanded}
              toggleCategoriesExpanded={(categoryId) => this.toggleCategoriesExpanded(categoryId)}
              accountDetail={this.props.accountDetail}
              setAccountDetail={(accountDetail) => this.setAccountDetail(accountDetail)}
              />
          </div>
          </div>
           :
          <div className='account-details'>
            <DebtSummaryTradeAccount
              debtReport={this.props.debtReport}
              showTradeAccounts={true}
              imageId={this.props.imageId}
              categoriesExpanded={this.props.categoriesExpanded}
              toggleCategoriesExpanded={(categoryId) => this.toggleCategoriesExpanded(categoryId)}
              accountDetail={this.props.accountDetail}
              setAccountDetail={(accountDetail) => this.setAccountDetail(accountDetail)}
              updateTradeDebtAccountYesFlagInformationIncorrect={() => this.props.updateTradeDebtAccountYesFlagInformationIncorrect()}
              updateTradeDebtAccountNoFlagInformationCorrect={() => this.props.updateTradeDebtAccountNoFlagInformationCorrect()}
              getDebtReport={() => this.props.getDebtReport()}
              resetFlaggedInOnboarding={() => this.props.resetFlaggedInOnboarding()}
              />
          </div>
        }
      </div>
      )}
}
