import React from 'react'

export const NeutralEmoji = ({
                           style = {},
                           fill = '#000000',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 460 460',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-3" transform="translate(120.500000, 114.600000)">
        <g id="Group">
          <circle id="Oval" stroke="#AEE0E8" stroke-width="16" fill="#FFFFFF" cx="109" cy="109" r="101"></circle>
          <circle id="Oval" fill="#AEE0E8" cx="80" cy="72" r="16"></circle>
          <circle id="Oval" fill="#AEE0E8" cx="138" cy="72" r="16"></circle>
        </g>
        <path d="M64,139.5 L154,139.5" id="Line-3" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square"></path>
      </g>
    </g>

  </svg>;

export default NeutralEmoji