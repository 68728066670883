import React from 'react'

export const PiggyBank = ({
                              style = {},
                              fill = '#141006',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 448 512',
                          }) =>

    <svg
        width="30"
        height="28"
        // viewBox="0 0 25 23"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7881 4.72656H11.4548C10.7715 4.72656 10.1131 4.8224 9.47979 4.9849C9.47793 4.94586 9.47276 4.90766 9.46759 4.86955C9.46118 4.82222 9.45479 4.77503 9.45479 4.72656C9.45479 2.51823 11.2465 0.726562 13.4548 0.726562C15.6631 0.726562 17.4548 2.51823 17.4548 4.72656C17.4548 4.73281 17.4537 4.73802 17.4527 4.74323C17.4517 4.74844 17.4506 4.75365 17.4506 4.7599C17.234 4.74323 17.0131 4.72656 16.7881 4.72656ZM24.1215 10.0599H22.8923C22.5256 9.22656 21.9923 8.48906 21.334 7.8724L22.1215 4.72656H20.7881C19.5631 4.72656 18.4798 5.28906 17.7465 6.15573L17.6672 6.1442C17.3775 6.1019 17.0898 6.0599 16.7881 6.0599H11.4548C8.22979 6.0599 5.54229 8.35156 4.92146 11.3932H3.12146C2.50479 11.3932 2.01729 10.8307 2.14229 10.1932C2.23396 9.71823 2.67979 9.39323 3.16312 9.39323H3.20479C3.34229 9.39323 3.45479 9.28073 3.45479 9.14323V8.3099C3.45479 8.1724 3.34229 8.0599 3.20479 8.0599C2.01729 8.0599 0.958957 8.9099 0.808957 10.0849C0.625623 11.5099 1.73396 12.7266 3.12146 12.7266H4.78812C4.78812 14.9016 5.84646 16.8141 7.45479 18.0307V21.3932C7.45479 21.7599 7.75479 22.0599 8.12146 22.0599H10.7881C11.1548 22.0599 11.4548 21.7599 11.4548 21.3932V19.3932H16.7881V21.3932C16.7881 21.7599 17.0881 22.0599 17.4548 22.0599H20.1215C20.4881 22.0599 20.7881 21.7599 20.7881 21.3932V18.0307C21.2798 17.6599 21.7173 17.2224 22.0923 16.7266H24.1215C24.4881 16.7266 24.7881 16.4266 24.7881 16.0599V10.7266C24.7881 10.3599 24.4881 10.0599 24.1215 10.0599ZM18.7881 12.7266C18.4215 12.7266 18.1215 12.4266 18.1215 12.0599C18.1215 11.6932 18.4215 11.3932 18.7881 11.3932C19.1548 11.3932 19.4548 11.6932 19.4548 12.0599C19.4548 12.4266 19.1548 12.7266 18.7881 12.7266Z" fill="black"/>
    </svg>;


export default PiggyBank