import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import {updateTradeDebtAccount} from '../../actions/trade-debt-account-actions'
import {displayLoadingIcon} from '../../actions/loading-icon-action'
import ValidationService from '../../services/validation-service'
import TradeDebtAccountForm from '../../components/debt-report/trade-debt-account-form'
import {getDebtReport} from '../../actions/debt-report-actions'



function mapStateToProps(state) {
  return {
    updateTradeDebtAccount: state.updateTradeDebtAccount,
    formSyncErrors: getFormSyncErrors('trade-debt-account')(state),//gets user validation errors on form level
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoadingIcon(truthy) {
      dispatch(displayLoadingIcon(truthy))
    },
    getDebtReport() {
      return dispatch(getDebtReport())
    },
  }
}


const TradeDebtAccountFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({
    form: 'trade-debt-account',
    validate: ValidationService.interestRate,
    onSubmit: (values, dispatch, props) => {
      // dispatch(displayLoadingIcon(true))
      return dispatch(updateTradeDebtAccount(values)).then(() => {
        console.log("GETTING DR trade-debt-account-form-container onSubmit")
        dispatch(getDebtReport())
      })
    },
    onChange: (values, dispatch, props) => {
      // dispatch(displayLoadingIcon(true))
      return dispatch(updateTradeDebtAccount(values)).then(() => {
        console.log("GETTING DR trade-debt-account-form-container onChange")
        dispatch(getDebtReport())
      })
    },
    destroyOnUnmount: false
  })(injectIntl(TradeDebtAccountForm))
)

export default TradeDebtAccountFormContainer