import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {saveUserData} from "../../actions/link-money-actions";

export default function ActionPlanLinkMoneyRedirect () {

    const login = useSelector(state => state.login);
    const [dataSaved, useDataSaved] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    function grabEvents() {
        const events = useLocation().search;
        const eventParams = new URLSearchParams(events);
        const decodedData = decodeURIComponent(eventParams.toString()).slice(8, -1);
        // let userData = JSON.parse(decodedData);
        dispatch(saveUserData(login.contactId, decodedData));
        useDataSaved(true);
    }

    return (
        <div id='action-plan-link-money-redirect'>

                {dataSaved == false ? grabEvents() : null}

        </div>
    )
}