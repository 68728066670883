import {GET_SPANISH_COUNSELORS} from "../actions/counselor-actions.js"

const spanishCounselors = (state = null, action) => {
  switch (action.type) {
    case GET_SPANISH_COUNSELORS:
      return action.spanishCounselors
    default:
      return state
  }
}

export default spanishCounselors
