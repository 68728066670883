import React from 'react'

export const HowItWorksPhone = ({
                            style = {},
                            fill = '#fff',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 220 220',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <defs>
      <polygon id="path-1" points="0 0.289208333 219.710988 0.289208333 219.710988 219.999804 0 219.999804"></polygon>
    </defs>
    <g id="Page-9" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="howitworks2_1200+" transform="translate(-231.000000, -1075.000000)">
        <g id="Group-26" transform="translate(231.000000, 1075.000000)">
          <g id="Group-3">
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path d="M219.710988,110.144506 C219.710988,170.816054 170.527238,219.999804 109.85569,219.999804 C49.1841429,219.999804 -0.000261904762,170.816054 -0.000261904762,110.144506 C-0.000261904762,49.4729583 49.1841429,0.289208333 109.85569,0.289208333 C170.527238,0.289208333 219.710988,49.4729583 219.710988,110.144506" id="Fill-1" fill="#AFDFE7" mask="url(#mask-2)"></path>
          </g>
          <path d="M139.918885,197 L79.0811154,197 C72.4086154,197 67,191.559858 67,184.848464 L67,34.1515355 C67,27.4407996 72.4086154,22 79.0811154,22 L139.918885,22 C146.590731,22 152,27.4407996 152,34.1515355 L152,184.848464 C152,191.559858 146.590731,197 139.918885,197" id="Fill-4" fill="#143156"></path>
          <path d="M139.142751,28 L130.343371,28 C130.632059,28.4725922 130.801062,29.0239498 130.801062,29.6140337 L130.801062,31.4860239 C130.801062,33.2306769 129.371434,34.6582992 127.623527,34.6582992 L91.285724,34.6582992 C89.5877942,34.6582992 88.1989376,33.2713723 88.1989376,31.5766041 L88.1989376,29.6140337 C88.1989376,29.0239498 88.3679414,28.4725922 88.656629,28 L79.8579066,28 C74.9653435,28 71,31.9579598 71,36.8414127 L71,183.159244 C71,188.041384 74.9653435,192 79.8579066,192 L139.142751,192 C144.033999,192 148,188.041384 148,183.159244 L148,36.8414127 C148,31.9579598 144.033999,28 139.142751,28" id="Fill-6" fill="#FEFEFE"></path>
          <path d="M122.725009,85 L97.2749913,85 C94.3615324,85 92,82.6384676 92,79.7250087 L92,54.2749913 C92,51.3615324 94.3615324,49 97.2749913,49 L122.725009,49 C125.638468,49 128,51.3615324 128,54.2749913 L128,79.7250087 C128,82.6384676 125.638468,85 122.725009,85" id="Fill-8" fill="#EE3E42"></path>
          <path d="M115,62.5105383 L115,71.4894617 C115,72.323649 114.319467,73 113.480595,73 L97.5194054,73 C96.6798871,73 96,72.323649 96,71.4894617 L96,62.5105383 C96,61.676351 96.6798871,61 97.5194054,61 L113.480595,61 C114.319467,61 115,61.676351 115,62.5105383" id="Fill-10" fill="#FEFEFE"></path>
          <path d="M122.451378,60.1413819 L117.516207,63.0252509 C117.19665,63.2115127 117,63.5566074 117,63.9297689 L117,71.0702311 C117,71.4433926 117.19665,71.7884873 117.516207,71.9747491 L122.451378,74.8586181 C123.139654,75.2604844 124,74.7578326 124,73.9534622 L124,61.0465378 C124,60.2421674 123.139654,59.7395156 122.451378,60.1413819" id="Fill-12" fill="#FEFEFE"></path>
          <path d="M122.725009,131 L97.2749913,131 C94.3615324,131 92,128.637851 92,125.724445 L92,100.274895 C92,97.3614891 94.3615324,95 97.2749913,95 L122.725009,95 C125.638468,95 128,97.3614891 128,100.274895 L128,125.724445 C128,128.637851 125.638468,131 122.725009,131" id="Fill-14" fill="#EE3E42"></path>
          <path d="M118.528917,124.938321 C118.350261,124.982921 118.165132,125.004898 117.980003,124.999081 C110.749611,124.792238 105.23846,119.742045 104.119918,118.638668 C104.113445,118.63479 104.111503,118.629619 104.106972,118.625741 C101.999995,116.744765 97.9750558,112.47411 97.0248128,106.635329 C96.9956841,106.451756 96.9924475,106.26689 97.0138086,106.083964 C97.1005474,105.31606 97.4507391,103.782839 99.5894334,101.896046 C99.5894334,101.896046 101.718418,99.5735902 103.710821,102.406689 C105.704519,105.237848 107.309835,106.507991 104.475935,108.586114 C101.900958,110.472261 103.459667,112.172895 107.082631,115.365383 C107.322133,115.575457 107.552574,115.777775 107.777188,115.97363 C111.602111,119.311553 113.487709,120.750403 115.061306,117.881753 C116.748829,114.801736 118.221447,116.22378 121.296144,117.822932 C124.370193,119.422085 122.346072,121.83568 122.346072,121.83568 C120.755645,124.201444 119.279143,124.750223 118.528917,124.938321" id="Fill-16" fill="#FEFEFE"></path>
          <path d="M122.725009,176 L97.2749913,176 C94.3615324,176 92,173.637851 92,170.724445 L92,145.275555 C92,142.362149 94.3615324,140 97.2749913,140 L122.725009,140 C125.638468,140 128,142.362149 128,145.275555 L128,170.724445 C128,173.637851 125.638468,176 122.725009,176" id="Fill-18" fill="#EE3E42"></path>
          <path d="M95,154.926317 L95,157.95719 C95,161.2166 97.7188848,163.884159 101.042483,163.884159 L101.140724,163.884159 L99.0590773,168.856614 C99.012612,168.967952 99.2084301,169.04934 99.3206107,168.965348 L106.080651,163.884159 L118.958845,163.884159 C122.281779,163.884159 125,161.2166 125,157.95719 L125,154.926317 C125,151.666908 122.281779,149 118.958845,149 L101.042483,149 C99.3119814,149 97.746764,149.724024 96.6428808,150.876472 C95.6259542,151.938417 95,153.363676 95,154.926317" id="Fill-20" fill="#FEFEFE"></path>
          <path d="M101,158.5 L119,158.5" id="Stroke-22" stroke="#EE3E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
          <path d="M101,154.5 L119,154.5" id="Stroke-24" stroke="#EE3E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
      </g>
    </g>

  </svg>;

export default HowItWorksPhone