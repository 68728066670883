import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Field from '../helpers/Field'
import {reduxForm} from "redux-form";
import {injectIntl} from "react-intl";
import Option from "../helpers/Option";
import {submitPrescreenerAnswer3} from "../../actions/dcl-actions";
import {connect} from "react-redux";

function DclPrescreener3(props){

    const { pristine, submitting } = props;

    return (
      <div className=''>
                  <form onSubmit={props.handleSubmit}>
                      <Field name='dclCreditScore' component='select' id='dclCreditScore'
                             className='form-control'>
                          <option value='' disabled>
                              <FormattedMessage id='app.dcl-prescreener.placeholder'/>
                          </option>
                          <Option value='yes-under-620' i18nPrefix='app.dcl-prescreener.credit-score.answer.'/>
                          <Option value='yes-620-or-over' i18nPrefix='app.dcl-prescreener.credit-score.answer.'/>
                          <Option value='no' i18nPrefix='app.dcl-prescreener.credit-score.answer.'/>
                      </Field>

                      <div className='submit-button-round '>
                          <button id='submit'
                                  type={'submit'}
                                  className='btn btn btn-primary btn-block'
                                  disabled={pristine || submitting}
                                  aria-disabled={pristine || submitting}
                          >
                              <FormattedMessage id='app.b-flow.email-form.button.continue'/>
                          </button>
                      </div>
                  </form>
      </div>)
}

const mapStateToProps = (state) => {
    return {
        initialValues: {
            dclCreditScore: '',
        }
    }
}

const dclPrescreener3Form = reduxForm({
    form: 'DclPrescreener3Form',
    onSubmit: (values, dispatch, props) => {
        return dispatch(submitPrescreenerAnswer3(props.values.dclCreditScore))
    },
    destroyOnUnmount: false
})(injectIntl(DclPrescreener3))

export default connect(mapStateToProps)(dclPrescreener3Form)