import React from 'react'

export const MessagingPlain = ({
                            style = {},
                            fill = '#000000',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 22 21',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.14 4.35986H1.98C0.886476 4.35986 0 5.24634 0 6.33986V18.2199C0 18.745 0.208606 19.2486 0.579928 19.6199C0.95125 19.9913 1.45487 20.1999 1.98 20.1999H19.14C20.2335 20.1999 21.12 19.3134 21.12 18.2199V6.33986C21.12 5.24634 20.2335 4.35986 19.14 4.35986ZM19.14 6.33986V8.02653C18.215 8.77653 16.7408 9.9507 13.5883 12.419C13.4715 12.5109 13.3354 12.6245 13.1853 12.7497L13.1853 12.7497C12.4425 13.3695 11.3565 14.2757 10.56 14.2632C9.76372 14.2764 8.67811 13.3703 7.93538 12.7504L7.93534 12.7503C7.78498 12.6248 7.64868 12.5111 7.53167 12.419C4.38 9.9482 2.905 8.77653 1.98 8.02653V6.33986H19.14ZM1.98 10.564V18.2199H19.1375V10.564C18.1925 11.3165 16.8517 12.3732 14.8083 13.9732C14.6757 14.0777 14.5303 14.1988 14.3733 14.3294C13.4301 15.1145 12.0686 16.2477 10.5583 16.2399C9.04375 16.2484 7.66841 15.1014 6.73821 14.3257C6.58186 14.1953 6.43808 14.0754 6.30833 13.9732C4.26583 12.3732 2.925 11.314 1.98 10.564Z" fill="black"/>
  </svg>;

export default MessagingPlain