import React from 'react'
import FormattedMessage from './FormattedMessage'

export const CounselorBubbleDebtSummary = ({i18n, imageId, wrapper}) => (
    <div>
        <div className={wrapper}>
            <div className='counselor-picture'>
                {
                    imageId ?
                        <img src={`/api/counselor/${imageId}/image`} className='img-circle'/>
                        :
                        <div className='counselor-image-default img-circle'></div>
                }
            </div>

            <div className='unfamiliar-advice-text-wrapper margin-bottom-24-px'>
                <div className='unfamiliar-advice'>
                    <FormattedMessage id={i18n}/>
                    <div className='unfamiliar-advice-triangle-wrapper'>
                        <div className='unfamiliar-advice-triangle-border'>
                            <div className='unfamiliar-advice-triangle'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='counselor-triangle-picture-wrapper-mobile'>
                <div className='counselor-picture-mobile'>
                {
                    imageId ?
                        <img src={`/api/counselor/${imageId}/image`} className='img-circle'/>
                        :
                        <div className='counselor-image-default img-circle'></div>
                }
                    <div className='unfamiliar-advice-triangle-wrapper-mobile'>
                        <div className='unfamiliar-advice-triangle-border-mobile'>
                            <div className='unfamiliar-advice-triangle-mobile'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default CounselorBubbleDebtSummary