import React from 'react'
import {FormattedMessage} from 'react-intl'
import SimpleDebtSummaryChart from './simple-debt-summary-chart'
import SimpleDebtSummaryTable from './simple-debt-summary-table'
import Icon from "../../../resources/icons";
import DebtSummaryReport from "./debt-summary-report";
import DebtReportCollections from "./debt-report-collections";
import DebtReportNoCollections from "./debt-report-no-collections";
import moment from "moment";

export default class DebtReportRecommendations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab,
      collectionPage: "all",
    }
  }

  toggleTab(e, tabName) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.setState({
      activeTab: tabName,
      collectionPage: "all",
    })
    this.props.updateActiveTab(tabName)
  }

  getObjectById(id) {
    let cat = null
    let cats = this.props.debtReport.debtCategories
    for (let i = 0; i < cats.length; i++) {
      if (cats[i].id === id) {
        cat = cats[i]
      }
    }
    return cat
  }

  firstBookedAppointmentStr() {
    if (this.props.bookedAppointments && this.props.bookedAppointments.length > 0) {
      let appt = this.props.bookedAppointments[0]
      let utcMoment = moment(appt.startDateTime)
      let localMoment = utcMoment.tz(appt.startDateTime, appt.timezone)
      let month = localMoment.format('MMMM')
      let time = localMoment.format('h:mm A')
      let day = localMoment.format('D')

      return month + ' ' + day + ', ' + time
    } else {
      return ''
    }
  }

  renderNoCollections() {
    return (
      <DebtReportNoCollections firstBookedAppointmentStr={this.firstBookedAppointmentStr()}/>
    )
  }

  renderCollections(cat) {
    cat.tradeDebtAccounts = cat.tradeDebtAccounts.filter(account => account.balance !== 0)
    return (
      <DebtReportCollections cat={cat}
                             firstBookedAppointmentStr={this.firstBookedAppointmentStr()}
                             debtReportCollectionUpdate={(account, know) => this.props.debtReportCollectionUpdate(account, know)}
                             nextPage={() => this.props.nextPage()}
                             collectionPage={this.state.collectionPage}
                             setCollectionPage={(page)=>this.setState({collectionPage: page})}
                             history={this.props.history}
      />
    )
  }

  renderDebtReport(fromActionPlan, imageId) {
    return (
      <div>
        <div className='recommendations-page-container collections'>
          <div className='recommendations-gradient-color'>
            <div className='top-priority'>
              <FormattedMessage id='app.debt-report-recommendations.debt-report-header'/>
            </div>
            <h2>
              <FormattedMessage id='app.debt-report-recommendations.debt-report-text-1'/>
            </h2>
          </div>
          { fromActionPlan ?
            <div className='recommendations-page-wrapper'>
              <DebtSummaryReport
                debtReport={this.props.debtReport}
                imageId={imageId}
                locale={this.props.locale}
                accountDetail={this.props.accountDetail}
                debtSummaryAccountDetail={(accountDetail) => this.props.debtSummaryAccountDetail(accountDetail)}
                categoriesExpanded={this.props.categoriesExpanded}
                debtSummaryCategoriesExpanded={(categoriesExpanded) => this.props.debtSummaryCategoriesExpanded(categoriesExpanded)}
                verifyToken={() => this.props.verifyToken()}
                updateTradeDebtAccountYesFlagInformationIncorrect={() => this.props.updateTradeDebtAccountYesFlagInformationIncorrect()}
                updateTradeDebtAccountNoFlagInformationCorrect={() => this.props.updateTradeDebtAccountNoFlagInformationCorrect()}
                getDebtReport={() => this.props.getDebtReport()}
                resetFlaggedInOnboarding={() => this.props.resetFlaggedInOnboarding()}
              />
            </div>
          :
            <div className='recommendations-page-wrapper'>
              <p>
              <FormattedMessage id='app.debt-report.total-debt-subtitle'/>
              </p>

              <div className='onboarding-pie-chart'>
                <SimpleDebtSummaryChart debtReport={this.props.debtReport} />
                <SimpleDebtSummaryTable
                  debtReport={this.props.debtReport}
                  showTradeAccounts={false}
                  imageId={imageId}
                />
              </div>

              <div className='submit-button-round sixty-percent-btn'>
                <button className='btn btn-primary btn-block btn-work-on-this'
                        id='debt-report-reviewed-collections-yes'
                        onClick={()=>this.props.nextPage()}>
                  <FormattedMessage id='app.common.continue.button'/>
                </button>
              </div>
            </div>
          }
          </div>
      </div>
    )
  }

  render() {

    const imageId = this.props.activeCounselor ? this.props.activeCounselor.userId :
      (this.props.randomCounselor ? this.props.randomCounselor.userId : '')

    return (

          <div className='recommendations-page'>
            {
              this.renderDebtReport(this.props.fromActionPlan, imageId)
            }
          </div>
    )
  }
}
