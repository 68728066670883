import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import FooterActionPlan from '../footer-action-plan';
import Icon from "../../../resources/icons";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import AppointmentDetail from "../appointment-detail";
import {navCurrentComponent, navEntered} from "../../actions/nav-actions";
import ActionPlanNav from "./action-plan-nav";
import ActionPlanMobileHome from "./action-plan-mobile-home";
import {logout} from "../../actions/logout-actions";
import {toggleLocale} from "../../actions/locale-actions";
import ActionPlanMobileHomeSelectedItem from "./action-plan-mobile-home-selected-item";
import NavComponentTitles from "../helpers/NavComponentTitles";
import NavComponentIcons from "../helpers/NavComponentIcons";
import ExternalLinkButton from "../helpers/ExternalLinkButton";
import {getBookedAppointments, getCompletedAttendedAppointments} from "../../actions/calendly-actions";
import ActionPlanLinkMoneyModal from "./action-plan-link-money-modal";
import {getLinkedAccounts, displayLinkMoneyModal} from "../../actions/link-money-actions";
import ActionPlanDashboardLinkMoney from "./action-plan-dashboard-link-money";
import ActionPlanDashboardDcl from "./action-plan-dashboard-dcl";

export default function ActionPlanDashboard(props) {

  const [currentTempComponent, setCurrentTempComponent] = useState(null)

  const locale = useSelector(state => state.locale)
  const login = useSelector(state => state.login)
  const registration = useSelector(state => state.registration)
  const employer = useSelector(state => state.employer)
  const bookedAppointments = useSelector(state => state.bookedAppointments)
  const entered = useSelector(state => state.navEntered)
  const currentComponent = useSelector(state => state.navCurrentComponent)
  const englishCounselors = useSelector(state => state.englishCounselors)
  const linkMoneyAccounts = useSelector(state => state.linkMoneyAccounts)
  const employerLoaded = useSelector(state => state.employerLoadedForNav)
  const dcl = useSelector(state => state.dcl)


  const dispatch = useDispatch();
  const history = useHistory();

  useEffect( () => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=>{
    if (login) {
      dispatch(getBookedAppointments()).then(() => {
        dispatch(getCompletedAttendedAppointments())
      })
    }
    dispatch(getLinkedAccounts())
  }, [])

  useEffect(() => {
    if (linkMoneyAccounts && linkMoneyAccounts.length > 0) {
      dispatch(displayLinkMoneyModal(false))
    }
  })

  useEffect(() => {
    if (entered === false && window.innerWidth >= 650) {
      dispatch(navCurrentComponent('ActionPlanDashboard'))
    }
    dispatch(navEntered(true))
  }, [])

  function apptDetail(appt, renderFromDashboard=false) {
    return <AppointmentDetail appt={appt}
                              renderFromDashboard={renderFromDashboard}
                              activeCurrentlyWorkingCounselors={englishCounselors}
    />
  }

  function determineAssessmentStatus(){
    if (registration.clientSmIntakeComplete === null || registration.clientSmIntakeComplete === "false") {
      return (<span className='dashboard-status-incomplete'> <FormattedMessage id='app.action-plan-dashboard.status-incomplete'/></span>)
    } else {
      return (<span className='dashboard-status-complete'> <FormattedMessage id='app.action-plan-dashboard.status-review-your-responses'/></span>)
    }
  }

  function getLatestAppt() {
    if (bookedAppointments && bookedAppointments.length > 0) {
      return bookedAppointments[0]
    } else {
      return null;
    }
  }

  function displayLatestAppointment() {
    let appt = getLatestAppt()

    if (appt == null) {
      return (
        <div className='dashboard-padding margin-bottom-8-px'>
          <FormattedMessage id='app.action-plan-sessions.no-upcoming'/>
        </div>
      )
    } else {
      return (
        <div className='session-wrapper upcoming-session-wrapper'>
          {apptDetail(appt, true)}
        </div>
      )
    }
  }

  function mobileDashboard() {
    return <div>
      <ActionPlanMobileHome navItemClick={(componentName) => dispatch(navCurrentComponent(componentName))}
                            logout={() => dispatch(logout())}
                            localeToggle={() => dispatch(toggleLocale(locale))}
                            firstName={registration.firstName}
                            latestServiceProgram={registration.latestServiceProgram}
                            setCurrentTempComponent={(currentTempComponent) => setCurrentTempComponent(currentTempComponent)}
                            employer={employer}
                            registration={registration}
      />
    </div>
  }

  function goToSurveyMonkey() {
    return (
        <ExternalLinkButton
          as="a"
          href={registration.clientSmEditLink}>
          <FormattedMessage id='app.action-plan-dashboard.button.complete-profile'/>
        </ExternalLinkButton>
      )
  }

  function renderLinkMoneyModal() {
    if (login.loginCount <= 1 && linkMoneyAccounts.length < 1 && employerLoaded && employer.code !== 'h310') {
      if (dcl !== null && dcl.onboardingProductStatus == 'Accepted' || registration.dclCriteria == 'Accepted') {
        return ''
      } else {
        return <div>
          <ActionPlanLinkMoneyModal/>
        </div>
      }
    }
  }

  function displayProductDashboard() {
    if (employerLoaded && employer.code !== 'h310') {
      if (dcl !== null && dcl.onboardingProductStatus == 'Accepted' || registration.dclCriteria == 'Accepted') {
        return <div className='action-plan-dashboard-settings-sessions-wrapper action-plan-dashboard-accounts-linked'>
          <ActionPlanDashboardDcl />
        </div>
      } else {
        return <div className='action-plan-dashboard-settings-sessions-wrapper action-plan-dashboard-accounts-linked'>
          <ActionPlanDashboardLinkMoney/>
        </div>
      }
    }
  }

  function dashboard() {
    return <div>

      {renderLinkMoneyModal()}

      <ActionPlanNav />
      <div id='action-plan-content'>
        <div className='action-plan-title-wrapper margin-bottom-24-px'>
          <div className='action-plan-title-background-gradient'></div>
          <div className='action-plan-title margin-bottom-8-px'>
            <FormattedMessage id='app.action-plan-nav.welcome-name'/> {registration.firstName}!
          </div>
        </div>

        <div className='action-plan-dashboard-container'>

          { employerLoaded && employer.code !== 'h310' && dcl !== null && dcl.onboardingProductStatus == 'Accepted' || registration.dclCriteria == 'Accepted' ?
            <div>
                <div className='action-plan-title'>
                  <FormattedMessage id='app.action-plan-dashboard.dcl.header-title'/>
                </div>
                <p>
                  <FormattedMessage id='app.action-plan-dashboard.dcl.header-description'/>
                </p>
            </div>
              : ''
          }

          <div className='action-plan-dashboard-settings-sessions-container'>

            {displayProductDashboard()}

            <div className='action-plan-dashboard-settings-sessions-wrapper action-plan-dashboard-sessions'>
              <div className='action-plan-dashboard-settings-sessions-title'>
                <div className='action-plan-dashboard-settings-session-title-text-area'>
                  <div className='nav-page-logo dashboard-icon'>
                    <Icon name='calendar'/>
                  </div>
                  <div className='dashboard-title'>
                    <FormattedMessage id='app.action-plan-dashboard.my-sessions'/>
                  </div>
                </div>
              </div>
              <div className='dashboard-sessions'>
                <div className='dashboard-main-wrapper'>
                  <div className='dashboard-main'>
                    {displayLatestAppointment()}
                  </div>
                </div>

                <div className='dashboard-settings-btn margin-bottom-16-px'>
                  <div className='submit-button-round'>
                    <button id='show-details'
                      type={'button'}
                      className='btn btn-primary btn-block btn-lg margin-bottom-16-px'
                      onClick={(e)=> history.push('/action-plan/sessions')}
                      >
                      <FormattedMessage id='app.action-plan-dashboard.button.view-sessions'/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FooterActionPlan/>
        </div>

      </div>
    </div>
  }

  return (
    login && login.nextPage == null && <div id='action-plan-dashboard'>
      {
        currentComponent === '' ?
          <div>{mobileDashboard()}</div> : null
      }
      {
        currentComponent === 'ActionPlanDashboard' ?
          <div>{dashboard()}</div> : null
      }

      {
        currentTempComponent ? (
          <ActionPlanMobileHomeSelectedItem
            key={'ActionPlanMobileHomeSelectedItem'}
            title={NavComponentTitles[currentTempComponent]}
            icon={NavComponentIcons[currentTempComponent]}
            currentComponent={currentComponent}
            clearCurrentTempComponent={() => setCurrentTempComponent(null)}/>
        ) : null
      }
    </div>
  )
}
