import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import CustomerPortalFooter from "./customer-portal-footer";
import CustomerPortalNav from "./customer-portal-nav";
import CustomerPortalContact from "./customer-portal-contact";

function CustomerPortalFaq(props) {

    useEffect(() => {
      window.scrollTo(0, 0)
    })

    return (
      <div id='customer-portal'>
        <CustomerPortalNav />
        <div id='customer-portal-content'>
          <div id='ap-tos' className={`customer-portal-pages margin-bottom-24px`}>
             <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title-background-gradient'></div>
              <div className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.customer-portal.dashboard-header'/>
              </div>
            </div>

            <div className='customer-portal-dashboard-container'>
              CP FAQs

                <CustomerPortalFooter />
                <CustomerPortalContact />

            </div>
          </div>
        </div>
      </div>
    )
}

export default (injectIntl(CustomerPortalFaq))