import React from 'react'

export const HowItWorksTalkWithCoach = ({
                                  style = {},
                                  fill = '#fff',
                                  width = '100%',
                                  className = '',
                                  height = '100%',
                                  viewBox = '0 0 220 220',
                                }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <defs>
      <polygon id="path-1" points="0 0.288154303 219.710988 0.288154303 219.710988 219.346789 0 219.346789"></polygon>
    </defs>
    <g id="Page-9" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="howitworks2_1200+" transform="translate(-737.000000, -1075.000000)">
        <g id="Group-28" transform="translate(737.000000, 1075.000000)">
          <g id="Group-3" transform="translate(0.000000, 0.364926)">
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path d="M219.710988,109.817472 C219.710988,170.308985 170.527238,219.346789 109.85569,219.346789 C49.1841429,219.346789 -0.000261904762,170.308985 -0.000261904762,109.817472 C-0.000261904762,49.3259585 49.1841429,0.288154303 109.85569,0.288154303 C170.527238,0.288154303 219.710988,49.3259585 219.710988,109.817472" id="Fill-1" fill="#AFDFE7" mask="url(#mask-2)"></path>
          </g>
          <path d="M161.339357,56.4108724 L161.339357,77.8370445 C161.339357,89.466362 151.796857,98.980546 140.132929,98.980546 L139.783286,98.980546 L147.091738,116.719596 C147.256738,117.119122 146.569238,117.408973 146.173107,117.107371 L122.447155,98.980546 L96.0366786,98.980546 C84.37275,98.980546 74.8295952,89.466362 74.8295952,77.8370445 L74.8295952,56.4108724 C74.8295952,44.7822077 84.37275,35.2667181 96.0366786,35.2667181 L140.132929,35.2667181 C146.204536,35.2667181 151.697988,37.84927 155.572214,41.9626825 C159.142631,45.7509911 161.339357,50.8351454 161.339357,56.4108724" id="Fill-4" fill="#143156"></path>
          <path d="M94.524244,52.1267478 L143.43103,52.1267478" id="Stroke-6" stroke="#FEFEFE" strokeWidth="2" strokeLinecap="round"></path>
          <path d="M94.524244,65.3227656 L143.43103,65.3227656" id="Stroke-8" stroke="#FEFEFE" strokeWidth="2" strokeLinecap="round"></path>
          <path d="M94.524244,78.5187181 L143.43103,78.5187181" id="Stroke-10" stroke="#FEFEFE" strokeWidth="2" strokeLinecap="round"></path>
          <path d="M86.7613869,137.303697 C86.7613869,144.624409 80.8096012,150.559187 73.4671012,150.559187 C66.1246012,150.559187 60.1728155,144.624409 60.1728155,137.303697 C60.1728155,129.982985 66.1246012,124.048861 73.4671012,124.048861 C80.8096012,124.048861 86.7613869,129.982985 86.7613869,137.303697" id="Fill-12" fill="#EE3E42"></path>
          <path d="M45.6335655,181.308398 L45.6335655,176.149822 C45.6335655,163.6973 55.7581488,153.603412 68.2470774,153.603412 L78.6866012,153.603412 C91.1761845,153.603412 101.300768,163.6973 101.300768,176.149822 L101.300768,181.308398 C101.300768,183.401335 99.5983869,185.098012 97.4992202,185.098012 L49.4344583,185.098012 C47.3352917,185.098012 45.6335655,183.401335 45.6335655,181.308398" id="Fill-14" fill="#EE3E42"></path>
          <path d="M159.538762,137.303697 C159.538762,144.624409 153.586321,150.559187 146.243821,150.559187 C138.901321,150.559187 132.95019,144.624409 132.95019,137.303697 C132.95019,129.982985 138.901321,124.048861 146.243821,124.048861 C153.586321,124.048861 159.538762,129.982985 159.538762,137.303697" id="Fill-16" fill="#EE3E42"></path>
          <path d="M118.410286,181.308398 L118.410286,176.149822 C118.410286,163.6973 128.534869,153.603412 141.024452,153.603412 L151.463976,153.603412 C163.95356,153.603412 174.078143,163.6973 174.078143,176.149822 L174.078143,181.308398 C174.078143,183.401335 172.376417,185.098012 170.276595,185.098012 L122.211833,185.098012 C120.112667,185.098012 118.410286,183.401335 118.410286,181.308398" id="Fill-18" fill="#EE3E42"></path>
          <path d="M60.3605357,76.4595312 L60.3605357,85.2399466 C60.3605357,94.6836261 68.1096429,102.410392 77.5814286,102.410392 L77.8655952,102.410392 L71.9308333,116.815496 C71.7966071,117.139947 72.3544643,117.375614 72.6772619,117.130807 L91.9442857,102.410392 L113.391667,102.410392 C122.862798,102.410392 130.61256,94.6836261 130.61256,85.2399466 L130.61256,76.4595312 C130.61256,67.0158516 122.862798,59.2890861 113.391667,59.2890861 L77.5814286,59.2890861 C72.6510714,59.2890861 68.1901786,61.3859407 65.0433929,64.7264154 C62.1447619,67.8024985 60.3605357,71.9315786 60.3605357,76.4595312" id="Fill-20" fill="#FEFEFE"></path>
          <path d="M60.3605357,76.4595312 L60.3605357,85.2399466 C60.3605357,94.6836261 68.1096429,102.410392 77.5814286,102.410392 L77.8655952,102.410392 L71.9308333,116.815496 C71.7966071,117.139947 72.3544643,117.375614 72.6772619,117.130807 L91.9442857,102.410392 L113.391667,102.410392 C122.862798,102.410392 130.61256,94.6836261 130.61256,85.2399466 L130.61256,76.4595312 C130.61256,67.0158516 122.862798,59.2890861 113.391667,59.2890861 L77.5814286,59.2890861 C72.6510714,59.2890861 68.1901786,61.3859407 65.0433929,64.7264154 C62.1447619,67.8024985 60.3605357,71.9315786 60.3605357,76.4595312 Z" id="Stroke-22" stroke="#143156" strokeWidth="3"></path>
          <path d="M75.0527381,74.3806291 L116.290952,74.3806291" id="Stroke-24" stroke="#143156" strokeWidth="2" strokeLinecap="round"></path>
          <path d="M75.0527381,86.6044036 L116.290952,86.6044036" id="Stroke-26" stroke="#143156" strokeWidth="2" strokeLinecap="round"></path>
        </g>
      </g>
    </g>
  </svg>;

export default HowItWorksTalkWithCoach