import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import TermsText from "../helpers/TermsText";
import ActionPlanNav from "./action-plan-nav";

export default class ActionPlanTermsOfService extends React.Component {

  constructor(props){
      super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)

  }

  render() {

    const { intl } = this.props


    return (
      <div id='ap-tos' >
        <ActionPlanNav />
        <div id='action-plan-content'>
          <div className='action-plan-breadcrumbs'>
          </div>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title margin-bottom-8-px'>
                  <FormattedMessage id='app.action-plan-nav.terms-of-service'/>
              </div>
          </div>

          <p><b><FormattedMessage id='app.terms.terms.title.revision.date'/></b></p>

          <TermsText intl={intl}
                     employer={this.props.employer}
                     employerCode={this.props.employerCode} />
        </div>
      </div>
    )
  }
}
