import {connect} from 'react-redux'
import {getPendingTakeActionToday, setAchievedTakeActionToday, getAchievedTakeActionToday} from '../actions/take-action-today-actions'
import TakeActionTodayPendingForm from '../components/take-action-today-pending-form'

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAchievedTakeActionToday(id) {
      dispatch(setAchievedTakeActionToday(id)).then(() => {
        getPendingTakeActionToday()
        getAchievedTakeActionToday()
      })
    }
  }
}

const TakeActionTodayPendingFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TakeActionTodayPendingForm)

export default TakeActionTodayPendingFormContainer
