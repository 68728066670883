import {GET_ENGLISH_COUNSELORS} from "../actions/counselor-actions.js"

const englishCounselors = (state = null, action) => {
  switch (action.type) {
    case GET_ENGLISH_COUNSELORS:
      return action.englishCounselors
    default:
      return state
  }
}

export default englishCounselors
