import React, {useState} from 'react'
import CustomerPortalFooter  from './customer-portal-footer';
import {injectIntl} from "react-intl";
import CustomerPortalAccountHeader from "./customer-portal-account-header";
import CustomerPortalAccountLanding from "./customer-portal-account-landing";
import CustomerPortalAccountSettings from "./customer-portal-account-settings";
import CustomerPortalAccountSettingsName from "./customer-portal-account-settings-name";
import CustomerPortalAccountSettingsPhone from "./customer-portal-account-settings-phone";
import CustomerPortalAccountSettingsEmail from "./customer-portal-account-settings-email";
import CustomerPortalAccountSettingsSecurity from "./customer-portal-account-settings-security";
import CustomerPortalNav from "./customer-portal-nav";
import CustomerPortalContact from "./customer-portal-contact";

function CustomerPortalMyAccount(props) {

  const pageAccLanding = "customer-portal-my-account"
  const pageAccSettings = "customer-portal-account-settings"
  const pageAccSettingsName = "customer-portal-account-settings-name"
  const pageAccSettingsPhone = "customer-portal-account-settings-phone"
  const pageAccSettingsEmail = "customer-portal-account-settings-email"
  const pageAccSecurity = "customer-portal-account-security"
  const [selectedPage, setSelectedPage] = useState(pageAccLanding)

  const onSimpleClick = (e, componentName) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()
    setSelectedPage(componentName);
  }

    return(
      <div id='customer-portal'>
        <CustomerPortalNav />
        <div id='customer-portal-content'>
          <div id='customer-portal-my-account' className='customer-portal-pages'>

            <CustomerPortalAccountHeader />

            <div className='customer-portal-dashboard-container'>
              {selectedPage === pageAccLanding ?
                <CustomerPortalAccountLanding onSimpleClick={(e, componentName) => onSimpleClick(e, componentName)}
                                              pageAccLanding={pageAccLanding}
                                              pageAccSettings={pageAccSettings}
                                              pageAccSecurity={pageAccSecurity}
                />: null}
              {selectedPage === pageAccSettings ?
                <CustomerPortalAccountSettings onSimpleClick={(e, componentName) => onSimpleClick(e, componentName)}
                                               pageAccLanding={pageAccLanding}
                                               pageAccSettings={pageAccSettings}
                                               pageAccSettingsName={pageAccSettingsName}
                                               pageAccSettingsPhone={pageAccSettingsPhone}
                                               pageAccSettingsEmail={pageAccSettingsEmail}
                />: null}
              {selectedPage === pageAccSettingsName ?
                <CustomerPortalAccountSettingsName onSimpleClick={(e, componentName) => onSimpleClick(e, componentName)}
                                                   pageAccSettings={pageAccSettings} />: null}
              {selectedPage === pageAccSettingsPhone ?
                <CustomerPortalAccountSettingsPhone onSimpleClick={(e, componentName) => onSimpleClick(e, componentName)}
                                                    pageAccSettings={pageAccSettings} />: null}
              {selectedPage === pageAccSettingsEmail ?
                <CustomerPortalAccountSettingsEmail onSimpleClick={(e, componentName) => onSimpleClick(e, componentName)}
                                                    pageAccSettings={pageAccSettings} />: null}
              {selectedPage === pageAccSecurity ?
                <CustomerPortalAccountSettingsSecurity onSimpleClick={(e, componentName) => onSimpleClick(e, componentName)}
                                                       pageAccLanding={pageAccLanding} />: null}

              <CustomerPortalFooter />
              <CustomerPortalContact />

            </div>
          </div>
        </div>
      </div>
    )
}

export default (injectIntl(CustomerPortalMyAccount))