import {compose} from 'redux'
import {connect} from 'react-redux'
import {reduxForm, formValueSelector} from 'redux-form'
import {injectIntl} from 'react-intl'
import {createBudgetIncome} from '../actions/budget-actions'
import BudgetIncomeForm from '../components/budget-income-form'

const selector = formValueSelector('budget-income-form')

const submitBudgetIncome = (values, dispatch) => new Promise((resolve, reject) => {
  dispatch(createBudgetIncome(values));
  resolve();
})

const BudgetIncomeFormContainer = compose(
  connect(
    state => ({
      initialValues: state.budgetIncome,
      enableReinitialize: false,
    }),
  ),
  reduxForm({
    form: 'budget-income-form',
    onSubmit: (values, dispatch, props) => {
      submitBudgetIncome(values, dispatch).then(() => {
        props.notifyIncomeBudgetSubmitted()
      })
    },
    destroyOnUnmount: false
  }),
  connect(
    state => {
      const formSalaryNet1 = selector(state, 'salaryNet1')
      const formSalaryNet2 = selector(state, 'salaryNet2')
      const formPartnerIncome1 = selector(state, 'partnerIncome1')
      const formPartnerIncome2 = selector(state, 'partnerIncome2')
      const formMyBusinessIncome = selector(state, 'myBusinessIncome')
      const formUnemployment = selector(state, 'unemployment')
      const formFoodStamps = selector(state, 'foodStamps')
      const formRetirementSocialSecurity = selector(state, 'retirementSocialSecurity')
      const formChildSupport = selector(state, 'childSupport')
      const formRentalIncome = selector(state, 'rentalIncome')
      const formFamilyOrFriends = selector(state, 'familyOrFriends')
      const formWic = selector(state, 'wic')
      const formSsdi = selector(state, 'ssdi')
      const formPublicAssistance = selector(state, 'publicAssistance')
      const formOtherIncome = selector(state, 'otherIncome')

      return {
        formSalaryNet1: formSalaryNet1,
        formSalaryNet2: formSalaryNet2,
        formPartnerIncome1: formPartnerIncome1,
        formPartnerIncome2: formPartnerIncome2,
        formMyBusinessIncome: formMyBusinessIncome,
        formUnemployment: formUnemployment,
        formFoodStamps: formFoodStamps,
        formRetirementSocialSecurity: formRetirementSocialSecurity,
        formChildSupport: formChildSupport,
        formRentalIncome: formRentalIncome,
        formFamilyOrFriends: formFamilyOrFriends,
        formWic: formWic,
        formSsdi: formSsdi,
        formPublicAssistance: formPublicAssistance,
        formOtherIncome: formOtherIncome,
        formTotalAvg: (_.isNaN(parseInt(formSalaryNet1)) ? 0 : parseInt(formSalaryNet1)) +
          (_.isNaN(parseInt(formSalaryNet2)) ? 0 : parseInt(formSalaryNet2)) +
          (_.isNaN(parseInt(formPartnerIncome1)) ? 0 : parseInt(formPartnerIncome1)) +
          (_.isNaN(parseInt(formPartnerIncome2)) ? 0 : parseInt(formPartnerIncome2)) +
          (_.isNaN(parseInt(formMyBusinessIncome)) ? 0 : parseInt(formMyBusinessIncome)) +
          (_.isNaN(parseInt(formUnemployment)) ? 0 : parseInt(formUnemployment)) +
          (_.isNaN(parseInt(formFoodStamps)) ? 0 : parseInt(formFoodStamps)) +
          (_.isNaN(parseInt(formRetirementSocialSecurity)) ? 0 : parseInt(formRetirementSocialSecurity)) +
          (_.isNaN(parseInt(formChildSupport)) ? 0 : parseInt(formChildSupport)) +
          (_.isNaN(parseInt(formRentalIncome)) ? 0 : parseInt(formRentalIncome)) +
          (_.isNaN(parseInt(formFamilyOrFriends)) ? 0 : parseInt(formFamilyOrFriends)) +
          (_.isNaN(parseInt(formWic)) ? 0 : parseInt(formWic)) +
          (_.isNaN(parseInt(formSsdi)) ? 0 : parseInt(formSsdi)) +
          (_.isNaN(parseInt(formPublicAssistance)) ? 0 : parseInt(formPublicAssistance)) +
          (_.isNaN(parseInt(formOtherIncome)) ? 0 : parseInt(formOtherIncome))
      }
    },
  )
)

export default BudgetIncomeFormContainer(injectIntl(BudgetIncomeForm))
