import {DEBT_SUMMARY_ACCOUNT_DETAIL} from '../actions/debt-summary-account-detail-actions'

export const debt_summary_account_details = {
  id: null,
  name: null,
  balance: null,
  creditLimit: null,
  monthlyPayment: null,
  accountType: null,
  type: null,
  openDate: null,
  accountOwnershipType: null,
  ratingType: null,
  ratingCode: null,
  triangle: false,
  flaggedByClient: null,
  interestRate: null,
  flaggedInOnboarding: null
}

export default function debtSummaryAccountDetail(state = debt_summary_account_details, action) {
  switch (action.type) {
    case DEBT_SUMMARY_ACCOUNT_DETAIL:
      return action.debtSummaryAccountDetail
    default:
      return state
  }
}