import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Icon from "../../../resources/icons";
import CustomerPortalFooter  from './customer-portal-footer';
import {injectIntl} from "react-intl";

import {useDispatch, useSelector} from "react-redux";
import {getEdsContract, pageCompleted} from '../../actions/cp-flow-actions'
import CustomerPortalProgressBar from "../helpers/CustomerPortalProgressBar";
import CustomerPortalNav from "./customer-portal-nav";
import {useHistory} from "react-router-dom";
import CustomerPortalContact from "./customer-portal-contact";

const options = {
  year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC'};


function CustomerPortalContractDetailsThree(props) {

  const locale = useSelector(state => state.locale)
  const employer = useSelector(state => state.employer)
    const edsContract = useSelector(state => state.edsContract)
    const contractTermInMonths = useSelector(state => state.edsContract.contractTermInMonths)
    const contractStartDate = useSelector(state => state.edsContract.contractStartDate)

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect( () => {
      window.scrollTo(0, 0)
      if ( edsContract ) {
        dispatch(getEdsContract(employer.id))
      }
    }, [])

  const ask = (e) => {
    e.preventDefault()
    history.push('/customer-portal/messaging')
  }

    const goBackToDetailsTwoPage = () => {
      history.push('/customer-portal/contracts/details-two')
    }

    const goToPreviewPage = () => {
      dispatch(pageCompleted(3))
      history.push('/customer-portal/contracts/preview')
    }

    const displayDate = (date) => {
      return <div>
        { new Intl.DateTimeFormat(locale, options).format(
          new Date(date))
        }
      </div>
    }

    const handleViewSteps = (e) => {
      e.preventDefault()
      history.push('/customer-portal/contracts/view-all-steps')
    }

    return(
        <div id='customer-portal'>
          <CustomerPortalNav />
          <div id='customer-portal-content'>
          <div id='customer-portal-contracts' className='customer-portal-pages'>
            <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title-background-gradient'></div>
              <div className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.customer-portal.contracts-header'/>
              </div>
            </div>

            <div className='customer-portal-dashboard-container'>


              <div className='customer-portal-back-link' onClick={goBackToDetailsTwoPage}>
                <FormattedMessage id='app.customer-portal-common.back-link'/>
              </div>
              <h2><FormattedMessage id='app.customer-portal-contracts.about-you.heading'/></h2>
              <div className='contract-content-wrapper'>
                <div className='contract-content-intro-wrapper'>
                  <div className='contract-content-intro-text'>
                    <div className='content-text'>
                      <FormattedMessage id='app.customer-portal-contracts.about-you.text-1'/>
                    </div>
                    <div className='content-text'>
                      <FormattedMessage id='app.customer-portal-contracts.about-you-text-2'/>
                    </div>
                  </div>
                  <CustomerPortalProgressBar pageNum={3}
                                             handleViewSteps={(e) => handleViewSteps(e)}
                  />
                </div>

                <div className='contracts-form-container'>
                  <div className='contracts-form-title-container'>
                    <div className='contracts-form-title'>
                      <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3a'/>
                      <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3b'/>
                    </div>
                  </div>
                  <div className='contracts-form-wrapper'>
                    <div className='contracts-prefill-wrapper'>
                      <div className='contracts-prefilled-label'>
                        <label htmlFor='full-name'>
                          <FormattedMessage id='app.customer-portal-contracts.form.details.contract-length'/>
                        </label>
                      </div>
                      <div className='contracts-prefilled-data'>
                        {contractTermInMonths} <FormattedMessage id='app.customer-portal-contracts.form.details.contract-length-unit'/>
                      </div>
                    </div>
                    <div className='contracts-prefill-wrapper'>
                      <div className='contracts-prefilled-label'>
                        <label htmlFor='full-name'>
                          <FormattedMessage id='app.customer-portal-contracts.form.details.contract-start-date'/>
                        </label>
                      </div>
                      <div className='contracts-prefilled-data'>
                        {displayDate(contractStartDate)}
                      </div>
                    </div>

                    <div className='contracts-contact-us-text'>
                      <FormattedMessage id='app.customer-portal-contracts.form.footer-text-1'/>
                      <a href="#" onClick={(e) => ask(e)}><FormattedMessage id='app.customer-portal-contracts.form.footer-text-2'/></a>
                      <FormattedMessage id='app.customer-portal-contracts.form.footer-text-3'/>
                    </div>

                  </div>
                  <div className='submit-button-round continue'>
                    <button
                      className="btn btn-primary btn-block"
                      id="visit-onboarding"
                      onClick={goToPreviewPage}>
                      <FormattedMessage id='app.customer-portal-contracts.detail.preview.contract.button'/>
                    </button>
                  </div>
                </div>
              </div>

              <CustomerPortalFooter />
              <CustomerPortalContact />

            </div>
          </div>
          </div>
        </div>
    )
}

export default (injectIntl(CustomerPortalContractDetailsThree))