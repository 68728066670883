import Icon from "../../../resources/icons";
import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export default function ActionPlanDashboardLinkMoney() {

    const linkMoneyAccounts = useSelector(state => state.linkMoneyAccounts)

    const history = useHistory();

    return (
        <div>
        {
            linkMoneyAccounts.length < 1 ?
                <div className='action-plan-dashboard-accounts-linked-container'>
                    <div className='action-plan-dashboard-accounts-linked-title'>
                        <div className='action-plan-dashboard-accounts-linked-title-text-area'>
                            <div className='dashboard-icon'>
                                <Icon name='piggy-bank'/>
                            </div>
                            <div className='dashboard-title'>
                                <FormattedMessage id='app.action-plan-dashboard.link-accounts.unlinked'/>
                            </div>
                        </div>
                    </div>
                    <div className="action-plan-dashboard-accounts-linked">
                        <div className='dashboard-main-wrapper action-plan-dashboard-accounts-linked-width'>
                            <div className='dashboard-main'>
                                <div className='action-plan-dashboard-accounts-linked-text-area'>
                                    <FormattedMessage
                                        id='app.action-plan-dashboard.link-accounts.description-1.unlinked'/>
                                    <a onClick={() => history.push('/action-plan/link-money/get-started')}>
                                        <FormattedMessage
                                            id='app.action-plan-dashboard.link-accounts.description-link.unlinked'/>
                                    </a>
                                    <FormattedMessage
                                        id='app.action-plan-dashboard.link-accounts.description-2.unlinked'/>
                                </div>
                            </div>
                            <div className='action-plan-dashboard-accounts-linked-button'>
                                <div className='dashboard-accounts-linked-btn'>
                                    <div className='submit-button-round'>
                                        <button id='submit-button-round'
                                                type={'button'}
                                                className="btn btn-primary btn-block btn-lg margin-bottom-16-px"
                                                onClick={(e) => history.push('/action-plan/link-money/get-started')}>
                                            <FormattedMessage id='app.action-plan-link-money.button.link-accounts'/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='action-plan-dashboard-accounts-linked-container'>
                    <div className='action-plan-dashboard-accounts-linked-title'>
                        <div className='action-plan-dashboard-accounts-linked-title-text-area'>
                            <div className='dashboard-icon'>
                                <Icon name='piggy-bank'/>
                            </div>
                            <div className='dashboard-title'>
                                <FormattedMessage id='app.action-plan-dashboard.link-accounts.linked'/>
                            </div>
                        </div>
                    </div>
                    <div className="action-plan-dashboard-accounts-linked">
                        <div className='dashboard-main-wrapper action-plan-dashboard-accounts-linked-width'>
                            <div className='dashboard-main margin-bottom-24-px'>
                                <div className='action-plan-dashboard-accounts-linked-text-area'>
                                    <FormattedMessage
                                        id='app.action-plan-dashboard.link-accounts.description-1.linked'/>
                                    <a onClick={() => history.push('/action-plan/link-money/get-started')}>
                                        <FormattedMessage
                                            id='app.action-plan-dashboard.link-accounts.description-link.linked'/>
                                    </a>
                                    <FormattedMessage
                                        id='app.action-plan-dashboard.link-accounts.description-2.linked'/>
                                </div>
                            </div>
                            <div className='action-plan-dashboard-accounts-linked-button'>
                                <div className='dashboard-accounts-linked-btn'>
                                    <div className='submit-button-round'>
                                        <button id='submit-button-round'
                                                type={'button'}
                                                className="btn btn-primary btn-block btn-lg margin-bottom-16-px"
                                                onClick={(e) => history.push('/action-plan/link-money/get-started')}>
                                            <FormattedMessage
                                                id='app.action-plan-dashboard.link-accounts.button.linked'/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
        </div>
    )
}