import {LINK_MONEY_ACCOUNTS, GET_CONNECTED_ACCOUNTS} from "../actions/link-money-actions";

const linkMoneyAccounts = (state = [], action) => {
  switch (action.type) {
    case LINK_MONEY_ACCOUNTS:
      return action.linkMoneyAccounts;
    case GET_CONNECTED_ACCOUNTS:
      return action.linkMoneyAccounts;
    default:
      return state
  }
}

export default linkMoneyAccounts
