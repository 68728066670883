import {SPANISH_COUNSELORS_RETURNED} from '../actions/counselor-actions'

function spanishCounselorsReturned(state = false, action) {
  switch (action.type) {
    case SPANISH_COUNSELORS_RETURNED:
      return action.spanishCounselorsReturned
    default:
      return state
  }
}

export default spanishCounselorsReturned