import {DEBT_SUMMARY_CATEGORIES_EXPANDED} from '../actions/debt-summary-categories-expanded-actions'

export const initial_open_categories = {
        credit: false,
        student: false,
        mortgage: false,
        auto: false,
        personal: false,
        collection: false,
        other: false
}

export default function debtSummaryCategoriesExpanded(state = initial_open_categories, action) {
  switch (action.type) {
    case DEBT_SUMMARY_CATEGORIES_EXPANDED:
      return action.debtSummaryCategoriesExpanded
    default:
      return state
  }
}