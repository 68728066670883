import React from 'react'

import Field from './helpers/Field'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'

const isSubmitDisabled = (props, state) => {
    return props.pristine ||
        props.submitting ||
        !props.valid ||
        state.isProcessing;
}

const renderField = ({input, autocomplete, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type} autocomplete={autocomplete}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

export default class CreatePassForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isProcessing: false,
        }
    }

    componentDidUpdate(prevProps, nextProps, state) {
      if (this.props.login === null) {
        this.props.history.push(`/welcome`)
      } else {
        if (this.props.login.nextPage === 'TEMPPASSWORD') {
          this.props.history.push('/enter-one-time-code')
        }
        // else if (this.props.login.status === 'Active' && this.props.login.token != null) {
        //     this.props.history.push('/action-plan')
        // }
        else if (this.props.login.status === 'Active' && this.props.login.token == null && this.props.login.nextPage === 'PASSWORD') {
          this.props.history.push('/login')
        }

      }
    }

    render() {
        return (
            <div>
                <HeaderContainerAP/>

                <div className='container-ap create-pass-form'>
                  <div className='page-title'>
                    <FormattedMessage id='app.create-pass.title'/>
                  </div>
                  <div className='text-ap margin-bottom-16-px'>
                    <FormattedMessage id='app.create-pass.instruction1'/>
                    <li><FormattedMessage id='app.create-pass.rules1'/></li>
                    <li><FormattedMessage id='app.create-pass.rules2'/></li>
                    <li><FormattedMessage id='app.create-pass.rules3'/></li>
                    <li><FormattedMessage id='app.create-pass.rules4'/></li>
                    <li><FormattedMessage id='app.create-pass.rules5'/></li>
                  </div>
                    {/*<form onSubmit={e => {e.preventDefault();}}>*/}
                    <form onSubmit={this.props.handleSubmit}>
                        <div className='form-group margin-bottom-32-px'>
                            <div className='label-text'>
                                <FormattedMessage id='app.create-password.password'/>
                            </div>
                            <Field name='newpassword' type='password' id='newpassword' 
                                autocomplete='newpassword' component={renderField} />
                        </div>

                        <div className='form-group margin-bottom-32-px'>
                            <div className='label-text'>
                                <FormattedMessage id='app.create-password.reenter-password'/>
                            </div>
                            <Field name='confirmpassword' type='password' id='confirmpassword' 
                                autocomplete='confirmpassword' component={renderField} />
                        </div>

                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-primary btn-block btn-lg'
                                    disabled={isSubmitDisabled(this.props, this.state)}>
                                <FormattedMessage id='app.common.continue.button'/>
                            </button>
                        </div>
                    </form>
                </div>
                <FooterContainerAp/>
            </div>)
    }
}
