import React from 'react'

export const MessagingInbox = ({
                            style = {},
                            fill = '#000000',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 19 13',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="m16.529 6.14-1.18-1.77 1.668 2.502a.184.184 0 0 1 .004.006l-.492-.737zm-.832 1.555H17.1v3.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-3.5h4.632l.723 1.448.277.552H11.469l.276-.552.724-1.448h3.228z" stroke="#003057" strokeWidth="2"/>
  </svg>;

export default MessagingInbox