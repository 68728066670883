import React from 'react'

export const SadEmoji = ({
                               style = {},
                               fill = '#000000',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 460 460',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(120.500000, 114.600000)">
        <circle id="Oval" stroke="#AEE0E8" stroke-width="16" fill="#FFFFFF" cx="109" cy="109" r="101"></circle>
        <path d="M44.031341,145.999453 C60.8179414,126.842452 82.653636,116.794533 109.538425,115.855696 C136.423213,114.916858 158.587519,124.964777 176.031341,145.999453" id="Line" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square" transform="translate(110.031341, 130.897490) scale(1, -1) rotate(182.000000) translate(-110.031341, -130.897490) "></path>
        <circle id="Oval" fill="#AEE0E8" cx="80" cy="68" r="16"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="138" cy="68" r="16"></circle>
      </g>
    </g>

  </svg>;

export default SadEmoji