import React from 'react'

export const TPCPieChartBelow580 = ({
                              style = {},
                              fill = '#fff',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 28 38',
                            }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <text fill="#FFF" fillRule="evenodd" fontFamily="Gotham-Medium, Gotham, Helvetica, Arial, sans-serif" fontSize="12" fontWeight="400" letterSpacing=".167" transform="rotate(-73 193.798 756.286)">
      <tspan x="825.953" y="369">Below</tspan> <tspan x="832.888" y="381">580</tspan>
    </text>
  </svg>

export default TPCPieChartBelow580