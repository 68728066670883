import React from 'react'

export const MyAccount = ({
                            style = {},
                            fill = '#000000',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 25 24',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <mask id="7vznyd1oja" fill="#fff">
      <path d="M16.4 13.875c-1.121 0-1.66.625-3.5.625s-2.375-.625-3.5-.625a5.251 5.251 0 0 0-5.25 5.25v1C4.15 21.16 4.99 22 6.025 22h13.75c1.035 0 1.875-.84 1.875-1.875v-1a5.251 5.251 0 0 0-5.25-5.25zm3.375 6.25H6.025v-1A3.38 3.38 0 0 1 9.4 15.75c.57 0 1.496.625 3.5.625 2.02 0 2.926-.625 3.5-.625a3.38 3.38 0 0 1 3.375 3.375v1zM12.9 13.25a5.626 5.626 0 0 0 5.625-5.625A5.626 5.626 0 0 0 12.9 2a5.626 5.626 0 0 0-5.625 5.625A5.626 5.626 0 0 0 12.9 13.25zm0-9.375a3.756 3.756 0 0 1 3.75 3.75 3.756 3.756 0 0 1-3.75 3.75 3.756 3.756 0 0 1-3.75-3.75 3.756 3.756 0 0 1 3.75-3.75z"/>
    </mask>
    <path d="M16.4 13.875c-1.121 0-1.66.625-3.5.625s-2.375-.625-3.5-.625a5.251 5.251 0 0 0-5.25 5.25v1C4.15 21.16 4.99 22 6.025 22h13.75c1.035 0 1.875-.84 1.875-1.875v-1a5.251 5.251 0 0 0-5.25-5.25zm3.375 6.25H6.025v-1A3.38 3.38 0 0 1 9.4 15.75c.57 0 1.496.625 3.5.625 2.02 0 2.926-.625 3.5-.625a3.38 3.38 0 0 1 3.375 3.375v1zM12.9 13.25a5.626 5.626 0 0 0 5.625-5.625A5.626 5.626 0 0 0 12.9 2a5.626 5.626 0 0 0-5.625 5.625A5.626 5.626 0 0 0 12.9 13.25zm0-9.375a3.756 3.756 0 0 1 3.75 3.75 3.756 3.756 0 0 1-3.75 3.75 3.756 3.756 0 0 1-3.75-3.75 3.756 3.756 0 0 1 3.75-3.75z" fill="#000"/>
    <path d="M19.775 20.125v2h2v-2h-2zm-13.75 0h-2v2h2v-2zm10.375-8.25c-.934 0-1.686.282-2.072.402-.39.12-.776.223-1.428.223v4c1.187 0 1.991-.21 2.61-.402.624-.193.702-.223.89-.223v-4zm-3.5.625a4.311 4.311 0 0 1-1.426-.223c-.388-.12-1.139-.402-2.074-.402v4c.19 0 .268.03.89.223a8.28 8.28 0 0 0 2.61.402v-4zm-3.5-.625a7.251 7.251 0 0 0-7.25 7.25h4a3.251 3.251 0 0 1 3.25-3.25v-4zm-7.25 7.25v1h4v-1h-4zm0 1A3.876 3.876 0 0 0 6.025 24v-4c.07 0 .125.056.125.125h-4zM6.025 24h13.75v-4H6.025v4zm13.75 0a3.876 3.876 0 0 0 3.875-3.875h-4c0-.07.056-.125.125-.125v4zm3.875-3.875v-1h-4v1h4zm0-1a7.251 7.251 0 0 0-7.25-7.25v4a3.251 3.251 0 0 1 3.25 3.25h4zm-3.875-1H6.025v4h13.75v-4zm-11.75 2v-1h-4v1h4zm0-1c0-.755.62-1.375 1.375-1.375v-4a5.38 5.38 0 0 0-5.375 5.375h4zM9.4 17.75c-.173 0-.254-.041-.061.016.055.016.487.153.73.221.685.194 1.604.388 2.83.388v-4a6.132 6.132 0 0 1-1.743-.237c-.311-.088-.342-.107-.684-.208a3.797 3.797 0 0 0-1.072-.18v4zm3.5.625c1.234 0 2.152-.194 2.838-.388.24-.069.674-.207.726-.222.189-.056.109-.015-.064-.015v-4c-.46 0-.869.12-1.075.18-.342.102-.37.12-.679.208-.416.118-.96.237-1.746.237v4zm3.5-.625c.755 0 1.375.62 1.375 1.375h4A5.38 5.38 0 0 0 16.4 13.75v4zm1.375 1.375v1h4v-1h-4zM12.9 15.25a7.627 7.627 0 0 0 7.625-7.625h-4c0 2-1.624 3.625-3.625 3.625v4zm7.625-7.625A7.626 7.626 0 0 0 12.9 0v4c2 0 3.625 1.624 3.625 3.625h4zM12.9 0a7.626 7.626 0 0 0-7.625 7.625h4c0-2 1.624-3.625 3.625-3.625V0zM5.275 7.625A7.626 7.626 0 0 0 12.9 15.25v-4c-2.001 0-3.625-1.624-3.625-3.625h-4zm7.625-1.75c.962 0 1.75.788 1.75 1.75h4a5.756 5.756 0 0 0-5.75-5.75v4zm1.75 1.75c0 .962-.788 1.75-1.75 1.75v4a5.756 5.756 0 0 0 5.75-5.75h-4zm-1.75 1.75c-.962 0-1.75-.788-1.75-1.75h-4a5.756 5.756 0 0 0 5.75 5.75v-4zm-1.75-1.75c0-.962.788-1.75 1.75-1.75v-4a5.756 5.756 0 0 0-5.75 5.75h4z" fill="#000" mask="url(#7vznyd1oja)"/>

  </svg>;

export default MyAccount