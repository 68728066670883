import React from 'react'

export const BluePiggyBank = ({
                              style = {},
                              fill = '#141006',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 448 512',
                          }) =>

    <svg
        width="30"
        height="28"
        viewBox="0 0 30 28"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6666 5.75377H13.1108C12.2708 5.75377 11.4616 5.87157 10.6831 6.07132C10.6808 6.02334 10.6744 5.97638 10.6681 5.92953C10.6602 5.87136 10.6523 5.81335 10.6523 5.75377C10.6523 3.03926 12.8547 0.836914 15.5692 0.836914C18.2837 0.836914 20.4861 3.03926 20.4861 5.75377C20.4861 5.76146 20.4848 5.76786 20.4835 5.77426C20.4822 5.78066 20.4809 5.78706 20.4809 5.79475C20.2146 5.77426 19.9432 5.75377 19.6666 5.75377ZM28.6805 12.3095H27.1696C26.7189 11.2852 26.0633 10.3786 25.2541 9.62061L26.2221 5.75371H24.5831C23.0774 5.75371 21.7457 6.44514 20.8443 7.51046L20.7469 7.49628C20.3908 7.44429 20.0371 7.39266 19.6663 7.39266H13.1105C9.14625 7.39266 5.84274 10.2096 5.0796 13.9485H2.86701C2.109 13.9485 1.50976 13.257 1.66341 12.4734C1.77609 11.8895 2.32411 11.49 2.91823 11.49H2.96945C3.13846 11.49 3.27675 11.3518 3.27675 11.1827V10.1584C3.27675 9.98938 3.13846 9.85109 2.96945 9.85109C1.50976 9.85109 0.208836 10.8959 0.024454 12.3403C-0.200902 14.0919 1.16148 15.5874 2.86701 15.5874H4.9157C4.9157 18.261 6.21662 20.6118 8.19361 22.1074V26.2406C8.19361 26.6913 8.56238 27.0601 9.01309 27.0601H12.291C12.7417 27.0601 13.1105 26.6913 13.1105 26.2406V23.7822H19.6663V26.2406C19.6663 26.6913 20.035 27.0601 20.4858 27.0601H23.7637C24.2144 27.0601 24.5831 26.6913 24.5831 26.2406V22.1074C25.1875 21.6516 25.7253 21.1138 26.1862 20.5043H28.6805C29.1312 20.5043 29.5 20.1355 29.5 19.6848V13.129C29.5 12.6783 29.1312 12.3095 28.6805 12.3095ZM22.1242 15.5877C21.6735 15.5877 21.3047 15.2189 21.3047 14.7682C21.3047 14.3175 21.6735 13.9487 22.1242 13.9487C22.5749 13.9487 22.9436 14.3175 22.9436 14.7682C22.9436 15.2189 22.5749 15.5877 22.1242 15.5877Z" fill="#AEE0E8"/>
    </svg>;



export default BluePiggyBank
