import React from 'react'

import FormattedMessage from './helpers/FormattedMessage'
import Field from './helpers/Field'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'
import QueryStringService from "../services/query-string-service";

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
    props.submitting ||
    !props.valid
}

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default class WelcomeForm extends React.Component {

    constructor(props) {
        super(props);

        if (props.login !== null) { props.doLogout(); }
    }

    buildSSOAltCompanyLogin() {
        if (this.props.activeEmployers && this.props.activeEmployers.length > 0) {
            let displaySsoLink = this.props.activeEmployers.filter(employer => employer.displaySsoServiceProviderLink === "Yes").map((employer, index) => {
                return <div key={index} className='company-login-link'>
                    <a href={`/api/saml-login/${employer.samlEntity}`}><FormattedMessage
                        id='app.alt-company-login.login-text'/> {employer.displayName}</a>
                </div>
            })

            if (displaySsoLink.length > 0) {
                return <><FormattedMessage id='app.alt-company-login.text-1'/>
                    <div className='company-login-links-wrapper'>{displaySsoLink} </div>
                </>
            } else {
                return null
            }

        } else {
            return null
        }
    }

    render() {

      let title = ''
      let instruction = ''

      let logoutVal = QueryStringService.getValue(this.props.location.search, "logout")
      let timeoutVal = QueryStringService.getValue(this.props.location.search, "timeout")

      if (logoutVal && logoutVal === 'true') {
        title = 'app.welcome.logout.title'
        instruction = 'app.welcome.logout.instruction1'
      }
      else if (timeoutVal && timeoutVal === 'true') {
        title = 'app.welcome.timeout.title'
        instruction = 'app.welcome.timeout.instruction1'
      }
      else {
          title = 'app.welcome.title'
          instruction = 'app.welcome.instruction1'
      }

      return (
          <div>
              <HeaderContainerAP/>

              <div className='container-ap welcome-form'>

                <div className='page-title'>
                  <FormattedMessage id={title}/>
                </div>
                <div className='text-ap margin-bottom-32-px'>
                  <FormattedMessage id={instruction}/>
                </div>
                  <form onSubmit={this.props.handleSubmit}>
                      <div className='form-group margin-bottom-32-px'>
                          <div className='label-text'>
                              <FormattedMessage id='app.welcome.input.email'/>
                          </div>
                          <Field name='username' type='text' id='username' component={renderField} />
                      </div>

                      <div className='submit-button-round margin-bottom-24-px'>
                          <button id='submit'
                                  type={'submit'}
                                  className='btn btn-primary btn-block btn-lg'
                                  disabled={isSubmitDisabled(this.props, this.state)}>
                              <FormattedMessage id='app.common.continue.button'/>
                          </button>
                      </div>
                  </form>
                  <div className='alt-company-login-container'>
                      {this.buildSSOAltCompanyLogin()}
                  </div>
                </div>
              <FooterContainerAp/>
          </div>)
    }
}
