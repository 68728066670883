import React from 'react'

export const WorksCall = ({
                      style = {},
                      fill = '#fff',
                      width = '100%',
                      className = '',
                      height = '100%',
                      viewBox = '0 0 23 34',
                    }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path fill="#A1A1A4" d="M13.27 15.607c-.31 0-.578-.113-.804-.34-.226-.225-.34-.496-.34-.812 0-.315.114-.586.34-.812.44-.44.66-.976.66-1.607 0-.62-.22-1.161-.66-1.625-.226-.226-.34-.494-.34-.804 0-.31.114-.577.34-.803.226-.227.494-.34.804-.34.31 0 .577.113.803.34.893.892 1.34 1.97 1.34 3.232 0 1.262-.447 2.339-1.34 3.232-.25.226-.518.34-.803.34zm4.035 2.893c-.214.226-.482.34-.803.34-.322 0-.59-.114-.804-.34-.226-.226-.339-.494-.339-.804 0-.31.113-.577.34-.803 1.333-1.357 2-2.976 2-4.857 0-1.881-.667-3.5-2-4.857-.227-.227-.34-.494-.34-.804 0-.31.113-.577.34-.804.226-.226.493-.339.803-.339.31 0 .577.113.803.34 1.786 1.785 2.68 3.94 2.68 6.464 0 2.524-.894 4.678-2.68 6.464zm3.233 3.232c-.215.226-.483.34-.804.34-.321 0-.59-.114-.804-.34-.226-.226-.339-.494-.339-.803 0-.31.113-.578.34-.804 1.07-1.083 1.895-2.321 2.473-3.714.577-1.393.866-2.851.866-4.375s-.289-2.982-.866-4.375c-.578-1.393-1.402-2.631-2.474-3.715-.226-.226-.339-.494-.339-.803 0-.31.113-.578.34-.804.226-.226.494-.339.803-.339.31 0 .577.113.804.34 1.297 1.297 2.291 2.779 2.982 4.446.69 1.666 1.035 3.416 1.035 5.25 0 1.833-.345 3.583-1.035 5.25-.69 1.666-1.685 3.149-2.982 4.446z" transform="translate(-773 -2135) translate(769 2132) rotate(-45 18.341 12.036)"/>
          <path fill="#EF3E42" d="M20.018 39.057c0 .262-.26.574-.777.937-.518.363-.967.616-1.348.76-.238.094-.506.142-.804.142-.404 0-.988-.107-1.75-.321l-.107-.036c-1.38-.429-2.446-1.185-3.196-2.268-.322-.464-.816-1.423-1.482-2.875-.584-1.274-.989-2.56-1.215-3.857C9.113 30.24 9 28.789 9 27.182c0-1.607.113-3.06.34-4.357.225-1.298.63-2.584 1.214-3.858.035-.083.089-.196.16-.339.37-.798.64-1.36.813-1.687.172-.328.446-.744.821-1.25.375-.506.798-.9 1.268-1.179.47-.28 1.045-.52 1.723-.723.762-.214 1.346-.322 1.75-.322.298 0 .566.048.804.143.38.143.83.396 1.348.76.518.362.777.675.777.937 0 .13-.078.476-.232 1.035-.155.56-.34 1.197-.554 1.911-.214.714-.333 1.125-.357 1.232-.012.048-.042.206-.09.473-.047.268-.097.483-.151.643-.054.161-.134.289-.241.384-.179.167-.482.25-.91.25-.275 0-.691-.032-1.25-.098-.56-.065-.983-.098-1.269-.098-.404 0-.684.065-.839.196-.071.06-.137.152-.196.277-.06.125-.105.244-.134.357l-.116.429c-.048.172-.078.282-.09.33-.44 1.524-.66 3.042-.66 4.554 0 1.512.22 3.03.66 4.553.012.048.042.158.09.33l.116.43c.03.112.074.231.134.356.06.125.125.218.196.277.155.131.435.197.84.197.285 0 .708-.033 1.267-.099.56-.065.976-.098 1.25-.098.429 0 .732.083.91.25.108.095.188.223.242.384.054.16.104.375.152.643.047.268.077.425.089.473.024.107.143.518.357 1.232.214.715.399 1.351.554 1.911.154.56.232.905.232 1.036z" transform="translate(-773 -2135) translate(769 2132) rotate(-45 14.509 27.182)"/>
        </g>
      </g>
    </g>

  </svg>;

export default WorksCall