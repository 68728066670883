import React from 'react'

export const DclOneBill = ({
                           style = {},
                           fill = '#fff',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 200 200',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <circle cx="100" cy="100" r="100" fill="#003057"/>
        <g>
          <g id="Phone">
            <path d="M144.52,48.09c9.65-.05,17.51,8.18,17.56,19.3l.42,71.55c.05,11.12-7.74,20.57-17.4,20.62l-51.58.6-38.04.22c-9.65.05-17.51-8.92-17.56-20.04l-.42-71.56c-.05-11.12,7.74-20.18,17.4-20.23l38.63-.22,50.99-.23Z" fill="none" stroke="#00c7b1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.85"/>
          </g>
          <g>
            <g>
              <path d="M50.91,78.05l.75-.9c.68.59,1.38.93,2.25.93.77,0,1.26-.37,1.26-.9v-.02c0-.51-.29-.79-1.61-1.09-1.52-.37-2.37-.82-2.37-2.13v-.02c0-1.22,1.02-2.07,2.44-2.07,1.04,0,1.86.32,2.59.9l-.67.95c-.64-.48-1.28-.73-1.94-.73-.73,0-1.16.38-1.16.85v.02c0,.55.33.8,1.7,1.12,1.51.37,2.28.91,2.28,2.09v.02c0,1.34-1.05,2.13-2.55,2.13-1.09,0-2.12-.38-2.98-1.14Z" fill="#00c7b1"/>
              <path d="M65.56,71.95h1.33l2.17,3.37,2.17-3.37h1.33v7.13h-1.25v-5.12l-2.25,3.36h-.04l-2.23-3.34v5.1h-1.23v-7.13Z" fill="#00c7b1"/>
              <path d="M83.81,73.11h-2.26v-1.16h5.79v1.16h-2.26v5.97h-1.26v-5.97Z" fill="#00c7b1"/>
              <path d="M95.76,71.95h1.36l1.7,5.29,1.75-5.31h1.06l1.75,5.31,1.7-5.29h1.31l-2.48,7.19h-1.09l-1.75-5.13-1.75,5.13h-1.08l-2.49-7.19Z" fill="#00c7b1"/>
              <path d="M117.09,73.11h-2.26v-1.16h5.79v1.16h-2.26v5.97h-1.26v-5.97Z" fill="#00c7b1"/>
              <path d="M129.59,71.95h5.31v1.14h-4.06v1.95h3.6v1.14h-3.6v2.9h-1.25v-7.13Z" fill="#00c7b1"/>
              <path d="M143.45,78.05l.75-.9c.68.59,1.38.93,2.25.93.77,0,1.26-.37,1.26-.9v-.02c0-.51-.29-.79-1.61-1.09-1.52-.37-2.37-.82-2.37-2.13v-.02c0-1.22,1.02-2.07,2.44-2.07,1.04,0,1.86.32,2.59.9l-.67.95c-.64-.48-1.28-.73-1.94-.73-.73,0-1.16.38-1.16.85v.02c0,.55.33.8,1.7,1.12,1.51.37,2.28.91,2.28,2.09v.02c0,1.34-1.05,2.13-2.55,2.13-1.09,0-2.12-.38-2.98-1.14Z" fill="#00c7b1"/>
            </g>
            <g>
              <circle cx="68.79" cy="134.44" r="7.23" fill="#00c7b1" stroke="#00c7b1" stroke-miterlimit="10" stroke-width="2.85"/>
              <g>
                <circle cx="131.03" cy="88.15" r="1.7" fill="#fff"/>
                <circle cx="146.54" cy="88.15" r="1.7" fill="#fff"/>
                <circle cx="115.51" cy="88.15" r="1.7" fill="#fff"/>
                <circle cx="100" cy="88.15" r="1.7" fill="#fff"/>
                <circle cx="84.49" cy="88.15" r="1.7" fill="#fff"/>
                <circle cx="68.97" cy="88.15" r="1.7" fill="#fff"/>
                <circle cx="53.46" cy="88.15" r="1.7" fill="#fff"/>
              </g>
              <g>
                <circle cx="131.03" cy="103.58" r="1.7" fill="#fff"/>
                <circle cx="146.54" cy="103.58" r="1.7" fill="#fff"/>
                <circle cx="115.51" cy="103.58" r="1.7" fill="#fff"/>
                <circle cx="100" cy="103.58" r="1.7" fill="#fff"/>
                <circle cx="84.49" cy="103.58" r="1.7" fill="#fff"/>
                <circle cx="68.97" cy="103.58" r="1.7" fill="#fff"/>
                <circle cx="53.46" cy="103.58" r="1.7" fill="#fff"/>
              </g>
              <g>
                <circle cx="131.03" cy="119.02" r="1.7" fill="#fff"/>
                <circle cx="146.54" cy="119.02" r="1.7" fill="#fff"/>
                <circle cx="115.51" cy="119.02" r="1.7" fill="#fff"/>
                <circle cx="100" cy="119.02" r="1.7" fill="#fff"/>
                <circle cx="84.49" cy="119.02" r="1.7" fill="#fff"/>
                <circle cx="68.97" cy="119.02" r="1.7" fill="#fff"/>
                <circle cx="53.46" cy="119.02" r="1.7" fill="#fff"/>
              </g>
              <g>
                <circle cx="131.03" cy="134.45" r="1.7" fill="#fff"/>
                <circle cx="146.54" cy="134.45" r="1.7" fill="#fff"/>
                <circle cx="115.51" cy="134.45" r="1.7" fill="#fff"/>
                <circle cx="100" cy="134.45" r="1.7" fill="#fff"/>
                <circle cx="84.49" cy="134.45" r="1.7" fill="#fff"/>
                <circle cx="68.97" cy="134.45" r="1.7" fill="#003057" stroke="#003057" stroke-miterlimit="10" stroke-width="2.85"/>
                <circle cx="53.46" cy="134.45" r="1.7" fill="#fff"/>
              </g>
            </g>
          </g>
          <g>
            <path d="M63.22,48.09v8.46-16.91,8.46Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.85"/>
            <path d="M100,48.09v8.46-16.91,8.46Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.85"/>
            <path d="M136.78,48.09v8.46-16.91,8.46Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.85"/>
          </g>
        </g>
      </g>
    </g>

  </svg>;

export default DclOneBill