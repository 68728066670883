import React from 'react'
import {FormattedMessage} from 'react-intl'


export default class DebtSummaryPermission extends React.Component {
  constructor(props) {
    super(props);
  }

  updatePermission() {
    this.props.toggleCreditFormOpen(true)
  }

  showPermissonText(code) {
    if (code === 'h310') {
      return (<div>
          <FormattedMessage id='app.action-plan-debt-summary.permission-text-transunion'/>
        </div>)
    } else {
      return (<div>
          <FormattedMessage id='app.action-plan-debt-summary.permission-text'/>
        </div>)
    }

  }

  render() {
    return(
      <div id='debt-report'>{
        <div id='debt-summary-permission'>
          <div className='debt-summary-box-wrapper margin-bottom-24-px'>
            <div className='debt-summary-box-inside'>
              <div className='debt-summary-box-title margin-bottom-24-px'>
                <FormattedMessage id='app.action-plan-debt-summary.permission-subtitle'/>
              </div>
              <p className='margin-bottom-24-px'>
                {this.showPermissonText(this.props.employer.code)}
              </p>

              <div className='submit-button-round margin-bottom-24-px'>
                <button id='submit'
                        type={'submit'}
                        onClick={() => this.updatePermission()}
                        className='btn btn-primary btn-block btn-lg'>
                  <FormattedMessage id='app.action-plan-debt-summary.permission-button'/>
                </button>
              </div>
              <div className='margin-bottom-24-px'>
                <FormattedMessage id='app.action-plan-debt-summary.permission-text-2'/>
              </div>
            </div>

              {
                this.props.navCurrentComponent === 'ActionPlanDebtSummary' ?
                  (
                    <div className='phone-in-hand-wrapper'>
                      <div className='phone-in-hand'>
                        <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/phones/phoneinhand.png`} />
                      </div>
                    </div>
                  )
                  :
                  this.props.navCurrentComponent == 'ActionPlanFicoScore' ?
                  (
                    <div className='phone-in-hand-wrapper'>
                      <div className='phone-in-hand'>
                        <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/phones/phoneinhand.png`} />
                      </div>
                    </div>
                  )
                  : (<div/>)
              }
          </div>
          <div className='clear-float'></div>
        </div>
        }
      </div>
      )}
}
