import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {sendNudge} from "../actions/nudge-actions";
import {useHistory} from "react-router-dom";

export default function Nudge() {

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        sendSmNudge()
        history.push('/action-plan')
    });

    function sendSmNudge() {
        const sfId = location.search;
        const idParams = new URLSearchParams(sfId);
        const contactId = idParams.get("sfrecid")
        const action = idParams.get("action")
        dispatch(sendNudge(contactId, action));
    }

    return (
        <div>
        </div>
    )
}