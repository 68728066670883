import React, {useEffect} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import FormattedMessage from "../helpers/FormattedMessage";

function LandingPagesTagline(props) {

  return (
    <div id='landing-pages-help-you'>
        <div className='how-we-can-help bluebg'>
        <div className='landing-propel-container'>
          <div className='propel-section-header'>
          <FormattedMessage id='app.landing-page.tagline.title'/>
          </div>
          <div className='tagline-text center'>
            <FormattedMessage id='app.landing-page.tagline.text'/>
          </div>

          <div className='submit-button-round speak-with-coach-btn btn-propel-landing'>
            <button className='btn btn-primary btn-block btn-propel-landing'
                    id='submit'
                    onClick={props.goToAppointmentSchedule}>
              <FormattedMessage id='app.landing-page.introduction.sign-up.button'/>
            </button>
          </div>
        </div>
      </div>
    </div>

  )

}

export default LandingPagesTagline
