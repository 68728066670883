import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'

export const CustomerPortalProgressBar = props => (
  <div className='customer-portal-progress-bar-container'>
      <div className='progress-indicator contracts'>
        <div className='progress-indicator-contract-heading'>
          <FormattedMessage id='app.customer-portal-contracts.progress-indicator-heading'/>
        </div>
      </div>
      <div className={`progress-bar-container step-${props.pageNum}`}>
        <div className='progress-bar-wrapper'>
          <div className='progress-bar-line'></div>
          <div className='progress-bar-line-filled'>
          </div>
          <div className='progress-bar-circles-wrapper'>
            <div className='progress-bar-circle-container circle-1'>
              <div className='progress-bar-circle'>
                <div className='progress-bar-number'>
                  1
                </div>
              </div>
              <div className='progress-bar-step-title title-1'>
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-1'/>
              </div>
            </div>
            <div className='progress-bar-circle-container circle-2'>
              <div className='progress-bar-circle'>
                <div className='progress-bar-number'>
                  2
                </div>
              </div>
              <div className='progress-bar-step-title title-2'>
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-2'/>
              </div>
            </div>
            <div className='progress-bar-circle-container circle-3'>
              <div className='progress-bar-circle'>
                <div className='progress-bar-number'>
                  3
                </div>
              </div>
              <div className='progress-bar-step-title title-3'>
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3a'/>
                <br/>
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3b'/>
              </div>
            </div>
            <div className='progress-bar-circle-container circle-4'>
              <div className='progress-bar-circle'>
                <div className='progress-bar-number'>
                  4
                </div>
              </div>
              <div className='progress-bar-step-title title-4'>
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-4a'/>
                <br />
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-4b'/>
              </div>
            </div>
            <div className='progress-bar-circle-container circle-5'>
              <div className='progress-bar-circle'>
                <div className='progress-bar-number'>
                  5
                </div>
              </div>
              <div className='progress-bar-step-title title-5'>
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-5a'/>
                <br />
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-5b'/>
              </div>
            </div>
            <div className='progress-bar-circle-container circle-6'>
              <div className='progress-bar-circle'>
                <div className='progress-bar-number'>
                  6
                </div>
              </div>
              <div className='progress-bar-step-title title-6'>
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-6a'/>
                <br />
                <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-6b'/>
              </div>
            </div>
          </div>
        </div>
        <div className='view-steps-container'>
          <a href="#" onClick={(e) => props.handleViewSteps(e)}>
            <FormattedMessage id='app.customer-portal-home.progress-view-steps'/>
          </a>
        </div>
      </div>
  </div>
)

export default CustomerPortalProgressBar