import axios from 'axios'
import moment from 'moment'
import {SubmissionError} from 'redux-form'


export const CREATE_SUCCESS_STORY = 'CREATE_SUCCESS_STORY'
export const UPDATE_SUCCESS_STORY = 'UPDATE_SUCCESS_STORY'


export const createSuccessStory = data => {
    return (dispatch, getState) => {
      const createSuccessStory  = Object.assign({}, data)
      const today = moment();

      createSuccessStory.releaseFormDate = today.format('YYYY-MM-DDTHH:mm:ss.SSS');

      return axios
        .post('/api/createSuccessStory', createSuccessStory)
        .then((response) => {
          dispatch({
            type: CREATE_SUCCESS_STORY,
            createSuccessStory: response.data
          })
        })
        .catch(error => {
          throw new SubmissionError({_error: error.response.data.message})
        })
    }
}

export const createSSContentVersion = data => {
    return (dispatch, getState) => {
        console.log(data)
        const root = "https://ntfp--pivotala.salesforce.com/"
        const url = root+"services/data/v48.0/sobjects/ContentVersion"
        let fileInput = data.attachment[0];
        let body = checkFileBase64(fileInput)
        console.log(body)
        console.log(body.fulfilled)

        const contentVersion = {
            "ContentLocation": "S",
            "VersionData": body,
            "Title": data.attachment[0].name,
            "PathOnClient": data.attachment[0].name
        }

        console.log(contentVersion)
    }
}

async function checkFileBase64(file) {
    let promise = getBase64(file);
    return await promise;
}

function getBase64(file) {
    return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        reader.onload = function () { resolve(reader.result); };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}


// export const setSuccessStory = data => {
//   return (dispatch, getState) => {
//     const updateSuccessStory  = Object.assign({}, data)
//
//     var root = "https://ntfp--pivotala.salesforce.com/"
//     var url = root+"services/data/v37.0/sobjects/Attachment"
//
//     const options = {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         "Authorization": "Bearer dXLrPPi1626vBSIE1slelUVr"
//       }
//     };
//
//     console.log("The Create Success Story Update Action");
//     console.log(data);
//
//     var fileInput = data
//     var reader = new FileReader();
//     var body = reader.readAsDataURL(fileInput);
//
//
//     var successStoryAttachment = {
//       "Name" : data.name,
//       "Body": data,
//       "parentId": "a2KP0000000lvwQ" //the id
//     }
//
//     return axios
//       .post(url, updateSuccessStory, options)
//       .then((response) => {
//         dispatch({
//           type: UPDATE_SUCCESS_STORY,
//           updateSuccessStory: response.data
//         })
//       })
//       .catch(error => {
//         throw new SubmissionError({_error: error.response.data.message})
//       })
//
//   }
//
// }

export const setSuccessStory = data => {

  return (dispatch, getState) => {
    const updateSuccessStory  = Object.assign({}, data)

    const options = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer dXLrPPi1626vBSIE1slelUVr"
          }
        };

    return axios
        .post('/api/updateSuccessStory',
          {
            pid: "a2KP0000000lvwQ",
            name: data.name,
            body: "c3RyaW5n\n"
          }, options)
        .then((response) => {
            dispatch({
                type: UPDATE_SUCCESS_STORY,
                updateSuccessStory: response.data
            })
        })
        .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
  }
}
