import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Redirect, Route, Switch} from "react-router";
import WelcomeFormContainer from './../containers/welcome-form-container'
import CustomersLoginForm from "./customers/customers-login-form";
import ScheduleADemo from "./customers/customers-schedule-a-demo";


export default function Customers()  {
  console.log("Customers")

  const locale = useSelector(state => state.locale)

  return (
    <Switch>
      <Route exact path="/customers" component={WelcomeFormContainer}/>
      <Route exact path="/customer-portal" component={WelcomeFormContainer}/>
      <Route exact path="/customers/schedule-a-demo" component={WelcomeFormContainer}/>
      <Route exact path="/customers/enter-your-email-address" component={WelcomeFormContainer}/>

      {/*<Route exact path="/customers" component={CustomersLoginForm}/>*/}
      {/*<Route exact path="/customer-portal" component={CustomersLoginForm}/>*/}
      {/*<Route exact path="/customers/schedule-a-demo" component={ScheduleADemo}/>*/}
      {/*<Route exact path="/customers/enter-your-email-address" component={CustomersLoginForm}/>*/}
    </Switch>
  )

}
