import {TAKE_ACTION_TODAY_ACHIEVED_DIV_HEIGHT} from '../actions/take-action-today-actions'

export default function takeActionTodayAchievedDivHeight(state = 0, action) {
  switch (action.type) {
    case TAKE_ACTION_TODAY_ACHIEVED_DIV_HEIGHT:
      return action.takeActionTodayAchievedDivHeight;
    default:
      return state
  }
}
