import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'

export default function TradeDebtWorkOnThis(props) {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <div>
      <div className='recommendations-gradient-color'>
        <div className='top-priority'>
          <FormattedMessage id='app.debt-report-recommendations.top-priority'/>
        </div>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.collections'/>
        </h2>
      </div>

      <div className='recommendations-page-wrapper'>
        <div className='work-on-this-page-container'>
          <div className='action-plan-subtitle margin-bottom-24-px'>
            { props.account.name }
          </div>

          <p>
            <FormattedMessage id='app.debt-report-work-on-this.text-1'/>
          </p>
          <div className='work-on-this-table-container'>
            <div className='work-on-this-table-row-1'>
              { props.account.name }
            </div>
            <div className='work-on-this-table'>
              <div>
                <FormattedMessage id='app.debt-report-work-on-this.table-row-1'/>
              </div>
              <div className='row-2'>
                {props.account.originalCreditor}
              </div>
              <div>
                <FormattedMessage id='app.debt-report-work-on-this.table-row-2'/>
              </div>
              <div className='row-2'>
                <FormattedMessage id='app.debt-report-work-on-this.table-row-2-text'/>
              </div>
              <div>
                <FormattedMessage id='app.debt-report-work-on-this.table-row-3'/>
              </div>
              <div className='row-2'>
                {formatter.format(props.account.balance)}
              </div>
              <div>
                <FormattedMessage id='app.debt-report-work-on-this.table-row-4'/>
              </div>
              <div className='row-2'>
                {props.account.openDate}
              </div>
            </div>
          </div>

          <h3>
            <FormattedMessage id='app.debt-report-work-on-this.header-text-1'/>
          </h3>
          <p>
            <FormattedMessage id='app.debt-report-work-on-this.text-2'/>
            <span className='bold'> {props.account.name}</span>
            <FormattedMessage id='app.debt-report-work-on-this.text-3'/>
          </p>
          <p>
            <FormattedMessage id='app.debt-report-work-on-this.text-4'/>
            <span className='bold'> {props.account.originalCreditor}</span>
            <FormattedMessage id='app.debt-report-work-on-this.text-5'/>
            <span className='bold'> {props.account.name}</span>
            <FormattedMessage id='app.debt-report-work-on-this.text-6'/>
            <span className='bold'> {props.account.originalCreditor}</span>
            <FormattedMessage id='app.debt-report-work-on-this.text-7'/>
          </p>
          <p>
            <FormattedMessage id='app.debt-report-work-on-this.text-8'/>
            <span className='bold'> {formatter.format(props.account.balance)}</span>
            <FormattedMessage id='app.debt-report-work-on-this.text-9'/>
            {props.account.originalCreditor}
            <FormattedMessage id='app.debt-report-work-on-this.text-10'/>
          </p>
          <p>
            <FormattedMessage id='app.debt-report-work-on-this.text-11'/>
            <span className='bold'> {props.account.openDate}</span>
            <FormattedMessage id='app.debt-report-work-on-this.text-12'/>
            {props.account.name}
            <FormattedMessage id='app.debt-report-work-on-this.text-13'/>
          </p>

          <h3>
            <FormattedMessage id='app.debt-report-work-on-this.header-text-2'/>
          </h3>
          <p>
            <FormattedMessage id='app.debt-report-work-on-this.text-14'/>
          </p>
          <p>
            <FormattedMessage id='app.debt-report-work-on-this.text-15'/>
          </p>
          <h3>
            <FormattedMessage id='app.debt-report-work-on-this.header-text-3'/>
          </h3>
          <div className='submit-button-round sixty-percent-btn'>
            <button className='btn btn-primary btn-block btn-work-on-this'
                    id='debt-report-reviewed-collections-yes'
                    onClick={()=>props.knowCollection(props.account, true)}>
              <FormattedMessage id='app.debt-report-work-on-this.btn-1'/>
            </button>
            <button className='btn btn-primary btn-block btn-work-on-this'
                    id='debt-report-reviewed-collections-yes'
                    onClick={()=>props.knowCollection(props.account, false)}>
              <FormattedMessage id='app.debt-report-work-on-this.btn-2'/>
            </button>
            <button className='btn btn-primary btn-block btn-work-on-this'
                    id='debt-report-reviewed-collections-yes'
                    onClick={()=>props.knowCollection(props.account, false)}>
              <FormattedMessage id='app.debt-report-work-on-this.btn-3'/>
            </button>
          </div>
          <div className='collection-skip-text'>
            <a href='#' onClick={(e)=>props.backToListWithoutRefresh(e)}>
            <FormattedMessage id='app.debt-report-recommendations.collections-text-6'/>
            </a>
          </div>

        </div>
      </div>
    </div>
  )
}