import {connect} from 'react-redux'
import {injectIntl} from "react-intl";
import {toggleLocale} from "../../actions/locale-actions";
import HumanityForwardHeader from "../../components/humanity-forward/humanity-forward-header";

function mapStateToProps(state) {
  return {
    locale: state.locale,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle(currentLocale) {
      dispatch(toggleLocale(currentLocale))
    }
  }
}

const HumanityForwardHeaderContainer = connect(
  mapStateToProps,
    mapDispatchToProps
)(injectIntl(HumanityForwardHeader))

export default HumanityForwardHeaderContainer