import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'

export default function NoEmployerCode() {
  return (
    <div className='container ptxxl'>
      <h5>
        <FormattedMessage id='app.no-employer-code.title'/>
      </h5>
      <h5 className='ptxl'>
        <FormattedMessage id='app.no-employer-code.message.english'
                          values={{
                            email: <a href='mailto:support@neighborhoodtrust.org'>support@neighborhoodtrust.org</a>
                          }}
        />
      </h5>
      <h5 className='ptxl'>
        <FormattedMessage id='app.no-employer-code.message.spanish'
                          values={{
                            email: <a href='mailto:support@neighborhoodtrust.org'>support@neighborhoodtrust.org</a>
                          }}
        />
      </h5>
    </div>
  )
}
