import {GET_COMPLETED_ATTENDED_APPOINTMENTS} from '../actions/calendly-actions'

const completedAttendedAppointments = (state = [], action) => {
  switch (action.type) {
    case GET_COMPLETED_ATTENDED_APPOINTMENTS:
      return action.completedAttendedAppointments;
    default:
      return state
  }
}

export default completedAttendedAppointments
