import React , { useState } from 'react'
import FormattedMessage from './helpers/FormattedMessage'


export default function FooterCalendly(props) {
  const {intl}=props;
  let supportEmail = intl.formatMessage({id: 'app.common.email.support'});
  let supportEmailAction = 'mailto:' + supportEmail;
  let externalTermsOfSvc = 'https://' + intl.formatMessage({id: 'app.common.link.external.terms'});
  let externalPrivacy = 'https://' + intl.formatMessage({id: 'app.common.link.external.privacy'});



  return (

    <div className='footer-calendly'>
    
      { props.locale &&  props.locale !== "es-US" ?
        <div>
          <div className='terms-and-conditions'>
            <span>
              <FormattedMessage id='app.footer.calendly.intro'/>
              </span>
            <span class="text-style-1">
              <a href={externalTermsOfSvc}>
                <FormattedMessage id='app.footer-ap.terms'/>
              </a>
              </span>
            <span> and </span>
            <span class="text-style-1">
              <a href={externalPrivacy}>
                <FormattedMessage id='app.footer-ap.privacy'/>
              </a>
            </span>
            <br/>
            <span><FormattedMessage id='app.footer.support'/> </span>
            <span class="text-style-2">
              <a href={supportEmailAction}>
                {supportEmail}
              </a>
            </span>
          </div>
        </div>
      :
        <div>
        <div className='terms-and-conditions'>
          <span>
            <FormattedMessage id='app.footer.calendly.intro1'/>
          </span>
          <span class="text-style-1">
            <a href={externalTermsOfSvc}>
              <FormattedMessage id='app.footer-ap.terms'/>
            </a>
            </span>
          <span> y </span>
          <span class="text-style-1">
            <a href={externalPrivacy}>
                <FormattedMessage id='app.footer-ap.privacy'/>
            </a>
          </span>
          <span>
            <FormattedMessage id='app.footer.calendly.intro2'/>
          </span>
          <br/>
          <span><FormattedMessage id='app.footer.support'/> </span>
          <span class="text-style-2">
            <a href={supportEmailAction}>
              {supportEmail}
            </a>
          </span>
        </div>
        </div>
      }


    </div>
  )
}
