import React from 'react'
import {connect} from 'react-redux'
import AdminTimezone from '../components/admin-timezone'
import {reduxForm} from "redux-form";
import {setClientTimezone} from "../actions/client-actions";

function mapStateToProps(state) {
    return {
        timezone: state.timezone,
        form: state.form
    }
}

function goToNextPage(props) {
    props.history.push('/coaches-calendly-selection')
}

const AdminTimezoneContainer = connect(
    mapStateToProps
)(reduxForm({
    form: 'appointment-client-timezone',
    onChange: (values, dispatch) => {
        return dispatch(setClientTimezone(values))
    },
    onSubmit: (values, dispatch, props) => {
        dispatch(setClientTimezone(values))
        return goToNextPage(props)
    },
    destroyOnUnmount: false
})(AdminTimezone))

export default AdminTimezoneContainer
