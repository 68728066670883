import React from 'react'

export const Twitter = ({
                           style = {},
                           fill = '#fff',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 29 24',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="headerandfooter_w360-(displaywidths:-320-649)" transform="translate(-45.000000, -4042.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="twitter" transform="translate(45.000000, 4042.000000)">
          <path d="M26.0191055,5.82943527 C26.0375138,6.08526127 26.0375138,6.34114352 26.0375138,6.59696952 C26.0375138,14.4 20.0572033,23.3909226 9.12693604,23.3909226 C5.75952445,23.3909226 2.63136379,22.4223501 -2.27373675e-13,20.7411998 C0.478445228,20.7959875 0.938425541,20.8142688 1.43527904,20.8142688 C4.21379576,20.8142688 6.77158309,19.8823152 8.81410865,18.292459 C6.20115313,18.237615 4.01141801,16.5381271 3.25696194,14.199018 C3.62501416,14.2538057 3.99300974,14.2903683 4.37947023,14.2903683 C4.91308364,14.2903683 5.4467537,14.217243 5.94355056,14.0893863 C3.22020203,13.5411154 1.17761983,11.1654999 1.17761983,8.29645741 L1.17761983,8.22338837 C1.96883582,8.66197134 2.88896636,8.93607866 3.86415181,8.97258505 C2.26325493,7.91266216 1.21443638,6.10354259 1.21443638,4.0568221 C1.21443638,2.96039281 1.50879886,1.95531389 2.02406064,1.07814796 C4.94984355,4.6598807 9.34772206,6.99893359 14.2791573,7.25481584 C14.1871726,6.81623288 14.1319478,6.35942484 14.1319478,5.90256056 C14.1319478,2.64972285 16.7817199,2.27373675e-13 20.0754983,2.27373675e-13 C21.7867882,2.27373675e-13 23.3324603,0.712690284 24.418152,1.86396353 C25.7613897,1.60813753 27.0494592,1.1147106 28.1903757,0.438582963 C27.7486904,1.80917582 26.8102649,2.96044906 25.5774202,3.69136442 C26.773505,3.56350767 27.9327732,3.23450013 29,2.7776921 C28.190489,3.94719042 27.1783737,4.98877574 26.0191055,5.82943527 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </svg>;

export default Twitter