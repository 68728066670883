import axios from "axios";
import 'moment-timezone';


export const GET_DEBT_REPORT_ON_FILE_STATUS = 'GET_DEBT_REPORT_ON_FILE_STATUS';

//LEAVE HERE Being imported and used into action-plan-container, create-pass-form-container, login-form-container.js, additional-questions-advocacy-container
export function doesContactHaveCreditReportOnFile(){
    return (dispatch, getState) => {
        let contactId = getState().login.contactId

        return axios.get('/api/debt-report-on-file', {params: {contactId}})
            .then(response => {
                dispatch({
                    type: GET_DEBT_REPORT_ON_FILE_STATUS,
                    debtReportAlreadyOnFile: response.data
                })
            })
    }

}

