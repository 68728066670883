import {CURRENT_CALENDLY_CONFIRMATION_PATH} from "../actions/calendly-actions";

export default function currentCalendlyConfirmationPath(state = false, action) {
  switch (action.type) {
    case CURRENT_CALENDLY_CONFIRMATION_PATH:
      return action.currentCalendlyConfirmationPath
    default:
      return state
  }
}

