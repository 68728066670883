import React from 'react'
import DebtSummaryChartService from '../../services/debt-summary-chart-service'
import Icon from '../../../resources/icons'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})


export default class SimpleDebtSummaryChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          showDetails: false,
        }
    }

    render() {
      let categories = this.props.debtReport.debtCategories;
      // Make the Pie Chart
      // collect all debt categories, assign colors to pie chart based on table's key-column colors

      const debtCategories = [
        {name: categories[0].name, amount: categories[0].totalBalance, sectionColor: "color-4",},
        {name: categories[1].name, amount: categories[1].totalBalance, sectionColor: "color-2",},
        {name: categories[2].name, amount: categories[2].totalBalance, sectionColor: "color-5",},
        {name: categories[3].name, amount: categories[3].totalBalance, sectionColor: "color-3",},
        {name: categories[4].name, amount: categories[4].totalBalance, sectionColor: "color-6",},
        {name: categories[5].name, amount: categories[5].totalBalance, sectionColor: "color-7"},
        {name: categories[6].name, amount: categories[6].totalBalance, sectionColor: "color-1"}
      ]

      //take debt categories data sets, return data to feed to the pie chart 7 sections 
      let totalDebt = this.props.debtReport ? this.props.debtReport.totalDebt : 0
      let accounts = DebtSummaryChartService.buildDebtCategoryChartData(debtCategories, totalDebt)


        return (
          this.props.debtReport && <div >

             <div>
                      <div className='debt-pie-chart-container margin-bottom-32-px'>
                        <Icon name="debt-pie-chart"
                              totalDebt={formatter.format(totalDebt)}
                              section1Dash={accounts[0].dasharray}
                              section1Offset={accounts[0].offset}
                              section1Class={accounts[0].sectionColor}
                              section2Dash={accounts[1].dasharray}
                              section2Offset={accounts[1].offset}
                              section2Class={accounts[1].sectionColor}
                              section3Dash={accounts[2].dasharray}
                              section3Offset={accounts[2].offset}
                              section3Class={accounts[2].sectionColor}
                              section4Dash={accounts[3].dasharray}
                              section4Offset={accounts[3].offset}
                              section4Class={accounts[3].sectionColor}
                              section5Dash={accounts[4].dasharray}
                              section5Offset={accounts[4].offset}
                              section5Class={accounts[4].sectionColor}
                              section6Dash={accounts[5].dasharray}
                              section6Offset={accounts[5].offset}
                              section6Class={accounts[5].sectionColor}
                              section7Dash={accounts[6].dasharray}
                              section7Offset={accounts[6].offset}
                              section7Class={accounts[6].sectionColor}
                              />
                      </div>
                    </div>
          </div>
          )
    }
}
