import React from 'react'

export const TPCPieChart580To669 = ({
                                      style = {},
                                      fill = '#fff',
                                      width = '100%',
                                      className = '',
                                      height = '100%',
                                      viewBox = '0 0 44 40',
                                    }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <text fill="#FFF" fillRule="evenodd" fontFamily="Gotham-Medium, Gotham, Helvetica, Arial, sans-serif" fontSize="12" fontWeight="400" letterSpacing=".167" transform="rotate(-41 94.751 1317.948)">
      <tspan x="865.359" y="295">580-669</tspan>
    </text>
  </svg>

export default TPCPieChart580To669