import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import MyAccount from '../../components/action-plan/action-plan-my-account'
import {verifyToken} from "../../actions/login-actions";
import {currentMyAccountComponent, navCurrentComponent} from "../../actions/nav-actions";

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('login')(state),
    login: state.login,
    currentMyAccountComponent: state.currentMyAccountComponent
  }
}

function mapDispatchToProps(dispatch) {
  return {
    verifyToken(token) {
      dispatch(verifyToken(token))
    },
    navCurrentComponent(component) {
      dispatch(navCurrentComponent(component))
    },
    setMyAccountComponent(name) {
      return dispatch(currentMyAccountComponent(name))
    },
  }
}

const MyAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
  form: 'action-plan-my-account',
  destroyOnUnmount: false
})(injectIntl(MyAccount))
)

export default MyAccountContainer
