import React, {useEffect} from 'react'
import FormattedMessage from '.././helpers/FormattedMessage'
import FooterContainer from '../../containers/footer-container'
import HeaderContainer from "../../containers/header-container";
import CalendlyWidget from "../helpers/CalendlyWidget";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../../actions/login-actions"
import {calendlyEvent, setCurrentCalendlyConfirmationPath} from "../../actions/calendly-actions";
import {displayLoadingIcon} from "../../actions/loading-icon-action";

export default function BFlowPickATime() {

    const registration = useSelector(state => state.registration);
    const login = useSelector(state => state.login);
    const activeCounselor = useSelector(state => state.activeCounselor);
    const locale = useSelector(state => state.locale);
    const employer = useSelector(state => state.employer);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        if(login == null){
           history.push(`/enter-your-email-address`)
        }
        else {
            dispatch(verifyToken(login))
        }
    }, [])

    const onEventScheduled = (event) => {
      //console.log("Pick a time onEventScheduled")
      //console.log(event.data.payload)
      dispatch(displayLoadingIcon(true))
      dispatch(calendlyEvent(event.data.payload.event.uri, event.data.payload.invitee.uri))
      dispatch(setCurrentCalendlyConfirmationPath('/great-your-session-is-scheduled'))
      dispatch(displayLoadingIcon(false))
      history.push('/great-your-session-is-scheduled')
    }


    return (
        activeCounselor && <div>
          <div className='b-flow'>
              <HeaderContainer displayAlert={null}
                               alertName={null}/>
              <main>
                  <div className='container-ap ptxxl'>
                    <h1 className='coach-select-title'>
                      <FormattedMessage id='app.b-flow.pick-a-time.title'/>
                    </h1>
                    <p>
                      <FormattedMessage id='app.b-flow.pick-a-time.instruction'/>
                    </p>
                    <div className='pick-a-coach-back-wrapper'>
                        <Link to={'/select-a-financial-coach'}>
                            <div className='select-counselor-back'>
                              <FormattedMessage id='app.b-flow.pick-a-time.change-coach.button'/>
                            </div>
                        </Link>
                    </div>
                    <CalendlyWidget locale={locale}
                                    counselor={activeCounselor}
                                    registration={registration}
                                    employer={employer}
                                    onEventScheduled={(event)=>onEventScheduled(event)}
                                    landingPage={false}
                                    aria-label='A calendar widget to schedule an appointment'
                                    />
                  </div>
                <FooterContainer/>
              </main>
          </div>
      </div>)
}
