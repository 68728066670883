import React from 'react'

export const ToDoPlant = ({
                            style = {},
                            fill = '',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 99 96',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TAT_1200+" transform="translate(-1053.000000, -182.000000)">
        <g id="Asset-1" transform="translate(1053.000000, 182.000000)">
          <path d="M12,95.8430769 C11.34,92.2615385 10.7076923,88.68 9.99692308,85.1123077 C9.88615385,84.5492308 9.53538462,83.7923077 9.11076923,83.5753846 C4.89230769,81.5584615 4.49538462,77.5061538 3.71076923,73.6569231 C2.78769231,69.0415385 1.92923077,64.4584615 1.07076923,59.8569231 C0.918461538,59.0353846 0.909230769,58.1861538 0.803076923,56.9907692 C7.56,62.8984615 15.8446154,64.0338462 23.9261538,65.2523077 C35.9117797,67.0436716 48.0679305,67.4031193 60.1384615,66.3230769 C67.1353846,65.7076923 74.1123077,64.8969231 81.0692308,63.8907692 C86.9390153,63.1016414 92.5604045,61.0192367 97.5276923,57.7938462 C97.3707692,58.5369231 97.1630769,59.2753846 97.0661538,60.0276923 C96.8538462,61.7169231 96.78,63.4246154 96.4984615,65.1046154 C95.76,69.4292308 95.0446154,73.7630769 94.0938462,78.0276923 C93.7938462,79.3707692 93.0646154,80.9630769 92.0169231,81.72 C90.2630769,82.9753846 89.6307692,84.4292308 89.3815385,86.4646154 C89.0030769,89.6076923 88.2876923,92.7138462 87.7107692,95.8384615 L12,95.8430769 Z" id="Path" fill="#F7505E" fillRule="nonzero"></path>
          <path d="M48.5630769,42.2676923 C48.5106678,41.3770823 48.5742779,40.483438 48.7523077,39.6092308 C60.5215385,39.5676923 73.8969231,40.2738462 85.2692308,43.5 C88.6343416,44.4796289 91.8455682,45.9260014 94.8092308,47.7969231 C99.4246154,50.6815385 99.7430769,57.3784615 94.5507692,60.8584615 C90.4846154,63.5861538 85.9292308,64.9569231 81.2492308,65.9353846 C66.7015385,68.9492308 51.9461538,68.9723077 37.1907692,68.6815385 C34.2738462,68.6261538 31.3707692,67.8507692 28.4584615,67.44 C24.9876923,66.9461538 21.4569231,66.7430769 18.06,65.9492308 C12.5676923,64.6661538 6.89538462,63.6046154 2.58923077,59.4876923 C-1.2,55.8646154 -0.775384615,50.5523077 3.51230769,47.4553846 C8.08153846,44.1461538 13.4215385,42.5815385 18.84,41.8707692 C27.1984615,40.7769231 35.6446154,40.3338462 44.0538462,39.6323077 C44.4268118,39.6408487 44.7979463,39.6872406 45.1615385,39.7707692 L45.1615385,42.5769231 C35.1184615,42.4384615 25.9107692,45.0692308 16.4769231,47.5107692 C14.2682343,48.1126938 12.1739661,49.0748409 10.2784615,50.3584615 C7.79076923,52.0246154 7.87846154,54.9184615 10.5738462,56.22 C13.8634391,57.8412464 17.3210733,59.0964574 20.8846154,59.9630769 C31.8784615,62.5338462 43.0892308,62.7323077 54.3230769,62.4323077 C63.4015385,62.1969231 72.3784615,61.3384615 81.0923077,58.6384615 C82.6892308,58.14 84.2307692,57.8815385 85.7538462,57.1661538 C87.2769231,56.4507692 88.6984615,55.6615385 88.2876923,53.9630769 C87.8759616,52.5487321 86.9221059,51.3543389 85.6338462,50.64 C82.8323077,49.3061538 79.8507692,47.3492308 76.8646154,46.5369231 C71.2661538,45.0276923 65.6353846,44.4876923 59.9492308,43.3846154 C56.2615385,42.6553846 52.4723077,42.6230769 48.5630769,42.2676923 Z" id="Path" fill="#F7505E" fillRule="nonzero"></path>
          <path d="M0.355384615,56.9907692 C7.11230769,62.8984615 15.3969231,64.0338462 23.4784615,65.2523077 C35.4640874,67.0436716 47.6202382,67.4031193 59.6907692,66.3230769" id="Path" stroke="#91D7E9"></path>
          <path d="M7.42153846,82.9107692 C16.0384615,86.1738462 32.5476923,87.8076923 43.4215385,87.1384615" id="Path" stroke="#91D7E9"></path>
          <path d="M88.2553846,55.0892308 C86.7646154,57.5723077 84.5630769,58.0615385 82.6338462,58.5230769 C76.3615385,60.0092308 70.0523077,61.6984615 63.6692308,62.2430769 C55.1261538,62.9676923 46.4907692,62.9723077 37.9107692,62.76 C29.8246154,62.5661538 21.4476923,62.2523077 14.1046154,58.6523077 C10.7123077,56.9907692 10.7123077,56.9907692 9.14307692,55.1261538 C19.8461538,44.82 31.4769231,43.0892308 44.7138462,41.8846154 L46.02,47.8384615 L49.3846154,47.8384615 C48.9738462,45.9415385 48.5815385,44.1230769 48.0969231,41.9030769 C62.8153846,42.4153846 76.1538462,44.7692308 88.2553846,55.0892308 Z M48.6230769,54.8769231 C46.3153846,53.5846154 44.0861538,53.2476923 41.8430769,55.1446154 C46.32,56.9215385 46.9846154,56.8938462 48.6230769,54.8769231 Z M25.5184615,48.7107692 C27.9507692,50.2015385 29.7415385,49.6061538 30.1338462,47.2246154 L25.5184615,48.7107692 Z M69.5861538,48.5769231 C70.6892308,50.8153846 72.2353846,51.1707692 74.4692308,50.0446154 L69.5861538,48.5769231 Z" id="Shape" fill="#615D59" fillRule="nonzero"></path>
          <path d="M31.6984615,0 C36.8123077,1.38461538 41.7461538,2.63538462 46.6523077,4.02 C47.7486088,4.3370668 48.7777967,4.85166072 49.6892308,5.53846154 C52.6615385,7.77692308 52.8184615,11.0076923 50.1923077,13.62 C49.7584615,14.0492308 49.3430769,14.4969231 49.0292308,14.82 C50.5015385,17.1692308 52.1723077,19.3753846 53.3584615,21.8169231 C55.14,25.4815385 54.9507692,29.3169231 52.1861538,32.3723077 C48.1615385,36.8215385 47.1415385,41.6446154 49.0846154,47.22 C49.1630308,47.5507891 49.215498,47.8871961 49.2415385,48.2261538 L45.6553846,48.5630769 C44.5707692,45.2815385 44.1369231,42.0553846 45.0646154,38.6723077 C46.1076923,34.8461538 45.1938462,33.1338462 41.4876923,31.5553846 C41.1784615,35.3307692 39.7338462,36.2030769 35.8707692,35.7092308 C30.9230769,35.0769231 25.9015385,33.6923077 20.8661538,35.0769231 C19.1492308,35.5384615 17.5615385,36.5030769 15.6046154,37.3846154 C15.7085464,36.7326658 15.855013,36.088213 16.0430769,35.4553846 C18.0923077,30.0323077 20.8984615,25.1676923 26.3723077,22.5323077 C30.4615385,20.5615385 33.6923077,21.2307692 36.7015385,24.5446154 C37.9370597,25.9320639 39.5044273,26.9833277 41.2569231,27.6 C43.7492308,28.5230769 45.9507692,30.2538462 48.4338462,31.7538462 C53.4553846,27.7015385 50.9861538,21.6 45.7523077,17.0907692 C45.2907692,17.3492308 44.7461538,17.6446154 44.2061538,17.9030769 C40.5138462,19.6569231 37.2830769,18.7615385 35.1830769,15.1338462 C32.4230769,10.4353846 31.9107692,5.21076923 31.6984615,0 Z" id="Path" fill="#48BFAF" fillRule="nonzero"></path>
        </g>
      </g>
    </g>

  </svg>;

export default ToDoPlant