import React from 'react'
import {Field} from 'redux-form'
import FormattedMessage from './FormattedMessage'
import Icon from "../../../resources/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



export const BudgetExpenseField = ({name, id, i18n, minus, jar, increment, decrement}) => (
  <div className='budget-expense-form-group-wrapper'>
    <div className='budget-expense-jar-svg-wrapper'>
      <div className='budget-jar-svg'>
        <Icon name={jar}/>
      </div>
    </div>
    <div className='budget-expense-form-group margin-bottom-8-px'>
      <div className='budget-expense-form-fields-wrapper'>
        <div className='budget-jar-space'></div>
        <div className='budget-expense-label-text'>
          <FormattedMessage id={i18n}/>
        </div>

        <div className='budget-expense-field-wrapper'>
          <div className='budget-expense-field'>
            <div className={`budget-page-minus-svg-wrapper ${minus}`}>
              <div className='budget-page-svg'>
                <a href='#' onClick={ e => decrement(e) }>
                  <FontAwesomeIcon icon={['fas', 'minus-square']} />
                </a>
              </div>
            </div>
            <div className='budget-field'>
              <Field name={name} type='number' id={id} component={renderField} placeholder='$0'/>
            </div>
            <div className='budget-page-plus-svg-wrapper'>
              <div className='budget-page-svg'>
                <a href='#' onClick={ e => increment(e) }>
                  <FontAwesomeIcon icon={['fas', 'plus-square']}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)


const renderField = ({input, type, id, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>

    <input {...input}  id={id} placeholder={placeholder} type="number" pattern="[0-9]*" inputMode="numeric" min="0"
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default BudgetExpenseField