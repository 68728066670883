import React from 'react'

export const QuizCheck = ({
                              style = {},
                              fill = '#141006',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 44 44',
                            }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Section-2B---Understanding-Your-Credi-Card-Statement" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TPC----CreditCard_Correct---Max-widths-of-various-elements" transform="translate(-887.000000, -303.000000)" fill="#7ED321" fillRule="nonzero">
        <path d="M923.78125,320.359375 C923.78125,319.82465 923.609377,319.385418 923.265625,319.041667 L920.658854,316.463542 C920.296005,316.100693 919.866322,315.919271 919.369792,315.919271 C918.873261,315.919271 918.443578,316.100693 918.080729,316.463542 L906.393229,328.122396 L899.919271,321.648438 C899.556422,321.285588 899.126739,321.104167 898.630208,321.104167 C898.133678,321.104167 897.703995,321.285588 897.341146,321.648438 L894.734375,324.226562 C894.390623,324.570314 894.21875,325.009546 894.21875,325.544271 C894.21875,326.059898 894.390623,326.489582 894.734375,326.833333 L905.104167,337.203125 C905.467016,337.565974 905.896699,337.747396 906.393229,337.747396 C906.908857,337.747396 907.348088,337.565974 907.710938,337.203125 L923.265625,321.648438 C923.609377,321.304686 923.78125,320.875003 923.78125,320.359375 Z M928.049479,313.971354 C930.016503,317.332482 931,321.008661 931,325 C931,328.991339 930.016503,332.672292 928.049479,336.042969 C926.082455,339.413645 923.413645,342.082455 920.042969,344.049479 C916.672292,346.016503 912.991339,347 909,347 C905.008661,347 901.327708,346.016503 897.957031,344.049479 C894.586355,342.082455 891.917545,339.413645 889.950521,336.042969 C887.983497,332.672292 887,328.991339 887,325 C887,321.008661 887.983497,317.327708 889.950521,313.957031 C891.917545,310.586355 894.586355,307.917545 897.957031,305.950521 C901.327708,303.983497 905.008661,303 909,303 C912.991339,303 916.672292,303.983497 920.042969,305.950521 C923.413645,307.917545 926.082455,310.591129 928.049479,313.971354 Z" id=""></path>
      </g>
    </g>

  </svg>;

export default QuizCheck