import React from 'react'
import FormattedMessage from '../../scripts/components/helpers/FormattedMessage'

export const DebtPieChart = ({
                            totalDebt,
                            section1Dash,
                            section1Offset,
                            section1Class,
                            section2Dash,
                            section2Offset,
                            section2Class,
                            section3Dash,
                            section3Offset,
                            section3Class,
                            section4Dash,
                            section4Offset,
                            section4Class,
                            section5Dash,
                            section5Offset,
                            section5Class,
                            section6Dash,
                            section6Offset,
                            section6Class,
                            section7Dash,
                            section7Offset,
                            section7Class
                        }) =>
  <svg
    width={'100%'}
    style={{strokeWidth: 16}}
    height={'100%'}
    viewBox={'0 0 180 181'}
    className={'debt-pie-chart'}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="90" cy="90" r="74" className={section1Class}
        strokeDasharray={section1Dash} strokeDashoffset={section1Offset}/>

      <circle cx="90" cy="90" r="74" className={section2Class}
        strokeDasharray={section2Dash} strokeDashoffset={section2Offset}/>

      <circle cx="90" cy="90" r="74" className={section3Class}
        strokeDasharray={section3Dash} strokeDashoffset={section3Offset}/>

      <circle cx="90" cy="90" r="74" className={section4Class}
        strokeDasharray={section4Dash} strokeDashoffset={section4Offset}/>

      <circle cx="90" cy="90" r="74" className={section5Class}
        strokeDasharray={section5Dash} strokeDashoffset={section5Offset}/>

      <circle cx="90" cy="90" r="74" className={section6Class}
        strokeDasharray={section6Dash} strokeDashoffset={section6Offset}/>

      <circle cx="90" cy="90" r="74" className={section7Class}
        strokeDasharray={section7Dash} strokeDashoffset={section7Offset}/>

      <text x="50%" y="44%" fill="#333" className='total-debt-heading'>
          <FormattedMessage id='app.debt-report.debt-pie-chart.total-debt'>
          {(txt) => (
            <tspan>
                {txt}
            </tspan>
          )}
          </FormattedMessage>
      </text>
      <text x="50%" y="58%"fill="#333" className='total-debt-number'>
        <tspan>{totalDebt}</tspan>
      </text>
    </g>
  </svg>

export default DebtPieChart