import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import DebtTradeAccount from "./debt-trade-account";
import DebtTradeAccounts from "./debt-trade-accounts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function TradeDebtAll(props) {

  const disputeArray = props.cat.tradeDebtAccounts.filter(account => account.flaggedInOnboarding === "Yes" &&
    account.disputeLetterReason === "Collection: Not Recognized")

  const coachArray =
    props.cat.tradeDebtAccounts.filter(account => account.flaggedInOnboarding === "Yes" &&
      account.disputeLetterReason !== "Collection: Not Recognized" &&
      account.notes != null &&
      account.notes.includes("understand"))

  const notWorkedOnArray = props.cat.tradeDebtAccounts.filter(account => account.flaggedInOnboarding !== "Yes").sort((a,b) => b.balance - a.balance)
  const workedOnArray = props.cat.tradeDebtAccounts.filter(account => account.flaggedInOnboarding === "Yes").sort((a,b) => b.balance - a.balance)

  function clientReviewedAll() {
    return props.cat.tradeDebtAccounts.length === (disputeArray.length + coachArray.length)
  }

  function disputeOnline() {
    console.log("Dispute online")
  }

  function disputeOffline(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    props.setCollectionPage("disputeOffline")
  }

  return (
    <div>
      <div className='recommendations-gradient-color'>
        <div className='top-priority'>
          <FormattedMessage id='app.debt-report-recommendations.top-priority'/>
        </div>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.collections'/>
        </h2>
      </div>

      <div className='recommendations-page-wrapper'>
        {
        clientReviewedAll() === true ?
          <div>
            {
              disputeArray.length > 0 ?
                <div>
                  <p>
                    <FormattedMessage id='app.debt-report-recommendations.collections-text-1a'/> {disputeArray.length} <FormattedMessage id='app.debt-report-recommendations.collections-text-1b'/>
                  </p>
                  <DebtTradeAccounts accounts={disputeArray}
                                     firstBookedAppointmentStr={props.firstBookedAppointmentStr}
                                     type={"dispute"}
                                     disputeOnline={() => props.setCollectionPage("disputeStep1")}
                                     disputeOffline={(e) => disputeOffline(e)}
                  />
                </div> :
                <div />
            }

            {
              coachArray.length > 0 ?
                <div className='collections-speak-with-a-coach'>
                  <p>
                    <FormattedMessage id='app.debt-report-reviewed-collections.text-3'/>
                  </p>
                  <DebtTradeAccounts accounts={coachArray}
                                     firstBookedAppointmentStr={props.firstBookedAppointmentStr}
                                     type={"coach"}
                                     disputeOnline={() => props.setCollectionPage("disputeStep1")}
                                     disputeOffline={(e) => disputeOffline(e)}
                  />
                </div> :
                <div />
            }
          </div>:
          <div>
            <p>
              <FormattedMessage id='app.debt-report-recommendations.collections-text-1'/>
              { props.cat.tradeDebtAccounts.length }
              <FormattedMessage id='app.debt-report-recommendations.collections-text-2'/>
            </p>
            <div className='collection-action-container'>
              {
                notWorkedOnArray.map((account) => (
                  <DebtTradeAccount account={account}
                                    view={(account) => props.view(account)}
                  />
                ))
              }
            </div>

            {
              workedOnArray.length > 0 ?
                <div className='collection-already-worked-on'>
                  <p>
                    <FormattedMessage id='app.debt-report-recommendations.collections-text-7'/>
                  </p>

                  {
                    workedOnArray.map((account) => (
                      <DebtTradeAccount account={account}
                                        view={(account) => props.view(account)}
                      />
                    ))
                  }
                </div> :

                <div/>
            }
            
          </div>

        }
      </div>
    </div>
  )
}