import axios from 'axios'
import {SubmissionError} from 'redux-form'

export const GET_MESSAGES_BY_TO = 'GET_MESSAGES_BY_TO';
export const GET_MESSAGES_BY_FROM = 'GET_MESSAGES_BY_FROM';
export const MESSAGE_TO_VIEW = 'MESSAGE_TO_VIEW';

export const createMessage = data => {
    return (dispatch, getState) => {
        const createMessage = Object.assign({}, data)
        // console.log("DATA: ", data)
        createMessage.from = data.fromId
        createMessage.subject = data.subject
        createMessage.message = data.body
        createMessage.parentEdsMessageId = data.parentEdsMessageId
        // console.log("MESSAGE TO SEND", createMessage)
        return axios
            .post('/api/message/create', createMessage)
            .then((response) => {
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}


    export const messageToView = data => {
        return (dispatch, getState) => {
            dispatch({
                type: MESSAGE_TO_VIEW,
                messageToView: data
            })
        }
    }