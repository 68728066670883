import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import FicoScoreItem from '../components/fico-score-item'
import FicoScoreImage from '../components/fico-score-image'
import FicoMeterImage from '../components/fico-meter-image'

const options = { year: 'numeric', month: 'long',day: '2-digit'};

export default class FicoScore extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return (

          <div id='action-plan-fico-score' className='action-plan-pages'>
            {
              this.props.ficoScore ?
                  (
                      <div className=''>
                        <div className='fico-score-and-gauge-container'>
                          <div className='blue-fico-score-box-container margin-bottom-8-px'>
                            <div className='blue-fico-score-box-outline'/>
                            <div className='blue-fico-score-image-container'>
                              <FicoScoreImage ficoScore={this.props.ficoScore.ficoScore} />
                            </div>
                          </div>
                          <div className='fico-gauge-container'>
                              <FicoMeterImage ficoScore={this.props.ficoScore.meter} />
                          </div>
                        </div>
                        <div className='fico-score-text margin-bottom-24-px'>
                          <FormattedMessage id='app.action-plan-fico-score.transunion1'/>
                            <span className='fico-score-date'>
                              {new Intl.DateTimeFormat(
                                this.props.locale, options).format(
                                new Date(this.props.ficoScore.createdDate))}
                            </span>
                          <FormattedMessage id='app.action-plan-fico-score.transunion2'/>
                        </div>

                        <div className='action-plan-divide margin-bottom-24-px'></div>

                          <div className='fico-2nd-heading margin-bottom-24-px'>
                              <FormattedMessage id='app.action-plan-fico-score.desc-kim-title' />
                          </div>
                          {
                              this.props.ficoScore.detailsp1 == null && this.props.ficoScore.detailsp2 == null ?
                              (
                                  <div className='fico-score-insufficient'>
                                    <div className='fico-3rd-heading margin-bottom-24-px'>
                                      <FormattedMessage id='app.action-plan-fico-score.desc-insufficient-title' />
                                    </div>
                                    <div className='fico-score-text margin-bottom-24-px'>
                                        <FormattedMessage id='app.action-plan-fico-score.desc-insufficient-text' />
                                    </div>
                                  </div>
                              )
                              :
                              (
                                <div/>
                              )
                          }
                          {
                              this.props.locale === 'es-US' ?
                              (
                                  <div className=''>
                                      <FicoScoreItem item={'1'}
                                                       detail={this.props.ficoScore.detailsp1}
                                                       description={this.props.ficoScore.descsp1}
                                                       keepinmind={this.props.ficoScore.kimsp1}/>
                                      <FicoScoreItem item={'2'}
                                                       detail={this.props.ficoScore.detailsp2}
                                                       description={this.props.ficoScore.descsp2}
                                                       keepinmind={this.props.ficoScore.kimsp2}/>
                                  </div>
                              ) :
                              (
                                  <div className=''>
                                      <FicoScoreItem item={'1'}
                                                       detail={this.props.ficoScore.detail1}
                                                       description={this.props.ficoScore.desc1}
                                                       keepinmind={this.props.ficoScore.kim1}/>
                                      <FicoScoreItem item={'2'}
                                                       detail={this.props.ficoScore.detail2}
                                                       description={this.props.ficoScore.desc2}
                                                       keepinmind={this.props.ficoScore.kim2}/>
                                  </div>
                              )
                          }
                      </div>
                  ) : (<div className=''></div>)
            }
            <div className='clear-float'></div>
            <div className='fico-score-credit-education-link margin-bottom-24-px'>
              <a target='_blank' href='http://www.ficoscore.com/info'>
                <FormattedMessage id='app.action-plan-fico-score.credit-education-link'/>
              </a>
            </div>
            <div className='action-plan-divide margin-bottom-24-px'/>
            <div className='fico-score-disclaimer-box margin-bottom-24-px'>
              <div className='fico-score-disclaimer-wrapper'>
                <div className='fico-score-disclaimer-title margin-bottom-8-px'>
                  <FormattedMessage id='app.action-plan-fico-score.disclaimer-title'/>
                </div>
                <div className='fico-score-disclaimer-text'>
                  <p>
                    <FormattedMessage id='app.action-plan-fico-score.disclaimer1'/>
                  <a target='_blank' href='http://www.annualcreditreport.com' id='credit-report'>
                        <FormattedMessage id='app.action-plan-fico-score.disclaimer2'/>
                    </a>
                  </p>
                  <p>
                      <FormattedMessage id='app.action-plan-fico-score.disclaimer3'/>
                  </p>
                  <p>
                      <FormattedMessage id='app.action-plan-fico-score.disclaimer4'/>
                  </p>
                  <p>
                      <FormattedMessage id='app.action-plan-fico-score.disclaimer5'/>
                  </p>
                </div>
              </div>
            </div>
        </div>)
    }
}
