import React, {useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";

function CustomerPortalAccountHeader(props) {

    return(
        <div className='action-plan-title-wrapper margin-bottom-24-px'>
          <div className='action-plan-title-background-gradient'></div>
          <div className='action-plan-title margin-bottom-8-px'>
            <FormattedMessage id='app.customer-portal.my-account-header'/>
          </div>
        </div>
    )
}

export default (injectIntl(CustomerPortalAccountHeader))