import React from 'react'

export const WorksContact = ({
                              style = {},
                              fill = '#fff',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 37 32',
                            }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <g id="Page-7" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <g id="howitworks_1200+" transform="translate(-768.000000, -1299.000000)" fillRule="nonzero">
        <g id="Group-6" transform="translate(768.000000, 1299.000000)">
          <path d="M20,19.859375 C20,20.9947973 19.6744824,21.9687459 19.0234375,22.78125 C18.3723926,23.5937541 17.5885463,24 16.671875,24 L3.328125,24 C2.41145375,24 1.62760742,23.5937541 0.9765625,22.78125 C0.325517578,21.9687459 0,20.9947973 0,19.859375 C0,18.9739539 0.0442703906,18.1380248 0.1328125,17.3515625 C0.221354609,16.5651002 0.385415469,15.7734415 0.625,14.9765625 C0.864584531,14.1796835 1.16926898,13.4973987 1.5390625,12.9296875 C1.90885602,12.3619763 2.39843445,11.8984393 3.0078125,11.5390625 C3.61719055,11.1796857 4.31770437,11 5.109375,11 C6.47396516,12.33334 8.10415719,13 10,13 C11.8958428,13 13.5260348,12.33334 14.890625,11 C15.6822956,11 16.3828095,11.1796857 16.9921875,11.5390625 C17.6015655,11.8984393 18.091144,12.3619763 18.4609375,12.9296875 C18.830731,13.4973987 19.1354155,14.1796835 19.375,14.9765625 C19.6145845,15.7734415 19.7786454,16.5651002 19.8671875,17.3515625 C19.9557296,18.1380248 20,18.9739539 20,19.859375 Z M14.234375,1.765625 C15.4114642,2.9322975 16,4.34374172 16,6 C16,7.65625828 15.4140684,9.07030664 14.2421875,10.2421875 C13.0703066,11.4140684 11.6562583,12 10,12 C8.34374172,12 6.92969336,11.4140684 5.7578125,10.2421875 C4.58593164,9.07030664 4,7.65625828 4,6 C4,4.34374172 4.58593164,2.92969336 5.7578125,1.7578125 C6.92969336,0.585931641 8.34374172,0 10,0 C11.6562583,0 13.0677025,0.588535781 14.234375,1.765625 Z" id="" fill="#A1A1A4"></path>
          <path d="M36.8571429,26.5535714 C36.8571429,27.851197 36.4851228,28.9642811 35.7410714,29.8928571 C34.9970201,30.8214332 34.1011957,31.2857143 33.0535714,31.2857143 L17.8035714,31.2857143 C16.7559471,31.2857143 15.8601228,30.8214332 15.1160714,29.8928571 C14.3720201,28.9642811 14,27.851197 14,26.5535714 C14,25.5416616 14.0505947,24.586314 14.1517857,23.6875 C14.2529767,22.788686 14.4404748,21.8839331 14.7142857,20.9732143 C14.9880966,20.0624954 15.3363074,19.2827413 15.7589286,18.6339286 C16.1815497,17.9851158 16.7410679,17.4553592 17.4375,17.0446429 C18.1339321,16.6339265 18.9345193,16.4285714 19.8392857,16.4285714 C21.3988173,17.9523886 23.2618939,18.7142857 25.4285714,18.7142857 C27.5952489,18.7142857 29.4583255,17.9523886 31.0178571,16.4285714 C31.9226236,16.4285714 32.7232108,16.6339265 33.4196429,17.0446429 C34.1160749,17.4553592 34.6755931,17.9851158 35.0982143,18.6339286 C35.5208354,19.2827413 35.8690462,20.0624954 36.1428571,20.9732143 C36.416668,21.8839331 36.6041662,22.788686 36.7053571,23.6875 C36.8065481,24.586314 36.8571429,25.5416616 36.8571429,26.5535714 Z M30.2678571,5.875 C31.613102,7.20834 32.2857143,8.82141911 32.2857143,10.7142857 C32.2857143,12.6071523 31.6160781,14.2232076 30.2767857,15.5625 C28.9374933,16.9017924 27.321438,17.5714286 25.4285714,17.5714286 C23.5357048,17.5714286 21.9196496,16.9017924 20.5803571,15.5625 C19.2410647,14.2232076 18.5714286,12.6071523 18.5714286,10.7142857 C18.5714286,8.82141911 19.2410647,7.20536384 20.5803571,5.86607143 C21.9196496,4.52677902 23.5357048,3.85714286 25.4285714,3.85714286 C27.321438,3.85714286 28.9345171,4.52975518 30.2678571,5.875 Z" id="" fill="#EF3E42"></path>
        </g>
      </g>
    </g>

  </svg>;

export default WorksContact
