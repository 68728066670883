import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import ValidationService from '../services/validation-service'
import {createPassword, createPostgresRecord} from '../actions/login-actions'
import {getContact} from '../actions/registration-actions'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form';
import {setActiveCounselor} from "../actions/counselor-actions";
import PostgresResetPasswordForm from "../components/postgres-reset-password-form";


function mapStateToProps(state) {
    return {
        formSyncErrors: getFormSyncErrors('login')(state),
        login: state.login,
        employer: state.employer,
        registration: state.registration,
        activeCounselors: state.activeCounselors,
        bookedAppointments: state.bookedAppointments,
        completedAttendedAppointments: state.completedAttendedAppointments,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setActiveCounselor(counselor) {
            dispatch(setActiveCounselor(counselor))
        },
        getContact(id) {
           dispatch(getContact(id))
        }
    }
}

function goToNextPage(props) {
    props.history.push(`/action-plan`)
}

const PostgresResetPasswordFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
        form: 'reset-new-password-form',
        validate: ValidationService.createPassword,
        onSubmit: (values, dispatch, props) => {
            return dispatch(createPostgresRecord(props.registration)).then(() => {
                return dispatch(createPassword(values)).then(() => {
                    goToNextPage(props)
                })
            })
        },
        destroyOnUnmount: false
    })(injectIntl(PostgresResetPasswordForm))
)

export default PostgresResetPasswordFormContainer;

