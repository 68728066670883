const en_jpmc = {
  'app.common.email.support': 'support@mytrustplus.org',
  'app.common.email.support-text': 'Email Support',
  'app.common.email.washUnivHRPO': 'hrpo@wustl.edu',
  'app.common.link.external.self-scheduler': 'http://bit.ly/2krEB4t',
  'app.common.link.external.skype': 'www.skype.com/en/legal',
  'app.common.link.external.zoom': 'zoom.us/docs/en-us/privacy-and-security.html',
  'app.common.link.external.google': 'support.google.com/meet/answer/9852160',
  'app.common.link.external.nytimes': 'http://www.nytimes.com/2016/02/29/business/dealbook/overdraft-practices-continue-to-gut-bank-accounts-and-haunt-customers.html?_r=0',
  'app.common.link.external.vice': 'https://news.vice.com/story/how-to-establish-credit',
  'app.common.link.external.npr': 'http://www.npr.org/sections/money/2013/06/18/193176928/episode-466-diy-finance',
  'app.common.link.external.nycgov': 'http://www1.nyc.gov/site/dca/media/pr101816.page',
  'app.common.link.external.terms': 'neighborhoodtrust.org/terms-of-service/',
  'app.common.link.external.privacy': 'neighborhoodtrust.org/privacy-policy/',
  'app.common.phone.tollfree': '800-927-8772',
  'app.common.emergency-covid-email.support': 'covidsupport@mytrustplus.org',

  'app.common.punctuation.period': '.',
  'app.common.punctuation.space': ' ',
  'app.common.punctuation.question-mark':'?',
  'app.common.punctuation.comma': ', ',

  'app.common.button.goback': 'Go back',
  'app.common.continue.button': 'Continue',
  'app.common.send-code.button': 'Send code',
  'app.common.got-it.button': 'Got it!',
  'app.common.save.button': 'Save',
  'app.common.cancel.button': 'Cancel',
  'app.common.edit.button': 'Edit',
  'app.common.next.button': 'Next',
  'app.common.keep-going.button': 'Keep Going',
  'app.common.maybe-later.button': 'Maybe later',
  'app.common.yes-please.button' : 'Yes, please',
  'app.common.change-coach.button': 'Change coach',

  'app.message.login.unsuccessful': 'Your login was unsuccessful. Please check your password and try again.',
  'app.message.tempcode.unsuccessful': 'Your login was unsuccessful. Please check your temp code and try again.',
  'app.errors.tempcode.required': 'Temporary code is required',
  'app.message.change-password.unsuccessful': 'Please check your password and try again.',
  'app.tempcode.resent.code.message' : 'Didn’t receive the code to your phone? Sorry about that! Check your email now for the code.',

  'app.errors.password.required': 'Password is required',
  'app.errors.password.length': 'Password must have a minimum of 8 characters',
  'app.errors.password.containupper': 'Password must contain an upper case letter',
  'app.errors.password.containlower': 'Password must contain a lower case letter',
  'app.errors.password.containnumber': 'Password must contain a number',
  'app.errors.password.notcontainspecial': 'Password must not contain a special character',
  'app.errors.password.containspecial': 'Password must contain a special character',
  'app.errors.password.not-match': 'Passwords must match',

  'app.welcome.title': 'Welcome!',
  'app.welcome.instruction1': 'Get started today by entering your email.',
  'app.welcome.input.email': 'Email',

  'app.welcome.logout.title': 'Logged out',
  'app.welcome.logout.instruction1': 'Log back in by entering your email.',

  'app.welcome.timeout.title': 'Logged out due to inactivity',
  'app.welcome.timeout.instruction1': 'Log back in by entering your email.',

  'app.password.title': 'Enter Password',
  'app.password.instruction1': 'Enter your password to complete your login.',
  'app.password.input.password': 'Password',
  'app.password.alt-link': 'Forgot password?',

  'app.reschedule.title': 'Schedule coaching session!',
  'app.reschedule.instruction1': 'Please click the link below and schedule a coaching session.',

  'app.notification.title': 'Create a login',
  'app.notification.instruction1': 'The final step is to create the password for your account. On the next page enter the temp code sent to your phone. Once you create your password, you\'ll be able to review details of your debt summary, see your credit score, manage your upcoming session, and create a budget to review with your coach.',
  'app.notification.instruction2': 'We\'ve already sent a temporary code to your phone number.',
  'app.notification.alt-link': 'I haven\'t received my code',

  'app.tempcode.title': 'We sent a temporary code!',
  'app.tempcode.instruction1': 'A temporary code was sent to your email. Enter it below.',
  'app.tempcode.input.tempcode': 'Temporary code',
  'app.tempcode.alt-link': 'Resend temporary code',

  'app.newuser.title': 'New user',
  'app.newuser.instruction1': 'We don\'t recognize your email, but you can get started today by scheduling a session with a financial coach.',
  'app.newuser.dcl.debtconsolidationloan.instruction1': 'We don\'t recognize your email, but you can get started today by ',
  'app.newuser.dcl.debtconsolidationloan.instruction2': 'taking our prescreener!',
  'app.newuser.alt-link': 'Try a different email address',

  'app.forgotpassword-form.title': 'Forgot password?',
  'app.forgotpassword-form.instruction': 'No problem! We will send a message to your email address with a link to reset your password.',
  // 'app.forgotpassword-form.instruction1': 'No problem! We will send a message with a temporary code to your phone number.',
  'app.forgotpassword-form.instruction1': 'Please check your email for the link to reset your password.',
  'app.forgotpassword-form.send.button': 'Send link',

  'app.forgotpassword-confirmation.title': 'Thank you for confirming your email.',
  'app.forgotpassword-confirmation.instruction': 'You may now proceed to create a new password.',
  'app.forgotpassword-confirmation.continue.button': 'Continue',

  'app.reset-password.title': 'Hi, it looks like you\'ve been here before.',
  'app.reset-password.instructions': 'We\'ve been doing some security updates and you\'ll have to reset your password in order to log in. We have sent you an email with instructions. It may take a few moments to show up in your account.',

  'app.reset-new-password-form.title': 'Please make a new password.',

  'app.account-error.title': 'There has been an error.',
  'app.account-error.instructions': 'Something when wrong with accessing your account. We\'ve been notified and will let you know when you can login.',

  'app.create-pass.title': 'Create a password',
  'app.create-pass.instruction1': 'Password must: ',
  'app.create-pass.rules1': 'Must have a minimum of 8 characters',
  'app.create-pass.rules2': 'Contain upper and lower case letters',
  'app.create-pass.rules3': 'Contain at least one number',
  'app.create-pass.rules4': 'Cannot contain special characters',
  'app.create-pass.rules5': 'Passwords must match',
  'app.create-password.password': 'Password',
  'app.create-password.reenter-password': 'Re-enter password',

  'app.inactivity.message.part1': 'You haven\'t done anything for a while. We\'re concerned with your security, so we\'ll log you out in ',
  'app.inactivity.message.time': ' seconds',
  'app.inactivity.message.part2': ' if you stay idle.',
  'app.inactivity.continue': 'I\'m still here!',

  'app.introduction.locale-link': 'Español',
  'app.landing-page.covid19.text1': 'During these difficult times, ',
  'app.landing-page.covid19.text2': 'TrustPlus™ ',
  'app.landing-page.covid19.text3': 'can help you create a plan amidst the uncertainty to get a handle on your cash flow and plan for upcoming bills and expenses. Talk to one of us about how we can {signUpUrl} or visit our {resourcesUrl}.',
  'app.landing-page.covid19.text4': 'help',
  'app.landing-page.covid19.text5': 'resources page',
  'app.landing-page.introduction.sign-up.button-white': 'Sign Up',
  'app.landing-page.introduction.first-step.button': 'Take the 1st step on your journey',
  'app.landing-page.introduction.log-on.button': 'Log In',
  'app.landing-page.introduction.dreams-1': 'You have dreams.',
  'app.landing-page.introduction.debt': 'You have debt.',
  'app.landing-page.introduction.hero-label-mobile': 'Elayna, Financial Coach',
  'app.landing-page.introduction.intro-blurb': 'Our experienced coaches have guided thousands of people like you to start their journey from the burden of debt to realizing their dreams.',
  'app.landing-page.introduction.intro-tagline': 'We are here for you.',
  'app.landing-page.introduction.sign-up.button': 'Sign up!',
  'app.landing-page.how-it-works.title': 'How It Works',
  'app.landing-page.how-it-works.text-1': 'You can chat with a coach via phone, video chat, or text.',
  'app.landing-page.how-it-works.text-2': 'Individualized financial plans will be created to meet your unique needs.',
  'app.landing-page.how-it-works.text-3': 'Our coaches provide one-on-one support and expert guidance—with no judgment.',
  'app.landing-page.how-it-works.text-4': 'All conversations are 100% confidential.',
  'app.landing-page.how-it-works.text-5': 'We use the highest technology standards to protect your personal information.',
  'app.landing-page.company-logo.title': 'Provided By',
  'app.landing-page.company-logo.text-1': 'This service is provided by ',
  'app.landing-page.company-logo.text-2': ' at no cost to you.',
  'app.landing-page.helping-you-succeed.title': 'Helping You Succeed',
  'app.landing-page.helping-you-succeed.title-text-1': 'We Contact You',
  'app.landing-page.helping-you-succeed.text-1-a': 'After picking your time, a financial coach makes it simple by reaching out to you either by phone or video chat.',
  'app.landing-page.helping-you-succeed.title-text-2': 'Online Tools',
  'app.landing-page.helping-you-succeed.text-2': 'Get access to your credit report, FICO® score, and easy budgeting.',
  'app.landing-page.helping-you-succeed.title-text-3': 'We’re Here to Listen',
  'app.landing-page.helping-you-succeed.text-3': 'We are a mission-drive non-profit and our coaches do not receive commissions, so we can focus on a plan that’s made for your credit history, your budget, your story.',
  'app.landing-page.testimonials.title': 'Testimonials',
  'app.landing-page.testimonials.testimonial-1a': 'The way I approach money has completely changed and I have learned the importance of using the right accounts and preserving my credit.',
  'app.landing-page.testimonials.testimonial-1-red': ' I am in a better position financially',
  'app.landing-page.testimonials.testimonial-1b': ' than I have ever been.',
  'app.landing-page.testimonials.testimonial-author-1': 'Carlos',
  'app.landing-page.testimonials.testimonial-2a': 'No one really taught me anything about credit and truth be told I never really cared to ask until it became important for me to know.',
  'app.landing-page.testimonials.testimonial-2-red': ' Having someone who guides you makes all the difference.',
  'app.landing-page.testimonials.testimonial-2b': ' I have learned so much.',
  'app.landing-page.testimonials.testimonial-author-2': 'Lundeen',
  'app.landing-page.testimonials.testimonial-3-red': 'My financial counselor has been a force of motivation and clarity in my life.',
  'app.landing-page.testimonials.testimonial-3a': ' He has been consistent, patient, and extremely helpful. During this pandemic he has put me in touch with resources in my area and has remained diligent in making sure I am not overwhelmed by everything that is happening financially in my life... I am grateful for TrustPlus services and I look forward to continuing to build my financial future for myself and my family... Thank you TrustPlus!',
  'app.landing-page.testimonials.testimonial-author-3': 'Arleena',
  'app.landing-page.financial-empowerment.button': 'Read now',
  'app.landing-page.coaches.title': 'Our coaches',
  'app.landing-page.in-the-press.title': 'In the press',
  'app.landing-page.coaches.hello-1': 'Hi there, I\'m ',
  'app.landing-page.coaches.hello-2': '!',
  'app.landing-page.coaches.about-me': 'About Me',
  'app.landing-page.coaches.bio.jonathan': 'After finishing my service with the Peace Corps in the Dominican Republic as a Community Economic Development Volunteer, I joined as a Financial Coach at Neighborhood Trust Financial Partners, a nonprofit organization. Ever since college I have enjoyed using my talents and skill set to help other people achieve their goals. My favorite part of the job is working with clients on finding creative solutions to tackle their issues. I hold a BA in Economics from the University of Notre Dame and enjoy playing video games, being terrible at golf, and cooking.',
  'app.landing-page.coaches.bio.tiffany': 'I’m a California native that moved to New York City to work for Neighborhood Trust. On the weekend, I enjoy going to a café where dogs are welcomed, budgeting and reading personal finance articles. I believe that taking an active approach to finances allows someone to stay in control when life throws a curveball (Go Dodgers)! I obtained my B.A. in Psychology from the University of California Los Angeles.',
  'app.landing-page.coaches.bio.elise': 'I am a New Jersey native who has also lived in California, France, Argentina, and Brooklyn. After years in the corporate world, I decided to follow my passion for personal finance and for guiding people through a system that can often feel confusing. I get great satisfaction from helping my clients tackle their financial issues with confidence and purpose. In my downtime, I love to bake cupcakes and solve crossword puzzles. Je parle français aussi!',
  'app.landing-page.coaches.bio.rosie': 'My passion for personal finance emerged out of my own experiences budgeting, building credit, saving, and managing student loans. This passion drew me to my current role as a Financial Coach at Neighborhood Trust, where I can share all the tools I’ve learned with others and help them reach their financial goals. I was born and raised in Brooklyn, received my BA from Occidental College, and am currently pursuing an MPA from Baruch College. I hope to eventually use my understanding of how policies affect individuals to drive positive change in the broader field of financial empowerment. In my spare time I play soccer, explore new restaurants, and spend time with family.',
  'app.landing-page.coaches.bio.christina': 'With a passion to help and serve my community, I decided to join Neighborhood Trust Financial Partners as a financial coach. I am able to provide individuals with the tools they need to become financially empowered. With a background in college advising, I was able to help hundreds of students with both their college and financial aid applications. I have a B.A in Psychology and am a New York native. My hobbies are reading books on personal finance, business, self-improvement and leadership.',
  'app.landing-page.coaches.bio.chris': 'I joined Neighborhood Trust as a Financial Coach in 2018. I am passionate about helping people and empowering them with the education and tools they need to achieve their financial goals. I firmly believe that financial wellness is integral to building cohesive and prosperous communities. I hold a B.A. in English from Lehman College.',
  'app.landing-page.coaches.speak-with.button': 'Speak with a coach',
  'app.landing-page.tagline.title': 'Get started today!',
  'app.landing-page.tagline.text': 'It’s easy to get started with TrustPlus. Just click “Sign up” and select your 30 minute session.',
  'app.landing-page.tagline.get-started.button': 'Get started!',
  //775 Landing Page
  'app.landing-page.775.intro-text-1': 'Free ',
  'app.landing-page.775.intro-text-2': 'and 100% ',
  'app.landing-page.775.intro-text-3': 'confidential financial coaching to ',
  'app.landing-page.775.intro-text-4': 'help you ',
  'app.landing-page.775.intro-text-5': 'save, reduce debt,',
  'app.landing-page.775.intro-text-6': ' and ',
  'app.landing-page.775.intro-text-7': 'improve ',
  'app.landing-page.775.intro-text-8': 'your ',
  'app.landing-page.775.intro-text-9': 'credit score',
  'app.landing-page.775.intro-text-10': '.',
  'app.landing-page.775.intro-text-11': 'Schedule a session today with a TrustPlus Financial Coach via phone or video chat.',
  'app.landing-page.775.intro-text-12': 'Schedule your session.',
  'app.landing-page.775.how-we-help-1': 'How We Have Helped',
  'app.landing-page.775.how-we-help-2': 'Hear from two of our clients as they share how TrustPlus helped them achieve their financial goals, ranging from buying a house to paying off student loan debt. Two of our expert financial coaches also explain their role and why they are passionate about helping clients achieve their goals—no financial goal is too big or too small.',
  'app.landing-page.775.how-we-help-3': 'Our expert financial coaches are ready to listen and help you as well!',
  'app.landing-page.775.help-you-1': 'How We Can Help You',
  'app.landing-page.775.help-you-2': 'Meet with a Financial Coach',
  'app.landing-page.775.help-you-3': 'It\'s free to schedule a 30 minute session with one of our expert financial coaches.',
  'app.landing-page.775.help-you-4': 'Find a time',
  'app.landing-page.775.help-you-5': 'Chat with us',
  'app.landing-page.775.help-you-5-a': 'Chat with us',
  'app.landing-page.775.help-you-6': 'Don\'t have time for a 30 minute session? Send us a chat, and we\'ll figure it out.',
  'app.landing-page.775.help-you-7': 'Learn with us',
  'app.landing-page.775.help-you-8': 'We’ve prepared exclusive materials to help you make the most of your money.',
  'app.landing-page.775.help-you-9': 'Learn now',

  //DCL Landing page
  'app.dcl-landing-page.how-it-works.title': 'How It Works',
  'app.dcl-landing-page.testimonials.title': 'Testimonials',
  'app.dcl-landing-page.our-impact.title': 'Our Impact',
  'app.dcl-landing-page.who-we-are.title': 'Who We Are',
  'app.dcl-landing-page.benefits.title': 'Benefits of Debt Consolidation',
  'app.dcl-landing-page.faq.title': 'FAQs',
  'app.dcl-landing-page.contact.title': 'Contact Us',
  'app.dcl-landing-page.introduction.title-1': 'Simplify Your',
  'app.dcl-landing-page.introduction.title-2': 'Credit Card Debt',
  'app.dcl-landing-page.introduction.intro-blurb': 'Our expert financial coaches find the best debt consolidation loans from vetted credit unions, saving you time and money.',
  'app.dcl-landing-page.introduction.intro-blurb-2': 'Answer three simple questions',
  'app.dcl-landing-page.introduction.intro-blurb-3': ' to get started!',
  'app.dcl-landing-page.introduction.first-step.button': 'Take our 3-question pre-screener',
  'app.dcl-landing-page.benefits.title-1': 'Benefits of Debt Consolidation Loans',
  'app.dcl-landing-page.benefits.text-1': 'Debt consolidation loans allow you to combine multiple debts into a single loan with one monthly payment at a lower interest rate. TrustPlus\' expert financial coaches rigorously search for the best debt consolidation loans. We do not offer the loans directly, nor do we receive compensation for our recommendations. Our commitment is to you—the client.',
  'app.dcl-landing-page.benefits.subtitle-1': 'How you can benefit from a debt consolidation loan',
  'app.dcl-landing-page.benefits.subtitle-2': 'Take our quick pre-screener to get started!',
  'app.dcl-landing-page.benefits.text-5': 'The pre-screener WILL NOT impact your credit score.',
  'app.dcl-landing-page.how-it-works.subtitle-1': 'Save money on interest',
  'app.dcl-landing-page.how-it-works.text-1': 'Consolidating high-interest credit card debt to a lower interest loan can help you save money and become debt-free sooner.',
  'app.dcl-landing-page.how-it-works.subtitle-2': 'One bill, less stress',
  'app.dcl-landing-page.how-it-works.text-2': 'Simplify your debt management by combining multiple payments into one!',
  'app.dcl-landing-page.how-it-works.subtitle-3': 'Access to financial coaching',
  'app.dcl-landing-page.how-it-works.text-3': 'Our coaches offer personalized guidance and support tailored to your unique needs.',
  'app.dcl-landing-page.how-it-works.subtitle-4': 'Vetted financial products',
  'app.dcl-landing-page.how-it-works.text-4': 'We’ve done the heavy lifting for you, and we don’t make a dime for our recommendations. It’s all about getting you the best product!',
  'app.dcl-landing-page.eliminate-debt.title-1': 'Eliminate your debt faster with debt consolidation',
  'app.dcl-landing-page.testimonials.testimonial-1': '“Working with Tiffany lifted a big load off my back. Her support and consistency were helpful; I couldn\'t have accomplished everything on my own.”',
  'app.dcl-landing-page.testimonials.testimonial-author-1': '—Yakaira',
  'app.dcl-landing-page.testimonials.testimonial-2': '“I was maxing out my credit cards. The interest was killing me. My financial coach straightened me out and now I can set aside a small amount in savings each month.”',
  'app.dcl-landing-page.testimonials.testimonial-author-2': '—Edward',
  'app.dcl-landing-page.testimonials.testimonial-3': '“Thanks to Elise, I was able to access a loan that reduced my monthly debt payment by $146, helping me save over $4K in interest and get out of debt 8 months sooner!”',
  'app.dcl-landing-page.testimonials.testimonial-author-3': '—Annette',
  'app.dcl-landing-page.testimonials.testimonial-4': '“Thanks to TrustPlus, I’m making strides towards reducing my debts. After exploring various options, I found the best path for me. I feel much more confident that I will achieve my goals sooner than anticipated.”',
  'app.dcl-landing-page.testimonials.testimonial-author-4': '—James',
  'app.dcl-landing-page.our-impact.text-1': 'Median Debt Reduction Per Client',
  'app.dcl-landing-page.our-impact.text-2': 'Total Aggregate Debt Reduced Across All Clients',
  'app.dcl-landing-page.our-impact.text-3': 'Total Savings Built',
  'app.dcl-landing-page.who-we-are.text-1': 'TrustPlus is a nonprofit social enterprise that draws on 30 years of leadership and expertise in helping businesses and organizations maximize the financial health and productivity of workers like you.',
  'app.dcl-landing-page.who-we-are.text-2': 'Across the U.S., TrustPlus financial coaches work one-on-one with individuals to help them optimize debt, strengthen credit, and build savings. These ongoing relationships lead to lasting impact for clients.',
  'app.dcl-landing-page.who-we-are.text-3': 'Our commitment is solely to you. We vet financial institutions to find the best product to help our clients better manage their debts and achieve their financial goals. And we do this while not taking a single dime for our recommendations.',
  'app.dcl-landing-page.who-we-are.image-caption': 'Elise, TrustPlus Financial Coach',
  'app.dcl-landing-page.faq.question-1': 'What is a debt consolidation loan?',
  'app.dcl-landing-page.faq.answer-1': 'A debt consolidation loan combines multiple debts into one single loan with a lower interest rate, helping you save money and pay off your debt faster. It’s a convenient way to streamline your debts and reduce financial stress!',
  'app.dcl-landing-page.faq.question-2': 'What is the difference between a debt consolidation loan and a debt management plan?',
  'app.dcl-landing-page.faq.answer-2': 'A debt consolidation loan combines multiple debts into one new loan with a lower interest rate, simplifying payments and saving you money on interest. A debt management plan restructures your existing debt without new borrowing. While it simplifies your payments, it may not necessarily be the most cost effective solution to pursue.',
  'app.dcl-landing-page.faq.question-3': 'How does debt consolidation work at TrustPlus?',
  'app.dcl-landing-page.faq.answer-3': 'We know how stressful it can be to find the best financial product. That’s why we’re doing the leg work for you! We recommend our clients only debt consolidation loans from vetted and trusted credit unions. And we don’t make a dime from these recommendations. Our commitment is to you - the client.',
  'app.dcl-landing-page.faq.question-4': 'What happens after I take the pre-screener?',
  'app.dcl-landing-page.faq.answer-4': 'Based on your answers, you will be prompted to schedule a session with a TrustPlus financial coach to explore your options.',
  'app.dcl-landing-page.faq.question-5': 'How can I contact TrustPlus?',
  'app.dcl-landing-page.faq.answer-5.text-1': 'You can email us at ',
  'app.dcl-landing-page.faq.answer-5.email': 'support@mytrustplus.org',
  'app.dcl-landing-page.faq.answer-5.text-2': '. We look forward to serving you!',
  'app.dcl-landing-page.get-started.title': 'Get started today!',
  'app.dcl-landing-page.tagline.take-prescreener.button': 'Take our 3-question pre-screener',
  'app.dcl-landing-page.survey.question1-header': 'Question 1 of 3: ',
  'app.dcl-landing-page.survey.question1': 'What is your employment status?',
  'app.dcl-landing-page.survey.question2-header': 'Question 2 of 3:',
  'app.dcl-landing-page.survey.question2': 'What is your annual income?',
  'app.dcl-landing-page.survey.question3-header': 'Question 3 of 3:',
  'app.dcl-landing-page.survey.question3': 'Do you have a rough idea of your credit score?',
  'app.dcl-landing-page.youtube': 'https://www.youtube.com/embed/_KH38LUDfWMs',
  'app.dcl-landing-page.aria-label.hero': 'Happy Baltimore, Maryland residents who consolidated their credit card debt via TrustPlus vetted debt cosolidation loans',
  'app.dcl-landing-page.aria-label.dcl-survey-start': 'The debt consolidation loan pre-screener has three questions that will focus on employment, income, and credit score',
  'app.dcl-landing-page.aria-label.testimonial-1': 'TrustPlus client Yakaira feeling hopeful after her financial coach helped her reach her financial goals',
  'app.dcl-landing-page.aria-label.testimonial-2': 'TrustPlus client Edward looks relaxed as he explains how his financial coach helped lower his credit card debt and save money',
  'app.dcl-landing-page.aria-label.testimonial-3': 'TrustPlus client Annette details how her financial coach helped reduce her monthly debt payments, saving thousands in interest',
  'app.dcl-landing-page.aria-label.testimonial-4': 'TrustPlus client James confidently shares how his financial coach helped him reduce debt and make progress toward his goals',
  'app.dcl-landing-page.aria-label.who-we-wre-photo': 'Dedicated TrustPlus financial coach Elise sharing her expertise during a remote coaching session with a client',


  // DCL Prescreener
  'app.dcl-prescreener.employment-status.answer.full-time': "Employed full time",
  'app.dcl-prescreener.employment-status.answer.part-time': "Employed part time",
  'app.dcl-prescreener.employment-status.answer.self-employed': "Self employed",
  'app.dcl-prescreener.employment-status.answer.unemployed': "Unemployed",
  'app.dcl-prescreener.annual-income.answer.0-20': "$0 - $20,000",
  'app.dcl-prescreener.annual-income.answer.20-40': "$20,001 - $40,000",
  'app.dcl-prescreener.annual-income.answer.40-60': "$40,001 - $60,000",
  'app.dcl-prescreener.annual-income.answer.60+': "Over $60,000",
  'app.dcl-prescreener.credit-score.answer.yes-under-620': "Yes - it's 619 or Lower",
  'app.dcl-prescreener.credit-score.answer.yes-620-or-over': "Yes - it's 620 or Higher",
  'app.dcl-prescreener.credit-score.answer.no': "I don't know",
  'app.dcl-prescreener.placeholder': "Select from dropdown menu",

  // Footer links
  'app.footer-link-landing-page.follow': 'Follow Us',
  'app.footer-link-landing-page.contact': 'Contact Us',
  'app.footer-link-landing-page.join': 'Join Our List',
  'app.footer-link-landing-page.copyright': 'Copyright ©',
  'app.footer-link-landing-page.neighborhood-trust': ' Neighborhood Trust',
  'app.footer-link-landing-page.all-rights-reserved': 'All Rights Reserved',
  'app.footer-link-landing-page.tos': ' Terms of Service',
  'app.footer-link-landing-page.privacy-policy': 'Privacy Policy',
  'app.footer-link-landing-page.faq': 'FAQ',
  'app.footer-link-landing-page.pipe': ' | ',

  'app.spinner.loading': 'Loading',
  'app.shift-desktop-and-mobile-provider.more': 'more',
  'app.shift-desktop-and-mobile-provider.less': 'less',
  'app.shift-desktop-and-mobile-provider.view-bio': 'View full bio',
  'app.shift-table.dayname.Sunday': 'Sunday',
  'app.shift-table.dayname.Monday': 'Monday',
  'app.shift-table.dayname.Tuesday': 'Tuesday',
  'app.shift-table.dayname.Wednesday': 'Wednesday',
  'app.shift-table.dayname.Thursday': 'Thursday',
  'app.shift-table.dayname.Friday': 'Friday',
  'app.shift-table.dayname.Saturday': 'Saturday',
  'app.shift-table.dayname.Sun': 'Sun.',
  'app.shift-table.dayname.Mon': 'Mon.',
  'app.shift-table.dayname.Tue': 'Tues.',
  'app.shift-table.dayname.Wed': 'Wed.',
  'app.shift-table.dayname.Thu': 'Thurs.',
  'app.shift-table.dayname.Fri': 'Fri.',
  'app.shift-table.dayname.Sat': 'Sat.',
  'app.shift-table.monthname.January': 'January',
  'app.shift-table.monthname.February': 'February',
  'app.shift-table.monthname.March': 'March',
  'app.shift-table.monthname.April': 'April',
  'app.shift-table.monthname.May': 'May',
  'app.shift-table.monthname.June': 'June',
  'app.shift-table.monthname.July': 'July',
  'app.shift-table.monthname.August': 'August',
  'app.shift-table.monthname.September': 'September',
  'app.shift-table.monthname.October': 'October',
  'app.shift-table.monthname.November': 'November',
  'app.shift-table.monthname.December': 'December',
  'app.registration.introduce-yourself': 'Introduce yourself.',
  'app.registration.back-link-text': '\<\< back',
  'app.registration.session-time-heading': 'Session time: ',
  'app.registration.at': 'at',
  'app.registration.with': 'with',
  'app.registration.more-info-required': 'We need to know just a little bit about you to confirm your session: ',
  'app.registration.input.first-name': 'First name',
  'app.registration.input.last-name': 'Last name',
  'app.registration.input.phone-number': 'Phone number',
  'app.registration.input.email': 'Email',
  'app.registration.appointment-format-heading': 'I would like to have my first session',
  'app.registration.gender.': 'Please select from the following',
  'app.registration.gender.optional': '(optional)',
  'app.registration.gender.what-describes-you': 'What most closely describes your gender?',

  'app.registration.gender.answer.Female': 'Woman',
  'app.registration.gender.answer.Male': 'Man',
  'app.registration.gender.answer.Non-Binary': 'Non-binary',
  'app.registration.gender.answer.Agender': 'Agender  / I don\'t identify with a gender',
  'app.registration.gender.answer.Transgender Man': 'Transgender Man',
  'app.registration.gender.answer.Transgender Woman': 'Transgender Woman',
  'app.registration.gender.answer.Prefer Not to State': 'Prefer not to state',
  'app.registration.gender.answer.Other': 'Let me type it',

  'app.registration.gender-pronouns.question': 'To help understand how to best address you, what pronouns would you like us to use?',
  'app.registration.gender-pronouns.answer.She/her': 'She/her',
  'app.registration.gender-pronouns.answer.He/him': 'He/him',
  'app.registration.gender-pronouns.answer.They/Them': 'They/Them',
  'app.registration.gender-pronouns.answer.Prefer not to state': 'Prefer not to state',
  'app.registration.gender-pronouns.answer.Other': 'Let me type it',

  'app.registration.phone-appointment-radio.button': 'Phone',
  'app.registration.skype-appointment-radio.button': 'Skype',
  'app.registration.video-chat-appointment-radio.button': 'Video Chat',
  'app.registration.video-chat': 'Video chat service',
  'app.registration.video-chat.answer.Please select from the following':'Please select from the following',
  'app.registration.video-chat.answer.Google Meet': 'Google Meet',
  'app.registration.video-chat.answer.Zoom': 'Zoom',
  'app.registration.tty-appointment-radio.button': 'TRS for the deaf and hard-of-hearing',
  'app.registration.agreement.description': 'I agree to the {termsLink} and {privacyLink}.',
  'app.registration.agreement.terms-link': 'terms of service',
  'app.registration.agreement.privacy-link': 'privacy policy',
  'app.registration.submit.button': 'Confirm',
  'app.registration.tos.accion': 'By agreeing to our Terms of Service and Privacy Policy, you also agree that TrustPlus may share your name and participation status with Accion for program evaluation purposes. All of your financial information including anything discussed during a coaching session will remain confidential.',
  'app.registration.tos.unitedway': 'By agreeing to our Terms of Service and Privacy Policy, you also agree that TrustPlus may share your name and participation status with Connecticut United Way\'s Alice Saves for program evaluation purposes. All of your financial information including anything discussed during a coaching session will remain confidential.',
  'app.registration.tos.start-small-think-big': 'By agreeing to our Terms of Service and Privacy Policy, you also agree that TrustPlus may share your name and participation status with Start Small Think Big for program evaluation purposes. All of your financial information including anything discussed during a coaching session will remain confidential.',
  'app.registration.tos.feeding-america': 'By agreeing to our Terms of Service and Privacy Policy, you also agree that TrustPlus may share your name and participation status with Feeding America for program evaluation purposes. All of your financial information including anything discussed during a coaching session will remain confidential.',
  'app.create-appointment.input.first-name': 'First name',
  'app.create-appointment.input.last-name': 'Last name',
  'app.create-appointment.input.email': 'Email',
  'app.create-appointment.input.phone-number' : 'Cell phone number',
  'app.create-appointment.submit.button': 'Continue',
  'app.registration.share-name-terms.consent-given': 'By agreeing to our Terms of Service and Privacy Policy, you also agree that TrustPlus may share your name and participation status with {orgName} for program evaluation purposes. All of your financial information including anything discussed during a coaching session will remain confidential.',
  'app.registration.tos.agree': 'Agree',
  'app.registration.tos.disagree': 'Disagree',

  //Sign up Beta Test flow
  'app.beta-test.sign-up-email.text.1': 'Sign up with your email address',
  'app.beta-test.sign-up-email.text.2': 'To get started on the journey to financial freedom.',
  'app.beta-test.sign-up-password.text.1': 'Passwords must contain at least 8 characters, upper and lowercase letters, one number and no special characters.',
  'app.beta-test.sign-up-password.text.2': 'Enter password twice. Passwords must match.',

  'app.terms.title': 'Terms of Service',
  'app.terms.terms.title.revision.date': 'Last Revised: 10/2020',
  'app.terms.terms.intro.1': 'Thank you for using the services of Neighborhood Trust Financial Partners, LLC and its subsidiaries, partners and affiliates (which may include designations such as “we”, “us”, or “Neighborhood Trust” within this document and the application, and on our website and related marketing materials). Our services (the “Services”) are provided expressly for the purposes of financial capability building on behalf of employees of our partners, and other clients enrolled with us directly.',
  'app.terms.terms.intro.2': 'The terms contained in this document (the “Terms of Service”), as amended from time to time, apply to all individuals who use Neighborhood Trust’s website or web applications, set up an account with Neighborhood Trust, or otherwise utilize Neighborhood Trust’s Services (each a “User”) and govern each User’s access to and use of this website, at {neighborhoodTrustLink}, and any of our other websites that directly link to these Terms of Service, as well as any of our content, tools, functions, products, or services that are accessible from such websites (collectively, the “Site”).',
  'app.terms.terms.intro.3a': 'Please read these Terms of Service carefully upon accessing or using the Website. ',
  'app.terms.terms.intro.3b': 'By using the Site, or by clicking to accept or agree to these Terms when this option is made available to you,  you accept and agree to be bound by these Terms, and these Terms form a binding contract entered into between you and Neighborhood Trust.',
  'app.terms.terms.intro.3c': 'If you, as an individual, are acting for or on behalf of a company, organization, or other entity, such as your employer, then by accessing or using the Website: (i) you represent that you are fully authorized to access or use the Website; (ii) you agree to be bound by these Terms of Service on behalf of yourself and such entity; and (iii) “you” shall refer collectively to you and such entity.',
  'app.terms.terms.intro.4': 'If you do not accept or agree to these Terms of Service, then you are not authorized to, and must not, access or use the Services or Website.',
  'app.terms.terms.intro.5': 'You may not use any of the Services and you may not accept these Terms of Service if you are not legally authorized to accept and be bound by these terms or are not at least 18 years of age and, in any event, of a legal age to form a binding contract with Neighborhood Trust.',
  'app.terms.terms.intro.6': 'Before you continue, you should print or save a local copy of these Terms of Service for your records.',
  'app.terms.terms.intro.7' : 'The sole purpose of our Services is to assist Users in building financial capability, including but not limited to cash flow management, financial product selection, budget and expense analysis, and general financial advice. These services are only available to residents of the United States.',

  'app.terms.terms.section.1.header': 'Your right to opt-out',
  'app.terms.terms.section.1.text.1': 'Participation in these Services is voluntary, and you can revoke any of the permissions within this document at any time, including before your participation in these Services begins, by notifying Neighborhood Trust in writing. Please refer to the end of this document for information on how to contact us.',
  'app.terms.terms.section.1b.header': 'Description of the services',
  'app.terms.terms.section.1b.text.1': 'The Services consist of general personal finance counseling and financial information management services that allows you to track your financial information, while receiving support and advice on credit, debt, budgeting, and saving. The Neighborhood Trust Services are meant to provide you with your information to allow you to manage your finances at no cost to you. ',
  'app.terms.terms.section.1b.text.2': 'These Services may be available to you via your employer as a voluntary employee benefit, or through a third-party referral partner. Neighborhood Trust may receive compensation from employers and referral partners in order to provide our confidential financial counseling services. Your employer/plan sponsor determines your eligibility for each benefit. If you are enrolled in the Services under an employer or third party sponsored program, the provisions of these Terms of Service as applied to you may vary in one or more ways from those set forth above, but only if you are notified in writing.',
  'app.terms.terms.section.1b.text.3': 'The Neighborhood Trust Services may also include a service that provides your Transunion credit summary and FICO credit score.',
  'app.terms.terms.section.1b.text.4': 'The Site provides various tools and functions through the website and web application including a personal information management service that allows you to consolidate and track certain personal information.',
  'app.terms.terms.section.1b.text.5': 'The Services may also present you information relating to third party products or services that you may be interested in. The Services may also provide you general tips, action items and educational material, including with respect to designing a personal financial plan tailored to your specific needs and situation. Unless expressly noted otherwise, Neighborhood Trust receives no compensation for providing links to and explaining the benefits of any specific financial product or service provided by a third party. ',

  'app.terms.terms.section.2.header': 'Credit report authorization',
  'app.terms.terms.section.2.text.1': 'When you agree to the Terms of Service and click “Get My Credit Report”, you give Neighborhood Trust permission to obtain your credit report from any of the three credit reporting agencies for the duration of your use of our Sites or Services and for up to twenty six (26) months thereafter, for program evaluation purposes. Your credit score will not be affected.',

  'app.terms.terms.section.3.header': 'Permitted Uses',
  'app.terms.terms.section.3.subheader.1': 'Limited license: ',
  'app.terms.terms.section.3.text.1': 'Neighborhood Trust is providing you with a revocable usage license to use the Site and Services, and all terms, copyrights, trademarks, service marks, brand names, trade names, logos, designs, graphics, illustrations, images, videos, photographs, and all intellectual property of any kind whatsoever utilized therein, solely for the purpose of receiving and using the Services and other approved uses of the Site. ',
  'app.terms.terms.section.3.subheader.2': 'Approved uses: ',
  'app.terms.terms.section.3.text.2': 'Our Services are provided to offer actionable financial guidance. The Site may only be used by you in furtherance of the Services. The Site may only be used by approved and invited Users at Neighborhood Trust’s discretion. You may not register for more than one account, or register for an account on behalf of an individual other than yourself or on behalf of any group or entity. Your right to access and use the Site and the Services is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use the Site and Services for lawful purposes. Accurate records enable Neighborhood Trust to provide the Services to you. You must provide true, accurate, current and complete information about your accounts maintained at other websites. You may not misrepresent your Content and Account Information (each as defined below) provided to Neighborhood Trust. In order for the Services to function effectively, you must also keep such Content and Account Information up to date and accurate. If you do not do this, the accuracy and effectiveness of the Services will be affected. You represent that you are a legal owner of, and that you are authorized to provide us with, all registration information, Account Information and other information necessary to facilitate your use of the Services.',
  'app.terms.terms.section.3.subheader.3': 'Decency: ',
  'app.terms.terms.section.3.text.3': 'You will not use profane, derogatory or disrespectful language in your submissions to, and correspondence within, the Site. Neighborhood Trust may suspend or ban any User account that, in its sole determination, does not comply with these Terms of Service.',
  'app.terms.terms.section.3.subheader.4': 'Legality: ',
  'app.terms.terms.section.3.text.4': 'You will not use the Services or Site in a way that violates any state, federal or international laws or treaties.',
  'app.terms.terms.section.3.subheader.5': 'Commercial use: ',
  'app.terms.terms.section.3.text.5': 'Users will not use our services nor any of its parts or features to generate commercial revenue without the express written consent of Neighborhood Trust.',
  'app.terms.terms.section.3.subheader.6': 'Automated use: ',
  'app.terms.terms.section.3.text.6': 'You agree that you will not:',
  'app.terms.terms.section.3.text.6a': 'Use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor Neighborhood Trust, the Site or Services, or any portion thereof, without Neighborhood Trust’s express prior written consent, which may be withheld in Neighborhood Trust’s sole discretion;',
  'app.terms.terms.section.3.text.6b': 'Use or attempt to use any engine, software, tools, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search Neighborhood Trust, other than the search engines and search agents available through the Services and other than generally available third-party web browsers (such as Microsoft Internet Explorer and Chrome);',
  'app.terms.terms.section.3.text.6c': 'Post or transmit any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features, or that otherwise interfere with the proper working of Neighborhood Trust’s Sites or the Services; or',
  'app.terms.terms.section.3.text.6d': 'Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of Neighborhood Trust or the Services.',
  'app.terms.terms.section.3.subheader.7': 'Secure use: ',
  'app.terms.terms.section.3.text.7': 'You are responsible for maintaining the privacy and security of your passwords and other security information used in connection with the Services or Site. You are also responsible for maintaining the integrity of your hardware and operating system to prevent viruses, spyware or other malicious software from misappropriating your password information or data. You should exercise caution when accessing the Services or Site from public or shared computers so that others are not able to access your personal information. Neighborhood Trust bears no responsibility for unauthorized access to your Neighborhood Trust account as a result of your failure to follow appropriate security precautions. Users will not share, post, or otherwise leak their security credentials. If you should become aware of any unauthorized use of your account or user information, or if you otherwise believe that your account has been compromised or there has been a breach in security, you agree to immediately notify Neighborhood Trust by e-mail to: {mailToSupportAction} .',
  'app.terms.terms.section.3.subheader.8': 'Age restrictions: ',
  'app.terms.terms.section.3.text.8': 'In order to use Neighborhood Trust’s services you must be 18 years of age or older.',
  'app.terms.terms.section.3.subheader.9': 'Residency: ',
  'app.terms.terms.section.3.text.9' : 'You must be a resident of the United States to use our services.',

  'app.terms.terms.section.4.header': 'Communications',
  'app.terms.terms.section.4.subheader.1': 'Notices, alerts, and messages: ',
  'app.terms.terms.section.4.text.1': 'You will receive voluntary notifications, automatic alerts, and other communications from us related to the business purposes of the Site and Services. Automatic alerts may be sent to you following certain changes to your account or information, such as a change in your Content (defined below) or account information provided to Neighborhood Trust. These notices may be provided by email, SMS, MMS, telephone calls, or by other means.',
  'app.terms.terms.section.4.text.1a': 'Voluntary notifications may be turned on by default as part of the Services. Such notifications can be deactivated by you in communications with your Neighborhood Trust counselor. Neighborhood Trust may add new notifications from time to time, or cease to provide certain notifications at any time upon its sole discretion.',
  'app.terms.terms.section.4.text.1b': 'You understand and agree that any alerts or notifications provided to you through the Services or Site may be delayed or prevented by a variety of factors. Neighborhood Trust may make commercially reasonable efforts to provide notifications or alerts in a timely manner with accurate information, but cannot guarantee the delivery, timeliness, or accuracy of the content of any alert. Neighborhood Trust shall not be liable for any delays, failure to deliver, or misdirected delivery of any alert, for any errors in the content of an alert, or for any actions taken or not taken by you or any third party in reliance on an alert.',
  'app.terms.terms.section.4.text.1c': 'Electronic notifications will be sent to the email address or mobile number you have provided for the Services. If your email address or your mobile number changes, you are responsible for informing us of that change. Alerts may also be sent to a mobile device that accepts text messages. Changes to your email address and mobile number will apply to all of your alerts.',
  'app.terms.terms.section.4.text.1d': 'Notifications are not encrypted. They may include your Login ID and some information about your accounts. If you request a password reset, we may email a temporary password to you. Such requests do not constitute an alert. Anyone with access to the email account you provide to us will be able to view the content of these notifications. You may disable notifications at any time by emailing {mailToSupportAction} .',
  'app.terms.terms.section.4.subheader.2': 'Zoom: ',
  'app.terms.terms.section.4.text.2': 'Portions of the Services may be provided over Zoom, and any personal information communicated over Zoom will be subject to Zoom’s privacy policy and information security controls. Zoom’s policies can be found at {zoomUrl} .',
  'app.terms.terms.section.4.subheader.2a': 'Google: ',
  'app.terms.terms.section.4.text.2a': 'Portions of the Services may be provided over Google Meet, and any personal information communicated over Google Meet will be subject to Google’s privacy policy and information security controls. Google’s policies can be found at {googleUrl} .',
  'app.terms.terms.section.4.subheader.2b' : 'Feedback: ',
  'app.terms.terms.section.4.text.2b': 'Any feedback, questions, comments, suggestions, ideas, or the like that you send to Neighborhood Trust (collectively, “Feedback”) will be treated as being non-confidential and nonproprietary with respect to you, and Neighborhood Trust will be free to use such information for any purpose whatsoever, including developing and marketing products and services incorporating the information, without payment of royalties or other consideration to you.',
  'app.terms.terms.section.4a.header': 'Your content',
  'app.terms.terms.section.4.text.3': 'When you use the Services or Site, you will create a personal profile and manually enter information, including, without limitation, your financial information (budget, expenses, income, etc.). You warrant and represent that you own or otherwise control the rights to all of this information provided to Neighborhood Trust through your use of the Services or Site (collectively, “Content”). You are responsible for any Content that may be lost or unrecoverable through your use of the Services. You agree that you will not use the Services or Site to share, store, or in any way distribute Content that is not in accordance with the law. Neighborhood Trust is not responsible for the Content or data you submit on the website.',
  'app.terms.terms.section.4.text.3b': 'Neighborhood Trust cannot always foresee or anticipate technical or other difficulties which may result in failure to obtain data or loss of data, personalization settings or other service interruptions. Neighborhood Trust cannot assume responsibility for the timeliness, accuracy, deletion, non-delivery or failure to store any user data, communications or personalization settings. For example, when displayed through the Neighborhood Trust’s Site or Services, Account Information is only as fresh as the time shown, which reflects when the information is obtained from such sites. Such information may be more up-to-date when obtained directly from the relevant banks or credit unions’ sites.',
  'app.terms.terms.section.4b.header': 'Intellectual Property',
  'app.terms.terms.section.4b.text.1': 'The Site, Services and all of their contents (including, but not limited to, all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Neighborhood Trust, its licensors, or other providers of such material and may be protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.',
  'app.terms.terms.section.4b.text.2': 'No right, title, or interest in or to the Site, Services or any contents or materials on the Site or provided through the Services is granted or transferred to you except as expressly set forth herein, and all rights not expressly granted herein are reserved by Neighborhood Trust. Any use of the Site or Services not expressly permitted by these Terms of Service constitutes a breach of these Terms of Service and may violate copyright, trademark, and other laws.',
  'app.terms.terms.section.4b.text.3': 'These Terms of Service permit you to use the Site and Services for private use or use for internal business purposes only. Except as expressly provided herein, you shall not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit the Site or Services, or any of the contents thereof, except as follows:',
  'app.terms.terms.section.4b.3.bulletpoints.a': 'Your computer may temporarily store copies of such contents of the Site in RAM incidental to your accessing and viewing those contents.',
  'app.terms.terms.section.4b.3.bulletpoints.b': 'You may store files that are automatically cached by your browser for display enhancement purposes.',
  'app.terms.terms.section.4b.3.bulletpoints.c': 'You may print or download a reasonable number of pages of the Site or a reasonable amount of materials from the Site, in either case solely for private use or use for internal business purposes and not for further reproduction, publication, or distribution.',
  'app.terms.terms.section.4b.text.4': 'You must not: ',
  'app.terms.terms.section.4b.4.bulletpoints.a': 'Modify copies of any materials from the Site.',
  'app.terms.terms.section.4b.4.bulletpoints.b': 'Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Site.',
  'app.terms.terms.section.4b.text.5': 'If you print, copy, modify, download, publicly display or distribute, or otherwise use any contents or materials from the Site or Services in breach of these Terms, we may immediately terminate your right to use the Site and Services, and you must, at our option, return or destroy any and all copies of contents or materials from the Site or Services in your possession.',
  'app.terms.terms.section.4b.subheader.1': 'Trademarks.',
  'app.terms.terms.section.4b.text.6': ' The name Neighborhood Trust, the Neighborhood Trust logos, and all related names, logos, product and service names, designs, and slogans are trademarks of Neighborhood Trust or its affiliates or licensors.',
  'app.terms.terms.section.4b.text.7': 'You shall not use such marks without our prior written permission. All other names, logos, product and service names, designs, and slogans on this Site or Services are the trademarks of their respective owners.',

  'app.terms.terms.section.4c.header': 'Monitoring and Enforcement',
  'app.terms.terms.section.4c.text.1': 'We have the right to: ',
  'app.terms.terms.section.4c.1.bulletpoints.a': 'Take any action with respect to any user conduct that we deem necessary or appropriate in our sole discretion, including if we believe that such user conduct violates these Terms of Service, including those prohibited uses described herein, or that such user conduct infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Site or the public, or could create liability for Neighborhood Trust.',
  'app.terms.terms.section.4c.1.bulletpoints.b': 'Disclose your identity or other information about you to any third party who claims that your use of the Services or Site violates their rights, including their intellectual property rights or their right to privacy.',
  'app.terms.terms.section.4c.1.bulletpoints.c': 'Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Site or Services.',
  'app.terms.terms.section.4c.1.bulletpoints.d': 'Terminate or suspend your access to all or part of the Site or Services for any or no reason, including, without limitation, any violation of these Terms of Service.',
  'app.terms.terms.section.4c.text.2': 'Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of any Users of the Site or Services, or other information of anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS NEIGHBORHOOD TRUST AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY NEIGHBORHOOD TRUST DURING, OR AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER NEIGHBORHOOD TRUST OR LAW ENFORCEMENT AUTHORITIES.',
  'app.terms.terms.section.4c.text.3': 'However, we do not undertake to review all material before it is posted on the Site, and cannot ensure prompt removal of objectionable material from the Site. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We shall have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.',

  'app.terms.terms.section.4d.header': 'Confidentiality',
  'app.terms.terms.section.4d.text.1': 'Neighborhood Trust and their counselors will not at any time or in any manner, either directly or indirectly, use for the personal benefit of Neighborhood Trust, or divulge, disclose, or communicate in any manner information that is personal to the User. Except as otherwise set forth herein or in the Privacy Policy, Neighborhood Trust will protect your information and treat it as strictly confidential. This provision shall continue to be effective after the termination of your engagement with the Services for as long as required by applicable law. Your financial information may be aggregated with others and shared with third parties for research, evaluation, marketing, and other reasonable purposes. This aggregate information does not identify particular Users or otherwise allow anyone to recover sensitive information about individual Users. For more information regarding the confidentiality of information provided by you, please refer to our Privacy Policy, available at {privacyPolicyUrl}.',
  'app.terms.terms.section.4d.text.2': 'this link',
  'app.terms.terms.section.4e.header': 'Privacy and Information Practices',
  'app.terms.terms.section.4e.text.1':'We value individual privacy, and we are committed to protecting individuals’ information. All information collected through the Site and Services is subject to our Privacy Policy, available at {privacyPolicyUrl}. By accessing or using the Site or Services, or otherwise agreeing to these Terms of Service, you acknowledge that you have read and understand the terms of the Privacy Policy and consent to the treatment of your information in accordance with the Privacy Policy.',
  'app.terms.terms.section.4f.header': 'Third Party Coordination',
  'app.terms.terms.section.4f.text.1': 'At times, we may provide you with links to third party sites, products, services, features, online communities, or applications. Users are responsible for individual terms of use with third parties they contact through or connect to using our Services. If you decide to use any of these other services, additional terms and conditions and separate fees may apply. If you decide to use any third party products or access any third party websites, you are responsible for reviewing the third party’s separate product terms, website terms and privacy policies. Neighborhood Trust does not endorse, warrant or guarantee the products or services available through any such offers, and Neighborhood Trust is not an agent or broker or otherwise responsible for the activities or policies of those web sites. Neighborhood Trust does not guarantee that the third-party offer information displayed through the Services are actually the terms that may be offered to you if you pursue the offer or that they are the best terms, lowest rates available in the market, or otherwise suitable for you. You must complete your own review and due diligence of any third-party offers, products or services, and Neighborhood Trust disclaims any express or implied obligation to do so.',

  'app.terms.terms.section.5.header': 'Disclaimer of Warranties',
  'app.terms.terms.section.5.text.1': 'TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SITE AND ALL INFORMATION PRESENTED VIA THE SITE OR SERVICES ARE PROVIDED “AS IS” AND “WITH ALL FAULTS,” AND NEIGHBORHOOD TRUST HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY NATURE, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, REGARDING THE SITE AND SERVICES AND ALL INFORMATION PRESENTED VIA THE SITE OR SERVICES, AND NEIGHBORHOOD TRUST SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, NEIGHBORHOOD TRUST MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND AS TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION PRESENTED VIA THE SITE OR SERVICES; AVAILABILITY, SECURITY OR PERFORMANCE OF THE SITE OR SERVICES; EXISTENCE OR ABSENCE OF ANY DEFECTS IN THE SITE OR SERVICES; OR COMPATIBILITY OF THE SITE WITH ANY EQUIPMENT OR SOFTWARE.',

  'app.terms.terms.section.6.header': 'Limitation of Liability',
  'app.terms.terms.section.6.text.1': 'TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL NEIGHBORHOOD TRUST OR ANY OF ITS REPRESENTATIVES BE LIABLE TO YOU OR TO ANY THIRD PARTY UNDER OR IN CONNECTION WITH THESE TERMS OF SERVICE OR THEIR SUBJECT MATTER, UNDER ANY LEGAL THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, FOR ANY (A) CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES; (B) YOUR USE OF THE SITE OR SERVICES, OR ANY IMPAIRMENT, DELAY OR OTHER INTERRUPTION IN THE SITE, THE SERVICES OR YOUR ABILITY TO USE THE SAME; (C) LOSS OF BUSINESS, REVENUE OR PROFIT, DIMINUTION IN VALUE OR OTHER ECONOMIC LOSS; (D) HARM TO REPUTATION OR BUSINESS GOODWILL; OR (E) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY; (F) CONTENT, ACCURACY OR COMPLETENESS, AND ANY ERRORS IN, OR OMISSIONS FROM, THE MATERIALS INCLUDED IN OR AVAILABLE THROUGH THE SITE OR SERVICES; OR (G) CONTENT RETRIEVED FROM THE INTERNET EVEN IF RETRIEVED OR LINKED TO, FROM, OR WITHIN THE SITE OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER NEIGHBORHOOD TRUST OR ANY ITS REPRESENTATIVES WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.',
  'app.terms.terms.section.6.text.2': 'IN NO EVENT SHALL THE TOTAL AND AGGREGATE LIABILITY OF NEIGHBORHOOD TRUST TO YOU UNDER OR IN CONNECTION WITH THESE TERMS OF SERVICE OR THEIR SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED FIFTY U.S. DOLLARS ($50). ALL CLAIMS AGAINST NEIGHBORHOOD TRUST WITH RESPECT TO ANY LIABILITY RELATED TO THESE TERMS OF SERVICE SHALL BE AGGREGATED TO DETERMINE SATISFACTION OF SUCH LIMIT, AND THE EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT ENLARGE OR EXTEND THE FOREGOING LIMITATION. THE FOREGOING LIMITATION APPLIES NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.',
  'app.terms.terms.section.6.subheader.1': 'Indemnification',
  'app.terms.terms.section.6.text.3': 'You shall indemnify, defend and hold harmless Neighborhood Trust and its affiliates, and each of its and their respective officers, directors, employees, agents, successors and assigns (each, an “Indemnitee”) from and against any and all losses incurred by such Indemnitee in connection with any claim or action by a third party arising out of or relating to any: (i) content that you upload, post, email, transmit or otherwise make available via the Site; (ii) your breach of any of your representations, warranties, covenants or obligations under these Terms of Service; or (iii) negligence or any more culpable act or omission (including recklessness or willful misconduct) by you in connection with these Terms of Service or their subject matter.',
  'app.terms.terms.section.6.subheader.2': 'No Legal, Tax, or Investment Advice: ',
  'app.terms.terms.section.6.text.4': 'Neighborhood Trust counselors are not investment advisors, brokers, tax advisors, or Certified Financial Planners™. The Services and Site are only intended to provide you with support and assist in your financial decision-making and is broad in scope. You understand that the Services, Site and any other materials presented by Neighborhood Trust to you in connection with the Services or Site does not constitute any legal, tax, business or investment advice of any kind. No advice given by Neighborhood Trust to you as part of the Services or Site shall constitute an offer or solicitation in any jurisdiction to purchase or sell any securities of any kind. Nothing in these Terms of Service shall obligate or permit Neighborhood Trust to provide investment advice or investment recommendations to any User. We strongly encourage Users to consider obtaining additional information from their accountant or consult such legal, tax and investment advisors who are fully aware of their individual circumstances. You also understand that Neighborhood Trust is not a “credit repair organization” nor do we sell services that claim to improve or fix a User’s credit record, credit history, or credit rating for a fee. You agree that the use of the Sites and Service is at your sole risk.',
  'app.terms.terms.section.6.subheader.3': 'Change and termination: ',
  'app.terms.terms.section.6.text.5': 'Neighborhood Trust may modify or update these Terms of Service as needed and desired. Any and all changes to these Terms of Service may be provided to you by electronic means (i.e. via email or by posting the information on the Sites). The Terms of Service will always indicate the date it was last revised. Your usage after any update constitutes continued agreement.',
  'app.terms.terms.section.6.text.6': 'Neighborhood Trust reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Services or Site (or any part thereof) with or without notice. We can also terminate the agreement with you in the event you violate these Terms of Service in our judgement. ',
  'app.terms.terms.section.6.subheader.4': 'Personal mobile phone usage/data plan: ',
  'app.terms.terms.section.6.text.7': 'Use of the Services or Site may be available through a compatible mobile device, Internet and/or network access and may require software. Any mobile phone usage/data used to engage with the program will not be reimbursed by your employer or Neighborhood Trust. You agree that you are solely responsible for these requirements, including any applicable changes, updates and fees as well as the terms of your agreement with your mobile device and telecommunications provider.',
  'app.terms.terms.section.6.subheader.5': 'Governing law: ',
  'app.terms.terms.section.6.text.8': 'You agree that all terms and provisions, hereof, shall be governed, construed, and enforced in accordance with the laws of the state of New York.',

  'app.terms.privacy.section.6.header': 'How to contact us: ',
  'app.terms.privacy.section.6.text.1a': 'If you have questions, concerns or feedback regarding these Terms of Service, you can send an email to us at {mailToSupportAction} or by postal mail at:',
  'app.terms.privacy.section.6.text.1': 'Neighborhood Trust Financial Partners',
  'app.terms.privacy.section.6.text.2': '530 West 166th St, 4th floor',
  'app.terms.privacy.section.6.text.3': 'New York, NY 10032',
  'app.terms.privacy.section.6.text.4': 'The Neighborhood Trust Terms of Service & Privacy Policy can also be found at {externalTermsOfService}',
  'app.terms.agree': 'I agree',
  'app.terms.go-back': 'Go back',
  'app.terms.tos-pp-intro-text': 'Before proceeding, please review our Terms of Service and Privacy Policy.',

  'app.privacy-policy.last-revised': 'Last Revised: February 2020',
  'app.privacy-policy.overview.sub-header': '1. Overview',
  'app.privacy-policy.overview.intro.1': 'This Privacy Policy explains how Neighborhood Trust Financial Partners, LLC and its subsidiaries, partners and affiliates ("we", "us", or "Neighborhood Trust") collects, protects, uses and shares information about you when you use {ntfpSite} ("Website") and any of our services (collectively with the Website, the "Services"), and whether you access the Services directly or through a third party, which third parties may include your employer. This Privacy Policy is incorporated by reference in, and forms a part of, our Terms of Service, available at {tosLink} (the "Terms of Service"). In the event of any conflict between the provisions of this Privacy Policy and the Terms of Service, the applicable provisions of this Privacy Policy shall control.',
  'app.privacy-policy.overview.intro.2': 'Neighborhood Trust takes your privacy very seriously. Please read this notice carefully to understand what we do. ',
  'app.privacy-policy.overview.intro.3': 'If you do not want information about you to be used as described in this Privacy Policy, or if you disagree with any portion of this Privacy Policy or our information practices, you must stop, or refrain from, using the Services.',
  'app.privacy-policy.overview.intro.4': 'If you have any questions or concerns about our information practices, please contact us at {mailToSupportAction} or as otherwise set forth in the "How to Contact Us" section below.',
  'app.privacy-policy.overview.scope.sub-title': 'Scope',
  'app.privacy-policy.scope.1': 'This Privacy Policy applies to information that we collect through the Website, and any of our other websites that provide a link to this Privacy Policy, as well as information collected through any of our products and services that are accessible via the Website, from you as an individual user ("you") of the Website.',
  'app.privacy-policy.scope.2': 'Please understand that, except as specifically described below, this Privacy Policy ',
  'app.privacy-policy.scope.3': 'does not ',
  'app.privacy-policy.scope.4': 'apply to information collected by third parties, such as other companies, or third-party websites or mobile applications, even if any are linked to or accessible through the Services. Further, any Services received in person may be subject to additional privacy terms and conditions, which will be provided to you in connection with such in-person Services. To the extent any provisions of this Privacy Policy conflict with any such privacy terms provided in connection with in-person Services, the provisions of such privacy terms shall control.',
  'app.privacy-policy.information-we-collect.sub-header': '2. Information We Collect',
  'app.privacy-policy.information-we-collect.information-you-provide.subtitle': 'Information You Provide',
  'app.privacy-policy.information-we-collect.1': 'Throughout the course of using the Services, Neighborhood Trust may collect and store information you enter on our Website or information provided to us during a counseling session or in some other manner in connection with our provision of the Services. This includes Personally Identifiable Information ("PII"), including but not limited to: ',
  'app.privacy-policy.pii-bulletpoints.1': 'Social security numbers',
  'app.privacy-policy.pii-bulletpoints.2': 'Address and contact information',
  'app.privacy-policy.pii-bulletpoints.3': 'Date of birth',
  'app.privacy-policy.pii-bulletpoints.4': 'Demographic information',
  'app.privacy-policy.pii-bulletpoints.5' : 'Employer',
  'app.privacy-policy.pii-bulletpoints.6' : 'Income and expenses',
  'app.privacy-policy.pii-bulletpoints.7' : 'Bank account and balance information',
  'app.privacy-policy.pii-bulletpoints.8' : 'Information from the credit reporting agencies',
  'app.privacy-policy.information-we-collect.passive-data-collection.subtitle' : 'Passive Data Collection',
  'app.privacy-policy.passive-data-collection.1': 'As a general matter, we automatically collect certain information based on your use of and behavior on the Website and in connection with the Services. We use this information to analyze our customers\' demographics, interests and behavior to better understand and serve you and our customer base. We also analyze this information for security purposes, to protect the Services and customer information that we hold.',
  'app.privacy-policy.passive-data-collection.2': 'If the information we collect automatically identifies you or could be reasonably used to identify you, then it is treated as PII. Also, if we directly combine any information gathered through passive means with PII, we treat the combined information as PII under this Privacy Policy. But in many cases, the information that we collect passively is only used in de-identified or aggregated forms, which do not identify any individual.',
  'app.privacy-policy.passive-data-collection.3': 'We collect passive data in the following ways:',
  'app.privacy-policy.passive-data-collection.4': 'Mobile Device Information. When you download or use our Website from a mobile device, we receive information about your mobile device. We register your device, and we may use the identifier to send you push notifications about your transactions via the Services.',
  'app.privacy-policy.passive-data-collection.5': 'Log Files. Like most websites, we automatically receive and record certain information about our customers\' behavior on our Website and when using our Services. Our servers automatically gather technical information about our Website traffic and store it in log files. This information includes Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and other clickstream data. We aggregate this information with other pieces of information to analyze trends, to administer and improve the Services, for example to understand customers\' use of the Website and to make inventory and purchasing decisions that will best serve our customer base. Information from log files may also be used to determine general location information such as city, state or geographic area.',
  'app.privacy-policy.passive-data-collection.6': 'Hotjar. In accordance with the terms of this Privacy Policy, we use a technology service called Hotjar in order to better understand our users\' needs and to optimize this service and experience. It helps us better understand our users\' experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don\'t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users\' behavior and their devices (including, but not limited to, the device\'s IP address (captured and stored only in anonymized form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and preferred language used to display our website). Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match the information with other data with respect to an individual user. For further details, please see Hotjar\'s privacy policy by clicking on this {hotjarExternalLink}.',
  'app.privacy-policy.passive-data-collection.7': 'link',
  'app.privacy-policy.passive-data-collection.8': 'Cookies, Web Beacons and Similar Technologies. A cookie is a small text file that is stored on a computer for record-keeping or tracking purposes. Web beacons (also known as pixel tags or clear GIFs), are graphics that can record whether a person has accessed particular content. We use these and other similar technologies on the Website and in connection with our Services. Cookies we utilize may include session cookies, persistent cookies, or similar technologies. A session cookie expires when you close your browser. A persistent cookie is stored on your computer or device for an extended period of time. You can remove persistent cookies by following the directions provided in your Internet browser\'s "help" menu. You may also configure your browser to prevent cookies from being set on your computer. (Instructions should be provided in documentation for your browser.) If you reject cookies, your ability to use the Website and Services may be limited, and you may not be able to use certain features of the Website or Services. We may use cookies for the following purposes: ',
  'app.privacy-policy.passive-data-collection.cookies.1': 'Essential cookies. ',
  'app.privacy-policy.passive-data-collection.cookies.2': 'Essential cookies are required for providing you with features or services that you have requested. For example, certain cookies enable you to log into secure areas of our Services. Disabling these cookies may make certain features and services unavailable.',
  'app.privacy-policy.passive-data-collection.cookies.3': 'Functional cookies. ',
  'app.privacy-policy.passive-data-collection.cookies.4': 'Functional cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Service. These cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).',
  'app.privacy-policy.passive-data-collection.cookies.5': 'Performance/analytical cookies. ',
  'app.privacy-policy.passive-data-collection.cookies.6': 'Performance/analytical cookies allow us to understand how visitors use our Service such as by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/analytical cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services\' content for those who engage with our advertising.',
  'app.privacy-policy.passive-data-collection.9': 'To find our more information about cookies, including information about how to manage and delete cookies, please visit {allAboutCookiesLink} .',
  'app.privacy-policy.all-about-cookies-link' : 'www.allaboutcookies.org',
  'app.privacy-policy.passive-data-collection.10': 'We may use web beacons or similar technologies, which help manage content on the Website and in the Services by tracking what content is effective. These technologies may be used to track the online movements of customers, but in contrast to cookies, which are stored on a computer hard drive, these technologies are embedded in the Website.',
  'app.privacy-policy.passive-data-collection.11': 'We may also use web beacons in our HTML-based emails to let us know which emails recipients have opened. This allows us to gauge the effectiveness of certain communications and the effectiveness of our marketing campaigns.',
  'app.privacy-policy.information-we-collect.third-party-advertisers.subtitle': 'Third Party Advertisers',
  'app.privacy-policy.third-party-advertisers.1': 'Third-party advertising companies may also use cookies, web beacons or similar technologies on the Website to collect information (such as click stream information) during your visits to our Website and other websites, as well as during your use of our Services, in order to help us provide you with advertisements about our Services that we believe are likely to be of interest to you.',
  'app.privacy-policy.third-party-advertisers.2': 'If you prefer not to have your information used for the purpose of serving you personalized advertising, you may also learn more about your choices or change your preferences at {optOutAdsLink}, {optOutNetworkAdvertisingLink}, {trusteLink}, from Google at {googleAdsLink} or {googlePoliciesLink}, from Facebook at {facebookAdsLink} or {facebookPolicyLink}, or if you are located in the European Union at {euroUnionOnlineChoicesLink}.',
  'app.privacy-policy.third-party-advertisers.opt-out-ads-link': 'optout.aboutads.info',
  'app.privacy-policy.third-party-advertisers.opt-out-networking-advertising-link': 'optout.networkadvertising.org',
  'app.privacy-policy.third-party-advertisers.truste-link': 'preferences-mgr.truste.com',
  'app.privacy-policy.third-party-advertisers.google-ads-link': 'www.google.com/settings/ads',
  'app.privacy-policy.third-party-advertisers.google-policies-link': 'policies.google.com/technologies/partner-sites',
  'app.privacy-policy.third-party-advertisers.facebook-ads-link': 'www.facebook.com/about/ads',
  'app.privacy-policy.third-party-advertisers.facebook-policy-link': '/www.facebook.com/policy.php',
  'app.privacy-policy.third-party-advertisers.euro-union-online-choices-link': 'www.youronlinechoices.eu/',
  'app.privacy-policy.how-we-use-your-information.sub-header': '3. How We Use Your Information',
  'app.privacy-policy.how-we-use-your-information.we-may-use-info-to.subtitle': 'We may use information that we collect about you to:',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.1': 'Deliver the Services that you have requested;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.2': 'Manage your account and provide you with customer support;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.3' : 'Perform research and analysis about your use of, or interest in, our Services or content available thereby, or products, services, or content offered by others;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.4' : 'Aggregate data such as the anonymous personal financial data you may provide via the Website. This aggregate information does not identify particular Users or otherwise allow anyone to recover sensitive information about individual Users.',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.5': 'Communicate with you by e-mail, postal mail, telephone, and/or mobile devices about products or services that may be of interest to you;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.6': 'Enforce our terms and conditions;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.7': 'Manage our business; and',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.8': 'Perform functions as otherwise described to you at the time of collection.',
  'app.privacy-policy.how-we-use-your-information.1': 'In addition, we may use aggregate, statistical, and de-identified data, which does not specifically identify any individuals, for any purpose.',
  'app.privacy-policy.how-we-disclose-your-information.sub-header': '4. How We Disclose Your Information',
  'app.privacy-policy.how-we-disclose-your-information.1' : 'Disclosure to Third Parties: ',
  'app.privacy-policy.how-we-disclose-your-information.2': 'Other than the exceptions stated below, Neighborhood Trust pledges to not transfer your PII to any third party. We share PII and other information collected via our Services with other companies or individuals as follows:',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.1': 'When they help us operate our business, such as vendors and cloud providers who assist us with data storage, customer communication, outcome tracking, and analytics;',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.2': 'With third parties if we have a good faith belief that access, use or disclosure of such PII is reasonably necessary to (a) satisfy applicable law, regulation, legal process or an enforceable governmental request, (b) enforce the applicable Terms of Service or this Privacy Policy, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or (d) protect against harm to the rights, property or safety of Neighborhood Trust, its Users or the public, including as required or permitted by applicable laws or valid legal orders of a governmental authority of competent jurisdiction.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.3': 'With any of our subsidiaries, joint ventures, or other companies under common control with us, in which case we will require them to honor this Privacy Policy or terms which provide terms and conditions which are no less than stringent than those contained in this Privacy Policy. Additionally, in the event we go through a business transition such as a merger, acquisition by another company, or sale of all of a portion of our assets, your PII may be among the assets transferred.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.4': 'If requested, we may share your name with the organization that has connected you with the Services, but will not share your individual financial information.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.5': 'Your financial information may be aggregated with others\' financial information and shared with the organization that has connected you with this service for reporting and evaluation purposes only.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.6': 'PII that has been anonymized and aggregated may be shared with third parties for research, evaluation, and other internal business purposes.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.7': 'We do not sell your PII to third parties.',
  'app.privacy-policy.how-we-disclose-your-information.3': 'When you are no longer our customer, we may continue to share your information as described in this Privacy Policy, including as necessary to comply with applicable laws or valid legal orders of a government authority of competent jurisdiction.',
  'app.privacy-policy.third-party-websites.sub-header': '5. Third Party Websites',
  'app.privacy-policy.third-party-websites.1': 'In the event that we provide links to otehr websites that we do not control, whether or not those other websites refer to our branding, this Privacy Policy does not apply to those other websites. Visitors to those websites are advised to carefully read the policies on those individual websites.',
  'app.privacy-policy.childrens-privacy.sub-header': '6. Children\'s Privacy',
  'app.privacy-policy.childrens-privacy.1': 'Our Services are not intended for or directed toward children under 13 years of age, and children should not use the Services for any purpose. If we learn that we have collected or received PII from a child under 13 years of age, we will delete that information.',
  'app.privacy-policy.childrens-privacy.2': 'If you are the parent or legal guardian of a child under 13 years of age who has registered on the Website or you believe has otherwise provided PII to Neighborhood Trust, please contact us at {mailToSupportAction} to have the account terminated and information deleted.',
  'app.privacy-policy.childrens-privacy.3': 'If you believe that we may have inadvertently collected any PII about a child aged 13 years or younger, please contact us at {mailToSupportAction} or as provided in the "How to contact us" section below.',
  'app.privacy-policy.your-choices.sub-header': '7. Your Choices',
  'app.privacy-policy.your-choices.accessing-requesting-changes.subtitle': 'Accessing and requesting changes to your PII',
  'app.privacy-policy.your-choices.accessing-requesting-changes.1': 'If you are a customer of Neighborhood Trust, you may review and change your PII through your customer account.',
  'app.privacy-policy.your-choices.accessing-requesting-changes.2': 'If you do not have a customer account with Neighborhood Trust, or if you cannot access or change any of your PII through your account, but you still wish to inquire about, access, correct, update, or delete PII that we may hold about you, please email us at {mailToSupportAction}. Please understand that, before responding to your request, we may ask you for additional information in order to verify your identity, or take other actions that we believe are appropriate in order to verify your identity.',
  'app.privacy-policy.your-choices.accessing-requesting-changes.3':'Please also understand that we may not be able to alter or delete your PII and may retain information to provide you with requested services or fulfill obligations to you ,for security reasons, if we are required under applicable law or regulations, for internal business use or for other valid reasons. We are also not obligated to comply with requests that are unreasonable burdensome or expensive, or with requests that would interfere with the rights of another individual. In some circumstances, we may charge a reasonable fee to fulfill your request.',
  'app.privacy-policy.your-choices.communications-preferences.subtitle': 'Communications preferences:',
  'app.privacy-policy.your-choices.communications-preferences.1': 'If you would like to change the types of communications you receive from us or how your receive communications from us, or if you would like to opt out of promotional communications, you may do so at any time by updating the communications preferences provided in your customer account.',
  'app.privacy-policy.your-choices.communications-preferences.2':'If you do not have a customer account, you may also use the unsubscribe links and instructions that will be present in any promotional emails or other communications that we send.',
  'app.privacy-policy.your-choices.communications-preferences.3' : 'Please understand that, even if you opt out of marketing or promotional communications, we may continue to send non-promotional communications such as confirmations of transactions, changes to the Services or other information about your use of the Services.',
  'app.privacy-policy.your-choices.do-not-track.subtitle': '"Do Not Track" Settings:',
  'app.privacy-policy.your-choices.do-not-track.1' : 'Our Website and Services are not at this time configured to read or respond to "do not track" settings or signals in your browser headings.',
  'app.privacy-policy.notices-to-CA-residents.sub-header': '8. Notices to California Residents',
  'app.privacy-policy.notices-to-CA-residents.1': 'We will not share your personal information with third parties for their independent business purposes or direct marketing purposes without first giving you the ability to provide affirmative consent to such sharing. If you have provided such consent, then California\'s "Shine the Light" law permits you to request information regarding the disclosure of your Personal Information by us to a third party for the third party\'s direct marketing purposes. To make such a request, please sent an email to {mailToSupportAction}.',
  'app.privacy-policy.notices-to-VT-residents.sub-header': '9. Notices to Vermont Residents',
  'app.privacy-policy.notices-to-VT-residents.1': 'We will not disclose information about your creditworthiness to our affiliates and will not disclose your personal information, financial information, credit report, or health information to nonaffiliated third parties to market to you, other than as permitted by Vermont law, unless you authorize us to make those disclosures. Additional information concerning our privacy policies can be found at {externalTosLink} or call {tollFreePhone}.',
  'app.privacy-policy.notices-to-VT-residents.2': 'You have the right to opt-out of the disclosure of any of the nonpublic personal information as stated above by notifying Neighborhood Trust in writing. In some instances, we may no longer be able to provide our services to you.',
  'app.privacy-policy.notices-to-VT-residents.3': 'Please see the contact information at the bottom of this document to opt-out.',
  'app.privacy-policy.security.sub-header': '10. Security',
  'app.privacy-policy.security.1': 'Information security: ',
  'app.privacy-policy.security.2': 'We take reasonable technical and physical security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data. These include encryption, internal reviews of our data collection, best physical and digital storage and processing practices and security measures, keeping up to date with patches on all relevant software, as well as working with reliable cloud providers to guard against unauthorized access to systems.',
  'app.privacy-policy.security.3': 'Our failure to enforce any provision of these Terms of Service shall not be construed as a waiver of that provision or any other provision. No waiver shall be effective against us unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance. Except as expressly agreed by us and you in writing, these Terms of Service and the Privacy Policy constitute the entire agreement between you and us with respect to the subject matter, and supersedes all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter. These Terms of Service and the Privacy Policy will inure to the benefit of our successors, assigns, licensees, and sublicenses.',
  'app.privacy-policy.changes-to-privacy-policy.sub-header': '11. Changes to this Privacy Policy',
  'app.privacy-policy.changes-to-privacy-policy.1': 'We may change this Privacy Policy from time to time. The most recent version of the Privacy Policy will always be posted on the Website, with the effective date posted at the top of the Privacy Policy. We may revise and update this Privacy Policy if our practices change, as technology changes, or as we add new services or change existing ones. If we make material changes to our Privacy Policy or how we handle your PII, or if we are going to use any PII in a manner that is materially different from that stated in our Privacy Policy at the time we collected such information, we will try to notify you of such changes reasonably in advance. By using our Website or Services after the effective date, you are deemed to consent to our then-current Privacy Policy.',
  'app.privacy-policy.how-to-contact.sub-header': '12. How to Contact Us',
  'app.privacy-policy.how-to-contact.1': 'If you have questions, concerns or feedback regarding this Privacy Policy or our information practices, you can send an email to us at {mailToSupportAction} or by postal mail at:',
  'app.privacy-policy.how-to-contact.2': 'Attn: Legal Department',
  'app.privacy-policy.how-to-contact.3': 'The Neighborhood Trust Privacy Policy can also be found at {externalPrivacyPolicyLink}.',

  'app.appointment.title': 'Pick your coach',
  'app.appointment.timezone': 'What time zone is this client’s appointment in?',
  'app.appointment.timezone-note': '(Note: please take standard and daylight savings time into effect when choosing from the following options):',
  'app.appointment.timezone.answer.none': 'Please select from the following',
  'app.appointment.timezone.answer.est': 'Eastern Standard Time, EST',
  'app.appointment.timezone.answer.cst': 'Central Standard Time, CST',
  'app.appointment.timezone.answer.mst': 'Mountain Standard Time, MST',
  'app.appointment.timezone.answer.pst': 'Pacific Standard Time, PST',
  'app.appointment.timezone.answer.ast': 'Alaska Standard Time, AST',
  'app.appointment.timezone.answer.hst': 'Hawaii-Aleutian Standard Time, HST',
  'app.appointment.timezone.answer.edt': 'Eastern Daylight Time, EDT',
  'app.appointment.timezone.answer.cdt': 'Central Daylight Time, CDT',
  'app.appointment.timezone.answer.mdt': 'Mountain Daylight Time, MDT',
  'app.appointment.timezone.answer.pdt': 'Pacific Daylight Time, PDT',
  'app.appointment.timezone.answer.adt': 'Alaska Daylight Time, ADT',
  'app.appointment.timezone.answer.hdt': 'Hawaii-Aleutian Standard Time, HST',
  'app.appointment.timezone-submit': 'Submit',
  'app.appointment-schedule.title': 'Thirty Minute Meeting Calendar',
  'app.appointment.pick-a-time-with': 'Pick a time with ',
  'app.appointment.description': 'Our counselors are available at these times. Each session will take 30 minutes. All times are local based on your location.',
  'app.appointment.description2': 'If you don\'t have 30 minutes to spare, try ',
  'app.appointment.description.link': 'sending a message',
  'app.appointment.description3': ' directly to a coach.',
  'app.appointment.ask-a-coach-heading': 'Ask a Coach',
  'app.appointment.ask-a-coach-heading.default-to-ask-a-coach-orgs': 'Get started by sharing your questions with a coach',
  'app.appointment.ask-counselor.prompt': 'Ask me a question!',
  'app.appointment.ask-counselor.ask-me': 'Ask Me',
  'app.appointment.ask-counselor.1': 'What question do you have about your finances? I can help!',
  'app.appointment.ask-counselor.2': 'Your question',
  'app.appointment.ask-counselor.3': 'In order to send a response, I\'ll need your email! I will get back to you within three business days!',
  'app.appointment.ask-counselor.3.default-to-ask-a-coach-orgs': 'Please provide your email where you would like to receive a response: ',
  'app.appointment.ask-counselor.4': 'Email address',
  'app.appointment.ask-counselor.5': 'Okay! Let me think about this one, and I\'ll send a response as soon as I can!',
  'app.appointment.ask-counselor.5.default-to-ask-a-coach-orgs': 'Okay!  I\'ll send a response within three days. You can also continue the sign-up process in the next screens and choose a session time when you login.',
  'app.appointment.ask-counselor.6': 'We can start by pulling your credit report or answering a few questions.',
  'app.appointment.ask-counselor.7': ' You can always schedule a one-on-one session with me or any coach!',
  'app.appointment.ask-counselor.btn.schedule-now': 'Sign-up now',
  'app.appointment.ask.button': 'Ask',
  'app.appointment.submit.button': 'Submit',
  'app.appointment.close.button': 'Close',
  'app.appointment.counselors.title': 'Your coaches.',
  'app.appointment.phone.placeholder': '(XXX) XXX-XXXX',
  'app.appointment.phone-explanation': 'We will text you a link to a Skype meeting beforehand or call you at this number.',
  'app.appointment.submit.skype': 'Schedule Skype session',
  'app.appointment.submit.phone': 'Schedule phone call session',
  'app.appointment.alert.start-over': 'Your appointment was unable to save. Please schedule again below.',
  'app.no-appointments.title': 'Create a Login',
  'app.no-appointments.description.link': 'send a question',
  'app.no-appointments.description1': 'Our coached don\'t seem to have any available time slots right now. You can either ',
  'app.no-appointments.description2': ' directly to a coach or create a login to check back at any time. Enter your information below to get started.',
  'app.report-or-appointment.title': 'Let\'s get started.',
  'app.report-or-appointment.text-1': 'Before meeting with your coach, the first step toward achieving your goals is knowing about your financial past and present. An easy way to do this is to look at insights from your credit report.',
  'app.report-or-appointment.text-2': 'With your permission, we can pull your TransUnion® credit report and show you insights that will help you gain an understanding of your financial life. This will be a “soft pull,” meaning it won’t impact your score.',
  'app.report-or-appointment.text-3': 'This service has been provided by {employer} at no cost to you. We will never share any of your financial information with your employer.',
  'app.report-or-appointment.agreement': 'By clicking the blue button below, you authorize TrustPlus to pull your credit report for the purposes stated above. To further provide you with support and monitor your progress, you authorize TrustPlus to obtain your credit report from Transunion® or any of the three credit reporting agencies for the duration of your engagement in TrustPlus services and for up to 26 months thereafter.',
  'app.report-or-appointment.go-to-credit-report-form': 'Get my credit report',
  'app.report.additional-questions': 'Continue',
  'app.report-or-appointment.schedule-appointment': 'Skip this step',
  'app.credit-report-form.title': 'Pull your credit report.',
  'app.credit-report-form.text': 'We need a few pieces of information in order to pull your credit report and get your debt summary. All fields are required.',
  'app.credit-report-form.authorization': 'By clicking the blue button below, you authorize TrustPlus to pull your credit report for the purposes stated above. To further provide you with support and monitor your progress, you authorize TrustPlus to obtain your credit report from Transunion® or any of the three credit reporting agencies for the duration of your engagement in TrustPlus services and for up to 26 months thereafter.',
  'app.credit-report-form.additional-questions': 'Skip this step',
  'app.credit-report-form.input.street': 'Home street address',
  'app.credit-report-form.input.city': 'City',
  'app.credit-report-form.input.state': 'State',
  'app.credit-report-form.input.zip-code': 'Zip code',
  'app.credit-report-form.date-of-birth.title': 'Date of birth',
  'app.credit-report-form.date-of-birth.placeholder': 'MM/DD/YYYY',
  'app.credit-report-form.input.phone-number': 'Cell phone number',
  'app.credit-report-form.input.ssn': 'Social Security Number or ITIN',
  'app.credit-report-form.input.ssn.text': 'Your Social Security Number or ITIN will only be used to pull your credit report, nothing else, we promise.',
  'app.credit-report-form.input.ssn.link': 'Why are we asking for this information?',
  'app.credit-report-form.go-to-credit-report': 'Pull my credit report',
  'app.credit-report-form.ssn-modal.title': 'We take security seriously.',
  'app.credit-report-form.ssn-modal.body.1': 'We only use your SSN/ITIN, date of birth, and home address to uniquely identify you. We\'re unable to get your credit report without these.',
  'app.credit-report-form.ssn-modal.body.2': 'We encrypt your SSN/ITIN, storing it in the following format: ',
  'app.credit-report-form.ssn-modal.body.3': 'To see more details about our security policy, visit {link}',
  'app.debt-report-interim.title': 'Just a moment.',
  'app.debt-report-interim.explanation.title': 'It will take just a moment to securely pull your credit report.',
  'app.debt-report-interim.try-again.title': 'We had a problem pulling your credit report.',
  'app.debt-report-interim.try-again.button': 'Try again',
  'app.debt-report-interim.skip.button': 'Skip to questionnaire',
  'app.debt-report-interim.continue.button': 'Continue',
  'app.debt-report-recommendations.recommendations': 'Recommendations',
  'app.debt-report-recommendations.credit-report': 'Credit Report',
  'app.debt-report-recommendations.none-header': 'No Instant Recommendations',
  'app.debt-report-recommendations.none-text-1': 'We didn’t detect any immediate red flags for you to address, but our expert coaches will be reviewing your credit report carefully to help you to tackle any credit and debt issues you need in your upcoming session.',
  'app.debt-report-recommendations.none-text-2': 'You can explore the details yourself on the credit report tab.',
  'app.debt-report-recommendations.debt-report-header': 'Credit report',
  'app.debt-report-recommendations.debt-report-text-1': 'Total Debt',
  'app.debt-report-recommendations.top-priority': 'Top Priority',
  'app.debt-report-recommendations.new-top-priority': 'New Top Priority',
  'app.debt-report-recommendations.collections': 'Collections',
  'app.debt-report-recommendations.let-us-know-about-you': 'Let us know about you',
  'app.debt-report-recommendations.skip-these-questions': 'Skip these questions',
  'app.debt-report-recommendations.go-to-questionnaire': 'Go to questionnaire ',
  'app.debt-report-recommendations.collections-text-1a': 'You will be disputing the following ',
  'app.debt-report-recommendations.collections-text-1b': ' collections:',
  'app.debt-report-recommendations.collections-text-1':'We found ',
  'app.debt-report-recommendations.collections-text-2': ' collections on your Transunion report. A collection account represents a debt that an original creditor sold to another creditor--a collection agency--after the debt went unpaid for a certain amount of time. Collections decrease your credit score, but there are steps you can take to remediate them.',
  'app.debt-report-recommendations.collections-header': 'Collection Details',
  'app.debt-report-recommendations.collections-text-3': ' is looking to collect ',
  'app.debt-report-recommendations.collections-text-4': ' from a debt with ',
  'app.debt-report-recommendations.collections-text-6': 'I do not want to work on this right now.',
  'app.debt-report-recommendations.collections-text-7': 'You\'ve already looked at:',
  'app.debt-report-recommendations.collections-text-8': 'Waiting to dispute',
  'app.debt-report-recommendations.collections-btn-text': 'Work on this',
  'app.debt-report-work-on-this.text-1': 'Here’s how this item appears on your credit report:',
  'app.debt-report-work-on-this.table-row-1': 'Original Creditor',
  'app.debt-report-work-on-this.table-row-2': 'Type of Account',
  'app.debt-report-work-on-this.table-row-2-text': 'Collection',
  'app.debt-report-work-on-this.table-row-3': 'Account Balance',
  'app.debt-report-work-on-this.table-row-4': 'Open Date',
  'app.debt-report-work-on-this.header-text-1': 'What Does This Mean?',
  'app.debt-report-work-on-this.text-2': 'Credit reports can look confusing, but let’s break it down. ',
  'app.debt-report-work-on-this.text-3': ' is a collection agency that reported to Transunion(R) that they are the owners of one of your debts. It is very likely they have tried contacting you, either by mail or phone.',
  'app.debt-report-work-on-this.text-4': 'The original creditor is listed as ',
  'app.debt-report-work-on-this.text-5': ', which means that you originally owed them for the debt. After not receiving payment for a period of time, they sold the debt to ',
  'app.debt-report-work-on-this.text-6': '. It is unlikely that you can work with ',
  'app.debt-report-work-on-this.text-7': ' on the debt now that the collection agency has reported the debt.',
  'app.debt-report-work-on-this.text-8': 'The balance is reported as',
  'app.debt-report-work-on-this.text-9': ', which is likely more than you owed ',
  'app.debt-report-work-on-this.text-10': '. This includes the amount unpaid, plus fees and interest charges.',
  'app.debt-report-work-on-this.text-11': 'The “open date” with the collection agency is reported as ',
  'app.debt-report-work-on-this.text-12': '. This indicates the date where ',
  'app.debt-report-work-on-this.text-13': ' officially purchased the debt and began collecting the debt.',
  'app.debt-report-work-on-this.header-text-2': 'What Can I Do?',
  'app.debt-report-work-on-this.text-14': 'There are a few options! We’ll help guide you with some questions below.',
  'app.debt-report-work-on-this.text-15': 'First, ',
  'app.debt-report-work-on-this.header-text-3': 'Do You Recognize This Account?',
  'app.debt-report-work-on-this.btn-1': 'I know this account, and I understand that I owe the balance',
  'app.debt-report-work-on-this.btn-2': 'I know this account, but I paid this debt and should not owe anything',
  'app.debt-report-work-on-this.btn-3': 'I do not recognize this account',
  'app.debt-report-reviewed-collections.title': 'Collections',
  'app.debt-report-reviewed-collections.text-1': 'You will be disputing the following ',
  'app.debt-report-reviewed-collections.text-2': ' transactions:',
  'app.debt-report-reviewed-collections.btn-text-1': 'Dispute Online',
  'app.debt-report-reviewed-collections.text-3': 'You have opted to speak with a coach about the following collection:',
  'app.debt-report-reviewed-collections.text-4': 'Session Scheduled',
  'app.debt-report-reviewed-collections.recognize-account.title': 'What to do when you owe the balance on a collection.',
  'app.debt-report-reviewed-collections.recognize-account.text-1': 'Ok, thanks for confirming that you recognize this debt.',
  'app.debt-report-reviewed-collections.recognize-account.text-2': 'If you’re ready to take action to address it, the first thing you will do with your coach is validate the debt to confirm that the collection agency reporting this debt is meeting their legal obligations.',
  'app.debt-report-reviewed-collections.recognize-account.text-3': 'Your coach will provide more details in your session and help you know how to prioritize this item among your other credit report accounts and goals.',
  'app.debt-report-reviewed-collections.do-not-recognize-account.title': 'What to do when you don\'t recognize an account.',
  'app.debt-report-reviewed-collections.do-not-recognize-account.text-1': 'It is not uncommon for a credit report to contain inaccurate information. A 2021 Consumer Reports study shows that 34% of consumers found errors on their credit report.',
  'app.debt-report-reviewed-collections.do-not-recognize-account.text-2': 'Fortunately, TransUnion provides an online tool for disputing items on your credit report. However, before we do that, let\'s make sure everything else looks good.',
  'app.debt-report-collections.help-dispute-back-to-collections': 'Back to all collections',
  'app.debt-report-collections.dispute-step-1-title': 'Dispute Instructions',
  'app.debt-report-collections.dispute-step-1-subtitle': 'Step 1',
  'app.debt-report-collections.dispute-step-1-text-1': 'Sign up for an account on ',
  'app.debt-report-collections.dispute-step-1-text-2': 'ServiceTransunion.com',
  'app.debt-report-collections.dispute-step-1-text-3': '. You will need to input your name, address, and the last four digits of your social security number. In addition, you will need to make a username and password. A code will be sent to your email to confirm your account.',
  'app.debt-report-collections.dispute-step-1-text-4': 'Note: You may be requested to verify your identity by answering questions that only you should know about your accounts. If unscucessful, you may need to call 866-744-8221. Follow the prompts, a service rep will pick up the call and ask questions to verify your identity.',
  'app.debt-report-collections.dispute-next-step-btn': 'Next step',
  'app.debt-report-collections.dispute-previous-step-btn': 'Previous step',
  'app.debt-report-collections.dispute-step-2-subtitle': 'Step 2',
  'app.debt-report-collections.dispute-step-2-text-1': 'On your TransUnion dashboard, find the “Dispute” section and click “Get Started.” You will see an informational panel where you will click “Start Request”. You will need to agree to the term.',
  'app.debt-report-collections.dispute-step-3-subtitle': 'Step 3',
  'app.debt-report-collections.dispute-step-3-text-1': 'Locate the incorrect items you previously identified:',
  'app.debt-report-collections.dispute-step-3-text-2': 'You will need to go through each one and click “Dispute”. Select “It is not mine” or “It’s inaccurate” to see more specific reasons. Select the one that best fits each account, and upload any supporting documentation if available (for instance, a notice from the lender that shows a balance was paid off). ',
  'app.debt-report-collections.dispute-step-3-text-3': 'Repeat this process for each of the items above.',
  'app.debt-report-collections.dispute-step-3-text-4': 'Note: supporting documents are NOT required.',
  'app.debt-report-collections.dispute-step-4-subtitle': 'Step 4',
  'app.debt-report-collections.dispute-step-4-text-1': 'Once you have submitted your dispute(s), you will be redirected to the dashboard where you can see any active items. You will also receive an email from Transunion confirming your dispute. They will also email you once an account has been resolved. It will likely take a couple of weeks for your status to be updated.',
  'app.debt-report-collections.dispute-back-to-summary-btn': 'Back to summary',
  'app.debt-report-reviewed-collections.dispute-offline': 'I want to dispute offline.',
  'app.debt-report-reviewed-collections.dispute-offline.title': 'Dispute Offline',
  'app.debt-report-reviewed-collections.dispute-offline.text-1': 'Your financial coach can help you dispute your collections accounts. They will provide you with a letter that you can send to TransUnion or any of the credit bureaus that details the accounts that you are disputing, the reason you are disputing them, as well as your rights as a consumer.',
  'app.debt-report-collections.to-questionnaire': 'You\'ll be able to see information on your disputes again once you log in. But to help us deliver the best service to you, please answer some basic questions about yourself.',

  'app.debt-report.title': 'Your credit report insights.',
  'app.debt-report.total-debt-subtitle': 'Here\'s your total debt according to your credit report.',
  'app.debt-report.total-debt-chart.title': 'TOTAL DEBT',
  'app.debt-report.total-debt-chart.category.mortgage': 'Real Estate',
  'app.debt-report.total-debt-chart.category.auto': 'Auto Loan',
  'app.debt-report.total-debt-chart.category.student': 'Student Loan',
  'app.debt-report.total-debt-chart.category.credit': 'Credit Card',
  'app.debt-report.total-debt-chart.category.collection': 'Collection',
  'app.debt-report.total-debt-chart.category.personal': 'Personal Loan',
  'app.debt-report.total-debt-chart.category.other': 'Other',
  'app.debt-report.debt-pie-chart.total-debt': 'Total Debt',
  'app.footer.sponsored-by': 'Provided by',
  'app.footer.terms': 'Terms of Service',
  'app.footer.privacy':'Privacy Policy',
  'app.footer.faq':'FAQ',
  'app.footer-ap.terms': 'Terms of Service',
  'app.footer-ap.privacy':'Privacy Policy',
  'app.footer.calendly.intro': 'By scheduling a session, you confirm that you have read and agree to the TrustPlus ',
  'app.footer.support': 'Need help? Email us at',
  'app.schedule-confirmation.title': 'Great! Your session is scheduled!',
  'app.schedule-confirmation.description-1': 'On the day of the appointment, *coach first name* will text you a video link or call you from a dedicated number with a 929 area code.',
  'app.schedule-confirmation.description-2': 'In the meantime, tell us about your finances so we can get the most out of your meeting.',
  'app.schedule-confirmation.continue.title': 'Your financial profile: ',
  'app.schedule-confirmation.percent.complete-text': 'complete',
  'app.schedule-confirmation.counselor.bubble.text': 'On the day of the appointment, I’ll text you a video call link or call you from my dedicated number, a 929 area code.',
  'app.schedule-confirmation.pull.report.button': 'Pull Credit Report',
  'app.schedule-confirmation.question.button': 'Skip to questionnaire',
  'app.thank-you.title': 'Thank you!',
  'app.thank-you.summary': 'We\'ll talk to you soon!',
  'app.account-confirmation.title': 'Thanks for signing up',
  'app.account-confirmation.counselor.text': 'We can start by pulling your credit report or answering a few questions.',
  'app.additional-questions.goals.title': 'Additional Questions',

  'app.additional-questions.goals.question.sign-up': 'Why did you sign up today?',
  'app.additional-questions.goals.answer.A life change': 'A life change',
  'app.additional-questions.goals.answer.To build credit': 'To build credit',
  'app.additional-questions.goals.answer.To help build savings': 'To help build savings',
  'app.additional-questions.goals.answer.Struggling with debt': 'Struggling with debt',
  'app.additional-questions.goals.answer.Living paycheck to paycheck': 'Living paycheck to paycheck',
  'app.additional-questions.goals.answer.I was denied a loan due to bad credit': 'Denied a loan',
  'app.additional-questions.goals.answer.To plan for a large purchase': 'To plan for a purchase',
  'app.additional-questions.goals.answer.To save for a goal': 'To save for a goal',
  'app.additional-questions.goals.answer.To deal with other financial problems': 'To deal with other financial problems',
  'app.additional-questions.goals.answer.To make sure I\'m on the right path': 'To make sure I\'m on the right path',
  'app.additional-questions.goals.answer.Just curious': 'Just curious',
  'app.additional-questions.goals.question.confidence-of-goals': 'How confident are you that your household is currently doing what is needed to meet your long-term goals?',
  'app.additional-questions.goals.answer.': 'Please select from the following',
  'app.additional-questions.goals.answer.Very confident': 'Very confident',
  'app.additional-questions.goals.answer.Moderately confident': 'Moderately confident',
  'app.additional-questions.goals.answer.Somewhat confident': 'Somewhat confident',
  'app.additional-questions.goals.answer.Slightly confident': 'Slightly confident',
  'app.additional-questions.goals.answer.Not at all confident': 'Not at all confident',
  'app.additional-questions.goals.question.plans-ahead-financially': 'To what extent do you agree or disagree with the following: “My household plans ahead financially.”',
  'app.additional-questions.goals.answer.Agree strongly': 'Agree strongly',
  'app.additional-questions.goals.answer.Agree somewhat': 'Agree somewhat',
  'app.additional-questions.goals.answer.Neither agree or disagree': 'Neither agree or disagree',
  'app.additional-questions.goals.answer.Disagree somewhat': 'Disagree somewhat',
  'app.additional-questions.goals.answer.Disagree strongly': 'Disagree strongly',
  'app.additional-questions.goals.question.tell-counselor': 'What specifically do you want your coach to know before your session?',
  'app.additional-questions.goals.question.which-santander-branch': 'Which Santander Branch referred you (if applicable)?',
  'app.additional-questions.banking.title': 'Tell us how you bank.',
  'app.additional-questions.banking.question.savings-amount': 'How much do you have set aside in cash or liquid savings?',
  'app.additional-questions.banking.answer.': 'Please select from the following',
  'app.additional-questions.banking.answer.$0-99': '$0-99',
  'app.additional-questions.banking.answer.$100-399': '$100-399',
  'app.additional-questions.banking.answer.$400-1000': '$400-1000',
  'app.additional-questions.banking.answer.$1000-2000': '$1000-2000',
  'app.additional-questions.banking.answer.$2000+': '$2000+',
  'app.additional-questions.banking.question.checking-account': 'Do you have a checking account?',
  'app.additional-questions.banking.question.savings-account': 'Do you have a savings account?',
  'app.additional-questions.banking.question.direct-deposit': 'Do you use direct deposit?',
  'app.additional-questions.banking.answer.yes': 'Yes',
  'app.additional-questions.banking.answer.no': 'No',
  'app.additional-questions.banking.answer.Yes - Checking': 'Yes, checking account',
  'app.additional-questions.banking.answer.Yes - Savings': 'Yes, savings account',
  'app.additional-questions.banking.answer.Yes - Split': 'Yes, split between checking and savings',
  'app.additional-questions.banking.answer.Yes - Other': 'Yes, other account',
  'app.additional-questions.banking.answer.Unsure': 'I\'m not sure',
  'app.additional-questions.banking.answer.No': 'No, I do not use direct deposit',
  'app.additional-questions.banking.question.current-expenses-time-length': 'How long could your household afford to cover current expenses, without withdrawing money from retirement accounts or borrowing?',
  'app.additional-questions.banking.answer.6 months or more': '6 months or more',
  'app.additional-questions.banking.answer.3-5 months': '3-5 months',
  'app.additional-questions.banking.answer.1-2 months': '1-2 months',
  'app.additional-questions.banking.answer.1-3 weeks': '1-3 weeks',
  'app.additional-questions.banking.answer.Less than 1 week': 'Less than 1 week',
  'app.additional-questions.banking.question.confident-in-emergency': 'How confident are you that your household\'s insurance policies will provide enough support in an emergency?',
  'app.additional-questions.banking.answer.Very confident': 'Very confident',
  'app.additional-questions.banking.answer.Moderately confident': 'Moderately confident',
  'app.additional-questions.banking.answer.Somewhat confident': 'Somewhat confident',
  'app.additional-questions.banking.answer.Slightly confident': 'Slightly confident',
  'app.additional-questions.banking.answer.Not at all confident': 'Not at all confident',
  'app.additional-questions.banking.answer.No one in my household has any insurance': 'No one in my household has any insurance',
  'app.additional-questions.employment.title': 'Tell us about your work.',
  'app.additional-questions.employment.question.annual-income': 'How much do you make each year?',
  'app.additional-questions.employment.question.payment-frequency': 'How often do you get paid?',
  'app.additional-questions.employment.answer.Daily': 'Daily',
  'app.additional-questions.employment.answer.Weekly': 'Weekly',
  'app.additional-questions.employment.answer.Every other week or twice per month': 'Every other week or twice per month',
  'app.additional-questions.employment.answer.Monthly': 'Monthly',
  'app.additional-questions.employment.answer.It varies/Other': 'It varies or other',
  'app.additional-questions.employment.question.payment-same-amount': 'Is your paycheck typically the same amount?',
  'app.additional-questions.employment.answer.Yes': 'Yes',
  'app.additional-questions.employment.answer.No': 'No',
  'app.additional-questions.employment.question.last-paycheck-amount': 'How much was your last paycheck?',
  'app.additional-questions.employment.question.paycheck-amounts': 'How much were your last two paychecks?',
  'app.additional-questions.employment.question.employment-status': 'What is your job status?',
  'app.additional-questions.employment.answer.I work full time': 'I work full time.',
  'app.additional-questions.employment.answer.I work part time': 'I work part time.',
  'app.additional-questions.employment.answer.I have a side gig': 'I have a side gig.',
  'app.additional-questions.employment.answer.I am self-employed': 'I am self-employed',
  'app.additional-questions.employment.answer.I often work overtime': 'I often work overtime.',
  'app.additional-questions.employment.answer.I work two jobs': 'I work two jobs.',
  'app.additional-questions.employment.answer.I am not working right now': 'I am not working right now',
  'app.additional-questions.employment.answer.Someone else contributes to my family\'s finances': 'Someone else contributes to my family\'s finances (spouse, parent, friend, etc.).',
  'app.additional-questions.employment.answer.staples.site-type.': 'Please select Site Type from the following',
  'app.additional-questions.employment.answer.staples.site-type.FC': 'FC',
  'app.additional-questions.employment.answer.staples.site-type.DC': 'DC',
  'app.additional-questions.employment.answer.staples.site-type.SDO': 'SDO',
  'app.additional-questions.employment.answer.staples.site-type.Furniture': 'Furniture',
  'app.additional-questions.employment.answer.staples.city.': 'Please select City from the following',
  'app.additional-questions.employment.question.unemployed-2020': 'Were you unemployed at all in the past 12 months?',
  'app.additional-questions.employment.question.caregiver-775': 'Are you a caregiver?',
  'app.additional-questions.employment.question.company-775': 'What company do you work for?',
  'app.additional-questions.employment.answer.company-775.Addus Healthcare': 'Addus Healthcare',
  'app.additional-questions.employment.answer.company-775.Amicable Healthcare': 'Amicable Healthcare',
  'app.additional-questions.employment.answer.company-775.Catholic Community Service': 'Catholic Community Service',
  'app.additional-questions.employment.answer.company-775.Chesterfield Service': 'Chesterfield Service',
  'app.additional-questions.employment.answer.company-775.Concerned Citizen': 'Concerned Citizen',
  'app.additional-questions.employment.answer.company-775.First Choice': 'First Choice',
  'app.additional-questions.employment.answer.company-775.Full Life Elder Care': 'Full Life Elder Care',
  "app.additional-questions.employment.answer.company-775.Korean Womens Association (KWA)": "Korean Women's Association (KWA)",
  'app.additional-questions.employment.answer.company-775.Millennia Healthcare': 'Millennia Healthcare',
  'app.additional-questions.employment.answer.company-775.Always Caring (also known as ResCare or Bright Spring)': 'Always Caring (also known as ResCare or Bright Spring)',
  'app.additional-questions.employment.answer.company-775.CDWA, DSHS, IP, or independent contractor': 'CDWA, DSHS, IP, or independent contractor',

  'app.additional-questions.employment.question.how-long-unemployed': 'How long were you unemployed?',
  'app.additional-questions.employment.answer.1-3 months' : '1-3 months',
  'app.additional-questions.employment.answer.4-6 months' : '4-6 months',
  'app.additional-questions.employment.answer.More than 6 months' : 'More than 6 months',
  'app.additional-questions.employment.question.filed-2020-taxes': 'Did you file your taxes?',
  'app.additional-questions.employment.answer.Yes, and I received a refund + EITC (earned income tax credit)' : 'Yes, and I received a refund + EITC (earned income tax credit)',
  'app.additional-questions.employment.answer.Yes, and I received a refund' : 'Yes, and I received a refund',
  'app.additional-questions.employment.answer.Yes, and I owed taxes' : 'Yes, and I owed taxes',
  'app.additional-questions.employment.answer.Not yet' : 'Not yet',
  'app.additional-questions.employment.answer.I do not file taxes' : 'I do not file taxes',
  'app.additional-questions.owner-or-employee.question.owner-or-employee': 'Are you a small business owner or an employee?',
  'app.additional-questions.owner-or-employee.answer.An employee at a small business': 'An employee at a small business',
  'app.additional-questions.owner-or-employee.answer.A small business owner': 'A small business owner',
  // onboarding employment tax tooltips
  'app.additional-questions.owner-or-employee.tax-tooltip': 'Owner or employee tax tool tip.',
  'app.emergency-questions.what-is-your-annual-income.tax-tooltip': 'Annual income tax tool tip',
  'app.additional-questions.covid-questions.describe-current-or-most-recent-industry.tax-tooltip': 'Current or recent industry tax tool tip.',
  'app.additional-questions.covid-questions.describe-family-earnings.tax-tooltip': 'Describe family earnings tax tool tip.',
  'app.additional-questions.employment.question.how-long-unemployed.tax-tooltip': 'How long unemployed tax tool tip.',
  'app.additional-questions.employment.question.filed-2020-taxes.tax-tooltip': 'Did you know a new ‘lookback rule’ means you can use either your 2019 or 2020 income to get a bigger tax refund? Have these ready when you file: ',
  'app.additional-questions.employment.question.filed-2020-taxes.tax-tooltip-one': '1. Form 1099 G, (This form tells you how much unemployment you received. You may have to download it from your state’s Dep. of Labor website) ',
  'app.additional-questions.employment.question.filed-2020-taxes.tax-tooltip-two': '2. Your 2019 tax return.',
  'app.additional-questions.covid-questions.what-assistance-have-you-received.tax-tooltip':'Assistance received tax tool tip.',

  'app.additional-questions.expenses.title': 'Tell us about your expenses.',
  'app.additional-questions.household.title': 'Tell us about your household.',
  'app.additional-questions.household.question.living-situation': 'Tell us about your living situation.',
  'app.additional-questions.household.answer.I pay rent': 'I pay rent',
  'app.additional-questions.household.answer.I pay a mortgage': 'I have a mortgage',
  'app.additional-questions.household.answer.I live with a family member or friend': 'I live with a family member or friend',
  'app.additional-questions.household.answer.Other': 'Other',
  'app.additional-questions.household.question.monthly-rent': 'What is your monthly rent?',
  'app.additional-questions.household.question.monthly-mortgage': 'How much is your monthly mortgage?',
  'app.additional-questions.household.question.monthly-family-friend': 'How much is your monthly contribution to the household?',
  'app.additional-questions.household.question.monthly-other': "What are your monthly housing expenses?",
  'app.additional-questions.household.question.additional-monthly-costs': 'Do you have any additional monthly costs?',
  'app.additional-questions.household.answer.Maintenance': 'Maintenance',
  'app.additional-questions.household.answer.Utilities': 'Utilities',
  'app.additional-questions.household.answer.Insurance': 'Insurance',
  'app.additional-questions.household.question.household-spending': 'Which of the following best describes your household’s spending over the last 12 months?',
  'app.additional-questions.household.answer.': 'Please select from the following',
  'app.additional-questions.household.answer.Much less than income': 'Much less than income',
  'app.additional-questions.household.answer.A little less than income': 'A little less than income',
  'app.additional-questions.household.answer.About equal to income': 'About equal to income',
  'app.additional-questions.household.answer.A little more than income': 'A little more than income',
  'app.additional-questions.household.answer.Much more than income': 'Much more than income',
  'app.additional-questions.household.question.household-bill-payment': 'Which of the following best describes your household’s bills over the last 12 months? My household has been able to: ',
  'app.additional-questions.household.answer.Pay all of our bills on time': 'Pay all of our bills on time',
  'app.additional-questions.household.answer.Pay nearly all of our bills on time': 'Pay nearly all of our bills on time',
  'app.additional-questions.household.answer.Pay most of our bills on time': 'Pay most of our bills on time',
  'app.additional-questions.household.answer.Pay some of our bills on time': 'Pay some of our bills on time',
  'app.additional-questions.household.answer.Pay very few of our bills on time': 'Pay very few of our bills on time',
  'app.additional-questions.feelings.title': 'Tell us how you\'re feeling.',
  'app.additional-questions.feelings.question.stress': 'How much stress do you feel about your personal finances and debt?',
  'app.additional-questions.feelings.question.stress.help': '(1 is low stress, 5 is high stress)',
  'app.additional-questions.feelings.answer.1': '1',
  'app.additional-questions.feelings.answer.2': '2',
  'app.additional-questions.feelings.answer.3': '3',
  'app.additional-questions.feelings.answer.4': '4',
  'app.additional-questions.feelings.answer.5': '5',
  'app.additional-questions.feelings.question.challenge': 'How challenging is it for you to manage your finances?',
  'app.additional-questions.feelings.question.challenge.help': '(1 is not challenging, 5 is highly challenging)',
  'app.additional-questions.demographics.title': 'Tell us more about you.',
  'app.additional-questions.demographics.question.marital-status': 'What is your marital status?',
  'app.additional-questions.demographics.question.partner-contribution': 'Do you have a partner or former partner who contributes to your finances (rent, groceries, childcare, a shared account, etc.)?',
  'app.additional-questions.demographics.question.household-size': 'What is your household size?',
  'app.additional-questions.demographics.question.number-dependents': 'How many dependents (younger than 18 years old) do you have?',
  'app.additional-questions.demographics.question.race': 'What is your race?',
  'app.additional-questions.demographics.question.education': 'What is your highest level of education?',
  'app.additional-questions.demographics.answer.': 'Please select from the following',
  'app.additional-questions.demographics.answer.Yes, and we live together': 'Yes, and we live together',
  'app.additional-questions.demographics.answer.Yes, but we do not live together': 'Yes, but we do not live together',
  'app.additional-questions.demographics.answer.No': 'No',
  'app.additional-questions.demographics.answer.Single': 'Single',
  'app.additional-questions.demographics.answer.Domestic Partner/ unmarried': 'Domestic partner / unmarried',
  'app.additional-questions.demographics.answer.Married': 'Married',
  'app.additional-questions.demographics.answer.Divorced': 'Divorced',
  'app.additional-questions.demographics.answer.Separated': 'Separated',
  'app.additional-questions.demographics.answer.Widowed': 'Widow / widower',
  'app.additional-questions.demographics.answer.1': '1',
  'app.additional-questions.demographics.answer.2': '2',
  'app.additional-questions.demographics.answer.3': '3',
  'app.additional-questions.demographics.answer.4': '4',
  'app.additional-questions.demographics.answer.5': '5',
  'app.additional-questions.demographics.answer.6': '6',
  'app.additional-questions.demographics.answer.7': '7',
  'app.additional-questions.demographics.answer.8': '8',
  'app.additional-questions.demographics.answer.9': '9',
  'app.additional-questions.demographics.answer.10': '10',
  'app.additional-questions.demographics.answer.10+': '10+',
  'app.additional-questions.demographics.answer.Some high school': 'Some high school',
  'app.additional-questions.demographics.answer.High school diploma/ GED': 'High school diploma / GED',
  'app.additional-questions.demographics.answer.Some college': 'Some college',
  'app.additional-questions.demographics.answer.College degree': 'College degree',
  'app.additional-questions.demographics.answer.Postgraduate studies': 'Postgraduate studies',
  'app.additional-questions.demographics.answer.Black or African American': 'Black or African American',
  'app.additional-questions.demographics.answer.Latino/Latina/Latinx': 'Latino/Latina/Latinx',
  'app.additional-questions.demographics.answer.Asian American': 'Asian American',
  'app.additional-questions.demographics.answer.Pacific Islander': 'Pacific Islander',
  'app.additional-questions.demographics.answer.American Indian': 'American Indian',
  'app.additional-questions.demographics.answer.White': 'White',
  'app.additional-questions.demographics.answer.Alaska Native' : 'Alaska Native',
  'app.additional-questions.demographics.answer.Other': 'Let me type it',
  'app.additional-questions.advocacy.title': 'Tell us how to make a difference',
  'app.additional-questions.advocacy.text': 'TrustPlus empowers you to take control of your finances, but we see hard-working people every day who make the right decisions and still encounter systems that make it hard to support their families and live a good life. We\'d like to advocate for our clients and change those systems.',
  'app.additional-questions.advocacy.question.advocacy-question': 'Has anything outside your control ever held you back from achieving your financial goals? Tell us about it.',
  'app.additional-questions.advocacy.answer.My wages were higher':'My wages were higher',
  'app.additional-questions.advocacy.answer.My work schedule and pay were more predictable':'My work schedule and pay were more predictable',
  'app.additional-questions.advocacy.answer.I could borrow money from someone at a reasonable rate':'I could borrow money from someone at a reasonable rate',
  'app.additional-questions.advocacy.answer.I had better employee benefits (insurance, paid time off, retirement, etc.)':'I had better employee benefits (insurance, paid time off, retirement, etc.)',
  'app.additional-questions.advocacy.answer.I had access to more affordable child care or other dependent care':'I had access to more affordable child care or other dependent care',
  'app.additional-questions.advocacy.answer.I had more help with my student loans':'I had more help with my student loans',
  'app.additional-questions.advocacy.answer.Public benefits were more generous':'Public benefits were more generous',
  'app.additional-questions.advocacy.continue-to-debt-report': 'Continue to Debt Summary',

  'app.additional-questions.continue': 'Continue',

  'app.debt-credit-self-evaluation.title':'Tell us about your credit.',
  'app.debt-credit-self-evaluation-with-credit-report.details':'Before we show you a picture of your credit and debt, we would love to know how you view your credit and debt situation as of today.',
  'app.debt-credit-self-evaluation-without-credit-report.details': 'It\'s not a problem that you didn\'t pull your credit report, but knowing how you view your credit and debt situation will help us in your coaching session.',
  'app.household-debt-self-evaluation.question': 'Which of the following statements describes how manageable your household debt is (including mortgages, student loans, bank loans, money owed to people, medical debt, credit cards and past-due bills)?',
  'app.household-debt-self-evaluation.answer.': 'Please select from the following',
  'app.household-debt-self-evaluation.answer.Do not have any debt': 'Do not have any debt',
  'app.household-debt-self-evaluation.answer.Have a manageable amount of debt': 'Have a manageable amount of debt',
  'app.household-debt-self-evaluation.answer.Have a bit more debt than is manageable': 'Have a bit more debt than is manageable',
  'app.household-debt-self-evaluation.answer.Have far more debt than is manageable': 'Have far more debt than is manageable',
  'app.credit-self-evaluation.question': 'How would you rate your credit score? Your credit score is a number that tells lenders how risky or safe you are as a borrower.',
  'app.credit-self-evaluation.answer.': 'Please select from the following',
  'app.credit-self-evaluation.answer.Excellent': 'Excellent',
  'app.credit-self-evaluation.answer.Very Good': 'Very Good',
  'app.credit-self-evaluation.answer.Good': 'Good',
  'app.credit-self-evaluation.answer.Fair': 'Fair',
  'app.credit-self-evaluation.answer.Poor':'Poor',
  'app.credit-self-evaluation.answer.I do not know':'I don\'t know',

  'app.additional-questions.covid-questions.describe-your-savings' : 'How have your savings changed in the past 12 months?',
  'app.additional-questions.covid-questions.do-you-agree.i-have-a-plan' : 'To what extent do you agree with the following statement: I have a plan for navigating the financial impact the Corona virus has had on me.',
  'app.additional-questions.covid-questions.describe-typical-annual-income': 'How much do you (individually) typically make in a year?',
  'app.additional-questions.covid-questions.describe-family-earnings': 'How have your family\'s earnings changed in the last 12 months?',
  'app.additional-questions.covid-questions.describe-current-or-most-recent-industry': 'Which of the following describes the industry of your current or most recent job?',
  'app.additional-questions.covid-questions.what-assistance-have-you-received': 'What sources of assistance have you received?',

  'app.second-chance-report.title': 'Almost done.',
  'app.second-chance-report.continue.title': 'Your financial profile: ',
  'app.second-chance-report.percent.complete-text': 'complete',
  'app.second-chance-report.counselor.bubble.text': 'Just one step to go. Pull your credit report to see a summary of your debts and discuss your credit questions with your coach.',
  'app.second-chance-report.pull.report.button': 'Pull credit report',
  'app.second-chance-report.skip.button': 'Skip for now',
  'app.second-chance-report.skip.credit.report.forever': 'I do not want to pull my credit. Please do not show me this message again.',
  'app.no-employer-code.title': 'TrustPlus',
  'app.no-employer-code.message.english': 'Thank you for accessing TrustPlus. The URL you entered is either missing a code for your employer, or incorrect. Please contact your employer\'s administrator or email {email} to receive the correct link.',
  'app.no-employer-code.message.spanish': 'Gracias por acceder a TrustPlus. La dirección que usted a ingresado no tiene el código para su empleador o es incorrecta. Por favor comuníquese con el administrador de su empleador o envíe un correo electrónico a {email} para recibir el enlace correcto.',
  'app.message.contact.create': 'It looks like you have not met with a financial coach yet. We encourage you to first schedule a session to discuss your goals and create a personalized financial action plan. Click {alternativeSchedulerLink} or email {supportEmail} if you have questions or concerns.',
  'app.errors.contact.create': 'An account with this information already exists. For further assistance call {tollfreePhone} or email {supportEmail}.',
  'app.errors.contact.create.link.external.self-scheduler.display': 'this link',
  'app.errors.contact-exists': 'An account with this information already exists. Please {loginLink} to continue, or contact us for further assistance: {tollfreePhone} or {supportEmail}.',
  'app.errors.contact-exists.login' : 'login',
  'app.errors.contact-not-found': 'We don\'t recognize your phone number. Please try again or check with your financial coach.',
  //error displays:
  'app.errors.provide.info': 'Please provide the following information: ',
  'app.errors.firstName.required': 'Please enter first name',
  'app.errors.firstName.required.notice': 'First name',
  'app.errors.lastName.required': 'Please enter last name',
  'app.errors.lastName.required.notice': 'Last name',
  'app.errors.email.required': 'Please enter email address',
  'app.errors.email.required.notice': 'Email address',
  'app.errors.inquiry.required': 'Please enter your question',
  'app.errors.inquiry.required.notice': 'Your question',
  'app.errors.email.invalidFormat': 'Please enter a valid email address (name@example.com)',
  'app.errors.email.invalidFormat.notice': 'Email address',
  'app.errors.agreedToTerms.required': 'Please agree to proceed',
  'app.errors.agreedToTerms.required.notice': 'Agree to terms of service & privacy policy',
  'app.errors.videoChatService.required': 'A video chat service is required',
  'app.errors.videoChatService.required.notice': 'Please select a video chat service',
  //appointment-schedule.jsx
  'app.errors.mobilePhone.required': 'Please enter your cell phone number',
  'app.errors.mobilePhone.required.notice': 'Cell phone number',
  'app.errors.mobilePhone.invalidFormat': 'Cell phone number is an invalid format',
  'app.errors.mobilePhone.invalidFormat.notice': 'Cell phone number',
  //credit-report-form.jsx :
  'app.errors.street.required': 'Please enter your home address',
  'app.errors.street.required.notice': 'Home street address',
  'app.errors.city.required': 'Please enter your city',
  'app.errors.city.required.notice': 'City',
  'app.errors.state.required': 'Please enter your state',
  'app.errors.state.required.notice': 'State',
  'app.errors.state.invalidFormat': 'State is an invalid format',
  'app.errors.state.invalidFormat.notice': 'State',
  'app.errors.zipCode.required': 'Please enter your zip code',
  'app.errors.zipCode.required.notice': 'Zip code',
  'app.errors.zipCode.invalidFormat': 'Zip code is an invalid format',
  'app.errors.zipCode.invalidFormat.notice': 'Zip code',
  'app.errors.birthdate.required': 'Please enter your birthdate',
  'app.errors.birthdate.required.notice': 'Date of birth',
  'app.errors.birthdate.invalidFormat': 'Birthdate is an invalid format',
  'app.errors.birthdate.invalidFormat.notice': 'Date of birth',
  'app.errors.birthdate.notOfAge': ' ',
  'app.errors.birthdate.notOfAge.notice': 'In order to use Neighborhood Trust\'s services, you must be 18 years of age or older. For more information, please review our {externalTermsOfSvc}. For questions, please email {mailToSupport} or call {tollfreePhone}.',
  'app.errors.birthdate.isToOld': 'The birthdate entered is more than 110 years in the past. Please review and correct the year of birth.',
  'app.errors.birthdate.isToOld.notice': 'The birthdate entered is more than 110 years in the past.',
  'app.errors.ssn.required': 'Please enter your social security number or ITIN',
  'app.errors.ssn.required.notice': 'Social Security number or ITIN',
  'app.errors.ssn.invalidFormat': 'Social Security number or ITIN is an invalid format',
  'app.errors.ssn.invalidFormat.notice': 'Social Security number or ITIN',
  'app.errors.interestRate' : 'Interest Rate Error',
  'app.errors.interestRate.notice' : 'The interest rate must be a number',
  'app.errors.contact.update': 'It was not possible to obtain your credit report. Your coach will follow up on your appointment.',

  //client-stories.jsx form
  'app.errors.preferredName.required': 'Please enter preferred name',
  'app.errors.preferredName.required.notice': 'Preferred Name',
  'app.errors.signature.required': 'Please enter a signature',
  'app.errors.signature.required.notice': 'Signature',


  //customer-portal
  'app.errors.customerSignedName.required': 'Please type your full name in the box.',
  'app.errors.customerSignedName.required.notice': 'Signature',
  'app.errors.customerTitle.required': 'Please type your job title in the box.',
  'app.errors.customerTitle.required.notice': 'Job Title',
  'app.errors.customerAuthorization.required': 'Please check the authorized to sign this contract checkbox.',
  'app.errors.customerAuthorization.required.notice': 'Authorization',

  'app.learn-more.title': 'Frequently Asked Questions',
  'app.learn-more.question.1': 'What is TrustPlus?',
  'app.learn-more.answer.1a': 'TrustPlus is a financial wellness benefit that will help you build credit, reduce debt, and reach your financial goals. We are coaches that  provide one-on-one support and expert guidance- with no judgment, and are focused solely on improving your financial security. We do not sell financial products and never earn commissions.',
  'app.learn-more.answer.1b': 'TrustPlus is a service of Neighborhood Trust Financial Partners, a mission-driven, non-profit organization. For more than 20 years, we have helped more than 60,000 U.S. workers improve their credit scores, reduce their debt, and more. You can learn more about Neighborhood Trust and it’s services {neighborhoodtrusthome}.',
  'app.learn-more.question.2': 'How can TrustPlus help?',
  'app.learn-more.answer.2': 'TrustPlus can help address such issues as:',
  'app.learn-more.answer.2a': 'Building credit so that you can qualify for the best credit cards, loans, or mortgage',
  'app.learn-more.answer.2b': 'Reducing troublesome debt from payday loans and collections accounts to medical debt, student loan debt, and credit cards',
  'app.learn-more.answer.2c': 'Budgeting and building a safety net for emergencies',
  'app.learn-more.question.3': 'How does financial coaching work?',
  'app.learn-more.answer.3': 'Financial coaching provides a safe space for you to work towards your financial goals. During your coaching session, you will get to know your financial coach, share your story, provide some more details about your financial situation, and work together with your coach to build an action plan. You will also review your credit report and score. Your coach can also provide tools and support in reducing stress over your finances or tackling your burning financial questions. Sessions can be conducted via phone or video chat.',
  'app.learn-more.question.4': 'Will authorizing TrustPlus to pull my credit report hurt my credit score?',
  'app.learn-more.answer.4': 'No, we will do a “soft pull”, which has no effect on your credit report and score.',
  'app.learn-more.question.5': 'What happens after I meet with my financial coach?',
  'app.learn-more.answer.5': 'With TrustPlus, you get 12 months of access to the help you need—at no cost to you. As you work to reach your goals, your coach will continue to offer resources and guidance to support you, and you can contact them at any time. Connect as often or as little as needed via phone, video, chat or text.',
  'app.learn-more.question.6': 'Once I sign up for coaching, will I continue to have the same financial coach?',
  'app.learn-more.answer.6': 'As a client, you will have access to a team of financial coaches who are dedicated to helping improve your financial wellness and that of your colleagues. If the coach you choose is not available when you need them, our team of coaches will be there to support you. They are all trained in our unique methods to make sure you can success in reaching your goals. We will do our best to honor your preferences, but coach availability will vary.',
  'app.learn-more.question.7': 'What makes TrustPlus financial coaching different from other financial coaching and advising services?',
  'app.learn-more.question.7.1-heading': 'A Personalized Approach',
  'app.learn-more.answer.7.1': 'Unlike some financial coaching or coaching agencies, TrustPlus coaches will work with you individually to learn more about the goals you want to achieve and the unique circumstances of your financial situation and then partner with you in creating an action plan that is specific, relevant, and achievable for you. At other agencies, you might be taught a bunch of financial topics or be given a general laundry list of to-dos. And with other financial apps, your advice might come from algorithms and artificial intelligence. A combination of people and technology, TrustPlus reinforces our human guidance with relevant and safe financial products and tools to make our recommendations real, easier to adopt, and to help you make the most of every hard-earned paycheck.',
  'app.learn-more.question.7.2-heading': 'Trustworthy',
  'app.learn-more.answer.7.2': 'Unlike many financial advisors or financial companies, TrustPlus has your best interest at heart. Coaches will listen to you and provide guidance based on what is right for you and your family. We do not sell products or receive commissions. As a nonprofit, we’re unbiased and entirely motivated by your success and the changes that you will experience in your life after working with our coaches.',
  'app.learn-more.question.7.3-heading': 'Technology and Convenience',
  'app.learn-more.answer.7.3': 'TrustPlus coaches understand that you are busy and have limited time and energy to devote to working on your finances. Therefore, TrustPlus uses simple and user-friendly technology that will allow you to do everything you need at a time and place that is convenient to you. Individual coaching sessions are conducted via phone or video chat; and personalized financial action plans are delivered through an interactive online platform that you can access 24 hours a day. And to further assist you in staying on track among all of your responsibilities of daily life, TrustPlus will send you reminders based on the action items that you created with your financial coach.',
  'app.learn-more.question.8': 'How is TrustPlus different from credit repair or debt settlement services?',
  'app.learn-more.answer.8': 'Credit repair companies often charge upwards of $100/month to dispute errors on your credit report. This is something that you could do yourself for free, and our coaches arm you with the necessary tools to do so. Debt settlement companies promise to eliminate your debt burden, but it comes at a high price as your credit is often damaged during the process. They typically charge a fee of 25% of the settled debt. As with disputing errors on your credit report, this is something you can do yourself for free, and our coaches re here to provide the guidance and support throughout the process. ',
  'app.learn-more.question.9': 'Is my personal information secure? ',
  'app.learn-more.answer.9': 'We know security and privacy are important to you – and they are important to us, too. We make it a priority to provide strong security and give you confidence that your information is safe at all times. We use end-to-end Encryption technology such as Secure Sockets Layer (SSL) encryption, which helps to protect personal information in all areas of our website. Your information is GoDaddy SSL certified, one of the highest guarantees of privacy protection in the industry. And we don’t share your personal financial information with your employer or any third parties.',
  'app.learn-more.question.10': 'Can my family members and friends sign up for the service?',
  'app.learn-more.answer.10': 'TrustPlus’s financial coaching services are available as a benefit of your workplace or a membership in one of our partner organizations. Your significant other who participates in the financial decision-making in your home is welcome to be part of your TrustPlus coaching session over phone or video chat. For other friends and family, we are happy to help you find the right financial coaching services. Please ask your financial coach for more information.',
  'app.learn-more.question.11': 'Can I download the TrustPlus app to my phone?',
  'app.learn-more.answer.11': 'At this time we do not have a downloadable app. In order to make sure the service can be accessed wherever you are most comfortable, we have designed a web-based app that can be accessed with any smartphone, computer or tablet, and with most browsers.',
  'app.learn-more.question.12': 'What are the hours that coaches are available?',
  'app.learn-more.answer.12a': '9:00am - 9:00pm ET Monday through Thursday',
  'app.learn-more.answer.12b': '9:00am- 5:00pm ET Friday & Saturday',
  'app.learn-more.question.13': 'Who should I contact if I am having trouble accessing my account?',
  'app.learn-more.answer.13': 'If you are having trouble getting started or need more help, contact us at {supportEmail}.',
  'app.learn-more.question.14': 'How will TrustPlus access my credit report if my credit files are frozen?',
  'app.learn-more.answer.14a': 'If your credit files are frozen, TrustPlus may not be able to access your credit report. A credit freeze prevents credit reporting agencies from releasing your credit report to potential creditors, and coaching agencies that includes TrustPlus. ',
  'app.learn-more.answer.14b': 'However, if you want to review your credit report with TrustPlus while your credit files are frozen, you can temporarily lift the freeze with the credit reporting agencies. You will need to contact each credit bureau individually to request a temporary lift of the freeze, and they may require you to provide some information to verify your identity. Once the freeze is lifted, TrustPlus can access your credit report. ',
  'app.learn-more.question.15': 'What should I do if I am having trouble logging into my account?',
  'app.learn-more.answer.15a': 'If you are having touble logging into your account, here are some steps you can take to try and resolve the issue:',
  'app.learn-more.answer.15b': '1. Check your login credentials: Make sure you are entering the correct username and password. Check for typos, extra spaces, or capitalized letters that shouldn\'t be.',
  'app.learn-more.answer.15c': '2. Reset your password: If you can\'t remember your password, please utilize our "forgot password" option that will guide you through the process of resetting it.',
  'app.learn-more.answer.15d': '3. Clear your browser\'s cache and cookies: Sometimes login issues cna be caused by old or corrupted data storedin your browser\'s cache and cookies. Clearing them can often help resolve the issue.',
  'app.learn-more.answer.15e': '4. Try a different browser: If clearing your cache and cookies doesn\'t work, try using a different browser to see if the problem is specific to the one you were using. ',
  'app.learn-more.answer.15f': '5. Contact Customer support: If you\'ve tried all of the above steps and are still unable to log in, contact our customer support team for further assitance at support@mytrustplus.org. They may be able to identify and resolve the issue. ',
  'app.learn-more.question.16': 'How can I make sure that the call from my financial coach will pass the spam filters?',
  'app.learn-more.answer.16a': 'If you are expecting a call from your financial coach and are concerned that it might be flagged as spam or blocked by your phone\'s filters, there are a few things you can do to ensure that the call goes through:',
  'app.learn-more.answer.16b': '1. Add the coach\'s phone number to your contacts: By adding your coach\'s phone number to your contacts, you are telling your phone that this is a trusted number, and that calls from this number should be allowed through.',
  'app.learn-more.answer.16c': '2. Check your phone\'s blocked numbers list: Make sure that your coach\'s phone number is not on your phone\'s blocked numbers list. If it is, remove it from the list to allow the call to come through.',
  'app.learn-more.answer.16d': '3. Disable spam call blocking: Some phones have built-in spam call blocking features that can sometimes block legitimate calls. Check your phone\'s settings to see if this feature is turned on, and disable it temporarily if necessary.',
  'app.learn-more.answer.16e': '4. Contact your phone carrier: If you continue to have problems receiving calls from your financial coach, contact your phone carrier to see if they are blocking the calls. They may be able to add the number to a whitelist to allow it through.',
  'app.learn-more.answer.16f': '5. Provide an alternate number: If all else fails, consider providing an alternate number to your financial coach, such as a work number or a friend\'s phone, to ensure that you receive the call.',
  'app.learn-more.answer.16g': 'By taking these steps, you can help ensure that you receive the important call from your financial coach and avoid any potential issues with spam filters or call blocking.',
  'app.learn-more.question.17': 'What should I do if I can\'t find an available coach?',
  'app.learn-more.answer.17': 'Please consider using our "Ask a Coach" feature or emailing our support team at support@mytrustplus.org to speak with someone that can help. ',
  'app.learn-more.nytimes': 'The New York Times',
  'app.learn-more.vice': 'Vice',
  'app.learn-more.nprplanetmoney': 'NPR Planet Money',
  'app.learn-more.nycgov': 'NYC.gov',
  'app.learn-more.neighborhoodtrusthome':'here',

  'app.action-plan-nav.welcome-name': 'Welcome, ',
  'app.action-plan-nav.take-action-today': 'Take Action Today',
  'app.action-plan-nav.debt-summary': 'Debt Summary',
  'app.action-plan-nav.fico-score': 'FICO® Score',
  'app.action-plan-nav.budget': 'Budget',
  'app.action-plan-nav.sessions': 'Sessions',
  'app.action-plan-nav.messages': 'Messages',
  'app.action-plan-nav.link-money': 'Link Accounts',
  'app.action-plan-nav.link-money-category-chart': 'Link Money Category Chart',
  'app.action-plan-nav.link-money-accounts': 'Link Money Accounts',
  'app.action-plan-nav.link-money-transactions': 'Link Money Transactions',
  'app.action-plan-nav.espanol': 'Español',
  'app.action-plan-nav.my-account': 'My Account',
  'app.action-plan-nav.sign-out': 'Sign Out',
  'app.action-plan-nav.terms-of-service': 'Terms of Service',
  'app.action-plan-nav.and': ' and ',
  'app.action-plan-nav.privacy-policy': 'Privacy Policy',
  'app.action-plan-nav.feedback': 'Feedback',
  'app.action-plan.questions.save-and-next': 'Save and next',

  'app.action-plan-dashboard.my-sessions':'Upcoming Sessions',
  'app.action-plan-dashboard.button.view-sessions': 'View Sessions',
  'app.action-plan-dashboard.settings':'Settings',
  'app.action-plan-dashboard.my-profile':'My Profile',
  'app.action-plan-dashboard.status': 'Status:',
  'app.action-plan-dashboard.status-incomplete': 'Incomplete',
  'app.action-plan-dashboard.status-review-your-responses': 'Complete',
  'app.action-plan-dashboard.status-complete': 'Complete',
  'app.action-plan-dashboard.button.complete-profile': 'Edit your profile',
  'app.action-plan-dashboard.button.see-profile': 'See all Settings',
  'app.action-plan-dashboard.button.see-dashboard': 'See my dashboard',
  'app.action-plan-dashboard.link-accounts.unlinked':'NEW! Link Accounts',
  'app.action-plan-dashboard.link-accounts.description-1.unlinked':'When you ',
  'app.action-plan-dashboard.link-accounts.description-link.unlinked': 'link your accounts',
  'app.action-plan-dashboard.link-accounts.description-2.unlinked': ', your financial coach can better support you on your journey to get out of debt and achieve your dreams.',
  'app.action-plan-dashboard.link-accounts.linked': 'Your Linked Accounts',
  'app.action-plan-dashboard.link-accounts.description-1.linked':'Thank you for linking your accounts! If you\'d like to link additional accounts, visit the ',
  'app.action-plan-dashboard.link-accounts.description-link.linked': 'Link Accounts',
  'app.action-plan-dashboard.link-accounts.description-2.linked': ' tab.',
  'app.action-plan-dashboard.link-accounts.button.linked': 'Link more accounts',
  'app.action-plan-dashboard.dcl.title': 'Debt Consolidation: Next Steps',
  'app.action-plan-dashboard.dcl.description': 'In your first session with your financial coach, you will review your pre-screener responses and credit report, and then discuss your debt consolidation loan options. From there, you will work together on a plan of action.',
  'app.action-plan-dashboard.dcl.header-title': 'Your journey to debt consolidation starts here',
  'app.action-plan-dashboard.dcl.header-description': 'Congratulations on taking important steps toward your debt consolidation journey! Your financial coach is looking forward to working with you.',

  'app.action-plan-take-action-today.page-title': 'Take Action Today',
  'app.action-plan-take-action-today.to-do': 'To Do',
  'app.action-plan-take-action-today.achieved': 'Achieved',
  'app.action-plan-take-action-today.no-current-to-do-items': 'There are no current To Do items.',
  'app.action-plan-take-action-today.no-completed-items': 'There are no completed items.',
  'app.action-plan-take-action-today.overview': 'Overview:',
  'app.action-plan-take-action-today.work-goals': 'Work toward my goals',
  'app.action-plan-take-action-today.how-to-achieve': 'How to achieve:',
  'app.action-plan-take-action-today.due-date' : 'Due Date: ',
  'app.action-plan-take-action-today.achieved-date' : 'Achieved on: ',
  'app.action-plan-take-action-today.mark-as-achieved' : 'Mark as Achieved',
  'app.action-plan-take-action-today.create' : 'Create an action step',
  'app.action-plan-take-action-today.create-submit' : 'Finish Creating',
  'app.action-plan-take-action-today.cancel' : 'Cancel',
  'app.action-plan-take-action-today.create-title': 'New action step',
  'app.action-plan-take-action-today.input.goal-comment' : 'Your action step',
  'app.action-plan-take-action-today.input.goal-comment.placeholder': 'One short sentence for yourself on what you should do. This will be sent to you as a reminder.',
  'app.action-plan-take-action-today.input.due-date' : 'Due date',
  'app.action-plan-take-action-today.input.how-accomplish' : 'How to achieve',
  'app.action-plan-take-action-today.input.how-accomplish.placeholder': 'Add some more details about how you plan to achieve this action. What smaller steps will you take?',
  'app.action-plan-take-action-today.input.motivation' : 'Motivation (optional)',
  'app.action-plan-take-action-today.input.motivation.placeholder': 'What will this action help you to accomplish? How will this impact your long-term goal?',
  'app.errors.tatGoalComment.required' : 'An action step is required',
  'app.errors.tatGoalDueDate.required' : 'A due date is required',
  'app.errors.tatHowToAccomplish.required' : 'How to accomplish is required',
  'app.errors.tatGoalDueDate.invalidFormat': 'Due Date is an invalid format',
  'app.errors.tatGoalDueDate.invalidFormat.notice': 'Due Date',
  'app.errors.tatGoalDueDate.inThePast': 'Due date must be in the future',
  'app.errors.tatGoalDueDate.inThePast.notice': 'Due Date',

  'app.take-action-today-tat-goal-purpose.Establish Credit': 'Establish Credit',
  'app.take-action-today-tat-goal-purpose.Manage My Debt': 'Manage My Debt',
  'app.take-action-today-tat-goal-purpose.Organize My Spending': 'Organize My Spending',
  'app.take-action-today-tat-goal-purpose.Use Financial Services Wisely': 'Use Financial Services Wisely',
  'app.take-action-today-tat-goal-purpose.Work Towards My Goals': 'Work Towards My Goals',
  'app.take-action-today-tat-goal-purpose.Consider Bankruptcy': 'Consider Bankruptcy',
  'app.take-action-today-tat-goal-purpose.Improve My Credit': 'Improve My Credit',
  'app.take-action-today-tat-goal-purpose.Increase My Savings': 'Increase My Savings',
  'app.take-action-today-tat-goal-purpose.Find Money By Spending Less': 'Find Money By Spending Less',

  'app.take-action-today-tat-goal-action.Apply for a credit builder product':'Apply for a credit builder product',
  'app.take-action-today-tat-goal-action.Apply for an ITIN':'Apply for an ITIN',
  'app.take-action-today-tat-goal-action.Apply for a Self Lender account':'Apply for a Self Lender account',
  'app.take-action-today-tat-goal-action.Apply for the Trust Card':'Apply for the Trust Card',
  'app.take-action-today-tat-goal-action.Close high-cost account/financial service':'Close high-cost account/financial service',
  'app.take-action-today-tat-goal-action.Collect necessary documentation':'Collect necessary documentation',
  'app.take-action-today-tat-goal-action.Complete my budget':'Complete my budget',
  'app.take-action-today-tat-goal-action.Contact bankruptcy professional':'Contact bankruptcy professional',
  'app.take-action-today-tat-goal-action.Contact creditor or service provider to gather more information':'Contact creditor or service provider to gather more information',
  'app.take-action-today-tat-goal-action.Contact creditor to gather more information':'Contact creditor to gather more information',
  'app.take-action-today-tat-goal-action.Contact creditor to negotiate better terms on my debt':'Contact creditor to negotiate better terms on my debt',
  'app.take-action-today-tat-goal-action.Cut back on specific expenses':'Cut back on specific expenses',
  'app.take-action-today-tat-goal-action.Enroll in 401k/403b/ IRA/ retirement savings':'Enroll in 401k/403b/ IRA/ retirement savings',
  'app.take-action-today-tat-goal-action.Enroll in a debt management plan':'Enroll in a debt management plan',
  'app.take-action-today-tat-goal-action.Enroll in Direct Deposit':'Enroll in Direct Deposit',
  'app.take-action-today-tat-goal-action.Evaluate bankruptcy as an option for you':'Evaluate bankruptcy as an option for you',
  'app.take-action-today-tat-goal-action.Follow-up with my financial advisor':'Follow-up with my financial advisor',
  'app.take-action-today-tat-goal-action.Gather information or materials to satisfy service provider or creditor':'Gather information or materials to satisfy service provider or creditor',
  'app.take-action-today-tat-goal-action.Gather my bills and statements':'Gather my bills and statements',
  'app.take-action-today-tat-goal-action.Learn more about my goal':'Learn more about my goal',
  'app.take-action-today-tat-goal-action.Mail letters':'Mail letters',
  'app.take-action-today-tat-goal-action.Make a $ payment to a specific debt':'Make a $ payment to a specific debt',
  'app.take-action-today-tat-goal-action.Make changes to the way I pay bills':'Make changes to the way I pay bills',
  'app.take-action-today-tat-goal-action.Meet with a specialist':'Meet with a specialist',
  'app.take-action-today-tat-goal-action.Meet with my financial advisor':'Meet with my financial advisor',
  'app.take-action-today-tat-goal-action.Negotiate better terms on my debt':'Negotiate better terms on my debt',
  'app.take-action-today-tat-goal-action.Open a 529 College Savings Account':'Open a 529 College Savings Account',
  'app.take-action-today-tat-goal-action.Open an account with a trusted financial institution':'Open an account with a trusted financial institution',
  'app.take-action-today-tat-goal-action.Open a savings account or product':'Open a savings account or product',
  'app.take-action-today-tat-goal-action.Other':'Other',
  'app.take-action-today-tat-goal-action.Put away $ on a regular basis':'Put away $ on a regular basis',
  'app.take-action-today-tat-goal-action.Refinance my debt - consolidation loan, balance transfer card, etc.':'Refinance my debt - consolidation loan, balance transfer card, etc.',
  'app.take-action-today-tat-goal-action.Register for a class or seminar':'Register for a class or seminar',
  'app.take-action-today-tat-goal-action.Request additional reports (i.e. ACR)':'Request additional reports (i.e. ACR)',
  'app.take-action-today-tat-goal-action.Save $ in order to negotiate a settlement':'Save $ in order to negotiate a settlement',
  'app.take-action-today-tat-goal-action.Save for associated costs':'Save for associated costs',
  'app.take-action-today-tat-goal-action.Save up $ for deposit on a secured loan or card':'Save up $ for deposit on a secured loan or card',
  'app.take-action-today-tat-goal-action.Seek additional assistance from a specialist':'Seek additional assistance from a specialist',
  'app.take-action-today-tat-goal-action.Separate my business and personal expenses':'Separate my business and personal expenses',
  'app.take-action-today-tat-goal-action.Set up auto bill-pay':'Set up auto bill-pay',
  'app.take-action-today-tat-goal-action.Set up automatic transfer of funds':'Set up automatic transfer of funds',
  'app.take-action-today-tat-goal-action.Sign up for automatic transfer of funds':'Sign up for automatic transfer of funds',
  'app.take-action-today-tat-goal-action.Sign up for FREE credit monitoring service (credit karma, etc.)':'Sign up for FREE credit monitoring service (credit karma, etc.)',
  'app.take-action-today-tat-goal-action.Sign up for online banking':'Sign up for online banking',
  'app.take-action-today-tat-goal-action.Split my direct deposit':'Split my direct deposit',
  'app.take-action-today-tat-goal-action.Take advantage of free or low-cost services':'Take advantage of free or low-cost services',
  'app.take-action-today-tat-goal-action.Track my spending':'Track my spending',
  'app.take-action-today-tat-goal-action.Use in-network ATMs to reduce fees':'Use in-network ATMs to reduce fees',

  'app.action-plan-debt-summary.page-title': 'Debt Summary',
  'app.action-plan-debt-summary.subtitle': 'Here’s your total debt according to your credit report pulled on ',
  'app.action-plan.debt-summary.trade-account-counselor-text': 'We can help you to understand what these details mean, and together we will make a plan to tackle your debt.',
  'app.action-plan-debt-summary.waiting-subtitle': 'Give us time!',
  'app.action-plan-debt-summary.waiting-text': 'If you gave us permission to access your credit it will take 1 or 2 minutes in most cases for your information to appear here.',
  'app.action-plan-debt-summary.permission-subtitle': 'You’re missing out!',
  'app.action-plan-debt-summary.permission-text': 'We can’t pull your debt summary or show your FICO® Score without getting permission to pull your credit report.',
  'app.action-plan-debt-summary.permission-text-transunion': 'We can’t pull your debt summary without getting permission to pull your credit report.',
  'app.action-plan-debt-summary.permission-button': 'Give us permission',
  'app.action-plan-debt-summary.permission-text-2': 'Please note that it may take up to 48 hours after giving us permission for your information to appear.',
  'app.action-plan-debt-summary.key': 'KEY',
  'app.action-plan-debt-summary.category': 'CATEGORY',
  'app.action-plan-debt-summary.balance': 'BALANCE',
  'app.action-plan-debt-summary.monthly-payment': 'MINIMUM PAYMENT',
  'app.action-plan-debt-summary.detail.back-to-overview': 'Back to overview',
  'app.action-plan-debt-summary.detail.link': 'Details',
  'app.action-plan-debt-summary.more.link': 'More',
  'app.action-plan-debt-summary.less.link': 'Less',
  'app.action-plan-debt-summary.detail.type-of-account': 'Type of account',
  'app.action-plan-debt-summary.detail.status-of-account': 'Status of account',
  'app.action-plan-debt-summary.detail.balance': 'Balance',
  'app.action-plan-debt-summary.detail.credit-limit': 'Credit limit',
  'app.action-plan-debt-summary.detail.min-payment': 'Min. payment',
  'app.action-plan-debt-summary.detail.open-date': 'Open date',
  'app.action-plan-debt-summary.detail.last-activity-date': 'Last activity date',
  'app.action-plan-debt-summary.detail.original-creditor': 'Original creditor',
  'app.action-plan-debt-summary.detail.account-ownership-type' : 'Account ownership',
  'app.action-plan-debt-summary.detail.account-interest-rate' : 'Interest rate',
  'app.action-plan-debt-summary.detail.account-interest-rate-message' : 'Interest rates aren’t on credit reports. We can advise better how to pay down debt with it!',
  'app.action-plan-debt-summary.detail.Automobile': 'Automobile',
  'app.action-plan-debt-summary.detail.Collection': 'Collection',
  'app.action-plan-debt-summary.detail.CreditCard': 'Credit Card',
  'app.action-plan-debt-summary.detail.Educational': 'Educational',
  'app.action-plan-debt-summary.detail.InstallmentLoan': 'Installment Loan',
  'app.action-plan-debt-summary.detail.Other' : 'Other',
  'app.action-plan-debt-summary.detail.Lease': 'Lease',
  'app.action-plan-debt-summary.detail.Mortgage': 'Mortgage',
  'app.action-plan-debt-summary.detail.UnknownLoanType': 'Unknown Loan Type',
  'app.action-plan-debt-summary.detail.AsAgreed': 'As Agreed',
  'app.action-plan-debt-summary.detail.ChargeOff': 'Charge Off',
  'app.action-plan-debt-summary.detail.Late30Days': 'Late 30 Days',
  'app.action-plan-debt-summary.detail.Late60Days': 'Late 60 Days',
  'app.action-plan-debt-summary.detail.Late90Days': 'Late 90 Days',
  'app.action-plan-debt-summary.detail.LateOver120Days': 'Late over 120 Days',
  'app.action-plan-debt-summary.detail.BankruptcyOrWageEarnerPlan': 'Bankruptcy',
  'app.action-plan-debt-summary.detail.Repossession': 'Reposession',
  'app.action-plan-debt-summary.detail.NoDataAvailable': 'No Data Available',
  'app.action-plan-debt-summary.detail.Paid': 'PAID',
  'app.action-plan-debt-summary.detail.Closed': 'CLOSED',
  'app.action-plan-debt-summary.detail.Open': 'OPEN',
  'app.action-plan-debt-summary.detail.Frozen': 'FROZEN',
  'app.action-plan-debt-summary.detail.Refinanced': 'REFINANCED',
  'app.action-plan-debt-summary.detail.Transferred': 'TRANSFERRED',
  'app.action-plan-debt-summary.detail.Individual': 'Individual',
  'app.action-plan-debt-summary.detail.AuthorizedUser': 'Authorized User',
  'app.action-plan-debt-summary.detail.JointContractualLiability': 'Co-Signer',
  'app.action-plan-debt-summary.Paid': 'PAID',
  'app.action-plan-debt-summary.Closed': 'CLOSED',
  'app.action-plan-debt-summary.counselor.unfamiliar-advice': 'If something looks unfamiliar here, we can help investigate the account and provide clarity on how to address it.',
  'app.action-plan-debt-summary.counselor.no-score': 'Not having a score does not mean that you have bad credit. You may be new to the U.S., young, or just new to credit in general, and that’s okay. But without a credit history, it could be a challenge to get a credit card, a loan, and in some cases even an apartment or a phone contract. It can take some time to build your credit score, but with responsible and consistent credit use, you will be able to build your score. We can help you understand the best options for you to help you establish credit.',
  'app.action-plan-debt-summary.no-data': 'We don\'t see any credit cards or loans on your report.',
  'app.action-plan-debt-summary.detail.information-correct': 'Does the information above look correct?',
  'app.action-plan-debt-summary.detail.information-correct-no': 'No',
  'app.action-plan-debt-summary.detail.information-correct-yes': 'Yes',
  'app.action-plan-debt-summary.detail.confirmed-account': 'Thanks for confirming this information !',
  'app.action-plan-debt-summary.detail.undo-confirmation-link' : 'Undo confirmation',
  'app.action-plan-debt-summary.detail.flagged-account' : 'You’ve flagged this account because something was incorrect.',
  'app.action-plan-debt-summary.detail.remove-flag-link' : 'Remove flag',
  'app.action-plan-debt-summary.detail.balance-exceeds-limit': 'Balance exceeds limit',

  'app.action-plan-fico-score.page-title': 'FICO® Score',
  'app.action-plan-fico-score.transunion1': 'Your FICO® Score 4 based on TransUnion® Data is the same score that TrustPlus pulled on ',
  'app.action-plan-fico-score.transunion2': ' and is provided for your own non-commercial personal review and benefit.',
  'app.action-plan-fico-score.desc-kim-title': 'Key Factors affecting your FICO® Score',
  'app.action-plan-fico-score.desc-insufficient-title': 'The Profile report does not contain sufficient credit information',
  'app.action-plan-fico-score.desc-insufficient-text': 'Unfortunately, we do not have a FICO® Score to display at this time because your file does not contain the minimum information necessary.  Your credit report must contain information from at least one account that has been open for a 6 month period',
  'app.action-plan-fico-score.keep-in-mind': 'Keep in mind: ',
  'app.action-plan-fico-score.credit-education-link': 'Click for more credit education.',
  'app.action-plan-fico-score.disclaimer-title': 'FICO® Score Disclaimer: ',
  'app.action-plan-fico-score.disclaimer1': 'The FICO® Score we provide may be different from other credit scores. FICO® Scores and educational content intended only for your personal educational review purposes. Lenders may use various inputs like a FICO® Score, other credit scores and other information in credit decisions. The credit file used to create your FICO® Score is continually updated, and your FICO® Score above may not reflect the most current data on your credit file. To request a copy of your credit report please visit: ',
  'app.action-plan-fico-score.disclaimer2': 'http://www.annualcreditreport.com',
  'app.action-plan-fico-score.disclaimer3': 'The person and/or organization providing this report and Fair Isaac are not "credit repair" organizations as defined under federal or state law, including the Credit Repair Organizations Act.',
  'app.action-plan-fico-score.disclaimer4': 'FICO® and “The score lenders use” are registered trademarks of Fair Isaac Corporation in the United States and other countries.',
  'app.action-plan-fico-score.disclaimer5': 'Fair Isaac does not guarantee the accuracy of information input by the coach.',

  'app.action-plan-sessions.page-title': 'My Sessions',
  'app.action-plan-sessions.upcoming': 'Upcoming',
  'app.action-plan-sessions.previous': 'Previous',
  'app.action-plan-sessions.no-upcoming': 'No upcoming sessions',
  'app.action-plan-sessions.no-previous': 'No previous sessions',
  'app.action-plan-sessions.on': ' on ',
  'app.action-plan-sessions.selected-session': 'Your selected session.',
  'app.action-plan-sessions.my-session': 'I would like to have my session',
  'app.action-plan-nav.button.schedule': 'Schedule session',
  'app.action-plan-sessions.no-appointments.description' : 'There are no available times at the moment. Please check back shortly.',
  'app.action-plan-nav.button.go-back': '< Go back',

  'app.action-plan-messages.page-title': 'Messages',
  'app.action-plan-messaging.new': 'New',
  'app.action-plan-messaging.compose': 'Compose New Message',

  'app.action-plan-budget.page-title': 'My Budget',
  'app.action-plan-budget.button.save': 'Save',
  'app.action-plan-budget.button.saved-message': 'Saved!',
  'app.action-plan-budget.button.show-details': 'Show details',
  'app.action-plan-budget.button.hide-details': 'Hide details',
  'app.action-plan-budget.button.show-fewer': 'Show fewer sources',
  'app.action-plan-budget.button.show-more': 'Show more sources',
  'app.action-plan-budget.income.title': 'Monthly Income',
  'app.action-plan-budget.income.total': 'TOTAL (AVG)',
  'app.action-plan-budget.income.remaining': 'REMAINING: ',
  'app.action-plan-budget.income.over-budget': 'OVER BUDGET: ',
  'app.action-plan-budget.income.my-pay': 'My Pay',
  'app.action-plan-budget.income.my-side-gig': 'My Side Gig',
  'app.action-plan-budget.income.my-partner-pay': 'My Partner\'s Pay',
  'app.action-plan-budget.income.my-partner-side-gig': 'My Partner\'s Side Gig',
  'app.action-plan-budget.income.my-business': 'Income from My Business',
  'app.action-plan-budget.income.unemployment': 'Unemployment',
  'app.action-plan-budget.income.snap': 'SNAP / Food Stamps',
  'app.action-plan-budget.income.ssr': 'Social Security / Retirement',
  'app.action-plan-budget.income.child-support': 'Child Support',
  'app.action-plan-budget.income.rental-income': 'Rental Income',
  'app.action-plan-budget.income.help-fam-friends': 'Help from Family or Friends',
  'app.action-plan-budget.income.wic': 'WIC',
  'app.action-plan-budget.income.disability': 'SSI / SSD',
  'app.action-plan-budget.income.pa-tanf': 'Public Assistance / TANF',
  'app.action-plan-budget.income.other': 'Other',
  'app.action-plan-budget.expense.title': 'Monthly Expenses',
  'app.action-plan-budget.expense.home': 'Home',
  'app.action-plan-budget.expense.food': 'Food',
  'app.action-plan-budget.expense.utilities': 'Utilities',
  'app.action-plan-budget.expense.transportation': 'Transportation',
  'app.action-plan-budget.expense.entertainment': 'Entertainment',
  'app.action-plan-budget.expense.education-child-care': 'Education and Child Care',
  'app.action-plan-budget.expense.personal-care': 'Personal Care',
  'app.action-plan-budget.expense.other': 'Other',
  'app.action-plan-budget.counselor.advice': 'By completing a budget, we can get a better understanding of the flow of your money coming in and out each month. Then we can work together to see if there\'s anything that can be put towards debt or saving for your goals.',
  'app.action-plan-budget.warning.wait-a-second': 'Wait a second!',
  'app.action-plan-budget.warning.notice-changes': 'I noticed you made some changes to your budget without saving!',
  'app.action-plan-budget.warning.dont-save': 'Don\'t save',
  'app.action-plan-budget.warning.save': 'Save',

  'app.action-plan-account.page-title': 'My Account',
  'app.action-plan-account.change-phone.page-title': 'Cell phone number',
  'app.action-plan-account.change-phone.instruction1': 'Enter new cell phone number',
  'app.action-plan-account.change-email.page-title': 'Email',
  'app.action-plan-account.change-email.instruction1': 'Enter new email. This will change your login.',
  'app.action-plan-account.change-password.page-title': 'Password',
  'app.action-plan-account.change-password.step-one': 'Step One: ',
  'app.action-plan-account.change-password.instruction1': 'Enter your current password.',
  'app.action-plan-account.change-password.step-two': 'Step Two: ',
  'app.action-plan-account.change-password.instruction2': 'Create a new password. Your new password should contain: ',
  'app.action-plan-account.change-password.step-three': 'Step Three: ',
  'app.action-plan-account.change-password.reenter-new-password': 'Re-enter new password',
  'app.action-plan-account.change-password.confirmation': 'Your password has been changed!',

  'app.action-plan-feedback.page-title': 'Feedback',
  'app.action-plan-feedback.feedback-prompt-1': 'We welcome your feedback! Did something give you trouble? Are you looking for a new feature? Let us know below! These comments will be forwarded over to our tech and client experience team.',
  'app.action-plan-feedback.feedback-prompt-2': 'Have an important message for your coach? Text ',
  'app.action-plan-feedback.feedback-prompt-3':' to get in touch with them more quickly!',
  'app.action-plan-feedback.comments': 'Comments',
  'app.action-plan-feedback.thank-you': 'Thank you for submitting your feedback.',
  'app.action-plan-feedback.comments.required': 'If you would like to submit feedback, please enter your comments above.',
  'app.action-plan-feedback.comments.required.notice': 'Comments',

  'app.action-plan-link-money.title': 'Link Accounts',
  'app.action-plan-link-money.welcome': 'Take the next step on your journey',
  'app.action-plan-link-money.welcome.description-1': 'The more data we have, the better we can support you on your journey to get out of debt and achieve your dreams.',
  'app.action-plan-link-money.welcome.description-2': 'Give us confidential secure access to the data from your bank account so we can do our best work together.',
  'app.action-plan-link-money.welcome.description-3': 'Your privacy and data security are important to us.',
  'app.action-plan-link-money.welcome.description-4': 'All of your information is safely encrypted.',
  'app.action-plan-link-money.button.link-accounts': 'Link your accounts',
  'app.action-plan-link-money.button.cancel': 'Remind me later',
  'app.action-plan-link-money.instructions': 'Link your accounts for a faster, in-depth analysis of your debts',
  'app.action-plan-link-money.confirmation': 'Thank you for linking your accounts.',
  'app.action-plan-link-money-confirmation.header': 'Congratulations!',
  'app.action-plan-link-money-confirmation.description': 'Your account has been successfully verified and linked.',
  'app.action-plan-link-money-confirmation.button': 'Link another account',

  'app.action-plan-link-money-modal.title': 'New feature!',
  'app.action-plan-link-money-modal.link': 'Link your accounts',
  'app.action-plan-link-money-modal.description': ' to make the most out of your first financial coaching session.',

  //Client Stories

  'app.client-stories.heading': 'Share Your Story',
  'app.client-stories.description-1': 'Has TrustPlus helped you in a time of need? Did you have trouble filing your taxes this year or receiving your full refund? Has anything outside of your control stood in the way of you achieving your financial goals? Or do you just want to say “Thank You” to your Financial Coach?',
  'app.client-stories.description-2': 'We’d love to hear from you whether it’s a few sentences or just a few words! Responses may be used in promotional materials alongside the name you provide below, and one of our team members may reach out to you to hear more details about your experience.',
  'app.client-stories.thanks-heading': 'Thank you',
  'app.client-stories.thanks-body-1': 'Thank you for sharing your story! We are proud of our clients and the work that we do with you, and we appreciate your willingness to share!',
  'app.client-stories.thanks-body-2': 'A team member from our fundraising team may reach out to learn more in the coming weeks. But no further action is required at this time.',
  'app.client-stories.form-heading': 'Your Story',
  'app.client-stories.input.upload-heading': 'By uploading your video or photo, you agree that it may be accessible to the public through our website, social networks, and the media or shared with unders and partners, including city agencies and non-profit organizations.',
  'app.client-stories.input.upload-text': 'Add a video or photo (optional)',
  'app.client-stories.input.preferred-name': 'Display name (this can be a pseudonym)',
  'app.client-stories.input.phone': 'Cell number (in case we need to reach out)',
  'app.client-stories.input.comment': 'Comment',
  'app.client-stories.disclosure': 'By entering my full legal name below as an electronic signature, I confirm I am at least 18 years of age and am competent to contract in my own name. I have reviewed this form before signing below and I fully understand the contents, meaning, and impact.',
  'app.client-stories.input.signature': 'Your full legal name/Electronic signature',
  'app.client-stories.submit.button': 'Send story',

//  Standalone CR
  'app.standalone-credit-report.title': 'Login to see your credit report.',
  'app.standalone-credit-report.text': 'Thank you. Your coach will follow-up with you on the items on your credit report. If you would like to see what\'s on your credit report, please login! Get started by entering your email.',
  'app.standalone-credit-report-thank-you.title': 'Thanks for authorizing a credit report pull.',
  'app.common.button.gohome': 'Return to Home',


  // Emoji Tooltips - TrustPlus Credit, Emergency Response, Opportunity Fund
  'app.emoji-tooltip.shocked': 'Shocked',
  'app.emoji-tooltip.sad': 'Sad',
  'app.emoji-tooltip.overwhelmed': 'Overwhelmed',
  'app.emoji-tooltip.neutral': 'Neutral/Hanging In There',
  'app.emoji-tooltip.fine': 'Fine',
  'app.emoji-tooltip.stressed': 'Stressed',
  'app.emoji-tooltip.anxious': 'Anxious',
  'app.emoji-tooltip.panicked': 'Panicked',
  'app.emoji-tooltip.embarrassed':'Embarrassed',
  'app.emoji-tooltip.nervous': 'Nervous',
  'app.emoji-tooltip.worried': 'Worried',
  'app.emoji-tooltip.surprised': 'Surprised',
  'app.emoji-tooltip.scared': 'Scared',

  //Mental Effort Managing Finances - COVID flows and Additional Questions - Shared
  'app.managing-finances.Very very low': 'Very very low',
  'app.managing-finances.Very low' : 'Very low',
  'app.managing-finances.Low': 'Low',
  'app.managing-finances.Rather low': 'Rather low',
  'app.managing-finances.Neither low nor high': 'Neither low nor high',
  'app.managing-finances.Rather high': 'Rather high',
  'app.managing-finances.High': 'High',
  'app.managing-finances.Very high': 'Very high',
  'app.managing-finances.Very very high': 'Very very high',


  //  Emergency Response
  'app.emergency-response.saverlife-emergency-response-fund.title': 'SaverLife Emergency Response Fund',
  'app.emergency-response.hablas-espanol.link': '¿Hablas español?',
  'app.emergency-response.saver-life-trust-plus-are-here.text': 'SaverLife and TrustPlus are here to support you through this crisis.',
  'app.emergency-response.who-we-are.title': 'Who We Are',
  'app.emergency-response.trust-plus-who-we-are.text': 'TrustPlus™, a service of Neighborhood Trust, is a non-profit social enterprise and the nation’s leading provider of effective, high-quality financial coaching services for workers. For more than 20 years, TrustPlus has helped more than 60,000 U.S. workers improve their credit scores, reduce their debt, and adopt smarter financial habits.',
  'app.emergency-response.saver-life-who-we-are.text': 'SaverLife is a nonprofit on a mission — to inspire, inform, and reward the millions of Americans who need help saving money, through partners and technology.',
  'app.emergency-response.how-we-can-help.title': 'How We Can Help',
  'app.emergency-response.how-we-can-help.text': 'We want to support you by providing relief payments of $500, along with financial guidance in planning for the coming weeks and months. You also have the opportunity to work one-on-one with a TrustPlus financial coach.',
  'app.emergency-response.we-can-help-you.list-header': 'We can help you:',
  'app.emergency-response.we-can-help-you.list.understand-benefits': 'Understand benefits and relief programs',
  'app.emergency-response.we-can-help-you.list.help-negotiate': 'Help negotiate with creditors',
  'app.emergency-response.we-can-help-you.list.apply-for-forbearance': 'Apply for forbearance programs',
  'app.emergency-response.we-can-help-you.list.and-more': 'And more!',
  'app.emergency-response.we-know-you-have-questions.text': 'We know you have questions and are being faced with a ton of overwhelming decisions. You don’t have to do it alone. We’re here for you: by phone, skype or text.',
  'app.emergency-response.after-reviewing-your-finances.text': 'After reviewing your finances with TrustPlus, you will be connected to the SaverLife Relief Fund website, where you can choose the method in which to receive your payment.',
  'app.emergency-response.no-responses-impact-payment.text': 'None of your responses to the following questions will have an impact on whether you receive a payment.',
  'app.emergency-response.in-the-press.title': 'In the Press',
  'app.emergency-response.in-the-press.text-1':'To read more about Neighborhood Trust’s partnership with SaverLife:',
  'app.emergency-response.in-the-press.text-2': 'CNN',
  'app.emergency-response.in-the-press.text-3': ': “Andrew Yang\'s non-profit to spend more than $1 million to aid working families impacted by coronavirus”',
  'app.emergency-response.in-the-press.text-4': 'CRS Wire',
  'app.emergency-response.in-the-press.text-5': ': “Wells Fargo Invests $1M in SaverLife and Neighborhood Trust Financial Partners to Disperse Financial Aid to Small Businesses Affected by COVID-19”',
  'app.emergency-response.in-the-press.text-6': 'If you have any questions about this program or form, email',
  'app.emergency-response.in-the-press.text-7': 'covidsupport@mytrustplus.org',
  'app.emergency-response.in-the-press.text-8': ' or call ',
  'app.emergency-response.in-the-press.text-9': '(929) 335-3189',
  'app.emergency-response.tell-us-about-you.title': 'Get Started',
  'app.emergency-response.why-we-need-this-information.title': '*Why we need this information:',
  'app.emergency-response.why-we-need-this-information.text': ' This offer is only eligible for specific pre-selected employees and businesses. We will use your contact information  to confirm your participation. We may also contact you about your participation and provide further support.',
  'app.emergency-response.registration.mobile-phone': 'Mobile phone',
  'app.emergency-response.number-of-characters-left' : '{numOfCharacters} characters left.',
  'app.emergency-response.saver-life-trust-plus-agree-terms': 'I agree that my participation, including my name, email, and phone will be shared with SaverLife and TrustPlus for the purposes of confirming your identity and participation in this program. Your contact information will be used for no other purpose other than to support you with your finances, if requested.',
  'app.emergency-response.footer.col-1-header': 'Contact',
  'app.emergency-response.footer.col-2-header': 'Follow',
  'app.emergency-response.footer.copyright-message': 'TrustPlus | All Rights Reserved.',



  //Emergency Response Errors
  'app.errors.emergency-response.mobilePhone.required': 'Please enter your mobile number',
  'app.errors.emergency-response.contact-already-exists': 'An account with this information already exists. For further assistance call {tollfreePhone} or email {supportEmail}.',
  'app.errors.emergency-response.lastPaycheckAmount.invalidNumber' : 'Please enter a valid number, or enter an answer without the dollar sign ("$"). Commas (",")  and decimals (".") are ok.',

  //Emergency Response - Questions One
  'app.emergency-response.tell-us-more-about-your-finances.title': 'Tell Us About How Coronavirus Has Affected You',
  'app.emergency-response.but-first-how-are-you.text': 'But first, how are you doing? TrustPlus coaches are concerned for our clients — not just your finances but your physical and mental health too. We know they\'re all connected.',
  'app.emergency-questions.emoji-select.how-are-you-feeling' : 'Please select how you\'re feeling.',
  'app.emergency-questions.do-you-agree.i-have-a-plan': 'To what extent do you agree with the following statement: I have a plan for navigating the financial impact the Corona virus has had on me.',
  'app.emergency-responses.common-dropdown-select.answer.':'Please select...',
  'app.emergency-responses.i-have-a-plan.answer.Disagree strongly':'Disagree strongly',
  'app.emergency-responses.i-have-a-plan.answer.Disagree somewhat':'Disagree somewhat',
  'app.emergency-responses.i-have-a-plan.answer.Neither agree nor disagree':'Neither agree nor disagree',
  'app.emergency-responses.i-have-a-plan.answer.Agree somewhat':'Agree somewhat',
  'app.emergency-responses.i-have-a-plan.answer.Agree strongly':'Agree strongly',
  'app.emergency-questions.describe-current-situation': 'Which of the following best describes your current situation?',
  'app.emergency-responses.current-situation.answer.earnings-not-interrupted' : 'They have not changed',
  'app.emergency-responses.current-situation.answer.earnings-slightly-less': 'They are slightly less',
  'app.emergency-responses.current-situation.answer.earnings-somewhat-less': 'They are somewhat less',
  'app.emergency-responses.current-situation.answer.earnings-significantly-less': 'They are significantly less',
  'app.emergency-responses.current-situation.answer.lost-all-earnings' : 'We have lost all income',
  'app.emergency-questions.last-paycheck-amount' : 'What was the amount of your last paycheck?',
  'app.emergency-questions.paycheck-frequency' : 'Paycheck frequency',
  'app.emergency-responses.paycheck-frequency.answer.Weekly' : 'Weekly',
  'app.emergency-responses.paycheck-frequency.answer.Bi-weekly' : 'Bi-weekly',
  'app.emergency-responses.paycheck-frequency.answer.Semi-monthly' : 'Semi-monthly',
  'app.emergency-questions.financial-changes' : 'What other financial changes to your budget and cashflow are you experiencing? ',
  'app.emergency-questions.check-all-that-apply' : ' (check all that apply)',
  'app.emergency-responses.financial-changes.answer.more-mouths': 'More mouths to feed at home',
  'app.emergency-responses.financial-changes.answer.supporting-older-parents' : 'Supporting older parents or other family members',
  'app.emergency-responses.financial-changes.answer.saving-on-eating-out' : 'Saving on eating out and other entertainment and activities',
  'app.emergency-responses.financial-changes.answer.spending-more-shopping': 'Spending more on online shopping',
  'app.emergency-responses.financial-changes.answer.paying-more': 'Paying more for online education programs or faster internet at home',
  'app.emergency-responses.financial-changes.answer.other': 'Other',
  'app.emergency-questions.describe-your-savings': 'Which of the following describes your savings?',
  'app.emergency-responses.savings.answer.i-had-none':'I have not had any savings',
  'app.emergency-responses.savings.answer.i-have-used-all-savings':'I have used all of my savings',
  'app.emergency-responses.savings.answer.i-have-used-some-savings':'I have used some of my savings',
  'app.emergency-responses.savings.answer.i-have-not-used-any-savings':'I have not had to touch my savings',
  'app.emergency-responses.savings.we-know-it-must-have-been-hard-to-use-savings': 'We know it must have been hard to use your savings for this unexpected crisis. It takes a lot of hard work to build that habit. But this is exactly the kind of emergency you had your savings for. And do not despair, SaverLife and your TrustPlus coach can help you rebuild and replenish your savings in the future.',
  'app.emergency-questions.how-much-do-you-have-set-aside': 'How much do you have set aside in cash or liquid savings now? (in dollars)',
  'app.emergency-questions.what-assistance-have-you-received': 'What sources of assistance have you received since the start of the Corona virus crisis?',
  'app.emergency-responses.assistance.answer.unemployment' : 'Unemployment',
  'app.emergency-responses.assistance.answer.CAREact':'CARE Act stimulus payment',
  'app.emergency-responses.assistance.answer.SNAP':'SNAP (food stamps)',
  'app.emergency-responses.assistance.answer.foodBank': 'Food bank assistance or in-kind donations',
  'app.emergency-responses.assistance.answer.smallBusiness': 'Small business continuity loans and grants',
  'app.emergency-responses.assistance.answer.emergencyHealthCoverage': 'Emergency health coverage',
  'app.emergency-responses.assistance.answer.noneOfTheAbove': 'None of the above',
  'app.emergency-response.text.unemployment-expanded-under-CARES': 'Unemployment has expanded under the CARES Act, and some requirements have been eliminated in order to receive SNAP assistance (food stamps). We hope that you are able to access the benefits that can supplement your income and help meet your needs, but for some it may not be enough. Your TrustPlus financial coach can help you make a plan to make ends meet.',
  'app.emergency-response.text.it-will-be-a-challenge':'It will be a challenge without any benefits to supplement your income, but there are still programs available at local food banks or non-profit organizations that can ensure your family will meet their needs. Plus, your TrustPlus coach can help you make a plan to make ends meet.',
  'app.emergency-questions.have-you-spoken-with-landlord':'Have you or do you plan to speak with your landlord to understand if you can skip your rent payment or pay an amount lower than your rent?',
  'app.emergency-responses.landlord.answer.yes-rent-forgiven':'Yes, my rent or a part of my rent is being forgiven for this month',
  'app.emergency-responses.landlord.answer.yes-lower-amount':'Yes, and I will be paying a lower amount',
  'app.emergency-responses.landlord.answer.yes-not-sure': 'Yes, but I\'m not sure what I will pay',
  'app.emergency-responses.landlord.answer.no-not-an-option': 'No, that is not an option for me',
  'app.emergency-responses.landlord.answer.not-applicable':'N/A- I do not rent',
  'app.emergency-response.landlord.text.helpful-to-ask-landlord-support': 'Many landlords are taking initiative to support their tenants. It\'s helpful to ask. Some cities have a moratorium on evictions, which means that if you are unable to pay your rent, you won\'t risk losing your housing for the time-being.  If you decide to make a partial payment to your rent, consider how the next few months will be impacted by a higher rent, and what adjustments you may have to make.',
  'app.emergency-questions.have-you-filed-taxes':'Have you filed your taxes yet this season?',
  'app.emergency-responses.filed-taxes.answer.Yes, and I received a refund + EITC (earned income tax credit)': 'Yes, and I received a refund + EITC (earned income tax credit)',
  'app.emergency-responses.filed-taxes.answer.Yes, and I received a refund': 'Yes, and I received a refund',
  'app.emergency-responses.filed-taxes.answer.Yes, and I owed taxes':'Yes, and I owed taxes',
  'app.emergency-responses.filed-taxes.answer.Not yet':'Not yet',
  'app.emergency-responses.filed-taxes.answer.I do not file taxes':'I do not file taxes',
  'app.emergency-response.yes-owed-taxes.text.tax-payments-postponed':'Fortunately tax payments are postponed until July.',
  'app.emergency-response.not-filed-yet.text.we-strongly-encourage-to-file':'If you haven\'t filed your taxes yet, you might get a tax refund to help with cash flow. If you owe taxes,  fortunately tax payments are postponed until July. The CARES Act will be directly depositing stimulus payments of up to $1200 for qualifying individuals who have filed taxes for 2019 or 2018, with additional $500 payments if you have children. If you have not filed your taxes in the past couple of years, we strongly encourage you to file as soon as possible so that you can take advantage of the stimulus payments when they become available.',

  //  Emergency Response - Questions Two
  'app.emergency-response.questions-two.lets-make-a-plan.subtitle': 'Let\'s Make a Plan',
  'app.emergency-response.review-priorities.text': 'Now that we\'ve reviewed some other support systems, we want to help review your priorities. Think about the expenses that you may have in the next 4-6 weeks.',
  'app.emergency-questions.priorities.what-comes-first': 'What comes first for you? What needs your attention now? Or what is covered by other support systems and can be put to the bottom of your list?',
  'app.emergency-questions.priorities.take-a-moment-to-prioritize':' Take a moment to prioritize these categories of expenses. Mark each category on a scale of 1 (the most important priority) to 10 (the least important).',
  'app.emergency-responses.priority.label.food-groceries':'Food/Groceries',
  'app.emergency-responses.priority.label.rent-mortgage':'Rent/Mortgage',
  'app.emergency-responses.priority.label.car-transportation':'Car/Transportation',
  'app.emergency-responses.priority.label.gas-electric':'Gas/Electric',
  'app.emergency-responses.priority.label.mobile-phone':'Mobile Phone',
  'app.emergency-responses.priority.label.credit-card':'Credit Card Bills',
  'app.emergency-responses.priority.label.health-care':'Health care',
  'app.emergency-responses.priority.label.daycare-education':'Daycare or Education',
  'app.emergency-responses.priority.label.student-loans':'Student Loans',
  'app.emergency-responses.priority.label.maintaining-small-business':'Maintaining my small business',
  'app.emergency-responses.priority.answer.1': '1',
  'app.emergency-responses.priority.answer.2': '2',
  'app.emergency-responses.priority.answer.3': '3',
  'app.emergency-responses.priority.answer.4': '4',
  'app.emergency-responses.priority.answer.5': '5',
  'app.emergency-responses.priority.answer.6': '6',
  'app.emergency-responses.priority.answer.7': '7',
  'app.emergency-responses.priority.answer.8': '8',
  'app.emergency-responses.priority.answer.9': '9',
  'app.emergency-responses.priority.answer.10': '10',
  'app.emergency-questions.what-is-biggest-financial-challenge': 'What is the biggest financial challenge or concern that you have right now?',
  'app.emergency-questions.how-worried-are-you':'How worried are you about your priority expenses and making ends meet in the next 3 months?',
  'app.emergency-responses.worried.answer.Extremely worried':'Extremely worried',
  'app.emergency-responses.worried.answer.Very worried':'Very worried',
  'app.emergency-responses.worried.answer.Somewhat worried':'Somewhat worried',
  'app.emergency-responses.worried.answer.Not worried':'Not worried',
  'app.emergency-questions.consider-other-options-for-cash-payment': 'When you receive your cash payment of $500, you may want to put it all towards your top priority bill. Here are some other ideas that could work for you. Choose one that you might consider:',
  'app.emergency-responses.other-options-cash-payment.answer.Make partial payments on large bills': 'Make smaller partial payments on large bills, such as the rent',
  'app.emergency-responses.other-options-cash-payment.answer.Stabilize utility - cell phone and gas':'Stabilize utilities like cell phone and electric/gas',
  'app.emergency-responses.other-options-cash-payment.answer.Set aside cash for food/medical expenses':'Set aside cash for future expenses related to food and medical expenses',
  'app.emergency-responses.other-options-cash-payment.answer.Prioritize credit card payments':'Prioritize credit card payments based on hardship plans and interest rates',
  'app.emergency-responses.other-options-cash-payment.answer.Put your student loan into forbearance':'If applicable, work with your student loan provider to put your loan into interest free forbearance',
  'app.emergency-responses.other-options-cash-payment.answer.None of the above apply to me':'None of the above apply to me',
  'app.emergency-response.message.reflect-on-finances':'If you\'re wondering where to start, we encourage you to do some reflection on your finances and your personal comfort.',
  'app.emergency-response.message.ask-yourself': 'Ask yourself:',
  'app.emergency-response.message.are-certain-bills-more-stressful':'Are certain bills or debts more stressful to you than others?',
  'app.emergency-response.message.will-cash-bring-peace-of-mind': 'Will cash on hand bring you peace of mind?',
  'app.emergency-response.message.six-months-from-now': '6 months from now, will interest or additional debt feel like money well spent to help you get through the crisis, or a big setback?',
  'app.emergency-response.message.no-one-size-fits-all': 'There\'s not one specific strategy that will work for everyone. Ultimately, we encourage you to make a plan that will minimize the impact on your current needs (like food, utilities, and your family\'s health), while not severely increasing your future burden.',
  'app.emergency-response.message.trustplus-coaches-can-help': 'TrustPlus coaches can help you to make that plan.',
  'app.emergency-questions.what-are-your-initial-thoughts-emergency-payment':'What are your initial thoughts on how you might use your emergency payment? Again there is no wrong answer here. (limited to 250 characters)',
  'app.emergency-questions.additional-recommendations':'Aside from using your payment from Emergency Relief Fund to stabilize, we also recommend that you consider the following. Check any that might be actionable for you:',
  'app.emergency-responses.additional-recommendations.answer.proactively-communicate': 'Proactively communicating your plans to your creditors, if possible',
  'app.emergency-responses.additional-recommendations.answer.request-to-change-due-dates': 'Requesting changes to due dates',
  'app.emergency-responses.additional-recommendations.answer.request-to-waive-late-fees': 'Requesting waived late fees',
  'app.emergency-responses.additional-recommendations.answer.document': 'Documenting the disruptions to your income and budget, as well as all of your efforts to manage your obligations',
  'app.emergency-responses.additional-recommendations.answer.earmark-expenses': 'Earmarking any expenses that you have deferred or postponed',

  // Emergency Response Find A Time - Meet Your Coach
  'app.emergency-response.find-a-time.meet-with-your-coach.title': 'Because we all need one less thing to worry about right now…',
  'app.emergency-response.find-a-time.text-1': 'TrustPlus is here with free assistance to help with your overwhelming financial decisions.',
  'app.emergency-response.find-a-time.text-2': 'We can help you:',
  'app.emergency-response.find-a-time.how-we-help.bullet-1': 'Understand benefits and relief programs',
  'app.emergency-response.find-a-time.how-we-help.bullet-2' : 'Help negotiate with creditors',
  'app.emergency-response.find-a-time.how-we-help.bullet-3': 'Apply for programs to reduce interest and lower your payments',
  'app.emergency-response.find-a-time.how-we-help.bullet-4': 'Work with you to plan the best ways to utilize your emergency relief payment',
  'app.emergency-response.find-a-time.text-3': 'TrustPlus coaches are here for you by phone, skype, or text.',
  'app.emergency-response.find-a-time.text-4': 'Please note that this program is optional, but highly encouraged. Thirty minutes can make a difference.',
  'app.emergency-response.find-a-time.saverlife-trustplus-wells-fargo-agree-terms': 'I agree that my participation, including my name, email, and phone will be shared with SaverLife, TrustPlus, and Wells Fargo for the purposes of confirming your identity and participation in this program. Your contact information will be used for no other purpose other than to support you with your finances, if requested.',
  'app.emergency-response.find-a-time.btn': 'Find a time',
  'app.emergency-response.skip-this-step.link': 'Skip this step',
  'app.emergency-response.not-right-now.btn': 'Not right now',


  // Emergency Reponse - Other

  'app.emergency-response.your-credit-report.your-credit-report': 'Your credit report',
  'app.emergency-response.placeholder.redirect-to-SaverLife': 'We\'re sorry, but this program is no longer available. Please contact SaverLife support with any questions.',

  //  Opportunity Fund
  'app.opportunity-fund.trust-plus-who-we-are.text': 'TrustPlus™, a non-profit social enterprise, is a service of Neighborhood Trust and the nation’s leading provider of effective, high-quality financial coaching services for workers. For more than 20 years, TrustPlus has helped more than 60,000 U.S. workers improve their credit scores, reduce their debt, and adopt smarter financial habits.',
  'app.opportunity-fund.saver-life-trust-plus-united-way-are-here.text': 'SaverLife and TrustPlus are here to support you through this crisis. They have partnered with {orgName} with the support of {orgPartner} to provide emergency payments of $500 to pre-selected individuals.',
  'app.opportunity-fund.saver-life-trust-plus-opportunity-fund-are-here.text': 'SaverLife and TrustPlus are here to support you through this crisis. They have partnered with {orgName} to provide emergency payments of $500 to pre-selected individuals.',
  'app.opportunity-fund.how-we-can-help.title': 'Opportunity Fund',
  'app.opportunity-fund.opportunity-fund-who-we-are.text': 'Opportunity Fund is a non-profit whose mission is to drive economic mobility by delivering affordable capital and responsible financial solutions to determined entrepreneurs and communities.',
  'app.opportunity-fund.tell-us-about-you.title': 'Tell us about you',
  'app.opportunity-fund.saver-life-united-way-trust-plus-agree-terms': 'I agree that my participation, including my name, email, and phone will be shared with SaverLife, {orgName}, {orgPartner}, and TrustPlus for the purposes of confirming your identity and participation in this program. Your contact information will be used for no other purpose other than to support you with your finances, if requested.',
  'app.opportunity-fund.saver-life-opportunity-fund-trust-plus-agree-terms': 'I agree that my participation, including my name, email, and phone will be shared with SaverLife, {orgName}, and TrustPlus for the purposes of confirming your identity and participation in this program. Your contact information will be used for no other purpose other than to support you with your finances, if requested.',

  //Opportunity Fund - Questions One
  'app.opportunity-fund.questions-one.title': 'Tell Us Who You Are',
  'app.emergency-questions.owner-or-employee.I am': 'I am :',
  'app.emergency-responses.owner-or-employee.answer.An employee at a small business': 'An employee at a small business',
  'app.emergency-responses.owner-or-employee.answer.A small business owner': 'A small business owner',
  'app.emergency-questions.business-name': 'Business name:',
  'app.emergency-questions.industry': 'Industry',
  'app.emergency-responses.industry.answer.Accounting/Bookkeeping':	'Accounting/Bookkeeping',
  'app.emergency-responses.industry.answer.Apparel':	'Apparel',
  'app.emergency-responses.industry.answer.Auto Repair':	'Auto Repair',
  'app.emergency-responses.industry.answer.Auto Sales':	'Auto Sales',
  'app.emergency-responses.industry.answer.Childcare':	'Childcare',
  'app.emergency-responses.industry.answer.Construction':	'Construction',
  'app.emergency-responses.industry.answer.Delivery':	'Delivery',
  'app.emergency-responses.industry.answer.Direct Sales':	'Direct Sales',
  'app.emergency-responses.industry.answer.Dry Cleaning':	'Dry Cleaning',
  'app.emergency-responses.industry.answer.Electronic Sales':	'Electronic Sales',
  'app.emergency-responses.industry.answer.Financial Services':	'Financial Services',
  'app.emergency-responses.industry.answer.Flea Market/Swap Meet':	'Flea Market/Swap Meet',
  'app.emergency-responses.industry.answer.Flower Sales':	'Flower Sales',
  'app.emergency-responses.industry.answer.Food Catering':	'Food Catering',
  'app.emergency-responses.industry.answer.Food services':  'Food services',
  'app.emergency-responses.industry.answer.Food Truck':	'Food Truck',
  'app.emergency-responses.industry.answer.Gas Station':	'Gas Station',
  'app.emergency-responses.industry.answer.Hair Salon':	'Hair Salon',
  'app.emergency-responses.industry.answer.Healthcare':	'Healthcare',
  'app.emergency-responses.industry.answer.Home Care/Senior':	'Home Care/Senior',
  'app.emergency-responses.industry.answer.Home Repair Services':	'Home Repair Services',
  'app.emergency-responses.industry.answer.Insurance':	'Insurance',
  'app.emergency-responses.industry.answer.Janitorial Services':	'Janitorial Services',
  'app.emergency-responses.industry.answer.Mini Market / 99 cent':	'Mini Market / 99 cent',
  'app.emergency-responses.industry.answer.Pet Service':	'Pet Service',
  'app.emergency-responses.industry.answer.Printing and Publishing':	'Printing and Publishing',
  'app.emergency-responses.industry.answer.Professional Service':	'Professional Service',
  'app.emergency-responses.industry.answer.Real Estate':	'Real Estate',
  'app.emergency-responses.industry.answer.Restaurant':	'Restaurant',
  'app.emergency-responses.industry.answer.Retail':	'Retail',
  'app.emergency-responses.industry.answer.Taxi/Limo':	'Taxi/Limo',
  'app.emergency-responses.industry.answer.Towing':	'Towing',
  'app.emergency-responses.industry.answer.Transportation':	'Transportation',
  'app.emergency-responses.industry.answer.Trucking/Long Haul':	'Trucking/Long Haul',
  'app.emergency-responses.industry.answer.Web Info Service':	'Web Info Service',
  'app.emergency-responses.industry.answer.Other':	'Other',
  'app.emergency-responses.industry.answer.Entertainment':	'Entertainment',
  'app.emergency-responses.industry.answer.Agriculture':	'Agriculture',
  'app.emergency-responses.industry.answer.Hospitality':	'Hospitality',
  'app.emergency-responses.industry.answer.Manufacturing':	'Manufacturing',
  'app.emergency-responses.industry.answer.Education':	'Education',
  'app.emergency-responses.industry.answer.Accommodation':	'Accommodation',
  'app.emergency-responses.industry.answer.Government':	'Government',
  'app.emergency-responses.industry.answer.Non-Profit':	'Non-Profit',
  'app.emergency-questions.how-would-you-describe-your-job': 'How would you describe your job at the business / what is your job title?: ',
  'app.emergency-questions.emoji-select.how-are-you-doing': 'How are you doing? As financial coaches, we are concerned for our clients — not just your finances but your physical and mental health too. We know they’re all connected.',

//  Opportunity Fund - Questions Two
  'app.opportunity-fund.how-has-coronavirus-affected-you.title': 'How has the coronavirus affected you?',
  'app.emergency-questions.emoji-select.select-how-you-are-feeling': 'Select how you are feeling:',
  'app.emergency-questions.describe-current-earnings': 'Which of the following best describes your family\'s earnings?',
  'app.emergency-responses.current-earnings.answer.earnings-not-interrupted' : 'They have not been interrupted by the coronavirus crisis',
  'app.emergency-responses.current-earnings.answer.earnings-slightly-less': 'They are slightly less than before the coronavirus crisis',
  'app.emergency-responses.current-earnings.answer.earnings-somewhat-less': 'They are somewhat less than before the coronavirus crisis',
  'app.emergency-responses.current-earnings.answer.earnings-significantly-less': 'They are significantly less than before the coronavirus crisis',
  'app.emergency-responses.current-earnings.answer.lost-all-earnings' : 'We lost all earnings',
  'app.opportunity-fund.savings.we-know-it-must-have-been-hard-to-use-savings': 'We know it must have been hard to use your savings for this unexpected crisis. It takes a lot of hard work to build that habit. But this is exactly the kind of emergency you had your savings for. But do not despair! SaverLife and your TrustPlus coach can help you rebuild and replenish your savings in the future. ',
  'app.opportunity-fund.consider-options.we-encourage-you-to-make-a-plan': 'We encourage you to make a plan that will minimize the impact on your current needs (like food, utilities, and your family’s health), while not severely increasing your future burden.',
  'app.opportunity-fund.consider-options.trust-plus-coaches-can-help-you': 'TrustPlus coaches can help you to make that plan.',

//  Opportunity Fund - Questions Three
  'app.opportunity-fund.your-emergency-fund-payment.title': 'Your Emergency Fund Payment',
  'app.emergency-questions.do-you-have-a-bank-account': 'Do you have a bank account?',
  'app.emergency-responses.have-a-bank-account.answer.Yes': 'Yes',
  'app.emergency-responses.have-a-bank-account.answer.No': 'No',
  'app.opportunity-fund.yes-bank-account.we-will-direct-you-to-Saver-Life': 'Upon completing the steps with TrustPlus, we will direct you to the SaverLife Emergency Relief website where you will fill out an application and link your bank account using your online banking username and password. ',
  'app.opportunity-fund.yes-bank-account.if-your-bank-isnt-supported': 'If your bank isn’t supported or you choose not to link your bank account, you can get an ACH transfer by providing your routing number, account number, and or proof of address to upload. From there, your relief payment will be directly deposited into your account. ',
  'app.opportunity-fund.no-bank-account.no-problem': 'Ok, no problem. SaverLife can deliver your payment via a prepaid card. The prepaid card may take 4-6 weeks. However, if you want to receive the money sooner, you can get a bank account online now and then enter your banking details to receive the money that way. For bank account recommendations tailored to you, you can meet with a Financial Coach for guidance. ',
  'app.opportunity-fund.no-bank-account.venmo-and-paypal' : 'Note: Venmo and PayPal are also options that will deliver your money quickly but they must be linked to an account.',
  'app.opportunity-fund.no-bank-account.upon-completion-will-send-to-saverlife' : 'Upon completing this form, we will direct you to the Emergency Relief Payment site (SaverLife) where you will finish filling out your application.',

//  Opportunity Fund - Find A Time
  'app.opportunity-fund.find-a-time.meet-with-a-coach.title' : 'Meet With A TrustPlus Coach',
  'app.opportunity-fund.find-a-time.you-can-use-trust-plus-today': 'You can begin acessing TrustPlus today at no cost to you!',
  'app.opportunity-fund.find-a-time.trust-plus-coaches-are-here-for-you': 'TrustPlus coaches are here for you. Find a time and get started on your financial coaching session.',
  'app.opportunity-fund.find-a-time.no-thanks.link': 'No thanks',
  'app.opportunity-fund.session-confirmed.text': 'Your session is confirmed! The next step is to review a summary of your debts.',

//  Opportunity Fund and Partner Crisis Response Shared -- Go To Saverlife
  'app.opportunity-fund.go-to-saver-life.title': 'Go to SaverLife',
  'app.opportunity-fund.go-to-saverlife.youre-not-done.title': 'Important: You Are Not Done Yet.',
  'app.opportunity-fund.go-to-saver-life.we-have-included-a-short-video.prompt': 'We have included a short video to run you through creating a SaverLife account. When you feel ready, please continue to SaverLife.',
  'app.opportunity-fund.go-to-saver-life.take-me-to-saver-life.button': 'Take me to SaverLife',
  'app.opportunity-fund.go-to-saverlife.no-appointment.before-you-receive-your-deposit.text': 'Before you receive your deposit, you will need to create an account at SaverLife in order to link your bank account and collect your money.',
  'app.opportunity-fund.go-to-saverlife.thank-you-for-scheduling-your-appointment': 'Thank you for scheduling your financial coaching session with {trustPlusCoach} on {appointmentDate} at {appointmentTime}. We look forward to helping you!',
  'app.opportunity-fund.go-to-saverlife.with-appointment.before-you-receive-your-deposit.text': 'Also note that before you receive your deposit, you will need to create an account at SaverLife in order to link your bank account and collect your money.',

//  United Way
  'app.united-way.how-we-can-help.title': 'United Way of Greater Newark',
  'app.united-way.how-we-can-help.text': 'United Way of Greater Newark exists to improve the lives of individuals, children and families within our footprint to strengthen the collective community. Through programs and service initiatives we address the root causes of community concerns with an emphasis on: education, income and health.',
//Partner Crisis Response

//  Partner Crisis Response: Verify contact
  'app.partner-crisis-response.title': 'COVID-19 Emergency Response Fund',
  'app.partner-crisis-response.subheader': 'TrustPlus, a service of Neighborhood Trust, is here to support you through this crisis. They have partnered with SaverLife to provide emergency payments of $500 to pre-selected individuals.',
  'app.partner-crisis-response.trustplus-who-we-are': '{trustPlusLink}™, a service of Neighborhood Trust, is a non-profit social enterprise and the nation’s leading provider of effective, high-quality financial coaching services for workers. For more than 20 years, TrustPlus has helped more than 60,000 U.S. workers improve their credit scores, reduce their debt, and adopt smarter financial habits.',
  'app.partner-crisis-response.trustplus.link': 'TrustPlus',
  'app.partner-crisis-response.saverlife-who-we-are': '{saverLifeLink} is a nonprofit on a mission — to inspire, inform, and reward the millions of Americans who need help saving money, through partners and technology.',
  'app.partner-crisis-response.saverlife.link': 'SaverLife',
  'app.partner-crisis-response.how-trustplus-coaches-can-help.title': 'How TrustPlus Coaches Can Help',
  'app.partner-crisis-response.how-trustplus-coaches-can-help.along-with-your-emergency-relief-payment': 'Along with your emergency relief payment of $500, we\'re here to provide some guidance on what you can be doing for your finances in these uncertain times. Our goal right now is to illuminate resources available to you, but also help you make a plan for the coming weeks and months.',
  'app.partner-crisis-response.how-trustplus-coaches-can-help.we-know-you-have-questions': 'We know you have questions and are faced with many overwhelming decisions. You don\'t have to do it alone. TrustPlus coaches are here for you: by phone, skype, or text.',
  'app.partner-crisis-response.verify-your-identity.title': 'Verify your Identity',
  'app.partner-crisis-response.verify-your-identity.why-we-need-this-information': '*Why we need this information: this offer is only eligible pre-selected employees and businesses. We will use your contact information to confirm your participation. We may also contact you about your participation and provide further support.',
  'app.partner-crisis-response.agree-to-not-share-terms': 'I confirm that I am using the link that was exclusively sent to me, and that I am not sharing the link with anyone. Sharing this link may forfeit my participation.',
  'app.partner-crisis-response.saverlife-trustplus-tos': 'I agree that my participation, including my name, email, and phone will be shared with SaverLife and TrustPlus for the purposes of confirming your identity and participation in this program. Your contact information will be used for no other purpose other than to support you with your finances, if requested.',

//  Partner Crisis Response: First attempt
  'app.partner-crisis-response.first-attempt.something-went-wrong.title': 'Something went wrong',
  'app.partner-crisis-response.first-attempt.the-information-you-entered-doesnt-line-up': 'The information you entered doesn\'t quite line up into what is in our system. If you think it\'s possible that you made a typo or another simple mistake, we will give you one change to try again.',
  'app.partner-crisis-response.first-attempt.if-you-are-sure-the-information-is-correct': 'If you are sure the information is correct, please contact us by email at {supportEmail} or by phone at {tollFreePhone}.',
  'app.partner-crisis-response.try-again.button': 'Try again',
  'app.partner-crisis-response.phoneNumber': '(929) 335-3189',

//  Partner Crisis Response: Final attempt
  'app.partner-crisis-response.final-attempt.something-is-still-wrong': 'Something still doesn\'t seem to be working quite right.',
  'app.partner-crisis-response.final-attempt.if-you-are-sure-the-information-is-correct': 'If you are sure the information is correct, please contact us by email at {supportEmail} or by phone at {tollFreePhone}. We will do our best to help out!',

//  Partner Crisis Response - Questions 1
  'app.partner-crisis-response.owner-or-employee.answer.An employee at a small business': 'Employee',

//  Partner Crisis Response - Questions 3
  'app.partner-crisis-response.unbanked-user' : 'A bank account can help you get your payment faster and help you manage your finances. A TrustPlus coach can help you find a low cost bank account that is right for you. Schedule a session to learn more!',

//  Partner Crisis Response: Confirm email
  'app.partner-crisis-response.confirm-email.update-your-email.title': 'Update Your Email?',
  'app.partner-crisis-response.confirm-email.we-noticed-your-past-email-address': 'We noted in the past you used an email with the following properties (for security, we cannot provide the full address):',
  'app.partner-crisis-response.confirm-email.starts-with': 'Starts with ',
  'app.partner-crisis-response.confirm-email.is-at-the': 'Is at the ',
  'app.partner-crisis-response.confirm-email.domain': ' domain',
  'app.partner-crisis-response.confirm-email.do-you-want-to-update-your-email': 'Do you want to update your e-mail to ',
  'app.partner-crisis-response.confirm-email.updating-your-email-will-change-contact-and-login': '? Updating will change the email address we use to contact you, as well as the email you use to log in to any of our sites.',
  'app.partner-crisis-response.confirm-email.or-you-can-keep-using': 'Or you can keep using ',
  'app.partner-crisis-response.confirm-email.as-your-email': ' as your email.',
  'app.partner-crisis-response.confirm-email.update-email.btn': 'Update email',
  'app.partner-crisis-response.confirm-email.keep-old-email.btn': 'Keep old email',

  //Partner Crisis Response: Inactive/Placeholder
  'app.partner-crisis-response.program-inactive.title': 'This program is no longer available',
  'app.partner-crisis-response.program-inactive.text': 'Our partnership program for cash relief has ended for now. However, TrustPlus is still available to help you manage your finances. Return home to sign up for a financial coaching session.',
  'app.partner-crisis-response.program-inactive.btn.return-to-home': 'Return home',

  // Emergency Flow Added On Questions
  'app.emergency-responses.current-situation.answer.earnings-increased' : 'They have increased',
  'app.emergency-questions.increased-earnings-due-to' : 'My family\'s earnings have increased due to:',
  'app.emergency-responses.increased-earnings-reason.answer.New sources of assistance/benefits': 'New sources of assistance/benefits',
  'app.emergency-responses.increased-earnings-reason.answer.Increase in pay or hours' : 'Increase in pay or hours',
  'app.emergency-questions.decreased-earnings-due-to' : 'My earnings have decreased due to: ',
  'app.emergency-responses.decreased-earnings-reason.answer.reduced my work hours involuntarily': 'Reduced my work hours involuntarily',
  'app.emergency-responses.decreased-earnings-reason.answer.unpaid leave to care for a sick or immunocompromised member of my household': 'Unpaid leave to care for a sick or immunocompromised member of my household',
  'app.emergency-responses.decreased-earnings-reason.answer.had to take personal sick leave': 'Had to take personal sick leave',
  'app.emergency-responses.decreased-earnings-reason.answer.unpaid leave to comply with stay at home orders or self-isolate': 'Unpaid leave to comply with stay at home orders or self-isolate',
  'app.emergency-responses.decreased-earnings-reason.answer.unpaid leave to take care of my kids who cannot go to school': 'Unpaid leave to take care of my kids who cannot go to school',
  'app.emergency-questions.financial-situation-before-pandemic': 'How did you feel about your financial situation before the pandemic?',
  'app.emergency-responses.financial-situation-before-pandemic.answer.I was financially secure': 'I was financially secure',
  'app.emergency-responses.financial-situation-before-pandemic.answer.I had some breathing room in my budget': 'I had some breathing room in my budget',
  'app.emergency-responses.financial-situation-before-pandemic.answer.I was living paycheck to paycheck': 'I was living paycheck to paycheck',
  'app.emergency-responses.financial-situation-before-pandemic.answer.I was behind on my bills and other payments': 'I was behind on my bills and other payments',
  'app.emergency-questions.what-is-your-annual-income': 'What is your annual income?',
  'app.emergency-responses.annual-income.answer.0-15k': '0-15k',
  'app.emergency-responses.annual-income.answer.15-25k': '15-25k',
  'app.emergency-responses.annual-income.answer.25-35k': '25-35k',
  'app.emergency-responses.annual-income.answer.35-45k': '35-45k',
  'app.emergency-responses.annual-income.answer.45-60k': '45-60k',
  'app.emergency-responses.annual-income.answer.60-75k': '60-75k',
  'app.emergency-responses.annual-income.answer.75-100k': '75-100k',
  'app.emergency-responses.annual-income.answer.100+': '100+',

  // Emergency Flow Added On Expenses Questions
  'app.emergency-questions.covid-effect-on-expenses': 'How would you describe your expenses in the past 12 months?',
  'app.emergency-responses.covid-effect-on-expenses.answer.My expenses have increased': 'My expenses have increased',
  'app.emergency-responses.covid-effect-on-expenses.answer.My expenses have decreased': 'My expenses have decreased',
  'app.emergency-responses.covid-effect-on-expenses.answer.My expenses have not changed': 'My expenses have not changed',
  'app.emergency-questions.increased-expenses-due-to':'My expenses have increased due to',
  'app.emergency-questions.increased-expenses-reason.answer.More mouths to feed at home': 'More mouths to feed at home',
  'app.emergency-questions.increased-expenses-reason.answer.Supporting older parents or other family members':'Supporting older parents or other family members',
  'app.emergency-questions.increased-expenses-reason.answer.Spending more on online shopping': 'Spending more on online shopping',
  'app.emergency-questions.increased-expenses-reason.answer.Paying more for online education programs or faster internet at home': 'Paying more for online education programs or faster internet at home',
  'app.emergency-questions.increased-expenses-reason.answer.Other expenses': 'Other expenses',
  'app.emergency-questions.increased-expenses-reason.answer.increasedExpensesOther':'',
  'app.emergency-questions.decreased-expenses-due-to':'My expenses decreased due to',
  'app.emergency-responses.decreased-expenses-reason.answer.Lower transportation costs': 'Lower transportation costs',
  'app.emergency-responses.decreased-expenses-reason.answer.Lower childcare costs': 'Lower childcare costs',
  'app.emergency-responses.decreased-expenses-reason.answer.Shopping less': 'Shopping less',
  'app.emergency-responses.decreased-expenses-reason.answer.Negotiated lower rent or bills': 'Negotiated lower rent or bills',
  'app.emergency-responses.decreased-expenses-reason.answer.Saving on eating out and other entertainment and activities': 'Saving on eating out and other entertainment and activities',
  'app.emergency-questions.how-cover-shortfall': 'Have your expenses exceeded your income? How have you covered any budget shortfalls you have experienced?',
  'app.emergency-responses.how-cover-shortfall-reason.answer.noShortfalls': 'I have not had shortfalls',
  'app.emergency-responses.how-cover-shortfall-reason.answer.bankAccounts': 'Checking/Savings',
  'app.emergency-responses.how-cover-shortfall-reason.answer.borrowed': 'Borrowing from friends or family',
  'app.emergency-responses.how-cover-shortfall-reason.answer.creditCard': 'Credit card',
  'app.emergency-responses.how-cover-shortfall-reason.answer.advanceLoan': 'Cash advance/payday loan',
  'app.emergency-responses.how-cover-shortfall-reason.answer.cashRelief': 'Cash relief',
  'app.emergency-responses.how-cover-shortfall-reason.answer.otherWays': 'Other',
  'app.emergency-responses.how-cover-shortfall-reason.answer.unableToCover': 'I have not been able to cover them',
  'app.emergency-questions.how-managing-finances': 'How much mental effort do you use managing your expenses?',

  //Emergency/Crisis Response/Relief Flows - Pull Credit Report
  'app.emergency-response.credit-report-form.understand-past-and-present': 'As you make decisions in the coming weeks, it is important to understand your financial past and present. An easy way to do this is to look at insights from your credit report.',
  'app.emergency-response.credit-report-form.with-your-permission': 'With your permission, we can pull your TransUnion® credit report and show you insights that will help you gain an understanding of your financial life. This will be a “soft pull,” meaning it won’t impact your score.',

  //NTFCU Support Flow
  'app.ntfcu-support.title': 'COVID-19 Emergency Response Fund',
  'app.ntfcu-support.subheader': 'TrustPlus is here to support you through this crisis. They have partnered with Neighborhood Trust FCU to provide emergency payments of $500 to pre-selected individuals.',
  'app.ntfcu-support.ntfcu': 'Neighborhood Trust Federal Credit Union (NTFCU)',
  'app.ntfcu-support.ntfcu-who-we-are': 'Neighborhood Trust Federal Credit Union is a community development credit union (CDCU) whose mission is to provide access to affordable financial services to the underserved communities of New York.',
  'app.ntfcu-support.how-we-can-help': 'How We Can Help',
  'app.ntfcu-support.how-trustplus-coaches-can-help.along-with-your-emergency-relief-payment': 'Along with your emergency relief payment of $500, we\'re here to provide some guidance on what you can be doing for your finances in these uncertain times. Our goal right now is to illuminate resources available to you, but also help you make a plan for the coming weeks and months.',
  'app.ntfcu-support.how-trustplus-coaches-can-help.we-know-you-have-questions': 'We know you have questions and are faced with many overwhelming decisions. You don\'t have to do it alone. TrustPlus coaches are here for you: by phone, skype, or text.',
  'app.ntfcu-support.ntfcu-deposit-terms': 'I hereby authorize Neighborhood Trust FCU (NTFCU) to deposit $500.00 into the share account that I currently hold at NTFCU',
  'app.ntfcu-support.ntfcu-participation-terms': 'I agree that my participation, including my name, email, and phone will be shared with NTFCU and TrustPlus for the purposes of confirming your identity and participation in this program. Your contact information will be used for no other purpose other than to support you with your finances, if requested.',
  'app.ntfcu-support.thank-you.subtitle': 'Thank you! Your application has been submitted!',
  'app.ntfcu-support.you-will-be-notified-within-seven-days': 'You will be notified within 7 days if your application was accepted, and payment will follow 2 business days after.',
  'app.ntfcu-support.we-understand-finances-may-be-hard': 'We understand that finances may be hard over the next few months, and we hope the $500 can bridge that gap in some way. If you would like support from the Neighborhood Trust FCU financial coach, Ana Paez, please text (256) 867-1593 to schedule a phone call.',
  'app.ntfcu-support.phone': '(256) 867-1593',
  'app.ntfcu-support.placeholder.text.1': 'Due to the very high volume of applications we have received, our platform will not be accepting additional applications.',
  'app.ntfcu-support.placeholder.text.2': 'We will inform our membership as soon as we can open a new reception process.',
  'app.ntfcu-support.placeholder.text.3': 'Please note that we will inform within seven days which members will receive a donation.',
  'app.ntfcu-support.placeholder.text.4': 'If you applied and your name did not make it to our list, you are welcome to apply again as soon as we reopen the process.',
  'app.ntfcu-support.placeholder.text.5' : 'Thank you!',

  //Emergency Response/Emergency Relief/Crisis Response/NTFCU Support - common validation error
  'app.emergency-response-flows.common.submission-too-long': 'Please shorten your response (limited to 250 characters).',
  'app.emergency-response-flows.common.error.selection-required': 'Please make a selection.',
  'app.emergency-response-flows.common.error.multi-select-required': 'Please select at least one option.',
  'app.emergency-response-flows.common.error.text-entry-required': 'Please complete the required field.',
  'app.emergency-response-flows.agreedToAuthorization.required': 'Please agree to proceed',
  'app.emergency-response-flows.agreedToAuthorization.required.notice': 'Agree to authorization of US Alliance deposit',

  //Humanity Forward
  'app.humanity-forward.how-trustplus-coaches-can-help.along-with-your-emergency-relief-payment': 'Along with your holiday relief payment, we\'re here to provide some guidance on what you can be doing for your finances in these uncertain times. Our goal right now is to illuminate resources available to you, but also help you make a plan for the coming weeks and months.',
  'app.humanity-forward.who-we-are.usalliance-financial': '{usallianceFinancial} is a member-owned not-for-profit financial institution offering a full range of checking, savings and loan products with nationwide reach and over 120,000 members. Our primary goal is to go above and beyond to exceed our members’ unique financial needs and expectations by offering a robust portfolio of easy-to-use, fundamental banking products that improve our members’ ability to save, plan for the future, and conduct their everyday banking and money management for every stage of life.',
  'app.humanity-forward.usalliance-financial': 'USALLIANCE Financial',
  'app.humanity-forward.verify-your-identity.why-we-need-this-information': '*Why we need this information: this offer is only eligible to pre-selected credit union members. We will use your contact information to confirm your participation. We may also contact you about your participation and provide further support.',
  'app.humanity-forward.questions.1.employment-status': 'What is your job status?',
  'app.humanity-forward.questions.2.consider-other-options-for-cash-payment': 'When you receive your cash payment, you may want to put it all towards your top priority bill. Here are some other ideas that could work for you. Choose one that you might consider:',
  'app.humanity-forward.questions.2.additional-recommendations':'Aside from using your payment from Holiday Relief Fund to stabilize, we also recommend that you consider the following. Check any that might be actionable for you:',
  'app.humanity-forward.authorize.title': 'You\'re almost done!',
  'app.humanity-forward.authorize.text': 'Please authorize your payment below.',
  'app.humanity-forward.authorize.authorize-us-alliance-deposit': 'I hereby authorize US Alliance to deposit a holiday relief payment into the share account that I currently hold at US Alliance.',
  'app.humanity-forward.authorize.submit.button': 'Submit',
  'app.humanity-forward.conclusion.subtitle': 'Your application has been submitted',
  'app.humanity-forward.conclusion.text': 'Thank you! We will inform you when your application has been accepted within 5 days. Your cash will be deposited within your account.',
  'app.humanity-forward.placeholder.text.1': 'Thank you to all our members who have already applied for the Humanity Forward Foundation Holiday Relief Fund.' ,
  'app.humanity-forward.placeholder.text.2': 'Due to the very high volume of applications we have received, our platform will not be accepting additional applications.',

  //Alt Company login
  'app.alt-company-login.text-1': 'Normally access TrustPlus via your employer’s website? You can log in here:',
  'app.alt-company-login.login-text': "Login via ",

  // B-flow
  'app.b-flow.email-form.title': 'Get started by entering your email address',
  'app.b-flow.email-form.instruction': ' ',
  'app.b-flow.email-form.logout.title': 'Logged out',
  'app.b-flow.email-form.logout.instruction': 'Log back in by entering your email.',
  'app.b-flow.email-form.timeout.title': 'Logged out due to inactivity',
  'app.b-flow.email-form.timeout.instruction': 'Log back in by entering your email.',
  'app.b-flow.email-form.placeholder.email': 'email address',
  'app.b-flow.email-form.button.continue': 'Continue',
  'app.b-flow.email-form.button.sso-employer': 'Login with your {employer} account',

  'app.b-flow.full-name-form.title': 'You seem new here!',
  'app.b-flow.full-name-form.instruction': 'Enter your first and last name.',
  'app.b-flow.full-name-form.placeholder.first-name': 'first name',
  'app.b-flow.full-name-form.placeholder.last-name': 'last name',
  'app.b-flow.full-name-form.agreement.description': 'I agree to the {termsLink} and {privacyLink}.',
  'app.b-flow.full-name-form.agreement.terms-link': 'Terms of Service',
  'app.b-flow.full-name-form.agreement.privacy-link': 'Privacy Policy',
  'app.b-flow.full-name-form.not-new': 'I\'m not new. Try a different email.',
  'app.b-flow.full-name-form.continue.button': 'Continue',

  'app.b-flow.temp-code-form.title': 'Thanks, ',
  'app.b-flow.temp-code-form.instruction-1': 'Please check your text messages for a 6-digit code, and enter it below.',
  'app.b-flow.temp-code-form.instruction-2': 'I want to use a different mobile number.',
  'app.b-flow.temp-code-form.instruction-3': 'I didn\'t receive a code.',
  'app.b-flow.temp-code-form.instruction-resent.text-1': 'We’ve just sent a new 6-digit code to your mobile number. It may take a minute to arrive.',
  'app.b-flow.temp-code-form.instruction-resent.text-2': 'When ready, please enter the code below.',
  'app.b-flow.temp-code-form.unsuccessful': 'The verification was unsuccessful. Please check your temp code and try again.',
  'app.b-flow.temp-code-form.continue.button': 'Continue',
  'app.b-flow.errors.tempcode.required': 'Temporary code is required',
  // 'app.message.change-password.unsuccessful': 'Please check your password and try again.',
  // 'app.tempcode.resent.code.message' : 'Didn’t receive the code to your phone? Sorry about that! Check your email now for the code.',
  'app.b-flow.temp-code-form.modal.title': 'The code you entered is invalid or expired. ',
  'app.b-flow.temp-code-form.modal.link': 'Get new code.',

  'app.b-flow.new-password-form.title': 'Now make a password.',
  'app.b-flow.new-password-form.instruction': 'Password must: ',
  'app.b-flow.new-password-form.rules-1': 'Be at least 8 characters',
  'app.b-flow.new-password-form.rules-2': 'Contain upper and lower case letters',
  'app.b-flow.new-password-form.rules-3': 'Cannot contain special characters',
  'app.b-flow.new-password-form.rules-4': 'Contain at least one number',
  'app.b-flow.new-password-form.rules-5': 'Passwords must match',
  'app.b-flow.new-password-form.placeholder.password': 'password',
  'app.b-flow.new-password-form.placeholder.re-enter-password': 're-enter password',
  'app.b-flow.new-password-form.show-password': 'Show password',
  'app.b-flow.new-password-form.continue.button': 'Continue',

  'app.b-flow.login-password-form.title': 'Welcome back!',
  'app.b-flow.login-password-form.instruction': 'Enter your password.',
  'app.b-flow.login-password-form.placeholder.password': 'password',
  'app.b-flow.login-password-form.alt-link': 'Forgot password?',
  'app.b-flow.login-password-form.show-password': 'Show password',
  'app.b-flow.login-password-form.error.login-unsuccessful': 'Your login was unsuccessful. Please check your password and try again.',
  'app.b-flow.login-password-form.continue.button': 'Continue',

  'app.b-flow.forgot-password-form.title': 'Forgot password?',
  'app.b-flow.forgot-password-form.instruction': 'No problem! We will send a message to your email address with a link to reset your password.',
  'app.b-flow.forgot-password-form.instruction2': 'Please check your email for the link to reset your password.',
  'app.b-flow.forgot-password-form.send.button': 'Send link',

  // 'app.b-flow.forgot-password-confirmation.instruction': 'No problem! We will send a message with a temporary code to your email address.',
  'app.b-flow.forgot-password-confirmation.instruction': 'Thank you for confirming your email. You may now proceed to create a new password.',
  'app.b-flow.forgot-password-confirmation.continue.button': 'Continue',

  'app.b-flow.appointment-select-coach.title': 'Let\'s start by selecting a financial coach for your 30-minute one-on-one session.',
  'app.b-flow.appointment-select-coach.instruction.2': 'Select a coach:',
  'app.b-flow.appointment-select-coach.text.skip': 'I do not wish to speak with a coach at this time.',
  'app.b-flow.appointment-select-coach.instruction.3': 'Select a coach on the left to read more about them.',
  'app.b-flow.pull-credit-report.text.skip': 'Remind me later',

  'app.b-flow.pick-a-time.title': 'Pick a time',
  'app.b-flow.pick-a-time.instruction': 'Set up a 30-minute session with your coach. If your selected financial coach is unavailable, you can go back and pick a different one.',
  'app.b-flow.pick-a-time.change-coach.button': '< Back to all coaches',
  'app.b-flow.pick-a-time.with': 'Pick a time with ',

  'app.round-robin.pick-a-time.title': 'Let’s start by scheduling a financial coaching session.',
  'app.round-robin.pick-a-time.instruction1': 'A financial coach will be your guide through your financial wellness journey. They’ll be able to answer your financial questions.',
  'app.round-robin.pick-a-time.instruction2': 'Each session will take 30 minutes. All times are local based on your location.',
  'app.round-robin.pick-a-time.select-by-availability': 'SCHEDULE BY AVAILABILITY',
  'app.round-robin.pick-a-time.select-date-instruction': 'Select a specific date when you are available, and then select from a list of timeslots.',
  'app.round-robin.pick-a-time.pull-cr': 'You can make the most out of your session by pulling your credit report.',
  'app.round-robin.pick-a-time.dcl.accepted.title': 'Eliminate your debt faster with debt consolidation',
  'app.round-robin.pick-a-time.dcl.not-accepted.title': 'Explore debt reduction with our trusted financial coach experts',
  'app.round-robin.pick-a-time.dcl.description': 'Schedule a session to start your debt consolidation journey!',
  'app.round-robin.pick-a-time.dcl.not-accepted.description': 'Schedule a session to start your debt consolidation journey!',
  'app.round-robin.pick-a-time.dcl.accepted.text-1': 'Based on the answers you gave us, ',
  'app.round-robin.pick-a-time.dcl.accepted.text-2': 'we may have a solution ',
  'app.round-robin.pick-a-time.dcl.accepted.text-3': 'for your debt. Here are your next steps:',
  'app.round-robin.pick-a-time.dcl.accepted.text-4': 'Schedule a session. ',
  'app.round-robin.pick-a-time.dcl.accepted.text-5': 'Together, you and your financial coach will discuss your debt in more detail and create a plan of action.',
  'app.round-robin.pick-a-time.dcl.accepted.text-6': 'Pull your credit report. ',
  'app.round-robin.pick-a-time.dcl.accepted.text-7': 'This will be a soft pull and will not impact your score. Your report is an essential tool to make the most out of your first session and help TrustPlus determine the best path forward for your debt. ',
  'app.round-robin.pick-a-time.dcl.accepted.text-8': 'Our expert financial coaches will provide guidance and be your point person for debt consolidation questions.',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-1': 'Thank you for answering the pre-screener questions!',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-2': 'Based on the answers you gave us, ',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-3': 'we may have a solution ',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-4': 'for you.',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-5': 'Schedule a session today to explore this further and create a plan of action to tackle your debt.',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-6': 'By scheduling a session, your financial coach will answer your questions  and work with you to achieve your goals.',


  'app.b-flow.session-scheduled.additional-questions': 'Continue',
  'app.b-flow.session-scheduled.pull-report.button': 'Pull Credit Report',
  'app.b-flow.session-scheduled.title': 'What to expect next.',
  'app.b-flow.session-scheduled.description-1a': 'On the day of the appointment, ',
  'app.b-flow.session-scheduled.description-1b': ' will text you a video link or call you from a dedicated number with a 929 area code. ',
  'app.b-flow.session-scheduled.description-2': 'You can make the most out of your session by taking a few extra steps to ensure a comprehensive and personalized experience. By providing access to your credit report and filling out our assessment form you enable us to gain valuable insights into your financial profile.',
  'app.b-flow.session-scheduled.counselor-bubble.text': 'On the day of the appointment, I’ll text you a video call link or call you from my dedicated number, a 929 area code.',
  'app.b-flow.session-scheduled.upcoming-session': 'Your upcoming session',
  'app.b-flow.session-scheduled.reschedule-link': 'Reschedule this session',

  'app.b-flow.credit-debt-self-evaluation.title':'Additional questions',
  'app.b-flow.credit-debt-self-evaluation.text.details-with-credit-report':'Before we show you a picture of your credit and debt, we would love to know how you view your credit and debt situation as of today.',
  'app.b-flow.credit-debt-self-evaluation.text.details-without-credit-report': 'The following questions aren\’t required, but they\’ll help us build your credit profile in order to provide better insights on your credit report.',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.question': 'Which of the following statements describes how manageable your household debt is (including mortgages, student loans, bank loans, money owed to people, medical debt, credit cards and past-due bills)?',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.': 'Please select from the following',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.Do not have any debt': 'Do not have any debt',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.Have a manageable amount of debt': 'Have a manageable amount of debt',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.Have a bit more debt than is manageable': 'Have a bit more debt than is manageable',
  'app.b-flow.credit-debt-self-evaluation.household-debt-self-evaluation.answer.Have far more debt than is manageable': 'Have far more debt than is manageable',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.question': 'How would you rate your credit score? Your credit score is a number that tells lenders how risky or safe you are as a borrower.',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.': 'Please select from the following',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Excellent': 'Excellent',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Very Good': 'Very Good',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Good': 'Good',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Fair': 'Fair',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.Poor':'Poor',
  'app.b-flow.credit-debt-self-evaluation.credit-self-evaluation.answer.I do not know':'I don\'t know',
  'app.b-flow.credit-debt-self-evaluation.button.go-to-credit-report': 'Pull my credit report',
  'app.b-flow.credit-debt-self-evaluation.button.continue': 'Continue',

  'app.b-flow.credit-report-form-page.title': 'Make the most of your first session by pulling your credit report (it\'s a soft pull)',
  'app.b-flow.credit-report-form-page.text': 'By pulling your credit report now, you and your coach will have a clear picture of your finances during the first session, maximizing your success when tackling debt. This will be a “soft pull,” meaning this will not impact your credit score.',
  'app.b-flow.credit-report-form-page.text-2': 'All fields are required.',
  'app.b-flow.credit-report-form-page.button.continue': 'Continue',
  'app.b-flow.credit-report-form-page.dcl.title': 'Last step—pull your credit report (it\'s a soft pull)',
  'app.b-flow.credit-report-form-page.dcl.text': 'This will be a “soft pull,” meaning this will not impact your credit score. Pulling your credit report now will help you and your financial coach make the most out of your first session. It will also grant you access to the TrustPlus portal to keep track of your progress towards debt consolidation.',

  'app.b-flow.additional-questions.title':'Additional questions',
  'app.b-flow.additional-questions.progress-bar': 'Progress toward credit report',

  'app.saml-relay.title': 'logging you in...',

  // Customer Portal access items
  'app.customer-portal-common.back-link': "\< Back",

  'app.cp-flow.login-form.title': 'Welcome!',
  'app.cp-flow.login-form.intro': 'Log in by entering the email address you used when scheduling a demo.',
  'app.cp-flow.password-form.title': 'Welcome back!',
  'app.cp-flow.password-form.instruction': 'Enter your password.',
  'app.cp-flow.login-password-form.alt-link': 'Forgot your password?',
  'app.cp-flow.temp-code-form.title': 'Welcome!',
  'app.cp-flow.temp-code-form.instructions': 'Please verify your email address by entering the one-time code we just sent to it.',
  'app.cp-flow.temp-code-form.instructions-2': '(It may take a minute+ to arrive.)',
  'app.cp-flow.create-password-form.title': 'Finally, make a password.',
  'app.cp-flow.forgot-password-form.title': 'Forgot password?',
  'app.cp-flow.forgot-password-form.instruction': 'No problem! We will send a message to your email address with a link to reset your password.',
  'app.cp-flow.forgot-password-form.instruction2': 'Please check your email for the link to reset your password.',
  'app.cp-flow.forgot-password-form.send.button': 'Send link',
  'app.cp-flow.create-pass-form.agreement.description': 'I agree to the {termsLink} and {privacyLink}.',
  'app.cp-flow.create-pass-form.agreement.terms-link': 'Terms of Service',
  'app.cp-flow.create-pass-form.agreement.privacy-link': 'Privacy Policy',

  // Customer Portal Nav items
  'app.customer-portal.dashboard-header': 'Dashboard',
  'app.customer-portal-nav.contracts': 'Contracts',
  'app.customer-portal-nav.dashboard': 'Home',
  'app.customer-portal-nav.invoices':'Invoices',
  'app.customer-portal-nav.messages':'Messaging',
  'app.customer-portal-nav.account':'My Account',
  'app.customer-portal-nav.reports':'Reports',
  'app.customer-portal-nav.onboarding':'Onboarding',
  'app.customer-portal-nav.faq':'FAQ',
  'app.customer-portal-nav.files': 'Documents',

  // Customer Portal Page items
  'app.customer-portal-home.welcome-name': 'Welcome, ',
  'app.customer-portal-home.intro':'Congratulations on taking meaningful steps to maximize the financial health of your workers.',
  'app.customer-portal-home.progress-heading': 'Your Sign-Up Progress',
  'app.customer-portal-home.progress-done-heading': "You’re signed up for TrustPlus",
  'app.customer-portal-home.progress-sub-heading': 'Just a Few More Steps!' ,
  'app.customer-portal-home.progress-view-steps': 'View all steps',
  'app.customer-portal-home.back-to-progress': 'Back to your progress',
  'app.customer-portal-home.progress-next-step': 'Next Step:',
  'app.customer-portal-home.progress-step-1': 'Step 1:',
  'app.customer-portal-home.progress-step-2': 'Step 2:',
  'app.customer-portal-home.progress-step-3': 'Step 3:',
  'app.customer-portal-home.progress-step-1-instructions': 'Create an account (Done)',
  'app.customer-portal-home.progress-step-2-instructions': 'Generate and sign contract',
  'app.customer-portal-home.progress-step-3-instructions': 'Onboard',
  'app.customer-portal-home.progress-step-4-instructions': 'Generate and sign contract (Done)',
  'app.customer-portal-home.progress-step-5-instructions': 'Onboard (Done) ',
  'app.customer-portal-home.progress-generate-contract': 'Generate Contract here',
  'app.customer-portal-home.onboarding-heading': 'What happens after TrustPlus sign-up is complete?',
  'app.customer-portal-home.onboarding-sub-heading': 'Employee Onboarding',
  'app.customer-portal-home.onboarding-text-1': 'Shortly after signing up, the onboarding process can begin for your employees. At this stage, we will put you in touch with one of our Relationship Managers (RM), who will be your primary TrustPlus contact. The RM will schedule a call with you so you can create a launch plan together!',
  'app.customer-portal-home.onboarding-btn-1': 'Visit Onboarding Page',
  'app.customer-portal-home.onboarding-heading-2': 'It’s time to onboard your team!',
  'app.customer-portal-home.onboarding-sub-heading-2a': 'Meet Your Relationship Manager',
  'app.customer-portal-home.onboarding-text-2': 'Meet your new Relationship Manager, Brendon Kinney! Brendon will be your primary TrustPlus contact. Keep an eye out for a message from him soon, where he will schedule a call to get you started!',
  'app.customer-portal-home.progress-sign-up-complete-header': 'Sign-up Is Complete',
  'app.customer-portal-home.progress-sign-up-complete-thanks': 'Thank you!',
  'app.customer-portal-home.progress-sign-up-complete-text': 'Your Relationship Manager will get in touch with you shortly to create a launch plan.',
  'app.customer-portal.contracts-header': 'Contracts',
  'app.customer-portal-contracts.heading-1': 'Start the seamless contract process below.',
  'app.customer-portal-contracts.text-1a': 'TrustPlus makes it easy to help your employees ease the stress of the everyday money worries that hamper wellness and productivity.',
  'app.customer-portal-contracts.text-1b': 'Ready to invest in the financial health of your workers?',
  'app.customer-portal-contracts.btn-1': 'Generate Your Contract',
  'app.customer-portal-contracts.heading-2': 'You’ve signed your contract look for an email regarding your invoice soon.',
  'app.customer-portal-contracts.text-2a': 'Milly has shared your contract with the Finance team so that they can process your invoice. Keep an eye out for an email from bill.com in about __________ days/weeks. That email will have a link to pay your invoice directly. If you have any questions in the meantime, please feel free to contact Milly.',
  'app.customer-portal-contracts.heading-3': 'You’re up to date!',
  'app.customer-portal-contracts.text-3a': 'You have already signed your contract and onboarded with TrustPlus—there’s nothing you need to do on this screen!',
  'app.customer-portal-contracts.text-3b': 'If you’d like to view your contract history, please navigate to My Accounts.',
  'app.customer-portal-contracts.btn-3': 'Go to My Accounts',
  'app.customer-portal-contracts.about-you.heading': 'Generate your contract',
  'app.customer-portal-contracts.about-you.text-1': 'Begin generating your TrustPlus contract by completing the fields below.',
  'app.customer-portal-contracts.about-you-text-2': 'Please note that some fields will be filled in already, based on the questionnaire answers you submitted before getting a demo.',
  'app.customer-portal-contracts.progress-indicator-heading': 'This progress indicator lets you know where you are in the contract process:',
  'app.customer-portal-contracts.progress-bar-step-title.title-1': 'About',
  'app.customer-portal-contracts.progress-bar-step-title.title-2': 'Your Employees',
  'app.customer-portal-contracts.progress-bar-step-title.title-3a': 'Contract ',
  'app.customer-portal-contracts.progress-bar-step-title.title-3b': 'Details',
  'app.customer-portal-contracts.progress-bar-step-title.title-4a': 'Preview ',
  'app.customer-portal-contracts.progress-bar-step-title.title-4b': 'Contract',
  'app.customer-portal-contracts.progress-bar-step-title.title-5a': 'Sign ',
  'app.customer-portal-contracts.progress-bar-step-title.title-5b': 'Contract',
  'app.customer-portal-contracts.progress-bar-step-title.title-6a': 'Submit ',
  'app.customer-portal-contracts.progress-bar-step-title.title-6b': 'Contract',
  'app.customer-portal-contracts.contract-offering':'Contract Offering',
  'app.customer-portal-contracts.form.step-1.title': 'About the Primary Contact and Signor for the Organization',
  'app.customer-portal-contracts.form.step-1.intro-text': 'Please complete the following sections',
  'app.customer-portal-contracts.form.footer-text-1': 'If anything looks incorrect, please ',
  'app.customer-portal-contracts.form.footer-text-2': 'contact Milly',
  'app.customer-portal-contracts.form.footer-text-3': ' to make changes.',
  'app.customer-portal-contracts.form.common.intro-text-2': ' If anything looks incorrect, please ',
  'app.customer-portal-contracts.form.common.intro-text-3': 'contact Milly',
  'app.customer-portal-contracts.form.common.intro-text-4': ' to make changes.',
  'app.customer-portal-contracts.form.about.intro-text-1': 'Please complete the following sections, representing the primary contact and signor.',
  'app.customer-portal-contracts.form.about.select':'Please select',
  'app.customer-portal-contracts.form.employees.intro-text-1': 'This is our understanding of the number of employees who will participate.',
  'app.customer-portal-contracts.form.employees.table-header-left': 'Number of Employees',
  'app.customer-portal-contracts.form.employees.table-header-right': 'Cost per Employee',
  'app.customer-portal-contracts.form.details.intro-text-1': 'Your contract includes two webinars. Do you want to add any additional webinars?',
  'app.customer-portal-contracts.form.details.table-header-left': 'Your selection',
  'app.customer-portal-contracts.form.details.table-header-center': 'Number of Extra Webinars',
  'app.customer-portal-contracts.form.details.table-header-right-1': 'Cost: $',
  'app.customer-portal-contracts.form.details.table-header-right-2': '/each',
  'app.customer-portal-contracts.form.details.none': 'None',
  'app.customer-portal-contracts.form.details.contract-length': 'Length of Contract',
  'app.customer-portal-contracts.form.details.contract-length-unit': ' months',
  'app.customer-portal-contracts.form.details.contract-start-date': 'Contract Start Date',
  'app.customer-portal-contracts.detail.preview.contract.button':'Preview Contract',
  'app.customer-portal-contracts.about.organization-type.answer.Association': 'Association',
  'app.customer-portal-contracts.about.organization-type.answer.Corporation': 'Corporation',
  'app.customer-portal-contracts.about.organization-type.answer.Credit Union': 'Credit Union',
  'app.customer-portal-contracts.about.organization-type.answer.Employer Solution Organization': 'Employer Solution Organization',
  'app.customer-portal-contracts.about.organization-type.answer.Foundation': 'Foundation',
  'app.customer-portal-contracts.about.organization-type.answer.Government': 'Government',
  'app.customer-portal-contracts.about.organization-type.answer.Individual': 'Individual',
  'app.customer-portal-contracts.about.organization-type.answer.Media': 'Media',
  'app.customer-portal-contracts.about.organization-type.answer.Nonprofit': 'Nonprofit',
  'app.customer-portal-contracts.about.organization-type.answer.Small Business': 'Small Business',
  'app.customer-portal-contracts.about.organization-type.answer.Other': 'Other',

  'app.customer-portal-contracts.form.footer.contract-details': 'Our base implementation fee of $3,000 generally includes co-branded marketing, quarterly reporting, and our standard financial coaching services. This fee is subject to change if the employer requires service customizations (i.e. in-person presence or customized reporting).',
  'app.customer-portal-home.contracts.jump-to-bottom': 'Jump to Bottom',
  'app.customer-portal-home.contracts.jump-to-top': 'Jump Back to Top',
  'app.customer-portal-home.contracts.miss-something': 'Miss something?',
  'app.customer-portal-home.contracts.miss-something-2': 'and use the progress bar to navigate to previous screens.',
  'app.customer-portal-contracts.form.step-5.intro-text': 'By typing your name below, you are signing the contract and agreeing to the Terms of Service.',
  'app.customer-portal-home.contracts.sign-contracts': 'By clicking “Submit Contract” above, you are signing the contract and agreeing to the Terms of Service.',
  'app.customer-portal.contracts-submit-contract': 'Submit Contract',
  'app.customer-portal.contracts.submit-thank-you': 'Thank you for signing your TrustPlus contract!',
  'app.customer-portal.contracts-download': 'Download Contract',
  'app.customer-portal.contracts.download-text': 'Check the Downloads folder on your computer or device.',
  'app.customer-portal-contracts.submit-title-1': 'What happens next?',
  'app.customer-portal-contracts.submit-subtitle-1': 'Onboarding',
  'app.customer-portal-contracts.submit-subtitle-2': 'Invoicing',
  'app.customer-portal-contracts.submit.text-1': 'Navigate to Onboarding to begin uploading your documents. Starting this process now will allow your team to receive Financial Coaching as soon as the contract begins, if they would like to.',
  'app.customer-portal-contracts.submit.text-2': 'Our Finance team will reach out to you soon with an invoice. Please note that if your start date is not for a few months, you will receive the invoice closer to that date.',
  'app.customer-portal.contracts.go-to-onboarding': 'Go to Onboarding',
  'app.customer-portal-contracts.progress-bar-details.title': 'Progress Indicator Details',
  'app.customer-portal-contracts.progress-bar-details.title-2': 'Color Key',
  'app.customer-portal-contracts.progress-bar-details.text-1': 'This progress indicator lets you know where you are in the contract process.',
  'app.customer-portal-contracts.progress-bar-details.text-2': 'Not done',
  'app.customer-portal-contracts.progress-bar-details.text-3': 'Complete',
  'app.customer-portal-contracts.progress-bar-details.text-4': 'Each number represents the following:',
  'app.customer-portal-contracts.progress-bar-details.text-5': 'Select number of webinars',
  'app.customer-portal-contracts.progress-bar-details.text-6': 'Review contract offering/pricing',
  'app.customer-portal-contracts.progress-bar-details.text-7': 'Contract duration',
  'app.customer-portal-contracts.progress-bar-details.text-8': 'Contract start date',



  //Customer Portal Messaging
  'app.customer-portal.messaging-header': 'Messaging',
  'app.customer-portal-messaging.inbox': 'Inbox',
  'app.customer-portal-messaging.inbox.relationship-manager': 'View and send messages to your Relationship Manager (RM) here! Have a question? Message',
  'app.customer-portal-messaging.inbox.sales-owner': 'View and send messages to your Sales Owner (SO) here! Have a question? Message',
  'app.customer-portal-messaging.sent': 'Sent',
  'app.customer-portal-messaging.new': 'New',
  'app.customer-portal-messaging.reply': 'Reply',
  'app.customer-portal-messaging.compose': 'Compose New Message',
  'app.customer-portal-messaging.sent.sent-exclaim': 'Sent!',
  'app.customer-portal-messaging.btn-1': 'Send Message',
  'app.customer-portal.my-account-header':'My Account',
  'app.customer-portal.my-account-heading-1':'Use this page to manage your settings and security',
  'app.customer-portal.my-account.settings': 'Settings',
  'app.customer-portal.my-account.text-1': 'Update your name and contact information',
  'app.customer-portal.my-account.security': 'Security',
  'app.customer-portal.my-account.text-2': 'Change your password',
  'app.customer-portal.my-account.name': 'Name',
  'app.customer-portal.my-account.first-name': 'First Name',
  'app.customer-portal.my-account.last-name': 'Last Name',
  'app.customer-portal.my-account.phone': 'Phone Number',
  'app.customer-portal.my-account.email': 'Email Address',
  'app.customer-portal.my-account.name-confirmation': 'Your name has been updated.',
  'app.customer-portal.my-account.email-confirmation': 'Your email has been updated.',
  'app.customer-portal.my-account.name-text-1': 'Below is the contact information we have on file.',
  'app.customer-portal.my-account.name-text-2': 'To change it, please enter your updated info directly in the boxes.',
  'app.customer-portal.my-account.name-text-3': 'Your name has been updated.',
  'app.customer-portal.my-account.phone-text-1': 'Below is the phone number we have on file.',
  'app.customer-portal.my-account.phone-text-2': 'To change it, please enter an updated number directly in the box.',
  'app.customer-portal.my-account.phone-text-3': 'Your phone number has been updated.',
  'app.customer-portal.my-account.email-text-1': 'Below is the email address we have on file.',
  'app.customer-portal.my-account.email-text-2': 'To change it, please enter an updated email address diectly in the box.',
  'app.customer-portal.my-account.email-text-3': 'Your email address has been updated.',
  'app.customer-portal.my-account.security-text-1': 'Use this area to update your password.',
  'app.customer-portal.my-account.security-text-2': 'Confirm your current password in the first box.',
  'app.customer-portal.my-account.security-text-3': 'Add a new password in the second box.',
  'app.customer-portal.my-account.security-text-4': 'Passwords must:',
  'app.customer-portal.my-account.security-text-5': 'Be at least 8 characters',
  'app.customer-portal.my-account.security-text-6': 'Contain upper and lower case letters',
  'app.customer-portal.my-account.security-text-7': 'Have at least one special character (!, @, #, etc.)',
  'app.customer-portal.my-account.security-text-8': 'Your password has been updated.',

  'app.customer-portal.my-account.security-current-password': 'Current password',
  'app.customer-portal.my-account.security-new-password': 'New password',
  'app.customer-portal.my-account.security-confirm-password': 'Confirm password',



  'app.customer-portal-messaging.caught-up' : 'You\'re all caught up.',
  'app.errors.customer-portal-messaging.need-subject' : 'Please enter a Subject!',
  'app.errors.customer-portal-messaging.need-message' : 'Please enter a Message!',




  //Customer Portal Footer
  'app.customer-portal-footer.text-1':'If you have questions at any time, you can ',
  'app.customer-portal-footer.text-2':'contact',

  // Customer Portal Demo Questions
  'app.customers.welcome': 'Please provide the following information to schedule your demo:',
  'app.customers.schedule-a-demo.input-name': 'Name',
  'app.customers.schedule-a-demo.input-title': 'Title',
  'app.customers.schedule-a-demo.input-email': 'Email',
  'app.customers.schedule-a-demo.input-company-name': 'Employer name',
  'app.customers.schedule-a-demo.input-size-of-workforce': 'Number of employees',
  'app.errors.customers.schedule-a-demo.name': 'Name is required',
  'app.errors.customers.schedule-a-demo.employer-name': 'Employer name is required',
  'app.errors.customers.schedule-a-demo.number-of-employees': 'Number of employees is required',
  'app.customers.schedule-a-demo.button.continue': 'Continue',

  // Customer Portal Onboarding
  'app.customers.onboarding.title': 'Onboarding',
  'app.customers.onboarding.upload-documents.title': 'Begin uploading your documents',
  'app.customers.onboarding.upload-documents.description': 'By providing all of the recommended materials below you enable TrustPlus to provide you with the best possible experience including co-branded marketing materials and direct worker outreach to maximize engagement and impact.',
  'app.customers.onboarding.supported-file-types.title': 'Supported file types:',
  'app.customers.onboarding.supported-file-types.microsoft': 'Microsoft Office documents',
  'app.customers.onboarding.supported-file-types.adobe': 'Adobe PDFs',
  'app.customers.onboarding.supported-file-types.images': 'Images (.jpeg, .jpg, .png, .gif)',
  'app.customers.onboarding.button.upload': 'Upload',
  'app.customers.onboarding.button.replace': 'Replace',
  'app.customers.onboarding.logo-header': 'Your company logo (required)',
  'app.customers.onboarding.census-header': 'Employee census file',
  'app.customers.onboarding.census-description': 'An employee census file is a list of your workers\' and email addresses. By providing this list, TrustPlus can reach out with instructions about signing up, as well as provide tips from our financial coaches throughout the year.',
  'app.customers.onboarding.benefits-overview-header': 'Employee benefits overview or summary',
  'app.customers.onboarding.benefits-overview-description': 'An employee benefits overview or summary is a detailed file or brochure outlining the benefits and resources your company offers its workers. By uploading it here, our TrustPlus coaches can share these benefits and resources with your workers during a financial coaching session.',

}

export default en_jpmc
