import {GET_ACTIVE_EMPLOYERS_WITH_SSO} from '../actions/employer-actions'

const activeEmployers = (state = null, action) => {
    switch (action.type) {
        case GET_ACTIVE_EMPLOYERS_WITH_SSO:
            return action.activeEmployers
        default:
            return state
    }
}

export default activeEmployers
