import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Field from '../helpers/Field'
import {reduxForm} from "redux-form";
import {injectIntl} from "react-intl";
import Option from "../helpers/Option";
import {submitPrescreenerAnswer2} from "../../actions/dcl-actions";
import {connect} from "react-redux";

function DclPrescreener2(props){

    const { pristine, submitting } = props;

    return (
      <div className=''>
                  <form onSubmit={props.handleSubmit}>
                      <Field name='dclAnnualIncome' component='select' id='dclAnnualIncome'
                             className='form-control'>
                          <option value='' disabled>
                              <FormattedMessage id='app.dcl-prescreener.placeholder'/>
                          </option>
                          <Option value='0-20' i18nPrefix='app.dcl-prescreener.annual-income.answer.'/>
                          <Option value='20-40' i18nPrefix='app.dcl-prescreener.annual-income.answer.'/>
                          <Option value='40-60' i18nPrefix='app.dcl-prescreener.annual-income.answer.'/>
                          <Option value='60+' i18nPrefix='app.dcl-prescreener.annual-income.answer.'/>
                      </Field>

                      <div className='submit-button-round '>
                          <button id='submit'
                                  type={'submit'}
                                  className='btn btn btn-primary btn-block'
                                  disabled={pristine || submitting}
                                  aria-disabled={pristine || submitting}
                          >
                              <FormattedMessage id='app.b-flow.email-form.button.continue'/>
                          </button>
                      </div>
                  </form>
      </div>)
}

const mapStateToProps = (state) => {
    return {
        initialValues: {
            dclAnnualIncome: '',
        }
    }
}

const dclPrescreener2Form = reduxForm({
    form: 'DclPrescreener2Form',
    onSubmit: (values, dispatch, props) => {
        return dispatch(submitPrescreenerAnswer2(props.values.dclAnnualIncome))
    },
    destroyOnUnmount: false
})(injectIntl(DclPrescreener2))

export default connect(mapStateToProps)(dclPrescreener2Form)