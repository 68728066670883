import React, {useEffect, useState} from 'react';

import FormattedMessage from '../helpers/FormattedMessage'
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../../actions/login-actions";
import {useHistory} from "react-router-dom";
import {displayTimeoutModal} from "../../actions/display-timeout-action";

export default function InactivityWarningModal(props) {

  const displayModal = useSelector(state => state.displayTimeoutModal)
  const login = useSelector(state => state.login)
  const activeCounselor = useSelector(state => state.activeCounselor)

  const [counter, setCounter] = useState(60)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect( () => {
    if (counter === 0) {
      dispatch(displayTimeoutModal(false))
      history.push('/welcome?timeout=true')
    }
  }, [counter])

  function continueSave() {
    dispatch(verifyToken(login))
    dispatch(displayTimeoutModal(false))
  }

  const renderCounselorImage = () => {
    return (<img src={`/api/counselor/${activeCounselor.userId}/image`}/>)
  }

  return (
    <div className="modal-wrapper">

      <div className="modal-body" style={
        { transform: displayModal ? 'none' : 'translateY(-100vh)', opacity: displayModal ? '1' : '0', display: displayModal ? 'block' : 'none'}}>

        <div className='inactivity-warning-wrapper'>
          <div className='inactivity-warning-box'>
            <div className='inactivity-warning-text'>
              <FormattedMessage id='app.inactivity.message.part1' />
              <span className='inactivity-warning-time-text'>
                        {counter}
                <FormattedMessage id='app.inactivity.message.time' />
                      </span>
              <FormattedMessage id='app.inactivity.message.part2' />
            </div>
            <div className='inactivity-warning-buttons-wrapper'>
              <div className='submit-button-round inactivity-warning-continue-button inactivity-warning-continue'>
                <button id='continue'
                        onClick={() => continueSave()}
                        type={'submit'}
                        className='btn btn-primary btn-block btn-lg'>
                  <FormattedMessage id='app.inactivity.continue'/>
                </button>
              </div>
            </div>
            <div className='counselorUser-picture-wrapper-inactivity-warning'>
              <div className='counselorUser-picture-budget counselorUser-picture-inactivity-warning'>
                {
                  renderCounselorImage
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )

}