import React from 'react'

export const BudgetJarSome = ({
                                 style = {},
                                 fill = '',
                                 width = '100%',
                                 className = '',
                                 height = '100%',
                                 viewBox = '0 0 80 141',
                               }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <g id="Page-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="budget---styles" transform="translate(-311.000000, -176.000000)">
        <g id="Asset-6" transform="translate(311.000000, 177.000000)">
          <path d="M79.223301,64.8718232 L79.223301,61.878453 C79.223301,51.2508287 79.4097087,41.3502762 72.3495146,32.880663 L72.3495146,32.880663 C72.3495146,32.880663 70.4,29.5933702 70.4,26.7392265 C70.3437113,25.1963143 70.6081382,23.6584603 71.176699,22.2220994 L8.47378641,21.3248619 C8.47378641,21.3248619 10.0893204,29.8331492 8.10097087,32.4165746 C1.83300971,39.6099448 0.629126214,48.9458564 0.776699029,58.1270718 C1.27378641,86.3436464 0.776699029,75.1436464 0.776699029,103.352486 L0.776699029,114.475138 C0.776699029,128.861878 17.2893204,139.59779 30.4932039,139.59779 L49.5223301,139.59779 C62.7805825,139.59779 79.231068,128.769061 79.231068,114.475138 L79.231068,110.00442 C79.223301,81.8110497 79.223301,93.0729282 79.223301,64.8718232 Z" id="Path" fill="#D3F0F7" fillRule="nonzero"></path>
          <path d="M67.0834951,127.624309 C71.4563107,125.071823 74.1902913,119.116022 65.9339806,116.625414 C57.6776699,114.134807 38.423301,114.567956 38.423301,114.567956 C38.423301,114.567956 19.6271845,114.908287 14.8116505,117.313812 C9.9961165,119.719337 7.70485437,125.048619 11.8291262,127.585635" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M61.1262136,131.924862 C54.9126214,136.441989 37.2815534,135.119337 29.0252427,133.974586" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <ellipse id="Oval" fill="#D3F0F7" fillRule="nonzero" cx="39.4873786" cy="22.8254144" rx="31.176699" ry="6.85303867"></ellipse>
          <path d="M8.4815534,21.3248619 C8.4815534,21.3248619 6.60970874,24.5348066 10.2291262,25.9657459 C13.8485437,27.3966851 21.4601942,28.7038674 27.6504854,28.9359116" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M71.215534,22.2298343 C71.215534,22.2298343 72.2407767,24.1944751 68.8854369,25.5712707 C65.5300971,26.9480663 57.8796117,28.920442 54.9048544,28.920442" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M72.3572816,16.359116 L72.3572816,6.7679558 C72.3572816,6.7679558 63.4174757,0.379005525 40.007767,0.379005525 C16.5980583,0.379005525 6.78834951,6.31160221 6.78834951,6.31160221 L6.78834951,16.3668508 C6.78834951,16.3668508 4.7223301,16.3668508 4.7223301,17.9679558 C4.7223301,19.5690608 11.1456311,26.0662983 40.031068,26.0662983 C68.9165049,26.0662983 74.2446602,20.3348066 74.2446602,18.0530387 C74.2446602,16.5911602 72.3572816,16.359116 72.3572816,16.359116 Z" id="Path" stroke="#615D59" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M6.78834951,6.31160221 C6.78834951,6.31160221 12.6446602,13.9226519 40.007767,13.9226519 C67.3708738,13.9226519 72.3572816,6.7679558 72.3572816,6.7679558" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M10.384466,14.5723757 C25.584466,19.4762431 42.0970874,18.5016575 57.9106796,17.5270718 C61.3592233,17.0165746 64.792233,16.5447514 68.0543689,15.160221" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M11.5184466,9.42872928 L11.5184466,14.9900552" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M14.3533981,11.1071823 L14.3533981,15.5624309" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M17.2194175,11.679558 L17.2194175,16.1348066" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M20.0854369,12.2519337 L20.0854369,16.6994475" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M22.9514563,12.8243094 L22.9514563,17.2718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M25.8174757,13.3889503 L25.8174757,17.8441989" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M28.6834951,13.961326 L28.6834951,18.4165746" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M31.5495146,14.0773481 L31.5495146,18.5248619" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M34.415534,14.2243094 L34.415534,18.6718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M37.2815534,13.6209945 L37.2815534,18.0762431" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M40.1475728,13.9071823 L40.1475728,18.3624309" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M43.0135922,14.0232044 L43.0135922,18.4707182" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M45.8796117,14.2243094 L45.8796117,18.6718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M48.7456311,13.9071823 L48.7456311,18.3546961" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M51.6116505,13.9071823 L51.6116505,18.3546961" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M54.4776699,13.4508287 L54.4776699,17.8983425" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M57.3436893,12.7624309 L57.3436893,17.2176796" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M60.2019417,12.7624309 L60.2019417,17.2176796" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M63.0679612,11.7337017 L63.0679612,16.1889503" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M65.9339806,10.9911602 L65.9339806,15.4464088" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(19.959986, 119.519739) rotate(-6.970000) translate(-19.959986, -119.519739) " cx="19.9599857" cy="119.519739" rx="11.3235811" ry="4.9351123"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="27.6893204" cy="124.375691" rx="10.5398058" ry="3.75138122"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(54.264319, 124.617256) rotate(-3.320000) translate(-54.264319, -124.617256) " cx="54.2643185" cy="124.617256" rx="13.9027194" ry="3.34148479"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(23.911173, 122.477549) rotate(-6.970000) translate(-23.911173, -122.477549) " cx="23.9111725" cy="122.477549" rx="11.3235811" ry="4.9351123"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(60.775381, 120.625315) rotate(-88.050000) translate(-60.775381, -120.625315) " cx="60.7753806" cy="120.625315" rx="2.64531708" ry="11.0057724"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(60.776564, 118.583719) rotate(-88.050000) translate(-60.776564, -118.583719) " cx="60.7765635" cy="118.583719" rx="2.64531708" ry="11.0057724"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(62.153500, 116.126436) rotate(-84.580000) translate(-62.153500, -116.126436) " cx="62.1535001" cy="116.126436" rx="2.64540562" ry="11.0054184"></ellipse>
          <path d="M55.231068,122.503867 L31.5572816,117.321547 C31.5572816,117.321547 31.184466,120.585635 31.4097087,121.335912 C32.4504854,124.770166 38.5475728,128.041989 45.0330097,128.637569 C50.2990291,129.124862 54.1980583,127.693923 54.9203883,125.296133 C55.0135922,124.98674 55.231068,122.503867 55.231068,122.503867 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(43.372641, 120.053351) rotate(-77.080000) translate(-43.372641, -120.053351) " cx="43.3726413" cy="120.053351" rx="5.85647705" ry="12.1217615"></ellipse>
          <path d="M29.2038835,111.814365 C27.176699,111.226519 23.5495146,111.040884 19.4640777,111.481768 C15.3786408,111.922652 11.8135922,112.812155 9.95728155,113.80221 L10.1592233,115.704972 C12.007767,114.707182 15.5417476,113.80221 19.6582524,113.38453 C23.7747573,112.966851 27.3708738,113.129282 29.3980583,113.717127 L29.2038835,111.814365 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M60.776699,122.906077 L43.4951456,116.656354 C42.9908074,117.478132 42.5718174,118.348923 42.2446602,119.255249 C42.0970874,121.629834 46.0038835,124.499448 50.9747573,125.675138 C55.0135922,126.626519 58.5087379,126.170166 59.7514563,124.692818 C59.8990291,124.530387 60.776699,122.906077 60.776699,122.906077 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(52.071889, 119.870511) rotate(-74.020000) translate(-52.071889, -119.870511) " cx="52.071889" cy="119.870511" rx="4.21681155" ry="9.2786397"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(19.957627, 117.776799) rotate(-6.970000) translate(-19.957627, -117.776799) " cx="19.9576271" cy="117.776799" rx="11.3235811" ry="4.9351123"></ellipse>
          <path d="M42.2446602,122.558011 L20.0776699,123.269613 C20.2194018,124.490662 20.479515,125.695176 20.8543689,126.866298 C22.5553398,129.681768 28.6990291,131.21326 34.5864078,130.285083 C39.3631068,129.511602 42.5009709,127.392265 42.6019417,125.102762 C42.6097087,124.80884 42.2446602,122.558011 42.2446602,122.558011 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(31.170174, 123.036724) rotate(-1.330000) translate(-31.170174, -123.036724) " cx="31.1701741" cy="123.036724" rx="11.0912374" ry="5.36023312"></ellipse>
          <path d="M44.823301,125.257459 L60.5825243,125.767956 C60.4820509,126.632015 60.2973613,127.484284 60.031068,128.312707 C58.8271845,130.308287 54.4699029,131.40663 50.2990291,130.741436 C46.9048544,130.207735 44.6834951,128.683978 44.6135922,127.067403 C44.5669903,126.850829 44.823301,125.257459 44.823301,125.257459 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(52.681498, 125.603363) rotate(-88.670000) translate(-52.681498, -125.603363) " cx="52.6814978" cy="125.603363" rx="3.80553347" ry="7.8679436"></ellipse>
          <path d="M50.3067961,117.18232 L51.2621359,119.154696 C51.7902913,120.237569 57.4135922,118.61326 63.8213592,115.519337 C70.2291262,112.425414 74.9902913,109.037569 74.4621359,107.954696 L73.4990291,105.966851 L50.3067961,117.18232 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(61.896383, 111.587964) rotate(-25.860000) translate(-61.896383, -111.587964) " cx="61.896383" cy="111.587964" rx="12.8752796" ry="2.18304484"></ellipse>
          <path d="M39.3553398,113.871823 L39.7747573,115.774586 C40.007767,116.826519 45.2116505,116.548066 51.4252427,115.21768 C57.638835,113.887293 62.4543689,111.914917 62.2291262,110.862983 L61.8097087,108.960221 L39.3553398,113.871823 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(50.576194, 111.420518) rotate(-12.400000) translate(-50.576194, -111.420518) " cx="50.5761945" cy="111.420518" rx="11.4851838" ry="1.94957136"></ellipse>
        </g>
      </g>
    </g>
  </svg>;

export default BudgetJarSome