import React, {useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";

function CustomerPortalAccountSettings(props) {

    return(
      <div>
          <div className='customer-portal-back-link' onClick={((e) => {
            props.onSimpleClick(e, props.pageAccLanding)
          })}>
            <FormattedMessage id='app.customer-portal-common.back-link'/>
          </div>
          <h2><FormattedMessage id='app.customer-portal.my-account.settings'/></h2>
          <hr/>
          <div className='account-block'>
            <h3>
              <a href="#" onClick={((e) => { props.onSimpleClick(e, props.pageAccSettingsName) })}>
                <FormattedMessage id='app.customer-portal.my-account.name'/>
              </a>
            </h3>
          </div>
          <hr/>
          <div className='account-block'>
            <h3>
              <a href="#" onClick={((e) => { props.onSimpleClick(e, props.pageAccSettingsPhone) })}>
                <FormattedMessage id='app.customer-portal.my-account.phone'/>
              </a>
            </h3>
          </div>
          <hr/>
      </div>
    )
}

export default (injectIntl(CustomerPortalAccountSettings))