import {connect} from 'react-redux'
import Clear from '../components/clear'
import {clearReduxStore} from '../actions/clear-redux-store-actions'
import {injectIntl} from 'react-intl'

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearReduxStore() {
      dispatch(clearReduxStore())
    }
  }
}

const ClearContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Clear))

export default ClearContainer
