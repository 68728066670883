import {ACTIVE_CURRENTLY_WORKING_COUNSELORS} from "../actions/counselor-actions";

const activeCurrentlyWorkingCounselors = (state = null, action) => {
    switch (action.type) {
        case ACTIVE_CURRENTLY_WORKING_COUNSELORS:
            return action.activeCurrentlyWorkingCounselors
        default:
            return state
    }
}

export default activeCurrentlyWorkingCounselors
