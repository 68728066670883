import React , { useState } from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import Icon from "../../resources/icons";


export default function LandingFooter(props) {

  function getYear() {
    return new Date().getFullYear();
  }

  return (

    <div className='footer-landing-page'>
      <div className='landing-container'>
        <div className='footer-landing-page-wrapper'>
          <div className='footer-col col-1'>
            <div className='footer-header-text'>
              <FormattedMessage id='app.footer-link-landing-page.follow'/>
            </div>
            <div className='footer-social-links'>
              <div className='social-link'>
                <div className='social-link-mobile'>
                  <a href='https://www.facebook.com/MyTrustPlus/' target='_blank' alt='Facebook logo' aria-label='Facebook logo'><Icon
                    name="facebook"/> </a>
                </div>
                <div className='social-link-desktop'>
                  <a href='https://www.facebook.com/MyTrustPlus/' target='_blank'>Facebook </a>
                </div>
              </div>
              <div className='social-link'>
                <div className='social-link-mobile'>
                  <a href='https://twitter.com/mytrustplus' target='_blank' alt='Twitter logo' aria-label='Twitter logo'><Icon
                    name="twitter"/></a>
                </div>
                <div className='social-link-desktop'>
                  <a href='https://twitter.com/mytrustplus' target='_blank'>Twitter</a>
                </div>
              </div>
              <div className='social-link'>
                <div className='social-link-mobile'>
                  <a href='https://www.linkedin.com/company/mytrustplus/' target='_blank' alt='LinkedIn logo' aria-label='LinkedIn logo'><Icon
                    name="linkedin"/></a>
                </div>
                <div className='social-link-desktop'>
                  <a href='https://www.linkedin.com/company/mytrustplus/' target='_blank'>LinkedIn</a>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-col col-2'>
            <div className='footer-header-text'>
              <FormattedMessage id='app.footer-link-landing-page.contact'/>
            </div>
            <div>
              TrustPlus <br/>
              c/o Neighborhood Trust Financial Partners <br/>
              530 West 166th St <br/>
              New York, NY 10032 <br/>
              <br />
              <a href='mailto:support@mytrustplus.org'>support@mytrustplus.org</a>
            </div>

          </div>
          <div className='footer-col col-3'>
            <div className='footer-header-text'>
            </div>
          </div>
        </div>
        <div className='footer-copyright'>
          <div className='copyright-line-1'>
            <FormattedMessage id='app.footer-link-landing-page.copyright'/>{getYear()}
          </div>
          <div className='copyright-line-2'>
            <FormattedMessage id='app.footer-link-landing-page.neighborhood-trust'/> <FormattedMessage id='app.footer-link-landing-page.pipe'/>  <FormattedMessage id='app.footer-link-landing-page.all-rights-reserved'/> <span className='footer-pipe'> <FormattedMessage id='app.footer-link-landing-page.pipe'/></span>
          </div>
          <div className='copyright-line-3'>
            <a href='https://www.neighborhoodtrust.org/terms-of-service/' target='_blank'> <FormattedMessage id='app.footer-link-landing-page.tos'/></a>
            <FormattedMessage id='app.footer-link-landing-page.pipe'/>
            <a href='https://www.neighborhoodtrust.org/privacy-policy/' target='_blank'><FormattedMessage id='app.footer-link-landing-page.privacy-policy'/></a>
            <span class='footer-second-pipe'><FormattedMessage id='app.footer-link-landing-page.pipe'/></span>
            <a href='/0000/learn-more' target='_blank' class='faq-footer-link'><FormattedMessage id='app.footer-link-landing-page.faq'/></a>
          </div>
        </div>
      </div>

    </div>

  )
}
