import React from 'react'

export const Faq = ({
                            style = {},
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 26 26',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fillRule="evenodd" clip-rule="evenodd" d="M8.685 8.211a5.416 5.416 0 0 1 4.81-2.544c2.373 0 4.905 1.852 4.905 4.293 0 2.016-1.353 2.797-2.377 3.387-.622.36-1.123.65-1.123 1.103v.161c0 .37-.3.671-.671.671h-2.026c-.37 0-.671-.3-.671-.671v-.274c0-1.687 1.248-2.386 2.229-2.934l.071-.04c.849-.476 1.369-.8 1.369-1.43 0-.834-1.063-1.387-1.923-1.387a2.712 2.712 0 0 0-2.312 1.382.671.671 0 0 1-.929.116L8.83 9.13a.672.672 0 0 1-.145-.919zm2.594 9.831a1.936 1.936 0 1 1 3.872 0 1.936 1.936 0 0 1-3.872 0z" fill="#fff"/>
    <circle cx="12.9" cy="12.8" r="11.25" stroke="#fff" stroke-width="2" fill="transparent"/>

  </svg>;

export default Faq