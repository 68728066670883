import React from 'react'

export const ProgressBigCheck = ({
                          style = {},
                          fill = '#fff',
                          width = '100%',
                          className = '',
                          height = '100%',
                          viewBox = '0 0 34 34',
                        }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <g id="Intro/Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="TPC---MyProgress---320-649" transform="translate(-7.000000, -187.000000)" fill="#00C7B1">
        <g id="Group" transform="translate(8.000000, 188.000000)">
          <circle id="Oval" stroke="#00C7B1" fill-opacity="0.1" cx="16" cy="16" r="16"></circle>
          <path d="M26.0044643,11.0982143 C26.2544655,11.3482155 26.3794643,11.6517839 26.3794643,12.0089286 C26.3794643,12.3660732 26.2544655,12.6696416 26.0044643,12.9196429 L14.4866071,24.4375 C14.2366059,24.6875012 13.9330375,24.8125 13.5758929,24.8125 C13.2187482,24.8125 12.9151798,24.6875012 12.6651786,24.4375 L5.99553571,17.7678571 C5.74553446,17.5178559 5.62053571,17.2142875 5.62053571,16.8571429 C5.62053571,16.4999982 5.74553446,16.1964298 5.99553571,15.9464286 L7.81696429,14.125 C8.06696554,13.8749988 8.37053393,13.75 8.72767857,13.75 C9.08482321,13.75 9.38839161,13.8749988 9.63839286,14.125 L13.5758929,18.0758929 L22.3616071,9.27678571 C22.6116084,9.02678446 22.9151768,8.90178571 23.2723214,8.90178571 C23.6294661,8.90178571 23.9330345,9.02678446 24.1830357,9.27678571 L26.0044643,11.0982143 Z" id="" fill-rule="nonzero"></path>
        </g>
      </g>
    </g>
    </svg>

export default ProgressBigCheck