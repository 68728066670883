import React from 'react'
import { Link } from 'react-router-dom'
import Field from './helpers/Field'
import FormUserError from './form-user-error'
import FormError from './form-error'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
    props.submitting ||
    !props.valid ||
    state.isProcessing;
}

const renderField = ({input, type, autoComplete, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type} autoComplete={autoComplete}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default class LoginForm extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      isProcessing: false,
      fatalFormError: null
    }
  }

  componentDidUpdate(prevProps, nextProps, state) {
    if (this.props.login === null) {
      this.props.history.push(`/welcome`);
    } else if (this.props.login.nextPage === 'TEMPPASSWORD') {
      this.props.history.push('/temporary-code')
    } else if (this.props.login.nextPage === 'CREATEPASSWORD') {
      this.props.history.push('/create-password')
    } else if(this.props.login.nextPage === 'RESETPASSWORD') {
      this.props.history.push('/reset-password')
    }

    if (this.props.error !== prevProps.error && this.props.error === 'app.message.login.unsuccessful') {
      this.props.updateLoadingIcon(false)

      let err = this.props.error
      this.setState({
        fatalFormError: err,
        isProcessing: false
      })
    }
  }

  render() {

    const {intl, formSyncErrors}=this.props;

    return (
      <div>
        <HeaderContainerAP/>

        <div className='container-ap login-form'>
          <div className='page-title'>
            <FormattedMessage id='app.password.title'/>
          </div>
          <div className='text-ap margin-bottom-32-px'>
            <FormattedMessage id='app.password.instruction1'/>
          </div>
          {/*<form onSubmit={e => {e.preventDefault();}}>*/}
          <form onSubmit={this.props.handleSubmit}>
            <div className='form-group margin-bottom-32-px'>
              <div className='label-text'>
                <FormattedMessage id='app.password.input.password'/>
              </div>
              <Field name='password' autoComplete='password' type='password' id='password' component={renderField} />
            </div>

            <div className='margin-bottom-32-px'>
              <FormUserError validationErrors={formSyncErrors} intl={intl}/>
              <FormError error={this.state.fatalFormError} />
            </div>

            <div className='submit-button-round margin-bottom-24-px'>
              <button id='submit'
                      type={'submit'}
                      className='btn btn-primary btn-block btn-lg'
                      disabled={isSubmitDisabled(this.props, this.state)}>
                <FormattedMessage id='app.common.continue.button'/>
              </button>
            </div>
            <div className='text-ap additional-links'>
              <Link to={`forgot-password`} id='forgot'>
                <FormattedMessage id='app.password.alt-link'/>
              </Link>
            </div>
          </form>
        </div>
        <FooterContainerAp/>
      </div>)
  }
}
