import {EMPLOYER_LOADED_FOR_NAV} from "../actions/nav-actions";

export default function employerLoadedForNav(state = false, action) {
  switch (action.type) {
    case EMPLOYER_LOADED_FOR_NAV:
      return action.employerLoadedForNav
    default:
      return state
  }
}
