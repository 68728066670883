import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import CustomerPortalFooter  from './customer-portal-footer';
import {injectIntl} from "react-intl";

import {useDispatch, useSelector} from "react-redux";
import {pageCompleted} from '../../actions/cp-flow-actions'
import CustomerPortalProgressBar from "../helpers/CustomerPortalProgressBar";
import CustomerPortalNav from "./customer-portal-nav";
import {useHistory} from "react-router-dom";
import CustomerPortalContact from "./customer-portal-contact";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});


function CustomerPortalContractEmployees(props) {

    const edsContract = useSelector(state => state.edsContract)
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect( () => {
      window.scrollTo(0, 0)
    })

    const goBackToAboutPage = () => {
      history.push('/customer-portal/contracts/about')
    }

    const goToDetailsOnePage = () => {
        dispatch(pageCompleted(2))
      history.push('/customer-portal/contracts/details-one')
    }

    const handleViewSteps = (e) => {
      e.preventDefault()
      history.push('/customer-portal/contracts/view-all-steps')
    }

  const ask = (e) => {
    e.preventDefault()
    history.push('/customer-portal/messaging')
  }

    return(
        <div id='customer-portal'>
          <CustomerPortalNav />
          <div id='customer-portal-content'>

            <div id='customer-portal-contracts' className='customer-portal-pages'>
            <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title-background-gradient'></div>
              <div className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.customer-portal.contracts-header'/>
              </div>
            </div>

            <div className='customer-portal-dashboard-container'>


              <div className='customer-portal-back-link' onClick={goBackToAboutPage} >
                <FormattedMessage id='app.customer-portal-common.back-link'/>
              </div>
              <h2><FormattedMessage id='app.customer-portal-contracts.about-you.heading'/></h2>
              <div className='contract-content-wrapper'>
                <div className='contract-content-intro-wrapper'>
                  <div className='contract-content-intro-text'>
                    <div className='content-text'>
                      <FormattedMessage id='app.customer-portal-contracts.about-you.text-1'/>
                    </div>
                    <div className='content-text'>
                      <FormattedMessage id='app.customer-portal-contracts.about-you-text-2'/>
                    </div>
                  </div>
                <CustomerPortalProgressBar pageNum={2}
                                           handleViewSteps={(e) => handleViewSteps(e)}
                />
                </div>

                <div className='contracts-form-container'>

                  <div className='contracts-form-title-container'>
                    <div className='contracts-form-title'>
                      <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-2'/>
                    </div>
                    <div className='content-text'>
                      <FormattedMessage id='app.customer-portal-contracts.form.employees.intro-text-1'/>
                      <FormattedMessage id='app.customer-portal-contracts.form.common.intro-text-2'/>
                      <a href="#" onClick={(e) => ask(e)}><FormattedMessage id='app.customer-portal-contracts.form.common.intro-text-3'/></a>
                      <FormattedMessage id='app.customer-portal-contracts.form.common.intro-text-4'/>
                    </div>
                  </div>



                  <div className='contracts-form-wrapper contracts-col-wrapper'>
                    <div className='contracts-details-col'>
                      <div className='contracts-details-header'>
                        <FormattedMessage id='app.customer-portal-contracts.form.employees.table-header-left'/>
                      </div>
                      <div className='contracts-details-info'>
                        {edsContract.totalEmployees}
                      </div>
                    </div>

                    <div className='contracts-details-col'>
                      <div className='contracts-details-header padding-left-30px'>
                        <FormattedMessage id='app.customer-portal-contracts.form.employees.table-header-right'/>
                      </div>
                      <div className='contracts-details-info padding-left-30px'>
                        {
                          _.isNaN(edsContract.costPerEmployee) ?
                          ''
                          : formatter.format(edsContract.costPerEmployee)
                        }
                      </div>
                    </div>
                  </div>

                  <div className='submit-button-round continue'>
                    <button
                      className="btn btn-primary btn-block"
                      id="visit-onboarding"
                      onClick={goToDetailsOnePage}>
                      <FormattedMessage id='app.common.continue.button'/>
                    </button>
                  </div>

                </div>
              </div>

              <CustomerPortalFooter/>
              <CustomerPortalContact />

            </div>
          </div>
        </div>
        </div>
    )
}

export default (injectIntl(CustomerPortalContractEmployees))