import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Field from '../helpers/Field'
import {reduxForm} from "redux-form";
import {injectIntl} from "react-intl";
import Option from "../helpers/Option";
import {submitPrescreenerAnswer1} from "../../actions/dcl-actions";
import {connect} from "react-redux";

function DclPrescreener1(props){

    const { pristine, submitting } = props;

    return (
      <div className=''>
                  <form onSubmit={props.handleSubmit}>
                      <Field name='dclEmploymentStatus' component='select' id='dclEmploymentStatus'
                             className='form-control'>
                          <option value='' disabled>
                              <FormattedMessage id='app.dcl-prescreener.placeholder'/>
                          </option>
                          <Option value='full-time' i18nPrefix='app.dcl-prescreener.employment-status.answer.'/>
                          <Option value='part-time' i18nPrefix='app.dcl-prescreener.employment-status.answer.'/>
                          <Option value='self-employed' i18nPrefix='app.dcl-prescreener.employment-status.answer.'/>
                          <Option value='unemployed' i18nPrefix='app.dcl-prescreener.employment-status.answer.'/>
                      </Field>

                      <div className='submit-button-round '>
                          <button id='submit'
                                  type={'submit'}
                                  className='btn btn btn-primary btn-block'
                                  disabled={pristine || submitting}
                                  aria-disabled={pristine || submitting}
                          >
                              <FormattedMessage id='app.b-flow.email-form.button.continue'/>
                          </button>
                      </div>
                  </form>
      </div>)
}

const mapStateToProps = (state) => {
    return {
        initialValues: {
            dclEmploymentStatus: '',
        }
    }
}

const dclPrescreener1Form = reduxForm({
    form: 'DclPrescreener1Form',
    onSubmit: (values, dispatch, props) => {
        return dispatch(submitPrescreenerAnswer1(props.values.dclEmploymentStatus))
    },
    destroyOnUnmount: false
})(injectIntl(DclPrescreener1))

export default connect(mapStateToProps)(dclPrescreener1Form)