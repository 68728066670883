import React from 'react'
import {useDispatch} from "react-redux";
import {useCalendlyEventListener, InlineWidget} from "react-calendly";


function calendlyUrl(locale, counselor, registration, landingPage) {
    let url = baseCalendlyUrl(locale, counselor, landingPage)
    if (registration == null) {
        return url;
    } else {
        let queryString = `?first_name=${registration.firstName}&last_name=${registration.lastName}&email=${registration.email}`
        return `${url}${queryString}`
    }
}

function baseCalendlyUrl(locale, counselor, landingPage) {
    let storedUrl = locale === "en-US" ? counselor.englishCalendly : counselor.spanishCalendly
    return landingPage ? storedUrl.substring(0, storedUrl.lastIndexOf("/")) : storedUrl
}



export const CalendlyWidgetHelper = props => {

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) =>{ props.onEventScheduled(e)},
      });

    let email = props.registration.email != null ? props.registration.email : ''
    let a4 = props.employer.name != null ? props.employer.name : ''

    return (
        <InlineWidget url={calendlyUrl(props.locale, props.counselor, props.registration, props.landingPage)}
            styles={{
                height: '2200px'
            }}
            prefill={{
                email: email,
                a4: a4
            }}/>
        )
    }





export default CalendlyWidgetHelper