import React from 'react'

export const MessagingSent = ({
                                 style = {},
                                 fill = '#000000',
                                 width = '100%',
                                 className = '',
                                 height = '100%',
                                 viewBox = '0 0 17 17',
                               }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <path d="m13.95.898-13 7.497c-1.075.622-.972 2.212.178 2.684L4.7 12.557v2.638c0 1.45 1.85 2.047 2.706.893l1.369-1.846 3.496 1.443a1.515 1.515 0 0 0 1.31-.08c.4-.226.675-.626.746-1.08l1.856-12.1C16.374 1.174 15.03.276 13.95.899zM6.2 15.195v-2.02l1.144.473L6.2 15.195zm6.643-.897-4.806-1.985 4.381-6.322c.335-.484-.297-1.046-.74-.662l-6.61 5.76L1.7 9.694l13-7.5-1.857 12.103z" fill="#003057"/>  </svg>;

export default MessagingSent