import React from 'react'

export const TPCPieChart740To799 = ({
                                      style = {},
                                      fill = '#fff',
                                      width = '100%',
                                      className = '',
                                      height = '100%',
                                      viewBox = '0 0 42 38',
                                    }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <text fill="#FFF" fillRule="evenodd" fontFamily="Gotham-Medium, Gotham, Helvetica, Arial, sans-serif" fontSize="12" fontWeight="400" letterSpacing=".167" transform="rotate(41 929.074 -1310.867)">
      <tspan x="1091.193" y="293">740-799</tspan>
    </text>
  </svg>

export default TPCPieChart740To799