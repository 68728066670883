import {SET_ACTIVE_COUNSELORS} from "../actions/counselor-actions";

const activeCounselors = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_COUNSELORS:
      return action.activeCounselors
    default:
      return state
  }
}

export default activeCounselors
