import {connect} from 'react-redux'
import AdminSchedule from '../components/admin-schedule'
import {calendlyFollowUpEvent} from "../actions/calendly-actions";
import {setCurrentCalendlyConfirmationPath} from "../actions/calendly-actions";

function mapStateToProps(state) {
  return {
    employer: state.employer,
    locale: state.locale,
    activeCounselor: state.activeCounselor,
    client: state.client,
    clientRetrieved: state.clientRetrieved
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCalendlyFollowUpEvent(eventUri, inviteeUri) {
      return dispatch(calendlyFollowUpEvent(eventUri, inviteeUri))
    },
    setCurrentCalendlyConfirmationPath(url) {
      return dispatch(setCurrentCalendlyConfirmationPath(url))
    }
  }
}

const AdminScheduleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSchedule)

export default AdminScheduleContainer
