import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../../actions/login-actions";
import {useHistory} from "react-router-dom";
import ActionPlanNav from "./action-plan-nav";

export default function ActionPlanAppointmentConfirmation(props) {

  const activeCounselor = useSelector((state) =>state.activeCounselor)
  const login = useSelector((state) => state.login)

  const dispatch = useDispatch();

  const history = useHistory();

  const returnToSessions = () => {
    history.push("/action-plan/sessions")
    dispatch(verifyToken(login))
  }

  const  renderCounselorImage = () => {
    if (activeCounselor){
      return (<img src={`/api/counselor/${activeCounselor.userId}/image`} className='img-circle'/>)
    } else {
      return (<div className='counselor-image-default img-circle'></div>)
    }
  };

  return (
      <div id='ap-sessions'>
        <ActionPlanNav />
        <div id='action-plan-content'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <h1 className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.action-plan-sessions.page-title'/>
            </h1>
          </div>
          <div className='calendly-schedule-confirmation-container'>

            <div className='container-ap ap-sessions'>
              <div className='calendly-schedule-confirmation-wrapper'>
                <div className='ask-counselor-image'>
                  {renderCounselorImage()}
                </div>
                <div className='calendly-schedule-confirmation-text-wrapper'>
                  <h3>Confirmed</h3>
                  <p> <FormattedMessage id='app.schedule-confirmation.counselor.bubble.text'/>
                  </p>
                  <div className='submit-button-round'>
                    <button onClick={()=>returnToSessions()}
                            className='btn btn-primary btn-lg'>
                      Return to My Sessions
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)

};