import React , { useState } from 'react'
import {Link} from "react-router-dom";
import FormattedMessage from "../helpers/FormattedMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ToQuestionnaire(props) {

  function onSkipClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();

    props.history.push(`notification`)
  }

  return (
    <div>
      <div className='recommendations-gradient-color'>
        <div className='top-priority'>
          <FormattedMessage id='app.debt-report-recommendations.new-top-priority'/>
        </div>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.let-us-know-about-you'/>
        </h2>
      </div>

      <div className='recommendations-page-wrapper'>
        <p>
          <FormattedMessage id='app.debt-report-collections.to-questionnaire'/>
        </p>

        <div className='submit-button-round sixty-percent-btn'>
          <button className='btn btn-primary btn-block btn-work-on-this'
                  id='debt-report-reviewed-collections-yes'
                  onClick={()=>props.nextPage()}>
            <FormattedMessage id='app.debt-report-recommendations.go-to-questionnaire'/>
            <FontAwesomeIcon icon={['fal', 'angle-double-right']} aria-label='A double right angle icon' />
          </button>
          <p className='collections-dispute-offline'>
            <Link to='' onClick={e => onSkipClick(e) }>
              <FormattedMessage id='app.debt-report-recommendations.skip-these-questions'/>
            </Link>
          </p>
        </div>

      </div>
    </div>

  )
}