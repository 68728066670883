import React from 'react'

import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'

export default class ForgotpasswordForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isProcessing: false,
            submitted: false
        }
    }

    componentDidUpdate(prevProps, nextProps, state) {
        if (this.props.login === null) {
            this.props.history.push(`/welcome`);
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitted: true
        })
        this.props.handleSubmit();
    }

    render() {
        return (
            <div>
                <HeaderContainerAP/>

                <div className='container-ap forgotpassword-form'>
                    {
                        this.state.submitted ?
                            <div className='page-title'>
                                <FormattedMessage id='app.forgotpassword-form.instruction1'/>
                            </div>
                            :
                            <div>
                              <div className='page-title'>
                                <FormattedMessage id='app.forgotpassword-form.title'/>
                              </div>
                              <div className='text-ap margin-bottom-32-px'>
                                <FormattedMessage id='app.forgotpassword-form.instruction'/>
                              </div>
                                <form onSubmit={(event) => this.handleSubmit(event)}>
                                    <div className='submit-button-round margin-bottom-24-px'>
                                        <button id='submit'
                                                type={'submit'}
                                                className='btn btn-primary btn-block btn-lg'>
                                            <FormattedMessage id='app.forgotpassword-form.send.button'/>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        }
                </div>
                <FooterContainerAp/>
            </div>)
    }
}
