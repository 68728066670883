import {COLLECTION_TAKE_ACTION_TODAY_CREATED} from '../actions/take-action-today-actions'

function collectionTakeActionTodayCreated(state = false, action) {
    switch (action.type) {
        case COLLECTION_TAKE_ACTION_TODAY_CREATED:
            return action.collectionTATCreated
        default:
            return state
    }
}

export default collectionTakeActionTodayCreated