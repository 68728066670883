import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'

import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../services/validation-service'
import {authenticate} from '../actions/login-actions'
import {getContact} from '../actions/registration-actions'
import {displayLoadingIcon} from '../actions/loading-icon-action'
import LoginForm from '../components/login-form'
import {doesContactHaveCreditReportOnFile} from "../actions/trust-plus-credit-actions";
import {getEmployer} from "../actions/employer-actions";
import {getBookedAppointments, getCompletedAttendedAppointments} from "../actions/calendly-actions";
import {setActiveCounselor} from "../actions/counselor-actions";

function mapStateToProps(state) {
  return {
    login: state.login,
    registration: state.registration,
    formSyncErrors: getFormSyncErrors('login')(state),
    employer: state.employer,
    activeCounselors: state.activeCounselors,
    bookedAppointments: state.bookedAppointments,
    completedAttendedAppointments: state.completedAttendedAppointments
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoadingIcon(truthy) {
      dispatch(displayLoadingIcon(truthy))
    },
    setActiveCounselor(counselor) {
      dispatch(setActiveCounselor(counselor))
    }
  }
}

function goToNextPage(props) {
  console.log("LoginFormContainer goToNextPage")
  props.history.push(`/action-plan`);
}

function goToCustomerPortal(props) {
  console.log("LoginFormContainer goToCustomerPortal")
  props.history.push(`/customer-portal/login`);
}

function getCounselor(contactId, counselors) {
  let counselor = null;
  for (let i=0; i<counselors.length; i++) {
    let counselorTmp = counselors[i];
    if (counselorTmp.contactId === contactId) {
      counselor = counselorTmp;
    }
  }
  return counselor;
}

const LoginFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
      form: 'LoginForm',
      validate: ValidationService.login,
      onSubmit: (values, dispatch, props) => {
        dispatch(displayLoadingIcon(true))
        return dispatch(authenticate(values, "Client")).then(() => {
          return dispatch(getContact(props.registration.id)).then(() => {
            return dispatch(getBookedAppointments()).then(() => {
              if (props.bookedAppointments.length > 0) {
                props.setActiveCounselor(getCounselor(props.bookedAppointments[0].coach, props.activeCounselors))
              }
              return dispatch(getCompletedAttendedAppointments()).then(() => {
                // return dispatch(getEmployerCodeFromLogin()).then(()=> {
                //   if (props.employer && props.employer.code !== "0000") {
                //     dispatch(getEmployer(props.employer.code))
                //   }
                  return dispatch(doesContactHaveCreditReportOnFile()).then(()=>{
                    dispatch(displayLoadingIcon(false))
                    goToNextPage(props)
                    // if (props.login.isCustomersContact === true) {
                    //   goToCustomerPortal(props)
                    // } else {
                    //   goToNextPage(props)
                    // }
                })
              })
            })
          })
        })
      },
      destroyOnUnmount: false
    })(injectIntl(LoginForm))
)

export default LoginFormContainer
