import axios from 'axios'
import {SubmissionError} from "redux-form";
import {UPDATE_REGISTRATION} from "./registration-actions";

export const initiateStandaloneCrUpdate = data => {
  return (dispatch, getState) => {

    const contact = Object.assign({}, data)

    contact.creditReportAuthorizationReceived = 'Yes';
    contact.pullCredit = true;
    contact.id = getState().client.id
    contact.firstName = getState().client.firstName
    contact.lastName = getState().client.lastName
    contact.email = getState().client.email

    return axios
        .post('/api/contact/updateContact', contact)
        .then((response) => {
          dispatch({
            type: UPDATE_REGISTRATION,
            registration: response.data
          })
        })
        .catch(error => {
          throw new SubmissionError({_error: error.response.data.message})
        })
  }
}
