import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm, reset} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../../services/validation-service'
import {displayLoadingIcon} from '../../actions/loading-icon-action'
import ActionPlanFeedbackForm from "../../components/action-plan/action-plan-feedback-form";
import {submitClientFeedback} from "../../actions/feedback-case-actions";

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('ActionPlanFeedbackForm')(state),
    login: state.login
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoadingIcon(truthy) {
      dispatch(displayLoadingIcon(truthy))
    },
  }
}

function goToNextPage(props) {
  props.setFormSubmit(true)
}

const ActionPlanFeedbackFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
  form: 'ActionPlanFeedbackForm',
  validate: ValidationService.validateFeedbackInput,
  onSubmit: (values, dispatch, props) => {
    console.log("In the ActionPlanFeedbackForm submit");
    console.log(values);
    dispatch(displayLoadingIcon(true))
    return dispatch(submitClientFeedback(values)).then(() => {
      dispatch(displayLoadingIcon(false))
      goToNextPage(props)
      dispatch(reset('ActionPlanFeedbackForm'))
    })
  },
  destroyOnUnmount: false
})(injectIntl(ActionPlanFeedbackForm))
)

export default ActionPlanFeedbackFormContainer
