import React, {useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";
import Icon from "../../../resources/icons";
import Field from "../helpers/Field";
import {reduxForm} from "redux-form";
import ValidationService from "../../services/validation-service";
import {updateCPAccountName, updateCPAccountPhone} from "../../actions/cp-flow-actions";
import {connect} from "react-redux";
import {reGetContact} from "../../actions/registration-actions";

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

function CustomerPortalAccountSettingsName(props) {

  const isSubmitDisabled = (props) => {
    return props.pristine ||
      props.submitting ||
      !props.valid;
  }

    return(
      <div>
        <div className='customer-portal-back-link' onClick={((e) => {
          props.onSimpleClick(e, props.pageAccSettings)
        })}>
          <FormattedMessage id='app.customer-portal-common.back-link'/>
        </div>
        <h2><FormattedMessage id='app.customer-portal.my-account.name'/></h2>
        <div className='content-text'>
          <FormattedMessage id='app.customer-portal.my-account.name-text-1'/>
        </div>
        <div className='content-text'>
          <FormattedMessage id='app.customer-portal.my-account.name-text-2'/>
        </div>

        <form onSubmit={props.handleSubmit}>
          <div className='form-group margin-bottom-32-px'>
            <label><FormattedMessage id='app.customer-portal.my-account.first-name'/></label>
            <Field name='firstName' type='text' id='firstName' component={renderField} />
            <label><FormattedMessage id='app.customer-portal.my-account.last-name'/></label>
            <Field name='lastName' type='text' id='lastName' component={renderField} />
          </div>

          {!props.submitSucceeded ? <>
              {props.dirty ? <>
                <div className='submit-button-round continue'>
                  <button
                    className="btn btn-primary btn-block"
                    id="submit"
                    type={'submit'}
                    disabled={isSubmitDisabled(props)}>
                    <FormattedMessage id='app.common.save.button'/>
                  </button>
                </div>
                </> :<div/>
              }

            </>:
            <div className='w-50'>
              <div className='big-check-circle'>
                <Icon name="checkmark"/>
              </div>
              <div className='big-check-text'>
                <FormattedMessage id='app.customer-portal.my-account.name-confirmation'/>
              </div>
            </div>
          }
        </form>



      </div>
    )
}

const mapStateToProps = (state) => {

  return {
    initialValues: {
      firstName: state.registration && state.registration.firstName ? state.registration.firstName : '',
      lastName: state.registration && state.registration.lastName ? state.registration.lastName : '',
    }
  }
}

const sendAccountNameForm = reduxForm({
  form: 'CustomerPortalAccountSettingsNameForm',
  enableReinitialize: true,
  validate: ValidationService.cpAccountName,
  onSubmit: (values, dispatch, props) => {
    return dispatch(updateCPAccountName(values)).then(() => {
      dispatch(reGetContact()).then(() => {
        //
      })
    })
  },
  destroyOnUnmount: false
})(injectIntl(CustomerPortalAccountSettingsName))

export default connect(mapStateToProps)(sendAccountNameForm)