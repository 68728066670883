import React from 'react'

export const DclImpact3 = ({
                             style = {},
                             fill = '#fff',
                             width = '100%',
                             className = '',
                             height = '100%',
                             viewBox = '0 0 146.9 144.4',
                           }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <g>
          <path d="M11,135.35h13.24v6.17c0,1.59-1.29,2.88-2.88,2.88h-7.47c-1.59,0-2.88-1.29-2.88-2.88v-6.17h0Z" fill="#003057"/>
          <path d="M122.66,135.35h13.24v6.17c0,1.59-1.29,2.88-2.88,2.88h-7.47c-1.59,0-2.88-1.29-2.88-2.88v-6.17h0Z" fill="#003057"/>
        </g>
        <g>
          <rect x="1.2" y="1.2" width="144.49" height="133.79" rx="7.13" ry="7.13" fill="#003057" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.41"/>
          <path d="M109.92,57.04c-2.9.06-5.38,1.78-6.55,4.23l-18.83.41c-.63-2.91-1.92-5.71-3.9-8.2-6.19-7.77-16.97-9.72-25.38-5.06l-10.72-13.46c1.13-2.47.87-5.47-.94-7.74-2.56-3.21-7.23-3.74-10.45-1.18-3.21,2.55-3.74,7.23-1.18,10.44,1.81,2.27,4.67,3.2,7.32,2.64l10.73,13.46c-6.42,7.15-6.93,18.1-.74,25.87,2.18,2.74,4.93,4.75,7.95,6.01l-4.29,16.97c-2.65.59-4.87,2.62-5.59,5.43-1,3.98,1.41,8.02,5.39,9.03,3.98,1,8.03-1.41,9.03-5.39.71-2.81-.28-5.66-2.34-7.44l4.32-17.1c4.77.29,9.66-1.12,13.69-4.33,4.29-3.41,6.8-8.23,7.41-13.28l18.66-.41c1.28,2.4,3.82,4,6.73,3.94,4.11-.09,7.36-3.49,7.27-7.6-.09-4.11-3.49-7.36-7.6-7.27ZM66.33,72.68c-3.78.47-7.22-2.22-7.69-6-.47-3.78,2.22-7.22,6-7.69,3.78-.47,7.22,2.22,7.69,6,.47,3.78-2.22,7.22-6,7.69Z" fill="#00c7b1"/>
          <path d="M120.63,95.06c0,3-1.87,5.57-4.52,6.6v8.4c0,1.42-1.15,2.57-2.57,2.57s-2.57-1.15-2.57-2.57v-8.4c-2.64-1.03-4.52-3.59-4.52-6.6,0-3.91,3.17-7.08,7.08-7.08s7.08,3.17,7.08,7.08Z" fill="#00c7b1"/>
        </g>
      </g>
    </g>

  </svg>;

export default DclImpact3