import React from 'react'

export const OnlineTools = ({
                               style = {},
                               fill = '#fff',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 34 33',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g id="Page-9" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="howitworks2_1200+" transform="translate(-774.000000, -2292.000000)">
        <g id="Group-6" transform="translate(774.000000, 2292.000000)">
          <path d="M7.36666667,18.4 C6.5874961,18.4 5.92048889,18.1184924 5.365625,17.5554688 C4.81076111,16.9924451 4.53333333,16.315629 4.53333333,15.525 L4.53333333,2.875 C4.53333333,2.08437105 4.81076111,1.4075549 5.365625,0.84453125 C5.92048889,0.281507602 6.5874961,0 7.36666667,0 L26.6333333,0 C27.4125039,0 28.0795111,0.281507602 28.634375,0.84453125 C29.1892389,1.4075549 29.4666667,2.08437105 29.4666667,2.875 L29.4666667,15.525 C29.4666667,16.315629 29.1892389,16.9924451 28.634375,17.5554688 C28.0795111,18.1184924 27.4125039,18.4 26.6333333,18.4 L7.36666667,18.4 Z M0,19.55 L34,19.55 L34,21.275 C34,21.7541691 33.7225722,22.1614567 33.1677083,22.496875 C32.6128444,22.8322933 31.9458372,23 31.1666667,23 L2.83333333,23 C2.05416277,23 1.38715555,22.8322933 0.832291667,22.496875 C0.277427781,22.1614567 0,21.7541691 0,21.275 L0,19.55 Z M18.4166667,21.275 C18.6055565,21.275 18.7,21.1791676 18.7,20.9875 C18.7,20.7958324 18.6055565,20.7 18.4166667,20.7 L15.5833333,20.7 C15.3944435,20.7 15.3,20.7958324 15.3,20.9875 C15.3,21.1791676 15.3944435,21.275 15.5833333,21.275 L18.4166667,21.275 Z" id="" fill="#EF3E42" fill-rule="nonzero"></path>
          <path d="M6,2.58333333 L6,15.4166667 C6,15.5746536 6.06047393,15.711371 6.18142361,15.8268229 C6.30237329,15.9422749 6.44560102,16 6.61111111,16 L27.3888889,16 C27.554399,16 27.6976267,15.9422749 27.8185764,15.8268229 C27.9395261,15.711371 28,15.5746536 28,15.4166667 L28,2.58333333 C28,2.42534643 27.9395261,2.28862905 27.8185764,2.17317708 C27.6976267,2.05772512 27.554399,2 27.3888889,2 L6.61111111,2 C6.44560102,2 6.30237329,2.05772512 6.18142361,2.17317708 C6.06047393,2.28862905 6,2.42534643 6,2.58333333 Z" id="Path" fill="#AEE0E8"></path>
        </g>
      </g>
    </g>

  </svg>;

export default OnlineTools
