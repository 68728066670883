import React from 'react'

export const TPCAvatar = ({
                          style = {},
                          fill = '#fff',
                          width = '100%',
                          className = '',
                          height = '100%',
                          viewBox = '0 0 272.44 481.27',
                        }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <title>Asset 2</title><g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="tpc-avatar cls-1" d="M216.68,415.16A34.63,34.63,0,0,1,213.5,437c-.51,1-.38,2.69-.38,3.81a19.7,19.7,0,0,0,21.55.59c-.18-2-.81-2.93,0-4.76,6.54,2.42,12.92,5.28,19.89,5.48,6.06.18,12-1.35,17.91-2.88-.59-2.77.56-6.46-1.66-8.22a5.54,5.54,0,0,0-3.6-.8l-11.78.13a50.35,50.35,0,0,1-11.58-.77,16.81,16.81,0,0,1-9.84-5.78c-2.49-3.26-3.23-7.49-3.79-11.56"/>
      <path class="tpc-avatar cls-1" d="M24.13,420.89a34.71,34.71,0,0,1-20.56,8c-1.12,0-2.52,1-3.5,1.54a19.71,19.71,0,0,0,10.1,19.05c1.64-1.13,2.15-2.15,4.13-2.37,1.11,6.89,1.76,13.84,5,20,2.83,5.37,7.11,9.81,11.33,14.18,2.11-1.88,5.89-2.7,6.34-5.5a5.57,5.57,0,0,0-1.08-3.52L30,462.06a49.92,49.92,0,0,1-5-10.47,16.9,16.9,0,0,1,.18-11.4c1.62-3.78,1.68-4,4.94-6.5"/>
      <path class="tpc-avatar cls-2" d="M133.21,320.07c19.37,8.09,37.79,13,58.09,17.62,3.34.76,6.77,2.33,8.43,5.33a15.67,15.67,0,0,1,1.39,4.38c4.33,20.48,10.55,37.78,10,58.8A29.56,29.56,0,0,0,231.89,404c3.26-1.51-.9-17.51-1.36-20-2.52-13.85-4.19-28.22-7.93-41.8-2.91-10.56-8.46-21.28-19.93-24.11-6.91-1.71-5.22-1.63-12.12-3.35"/>
      <path class="tpc-avatar cls-2" d="M87.54,317.83c-1.42,15.75-5.72,30.21-10.26,45.25-1.74,5.77-3.71,11.61-7.38,16.4-5.33,6.94-12.75,11.91-19.67,17.13-7.35,5.54-13.87,12.06-21.35,17.44,1.52,5.28,8,10.1,9.56,15.39a385.24,385.24,0,0,0,54.74-37.63c9.54-7.85,10.75-16.12,13.67-27.62C111,347.88,117,332,120.58,315.6"/>
      <path class="tpc-avatar cls-3" d="M187.86,232.56c-1.54-12.35-3.08-24.7-5.13-41.16-2-15.71-12.12-29.12-24.44-34a34.4,34.4,0,0,0-10.88-4.93c-6.42-1.62-13.37-1.81-20-1.75-9.4.09-18.07,1.78-26.15,6.72a48.46,48.46,0,0,0-21.4,33.94c-2.28,18.24-3.92,31.42-5.63,45.15l-3.87,73.13H192.26Z"/>
      <path class="tpc-avatar cls-4" d="M247.86,240.53a9,9,0,0,0-5,.54c-7.24,2.78-9.59,15.05-4.65,20.61,8.51,9.58,40.33,5.78,28.12-10.47-3.45-.27-14.43-1.49-17.53-2.77"/>
      <line class="tpc-avatar cls-5" x1="247.86" y1="240.53" x2="257.04" y2="241.3"/>
      <path class="tpc-avatar cls-6" d="M269.11,258.1s-11.82,2.7-22.57-1.9"/>
      <path class="tpc-avatar cls-3" d="M228.76,242.25,127.27,179.07l-19,37.42L229.9,265.37A29.27,29.27,0,0,1,228.76,242.25Z"/>
      <circle class="tpc-avatar cls-7" cx="141.5" cy="86.85" r="62.56" transform="translate(-24.53 87.28) rotate(-31.69)"/>
      <path d="M217.3,73.38c.2-29.1-20.06-57.1-44.94-67.59C131.51-11.41,81.8,11.29,68.12,53a138,138,0,0,0-4.81,20.16v28.45a439.61,439.61,0,0,0,7.85,44.09,8.23,8.23,0,0,0,1.24.18c25.55,1.81,48.39,7.3,73.78,10.26a296.24,296.24,0,0,0,40.29,2.26c-8.65-12.53-15.17-26.11-21.56-39-6-12.19-8.89-24.85-10.24-37.67a307.87,307.87,0,0,0,47-2.78C207.18,78.17,212.91,77.08,217.3,73.38Z"/>
      <path class="tpc-avatar cls-8" d="M26.5,168.78H70a0,0,0,0,1,0,0v116.6a0,0,0,0,1,0,0H14.58a6.65,6.65,0,0,1-6.65-6.65V187.35A18.56,18.56,0,0,1,26.5,168.78Z" transform="translate(30 -3.14) rotate(7.51)"/>
      <rect class="tpc-avatar cls-9" x="36.8" y="182.75" width="26.81" height="33.24" rx="1" transform="translate(32.77 -5.48) rotate(9.27)"/>
      <path class="tpc-avatar cls-10" d="M54.46,200.79a1.44,1.44,0,0,1-1.23,1.07,1.64,1.64,0,0,1-1.67-2.41,6.25,6.25,0,0,0-1.93.09,1.63,1.63,0,0,1-1.06,2.52,1.5,1.5,0,0,1-1.68-.91,2.93,2.93,0,0,0-.55,1.82l1.92,50.18c.18,4.53,9,4.2,8.87-.34l-1.92-50.18A2.89,2.89,0,0,0,54.46,200.79Z"/>
      <path class="tpc-avatar cls-11" d="M54.11,202s-3-12.55-3.88-12.4-2.78,13.48-2.78,13.48"/>
      <line class="tpc-avatar cls-12" x1="16.72" y1="212.06" x2="64.01" y2="218.08"/>
      <polyline class="tpc-avatar cls-13" points="23.38 208.31 21.87 199.06 30.01 197.73 31.94 209.57"/>
      <line class="tpc-avatar cls-13" x1="22.23" y1="201.26" x2="30.37" y2="199.93"/>
      <path class="tpc-avatar cls-14" d="M100.6,168.91A154.67,154.67,0,0,1,136,165c20.47,0,25.54,34.34,25.54,34.34"/>
      <path class="tpc-avatar cls-14" d="M158.91,237.93s1.24,18-33.19,16.12c-20.24-1.09-43.48-7.8-43.48-7.8"/>
      <ellipse class="tpc-avatar cls-15" cx="188.73" cy="91.5" rx="4.76" ry="17.44"/>
      <line class="tpc-avatar cls-16" x1="183.97" y1="91.5" x2="161.52" y2="91.5"/>
    </g>
  </g>
  </svg>

export default TPCAvatar