import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import {displayLoadingIcon} from '../actions/loading-icon-action'
import {createSSContentVersion, createSuccessStory, setSuccessStory} from '../actions/success-stories-actions'
import ValidationService from '../services/validation-service'
import ClientStoriesForm from '../components/client-stories-form'



function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('client-stories')(state),//gets user validation errors on form level
    displayIcon: state.displayLoadingIcon,
    updateSuccessStory: state.updateSuccessStory
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSuccessStory(id) {
      dispatch(setSuccessStory(id))
    }
  }
}

const ClientStoriesFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({
    form: 'client-stories',
    validate: ValidationService.validateClientStoriesForm, // need name, phone validation and signature
    onSubmit: (values, dispatch, props) => {
      dispatch(displayLoadingIcon(true))
      return dispatch(createSuccessStory(values)).then(() => {
        // dispatch(createSSContentVersion(values))
        dispatch(displayLoadingIcon(false))
      })
    },
    destroyOnUnmount: false
  })(injectIntl(ClientStoriesForm))
)

export default ClientStoriesFormContainer
