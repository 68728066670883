import axios from "axios";
import {SubmissionError} from "redux-form";

export const SUBMIT_CREATE_ENROLLMENT = 'SUBMIT_CREATE_ENROLLMENT';
export const VERIFY_ENROLLMENT_CODE = 'VERIFY_ENROLLMENT_CODE'
export const SUBMIT_NEW_ENROLLMENT_CODE = 'SUBMIT_NEW_ENROLLMENT_CODE'

export const createEnrollment = data => {
    return (dispatch, getState) => {
        const login = getState().login;
        let mobilePhone = getState().registration.mobilePhone

        return axios
            .post('/api/login/create-enrollment', login, {params: {mobilePhone}})
            .then((response) => {
                console.log(response.data)
                dispatch({
                    type: SUBMIT_CREATE_ENROLLMENT,
                    login: response.data,
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const verifyEnrollmentCode = data => {
    return (dispatch, getState) => {
        const verifyTempCode = Object.assign({}, data)

        verifyTempCode.username = getState().login.username;
        verifyTempCode.contactId = getState().login.contactId;

        console.log(verifyTempCode)

        return axios
            .post('/api/login/verify-enrollment-code', verifyTempCode)
            .then((response) => {
                dispatch({
                    type: VERIFY_ENROLLMENT_CODE,
                    login: response.data
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}

export const resendEnrollmentCode = data => {
    return (dispatch, getState) => {
        const login = getState().login;

        return axios
            .post('/api/login/resend-enrollment-code', login)
            .then((response) => {
                dispatch({
                    type: SUBMIT_NEW_ENROLLMENT_CODE,
                    login: response.data
                })
            })
            .catch(error => {
                throw new SubmissionError({_error: error.response.data.message})
            })
    }
}