import React from 'react'

export const DclImpact2 = ({
                             style = {},
                             fill = '#fff',
                             width = '100%',
                             className = '',
                             height = '100%',
                             viewBox = '0 0 180 91.61',
                           }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <path d="M109.31,24.05c6.69,0,12.12-5.38,12.12-12.03S116,0,109.31,0s-12.12,5.38-12.12,12.02,5.43,12.03,12.12,12.03Z" fill="#00c7b1"/>
        <path d="M83.94,51.92v-4.68c0-11.3,9.23-20.45,20.61-20.45h9.51c11.38,0,20.61,9.16,20.61,20.45v4.68c0,1.9-1.55,3.44-3.46,3.44h-43.8c-1.91,0-3.46-1.54-3.46-3.44Z" fill="#00c7b1"/>
        <path d="M77.26,35.98c7.82,0,14.17-6.29,14.17-14.06s-6.34-14.06-14.17-14.06-14.17,6.29-14.17,14.06,6.34,14.06,14.17,14.06Z" fill="#003057"/>
        <path d="M47.61,68.57v-5.48c0-13.21,10.79-23.91,24.1-23.91h11.11c13.31,0,24.1,10.71,24.1,23.91v5.48c0,2.22-1.81,4.02-4.05,4.02h-51.21c-2.23,0-4.05-1.8-4.05-4.02Z" fill="#003057"/>
        <path d="M38.38,44.21c10.12,0,18.32-8.14,18.32-18.17S48.5,7.86,38.38,7.86s-18.32,8.14-18.32,18.17,8.2,18.17,18.32,18.17Z" fill="#00c7b1"/>
        <path d="M0,86.41v-7.08c0-17.08,13.96-30.94,31.18-30.94h14.39c17.21,0,31.18,13.86,31.18,30.94v7.08c0,2.88-2.34,5.2-5.24,5.2H5.26c-2.9,0-5.24-2.32-5.24-5.2h-.02Z" fill="#00c7b1"/>
        <path d="M141.6,44.21c10.12,0,18.32-8.14,18.32-18.17s-8.2-18.17-18.32-18.17-18.32,8.14-18.32,18.17,8.2,18.17,18.32,18.17Z" fill="#003057"/>
        <path d="M103.25,86.41v-7.08c0-17.08,13.96-30.94,31.18-30.94h14.39c17.21,0,31.18,13.86,31.18,30.94v7.08c0,2.88-2.34,5.2-5.24,5.2h-66.24c-2.9,0-5.24-2.32-5.24-5.2h-.02Z" fill="#003057"/>
      </g>
    </g>

  </svg>;

export default DclImpact2