import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import ActionPlanFeedbackFormContainer from "../../containers/action-plan/action-plan-feedback-form-container";
import FooterActionPlan from '../footer-action-plan';
import ActionPlanNav from "./action-plan-nav";
import {displayLinkMoneyModal} from "../../actions/link-money-actions";

export default class ActionPlanFeedback extends React.Component {

    constructor(props){
        super(props);
        this.state={
            displayForm: true
        }
    }

    componentDidMount() {
        this.props.displayLinkMoneyModal()
    }

    setFormSubmit(truthy){
        let submitState = truthy === true
        this.setState({
            displayForm: !submitState
        })
    }

    render() {

        const {intl, formSyncErrors}=this.props;

        return (
            <div id='ap-feedback' >

                <ActionPlanNav />
                <div id='action-plan-content'>

                  <div className='action-plan-title-background-gradient'></div>
                    <div className='action-plan-title-wrapper margin-bottom-24-px'>
                        <div className='action-plan-title margin-bottom-8-px'>
                            <FormattedMessage id='app.action-plan-feedback.page-title' />
                        </div>
                    </div>
                            {this.state.displayForm ?
                                <ActionPlanFeedbackFormContainer verifyToken={()=> this.props.verifyToken()} setFormSubmit={(truthy)=> this.setFormSubmit(truthy)}/>
                                :
                              <div className='feedback-container'>
                                <div className='my-account-container'>
                                  <div className='feedback-thank-you-text margin-bottom-40-px'>
                                    <FormattedMessage id='app.action-plan-feedback.thank-you'/>
                                  </div>
                                </div>
                              </div>
                            }
                  <FooterActionPlan />
                </div>
            </div>
        )
    }
}
