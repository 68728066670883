import {CHANGE_LOCALE} from '../actions/locale-actions'

export default function locale(state = 'en-US', action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return action.locale
    default:
      return state
  }
}
