import React from 'react'
import {Link} from 'react-router-dom'
import FormattedMessage from './helpers/FormattedMessage'
import Icon from "../../resources/icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import NoEmployerCode from '../components/no-employer-code'
import IntroductionPageCounselors from '../components/introduction-page-counselors'

export default class Clear extends React.Component {

  constructor(props) {
    super(props);

    this.props.clearReduxStore();

    this.props.history.push('/');
  }

  render() {
    return (
      <div id='clear'/>
    )
  }
}
