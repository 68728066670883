import React from 'react'
import {FormattedMessage} from 'react-intl'

export default class DebtSummaryWaiting extends React.Component { 
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div id='debt-summary-waiting'>

        <div className='debt-summary-box-wrapper margin-bottom-24-px'>

          <div className='debt-summary-box-inside'>
            <div className='debt-summary-box-title margin-bottom-24-px'>
              <FormattedMessage id='app.action-plan-debt-summary.waiting-subtitle'/>
            </div>
            <p>
            <FormattedMessage id='app.action-plan-debt-summary.waiting-text'/>
            </p>
          </div>
            <div className='clock'>
              <div className='clock-360px'>
                <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/clocks/clock-360px.png`} />
              </div>
              <div className='clock-649px'>
                <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/clocks/clock-649px.png`} />
              </div>
              <div className='clock-1199px'>
                <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/clocks/clock-1199px.png`} />
              </div>
              <div className='clock-1200px'>
                <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/clocks/clock-1200px.png`} />
              </div>
            </div>
          </div>
        <div className='clear-float'></div>
        </div>
      )}
}