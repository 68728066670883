import React from 'react'

export const BudgetJarEmpty = ({
                               style = {},
                               fill = '',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 80 141',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g id="Page-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="budget---styles" transform="translate(-132.000000, -176.000000)">
        <g id="Asset-7" transform="translate(132.000000, 177.000000)">
          <path d="M78.8950276,64.8718232 L78.8950276,61.878453 C78.8950276,51.2508287 79.080663,41.3502762 72.0497238,32.880663 L72.0497238,32.880663 C72.0497238,32.880663 70.1082873,29.5933702 70.1082873,26.7392265 C70.0522319,25.1963143 70.315563,23.6584603 70.881768,22.2220994 L8.43867403,21.3248619 C8.43867403,21.3248619 10.0475138,29.8331492 8.06740331,32.4165746 C1.82541436,39.6099448 0.626519337,48.9458564 0.773480663,58.1270718 C1.26850829,86.3436464 0.773480663,75.1436464 0.773480663,103.352486 L0.773480663,114.475138 C0.773480663,128.861878 17.2176796,139.59779 30.3668508,139.59779 L49.3171271,139.59779 C62.520442,139.59779 78.9027624,128.769061 78.9027624,114.475138 L78.9027624,110.00442 C78.8950276,81.8110497 78.8950276,93.0729282 78.8950276,64.8718232 Z" id="Path" fill="#D3F0F7" fillRule="nonzero"></path>
          <path d="M66.8055249,127.624309 C71.160221,125.071823 73.8828729,119.116022 65.6607735,116.625414 C57.438674,114.134807 38.2640884,114.567956 38.2640884,114.567956 C38.2640884,114.567956 19.5458564,114.908287 14.7502762,117.313812 C9.95469613,119.719337 7.67292818,125.048619 11.7801105,127.585635" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M60.8729282,131.924862 C54.6850829,136.441989 37.1270718,135.119337 28.9049724,133.974586" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <ellipse id="Oval" fill="#D3F0F7" fillRule="nonzero" cx="39.3237569" cy="22.8254144" rx="31.0475138" ry="6.85303867"></ellipse>
          <path d="M8.44640884,21.3248619 C8.44640884,21.3248619 6.58232044,24.5348066 10.1867403,25.9657459 C13.7911602,27.3966851 21.3712707,28.7038674 27.5359116,28.9359116" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M70.920442,22.2298343 C70.920442,22.2298343 71.9414365,24.1944751 68.6,25.5712707 C65.2585635,26.9480663 57.639779,28.920442 54.6773481,28.920442" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M72.0574586,16.359116 L72.0574586,6.7679558 C72.0574586,6.7679558 63.1546961,0.379005525 39.841989,0.379005525 C16.5292818,0.379005525 6.76022099,6.31160221 6.76022099,6.31160221 L6.76022099,16.3668508 C6.76022099,16.3668508 4.70276243,16.3668508 4.70276243,17.9679558 C4.70276243,19.5690608 11.0994475,26.0662983 39.8651934,26.0662983 C68.6309392,26.0662983 73.9370166,20.3348066 73.9370166,18.0530387 C73.9370166,16.5911602 72.0574586,16.359116 72.0574586,16.359116 Z" id="Path" stroke="#615D59" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M6.76022099,6.31160221 C6.76022099,6.31160221 12.5922652,13.9226519 39.841989,13.9226519 C67.0917127,13.9226519 72.0574586,6.7679558 72.0574586,6.7679558" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M10.3414365,14.5723757 C25.478453,19.4762431 41.9226519,18.5016575 57.6707182,17.5270718 C61.1049724,17.0165746 64.5237569,16.5447514 67.7723757,15.160221" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M11.4707182,9.42872928 L11.4707182,14.9900552" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M14.2939227,11.1071823 L14.2939227,15.5624309" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M17.1480663,11.679558 L17.1480663,16.1348066" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M20.0022099,12.2519337 L20.0022099,16.6994475" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M22.8563536,12.8243094 L22.8563536,17.2718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M25.7104972,13.3889503 L25.7104972,17.8441989" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M28.5646409,13.961326 L28.5646409,18.4165746" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M31.4187845,14.0773481 L31.4187845,18.5248619" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M34.2729282,14.2243094 L34.2729282,18.6718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M37.1270718,13.6209945 L37.1270718,18.0762431" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M39.9812155,13.9071823 L39.9812155,18.3624309" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M42.8353591,14.0232044 L42.8353591,18.4707182" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M45.6895028,14.2243094 L45.6895028,18.6718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M48.5436464,13.9071823 L48.5436464,18.3546961" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M51.3977901,13.9071823 L51.3977901,18.3546961" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M54.2519337,13.4508287 L54.2519337,17.8983425" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M57.1060773,12.7624309 L57.1060773,17.2176796" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M59.9524862,12.7624309 L59.9524862,17.2176796" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M62.8066298,11.7337017 L62.8066298,16.1889503" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M65.6607735,10.9911602 L65.6607735,15.4464088" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
        </g>
      </g>
    </g>
  </svg>;

export default BudgetJarEmpty