import Icon from "../../../resources/icons";
import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";

export default function ActionPlanDashboardDcl() {

    return (
        <div className='action-plan-dashboard-accounts-linked-container'>
            <div className='action-plan-dashboard-settings-sessions-title'>
                <div className='action-plan-dashboard-settings-session-title-text-area'>
                    <div className='dashboard-icon dcl'>
                        <Icon name='dcl-dashboard'/>
                    </div>
                    <div className='dcl-dashboard-title'>
                        <FormattedMessage id='app.action-plan-dashboard.dcl.title' />
                    </div>
                </div>
            </div>
            <div className="action-plan-dashboard-accounts-linked">
                <div className='dashboard-main-wrapper action-plan-dashboard-accounts-linked-width'>
                    <div className='dashboard-main'>
                        <div className='action-plan-dashboard-accounts-linked-text-area'>
                            <FormattedMessage id='app.action-plan-dashboard.dcl.description'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}