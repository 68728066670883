import {connect} from 'react-redux'
import {injectIntl} from "react-intl";
import EmergencyResponseHeader from "../../components/emergency-response/emergency-response-header";

function mapStateToProps(state) {
  return {
    locale: state.locale,
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

const EmergencyResponseHeaderContainer = connect(
  mapStateToProps,
    mapDispatchToProps
)(injectIntl(EmergencyResponseHeader))

export default EmergencyResponseHeaderContainer
