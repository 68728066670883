import React, {useEffect, useState} from 'react'
import FormattedMessage from './../helpers/FormattedMessage'
import FooterContainer from '../../containers/footer-container'

import HeaderContainer from "../../containers/header-container";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  getEnglishCurrentlyWorkingCounselors,
  getSpanishCurrentlyWorkingCounselors,
  setActiveCounselor, setActiveCurrentlyWorkingCounselors
} from "../../actions/counselor-actions";

function BFlowPickACoach (props) {
  const locale = useSelector(state => state.locale)
  const login = useSelector(state => state.login)
  const activeCurrentlyWorkingCounselors = useSelector(state => state.activeCurrentlyWorkingCounselors)
  const activeCounselor = useSelector(state => state.activeCounselor)

  const dispatch = useDispatch()
  let history = useHistory()

  useEffect( () => {
    if (login === null) {
      history.push(`/enter-your-email-address`)
    }

    if (activeCurrentlyWorkingCounselors === null){
      dispatch(getEnglishCurrentlyWorkingCounselors()).then(() => {
        dispatch(getSpanishCurrentlyWorkingCounselors()).then(() => {
          dispatch(setActiveCurrentlyWorkingCounselors())
        })
      })
    }
  }, [])

  const selectCounselor = (counselor) => {
    dispatch(setActiveCounselor(counselor))
  }

  const goToSchedule = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()
    history.push('/pick-a-time')
  }

  return (
    activeCurrentlyWorkingCounselors && <div>
      <div className='b-flow'>
        <HeaderContainer displayAlert={null}
                         alertName={null}/>

        <div className='container-ap ptxxl'>
          <h1><FormattedMessage id='app.b-flow.appointment-select-coach.title'/></h1>

          <div className='select-coach-bio-container'>
            <div className='all-counselor-select-container'>
              <h3 className='coach-select-subtitle-bold margin-bottom-24-px'>
                <FormattedMessage id='app.b-flow.appointment-select-coach.instruction.2'/>
              </h3>
              <div className={`counselor-select-container ${activeCounselor ? 'one-image-selected' : ''}`}>
                {
                  activeCurrentlyWorkingCounselors.map(counselor => {
                    return (
                      <div className={`counselor-b-flow-image-container ${activeCounselor && activeCounselor.userId === counselor.userId ? 'counselor-selected' : ''}`}>
                        <button onClick={()=>selectCounselor(counselor)}>
                          {
                            counselor.imageUrl ?
                              <img alt={`${counselor.firstName} ${counselor.lastName}`} src={`${counselor.imageUrl}`}/>
                              : <div className='counselor-image-default img-circle'/>
                          }
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='coach-bio desktop'>
              { activeCounselor ?
                <div>
                  <h3 className='coach-bio-header'> About {activeCounselor.firstName}</h3>
                  { locale === 'en-US' ?
                    <p>{activeCounselor.aboutMe}</p> :
                    <p>{activeCounselor.aboutMeSpanish}</p>
                  }
                </div>:
                <div>
                  <h3 className={'coach-bio-header'}> <FormattedMessage id='app.b-flow.appointment-select-coach.instruction.3'/></h3></div>
              }
            </div>
          </div>
          {
            activeCounselor && <div>
              <div className='select-counselor-btn-wrapper'>
                <button onClick={e => goToSchedule(e)}>
                  <FormattedMessage id='app.b-flow.pick-a-time.with'/>{activeCounselor.firstName}
                </button>
              </div>
              <div className='coach-bio mobile'>
                <h3 className='coach-bio-header'> About {activeCounselor.firstName}</h3>
                { locale === 'en-US' ?
                  <p>{activeCounselor.aboutMe}</p> :
                  <p>{activeCounselor.aboutMeSpanish}</p>
                }
              </div>
            </div>
          }

          <div className='text-ap additional-links'>
            <Link to={`/pull-your-credit-report`} id='skip'>
              <FormattedMessage id='app.b-flow.appointment-select-coach.text.skip'/>
            </Link>
          </div>
        </div>
        <FooterContainer/>
      </div>
    </div>
  )
}

export default BFlowPickACoach
