import React from 'react'

import NoEmployerCode from '../components/no-employer-code'
import LandingPagesFooter from "./landing-pages/landing-pages-footer";
import LandingPagesHelpYou from "./landing-pages/landing-pages-help-you";
import LandingPagesTagline from "./landing-pages/landing-pages-tagline";
import LandingPagesHeader from "./landing-pages/landing-pages-header";
import FormattedMessage from "./helpers/FormattedMessage";

export default class LandingSevenSevenFive extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      displayBlueHeader: false
    }

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if (window.scrollY >= 50 && this.state.displayBlueHeader === false) {
      this.setState({displayBlueHeader: true})
    }
    if (window.scrollY < 50 && this.state.displayBlueHeader === true) {
      this.setState({displayBlueHeader: false})
    }
  };

  goToAppointmentSchedule() {
    this.props.history.push('/enter-your-email-address')
  }

  goToWelcomePage() {
    this.props.history.push(`/welcome`)
  }

  goToAskQuestion() {
    this.props.history.push('/ask-question')
  }

  goToTrustPlus() {
    this.props.history.push(`/${this.props.employer.code}/trustplus-credit`)
  }

  onLocaleToggle(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.props.onLocaleToggle(this.props.locale);
  }

  render(){
    return (
      this.props.employer && <div id='intro-775'>
        {this.props.employer.code === 'err' ?
          <NoEmployerCode/>
          :
          <div>

            <LandingPagesHeader displayBlueHeader={this.state.displayBlueHeader}
                                goToWelcomePage={() => this.goToWelcomePage()}
                                goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
                                displayLocaleToggle={true}
                                onLocaleToggle={(e) => this.onLocaleToggle(e)}
            />

            <div className='intro-775-hero-video'>
              <span role="img" aria-label='A video with the heading of A Message From Lillian Mancebo of SEIU 775 Benefits Group.'>
              </span>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/4-7C8UprdQQ?controls=0"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen>

              </iframe>
            </div>

              <div className='landing-775-intro-wrapper'>
                <div className='seven-seven-five-box'>
                  <div className='welcome-landing-page-775'>
                    <strong><FormattedMessage id='app.landing-page.775.intro-text-1'/></strong>
                    <FormattedMessage id='app.landing-page.775.intro-text-2'/>
                    <strong><FormattedMessage id='app.landing-page.775.intro-text-3'/></strong>
                    <FormattedMessage id='app.landing-page.775.intro-text-4'/>
                    <strong><FormattedMessage id='app.landing-page.775.intro-text-5'/> </strong>
                    <FormattedMessage id='app.landing-page.775.intro-text-6'/>
                    <strong><FormattedMessage id='app.landing-page.775.intro-text-7'/></strong>
                    <FormattedMessage id='app.landing-page.775.intro-text-8'/>
                    <strong><FormattedMessage id='app.landing-page.775.intro-text-9'/></strong>
                    <FormattedMessage id='app.landing-page.775.intro-text-10'/>
                  </div>
                  <div className='welcome-seven-seven-five-subtitle'>
                    <FormattedMessage id='app.landing-page.775.intro-text-11'/>
                  </div>

                  <div className='submit-button-round btn-propel-landing'>
                    <button className='btn btn-primary btn-block btn-propel-landing'
                            id='submit'
                            onClick={this.goToAppointmentSchedule.bind(this)}>
                      <FormattedMessage id='app.landing-page.775.intro-text-12'/>
                    </button>
                  </div>
                </div>
              </div>

            <div id='landing-pages-help-you'>
              <div className='how-we-have-helped bluebg'>
                <div className='landing-propel-container'>
                  <div className='propel-section-header how-we-have-helped-header-1'>
                    <FormattedMessage id='app.landing-page.775.how-we-help-1'/>
                  </div>
                  <div className='how-we-have-helped-wrapper'>
                    <div className='how-we-have-helped-col-1'>
                      <div className='testimonial-video propel-testimonial-video'>
                        <iframe width="auto" height="360" src="https://www.youtube.com/embed/K6-Vg9fGqVA"
                                title="Video preview of a testimonial from a client" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className='how-we-have-helped-col-2'>
                      <div className='how-we-have-helped-content-wrapper'>
                        <div className='propel-section-header how-we-have-helped-header-2'>
                          <FormattedMessage id='app.landing-page.775.how-we-help-1'/>
                        </div>
                        <div className='propel-section-text'>
                          <p>
                            <FormattedMessage id='app.landing-page.775.how-we-help-2'/>
                          </p>
                          <p>
                            <FormattedMessage id='app.landing-page.775.how-we-help-3'/>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LandingPagesHelpYou goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
              goToAskQuestion={() => this.goToAskQuestion()}
              goToTrustPlus={() => this.goToTrustPlus()}
            />

            <LandingPagesTagline goToAppointmentSchedule={() => this.goToAppointmentSchedule()}
            />

            <LandingPagesFooter/>
          </div>
        }
      </div>
    )
  }
}
