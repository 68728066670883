import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";

import {useDispatch, useSelector} from "react-redux";
import CustomerPortalProgressBar from "../helpers/CustomerPortalProgressBar";
import CustomerPortalNav from "./customer-portal-nav";
import CustomerPortalFooter  from './customer-portal-footer';
import CustomerPortalContact from "./customer-portal-contact";
import {useHistory} from "react-router-dom";


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

function CustomerPortalContractDetailsTwo(props) {

    const totalEmployees = useSelector(state => state.edsContract.totalEmployees);
    const currentWebniars = useSelector(state => state.edsContract.extraWebinars)
    const costPerWebinar = useSelector(state => state.edsContract.costPerExtraWebinar)
    const costPerEmployee = useSelector(state => state.edsContract.costPerEmployee)
    const implementationFee = useSelector(state => state.edsContract.implementationFee)

    let currentPrice = formatter.format(totalEmployees * costPerEmployee);
    let currentWebniarCost = formatter.format(currentWebniars * costPerWebinar);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect( () => {
      window.scrollTo(0, 0)
    }, [])

    const ask = (e) => {
      e.preventDefault()
      history.push('/customer-portal/messaging')
    }

    const goBackToDetailsOnePage = () => {
      history.push('/customer-portal/contracts/details-one')
    }

    const goToDetailsThreePage = () => {
      history.push('/customer-portal/contracts/details-three')
    }

    const handleViewSteps = (e) => {
      e.preventDefault()
      history.push('/customer-portal/contracts/view-all-steps')
    }

    return(
        <div id='customer-portal'>
          <CustomerPortalNav />
          <div id='customer-portal-content'>
          <div id='customer-portal-contracts' className='customer-portal-pages'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <div className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.customer-portal.contracts-header'/>
            </div>
          </div>

          <div className='customer-portal-dashboard-container'>

            <div className='customer-portal-back-link' onClick={goBackToDetailsOnePage}>
              <FormattedMessage id='app.customer-portal-common.back-link'/>
            </div>
            <h2><FormattedMessage id='app.customer-portal-contracts.about-you.heading'/></h2>
            <div className='contract-content-wrapper'>
              <div className='contract-content-intro-wrapper'>
                <div className='contract-content-intro-text'>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you.text-1'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you-text-2'/>
                  </div>
                </div>
                <CustomerPortalProgressBar pageNum={3}
                                           handleViewSteps={(e) => handleViewSteps(e)}
                />
              </div>

              <div className='contracts-form-container'>
                <div className='contracts-form-title-container'>
                  <div className='contracts-form-title'>
                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3a'/>
                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3b'/>

                  </div>
                </div>
                <div className='contracts-form-wrapper'>
                  <div className='contracts-prefill-wrapper'>
                    <div className='contracts-prefilled-label'>
                      <label htmlFor='full-name'>
                        Number of Eligible Employees at the Company
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      {totalEmployees}
                    </div>
                  </div>

                  <div className='contracts-prefill-wrapper'>
                    <div className='contracts-prefilled-label'>
                      <label htmlFor='full-name'>
                        Per Employee/Year Rate
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      { formatter.format(costPerEmployee) }
                    </div>
                  </div>

                  <div className='contracts-prefill-wrapper'>
                    <div className='contracts-prefilled-label'>
                      <label htmlFor='full-name'>
                        Price
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      {currentPrice}
                    </div>
                  </div>

                  <div className='contracts-prefill-wrapper'>
                    <div className='contracts-prefilled-label'>
                      <label htmlFor='full-name'>
                        Additional Webinars
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      {currentWebniarCost}
                    </div>
                  </div>

                  <div className='contracts-prefill-wrapper'>
                    <div className='contracts-prefilled-label'>
                      <label htmlFor='full-name'>
                        Implementation Fee
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      { formatter.format(implementationFee) }
                    </div>
                  </div>


                  <div className='contracts-contact-us-text'>
                    <p>
                      <FormattedMessage id='app.customer-portal-contracts.form.footer.contract-details'/>
                    </p>
                    <FormattedMessage id='app.customer-portal-contracts.form.footer-text-1'/>
                    <a href="#" onClick={(e) => ask(e)}><FormattedMessage id='app.customer-portal-contracts.form.footer-text-2'/></a>
                    <FormattedMessage id='app.customer-portal-contracts.form.footer-text-3'/>
                  </div>
                </div>
                <div className='submit-button-round continue'>
                  <button
                    className="btn btn-primary btn-block"
                    id="visit-onboarding"
                    onClick={goToDetailsThreePage}>
                    <FormattedMessage id='app.common.continue.button'/>
                  </button>
                </div>
              </div>
            </div>

              <CustomerPortalFooter />
              <CustomerPortalContact />

          </div>
        </div>
        </div>
      </div>
    )
}

export default (injectIntl(CustomerPortalContractDetailsTwo))
