import React from 'react'

export const Checkmark = ({
                               style = {},
                               fill = '#141006',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 39 39',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="m33.712 9.613 2.998 2.997a2.588 2.588 0 0 1 0 3.66L16.597 36.382a2.588 2.588 0 0 1-3.66 0l-4.826-4.827L30.053 9.613a2.588 2.588 0 0 1 3.66 0z" fill="#003057"/>
    <path d="m21.274 31.705-4.677 4.678a2.588 2.588 0 0 1-3.66 0L2.157 25.602a2.588 2.588 0 0 1 0-3.66l2.848-2.847a2.588 2.588 0 0 1 3.659 0l12.61 12.61z" fill="#003057"/>  </svg>;

export default Checkmark