import React from 'react'

export const LinkedIn = ({
                           style = {},
                           fill = '#fff',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 18 18',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="headerandfooter_w360-(displaywidths:-320-649)" transform="translate(-101.000000, -4045.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="linkedin" transform="translate(98.000000, 4042.000000)">
          <polygon id="Path" points="7.25357143 20.5714286 3.69642857 20.5714286 3.69642857 9.11785714 7.25892857 9.11785714 7.25892857 20.5714286"></polygon>
          <path d="M5.475,7.55357143 C4.33392857,7.55357143 3.4125,6.62678571 3.4125,5.49107143 C3.4125,4.35535714 4.33392857,3.42857143 5.475,3.42857143 C6.61071429,3.42857143 7.5375,4.35535714 7.5375,5.49107143 C7.5375,6.63214286 6.61607143,7.55357143 5.475,7.55357143 L5.475,7.55357143 Z" id="Path"></path>
          <path d="M20.5875,20.5714286 L17.0303571,20.5714286 L17.0303571,15 C17.0303571,13.6714286 17.0035714,11.9625 15.1821429,11.9625 C13.3285714,11.9625 13.0446429,13.4089286 13.0446429,14.9035714 L13.0446429,20.5714286 L9.4875,20.5714286 L9.4875,9.11785714 L12.9,9.11785714 L12.9,10.6821429 L12.9482143,10.6821429 C13.425,9.78214286 14.5875,8.83392857 16.3178571,8.83392857 C19.9178571,8.83392857 20.5875,11.2071429 20.5875,14.2928571 L20.5875,20.5714286 L20.5875,20.5714286 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </svg>;

export default LinkedIn