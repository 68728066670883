import React, {useEffect, useState} from 'react';
import FormattedMessage from '../helpers/FormattedMessage'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {displayLinkMoneyModal, getLinkedAccounts} from "../../actions/link-money-actions";

export default function ActionPlanLinkMoneyModal(props) {

    const [modal, setModal] = useState(true)

    const linkedAccounts = useSelector(state => state.linkMoneyAccounts)

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if(linkedAccounts && linkedAccounts.length > 0){
            dispatch(displayLinkMoneyModal(false))
        } else if (modal == false){
            dispatch(displayLinkMoneyModal(false))
        } else {
            dispatch(displayLinkMoneyModal(true))
        }
    })

    function linkAccounts() {
        dispatch(displayLinkMoneyModal(false))
        history.push('/action-plan/link-money/get-started')
    }

    function closeModal() {
        setModal(false)
        dispatch(displayLinkMoneyModal(false))
    }

    return (
        <div className="link-money-modal">

            <div className="link-money-modal-body" style={{ transform: modal ? 'none' : 'translateY(-100vh)', opacity: modal ? '1' : '0', display: modal ? 'block' : 'none'}}>
                <div className='link-money-modal-pointer'></div>

                <div className='link-money-modal-title margin-bottom-24-px'>
                    <FormattedMessage id='app.action-plan-link-money-modal.title'/>
                </div>

                <div className='link-money-modal-text margin-bottom-24-px'>
                    <a onClick={() => linkAccounts()}>
                        <FormattedMessage id='app.action-plan-link-money-modal.link'/>
                    </a>
                    <FormattedMessage id='app.action-plan-link-money-modal.description'/>
                </div>

                <div className='link-money-modal-cancel'>
                   <a onClick={(e) => closeModal()}>
                        <FormattedMessage id='app.action-plan-link-money.button.cancel'/>
                    </a>
                </div>

            </div>
        </div>
    )


}


