export default class DebtSummaryChartService {

  static buildDebtCategoryChartData(debtCategories, totalDebt) {
      const secondDashArray = 465
      const activeCategories = []
      const inactiveCategories = []
      const activeAccounts = debtCategories.map((activeAccount) => {
        activeAccount.amount > 0 ? activeCategories.push(activeAccount) : []
        return activeCategories;
        });
      const inactiveAccounts = debtCategories.map((inactiveAccount) => {
        inactiveAccount.amount == 0 ? inactiveCategories.push(inactiveAccount) : []
        return inactiveCategories;
      });

      // collect all accounts, push accounts with 0 balance to end
      const accounts = activeCategories.concat(inactiveCategories);
      let activeCategoriesCount = activeCategories.length
      let radiusMultiplier = 465
      //tweak the radius value to shrink dash array values to better render based on # of sections a report contans
      if (activeCategoriesCount <= 7 && activeCategoriesCount >= 2 ) {
        radiusMultiplier = (465 - (activeCategoriesCount * 2)) 
      }
      if (activeCategoriesCount === 1) {
        radiusMultiplier = 465
      }

      for(var i=0; i < accounts.length; i++) {
        accounts[i].precent = ((accounts[i].amount /totalDebt).toPrecision(6) *100).toFixed(4); 
        if (accounts[i].precent == 0) {
          accounts[i].dasharray1 = '0';
        };
        if (accounts[i].precent < 1 && accounts[i].precent != 0) {
          accounts[i].dasharray1 = '3';
        };
        if (accounts[i].precent > 1 &&  accounts[i].precent < 3 && accounts[i].precent != 0) {
          accounts[i].dasharray1 = '5';
        };
        if (accounts[i].precent > 2 && accounts[i].precent != 0) {
          accounts[i].dasharray1 = (JSON.stringify (
            ((accounts[i].amount / totalDebt).toPrecision(4) * radiusMultiplier).toFixed(0) - 0)
          );
        };
        if (accounts[i].dasharray1 != null) {
          accounts[i].dasharray = accounts[i].dasharray1.concat(" ", secondDashArray);
        }
        if (accounts[0].amount != 0) {
          accounts[0].offset = 0 
        }

        if (accounts.length > 1) {
          if (accounts[1].amount === 0) {
            accounts[1].offset = 0;
          }
          accounts[1].amount != 0 ? accounts[1].offset = ((-accounts[0].dasharray1) - 2) : 0
          totalDebt == 0 ? accounts[1].sectionColor = "color-0" : 0
        }

        if (accounts.length > 2) {
          if (accounts[2].amount === 0) {
            accounts[2].offset = 0;
          }
          accounts[2].amount != 0 ? accounts[2].offset = ((accounts[1].offset - accounts[1].dasharray1) - 2) : 0
          totalDebt == 0  ? accounts[2].sectionColor = "color-0" : 0
        }
        if (accounts.length > 3) {
          if (accounts[3].amount === 0) {
            accounts[3].offset = 0;
          }
          accounts[3].amount != 0 ? accounts[3].offset = ((accounts[2].offset - accounts[2].dasharray1) - 2) : 0
          totalDebt == 0  ? accounts[3].sectionColor = "color-0" : 0
        }
        if (accounts.length > 4) {
          if (accounts[4].amount === 0) {
            accounts[4].offset = 0;
          }
          accounts[4].amount != 0 ? accounts[4].offset = ((accounts[3].offset - accounts[3].dasharray1) - 2) : 0
          totalDebt == 0  ? accounts[4].sectionColor = "color-0" : 0
        }
        if (accounts.length > 5) {
          if (accounts[5].amount === 0) {
            accounts[5].offset = 0;
          }
          accounts[5].amount != 0 ? accounts[5].offset = ((accounts[4].offset - accounts[4].dasharray1) - 2) : 0
          totalDebt == 0  ? accounts[5].sectionColor = "color-0" : 0
        }
        if (accounts.length > 6) {
          if (accounts[6].amount === 0) {
            accounts[6].offset = 0;
          }
          accounts[6].amount != 0 ? accounts[6].offset = ((accounts[5].offset - accounts[5].dasharray1) - 2) : 0
          totalDebt == 0  ? accounts[6].sectionColor = "color-0" : 0
        }

      }
    console.table(accounts);
    return accounts
  }

}