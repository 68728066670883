import React from 'react'

export const NervousEmoji = ({
                               style = {},
                               fill = '#000000',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 460 460',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-2" transform="translate(120.000000, 114.000000)" fill="#FFFFFF" stroke="#AEE0E8" stroke-width="16">
        <g id="Group" transform="translate(0.495858, 0.603726)">
          <circle id="Oval" cx="109" cy="109" r="101"></circle>
        </g>
      </g>
      <path d="M173.913775,220.588291 L287.363103,286.088291" id="Line-2" stroke="#AEE0E8" stroke-width="8" strokeLinecap="square" transform="translate(230.538130, 253.588291) scale(1, -1) rotate(-30.000000) translate(-230.538130, -253.588291) "></path>
      <path d="M170.55194,236.852794 L216.44806,263.350931" id="Line-2" stroke="#AEE0E8" stroke-width="8" strokeLinecap="square" transform="translate(193.500000, 250.101863) scale(1, -1) rotate(60.000000) translate(-193.500000, -250.101863) "></path>
      <path d="M205.55194,236.852794 L251.44806,263.350931" id="Line-2" stroke="#AEE0E8" stroke-width="8" strokeLinecap="square" transform="translate(228.500000, 250.101863) scale(1, -1) rotate(60.000000) translate(-228.500000, -250.101863) "></path>
      <path d="M240.55194,236.852794 L286.44806,263.350931" id="Line-2" stroke="#AEE0E8" stroke-width="8" strokeLinecap="square" transform="translate(263.500000, 250.101863) scale(1, -1) rotate(60.000000) translate(-263.500000, -250.101863) "></path>
      <rect id="Rectangle" stroke="#AEE0E8" stroke-width="8" x="160" y="219" width="140" height="63" rx="22"></rect>
      <circle id="Oval" fill="#AEE0E8" cx="199.5" cy="176.6" r="16"></circle>
      <circle id="Oval" fill="#AEE0E8" cx="257.5" cy="176.6" r="16"></circle>
    </g>

  </svg>;

export default NervousEmoji