import React from 'react'
import Icon from '../../../resources/icons'
import FormattedMessage from '../helpers/FormattedMessage'
import {CSSTransition} from 'react-transition-group'

export default class ActionPlanMobileHomeSelectedItem extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      startTextFadeTransition: false,
      startNavTransition: false,
      displayText: true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentComponent != prevProps.currentComponent && this.props.currentComponent != '') {
      this.setState({
        startTextFadeTransition: true,
      })
    }

  }

  render() {
      return (
        <div style={{position: 'absolute', top: 0, left: 0, width: window.innerWidth + 'px'}}>
          <CSSTransition
            in={this.state.startNavTransition}
            timeout={500}
            classNames="nav-transition"
            onEntered={() => {
              this.props.clearCurrentTempComponent()
            }}
          >
            <div className='nav-page-box nav-page-box-active'>
              <div className='mobile-home-selected nav-page-white-box-container'>
                <div className='nav-page-logo-wrapper'>
                  <div className='nav-page-logo'>
                    <Icon fill="#000000" name={this.props.icon}/>
                  </div>
                </div>
                <div className='mobile-home-selected nav-page-name-wrapper'>
                  <div className='nav-page-name' style={{display: this.state.displayText ? 'block' : 'none'}}>
                    <CSSTransition
                      in={this.state.startTextFadeTransition}
                      timeout={500}
                      classNames="nav-fade"
                      onEntered={() => {
                        this.setState({
                          startTextFadeTransition: false,
                          startNavTransition: true,
                          displayText: false
                        });
                      }}
                    >
                      <FormattedMessage id={this.props.title}/>
                    </CSSTransition>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>)
  }
}
