const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
  useGrouping: true,
  minimumFractionDigits: 0
});


export default class NormalizationService {

  static ssn(value, previousValue) {
    if (!value) {
      return value
    }

    const numbers = value.replace(/[^\d]/g, '')

    if (numbers.length < 4) {
      return numbers
    }

    if (numbers.length < 6) {
      return numbers.slice(0, 3) + '-' + numbers.slice(3)
    }

    return numbers.slice(0, 3) + '-' + numbers.slice(3, 5) + '-' + numbers.slice(5, 9)
  }

  static currency(value, previousValue){
    if (!value) {
      return value;
    }

    const numbers = value.replace(/[^\d]/g, '');

    if (""==numbers) {
      return null;
    }

    var fnums = USDollar.format(numbers);
    return fnums;
  }


  static date(value, previousValue) {
    if (!value) {
      return value
    }

    const numbers = value.replace(/[^\d]/g, '');

    if (numbers.length < 3) {
      return numbers
    }

    if (numbers.length < 5) {
      return numbers.slice(0, 2) + '/' + numbers.slice(2)
    }

    return numbers.slice(0, 2) + '/' + numbers.slice(2, 4) + '/' + numbers.slice(4, 8)
  }

  static mobilePhone(value, previousValue) {
    if (!value) {
      return value
    }

    const numbers = value.replace(/[^\d]/g, '')
    const userBackspaced = previousValue && previousValue.length > value.length

    if (numbers.length < 1) {
      return ''
    }

    if (numbers.length > 0) {
      if (numbers.charAt(0) == 1) {

        if (userBackspaced) {
          if (numbers.length <= 1) {
            return numbers.slice(0,1)
          }
          if (numbers.length <= 4) {
            return numbers.slice(0,1) + ' (' + numbers.slice(1, 4)
          }
          if (numbers.length <= 7) {
            return numbers.slice(0,1) + ' (' + numbers.slice(1, 4) + ') ' + numbers.slice(4)
          }
        }

        if (numbers.length < 4) {
            return numbers.slice(0,1) + ' (' + numbers.slice(1, 4)
        }

        if (numbers.length < 7) {
            return numbers.slice(0,1) + ' (' + numbers.slice(1, 4) + ') ' + numbers.slice(4)
        }

        return numbers.slice(0,1) + ' (' + numbers.slice(1, 4) + ') ' + numbers.slice(4, 7) + '-' + numbers.slice(7, 11)
    } 
      else 
    {
        if (userBackspaced) {
          if (numbers.length <= 3) {
            return '(' + numbers
          }
          if (numbers.length <= 6) {
            return '(' + numbers.slice(0, 3) + ') ' + numbers.slice(3)
          }
        }

        if (numbers.length < 3) {
          return '(' + numbers
        }

        if (numbers.length < 6) {
          return '(' + numbers.slice(0, 3) + ') ' + numbers.slice(3)
        }

        return '(' + numbers.slice(0, 3) + ') ' + numbers.slice(3, 6) + '-' + numbers.slice(6, 10)
      }
    }
    
  }


  static stateAbbreviation(value, previousValue) {
    if (!value) {
      return value
    }

    const letters = value.replace(/[^a-zA-Z]/g, '').toUpperCase();

    if (letters.length <= 2) {
      return letters
    }

    return letters.slice(0,2);
  }


  //salesforce restrict to 10
  static zipcode(value, previousValue) {
    if (!value) {
      return value;
    }

    const numbers = value.replace(/[^\d]/g, '');

    if (numbers.length < 6) {
      return numbers
    }

    if (numbers.length >= 6) {
      return numbers.slice(0, 5) + '-' + numbers.slice(5, 9)
    }

  }

}
