import React from 'react';
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default class PickACoachLearnMoreModal extends React.Component {

  constructor(props) {
    super(props);
  }

  cancel(e) {
    this.props.closeModal(e)
  }

  selectCounselor(e){
    this.props.closeModal(e)
    this.props.selectCounselor(this.props.counselor)
  }

  bio() {
    return this.props.locale === 'en-US' ?
      this.props.counselor.aboutMe :
      this.props.counselor.aboutMeSpanish
  }



  render() {

    return (
            <div className="modal-wrapper">
                <div className="modal-body" style={{ transform: this.props.displayModal ? 'none' : 'translateY(-100vh)', opacity: this.props.displayModal ? '1' : '0', display: this.props.displayModal ? 'block' : 'none'}}>
                  <div className='ask-counselor-wrapper pick-a-coach-bio-modal'>
                    <div className='pick-a-coach-modal-position-wrapper'>
                      <div className='ask-counselor-x' onClick={(e) => this.cancel(e)}></div>
                      <div className='ask-counselor-box'>
                        <div className='ask-counselor-image-text-wrapper'>
                          <div className='ask-counselor-image'>
                            {
                              this.props.counselor.userId ?
                                <img src={`/api/counselor/${this.props.counselor.userId}/image`} className='img-circle'/>
                                :
                                <div className='counselor-image-default img-circle'></div>
                            }
                          </div>
                          <div>
                            <div className='counselor-name'>{`${this.props.counselor.firstName} ${this.props.counselor.lastName}`}</div>
                            <div className='counselor-bio-modal'> {`${this.bio()}`}</div>
                          </div>
                        </div>
                      <div className='pick-a-counselor-btn-wrapper'>
                        <button onClick={(e)=>this.selectCounselor(e)}>
                          <FormattedMessage id='app.appointment.pick-a-time-with'/> {this.props.counselor.firstName}
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
            </div>
            </div>
        )
    }

}


