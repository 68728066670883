import React from 'react'

export default class FicoScoreImage extends React.Component {


    constructor(props){
        super(props);
    }

    ficoScoreImage(position) {
      let extension = position == '000' ? '.png' : '.jpg'
      return (
        <img
          src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/fico/FICO_lockup_Blue_rev_Position_${position}${extension}`}
            />
        )
    }

    render() {

        return (

            this.props.ficoScore == 300 ? this.ficoScoreImage('300') :
            this.props.ficoScore == 301 ? this.ficoScoreImage('301') :
            this.props.ficoScore == 302 ? this.ficoScoreImage('302') :
            this.props.ficoScore == 303 ? this.ficoScoreImage('303') :
            this.props.ficoScore == 304 ? this.ficoScoreImage('304') :
            this.props.ficoScore == 305 ? this.ficoScoreImage('305') :
            this.props.ficoScore == 306 ? this.ficoScoreImage('306') :
            this.props.ficoScore == 307 ? this.ficoScoreImage('307') :
            this.props.ficoScore == 308 ? this.ficoScoreImage('308') :
            this.props.ficoScore == 309 ? this.ficoScoreImage('309') :
            this.props.ficoScore == 310 ? this.ficoScoreImage('310') :
            this.props.ficoScore == 311 ? this.ficoScoreImage('311') :
            this.props.ficoScore == 312 ? this.ficoScoreImage('312') :
            this.props.ficoScore == 313 ? this.ficoScoreImage('313') :
            this.props.ficoScore == 314 ? this.ficoScoreImage('314') :
            this.props.ficoScore == 315 ? this.ficoScoreImage('315') :
            this.props.ficoScore == 316 ? this.ficoScoreImage('316') :
            this.props.ficoScore == 317 ? this.ficoScoreImage('317') :
            this.props.ficoScore == 318 ? this.ficoScoreImage('318') :
            this.props.ficoScore == 319 ? this.ficoScoreImage('319') :
            this.props.ficoScore == 320 ? this.ficoScoreImage('320') :
            this.props.ficoScore == 321 ? this.ficoScoreImage('321') :
            this.props.ficoScore == 322 ? this.ficoScoreImage('322') :
            this.props.ficoScore == 323 ? this.ficoScoreImage('323') :
            this.props.ficoScore == 324 ? this.ficoScoreImage('324') :
            this.props.ficoScore == 325 ? this.ficoScoreImage('325') :
            this.props.ficoScore == 326 ? this.ficoScoreImage('326') :
            this.props.ficoScore == 327 ? this.ficoScoreImage('327') :
            this.props.ficoScore == 328 ? this.ficoScoreImage('328') :
            this.props.ficoScore == 329 ? this.ficoScoreImage('329') :
            this.props.ficoScore == 330 ? this.ficoScoreImage('330') :
            this.props.ficoScore == 331 ? this.ficoScoreImage('331') :
            this.props.ficoScore == 332 ? this.ficoScoreImage('332') :
            this.props.ficoScore == 333 ? this.ficoScoreImage('333') :
            this.props.ficoScore == 334 ? this.ficoScoreImage('334') :
            this.props.ficoScore == 335 ? this.ficoScoreImage('335') :
            this.props.ficoScore == 336 ? this.ficoScoreImage('336') :
            this.props.ficoScore == 337 ? this.ficoScoreImage('337') :
            this.props.ficoScore == 338 ? this.ficoScoreImage('338') :
            this.props.ficoScore == 339 ? this.ficoScoreImage('339') :
            this.props.ficoScore == 340 ? this.ficoScoreImage('340') :
            this.props.ficoScore == 341 ? this.ficoScoreImage('341') :
            this.props.ficoScore == 342 ? this.ficoScoreImage('342') :
            this.props.ficoScore == 343 ? this.ficoScoreImage('343') :
            this.props.ficoScore == 344 ? this.ficoScoreImage('344') :
            this.props.ficoScore == 345 ? this.ficoScoreImage('345') :
            this.props.ficoScore == 346 ? this.ficoScoreImage('346') :
            this.props.ficoScore == 347 ? this.ficoScoreImage('347') :
            this.props.ficoScore == 348 ? this.ficoScoreImage('348') :
            this.props.ficoScore == 349 ? this.ficoScoreImage('349') :
            this.props.ficoScore == 350 ? this.ficoScoreImage('350') :
            this.props.ficoScore == 351 ? this.ficoScoreImage('351') :
            this.props.ficoScore == 352 ? this.ficoScoreImage('352'):
            this.props.ficoScore == 353 ? this.ficoScoreImage('353') :
            this.props.ficoScore == 354 ? this.ficoScoreImage('354') :
            this.props.ficoScore == 355 ? this.ficoScoreImage('355') :
            this.props.ficoScore == 356 ? this.ficoScoreImage('356') :
            this.props.ficoScore == 357 ? this.ficoScoreImage('357') :
            this.props.ficoScore == 358 ? this.ficoScoreImage('358') :
            this.props.ficoScore == 359 ? this.ficoScoreImage('359') :
            this.props.ficoScore == 360 ? this.ficoScoreImage('360') :
            this.props.ficoScore == 361 ? this.ficoScoreImage('361') :
            this.props.ficoScore == 362 ? this.ficoScoreImage('362') :
            this.props.ficoScore == 363 ? this.ficoScoreImage('363'):
            this.props.ficoScore == 364 ? this.ficoScoreImage('364') :
            this.props.ficoScore == 365 ? this.ficoScoreImage('365') :
            this.props.ficoScore == 366 ? this.ficoScoreImage('366') :
            this.props.ficoScore == 367 ? this.ficoScoreImage('367') :
            this.props.ficoScore == 368 ? this.ficoScoreImage('368'):
            this.props.ficoScore == 369 ? this.ficoScoreImage('369') :
            this.props.ficoScore == 370 ? this.ficoScoreImage('370') :
            this.props.ficoScore == 371 ? this.ficoScoreImage('371') :
            this.props.ficoScore == 372 ? this.ficoScoreImage('372') :
            this.props.ficoScore == 373 ? this.ficoScoreImage('373') :
            this.props.ficoScore == 374 ? this.ficoScoreImage('374') :
            this.props.ficoScore == 375 ? this.ficoScoreImage('375') :
            this.props.ficoScore == 376 ? this.ficoScoreImage('376') :
            this.props.ficoScore == 377 ? this.ficoScoreImage('377') :
            this.props.ficoScore == 378 ? this.ficoScoreImage('378') :
            this.props.ficoScore == 379 ? this.ficoScoreImage('379') :
            this.props.ficoScore == 380 ? this.ficoScoreImage('380') :
            this.props.ficoScore == 381 ? this.ficoScoreImage('381') :
            this.props.ficoScore == 382 ? this.ficoScoreImage('382') :
            this.props.ficoScore == 383 ? this.ficoScoreImage('383') :
            this.props.ficoScore == 384 ? this.ficoScoreImage('384') :
            this.props.ficoScore == 385 ? this.ficoScoreImage('385') :
            this.props.ficoScore == 386 ? this.ficoScoreImage('386') :
            this.props.ficoScore == 387 ? this.ficoScoreImage('387') :
            this.props.ficoScore == 388 ? this.ficoScoreImage('388') :
            this.props.ficoScore == 389 ? this.ficoScoreImage('389') :
            this.props.ficoScore == 390 ? this.ficoScoreImage('390') :
            this.props.ficoScore == 391 ? this.ficoScoreImage('391') :
            this.props.ficoScore == 392 ? this.ficoScoreImage('392') :
            this.props.ficoScore == 393 ? this.ficoScoreImage('393') :
            this.props.ficoScore == 394 ? this.ficoScoreImage('394') :
            this.props.ficoScore == 395 ? this.ficoScoreImage('395') :
            this.props.ficoScore == 396 ? this.ficoScoreImage('396') :
            this.props.ficoScore == 397 ? this.ficoScoreImage('397') :
            this.props.ficoScore == 398 ? this.ficoScoreImage('398') :
            this.props.ficoScore == 399 ? this.ficoScoreImage('399') :
            this.props.ficoScore == 400 ? this.ficoScoreImage('400') :
            this.props.ficoScore == 401 ? this.ficoScoreImage('401') :
            this.props.ficoScore == 402 ? this.ficoScoreImage('402') :
            this.props.ficoScore == 403 ? this.ficoScoreImage('403') :
            this.props.ficoScore == 404 ? this.ficoScoreImage('404') :
            this.props.ficoScore == 405 ? this.ficoScoreImage('405') :
            this.props.ficoScore == 406 ? this.ficoScoreImage('406') :
            this.props.ficoScore == 407 ? this.ficoScoreImage('407') :
            this.props.ficoScore == 408 ? this.ficoScoreImage('408') :
            this.props.ficoScore == 409 ? this.ficoScoreImage('409') :
            this.props.ficoScore == 410 ? this.ficoScoreImage('410') :
            this.props.ficoScore == 411 ? this.ficoScoreImage('411') :
            this.props.ficoScore == 412 ? this.ficoScoreImage('412') :
            this.props.ficoScore == 413 ? this.ficoScoreImage('413') :
            this.props.ficoScore == 414 ? this.ficoScoreImage('414') :
            this.props.ficoScore == 415 ? this.ficoScoreImage('415') :
            this.props.ficoScore == 416 ? this.ficoScoreImage('416') :
            this.props.ficoScore == 417 ? this.ficoScoreImage('417') :
            this.props.ficoScore == 418 ? this.ficoScoreImage('418') :
            this.props.ficoScore == 419 ? this.ficoScoreImage('419') :
            this.props.ficoScore == 420 ? this.ficoScoreImage('420') :
            this.props.ficoScore == 421 ? this.ficoScoreImage('421') :
            this.props.ficoScore == 422 ? this.ficoScoreImage('422') :
            this.props.ficoScore == 423 ? this.ficoScoreImage('423') :
            this.props.ficoScore == 424 ? this.ficoScoreImage('424') :
            this.props.ficoScore == 425 ? this.ficoScoreImage('425') :
            this.props.ficoScore == 426 ? this.ficoScoreImage('426') :
            this.props.ficoScore == 427 ? this.ficoScoreImage('427') :
            this.props.ficoScore == 428 ? this.ficoScoreImage('428') :
            this.props.ficoScore == 429 ? this.ficoScoreImage('429') :
            this.props.ficoScore == 430 ? this.ficoScoreImage('430') :
            this.props.ficoScore == 431 ? this.ficoScoreImage('431') :
            this.props.ficoScore == 432 ? this.ficoScoreImage('432') :
            this.props.ficoScore == 433 ? this.ficoScoreImage('433') :
            this.props.ficoScore == 434 ? this.ficoScoreImage('434') :
            this.props.ficoScore == 435 ? this.ficoScoreImage('436') :
            this.props.ficoScore == 436 ? this.ficoScoreImage('436') :
            this.props.ficoScore == 437 ? this.ficoScoreImage('437') :
            this.props.ficoScore == 438 ? this.ficoScoreImage('438') :
            this.props.ficoScore == 439 ? this.ficoScoreImage('439') :
            this.props.ficoScore == 440 ? this.ficoScoreImage('440') :
            this.props.ficoScore == 441 ? this.ficoScoreImage('441') :
            this.props.ficoScore == 442 ? this.ficoScoreImage('442') :
            this.props.ficoScore == 443 ? this.ficoScoreImage('443') :
            this.props.ficoScore == 444 ? this.ficoScoreImage('444') :
            this.props.ficoScore == 445 ? this.ficoScoreImage('445') :
            this.props.ficoScore == 446 ? this.ficoScoreImage('446') :
            this.props.ficoScore == 447 ? this.ficoScoreImage('447') :
            this.props.ficoScore == 448 ? this.ficoScoreImage('448') :
            this.props.ficoScore == 449 ? this.ficoScoreImage('449') :
            this.props.ficoScore == 450 ? this.ficoScoreImage('450') :
            this.props.ficoScore == 451 ? this.ficoScoreImage('451') :
            this.props.ficoScore == 452 ? this.ficoScoreImage('452') :
            this.props.ficoScore == 453 ? this.ficoScoreImage('453') :
            this.props.ficoScore == 454 ? this.ficoScoreImage('454') :
            this.props.ficoScore == 455 ? this.ficoScoreImage('455') :
            this.props.ficoScore == 456 ? this.ficoScoreImage('456') :
            this.props.ficoScore == 457 ? this.ficoScoreImage('457') :
            this.props.ficoScore == 458 ? this.ficoScoreImage('458') :
            this.props.ficoScore == 459 ? this.ficoScoreImage('459') :
            this.props.ficoScore == 460 ? this.ficoScoreImage('460') :
            this.props.ficoScore == 461 ? this.ficoScoreImage('461') :
            this.props.ficoScore == 462 ? this.ficoScoreImage('462') :
            this.props.ficoScore == 463 ? this.ficoScoreImage('463') :
            this.props.ficoScore == 464 ? this.ficoScoreImage('464') :
            this.props.ficoScore == 465 ? this.ficoScoreImage('465') :
            this.props.ficoScore == 466 ? this.ficoScoreImage('466') :
            this.props.ficoScore == 467 ? this.ficoScoreImage('467') :
            this.props.ficoScore == 468 ? this.ficoScoreImage('468') :
            this.props.ficoScore == 469 ? this.ficoScoreImage('469') :
            this.props.ficoScore == 470 ? this.ficoScoreImage('470') :
            this.props.ficoScore == 471 ? this.ficoScoreImage('471') :
            this.props.ficoScore == 472 ? this.ficoScoreImage('472') :
            this.props.ficoScore == 473 ? this.ficoScoreImage('473') :
            this.props.ficoScore == 474 ? this.ficoScoreImage('474') :
            this.props.ficoScore == 475 ? this.ficoScoreImage('475') :
            this.props.ficoScore == 476 ? this.ficoScoreImage('476') :
            this.props.ficoScore == 477 ? this.ficoScoreImage('477') :
            this.props.ficoScore == 478 ? this.ficoScoreImage('478') :
            this.props.ficoScore == 479 ? this.ficoScoreImage('479') :
            this.props.ficoScore == 480 ? this.ficoScoreImage('480') :
            this.props.ficoScore == 481 ? this.ficoScoreImage('481') :
            this.props.ficoScore == 482 ? this.ficoScoreImage('482') :
            this.props.ficoScore == 483 ? this.ficoScoreImage('483') :
            this.props.ficoScore == 484 ? this.ficoScoreImage('484') :
            this.props.ficoScore == 485 ? this.ficoScoreImage('485') :
            this.props.ficoScore == 486 ? this.ficoScoreImage('486') :
            this.props.ficoScore == 487 ? this.ficoScoreImage('487') :
            this.props.ficoScore == 488 ? this.ficoScoreImage('488') :
            this.props.ficoScore == 489 ? this.ficoScoreImage('489') :
            this.props.ficoScore == 490 ? this.ficoScoreImage('490') :
            this.props.ficoScore == 491 ? this.ficoScoreImage('491') :
            this.props.ficoScore == 492 ? this.ficoScoreImage('492') :
            this.props.ficoScore == 493 ? this.ficoScoreImage('493') :
            this.props.ficoScore == 494 ? this.ficoScoreImage('494') :
            this.props.ficoScore == 495 ? this.ficoScoreImage('495') :
            this.props.ficoScore == 496 ? this.ficoScoreImage('496') :
            this.props.ficoScore == 497 ? this.ficoScoreImage('497') :
            this.props.ficoScore == 498 ? this.ficoScoreImage('498') :
            this.props.ficoScore == 499 ? this.ficoScoreImage('499') :
            this.props.ficoScore == 500 ? this.ficoScoreImage('500') :
            this.props.ficoScore == 501 ? this.ficoScoreImage('501') :
            this.props.ficoScore == 502 ? this.ficoScoreImage('502') :
            this.props.ficoScore == 503 ? this.ficoScoreImage('503') :
            this.props.ficoScore == 504 ? this.ficoScoreImage('504') :
            this.props.ficoScore == 505 ? this.ficoScoreImage('505') :
            this.props.ficoScore == 506 ? this.ficoScoreImage('506') :
            this.props.ficoScore == 507 ? this.ficoScoreImage('507') :
            this.props.ficoScore == 508 ? this.ficoScoreImage('508') :
            this.props.ficoScore == 509 ? this.ficoScoreImage('509') :
            this.props.ficoScore == 510 ? this.ficoScoreImage('510') :
            this.props.ficoScore == 511 ? this.ficoScoreImage('511') :
            this.props.ficoScore == 512 ? this.ficoScoreImage('512') :
            this.props.ficoScore == 513 ? this.ficoScoreImage('513') :
            this.props.ficoScore == 514 ? this.ficoScoreImage('514') :
            this.props.ficoScore == 515 ? this.ficoScoreImage('515') :
            this.props.ficoScore == 516 ? this.ficoScoreImage('516') :
            this.props.ficoScore == 517 ? this.ficoScoreImage('517') :
            this.props.ficoScore == 518 ? this.ficoScoreImage('518') :
            this.props.ficoScore == 519 ? this.ficoScoreImage('519') :
            this.props.ficoScore == 520 ? this.ficoScoreImage('520') :
            this.props.ficoScore == 521 ? this.ficoScoreImage('521') :
            this.props.ficoScore == 522 ? this.ficoScoreImage('522') :
            this.props.ficoScore == 523 ? this.ficoScoreImage('523') :
            this.props.ficoScore == 524 ? this.ficoScoreImage('524') :
            this.props.ficoScore == 525 ? this.ficoScoreImage('525') :
            this.props.ficoScore == 526 ? this.ficoScoreImage('526') :
            this.props.ficoScore == 527 ? this.ficoScoreImage('527') :
            this.props.ficoScore == 528 ? this.ficoScoreImage('528') :
            this.props.ficoScore == 529 ? this.ficoScoreImage('529') :
            this.props.ficoScore == 530 ? this.ficoScoreImage('530') :
            this.props.ficoScore == 531 ? this.ficoScoreImage('531') :
            this.props.ficoScore == 532 ? this.ficoScoreImage('532') :
            this.props.ficoScore == 533 ? this.ficoScoreImage('533') :
            this.props.ficoScore == 534 ? this.ficoScoreImage('534') :
            this.props.ficoScore == 535 ? this.ficoScoreImage('535') :
            this.props.ficoScore == 536 ? this.ficoScoreImage('536') :
            this.props.ficoScore == 537 ? this.ficoScoreImage('537') :
            this.props.ficoScore == 538 ? this.ficoScoreImage('538') :
            this.props.ficoScore == 539 ? this.ficoScoreImage('539') :
            this.props.ficoScore == 540 ? this.ficoScoreImage('540') :
            this.props.ficoScore == 541 ? this.ficoScoreImage('541') :
            this.props.ficoScore == 542 ? this.ficoScoreImage('542') :
            this.props.ficoScore == 543 ? this.ficoScoreImage('543') :
            this.props.ficoScore == 544 ? this.ficoScoreImage('544') :
            this.props.ficoScore == 545 ? this.ficoScoreImage('545') :
            this.props.ficoScore == 546 ? this.ficoScoreImage('546') :
            this.props.ficoScore == 547 ? this.ficoScoreImage('547') :
            this.props.ficoScore == 548 ? this.ficoScoreImage('548') :
            this.props.ficoScore == 549 ? this.ficoScoreImage('549') :
            this.props.ficoScore == 550 ? this.ficoScoreImage('550') :
            this.props.ficoScore == 551 ? this.ficoScoreImage('551') :
            this.props.ficoScore == 552 ? this.ficoScoreImage('552') :
            this.props.ficoScore == 553 ? this.ficoScoreImage('553') :
            this.props.ficoScore == 554 ? this.ficoScoreImage('554') :
            this.props.ficoScore == 555 ? this.ficoScoreImage('555') :
            this.props.ficoScore == 556 ? this.ficoScoreImage('556') :
            this.props.ficoScore == 557 ? this.ficoScoreImage('557') :
            this.props.ficoScore == 558 ? this.ficoScoreImage('558') :
            this.props.ficoScore == 559 ? this.ficoScoreImage('559') :
            this.props.ficoScore == 560 ? this.ficoScoreImage('560') :
            this.props.ficoScore == 561 ? this.ficoScoreImage('561') :
            this.props.ficoScore == 562 ? this.ficoScoreImage('562') :
            this.props.ficoScore == 563 ? this.ficoScoreImage('563') :
            this.props.ficoScore == 564 ? this.ficoScoreImage('564') :
            this.props.ficoScore == 565 ? this.ficoScoreImage('565') :
            this.props.ficoScore == 566 ? this.ficoScoreImage('566') :
            this.props.ficoScore == 567 ? this.ficoScoreImage('567') :
            this.props.ficoScore == 568 ? this.ficoScoreImage('568') :
            this.props.ficoScore == 569 ? this.ficoScoreImage('569') :
            this.props.ficoScore == 570 ? this.ficoScoreImage('570') :
            this.props.ficoScore == 571 ? this.ficoScoreImage('571') :
            this.props.ficoScore == 572 ? this.ficoScoreImage('572') :
            this.props.ficoScore == 573 ? this.ficoScoreImage('573') :
            this.props.ficoScore == 574 ? this.ficoScoreImage('574') :
            this.props.ficoScore == 575 ? this.ficoScoreImage('575') :
            this.props.ficoScore == 576 ? this.ficoScoreImage('576') :
            this.props.ficoScore == 577 ? this.ficoScoreImage('577') :
            this.props.ficoScore == 578 ? this.ficoScoreImage('578') :
            this.props.ficoScore == 579 ? this.ficoScoreImage('579') :
            this.props.ficoScore == 580 ? this.ficoScoreImage('580') :
            this.props.ficoScore == 581 ? this.ficoScoreImage('581') :
            this.props.ficoScore == 582 ? this.ficoScoreImage('582') :
            this.props.ficoScore == 583 ? this.ficoScoreImage('583') :
            this.props.ficoScore == 584 ? this.ficoScoreImage('584') :
            this.props.ficoScore == 585 ? this.ficoScoreImage('585') :
            this.props.ficoScore == 586 ? this.ficoScoreImage('586') :
            this.props.ficoScore == 587 ? this.ficoScoreImage('587') :
            this.props.ficoScore == 588 ? this.ficoScoreImage('588') :
            this.props.ficoScore == 589 ? this.ficoScoreImage('589') :
            this.props.ficoScore == 590 ? this.ficoScoreImage('590') :
            this.props.ficoScore == 591 ? this.ficoScoreImage('591') :
            this.props.ficoScore == 592 ? this.ficoScoreImage('592') :
            this.props.ficoScore == 593 ? this.ficoScoreImage('593') :
            this.props.ficoScore == 594 ? this.ficoScoreImage('594') :
            this.props.ficoScore == 595 ? this.ficoScoreImage('595') :
            this.props.ficoScore == 596 ? this.ficoScoreImage('596') :
            this.props.ficoScore == 597 ? this.ficoScoreImage('597') :
            this.props.ficoScore == 598 ? this.ficoScoreImage('598') :
            this.props.ficoScore == 599 ? this.ficoScoreImage('599') :
            this.props.ficoScore == 600 ? this.ficoScoreImage('600') :
            this.props.ficoScore == 601 ? this.ficoScoreImage('601') :
            this.props.ficoScore == 602 ? this.ficoScoreImage('602') :
            this.props.ficoScore == 603 ? this.ficoScoreImage('603') :
            this.props.ficoScore == 604 ? this.ficoScoreImage('604') :
            this.props.ficoScore == 605 ? this.ficoScoreImage('605') :
            this.props.ficoScore == 606 ? this.ficoScoreImage('606') :
            this.props.ficoScore == 607 ? this.ficoScoreImage('607') :
            this.props.ficoScore == 608 ? this.ficoScoreImage('608') :
            this.props.ficoScore == 609 ? this.ficoScoreImage('609') :
            this.props.ficoScore == 610 ? this.ficoScoreImage('610') :
            this.props.ficoScore == 611 ? this.ficoScoreImage('611') :
            this.props.ficoScore == 612 ? this.ficoScoreImage('612') :
            this.props.ficoScore == 613 ? this.ficoScoreImage('613') :
            this.props.ficoScore == 614 ? this.ficoScoreImage('614') :
            this.props.ficoScore == 615 ? this.ficoScoreImage('615') :
            this.props.ficoScore == 616 ? this.ficoScoreImage('616') :
            this.props.ficoScore == 617 ? this.ficoScoreImage('617') :
            this.props.ficoScore == 618 ? this.ficoScoreImage('618') :
            this.props.ficoScore == 619 ? this.ficoScoreImage('619') :
            this.props.ficoScore == 620 ? this.ficoScoreImage('620') :
            this.props.ficoScore == 621 ? this.ficoScoreImage('621') :
            this.props.ficoScore == 622 ? this.ficoScoreImage('622') :
            this.props.ficoScore == 623 ? this.ficoScoreImage('623') :
            this.props.ficoScore == 624 ? this.ficoScoreImage('624') :
            this.props.ficoScore == 625 ? this.ficoScoreImage('625') :
            this.props.ficoScore == 626 ? this.ficoScoreImage('626') :
            this.props.ficoScore == 627 ? this.ficoScoreImage('627') :
            this.props.ficoScore == 628 ? this.ficoScoreImage('628') :
            this.props.ficoScore == 629 ? this.ficoScoreImage('629') :
            this.props.ficoScore == 630 ? this.ficoScoreImage('630') :
            this.props.ficoScore == 631 ? this.ficoScoreImage('631') :
            this.props.ficoScore == 632 ? this.ficoScoreImage('632') :
            this.props.ficoScore == 633 ? this.ficoScoreImage('633') :
            this.props.ficoScore == 634 ? this.ficoScoreImage('634') :
            this.props.ficoScore == 635 ? this.ficoScoreImage('635') :
            this.props.ficoScore == 636 ? this.ficoScoreImage('636') :
            this.props.ficoScore == 637 ? this.ficoScoreImage('637') :
            this.props.ficoScore == 638 ? this.ficoScoreImage('638') :
            this.props.ficoScore == 639 ? this.ficoScoreImage('639') :
            this.props.ficoScore == 640 ? this.ficoScoreImage('640') :
            this.props.ficoScore == 641 ? this.ficoScoreImage('641') :
            this.props.ficoScore == 642 ? this.ficoScoreImage('642') :
            this.props.ficoScore == 643 ? this.ficoScoreImage('643') :
            this.props.ficoScore == 644 ? this.ficoScoreImage('644') :
            this.props.ficoScore == 645 ? this.ficoScoreImage('645') :
            this.props.ficoScore == 646 ? this.ficoScoreImage('646') :
            this.props.ficoScore == 647 ? this.ficoScoreImage('647') :
            this.props.ficoScore == 648 ? this.ficoScoreImage('648') :
            this.props.ficoScore == 649 ? this.ficoScoreImage('649') :
            this.props.ficoScore == 650 ? this.ficoScoreImage('650') :
            this.props.ficoScore == 651 ? this.ficoScoreImage('651') :
            this.props.ficoScore == 652 ? this.ficoScoreImage('652') :
            this.props.ficoScore == 653 ? this.ficoScoreImage('653') :
            this.props.ficoScore == 654 ? this.ficoScoreImage('654') :
            this.props.ficoScore == 655 ? this.ficoScoreImage('655') :
            this.props.ficoScore == 656 ? this.ficoScoreImage('656') :
            this.props.ficoScore == 657 ? this.ficoScoreImage('657') :
            this.props.ficoScore == 658 ? this.ficoScoreImage('658') :
            this.props.ficoScore == 659 ? this.ficoScoreImage('659') :
            this.props.ficoScore == 660 ? this.ficoScoreImage('660') :
            this.props.ficoScore == 661 ? this.ficoScoreImage('661') :
            this.props.ficoScore == 662 ? this.ficoScoreImage('662') :
            this.props.ficoScore == 663 ? this.ficoScoreImage('663') :
            this.props.ficoScore == 664 ? this.ficoScoreImage('664') :
            this.props.ficoScore == 665 ? this.ficoScoreImage('665') :
            this.props.ficoScore == 666 ? this.ficoScoreImage('666') :
            this.props.ficoScore == 667 ? this.ficoScoreImage('667') :
            this.props.ficoScore == 668 ? this.ficoScoreImage('668') :
            this.props.ficoScore == 669 ? this.ficoScoreImage('669') :
            this.props.ficoScore == 670 ? this.ficoScoreImage('670') :
            this.props.ficoScore == 671 ? this.ficoScoreImage('671') :
            this.props.ficoScore == 672 ? this.ficoScoreImage('672') :
            this.props.ficoScore == 673 ? this.ficoScoreImage('673') :
            this.props.ficoScore == 674 ? this.ficoScoreImage('674') :
            this.props.ficoScore == 675 ? this.ficoScoreImage('675') :
            this.props.ficoScore == 676 ? this.ficoScoreImage('676') :
            this.props.ficoScore == 677 ? this.ficoScoreImage('677') :
            this.props.ficoScore == 678 ? this.ficoScoreImage('678') :
            this.props.ficoScore == 679 ? this.ficoScoreImage('679') :
            this.props.ficoScore == 680 ? this.ficoScoreImage('680') :
            this.props.ficoScore == 681 ? this.ficoScoreImage('681') :
            this.props.ficoScore == 682 ? this.ficoScoreImage('682') :
            this.props.ficoScore == 683 ? this.ficoScoreImage('683') :
            this.props.ficoScore == 684 ? this.ficoScoreImage('680') :
            this.props.ficoScore == 685 ? this.ficoScoreImage('685') :
            this.props.ficoScore == 686 ? this.ficoScoreImage('686') :
            this.props.ficoScore == 687 ? this.ficoScoreImage('687') :
            this.props.ficoScore == 688 ? this.ficoScoreImage('688') :
            this.props.ficoScore == 689 ? this.ficoScoreImage('689') :
            this.props.ficoScore == 690 ? this.ficoScoreImage('690') :
            this.props.ficoScore == 691 ? this.ficoScoreImage('691') :
            this.props.ficoScore == 692 ? this.ficoScoreImage('692') :
            this.props.ficoScore == 693 ? this.ficoScoreImage('693') :
            this.props.ficoScore == 694 ? this.ficoScoreImage('694') :
            this.props.ficoScore == 695 ? this.ficoScoreImage('695') :
            this.props.ficoScore == 696 ? this.ficoScoreImage('696') :
            this.props.ficoScore == 697 ? this.ficoScoreImage('697') :
            this.props.ficoScore == 698 ? this.ficoScoreImage('698') :
            this.props.ficoScore == 699 ? this.ficoScoreImage('699') :
            this.props.ficoScore == 700 ? this.ficoScoreImage('700') :
            this.props.ficoScore == 701 ? this.ficoScoreImage('701') :
            this.props.ficoScore == 702 ? this.ficoScoreImage('702') :
            this.props.ficoScore == 703 ? this.ficoScoreImage('703') :
            this.props.ficoScore == 704 ? this.ficoScoreImage('704') :
            this.props.ficoScore == 705 ? this.ficoScoreImage('705') :
            this.props.ficoScore == 706 ? this.ficoScoreImage('706') :
            this.props.ficoScore == 707 ? this.ficoScoreImage('707') :
            this.props.ficoScore == 708 ? this.ficoScoreImage('708') :
            this.props.ficoScore == 709 ? this.ficoScoreImage('709') :
            this.props.ficoScore == 710 ? this.ficoScoreImage('710') :
            this.props.ficoScore == 711 ? this.ficoScoreImage('711') :
            this.props.ficoScore == 712 ? this.ficoScoreImage('712') :
            this.props.ficoScore == 713 ? this.ficoScoreImage('713') :
            this.props.ficoScore == 714 ? this.ficoScoreImage('714') :
            this.props.ficoScore == 715 ? this.ficoScoreImage('715') :
            this.props.ficoScore == 716 ? this.ficoScoreImage('716') :
            this.props.ficoScore == 717 ? this.ficoScoreImage('717') :
            this.props.ficoScore == 718 ? this.ficoScoreImage('718') :
            this.props.ficoScore == 719 ? this.ficoScoreImage('719') :
            this.props.ficoScore == 720 ? this.ficoScoreImage('720') :
            this.props.ficoScore == 721 ? this.ficoScoreImage('721') :
            this.props.ficoScore == 722 ? this.ficoScoreImage('722') :
            this.props.ficoScore == 723 ? this.ficoScoreImage('723') :
            this.props.ficoScore == 724 ? this.ficoScoreImage('724') :
            this.props.ficoScore == 725 ? this.ficoScoreImage('725') :
            this.props.ficoScore == 726 ? this.ficoScoreImage('726') :
            this.props.ficoScore == 727 ? this.ficoScoreImage('727') :
            this.props.ficoScore == 728 ? this.ficoScoreImage('728') :
            this.props.ficoScore == 729 ? this.ficoScoreImage('729') :
            this.props.ficoScore == 730 ? this.ficoScoreImage('730') :
            this.props.ficoScore == 731 ? this.ficoScoreImage('731') :
            this.props.ficoScore == 732 ? this.ficoScoreImage('732') :
            this.props.ficoScore == 733 ? this.ficoScoreImage('733') :
            this.props.ficoScore == 734 ? this.ficoScoreImage('734') :
            this.props.ficoScore == 735 ? this.ficoScoreImage('735') :
            this.props.ficoScore == 736 ? this.ficoScoreImage('736') :
            this.props.ficoScore == 737 ? this.ficoScoreImage('737') :
            this.props.ficoScore == 738 ? this.ficoScoreImage('738') :
            this.props.ficoScore == 739 ? this.ficoScoreImage('739') :
            this.props.ficoScore == 740 ? this.ficoScoreImage('740') :
            this.props.ficoScore == 741 ? this.ficoScoreImage('741') :
            this.props.ficoScore == 742 ? this.ficoScoreImage('742') :
            this.props.ficoScore == 743 ? this.ficoScoreImage('743') :
            this.props.ficoScore == 744 ? this.ficoScoreImage('744') :
            this.props.ficoScore == 745 ? this.ficoScoreImage('745') :
            this.props.ficoScore == 746 ? this.ficoScoreImage('746') :
            this.props.ficoScore == 747 ? this.ficoScoreImage('747') :
            this.props.ficoScore == 748 ? this.ficoScoreImage('748') :
            this.props.ficoScore == 749 ? this.ficoScoreImage('749') :
            this.props.ficoScore == 750 ? this.ficoScoreImage('750') :
            this.props.ficoScore == 751 ? this.ficoScoreImage('751') :
            this.props.ficoScore == 752 ? this.ficoScoreImage('752') :
            this.props.ficoScore == 753 ? this.ficoScoreImage('753') :
            this.props.ficoScore == 754 ? this.ficoScoreImage('754') :
            this.props.ficoScore == 755 ? this.ficoScoreImage('755') :
            this.props.ficoScore == 756 ? this.ficoScoreImage('756') :
            this.props.ficoScore == 757 ? this.ficoScoreImage('757') :
            this.props.ficoScore == 758 ? this.ficoScoreImage('758') :
            this.props.ficoScore == 759 ? this.ficoScoreImage('759') :
            this.props.ficoScore == 760 ? this.ficoScoreImage('760') :
            this.props.ficoScore == 761 ? this.ficoScoreImage('761') :
            this.props.ficoScore == 762 ? this.ficoScoreImage('762') :
            this.props.ficoScore == 763 ? this.ficoScoreImage('763') :
            this.props.ficoScore == 764 ? this.ficoScoreImage('764') :
            this.props.ficoScore == 765 ? this.ficoScoreImage('765') :
            this.props.ficoScore == 766 ? this.ficoScoreImage('766') :
            this.props.ficoScore == 767 ? this.ficoScoreImage('767') :
            this.props.ficoScore == 768 ? this.ficoScoreImage('768') :
            this.props.ficoScore == 769 ? this.ficoScoreImage('769') :
            this.props.ficoScore == 770 ? this.ficoScoreImage('770') :
            this.props.ficoScore == 771 ? this.ficoScoreImage('771') :
            this.props.ficoScore == 772 ? this.ficoScoreImage('772') :
            this.props.ficoScore == 773 ? this.ficoScoreImage('773') :
            this.props.ficoScore == 774 ? this.ficoScoreImage('774') :
            this.props.ficoScore == 775 ? this.ficoScoreImage('775') :
            this.props.ficoScore == 776 ? this.ficoScoreImage('776') :
            this.props.ficoScore == 777 ? this.ficoScoreImage('777') :
            this.props.ficoScore == 778 ? this.ficoScoreImage('778') :
            this.props.ficoScore == 779 ? this.ficoScoreImage('779') :
            this.props.ficoScore == 780 ? this.ficoScoreImage('780') :
            this.props.ficoScore == 781 ? this.ficoScoreImage('781') :
            this.props.ficoScore == 782 ? this.ficoScoreImage('782') :
            this.props.ficoScore == 783 ? this.ficoScoreImage('783') :
            this.props.ficoScore == 784 ? this.ficoScoreImage('784') :
            this.props.ficoScore == 785 ? this.ficoScoreImage('785') :
            this.props.ficoScore == 786 ? this.ficoScoreImage('786') :
            this.props.ficoScore == 787 ? this.ficoScoreImage('787') :
            this.props.ficoScore == 788 ? this.ficoScoreImage('788') :
            this.props.ficoScore == 789 ? this.ficoScoreImage('789') :
            this.props.ficoScore == 790 ? this.ficoScoreImage('790') :
            this.props.ficoScore == 791 ? this.ficoScoreImage('791') :
            this.props.ficoScore == 792 ? this.ficoScoreImage('792') :
            this.props.ficoScore == 793 ? this.ficoScoreImage('793') :
            this.props.ficoScore == 794 ? this.ficoScoreImage('794') :
            this.props.ficoScore == 795 ? this.ficoScoreImage('795') :
            this.props.ficoScore == 796 ? this.ficoScoreImage('796') :
            this.props.ficoScore == 797 ? this.ficoScoreImage('797') :
            this.props.ficoScore == 798 ? this.ficoScoreImage('798') :
            this.props.ficoScore == 799 ? this.ficoScoreImage('799') :
            this.props.ficoScore == 800 ? this.ficoScoreImage('800') :
            this.props.ficoScore == 801 ? this.ficoScoreImage('801') :
            this.props.ficoScore == 802 ? this.ficoScoreImage('802') :
            this.props.ficoScore == 803 ? this.ficoScoreImage('803') :
            this.props.ficoScore == 804 ? this.ficoScoreImage('804') :
            this.props.ficoScore == 805 ? this.ficoScoreImage('805') :
            this.props.ficoScore == 806 ? this.ficoScoreImage('806') :
            this.props.ficoScore == 807 ? this.ficoScoreImage('807') :
            this.props.ficoScore == 808 ? this.ficoScoreImage('808') :
            this.props.ficoScore == 809 ? this.ficoScoreImage('809') :
            this.props.ficoScore == 810 ? this.ficoScoreImage('810') :
            this.props.ficoScore == 811 ? this.ficoScoreImage('811') :
            this.props.ficoScore == 812 ? this.ficoScoreImage('812') :
            this.props.ficoScore == 813 ? this.ficoScoreImage('813') :
            this.props.ficoScore == 814 ? this.ficoScoreImage('814') :
            this.props.ficoScore == 815 ? this.ficoScoreImage('815') :
            this.props.ficoScore == 816 ? this.ficoScoreImage('816') :
            this.props.ficoScore == 817 ? this.ficoScoreImage('817') :
            this.props.ficoScore == 818 ? this.ficoScoreImage('818') :
            this.props.ficoScore == 819 ? this.ficoScoreImage('819') :
            this.props.ficoScore == 820 ? this.ficoScoreImage('820') :
            this.props.ficoScore == 821 ? this.ficoScoreImage('821') :
            this.props.ficoScore == 822 ? this.ficoScoreImage('822') :
            this.props.ficoScore == 823 ? this.ficoScoreImage('823') :
            this.props.ficoScore == 824 ? this.ficoScoreImage('824') :
            this.props.ficoScore == 825 ? this.ficoScoreImage('825') :
            this.props.ficoScore == 826 ? this.ficoScoreImage('826') :
            this.props.ficoScore == 827 ? this.ficoScoreImage('827') :
            this.props.ficoScore == 828 ? this.ficoScoreImage('828') :
            this.props.ficoScore == 829 ? this.ficoScoreImage('829') :
            this.props.ficoScore == 830 ? this.ficoScoreImage('830') :
            this.props.ficoScore == 831 ? this.ficoScoreImage('831') :
            this.props.ficoScore == 832 ? this.ficoScoreImage('832') :
            this.props.ficoScore == 833 ? this.ficoScoreImage('833') :
            this.props.ficoScore == 834 ? this.ficoScoreImage('834') :
            this.props.ficoScore == 835 ? this.ficoScoreImage('835') :
            this.props.ficoScore == 836 ? this.ficoScoreImage('836') :
            this.props.ficoScore == 837 ? this.ficoScoreImage('837') :
            this.props.ficoScore == 838 ? this.ficoScoreImage('838') :
            this.props.ficoScore == 839 ? this.ficoScoreImage('839') :
            this.props.ficoScore == 840 ? this.ficoScoreImage('840') :
            this.props.ficoScore == 841 ? this.ficoScoreImage('841') :
            this.props.ficoScore == 842 ? this.ficoScoreImage('842') :
            this.props.ficoScore == 843 ? this.ficoScoreImage('843') :
            this.props.ficoScore == 844 ? this.ficoScoreImage('844') :
            this.props.ficoScore == 845 ? this.ficoScoreImage('845') :
            this.props.ficoScore == 846 ? this.ficoScoreImage('846') :
            this.props.ficoScore == 847 ? this.ficoScoreImage('847') :
            this.props.ficoScore == 848 ? this.ficoScoreImage('848') :
            this.props.ficoScore == 849 ? this.ficoScoreImage('849') :
            this.props.ficoScore == 850 ? this.ficoScoreImage('850') :
            this.ficoScoreImage('000')
        )
    }
}
