import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icon from '../../../resources/icons'
import FormattedMessage from '../helpers/FormattedMessage'

export default function CustomerPortalBlueNavItem (props) {

  const onNavItemClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    props.navItemClick();
  }

  return (
    <div className='nav-page-box-blue'>
      <a href='#' onClick={ e => onNavItemClick(e, "CustomerPortalDashboard") }>
        <div className='nav-page-white-box-container'>
          <div className='nav-page-logo-wrapper'>
            <div className='nav-page-logo'>
              <Icon fill="#ffffff" name={props.icon}/>
              {props.iconfontawesome && <FontAwesomeIcon icon={props.iconfontawesome}/>}
            </div>
          </div>
          <div className='nav-page-name-wrapper'>
            <div className='nav-page-name-utility'>
              <FormattedMessage id={props.title}/>
            </div>
          </div>
        </div>
      </a>
    </div>)
  }