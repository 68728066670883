import React from 'react'

export const HowItWorksConfidential = ({
                                          style = {},
                                          fill = '#fff',
                                          width = '100%',
                                          className = '',
                                          height = '100%',
                                          viewBox = '0 0 220 220',
                                        }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <defs>
      <polygon id="path-1" points="0 0.288154303 219.710923 0.288154303 219.710923 219.346789 0 219.346789"></polygon>
    </defs>
    <g id="Page-9" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="howitworks2_1200+" transform="translate(-990.000000, -1075.000000)">
        <g id="Group-28" transform="translate(990.000000, 1075.000000)">
          <g id="Group-3" transform="translate(0.000000, 0.364926)">
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path d="M219.710923,109.817472 C219.710923,170.308985 170.527173,219.346789 109.855625,219.346789 C49.1840774,219.346789 -0.000327380952,170.308985 -0.000327380952,109.817472 C-0.000327380952,49.3259585 49.1840774,0.288154303 109.855625,0.288154303 C170.527173,0.288154303 219.710923,49.3259585 219.710923,109.817472" id="Fill-1" fill="#AFDFE7" mask="url(#mask-2)"></path>
          </g>
          <path d="M147.834827,141.810955 L39.2006607,141.810955 C37.7758988,141.810955 36.6208988,140.659383 36.6208988,139.239501 L36.6208988,52.7540415 C36.6208988,51.421638 37.7045298,50.3418754 39.0408988,50.3418754 L145.433815,50.3418754 C146.759708,50.3418754 147.834827,51.4131513 147.834827,52.7357626 L147.834827,141.810955 Z" id="Fill-4" fill="#143156"></path>
          <path d="M128.179202,69.1701543 L128.179202,63.6087893 C128.179202,62.2248131 129.304083,61.10327 130.692179,61.10327 L154.744202,61.10327 C156.270452,61.10327 157.508607,62.3370979 157.508607,63.8594718 L157.508607,69.1701543 L157.649381,69.2080178 L165.507833,69.2080178 C167.15194,69.2080178 168.396643,70.6899169 168.107238,72.3036855 L156.079262,139.474843 C155.837655,140.826831 154.658429,141.810629 153.282119,141.810629 L44.3755714,141.810629 L56.96075,71.526178 C57.2010476,70.1852878 58.3711071,69.2080178 59.73825,69.2080178 L128.151048,69.2080178 L128.179202,69.1701543 Z" id="Fill-6" fill="#FEFEFE"></path>
          <path d="M128.179202,69.1701543 L128.179202,63.6087893 C128.179202,62.2248131 129.304083,61.10327 130.692179,61.10327 L154.744202,61.10327 C156.270452,61.10327 157.508607,62.3370979 157.508607,63.8594718 L157.508607,69.1701543 L157.649381,69.2080178 L165.507833,69.2080178 C167.15194,69.2080178 168.396643,70.6899169 168.107238,72.3036855 L156.079262,139.474843 C155.837655,140.826831 154.658429,141.810629 153.282119,141.810629 L44.3755714,141.810629 L56.96075,71.526178 C57.2010476,70.1852878 58.3711071,69.2080178 59.73825,69.2080178 L128.151048,69.2080178 L128.179202,69.1701543 Z" id="Stroke-8" stroke="#143156" strokeWidth="3"></path>
          <path d="M188.636577,142.57854 C188.636577,163.251359 171.82753,180.010528 151.093185,180.010528 C130.358185,180.010528 113.549792,163.251359 113.549792,142.57854 C113.549792,121.905068 130.358185,105.146552 151.093185,105.146552 C171.82753,105.146552 188.636577,121.905068 188.636577,142.57854" id="Fill-10" fill="#FEFEFE"></path>
          <path d="M188.636577,142.57854 C188.636577,163.251359 171.82753,180.010528 151.093185,180.010528 C130.358185,180.010528 113.549792,163.251359 113.549792,142.57854 C113.549792,121.905068 130.358185,105.146552 151.093185,105.146552 C171.82753,105.146552 188.636577,121.905068 188.636577,142.57854 Z" id="Stroke-12" stroke="#143156" strokeWidth="3"></path>
          <path d="M151.106083,142.89346 L151.106083,142.913697 C151.095607,142.908475 151.091024,142.908475 151.079893,142.903905 C151.091024,142.903905 151.095607,142.898682 151.106083,142.89346" id="Fill-14" fill="#EE3E42"></path>
          <path d="M150.933423,171.336979 C150.922292,171.331757 150.911161,171.328493 150.90003,171.324576 C150.480982,171.169205 150.068482,171.005347 149.66253,170.833656 C149.549256,170.786 149.437292,170.737691 149.325327,170.68873 C128.601458,161.619769 125.440268,131.846653 125.048065,126.732469 C125.016637,126.327068 125.33878,125.988255 125.746696,125.985644 C137.184732,125.906 146.446339,119.559294 149.779077,116.945407 C149.779077,116.945407 150.949792,116.003389 152.34378,116.896445 C152.421696,116.946712 152.493065,117.0133 152.571637,117.074665 C156.034673,119.761668 165.182351,125.91057 176.439018,125.985644 C176.847589,125.988255 177.169732,126.327068 177.138304,126.733122 C176.745446,131.849917 173.582292,161.64784 152.832232,170.701134 C152.707173,170.75597 152.580804,170.809501 152.45378,170.863033 C152.083185,171.019056 151.707351,171.167899 151.325625,171.310214 C151.303363,171.3187 151.281101,171.326534 151.259494,171.334368 C151.259494,171.334368 151.104315,171.416623 150.933423,171.336979" id="Fill-16" fill="#EE3E42"></path>
          <path d="M151.079893,142.89346 C151.091024,142.898682 151.095607,142.903905 151.106083,142.903905 C151.095607,142.908475 151.091024,142.908475 151.079893,142.913697 L151.079893,142.89346 Z" id="Fill-18" fill="#EE3E42"></path>
          <path d="M144.787369,140.758415 C145.133738,135.761739 144.991,129.786487 151.093381,129.786487 C156.980345,129.786487 157.052369,135.761739 157.398738,140.758415" id="Stroke-20" stroke="#FEFEFE" strokeWidth="3"></path>
          <path d="M158.085321,156.020605 L144.101571,156.020605 C142.551095,156.020605 141.294607,154.767846 141.294607,153.222623 L141.294607,141.264285 C141.294607,139.719062 142.551095,138.466303 144.101571,138.466303 L158.085321,138.466303 C159.635143,138.466303 160.890976,139.719062 160.890976,141.264285 L160.890976,153.222623 C160.890976,154.767846 159.635143,156.020605 158.085321,156.020605" id="Fill-22" fill="#FEFEFE"></path>
          <path d="M153.822952,144.521656 C153.822952,146.025098 152.601167,147.243258 151.09325,147.243258 C149.585333,147.243258 148.362893,146.025098 148.362893,144.521656 C148.362893,143.018214 149.585333,141.799401 151.09325,141.799401 C152.601167,141.799401 153.822952,143.018214 153.822952,144.521656" id="Fill-24" fill="#EE3E42"></path>
          <polygon id="Fill-26" fill="#EE3E42" points="153.909315 152.687638 148.277054 152.687638 149.617351 145.772326 152.569018 145.772326"></polygon>
        </g>
      </g>
    </g>
  </svg>;

export default HowItWorksConfidential