import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import ValidationService from '../services/validation-service'
import {changePassword} from '../actions/login-actions'
import {displayLoadingIcon} from '../actions/loading-icon-action'
import ChangePassForm from '../components/change-pass-form'

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('login')(state),
    login: state.login
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoadingIcon(truthy) {
      dispatch(displayLoadingIcon(truthy))
    },
  }
}

function goToNextPage(props) {
  props.setMyAccountComponent("Account")
}

const ChangePassFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
  form: 'change-pass-form',
  validate: ValidationService.changePassword,
  onSubmit: (values, dispatch, props) => {
    dispatch(displayLoadingIcon(true))
    return dispatch(changePassword(values)).then(() => {
      dispatch(displayLoadingIcon(false))
      goToNextPage(props)
    })
  },
  destroyOnUnmount: false
})(injectIntl(ChangePassForm))
)

export default ChangePassFormContainer
