import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import CustomerPortalFooter  from './customer-portal-footer';
import CustomerPortalContact from './customer-portal-contact';
import {useDispatch, useSelector} from "react-redux";
import CustomerPortalNav from "./customer-portal-nav";
import {useHistory} from "react-router-dom";

function CustomerPortalDashboard() {

  const employer = useSelector(state => state.employer);
  const contact = useSelector(state => state.registration);
  const edsContract = useSelector(state => state.edsContract);
  const login = useSelector(state => state.login);
  const history = useHistory();

  const [step, setStep] = useState(1);
  const [viewSteps, setViewSteps] = useState(false);
  const [checkedFiles, setCheckedFiles] = useState(true);

  useEffect(() => {
    if (login === null) {
      history.push(`/customers/enter-your-email-address`);
    } else if (login && login.isCustomersContact === false) {
        history.push(`/action-plan`)
      }
  },[])

  const goToContracts = (e) => {
    e.preventDefault()
    history.push('/customer-portal/contracts')
  }

  const handleViewSteps = (e) => {
    e.preventDefault()
    setViewSteps(!viewSteps)
  }


  useEffect( () => {
    // console.log("CustomerPortalDashboard useEffect running")
    // setCheckedFiles(false);
    // if (employer != null  && employerFiles(employer) === 3 && edsContract.status === "Complete") {
    //   setStep(4)
    // } else
    if (edsContract && edsContract.status === "Complete") {
      // setTimeout(() => {
      //   setCheckedFiles(true);
      //   }, 5000);
      // setStep(3)
      setStep(4)
    } else if (edsContract && edsContract.status !== "Complete") {
      setStep(2)
    } else {
      setStep(2)
    }
  }, [employer,edsContract])


  const hasStepTwoComplete = (step) => {
    return edsContract && step === 2 ? <div>
      <div className='progress-bar-wrapper'>
        <div className='progress-bar-line'></div>
        <div className={`progress-bar-line-filled step-${step}`}></div>
        <div className='progress-bar-circles-wrapper'>
          <div className={`progress-bar-circle filled`}>
            <div className='progress-bar-number number-1'>
              1
            </div>
          </div>
          <div className={`progress-bar-circle active`}>
            <div className='progress-bar-number'>
              2
            </div>
          </div>
          <div className={`progress-bar-circle`}>
            <div className='progress-bar-number'>
              3
            </div>
          </div>

        </div>
      </div>

      </div> : null
    }

  const hasStepThreeComplete = (step) => {
    return edsContract && step === 3 ? <div>
      <div className='progress-bar-wrapper'>
        <div className='progress-bar-line'></div>
        <div className={`progress-bar-line-filled step-${step}`}></div>
        <div className='progress-bar-circles-wrapper'>
          <div className={`progress-bar-circle filled`}>
            <div className='progress-bar-number number-1'>
              1
            </div>
          </div>
          <div className={`progress-bar-circle filled`}>
            <div className='progress-bar-number'>
              2
            </div>
          </div>
          <div className={`progress-bar-circle active`}>
            <div className='progress-bar-number'>
              3
            </div>
          </div>

        </div>
      </div>

      </div> : null
    }

  const hasStepFourComplete = (step) => {
    return edsContract && step === 4 ? <div>
      <div className='progress-bar-wrapper'>
        <div className={`progress-bar-line step-${step}`}></div>
        <div className={`progress-bar-line-filled step-${step}`}></div>
        <div className='progress-bar-circles-wrapper'>

          <div className={`progress-bar-circle filled`}>
            <div className='progress-bar-number number-1'>
              1
            </div>
          </div>
          <div className={`progress-bar-circle filled`}>
            <div className='progress-bar-number'>
              2
            </div>
          </div>
          <div className={`progress-bar-circle filled`}>
            <div className='progress-bar-number'>
              3
            </div>
          </div>

        </div>
      </div>

      </div> : null
    }

    const viewStepTwoComplete = (step) => {
      return edsContract && step === 2 ? <div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-1'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-1-instructions'/></div>
        </div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-2'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-2-instructions'/></div>
        </div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-3'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-3-instructions'/></div>
        </div>
      </div> : null
    }

    const viewStepThreeComplete = (step) => {
      return edsContract && step === 3 ? <div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-1'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-1-instructions'/></div>
        </div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-2'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-4-instructions'/></div>
        </div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-3'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-3-instructions'/></div>
        </div>
      </div> : null
    }

    const viewStepFourComplete = (step) => {
      return edsContract && step === 4 ? <div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-1'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-1-instructions'/></div>
        </div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-2'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-4-instructions'/></div>
        </div>
        <div className='step-text-wrapper'>
          <div className='step'><FormattedMessage id='app.customer-portal-home.progress-step-3'/></div>
          <div className='step-text'><FormattedMessage id='app.customer-portal-home.progress-step-5-instructions'/></div>
        </div>
      </div> : null

    }




    return(
        login && <div id='customer-portal'>
        <CustomerPortalNav />
        <div id='customer-portal-content'>
          <div id='customer-portal-dashboard' className='customer-portal-pages'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <div className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.customer-portal.dashboard-header'/>
            </div>
          </div>

        <div className='customer-portal-dashboard-container'>
          <div className='customer-portal-dashboard-welcome'>
            <h2>
              <FormattedMessage id='app.customer-portal-home.welcome-name'/> {contact.firstName}!
            </h2>
          </div>


          <div className='customer-portal-dashboard-content-heading'>
            <FormattedMessage id='app.customer-portal-home.intro'/>
          </div>
          { checkedFiles === true ?
            <div className='dashboard-blocks-container'>

            { step === 4 ?
              <div className='dashboard-content-block'>
                <div className='dashboard-content-block-heading-container'>
                  <div className='content-block-heading'>
                    <FormattedMessage id='app.customer-portal-home.onboarding-heading-2'/>
                  </div>
                  <div className='content-block-sub-heading'>
                    <FormattedMessage id='app.customer-portal-home.onboarding-sub-heading-2a'/>
                  </div>
                </div>
                <div className='dashboard-blocks-content-container'>
                  <div className='flex'>
                  <div className='content-block-text'>
                    <FormattedMessage id='app.customer-portal-home.onboarding-text-2'/>
                  </div>
                    <div className='content-block-image'>
                      <img src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/clocks/clock-360px.png`} />
                    </div>
                  </div>
                </div>
              </div>:null
            }


            <div className='dashboard-content-block progress-block'>
              <div className='dashboard-content-block-heading-container'>
                <div className='content-block-heading'>
                  { step !== 4 ?
                    <FormattedMessage id='app.customer-portal-home.progress-heading'/>
                  :
                    <FormattedMessage id = 'app.customer-portal-home.progress-done-heading'/>
                  }
                </div>

                <div className='content-block-sub-heading'>
                  { step !== 4 ? <FormattedMessage id='app.customer-portal-home.progress-sub-heading'/>
                    : <FormattedMessage  id='app.customer-portal-home.progress-sign-up-complete-thanks'/>
                  }
                </div>

              </div>
              <div className='dashboard-blocks-content-container'>
                <div className='progress-bar-container'>

                  {hasStepTwoComplete(step)}
                  {hasStepThreeComplete(step)}
                  {hasStepFourComplete(step)}

                  <div className='view-steps-container'>
                    { step !== 4 ?  <a href="#" onClick={(e) => handleViewSteps(e)}>
                      {viewSteps ?
                        <FormattedMessage id='app.customer-portal-home.back-to-progress'/> :
                        <FormattedMessage id='app.customer-portal-home.progress-view-steps'/>
                      }
                    </a>
                    : null
                    }

                  </div>
                  {viewSteps ?
                    <div className='view-all-steps'>
                      { viewStepTwoComplete(step) }
                      { viewStepThreeComplete(step) }
                      { viewStepFourComplete(step) }
                    </div> : null
                  }
                  {!viewSteps && step === 1 ?
                    <div className='step-text-wrapper'>
                      <div className='step'>
                        Step 1:
                      </div>
                      <div className='step-text'>
                        Creating an Account
                      </div>
                    </div> : <div/>
                  }
                  {!viewSteps && step === 2 ?
                    <div className='step-text-wrapper'>
                      <div className='step'>
                        Step 2:
                      </div>
                      <div className='step-text'>
                        <a href="#" onClick={(e) => goToContracts(e)}>
                          Generate and sign contract
                        </a>
                      </div>
                    </div> : <div/>
                  }
                  {!viewSteps && step === 3 ?
                    <div className='step-text-wrapper'>
                      <div className='step'>
                        Step 3:
                      </div>
                    </div> : <div/>
                  }
                  {step === 4 ?
                    <div>
                      <div className='sign-up-complete-header'>
                        <FormattedMessage id='app.customer-portal-home.progress-sign-up-complete-header'/>
                      </div>
                      <div className='sign-up-complete-text'>
                        <FormattedMessage id='app.customer-portal-home.progress-sign-up-complete-text'/>
                      </div>
                    </div>:null
                  }

                  </div>
                </div>

              </div>


            {step < 4 ?
              <div className='dashboard-content-block'>
                <div className='dashboard-content-block-heading-container'>
                  <div className='content-block-heading'>
                    <FormattedMessage id='app.customer-portal-home.onboarding-heading'/>
                  </div>
                  <div className='content-block-sub-heading'>
                    <FormattedMessage id='app.customer-portal-home.onboarding-sub-heading'/>
                  </div>
                </div>
                <div className='dashboard-blocks-content-container'>
                  <div className='content-block-text'>
                    <FormattedMessage id='app.customer-portal-home.onboarding-text-1'/>
                  </div>
                </div>
              </div> : <div/>
            }
            </div>
              : <div className='dashboard-blocks-container'>
                Please wait while we gather your information.
              </div>
          }



        </div>
            <CustomerPortalFooter />
            <CustomerPortalContact />
        </div>
      </div>
      </div>
    )
}

export default CustomerPortalDashboard
