import React, {useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import PickACoachBlock from "../pick-a-coach-block";
import PickACoachLearnMoreModal from "../modals/pick-a-coach-learn-more-modal";
import {verifyToken} from "../../actions/login-actions";
import {useDispatch, useSelector} from "react-redux";
import {setActiveCounselor} from "../../actions/counselor-actions";
import {useHistory} from "react-router-dom";
import ActionPlanNav from "./action-plan-nav";

export default function ActionPlanPickCoach(props) {

  const activeCounselors = useSelector((state) =>state.activeCurrentlyWorkingCounselors)
  const locale = useSelector((state) => state.locale)
  const login = useSelector((state) => state.login)

  const dispatch = useDispatch();

  const history = useHistory();

  const [displayLearnMore, setDisplayLearnMore] = useState(false);
  const [counselor, setCounselor] = useState('');

  const selectCounselor = (counselor) => {
    dispatch(setActiveCounselor(counselor))
    if (displayLearnMore){
      setDisplayLearnMore(false)
    }
    history.push("/action-plan/sessions/appointment-schedule")
    dispatch(verifyToken(login))
  };

  const buildCoachBlock = () => {
    return activeCounselors.map(counselor => {
      return (
          <PickACoachBlock counselor={counselor}
                           key={counselor.userId}
                           locale={locale}
                           selectCounselor={(counselor)=>selectCounselor(counselor)}
                           openLearnMore={(counselor)=>openLearnMoreModal(counselor)}
                           closeLearnMore={()=>closeLearnMoreModal()}/>
      )
    })
  };

  const openLearnMoreModal = (counselor) => {
    setDisplayLearnMore(true)
    setCounselor(counselor)
  };

  const closeLearnMoreModal = () => {
    setDisplayLearnMore(false)
  };

  return (
      <div id='ap-pick-a-coach' className={`${displayLearnMore === false ? '' : 'dimmer'}`}>
        <ActionPlanNav />
        <div id='action-plan-content'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <h1 className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.action-plan-sessions.page-title'/>
            </h1>
          </div>

          {displayLearnMore ? <PickACoachLearnMoreModal
              counselor={counselor}
              closeModal={()=> closeLearnMoreModal()}
              displayModal={displayLearnMore}
              locale={locale}
              selectCounselor={(counselor)=>selectCounselor(counselor)}/> : ''}
          <div>
            <h2 className='action-plan-subtitle margin-bottom-24-px'>
              <FormattedMessage id='app.appointment.title'/>
            </h2>
          </div>
          <div>
            <div className='detail margin-bottom-16-px'>
              <FormattedMessage id='app.appointment.description'/>
            </div>
          </div>
          <div>
            {activeCounselors && activeCounselors.length > 0 ? buildCoachBlock() : ''}
          </div>
        </div>
      </div>)

};