import React, {useEffect} from 'react'

import FormattedMessage from './../helpers/FormattedMessage'
import Field from './../helpers/Field'
import HeaderContainerAP from '../../containers/header-container-ap'
import FooterContainerAp from '../../containers/footer-container-ap'
import {Link} from "react-router-dom";
import {reduxForm} from "redux-form";
import ValidationService from "../../services/validation-service";
import {
    createOrUpdateContact, getOrCreateLoginRecord, resetLogin, resetRegistration, resetTemporaryEmail,
} from "../../actions/b-flow-actions";
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {displayLoadingIcon} from "../../actions/loading-icon-action";

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

const checkboxWidget = ({input, placeholder, type, value, meta: {touched, error}}) => (
    <div className='tos-checkbox-container'>
        <input {...input}  placeholder={placeholder} type={type}/>
        <span className='tos-checkmark'></span>
        <span className='tos-checkmark-text'>
      <FormattedMessage id='app.b-flow.full-name-form.agreement.description'
                        values={{
                            termsLink: (
                                <Link to={`/terms`} id="#terms-link">
                                    <FormattedMessage id='app.b-flow.full-name-form.agreement.terms-link'/>
                                </Link>
                            ),
                            privacyLink: (
                                <Link to={`/privacy-policy`} id="#terms-link">
                                    <FormattedMessage id='app.b-flow.full-name-form.agreement.privacy-link'/>
                                </Link>
                            )
                        }}
      />
    </span>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

function BFlowFullNameForm(props){

  const login = useSelector(state => state.login);
  const registration = useSelector(state => state.registration);

  const dispatch = useDispatch();

  const isSubmitDisabled = (props) => {
    return props.pristine ||
      props.submitting ||
      !props.valid;
  }

  useEffect(() => {
      if (login === null || registration === null){
          props.history.push('/enter-your-email-address')
      } else if (login != null && login !== '') {
          if (login.status === 'Active' && login.token != null) {
            props.history.push('/select-a-financial-coach')
          } else if (login.nextPage === 'TEMPPASSWORD') {
            props.history.push('/enter-one-time-code')
          } else if (login.nextPage === 'CREATEPASSWORD') {
            props.history.push('/make-a-password')
          } else if (login.nextPage === 'PASSWORD') {
            props.history.push('/enter-your-password')
          } else if (login.nextPage === 'RESETPASSWORD') {
              props.history.push('/reset-password')
          } else if (login.nextPage === 'CPLOGIN') {
              // props.history.push('/customers/enter-your-password')
              props.history.push('/enter-your-password')
          } else if (login.nextPage === 'CPTEMPCODE' || login.nextPage === 'CPREGISTRATION' ) {
              // props.history.push('/customers/enter-one-time-code')
              props.history.push('/enter-one-time-code')
          }
    }
  }, [])

  const clearRegistration = () => {
    dispatch(resetTemporaryEmail())
    dispatch(resetRegistration())
    dispatch(resetLogin())
  }

  const {intl} = props;

  return (
    <div className='b-flow b-flow-background'>
      <HeaderContainerAP/>
      <div className='b-flow-body full-name-form'>

        <div className='container-ap welcome-form'>

          <h1>
            <FormattedMessage id="app.b-flow.full-name-form.title"/>
            <br />
            <FormattedMessage id="app.b-flow.full-name-form.instruction"/>
          </h1>
          <form onSubmit={props.handleSubmit}>
            <div className='form-group margin-bottom-32-px'>
              <Field name='firstName' type='text' id='firstName' component={renderField} placeholder={intl.formatMessage({ id: 'app.b-flow.full-name-form.placeholder.first-name'})} />
              <Field name='lastName' type='text' id='lastName' component={renderField} placeholder={intl.formatMessage ({ id: 'app.b-flow.full-name-form.placeholder.last-name'})} />
            </div>

            <div className='form-group mtxxl'>
              <label className='checkbox-inline'>
                <Field type='checkbox' name='agreedToTerms' id='agreedToTerms' component={checkboxWidget} />
              </label>
            </div>

            <div className='submit-button-round margin-bottom-24-px'>
              <button id='submit'
                      type={'submit'}
                      className='btn btn-red'
                      disabled={isSubmitDisabled(props)}>
                <FormattedMessage id='app.b-flow.full-name-form.continue.button'/>
              </button>
            </div>
          </form>
          <div>
            {
                login == '' ?
                  <div className='not-new-link'>
                    <Link to={`/enter-your-email-address`} onClick={clearRegistration}>
                      <div className='small-text'>
                        <FormattedMessage id='app.b-flow.full-name-form.not-new'/>
                      </div>
                    </Link>
                  </div>
                : ''
            }
          </div>
        </div>
        <FooterContainerAp/>
      </div>
    </div>)
}

function goToNextPage(props) {
    props.history.push('/enter-one-time-code');
}

export default reduxForm({
    form: 'BFlowFullNameForm',
    validate: ValidationService.fullName,
    onSubmit: (values, dispatch, props) => {
      dispatch(displayLoadingIcon(true))
      return dispatch(createOrUpdateContact(values)).then(() => {
        dispatch(getOrCreateLoginRecord())
        dispatch(displayLoadingIcon(false))
        goToNextPage(props)
      })
    },
    destroyOnUnmount: false
})(injectIntl(BFlowFullNameForm))
