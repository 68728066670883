import React from 'react'

export const DclCoaching = ({
                               style = {},
                               fill = '#fff',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 200 200',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <circle cx="100" cy="100" r="100" fill="#003057"/>
        <g>
          <path d="M74.06,61.08c-.53-.31-1.07-.53-1.56-.62l.56,2.25c1.8.07,2.74.62,3.17,1.87v.02c.45,1.16-.22,2.38-1.56,3.37l.38,1.09-1.16.89-.39-1.21c-1,.57-2.06.82-2.98.66l.45-2.35c.75.22,1.4.26,2.1.02l-.59-2.18c-1.72.22-2.7-.25-3.12-2.31v-.03c-.39-1.99.26-3.6,1.58-4.08l-.21-1.01,1.12-.06.22,1c.79.1,1.53.47,2.26,1l-.28,1.68ZM71.95,62.68l-.49-2.14c-.51.24-.64.7-.53,1.23v.03c.12.52.35.79,1.02.88ZM73.61,64.74l.53,1.78c.49-.36.67-.75.54-1.18v-.02c-.13-.4-.4-.58-1.07-.58Z" fill="#003057"/>
          <g>
            <path d="M143.9,53.08v18.33c0,9.95-8.14,18.08-18.08,18.08h-.3l6.23,15.17c.14.34-.45.59-.78.33l-20.23-15.5h-22.52c-9.95,0-18.08-8.14-18.08-18.08v-18.33c0-9.95,8.14-18.08,18.08-18.08h37.61c5.18,0,9.86,2.21,13.17,5.73,3.04,3.24,4.92,7.59,4.92,12.36Z" fill="#003057" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <g>
              <circle cx="68.97" cy="122.27" r="11.34" fill="#00c7b1" stroke="#00c7b1" stroke-miterlimit="10" stroke-width="2"/>
              <path d="M45.23,161.76v-4.41c0-10.65,8.63-19.28,19.28-19.28h8.9c10.65,0,19.28,8.63,19.28,19.28v4.41c0,1.79-1.45,3.24-3.24,3.24h-40.99c-1.79,0-3.24-1.45-3.24-3.24Z" fill="#00c7b1" stroke="#00c7b1" stroke-miterlimit="10" stroke-width="2"/>
            </g>
            <g>
              <circle cx="131.03" cy="122.27" r="11.34" fill="#00c7b1" stroke="#00c7b1" stroke-miterlimit="10" stroke-width="2"/>
              <path d="M107.3,161.76v-4.41c0-10.65,8.63-19.28,19.28-19.28h8.9c10.65,0,19.28,8.63,19.28,19.28v4.41c0,1.79-1.45,3.24-3.24,3.24h-40.99c-1.79,0-3.24-1.45-3.24-3.24Z" fill="#00c7b1" stroke="#00c7b1" stroke-miterlimit="10" stroke-width="2"/>
            </g>
            <g>
              <path d="M57.79,70.23v7.51c0,8.08,6.61,14.69,14.69,14.69h.24l-5.06,12.32c-.11.28.36.48.64.27l16.43-12.59h18.29c8.08,0,14.69-6.61,14.69-14.69v-7.51c0-8.08-6.61-14.69-14.69-14.69h-30.54c-4.2,0-8.01,1.79-10.69,4.65-2.47,2.63-3.99,6.16-3.99,10.04Z" fill="#003057" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="72.52" y1="68.45" x2="103.71" y2="68.45" fill="none" stroke="#00c7b1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="72.52" y1="79.83" x2="103.71" y2="79.83" fill="none" stroke="#00c7b1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
          </g>
        </g>
      </g>
    </g>

  </svg>;

export default DclCoaching