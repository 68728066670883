import React from 'react'

export const Minus = ({
                       style = {},
                       fill = '#141006',
                       width = '100%',
                       className = '',
                       height = '100%',
                       viewBox = '0 0 448 512',
                     }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <path d="M424 318.2c13.3 0 24-10.7 24-24v-76.4c0-13.3-10.7-24-24-24H24c-13.3 0-24 10.7-24 24v76.4c0 13.3 10.7 24 24 24h400z" fill={fill}/>
  </svg>;

export default Minus