import React from 'react';
import Field from './helpers/Field'
import FormattedMessage from './helpers/FormattedMessage'
import FormUserError from './form-user-error'
import FormError from './form-error'

export default class UploadFileInput extends React.Component {
  render() {
    const { input,dataAllowedFileExtensions } = this.props
    const onInputChange = (e) => {
        e.preventDefault();
        const files = [...e.target.files];
        input.onChange(files);
    };
    return (
      <div>
        <input type="file"
               onChange={onInputChange}
               data-allowed-file-extensions={dataAllowedFileExtensions}               />
      </div>
    )
  }
}
