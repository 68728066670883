import {MARKED_ACHIEVED_TAKE_ACTION_TODAY} from '../actions/take-action-today-actions'

function markedAchievedTakeActionToday(state = [], action) {
  switch (action.type) {
    case MARKED_ACHIEVED_TAKE_ACTION_TODAY:
      return action.markedAchievedTakeActionToday
    default:
      return state
  }
}

export default markedAchievedTakeActionToday