import React from 'react'
import Field from './helpers/Field'
import FormattedMessage from './helpers/FormattedMessage'
import FormUserError from './form-user-error'
import FormError from './form-error'

const isSubmitDisabled = (props, state) => {
  return props.pristine ||
    props.submitting ||
    !props.valid ||
    state.isProcessing;
}

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default class ChangeEmailForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isProcessing: false,
            fatalFormError: null
        }
    }

    componentDidUpdate(prevProps, nextProps, state) {
        if (this.props.error !== prevProps.error && this.props.error !== null) {
            this.props.updateLoadingIcon(false)

            let err = this.props.error
            this.setState({
                fatalFormError: err,
                isProcessing: false
            })

            this.props.verifyToken()
        }
    }

    editAction() {
        this.props.setMyAccountComponent("ChangeEmail")
    }

    cancelAction() {
        this.props.setMyAccountComponent("Account")
    }

    render() {

        const {intl, formSyncErrors}=this.props;

        return (
            <div className='my-account-wrapper'>
                {
                    (this.props.currentMyAccountComponent === 'ChangeEmail' ? (<div className='my-account-active-wrapper'>
                    <div className='my-account-active'>
                        {/*<form onSubmit={e => {e.preventDefault();}}>*/}
                        <form onSubmit={this.props.handleSubmit}>
                          <div className='my-account-reg-view-field margin-bottom-8-px'>
                            <FormattedMessage id='app.action-plan-account.change-email.page-title'/>
                          </div>

                            <div className='my-account-personal-detail margin-bottom-24-px'>
                                <FormattedMessage id='app.action-plan-account.change-email.instruction1'/>
                            </div>

                            <div className='my-account-input-field margin-bottom-16-px'>
                                <Field name='newUsername' type='text' id='newUsername' component={renderField}/>
                            </div>

                            <div>
                                <FormUserError validationErrors={formSyncErrors} intl={intl}/>
                                <FormError error={this.state.fatalFormError}/>
                            </div>

                            <div className='submit-button-round margin-bottom-24-px'>
                                <button id='cancel'
                                        type={'button'}
                                        className='btn btn-default btn-lg my-account-btn my-account-btn-active my-account-btn-cancel'
                                        onClick={() => this.cancelAction()}>
                                    <FormattedMessage id='app.common.cancel.button'/>
                                </button>
                                <button id='submit'
                                        type={'submit'}
                                        className='btn btn-primary btn-lg my-account-btn my-account-btn-active my-account-btn-save'
                                        disabled={isSubmitDisabled(this.props, this.state)}>
                                    <FormattedMessage id='app.common.save.button'/>
                                </button>
                            </div>
                        </form>
                      </div>
                        <div className='whitespace-8px-top'></div>
                    </div>)
                        :
                    (<div>
                          <div className='my-account-reg-view-field margin-bottom-8-px'>
                            <FormattedMessage id='app.action-plan-account.change-email.page-title'/>
                          </div>
                              <div className='submit-button-round my-account-edit-button'>
                                <button id='edit-email'
                                        type={'button'}
                                        className='btn btn-default btn-lg my-account-btn'
                                        onClick={() => this.editAction()}>
                                    <FormattedMessage id='app.common.edit.button'/>
                                </button>
                              </div>
                        <div className='my-account-personal-detail margin-bottom-32-px'>
                            {this.props.login.username}
                        </div>
                        {
                          this.props.currentMyAccountComponent === 'ChangePhone' ?
                            (<div className='action-plan-divide my-account-divide-hide'></div>)
                            : (<div className='action-plan-divide my-account-divide'></div>)
                        }
                    </div>))
                }
            </div>
        )
    }
}
