import React, {useEffect, useState} from 'react'
import { useLocation } from "react-router-dom"
import FormattedMessage from "./helpers/FormattedMessage";
import Field from "./helpers/Field";
import Option from "./helpers/Option";
import HeaderContainer from "../containers/header-container";
import FooterContainer from "../containers/footer-container";
import {useDispatch, useSelector} from "react-redux";
import {clientRetrieved, getClient, clearClient} from "../actions/client-actions";
import QueryStringService from "../services/query-string-service";

export default function AdminTimezone(props){
    const dispatch = useDispatch()
    const location = useLocation();

    const isSubmitDisabled = () => {
        if (timezone == 'none'){
            return true
        } else {
            return false
        }
    }

    const timezone = useSelector(store => store['timezone']['clientTimezone']);

    useEffect(() => {
        let cid = QueryStringService.getValue(location.search, "cid")
        handleCid(cid)
    }, [])

    function handleCid(cid) {
        dispatch(clientRetrieved(false))
        if (cid) {
            dispatch(getClient(cid))
        } else {
            dispatch(clearClient())
            dispatch(clientRetrieved(true))
        }
    }

    function hasDST(date = new Date()) {
        const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

        return Math.max(january, july) !== date.getTimezoneOffset();
    }

    return (
        <div>
            <HeaderContainer displayAlert={props.showAlert}
                             alertName={props.nameOfAlert}/>

            <div className='container ptxxl'>
                <form onSubmit={props.handleSubmit}
                      onChange={props.handleChange}>
                    <div className='form-group form-group-additional-questions'>
                        <label htmlFor='appointment-client-timezone'>
                            <FormattedMessage id='app.appointment.timezone'/>
                        </label>
                        <div className='detail'>
                            <FormattedMessage id='app.appointment.timezone-note'/>
                        </div>

                            { hasDST() ?
                              <Field name='clientTimezone' component='select' id='appointment-client-timezone'
                                     className='form-control'>
                                  <Option value='none' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='edt' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='cdt' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='mdt' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='pdt' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='adt' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='hdt' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='mst' i18nPrefix='app.appointment.timezone.answer.'/>
                              </Field>
                            :
                              <Field name='clientTimezone' component='select' id='appointment-client-timezone'
                                    className='form-control'>
                                  <Option value='none' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='est' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='cst' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='mst' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='pst' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='ast' i18nPrefix='app.appointment.timezone.answer.'/>
                                  <Option value='hst' i18nPrefix='app.appointment.timezone.answer.'/>
                              </Field>
                            }

                        <div className='container ptxxl'>
                            <div className='submit-button-round margin-bottom-16-px'>
                                <button id='submit'
                                        type={'submit'}
                                        className='btn btn-primary btn-block btn-lg'
                                        disabled={isSubmitDisabled(props)}
                                >
                                    <FormattedMessage id='app.appointment.timezone-submit'/>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <FooterContainer/>
        </div>
    )
}