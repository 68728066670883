import {NAV_CURRENT_COMPONENT} from '../actions/nav-actions'

export default function navCurrentComponent(state = '', action) {
  switch (action.type) {
    case NAV_CURRENT_COMPONENT:
      return action.navCurrentComponent
    default:
      return state
  }
}
