import React, {useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import FormattedMessage from '.././helpers/FormattedMessage'
import HeaderContainer from '../../containers/header-container'
import FooterContainer from "../../containers/footer-container";
import {useDispatch, useSelector} from "react-redux";
import {getContact} from '../../actions/registration-actions'

const ndwaEmployers = () => {
    return ['ya45', 'rn12', 'px25']
}

function BFlowSessionScheduled() {

  const employer = useSelector(state => state.employer)
  const activeCounselor = useSelector(state => state.activeCounselor)
  const login = useSelector(state => state.login)
  const registration = useSelector(state => state.registration)
  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    if(login == null){
        history.push(`/enter-your-email-address`)
    }
  }, [])

  useEffect(() =>{
      if (registration !== null && registration.clientSmLink === null)  {
        dispatch(getContact(registration.id));
      }
    })

  function goToSurveyMonkey(link) {
  if (link !== null) {
    window.location.href = link;
  }
}

  const buttonMessage = () => {
    if (ndwaEmployers().includes(employer.code.toLowerCase())) {
      // should this be a new button?
      // return 'app.b-flow.session-scheduled.additional-questions'
      return 'app.common.continue.button'
    } else {
      return 'app.common.continue.button'
    }
  }

  const nextPage = (code) => {
    if (ndwaEmployers().includes(employer.code.toLowerCase())) {
      if (registration.clientSmLink !== null) {
        window.location.href = registration.clientSmLink;
      }
    } else {
      return `/pull-your-credit-report`
    }
  }

  const renderCounselorImage = () => {
    return (<img src={`/api/counselor/${activeCounselor.userId}/image`}/>)
  }

  return (
    <div className='b-flow'>
        <HeaderContainer
                         displayAlert={null}
                         alertName={null}/>

        <div className='container-ap ptxxl'>

         {activeCounselor ?
          <div>
              <h1>
                <FormattedMessage id='app.schedule-confirmation.title'/>
              </h1>
              <p>
                <FormattedMessage id='app.b-flow.session-scheduled.description-1a'/>
                  {activeCounselor.firstName}
                <FormattedMessage id='app.b-flow.session-scheduled.description-1b'/>
              </p>

              <div className='counselor-b-flow-image-container session-scheduled'>
                  {renderCounselorImage()}
              </div>
          </div>
             : '' }

          <p className='detail margin-bottom-40-px'>
              <FormattedMessage id='app.b-flow.session-scheduled.description-2'/>
          </p>

            <div className='form-group mtxxl'>
                <div className='submit-button-round margin-bottom-24-px'>
                    <Link id='credit-report'
                          to={nextPage(employer.code)}
                          className='btn btn-primary btn-block btn-lg'>
                        <FormattedMessage id={buttonMessage()}/>
                    </Link>
                </div>
            </div>
        </div>
        <FooterContainer/>
    </div>
  )
}

export default BFlowSessionScheduled