import React, {useEffect} from 'react'
import FormattedMessage from "../helpers/FormattedMessage";

function LandingPagesHelpYou(props) {

  return (
    <div id='landing-pages-help-you'>
      <div className='how-we-can-help graybg'>
        <div className='landing-propel-container'>
          <div className='propel-section-header'>
            <FormattedMessage id='app.landing-page.775.help-you-1'/>
          </div>
          <div className='how-we-can-help-wrapper'>
            <div className='how-we-can-help-col-1'>
              <div className='how-we-can-help-box-1'>
                <div className='how-we-can-help-box-1-content'>
                  <div className='propel-small-header'>
                    <FormattedMessage id='app.landing-page.775.help-you-2'/>
                  </div>
                  <div className='propel-small-text'>
                    <FormattedMessage id='app.landing-page.775.help-you-3'/>
                  </div>
                  <div className='submit-button-round btn-propel-landing'>
                    <button className='btn btn-primary btn-block btn-propel-landing'
                            id='submit'
                            onClick={props.goToAppointmentSchedule}>
                      <FormattedMessage id='app.landing-page.775.help-you-4'/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='how-we-can-help-col-2'>
              <div className='how-we-can-help-box-2'>
                <div className='how-we-can-help-box-2-col-1'>
                  <div className='propel-small-header'>
                    <FormattedMessage id='app.landing-page.775.help-you-5'/>
                  </div>
                  <div className='propel-small-text'>
                    <FormattedMessage id='app.landing-page.775.help-you-6'/>
                  </div>
                </div>
                <div className='how-we-can-help-box-2-col-2'>
                  <div className='submit-button-round btn-propel-landing'>
                    <button className='btn btn-primary btn-block btn-propel-landing'
                            id='submit'
                            onClick={props.goToAskQuestion}>
                      <FormattedMessage id='app.landing-page.775.help-you-5-a'/>
                    </button>
                  </div>
                </div>
              </div>
              <div className='how-we-can-help-box-3'>
                <div className='how-we-can-help-box-3-col-1'>
                  <div className='propel-small-header'>
                    <FormattedMessage id='app.landing-page.775.help-you-7'/>
                  </div>
                  <div className='propel-small-text'>
                    <FormattedMessage id='app.landing-page.775.help-you-8'/>
                  </div>
                </div>
                <div className='how-we-can-help-box-3-col-2'>
                  <div className='submit-button-round btn-propel-landing'>
                    <button className='btn btn-primary btn-block btn-propel-landing'
                            id='submit'
                            onClick={props.goToTrustPlus}>
                      <FormattedMessage id='app.landing-page.775.help-you-9'/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default LandingPagesHelpYou
