export const NAV_CURRENT_COMPONENT = 'NAV_CURRENT_COMPONENT'
export const NAV_ENTERED = 'NAV_ENTERED'
export const CURRENT_MY_ACCOUNT_COMPONENT = 'CURRENT_MY_ACCOUNT_COMPONENT'
export const EMPLOYER_LOADED_FOR_NAV = 'EMPLOYER_LOADED_FOR_NAV'

export function navEntered(truthy) {
  let entered = truthy === true
  return {
    type: NAV_ENTERED,
    navEntered: entered
  }
}

export function employerLoadedForNav(truthy) {
  let entered = truthy === true
  return {
    type: EMPLOYER_LOADED_FOR_NAV,
    employerLoadedForNav: entered
  }
}

export function navCurrentComponent(val) {
  let currentComponent = val
  return {
    type: NAV_CURRENT_COMPONENT,
    navCurrentComponent: currentComponent
  }
}

export function currentMyAccountComponent(val) {
  let currentMyAccountComponent = val
  return {
    type: CURRENT_MY_ACCOUNT_COMPONENT,
    currentMyAccountComponent: currentMyAccountComponent
  }
}
