import {GET_BOOKED_APPOINTMENTS} from '../actions/calendly-actions'

const bookedAppointments = (state = [], action) => {
  switch (action.type) {
    case GET_BOOKED_APPOINTMENTS:
      return action.bookedAppointments;
    default:
      return state
  }
}

export default bookedAppointments
