import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";
import Icon from "../../../resources/icons";
import {updateCPAccountPhone} from "../../actions/cp-flow-actions";
import {connect} from "react-redux";
import ValidationService from "../../services/validation-service";
import {Field, reduxForm} from "redux-form";
import NormalizationService from "../../services/normalization-service";
import {reGetContact} from "../../actions/registration-actions";

const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

function CustomerPortalAccountSettingsPhone(props) {

  const isSubmitDisabled = (props) => {
    return props.pristine ||
      props.submitting ||
      !props.valid;
  }

    return(
      <div>
        <div className='customer-portal-back-link' onClick={((e) => {
          props.onSimpleClick(e, props.pageAccSettings)
        })}>
          <FormattedMessage id='app.customer-portal-common.back-link'/>
        </div>
        <h2><FormattedMessage id='app.customer-portal.my-account.phone'/></h2>
        <div className='content-text'>
          <FormattedMessage id='app.customer-portal.my-account.phone-text-1'/>
        </div>
        <div className='content-text'>
          <FormattedMessage id='app.customer-portal.my-account.phone-text-2'/>
        </div>

        <form onSubmit={props.handleSubmit}>
          <div className='form-group margin-bottom-32-px'>
            <label><FormattedMessage id='app.customer-portal.my-account.phone'/></label>
            <Field
              name='preferredPhone'
              type='text'
              id='preferredPhone'
              component={renderField}
              normalize={NormalizationService.mobilePhone}/>
          </div>

          {!props.submitSucceeded ? <>
              {props.dirty ? <>
                <div className='submit-button-round continue'>
                  <button
                    className="btn btn-primary btn-block"
                    id="submit"
                    type={'submit'}
                    disabled={isSubmitDisabled(props)}>
                    <FormattedMessage id='app.common.save.button'/>
                  </button>
                </div>
              </> :<div/>
              }

            </>:
            <div className='w-50'>
              <div className='big-check-circle'>
                <Icon name="checkmark"/>
              </div>
              <div className='big-check-text'>
                <FormattedMessage id='app.customer-portal.my-account.phone-text-3'/>
              </div>
            </div>
          }
        </form>

      </div>
    )
}

const mapStateToProps = (state) => {

  return {
    initialValues: {
      preferredPhone: state.registration && state.registration.preferredPhone? state.registration.preferredPhone : '',
    }
  }
}

const sendAccountPhoneForm = reduxForm({
  form: 'CustomerPortalAccountSettingsPhoneForm',
  enableReinitialize: true,
  validate: ValidationService.cpAccountPhone,
  onSubmit: (values, dispatch, props) => {
    return dispatch(updateCPAccountPhone(values)).then(() => {
      dispatch(reGetContact()).then(() => {
       //
      })
    })
  },
  destroyOnUnmount: false
})(injectIntl(CustomerPortalAccountSettingsPhone))

export default connect(mapStateToProps)(sendAccountPhoneForm)
