import {connect} from 'react-redux'
import {injectIntl} from "react-intl";
import {toggleLocale} from "../../actions/locale-actions";
import NtfcuSupportHeader from "../../components/ntfcu-support/ntfcu-support-header";

function mapStateToProps(state) {
  return {
    locale: state.locale,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle(currentLocale) {
      dispatch(toggleLocale(currentLocale))
    }
  }
}

const NtfcuSupportHeaderContainer = connect(
  mapStateToProps,
    mapDispatchToProps
)(injectIntl(NtfcuSupportHeader))

export default NtfcuSupportHeader
