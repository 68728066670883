import React from 'react'

export const TPLogo = ({
                             style = {},
                             fill = '#fff',
                             width = '100%',
                             className = '',
                             height = '100%',
                             viewBox = '0 0 80.14 91.09',
                           }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>TrustPlus</title>
    <polygon className="tp-logo cls-1" points="64.55 48.53 64.55 91.09 53.28 91.09 53.28 48.53 37.69 48.53 37.69 37.69 80.14 37.69 80.14 48.53 64.55 48.53"/>
    <path className="tp-logo  cls-2" d="M37.69,0h0A26.86,26.86,0,0,1,64.55,26.86v0a0,0,0,0,1,0,0H37.69a0,0,0,0,1,0,0V0A0,0,0,0,1,37.69,0Z"/>
    <path className="tp-logo cls-3" d="M26.86,0h0a0,0,0,0,1,0,0V26.86a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A26.86,26.86,0,0,1,26.86,0Z"/>
    <polygon className="tp-logo cls-4" points="0 37.69 26.86 37.69 26.86 64.55 0 37.69"/>
  </svg>

export default TPLogo