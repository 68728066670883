import React from 'react'

export const Reports = ({
                            style = {},
                            fill = '#000000',
                            width = '100%',
                            className = '',
                            height = '100%',
                            viewBox = '0 0 21 18',
                          }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M2.047 1.344a1 1 0 0 0-2 0v13.913a1 1 0 0 0 1 1h18.907a1 1 0 0 0 0-2H2.047V1.344zm15.303 10.99h1.668a.597.597 0 0 0 .556-.555V1.798a.597.597 0 0 0-.556-.555H17.35a.597.597 0 0 0-.556.555v9.98c.02.298.257.535.556.555zm-4.17 0h1.668a.597.597 0 0 0 .556-.555V5.956a.597.597 0 0 0-.556-.554h-1.669a.597.597 0 0 0-.556.554v5.823c.02.297.258.534.556.554zm-6.671 0H4.841a.597.597 0 0 1-.557-.555V8.728a.597.597 0 0 1 .557-.555h1.668c.298.02.536.257.556.555v3.05a.597.597 0 0 1-.556.555zm2.502 0h1.668a.597.597 0 0 0 .557-.555V3.185a.597.597 0 0 0-.557-.555H9.01a.597.597 0 0 0-.557.555v8.594c.02.297.258.534.556.554h.003z" fill="#000"/>

  </svg>;

export default Reports