import axios from 'axios'
import 'moment-timezone'

export const GET_CLIENT = 'GET_CLIENT'
export const CLIENT_RETRIEVED = 'CLIENT_RETRIEVED'
export const SET_TIMEZONE = 'SET_TIMEZONE'

export function getClient(id) {
  return (dispatch) => {
    return axios
      .get('/api/getContact', {params: {id}})
      .then(response => {
        dispatch({
          type: GET_CLIENT,
          client: response.data
        })
        dispatch({
          type: CLIENT_RETRIEVED,
          clientRetrieved: true
        })
      })
      .catch(error => {
        dispatch({
          type: GET_CLIENT,
          client: null
        })
        dispatch({
          type: CLIENT_RETRIEVED,
          clientRetrieved: true
        })
      })
  }
}

export function clientRetrieved(truthy) {
  return (dispatch) => {
    dispatch({
      type: CLIENT_RETRIEVED,
      clientRetrieved: truthy
    })
  }
}

export function clearClient() {
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT,
      client: null
    })
  }
}

export function setClientTimezone(timezone) {
  return (dispatch) => {
    dispatch({
      type: SET_TIMEZONE,
      timezone: timezone
    })
  }
}
