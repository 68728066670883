import React from 'react';
import FormattedMessage from '../helpers/FormattedMessage'

export default function BudgetSaveWarningModal(props) {

  function cancel() {
    props.cancelModal()
  }

  function save() {
    props.saveModal()
  }

    return (
        <div className="modal-wrapper">
            <div className="modal-body" style={{ transform: props.displayModal ? 'none' : 'translateY(-100vh)', opacity: props.displayModal ? '1' : '0', display: props.displayModal ? 'block' : 'none'}}>

              <div className='budget-save-warning-wrapper'>
                <div className='budget-save-warning-x' onClick={() => cancel()}>
                </div>
                <div className='budget-save-warning-box'>
                  <div className='budget-save-warning-text-header'>
                    <FormattedMessage id='app.action-plan-budget.warning.wait-a-second'/>
                  </div>
                  <div className='budget-save-warning-text'>
                    <FormattedMessage id='app.action-plan-budget.warning.notice-changes' />
                  </div>
                  <div className='budget-save-warning-buttons-wrapper'>
                    <div className='submit-button-round budget-save-button budget-save-warning-button budget-dont-save'>
                      <button id='dont-save'
                              onClick={() => cancel()}
                              type={''}
                              className='btn btn-primary btn-block btn-lg'>
                        <FormattedMessage id='app.action-plan-budget.warning.dont-save'/>
                      </button>
                    </div>
                      <div className='submit-button-round budget-save-button budget-save-warning-button budget-save'>
                        <button id='save'
                                onClick={() => save()}
                                type={'submit'}
                                className='btn btn-primary btn-block btn-lg'>
                          <FormattedMessage id='app.action-plan-budget.warning.save'/>
                        </button>
                      </div>
                  </div>
                  <div className='counselorUser-picture-wrapper-save-warning-budget'>
                    <div className='counselorUser-picture-budget counselorUser-picture-save-warning'>
                      {
                        props.imageId ?
                            <img src={`/api/counselorUser/${props.imageId}/image`} className='img-circle'/>
                            :
                            <div className='counselorUser-image-default img-circle'></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    )

}


