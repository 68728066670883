import React from 'react'

export const ArrowRed = ({
                        style = {},
                        fill = '#141006',
                        width = '100%',
                        className = '',
                        height = '100%',
                        viewBox = '0 0 16 21',
                      }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g id="Section-2D---Terms" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <g id="TPC---Terms_Fail2nd---Max-widths-of-various-elements" transform="translate(-1020.000000, -397.000000)" fill="#BA122B">
        <g id="Group-3" transform="translate(1028.687270, 408.778844) rotate(-30.000000) translate(-1028.687270, -408.778844) translate(1023.687270, 398.064558)">
          <path d="M4.2907504,20.3449425 C1.67208238,18.2434388 -0.0066354427,15.0155302 -0.000193531425,11.4079386 C0.00530892056,8.35406759 1.20871906,5.58134869 3.16503242,3.534298 L1.51917989,1.88844547 C0.822451778,1.19167127 1.315908,0.000380952381 2.30125362,0.000380952381 L8.47908772,0.000380952381 C9.08991722,0.000380952381 9.58507851,0.495542243 9.58507851,1.10637174 L9.58507851,7.28420584 C9.58507851,8.26950538 8.39378818,8.7629616 7.69701399,8.06627957 L5.77305086,6.14231644 C4.4412997,7.56462058 3.70360385,9.40503533 3.68673749,11.3602888 C3.66303335,14.1048895 5.08633079,16.5716471 7.27926719,17.9685176 C8.48841452,18.738728 6.24845293,21.9160157 4.2907504,20.3449425 Z" id="Fill-1"></path>
        </g>
      </g>
    </g>

  </svg>;

export default ArrowRed