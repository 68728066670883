import React from 'react'

export const BudgetJarMore = ({
                                style = {},
                                fill = '',
                                width = '100%',
                                className = '',
                                height = '100%',
                                viewBox = '0 0 80 141',
                              }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g id="Page-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="budget---styles" transform="translate(-491.000000, -176.000000)">
        <g id="Asset-7" transform="translate(491.000000, 177.000000)">
          <path d="M79.223301,64.8718232 L79.223301,61.878453 C79.223301,51.2508287 79.4097087,41.3502762 72.3495146,32.880663 L72.3495146,32.880663 C72.3495146,32.880663 70.4,29.5933702 70.4,26.7392265 C70.3437113,25.1963143 70.6081382,23.6584603 71.176699,22.2220994 L8.47378641,21.3248619 C8.47378641,21.3248619 10.0893204,29.8331492 8.10097087,32.4165746 C1.83300971,39.6099448 0.629126214,48.9458564 0.776699029,58.1270718 C1.27378641,86.3436464 0.776699029,75.1436464 0.776699029,103.352486 L0.776699029,114.475138 C0.776699029,128.861878 17.2893204,139.59779 30.4932039,139.59779 L49.5223301,139.59779 C62.7805825,139.59779 79.231068,128.769061 79.231068,114.475138 L79.231068,110.00442 C79.223301,81.8110497 79.223301,93.0729282 79.223301,64.8718232 Z" id="Path" fill="#D3F0F7" fillRule="nonzero"></path>
          <path d="M67.0834951,127.624309 C71.4563107,125.071823 74.1902913,119.116022 65.9339806,116.625414 C57.6776699,114.134807 38.423301,114.567956 38.423301,114.567956 C38.423301,114.567956 19.6271845,114.908287 14.8116505,117.313812 C9.9961165,119.719337 7.70485437,125.048619 11.8291262,127.585635" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M61.1262136,131.924862 C54.9126214,136.441989 37.2815534,135.119337 29.0252427,133.974586" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <ellipse id="Oval" fill="#D3F0F7" fillRule="nonzero" cx="39.4873786" cy="22.8254144" rx="31.176699" ry="6.85303867"></ellipse>
          <path d="M8.4815534,21.3248619 C8.4815534,21.3248619 6.60970874,24.5348066 10.2291262,25.9657459 C13.8485437,27.3966851 21.4601942,28.7038674 27.6504854,28.9359116" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M71.215534,22.2298343 C71.215534,22.2298343 72.2407767,24.1944751 68.8854369,25.5712707 C65.5300971,26.9480663 57.8796117,28.920442 54.9048544,28.920442" id="Path" stroke="#FFFFFF" strokeWidth="0.98"></path>
          <path d="M72.3572816,16.359116 L72.3572816,6.7679558 C72.3572816,6.7679558 63.4174757,0.379005525 40.007767,0.379005525 C16.5980583,0.379005525 6.78834951,6.31160221 6.78834951,6.31160221 L6.78834951,16.3668508 C6.78834951,16.3668508 4.7223301,16.3668508 4.7223301,17.9679558 C4.7223301,19.5690608 11.1456311,26.0662983 40.031068,26.0662983 C68.9165049,26.0662983 74.2446602,20.3348066 74.2446602,18.0530387 C74.2446602,16.5911602 72.3572816,16.359116 72.3572816,16.359116 Z" id="Path" stroke="#615D59" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M6.78834951,6.31160221 C6.78834951,6.31160221 12.6446602,13.9226519 40.007767,13.9226519 C67.3708738,13.9226519 72.3572816,6.7679558 72.3572816,6.7679558" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M10.384466,14.5723757 C25.584466,19.4762431 42.0970874,18.5016575 57.9106796,17.5270718 C61.3592233,17.0165746 64.792233,16.5447514 68.0543689,15.160221" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M11.5184466,9.42872928 L11.5184466,14.9900552" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M14.3533981,11.1071823 L14.3533981,15.5624309" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M17.2194175,11.679558 L17.2194175,16.1348066" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M20.0854369,12.2519337 L20.0854369,16.6994475" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M22.9514563,12.8243094 L22.9514563,17.2718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M25.8174757,13.3889503 L25.8174757,17.8441989" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M28.6834951,13.961326 L28.6834951,18.4165746" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M31.5495146,14.0773481 L31.5495146,18.5248619" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M34.415534,14.2243094 L34.415534,18.6718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M37.2815534,13.6209945 L37.2815534,18.0762431" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M40.1475728,13.9071823 L40.1475728,18.3624309" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M43.0135922,14.0232044 L43.0135922,18.4707182" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M45.8796117,14.2243094 L45.8796117,18.6718232" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M48.7456311,13.9071823 L48.7456311,18.3546961" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M51.6116505,13.9071823 L51.6116505,18.3546961" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M54.4776699,13.4508287 L54.4776699,17.8983425" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M57.3436893,12.7624309 L57.3436893,17.2176796" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M60.2019417,12.7624309 L60.2019417,17.2176796" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M63.0679612,11.7337017 L63.0679612,16.1889503" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <path d="M65.9339806,10.9911602 L65.9339806,15.4464088" id="Path" stroke="#615D59" strokeWidth="0.98"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(63.580787, 104.609296) rotate(-83.810000) translate(-63.580787, -104.609296) " cx="63.5807866" cy="104.609296" rx="9.3518336" ry="2.50862055"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(73.187348, 97.939813) rotate(-88.050000) translate(-73.187348, -97.939813) " cx="73.1873476" cy="97.9398127" rx="10.6663497" ry="1.7786329"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="60.8854369" cy="96.2596685" rx="7.28543689" ry="3.51933702"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(67.821660, 105.300131) rotate(-79.860000) translate(-67.821660, -105.300131) " cx="67.8216602" cy="105.300131" rx="11.7584226" ry="3.65779263"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(67.820615, 113.786228) rotate(-79.860000) translate(-67.820615, -113.786228) " cx="67.8206149" cy="113.786228" rx="11.7584226" ry="3.65779263"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="43.1378641" cy="127.121547" rx="9.21941748" ry="3.51933702"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.976170518" fill="#FFFFFF" fillRule="nonzero" transform="translate(70.161087, 115.110011) rotate(-83.659808) translate(-70.161087, -115.110011) " cx="70.1610874" cy="115.110011" rx="9.31531209" ry="2.49881206"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(50.240268, 99.780685) rotate(-61.850000) translate(-50.240268, -99.780685) " cx="50.2402679" cy="99.7806849" rx="9.59231548" ry="9.61444018"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(72.048428, 114.914562) rotate(-70.530000) translate(-72.048428, -114.914562) " cx="72.048428" cy="114.914562" rx="12.4974872" ry="1.55277068"></ellipse>
          <path d="M21.1728155,95.1381215 L21.9961165,96.9016575 C22.4543689,97.8762431 27.4873786,96.4994475 33.2349515,93.8077348 C38.9825243,91.1160221 43.2699029,88.1767956 42.8194175,87.2022099 L41.9883495,85.438674 L21.1728155,95.1381215 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(31.576044, 90.329322) rotate(-25.030000) translate(-31.576044, -90.329322) " cx="31.576044" cy="90.329322" rx="11.4788584" ry="1.95071023"></ellipse>
          <path d="M42.2058252,73.2872928 C41.6757599,77.576416 38.0702062,80.8313352 33.7320388,80.9370166 C31.7560025,80.9763122 29.8619621,80.1511088 28.5498211,78.6792066 C27.2376801,77.2073043 26.6393026,75.2366225 26.9126214,73.2872928 C27.3708738,69.0563536 31.168932,69.4198895 35.3941748,69.4198895 C39.6194175,69.4198895 42.6640777,69.0563536 42.2058252,73.2872928 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="38.1902913" cy="125.319337" rx="9.21941748" ry="3.51933702"></ellipse>
          <path d="M58.1281553,76.2110497 C58.6640777,81.3314917 54.4,85.4928177 48.5980583,85.4928177 C42.7961165,85.4928177 37.6543689,81.3392265 37.1184466,76.2110497 C36.5825243,71.0828729 40.8466019,70.7116022 46.6485437,70.7116022 C52.4504854,70.7116022 57.592233,71.0828729 58.1281553,76.2110497 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M17.1662599,100.709593 L41.3673729,100.735829 C41.5603757,100.736038 41.7167833,100.892446 41.7169933,101.085448 L41.7183782,102.358429 C41.7185885,102.551728 41.5620587,102.708599 41.3687592,102.708809 C41.3685058,102.70881 41.3682524,102.70881 41.367999,102.708809 L17.1668859,102.682573 C16.9738832,102.682364 16.8174755,102.525957 16.8172656,102.332954 L16.8158807,101.059974 C16.8156704,100.866674 16.9722001,100.709803 17.1654997,100.709593 C17.1657531,100.709593 17.1660065,100.709593 17.1662599,100.709593 Z" id="Rectangle" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(29.267129, 101.709201) rotate(-15.770000) translate(-29.267129, -101.709201) "></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(27.449439, 81.502771) rotate(-36.970000) translate(-27.449439, -81.502771) " cx="27.4494391" cy="81.5027709" rx="9.15908167" ry="7.68449735"></ellipse>
          <path d="M16.776699,99.9337017 L17.3902913,102.029834 C17.7320388,103.190055 23.5417476,102.524862 30.376699,100.544751 C37.2116505,98.5646409 42.4854369,96.0276243 42.1436893,94.8674033 L41.5300971,92.7712707 L16.776699,99.9337017 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(29.159653, 96.352060) rotate(-16.200000) translate(-29.159653, -96.352060) " cx="29.1596531" cy="96.3520603" rx="12.8812813" ry="2.18196979"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(39.816411, 82.558023) rotate(-71.750000) translate(-39.816411, -82.558023) " cx="39.8164111" cy="82.5580229" rx="9.12306803" ry="10.6908055"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(41.135026, 81.348665) rotate(-71.750000) translate(-41.135026, -81.348665) " cx="41.1350256" cy="81.348665" rx="9.12306803" ry="10.6908055"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="35.5805825" cy="123.60221" rx="9.21941748" ry="3.51933702"></ellipse>
          <path d="M11.5805825,91.7657459 L11.5805825,93.7071823 C11.5805825,94.7823204 16.7300971,95.6563536 23.0757282,95.6563536 C29.4213592,95.6563536 34.5631068,94.7823204 34.5631068,93.7071823 L34.5631068,91.7657459 L11.5805825,91.7657459 Z" id="Path" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero"></path>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="23.0757282" cy="91.7657459" rx="11.4873786" ry="1.94917127"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="30.9048544" cy="120.407735" rx="7.28543689" ry="3.51933702"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="23.6116505" cy="126.394475" rx="9.21941748" ry="3.51933702"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" cx="30.9048544" cy="117.924862" rx="7.28543689" ry="3.51933702"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.976514803" fill="#FFFFFF" fillRule="nonzero" transform="translate(12.251107, 103.489083) rotate(-31.456823) translate(-12.251107, -103.489083) " cx="12.2511068" cy="103.489083" rx="5.16408077" ry="7.02159635"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(12.957596, 110.507649) rotate(-17.090000) translate(-12.957596, -110.507649) " cx="12.957596" cy="110.507649" rx="5.58248204" ry="10.7475091"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(17.810875, 80.521448) rotate(-78.530000) translate(-17.810875, -80.521448) " cx="17.8108749" cy="80.5214477" rx="11.3643021" ry="7.4861576"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(13.852006, 113.785554) rotate(-24.520000) translate(-13.852006, -113.785554) " cx="13.852006" cy="113.785554" rx="8.93344369" ry="11.3009195"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(14.655524, 81.511097) rotate(-78.530000) translate(-14.655524, -81.511097) " cx="14.6555241" cy="81.5110966" rx="11.3643021" ry="7.4861576"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(17.802713, 119.602701) rotate(-27.090000) translate(-17.802713, -119.602701) " cx="17.802713" cy="119.602701" rx="5.57969732" ry="10.7529211"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(16.357089, 120.412908) rotate(-27.090000) translate(-16.357089, -120.412908) " cx="16.3570885" cy="120.412908" rx="5.57969732" ry="10.7529211"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(40.227106, 105.603314) rotate(-61.850000) translate(-40.227106, -105.603314) " cx="40.227106" cy="105.603314" rx="7.3703667" ry="7.38736646"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(29.516496, 110.643753) rotate(-11.120000) translate(-29.516496, -110.643753) " cx="29.5164962" cy="110.643753" rx="7.2299567" ry="8.63338209"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(43.463563, 117.073348) rotate(-39.030000) translate(-43.463563, -117.073348) " cx="43.4635634" cy="117.073348" rx="9.96419265" ry="6.91086204"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(53.188836, 102.993251) rotate(-44.690000) translate(-53.188836, -102.993251) " cx="53.1888358" cy="102.993251" rx="8.07663195" ry="7.24694707"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(52.363272, 115.648565) rotate(-45.260000) translate(-52.363272, -115.648565) " cx="52.3632717" cy="115.648565" rx="12.5407361" ry="10.3244248"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(53.595236, 117.076232) rotate(-45.260000) translate(-53.595236, -117.076232) " cx="53.5952356" cy="117.076232" rx="12.5407361" ry="10.3244248"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(56.875039, 84.642302) rotate(-42.870000) translate(-56.875039, -84.642302) " cx="56.8750389" cy="84.642302" rx="12.0080287" ry="13.9262392"></ellipse>
          <ellipse id="Oval" stroke="#F7505E" strokeWidth="0.98" fill="#FFFFFF" fillRule="nonzero" transform="translate(59.097935, 83.466788) rotate(-42.870000) translate(-59.097935, -83.466788) " cx="59.0979353" cy="83.4667884" rx="12.0080287" ry="13.9262392"></ellipse>
        </g>
      </g>
    </g>
  </svg>;

export default BudgetJarMore