import React, {useEffect, useState} from 'react'

import Field from '.././helpers/Field'
import FormattedMessage from '.././helpers/FormattedMessage'
import HeaderContainerAP from '../../containers/header-container-ap'
import FooterContainerAp from '../../containers/footer-container-ap'
import CheckboxOther from "../helpers/CheckboxOther";
import {reduxForm} from "redux-form";
import ValidationService from "../../services/validation-service";
import {injectIntl} from "react-intl";
import {displayLoadingIcon} from "../../actions/loading-icon-action";
import {createPassword } from "../../actions/login-actions";
import {getEmployer} from "../../actions/employer-actions";
import {useDispatch, useSelector} from "react-redux";
import {createEnrollment} from "../../actions/enrollment-actions";


const renderField = ({input, autocomplete, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type} autocomplete={autocomplete}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

function BFlowNewPasswordForm(props) {

    const [isProcessing, setIsProcessing] = useState(false);
    const [showPassword, setShowPassword] = useState('password');

    const login = useSelector(state => state.login);
    const registration = useSelector(state => state.registration);
    const dispatch = useDispatch();

    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid ||
            isProcessing;
    }

    useEffect(() => {
        if(registration === null && login === null){
            props.history.push(`/welcome`)
        } else if (login != null && login !== '') {
            if (login.nextPage === 'TEMPPASSWORD') {
            props.history.push('/enter-one-time-code')
            } else if (login.nextPage === 'PASSWORD') {
                props.history.push('/enter-your-password')
            } else if (login.nextPage === null){
                props.history.push('/action-plan')
            }
        }
    }, [])

    const {intl} = props;

    return (
        <div className='b-flow b-flow-background'>
          <HeaderContainerAP/>
          <div className='b-flow-body'>

            <div className='container-ap create-pass-form'>
              <div className='page-title-div'>
                <h1 className='page-title'>
                    <FormattedMessage id='app.b-flow.new-password-form.title'/>
                </h1>
              </div>
                <p className='small-text ul-intro-text'>
                    <FormattedMessage id='app.b-flow.new-password-form.instruction'/>
                </p>
              <ul className='small-text'>
                    <li><FormattedMessage id='app.b-flow.new-password-form.rules-1'/></li>
                    <li><FormattedMessage id='app.b-flow.new-password-form.rules-2'/></li>
                    <li><FormattedMessage id='app.b-flow.new-password-form.rules-3'/></li>
                    <li><FormattedMessage id='app.b-flow.new-password-form.rules-4'/></li>
                    <li><FormattedMessage id='app.b-flow.new-password-form.rules-5'/></li>
              </ul>
                <form onSubmit={props.handleSubmit}>
                    <div className='form-group margin-bottom-32-px'>
                      <Field name='newpassword' type={showPassword} id='newpassword'
                             autocomplete='newpassword' component={renderField} placeholder={intl.formatMessage({ id: 'app.b-flow.new-password-form.placeholder.password'})} />
                    </div>

                    <div className='form-group margin-bottom-32-px'>
                      <Field name='confirmpassword' type={showPassword} id='confirmpassword'
                             autocomplete='confirmpassword' component={renderField} placeholder={intl.formatMessage({ id: 'app.b-flow.new-password-form.placeholder.re-enter-password'})} />
                    </div>

                    <div className='form-group margin-bottom-32-px checkbox-margin-container'>

                      <CheckboxOther name='show-password' type='checkbox' id='show-password' component={renderField} i18nPrefix='app.b-flow.new-password-form.' onChange={(e)=>{e.target.checked ? setShowPassword('text') : setShowPassword('password')}} />
                    </div>
                    <div className='submit-button-round margin-bottom-24-px'>
                        <button id='submit'
                                type={'submit'}
                                className='btn btn-red'
                                disabled={isSubmitDisabled(props, isProcessing)}>
                            <FormattedMessage id='app.b-flow.new-password-form.continue.button'/>
                        </button>
                    </div>
                </form>
            </div>
            <FooterContainerAp/>
          </div>
        </div>)

}

function goToNextPage(props) {
    props.history.push(`/enter-one-time-code`);
}

export default reduxForm({
    form: 'BFlowNewPasswordForm',
    validate: ValidationService.createPassword,
    onSubmit: (values, dispatch, props) => {
        dispatch(displayLoadingIcon(true))
        return dispatch(createPassword(values)).then(() => {
            dispatch(createEnrollment())
            if ( props.employer && (props.employer.code === 'err')){
                return dispatch(getEmployer('noCode')).then(()=>{
                    dispatch(displayLoadingIcon(false))
                    goToNextPage(props)
                })
            } else {
                dispatch(displayLoadingIcon(false))
                goToNextPage(props)
            }
        })
    },
    destroyOnUnmount: false
})(injectIntl(BFlowNewPasswordForm))
