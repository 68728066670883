import axios from 'axios'
import {SubmissionError} from 'redux-form'
import {DISPLAY_LOADING_ICON} from "./loading-icon-action";

export const CREATE_INCOME_BUDGET = 'CREATE_INCOME_BUDGET';
export const CREATE_EXPENSE_BUDGET = 'CREATE_EXPENSE_BUDGET';
export const GET_INCOME_BUDGET = 'GET_INCOME_BUDGET';
export const GET_EXPENSE_BUDGET = 'GET_EXPENSE_BUDGET';

export const INCOME_BUDGET_FORM_PRISTINE = 'INCOME_BUDGET_FORM_PRISTINE'
export const EXPENSE_BUDGET_FORM_PRISTINE = 'EXPENSE_BUDGET_FORM_PRISTINE'

export function incomeBudgetFormPristine(truthy) {
  let displayValue = truthy === true
  return {
    type: INCOME_BUDGET_FORM_PRISTINE,
    incomeBudgetFormPristine: displayValue
  }
}
export function expenseBudgetFormPristine(truthy) {
  let displayValue = truthy === true
  return {
    type: EXPENSE_BUDGET_FORM_PRISTINE,
    expenseBudgetFormPristine: displayValue
  }
}

export const createBudgetIncome = data => {
    return (dispatch, getState) => {
      const budget = Object.assign({}, data)

      budget.clientId = getState().registration.id

      return axios
        .post('/api/create-budget-income', budget)
        .then((response) => {
          dispatch({
            type: CREATE_INCOME_BUDGET,
            budgetIncome: response.data
          })
        })
        .catch(error => {
          throw new SubmissionError({_error: error.response.data.message})
        })
    }
}

export const createBudgetExpense = data => {
    return (dispatch, getState) => {
      const budget = Object.assign({}, data)

      budget.clientId = getState().registration.id

      return axios
          .post('/api/create-budget-expense', budget)
          .then((response) => {
          dispatch({
              type: CREATE_EXPENSE_BUDGET,
              budgetExpense: response.data
          })
      })
    .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
    }
}

export function getBudgetIncome() {
    return (dispatch, getState) => {
        let id = getState().registration.id

        return axios
            .get('/api/get-budget-income/{id}', {params: {id}})
            .then(response => {
            dispatch({
                type: GET_INCOME_BUDGET,
                budgetIncome: response.data
            })
        })
    .catch(() => {})
    }
}

export function getBudgetExpense() {
    return (dispatch, getState) => {
        let id = getState().registration.id

        return axios
            .get('/api/get-budget-expense/{id}', {params: {id}})
            .then(response => {
            dispatch({
                 type: GET_EXPENSE_BUDGET,
                 budgetExpense: response.data
            })
        })
    .catch(() => {})
    }
}