import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import {toggleLocale} from '../actions/locale-actions'
import Header_AP from '../components/header-ap'

function mapStateToProps(state) {
    return {
        locale: state.locale,
        formSyncErrors: getFormSyncErrors('header_ap')(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLocaleToggle(currentLocale) {
            dispatch(toggleLocale(currentLocale))
        }
    }
}

const HeaderContainerAP = connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
        form: 'Header_AP',
        destroyOnUnmount: false
    })(injectIntl(Header_AP))
)

export default HeaderContainerAP