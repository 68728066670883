import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import FooterCalendly from '../components/footer-calendly'

function mapStateToProps(state) {
  return {
    employer: state.employer,
    locale: state.locale
  }
}

const FooterCalendlyContainer = connect(
  mapStateToProps,
  () => ({})
)(injectIntl(FooterCalendly))

export default FooterCalendlyContainer
