import React from 'react'
import Icon from '../../../resources/icons'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

export default class DebtSummaryTradeAccountRow extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.placeBlue) {
      if (this.props.currentAccountDetailId === this.props.accountDetail.id) {
        this.props.placeBlue(this.tradeAccountNameWrapper.getBoundingClientRect())
      }
    }
  }

  onTradeAccountRowClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.props.setAccountDetail(this.props.accountDetail);
    this.props.placeBlue(this.tradeAccountNameWrapper.getBoundingClientRect())
  }

  render() {

    let accountStatusType = this.props.accountDetail.accountStatusType == 'Paid' || this.props.accountDetail.accountStatusType == 'Closed' ?
        'app.action-plan-debt-summary.' + this.props.accountDetail.accountStatusType : formatter.format(this.props.accountDetail.balance);

    return (
        this.props.accountDetail && <tr className={`trade-accounts ${this.props.hidden == true ? 'hidden' : ''}`} >
          <td className='empty-column'></td>
          <td className='trade-account-name-wrapper'
              ref={ (tradeAccountNameWrapper) => this.tradeAccountNameWrapper = tradeAccountNameWrapper}
          >
          { this.props.accountDetail.triangle ?
            <div className='exclamation-triangle'>
              <div className='exclamation-triangle-wrapper'>
                <div className='icon-legend'>
                  <Icon name='exclamation-triangle' fill="#ba0c2f" />
                </div>
              </div>
            </div> : ''
          }
              <div className='trade-account-name' >
                {this.props.accountDetail.name}
              </div>
          </td>
            <td className='balance'>
                { this.props.accountDetail.balance === 0 ?
                    <div><FormattedMessage id={accountStatusType}/></div> :
                    <div>{formatter.format(this.props.accountDetail.balance)}</div>
                }
            </td>
          <td className='monthly-payment'>{formatter.format(this.props.accountDetail.monthlyPayment)}</td>
          <td className='chevron-right' >
            <a href='#' onClick={ e => this.onTradeAccountRowClick(e) }>
            <div className='icon-wrapper-legend-chevron'>
              <div className='icon-legend debt-summary-chevron'>
                <FontAwesomeIcon icon={['fas', 'chevron-circle-right']} />
              </div>
              <div className='chevron-detail-link'>
                <FormattedMessage id='app.action-plan-debt-summary.detail.link'/>
              </div>
            </div>
            </a>
        </td>
      </tr>
      )
  }
}
