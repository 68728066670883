import React, {useEffect, useState} from 'react'

import Field from './helpers/Field'
import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'
import FooterContainerAp from '../containers/footer-container-ap'
import CheckboxOther from "./helpers/CheckboxOther";
import {useLocation} from "react-router-dom";


const renderField = ({input, autocomplete, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type} autocomplete={autocomplete}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

export default function PostgresResetPasswordForm(props) {

    const [isProcessing, setIsProcessing] = useState(false);
    const [showPassword, setShowPassword] = useState('password');

    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid ||
            isProcessing;
    }

    useEffect(() => {
        if (props.login === null) {
            props.history.push(`/enter-your-email-address`);
        }

        if (props.registration === null || props.registration.id !== contactId) {
            props.getContact(contactId)
        }
    })

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const contactId = urlSearchParams.get('cid');

    const {intl} = props;

    return (
        <div className='body'>
            <HeaderContainerAP/>
            <div className='b-flow-body'>

                <div className='container-ap create-pass-form'>
                    <div className='page-title-div'>
                        <h1 className='page-title'>
                            <FormattedMessage id='app.reset-new-password-form.title'/>
                        </h1>
                    </div>
                    <div className='text-ap margin-bottom-16-px'>
                        <FormattedMessage id='app.b-flow.new-password-form.instruction'/>
                        <li><FormattedMessage id='app.b-flow.new-password-form.rules-1'/></li>
                        <li><FormattedMessage id='app.b-flow.new-password-form.rules-2'/></li>
                        <li><FormattedMessage id='app.b-flow.new-password-form.rules-3'/></li>
                        <li><FormattedMessage id='app.b-flow.new-password-form.rules-4'/></li>
                        <li><FormattedMessage id='app.b-flow.new-password-form.rules-5'/></li>
                    </div>
                    <form onSubmit={props.handleSubmit}>
                        <div className='form-group margin-bottom-32-px'>
                            <Field name='newpassword' type={showPassword} id='newpassword'
                                   autocomplete='newpassword' component={renderField} placeholder={intl.formatMessage({ id: 'app.b-flow.new-password-form.placeholder.password'})} />
                        </div>

                        <div className='form-group margin-bottom-32-px'>
                            <Field name='confirmpassword' type={showPassword} id='confirmpassword'
                                   autocomplete='confirmpassword' component={renderField} placeholder={intl.formatMessage({ id: 'app.b-flow.new-password-form.placeholder.re-enter-password'})} />
                        </div>

                        <div className='form-group margin-bottom-32-px checkbox-margin-container'>

                            <CheckboxOther name='show-password' type='checkbox' id='show-password' component={renderField} i18nPrefix='app.b-flow.new-password-form.' onChange={(e)=>{e.target.checked ? setShowPassword('text') : setShowPassword('password')}} />
                        </div>
                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-primary btn-block btn-lg'
                                    disabled={isSubmitDisabled(props, isProcessing)}>
                                <FormattedMessage id='app.b-flow.new-password-form.continue.button'/>
                            </button>
                        </div>
                    </form>
                </div>
                <FooterContainerAp/>
            </div>
        </div>)

}