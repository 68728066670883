import {ENGLISH_COUNSELORS_RETURNED} from '../actions/counselor-actions'

function englishCounselorsReturned(state = false, action) {
  switch (action.type) {
    case ENGLISH_COUNSELORS_RETURNED:
      return action.englishCounselorsReturned
    default:
      return state
  }
}

export default englishCounselorsReturned