import {SPANISH_CURRENTLY_WORKING_COUNSELORS} from '../actions/counselor-actions'

const spanishCurrentlyWorkingCounselors = (state = null, action) => {
    switch (action.type) {
        case SPANISH_CURRENTLY_WORKING_COUNSELORS:
            return action.spanishCurrentlyWorkingCounselors
        default:
            return state
    }
}

export default spanishCurrentlyWorkingCounselors