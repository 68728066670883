import React from "react";

export const ReplyArrow = ({
                               style = {},
                               fill = '#000000',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 20 18',
                           }) =>
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        className={className}
        xmlns="https://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
    >
        <path
            d="m.333 4.247 4.774-3.8c.188-.16.45-.2.676-.105a.66.66 0 0 1 .4.56v2.001c4.358.047 7.814.85 7.814 4.657a5.02 5.02 0 0 1-2.26 3.854c-.375.248-.899-.063-.762-.466 1.23-3.627-.583-4.59-4.791-4.645v2.199a.66.66 0 0 1-.4.559.651.651 0 0 1-.677-.105L.333 5.155a.568.568 0 0 1 0-.908z"
            fill="#0032a0"/>
    </svg>;

export default ReplyArrow;

