import {GET_EMPLOYER} from '../actions/employer-actions'
import {FETCH_FILES, DELETE_FILE} from "../actions/customer-actions";

const employer = (state = {code:'0000', name:'No Employer'}, action) => {
  switch (action.type) {
    case GET_EMPLOYER:
      return action.employer
    case FETCH_FILES:
      return action.employer
    case DELETE_FILE:
      return action.employer
    default:
      return state
  }
}

export default employer
