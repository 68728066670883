import {connect} from 'react-redux'
import {setActiveCounselor} from "../actions/counselor-actions";
import AdminPickACoach from "../components/admin-pick-a-coach";

function mapStateToProps(state) {
  return {
    client: state.client,
    employer: state.employer,
    locale: state.locale,
    activeCounselor: state.activeCounselor,
    activeCounselors: state.activeCounselors,
    activeCurrentlyWorkingCounselors: state.activeCurrentlyWorkingCounselors
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveCounselor(counselor) {
      return dispatch(setActiveCounselor(counselor))
    },
  }
}

const AdminPickACoachContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPickACoach)

export default AdminPickACoachContainer
