import React from 'react'

import FormattedMessage from './helpers/FormattedMessage'
import HeaderContainerAP from '../containers/header-container-ap'

export default function LearnMore({match: {params: {employerCode}}}) {

  return (
    <div id='learn-more'>

        <HeaderContainerAP/>

      <div className='container ptxxl'>

          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.learn-more.title'/>
            </div>
          </div>

        <div className='detail'>

          <h4><FormattedMessage id='app.learn-more.question.1'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.1a'/></p>
          <p><FormattedMessage id='app.learn-more.answer.1b' values={{
              neighborhoodtrusthome: (
                  <a target='_blank' href='http://neighborhoodtrust.org'>
                      <FormattedMessage id='app.learn-more.neighborhoodtrusthome'/>
                  </a>
              )}}/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.2'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.2'/></p>
          <ul>
            <li><FormattedMessage id='app.learn-more.answer.2a'/></li>
            <li><FormattedMessage id='app.learn-more.answer.2b'/></li>
            <li><FormattedMessage id='app.learn-more.answer.2c'/></li>
          </ul><br />

          <h4><FormattedMessage id='app.learn-more.question.3'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.3'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.4'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.4'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.5'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.5'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.6'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.6'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.7'/></h4><br/>
          <p><b> <FormattedMessage id='app.learn-more.question.7.1-heading'/></b></p>
          <p><FormattedMessage id='app.learn-more.answer.7.1'/></p><br />
          <p><b> <FormattedMessage id='app.learn-more.question.7.2-heading'/></b></p>
          <p><FormattedMessage id='app.learn-more.answer.7.2'/></p><br/>
          <p><b> <FormattedMessage id='app.learn-more.question.7.3-heading'/></b></p>
          <p><FormattedMessage id='app.learn-more.answer.7.3'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.8'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.8'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.9'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.9'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.10'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.10'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.11'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.11'/></p><br/>

          <h4><FormattedMessage id='app.learn-more.question.12'/></h4><br/>
          <p>
            <ul>
              <li><FormattedMessage id='app.learn-more.answer.12a'/></li>
              <li><FormattedMessage id='app.learn-more.answer.12b'/></li>
            </ul>
          </p><br/>


          <h4><FormattedMessage id='app.learn-more.question.13'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.13' values={{
              supportEmail: (
                <a href='mailto:support@neighborhoodtrust.org'>
                  <FormattedMessage id='app.common.email.support'/>
                </a>
              )
          }}/>&nbsp;</p><br/>


          <h4><FormattedMessage id='app.learn-more.question.14'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.14a'/></p>
          <p><FormattedMessage id='app.learn-more.answer.14b'/></p>
          <br/>

          <h4><FormattedMessage id='app.learn-more.question.15'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.15a'/></p>
          <p><FormattedMessage id='app.learn-more.answer.15b'/></p>
          <p><FormattedMessage id='app.learn-more.answer.15c'/></p>
          <p><FormattedMessage id='app.learn-more.answer.15d'/></p>
          <p><FormattedMessage id='app.learn-more.answer.15e'/></p>
          <p><FormattedMessage id='app.learn-more.answer.15f'/></p>
          <br/>

          <h4><FormattedMessage id='app.learn-more.question.16'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.16a'/></p>
          <p><FormattedMessage id='app.learn-more.answer.16b'/></p>
          <p><FormattedMessage id='app.learn-more.answer.16c'/></p>
          <p><FormattedMessage id='app.learn-more.answer.16d'/></p>
          <p><FormattedMessage id='app.learn-more.answer.16e'/></p>
          <p><FormattedMessage id='app.learn-more.answer.16f'/></p>
          <p><FormattedMessage id='app.learn-more.answer.16g'/></p>
          <br/>

          <h4><FormattedMessage id='app.learn-more.question.17'/></h4><br/>
          <p><FormattedMessage id='app.learn-more.answer.17'/></p>
          <br/>

          <p>&nbsp;</p>

        </div>

      </div>
    </div>
  )
}
