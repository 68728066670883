import {CHANGE_MSG_FILE} from '../actions/locale-actions'

export default function msgFile(state = 'en-US', action) {
  switch (action.type) {
    case CHANGE_MSG_FILE:
      return action.msgFile
    default:
      return state
  }
}
