import {connect} from 'react-redux'
import Introduction from '../components/introduction'
import {injectIntl} from 'react-intl'
import {getEmployer} from "../actions/employer-actions";
import {toggleLocale} from "../actions/locale-actions";


function mapStateToProps(state) {
  return {
    locale: state.locale,
    employer: state.employer,
    activeCounselors: state.activeCounselors,
    activeEmployers: state.activeEmployers,
    dcl: state.dcl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle(currentLocale) {
      dispatch(toggleLocale(currentLocale))
    },
    updateNoCodeEmployer(code){
      return dispatch(getEmployer(code))
    }
  }
}

const IntroductionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Introduction))

export default IntroductionContainer
