import {CREATE_EXPENSE_BUDGET, GET_EXPENSE_BUDGET} from '../actions/budget-actions'

const budgetExpense = (state = null, action) => {
  switch (action.type) {
    case CREATE_EXPENSE_BUDGET:
      return action.budgetExpense
    case GET_EXPENSE_BUDGET:
      return action.budgetExpense
    default:
      return state
  }
}

export default budgetExpense