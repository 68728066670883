import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icon from '../../../resources/icons'
import FormattedMessage from '../helpers/FormattedMessage'

export default function ActionPlanNavItem(props){

  function onNavItemClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    props.navItemClick(e);
  }

      return (
        <div className={`nav-page-box ${props.componentActive ? 'nav-page-box-active' : ''}`}>
          <a href='#' onClick={ e => onNavItemClick(e) }>
            <div className='nav-page-white-box-container'>
              <div className='nav-page-logo-wrapper'>
                <div className='nav-page-logo'>
                  <Icon fill="#000000" name={props.icon}/>
                    <FontAwesomeIcon icon={props.iconfontawesome} />
                </div>
              </div>
              <div className='nav-page-name-wrapper'>
                <div className='nav-page-name'>
                  <FormattedMessage id={props.title}/>
                </div>
              </div>
              {
                props.componentActive ?
                  (
                    <div className='nav-page-triangle-wrapper'>
                      <div className='nav-page-box-active-triangle-border'>
                        <div className='nav-page-box-active-triangle'></div>
                      </div>
                    </div>
                  ) : (<div />)
              }
            </div>
          </a>
        </div>)
}
