import React, {useEffect, useState} from 'react'

import FormattedMessage from '../../components/helpers/FormattedMessage'
import Field from '../../components/helpers/Field'
import HeaderContainerAP from '../../containers/header-container-ap'
import CheckboxOther from "../helpers/CheckboxOther";
import FormUserError from "../form-user-error";
import FormError from "../form-error";
import {Link} from "react-router-dom";
import {reduxForm} from "redux-form";
import ValidationService from "../../services/validation-service";
import {injectIntl} from "react-intl";
import {bFlowAuthenticate} from "../../actions/b-flow-actions";
import {useSelector} from "react-redux";


const renderField = ({input, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
    <input {...input}  placeholder={placeholder} type={type}
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>
    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
    </div>
)

let login = null;
let registration = null;

function BFlowLogin(props){

  const [showPassword, setShowPassword] = useState('password');
  const [isProcessing, setIsProcessing] = useState(false)
  const [fatalFormError, setFatalFormError] = useState(null)

  login = useSelector((state) => state.login)
  registration = useSelector((state) => state.registration)

  useEffect(() => {
    if (props.submitSucceeded) {
      if (registration.id === null) {
        goToNextPage(props)
      } else {
        goToActionPlanPage(props)
      }
    }
  }, [props.submitSucceeded]);

    useEffect(() => {
        if (login != null && login !== '') {
          if (login.status === 'Active' && login.token != null) {
            props.history.push('/select-a-financial-coach')
          } else if (login.nextPage === 'TEMPPASSWORD') {
            props.history.push('/enter-one-time-code')
          } else if (login.nextPage === 'CREATEPASSWORD') {
            props.history.push('/make-a-password')
          }
        }
        else if (login === null){
            props.history.push('/enter-your-email-address')
        }

        if (props.error && props.error === 'app.message.login.unsuccessful') {
              let err = props.error
              setFatalFormError(err);
              setIsProcessing(false);
          }
    })

    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid ||
            isProcessing
    }

    const {intl, formSyncErrors} = props;
    
    return (
    <div className='b-flow b-flow-background'>
        <HeaderContainerAP/>
          <div className='b-flow-body'>

          <div className='container-ap welcome-form'>
              {console.log(props)}
            <h1>
              <FormattedMessage id='app.b-flow.login-password-form.title'/>
              <p><FormattedMessage id='app.b-flow.login-password-form.instruction'/></p>
            </h1>
            <form onSubmit={props.handleSubmit}>
                <div className='form-group margin-bottom-32-px'>
                  <Field name='password' autocomplete='password' type={showPassword} id='password' component={renderField} placeholder={intl.formatMessage({ id: 'app.b-flow.login-password-form.placeholder.password'})} />
                  <CheckboxOther name='show-password' type='checkbox' id='show-password' component={renderField} i18nPrefix='app.b-flow.login-password-form.' onChange={(e)=>{e.target.checked ? setShowPassword('text') : setShowPassword('password')}} />
                </div>

                <div className='margin-bottom-32-px'>
                    <FormUserError validationErrors={formSyncErrors} intl={intl}/>
                    <FormError error={fatalFormError} />
                </div>

                <div className='submit-button-round margin-bottom-24-px'>
                    <button id='submit'
                            type={'submit'}
                            className='btn btn-red'
                            disabled={isSubmitDisabled(props)}>
                        <FormattedMessage id='app.b-flow.new-password-form.continue.button'/>
                    </button>
                </div>

                <Link to={`forgot-your-password`} id='forgot'>
                    <FormattedMessage id='app.b-flow.login-password-form.alt-link'/>
                </Link>
            </form>
          </div>
        </div>
    </div>)
}

function goToNextPage(props) {
    props.history.push('/select-a-financial-coach')
}

function goToActionPlanPage(props) {
    props.history.push('/action-plan')
}

export default reduxForm({
    form: 'LoginForm',
    validate: ValidationService.login,
    onSubmit: (values, dispatch, props) => {
        return dispatch(bFlowAuthenticate(values))
    },
    destroyOnUnmount: false
})(injectIntl(BFlowLogin))