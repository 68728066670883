import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'
import {reset} from 'redux-form';
import {createTakeActionToday} from '../actions/take-action-today-actions'
import TakeActionTodayCreateForm from '../components/take-action-today-create-form'
import ValidationService from '../services/validation-service'
import {getPendingTakeActionToday} from '../actions/take-action-today-actions'

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

function goToOverview(props) {
  props.history.push('/action-plan/take-action-today')
}


const TakeActionTodayCreateFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({
    form: 'take-action-today-create',
    validate: ValidationService.validateTakeActionToday,
    onSubmit: (values, dispatch, props) => {
      return dispatch(createTakeActionToday(values)).then(() => {
        goToOverview(props)
        dispatch(reset('take-action-today-create'));
        dispatch(getPendingTakeActionToday(props))
      })
    },
    destroyOnUnmount: false
  })(injectIntl(TakeActionTodayCreateForm))
)

export default TakeActionTodayCreateFormContainer