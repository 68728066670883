import {connect} from 'react-redux'
import {injectIntl} from "react-intl";
import {displayLoadingIcon} from "../../actions/loading-icon-action";
import {alertName, displayAlert} from "../../actions/alert-actions";
import {toggleLocale} from "../../actions/locale-actions";
import EmergencyResponsePlaceholder from "../../components/emergency-response/emergency-response-placeholder";
import NtfcuSupportPlaceholder from "../../components/ntfcu-support/ntfcu-support-placeholder";

function mapStateToProps(state) {
  return {
    locale: state.locale,
    displayIcon: state.displayLoadingIcon,
    employer: state.employer
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle(currentLocale) {
      dispatch(toggleLocale(currentLocale))
    },
  }
}

const NtfcuSupportPlaceholderContainer = connect(
  mapStateToProps,
    mapDispatchToProps
)(injectIntl(NtfcuSupportPlaceholder))

export default NtfcuSupportPlaceholderContainer
