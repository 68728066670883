import React from 'react'

export const OverwhelmedEmoji = ({
                           style = {},
                           fill = '#000000',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 460 460',
                         }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-2" transform="translate(27.000000, 50.000000)">
        <g id="Group" transform="translate(93.495858, 64.603726)">
          <circle id="Oval" stroke="#AEE0E8" stroke-width="16" fill="#FFFFFF" cx="109" cy="109" r="101"></circle>
          <circle id="Oval" fill="#AEE0E8" cx="80" cy="66" r="16"></circle>
          <circle id="Oval" fill="#AEE0E8" cx="138" cy="78" r="16"></circle>
        </g>
        <path d="M133.995858,190.603726 C152.495858,208.197476 161.745858,216.994351 161.745858,216.994351 C161.745858,216.994351 202.495858,192.597634 202.495858,192.597634 C202.495858,192.597634 231.495858,204.603726 231.495858,204.603726 C254.36565,195.270392 265.800545,190.603726 265.800545,190.603726" id="Line-2" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square"></path>
        <polygon id="Star" stroke="#AEE0E8" stroke-width="16" transform="translate(133.892132, 34.000000) rotate(16.000000) translate(-133.892132, -34.000000) " points="133.892132 47.5 118.02193 55.8434588 121.052869 38.1717294 108.213606 25.6565412 125.957031 23.0782706 133.892132 7 141.827233 23.0782706 159.570658 25.6565412 146.731395 38.1717294 149.762334 55.8434588"></polygon>
        <polygon id="Star" stroke="#AEE0E8" stroke-width="16" transform="translate(311.892132, 80.000000) rotate(16.000000) translate(-311.892132, -80.000000) " points="311.892132 93.5 296.02193 101.843459 299.052869 84.1717294 286.213606 71.6565412 303.957031 69.0782706 311.892132 53 319.827233 69.0782706 337.570658 71.6565412 324.731395 84.1717294 327.762334 101.843459"></polygon>
        <path d="M91.4817982,124.539545 C134.741009,115.873439 163.5,100.511478 163.5,83.0053215 C163.5,55.9433688 94.7757091,34.0053215 10,34.0053215" id="Oval" stroke="#AEE0E8" stroke-width="8" transform="translate(86.750000, 79.272433) scale(-1, -1) rotate(16.000000) translate(-86.750000, -79.272433) "></path>
        <path d="M343.481798,156.539545 C386.741009,147.873439 415.5,132.511478 415.5,115.005322 C415.5,87.9433688 346.775709,66.0053215 262,66.0053215" id="Oval" stroke="#AEE0E8" stroke-width="8" transform="translate(338.750000, 111.272433) scale(1, -1) rotate(16.000000) translate(-338.750000, -111.272433) "></path>
      </g>
    </g>

  </svg>;

export default OverwhelmedEmoji