import axios from 'axios'
import {SubmissionError} from 'redux-form'
import 'moment-timezone'

export const UPDATE_REGISTRATION = 'UPDATE_REGISTRATION'
export const CREATE_REGISTRATION = 'CREATE_REGISTRATION'
export const GET_REGISTRATION = 'GET_REGISTRATION'
export const CREDIT_REPORT_OPT_OUT = 'CREDIT_REPORT_OPT_OUT'
export const UPDATE_REGISTRATION_COMPLETED = 'UPDATE_REGISTRATION_COMPLETED'
export const UPDATE_CONTACT_ID = 'UPDATE_CONTACT_ID'
export const UPDATE_SM_LINK = 'UPDATE_SM_LINK'

const localeToPreferredLanguage = {
  'en-US': 'English',
  'es-US': 'Spanish'
}

export const update = data => {
  return (dispatch, getState) => {

    const contact = Object.assign({}, data)

    contact.id = getState().registration.firstName === "New Onboarding" ? getState().login.contactId : getState().registration.id;
    contact.email = getState().registration.firstName === "New Onboarding" ? getState().login.username: getState().registration.email;
    contact.mobilePhone = getState().registration.firstName === "New Onboarding" && getState().form["beta-appointment-registration"] && getState().form["beta-appointment-registration"].values ? getState().form["beta-appointment-registration"].values.mobilePhone : getState().registration.mobilePhone
    contact.firstName = getState().registration.firstName === "New Onboarding" && getState().form["beta-appointment-registration"] && getState().form["beta-appointment-registration"].values ? getState().form["beta-appointment-registration"].values.firstName : getState().registration.firstName
    contact.lastName = getState().registration.firstName === "New Onboarding" && getState().form["beta-appointment-registration"] && getState().form["beta-appointment-registration"].values ? getState().form["beta-appointment-registration"].values.lastName : getState().registration.lastName
    contact.creditReportAuthorizationReceived = 'Yes';
    contact.pullCredit = true;
    contact.clientSmLink = getState().registration.firstName === "New Onboarding" ? getState().login.contactId : getState().registration.clientSmLink;

    return axios
      .post('/api/contact/updateContact', contact)
      .then((response) => {
        dispatch({
          type: UPDATE_REGISTRATION,
          registration: response.data
        })
        dispatch({
          type: UPDATE_REGISTRATION_COMPLETED,
          updateRegistrationCompleted: true
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export const createContact = data => {
  return (dispatch, getState) => {

    const registration = Object.assign({}, data)

    registration.employerCode = getState().employer ? getState().employer.code : '0000';
    registration.employerId = getState().employer ? getState().employer.id : registration.employerId;

    registration.preferredLanguage = localeToPreferredLanguage[getState().locale];

    return axios
      .post('/api/contact/createContact', {
        contactId: registration.id,
        firstName: registration.firstName,
        lastName: registration.lastName,
        mobilePhone: registration.mobilePhone,
        email: registration.email,
        preferredLanguage: registration.preferredLanguage,
        employerId: registration.employerId,
        employerCode: registration.employerCode
        }
      ).then((response) => {
        dispatch({
          type: CREATE_REGISTRATION,
          registration: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export const creditReportOptOut = data => {
  return (dispatch, getState) => {

    const contact = Object.assign({}, data)

    contact.creditReportOptOut = true

    return axios
      .post('/api/contact/creditReportOptOut', contact)
      .then((response) => {
        dispatch({
          type: CREDIT_REPORT_OPT_OUT,
          registration: response.data
        })
      })
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export function getContact(id) {
    return (dispatch) => {
        return axios
            .get('/api/getContact', {params: {id}})
            .then(response => {
                dispatch({
                    type: GET_REGISTRATION,
                    registration: response.data
                })
            })
            .catch(() => {})
    }
}

export function reGetContact() {
  return (dispatch, getState) => {
    let id = getState().registration.id
    return axios
      .get('/api/getContact', {params: {id}})
      .then(response => {
        dispatch({
          type: GET_REGISTRATION,
          registration: response.data
        })
      })
      .catch(() => {})
  }
}

export function getContactWithEmail() {
    return (dispatch, getState) => {
        let email = getState().registration.email
        return axios
            .get('/api/getContactWithEmail', {params: {email}})
            .then(response => {
                dispatch({
                    type: UPDATE_CONTACT_ID,
                    registration: response.data
                })
            })
            .catch(() => {})
    }
}

export function getSmLink(id) {
    return (dispatch) => {
        return axios
            .get('/api/getContact', {params: {id}})
            .then(response => {
                dispatch({
                    type: UPDATE_SM_LINK,
                    registration: response.data
                })
            })
            .catch(() => {})
    }
}