import React , { useState } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormattedMessage from "../helpers/FormattedMessage";
import TradeDebtTable from "./trade-debt-table";

export default function DisputeStep3(props) {
  const disputeArray = props.cat.tradeDebtAccounts.filter(account => account.flaggedInOnboarding === "Yes" &&
    account.disputeLetterReason === "Collection: Not Recognized")

  return (
    <div>
      <div className='recommendations-gradient-color'>
        <div className='top-priority'>
          <FormattedMessage id='app.debt-report-recommendations.top-priority'/>
        </div>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.collections'/>
        </h2>
      </div>

      <div className='recommendations-page-wrapper'>
        <div className='work-on-this-page-container dispute-steps'>
          <div className='action-plan-subtitle margin-bottom-24-px'>
            <FormattedMessage id='app.debt-report-collections.dispute-step-1-title'/>
          </div>
          <div className='collections-dispute-steps-wrapper'>
            <h2 className='collections-dispute-instructions-step-heading'>
              <FormattedMessage id='app.debt-report-collections.dispute-step-3-subtitle'/>
            </h2>

            <p>
              <FormattedMessage id='app.debt-report-collections.dispute-step-3-text-1'/>
            </p>
            {
              disputeArray.map((account) => (
                <TradeDebtTable account={account}
                                key={account.id} />
              ))
            }

            <p>
              <FormattedMessage id='app.debt-report-collections.dispute-step-3-text-2'/>
            </p>
            <p>
              <FormattedMessage id='app.debt-report-collections.dispute-step-3-text-3'/>
            </p>
            <div className='collections-dispute-note-notice-wrapper one-line'>
              <div className='collections-dispute-note-notice-img'>
                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} aria-label='An exclamation point in an orange circle' />
              </div>
              <p>
                <FormattedMessage id='app.debt-report-collections.dispute-step-3-text-4'/>
              </p>
            </div>
            <div className='dispute-buttons-wrapper'>
              <div className='dispute-button-previous'>
                <button className='btn btn-primary btn-block btn-work-on-this btn-previous'
                        id='debt-report-reviewed-collections-yes'
                        onClick={()=>props.setCollectionPage("disputeStep2")}>
                  <FormattedMessage id='app.debt-report-collections.dispute-previous-step-btn'/>
                </button>
              </div>
              <div className='dispute-button-next'>
                <button className='btn btn-primary btn-block btn-work-on-this'
                        id='debt-report-reviewed-collections-yes'
                        onClick={()=>props.setCollectionPage("disputeStep4")}>
                  <FormattedMessage id='app.debt-report-collections.dispute-next-step-btn'/>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}