import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'

import {getFormSyncErrors, reduxForm/*, getFormMeta*/} from 'redux-form'
import StandaloneCreditReportSubmittedPage from "../components/standalone-credit-report-submitted-page";
import StandaloneCreditReportThankYouPage from "../components/standalone-credit-report-thank-you-page";
import ValidationService from "../services/validation-service";
import {displayLoadingIcon} from "../actions/loading-icon-action";
import WelcomeForm from "../components/b-flow/b-flow-email-form";
import {checkContactRecord, checkLoginRecord, setTemporaryEmail} from "../actions/b-flow-actions";

function mapStateToProps(state) {
  return {
    employer: state.employer,
    registration: state.form.registration && state.form.registration.values,
    showAlert: state.displayAlert,
    nameOfAlert: state.alertName,
    attempt: state.debtReportAttempt,
    formSyncErrors: getFormSyncErrors('login')(state),
    login: state.login
  }
}

function goToNextPage(props) {
    props.history.push('/tell-us-your-name')
}

const StandaloneCreditReportSubmittedPageContainer = connect(
    mapStateToProps
)(reduxForm({
      form: 'WelcomeForm',
      validate: ValidationService.welcome,
      onSubmit: (values, dispatch, props) => {
        dispatch(displayLoadingIcon(true))
          return dispatch(checkContactRecord(props.values.username)).then(() => {
              dispatch(checkLoginRecord(props.values.username)).then(() => {
                  dispatch(setTemporaryEmail(props.values.username))
                  goToNextPage(props)
              })
          })
      },
      destroyOnUnmount: false
    // })(injectIntl(StandaloneCreditReportSubmittedPage))
    })(injectIntl(StandaloneCreditReportThankYouPage))
)

export default StandaloneCreditReportSubmittedPageContainer
