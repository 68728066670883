import React from 'react';
import {PieChart, Pie, Tooltip, Legend, Cell} from 'recharts';

const COLORS = ['#ffb3ab', '#00c7b1', '#ff9e1b', '#ef3e42', '#aee0e8', '#003057', '#b0008e', '#6f263d'];

let PieChartComponent;
PieChartComponent = ({data, onClick}) => {
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const catSum = () => {
    let sum = 0;

    data.forEach( v => {
      sum += v.value;
    })

    return sum;
  };

  function currencyFormatter(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  function onPieClick(segment) {
    onClick(segment.name)
  }

  return (
    <PieChart width={700} height={450}>
      <text x={210} y={180} textAnchor="middle">
        Total spending
      </text>
      <text x={210} y={230} textAnchor="middle">
        {USDollar.format(catSum())}
      </text>
      <Pie
        dataKey="value"
        data={data}
        cx={200}
        cy={200}
        innerRadius={150}
        outerRadius={200}
        fill="#8884d8"
        onClick={onPieClick}
      >
        {
          data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
        }
      </Pie>
      <Tooltip formatter={currencyFormatter} />
      <Legend layout="vertical" verticalAlign="center" align="right" iconType="circle"/>
    </PieChart>
  );
};

export default PieChartComponent;