import React from 'react'

export const SurprisedEmoji = ({
                               style = {},
                               fill = '#000000',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 460 460',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(120.500000, 114.600000)">
        <circle id="Oval" stroke="#AEE0E8" stroke-width="16" fill="#FFFFFF" cx="109" cy="109" r="101"></circle>
        <circle id="Oval" stroke="#AEE0E8" stroke-width="8" cx="64" cy="108" r="35"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="64" cy="108" r="16"></circle>
        <circle id="Oval" stroke="#AEE0E8" stroke-width="8" cx="154" cy="108" r="35"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="154" cy="108" r="16"></circle>
      </g>
      <path d="M209.5,284.6 C213.742424,283.266667 220.409091,282.6 229.5,282.6 C238.590909,282.6 245.257576,283.266667 249.5,284.6" id="Line" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square"></path>
      <path d="M149.00005,165.6 C154.455502,160.040104 164.455502,157.260156 179.00005,157.260156 C193.544598,157.260156 203.544598,160.040104 209.00005,165.6" id="Line" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square" transform="translate(179.000050, 161.430078) rotate(-20.000000) translate(-179.000050, -161.430078) "></path>
      <path d="M249,166.6 C254.455452,161.040104 264.455452,158.260156 279,158.260156 C293.544548,158.260156 303.544548,161.040104 309,166.6" id="Line" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square" transform="translate(279.000000, 162.430078) rotate(20.000000) translate(-279.000000, -162.430078) "></path>
    </g>
  </svg>;

export default SurprisedEmoji