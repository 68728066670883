import {connect} from 'react-redux'
import {injectIntl} from "react-intl";
import {displayLoadingIcon} from "../../actions/loading-icon-action";
import {alertName, displayAlert} from "../../actions/alert-actions";
import {toggleLocale} from "../../actions/locale-actions";
import EmergencyResponsePlaceholder from "../../components/emergency-response/emergency-response-placeholder";
import PartnerCrisisResponseInactive from "../../components/partner-crisis-response/partner-crisis-response-inactive";

function mapStateToProps(state) {
  return {
    locale: state.locale,
    displayIcon: state.displayLoadingIcon,
    employer: state.employer
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoadingIcon(truthy) {
      dispatch(displayLoadingIcon(truthy))
    },
    displayAlert(truthy) {
      return dispatch(displayAlert(truthy))
    },
    alertName(val) {
      return dispatch(alertName(val))
    },
    onLocaleToggle(currentLocale) {
      dispatch(toggleLocale(currentLocale))
    },
  }
}

const PartnerCrisisResponseInactiveContainer = connect(
  mapStateToProps,
    mapDispatchToProps
)(injectIntl(PartnerCrisisResponseInactive))

export default PartnerCrisisResponseInactiveContainer
