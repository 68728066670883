import axios from "axios";

export const sendNudge = (sfrecid, action) => {
    return () => {
        return axios
            .get('/api/nudge', {params: {sfrecid, action}})
            .then((response) => {
                console.log(response.data)
            })
    }
}