import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import {getEmployer} from "../actions/employer-actions";
import {toggleLocale, updateMsgFile} from "../actions/locale-actions";
import {Redirect, Route, Switch} from "react-router";
import IntroductionContainer from "../containers/introduction-container";
import LandingPropelContainer from "../containers/landing-propel-container";
import LandingTaxesContainer from "../containers/landing-taxes-container";
import LandingRentContainer from "../containers/landing-rent-container";
import LearnMore from "./learn-more";
import EmergencyResponsePlaceholderContainer
  from "../containers/emergency-response/emergency-response-placeholder-container";
import OpportunityFundInactiveContainer from "../containers/opportunity-fund/opportunity-fund-inactive-container";
import PartnerCrisisResponseInactiveContainer
  from "../containers/partner-crisis-response/partner-crisis-response-inactive-container";
import NtfcuSupportPlaceholderContainer from "../containers/ntfcu-support/ntfcu-support-placeholder-container";
import HumanityForwardPlaceholderContainer from "../containers/humanity-forward/humanity-forward-placeholder-container";
 import LandingSevenSevenFiveContainer from "../containers/landing-seven-seven-five-container";
import {updateOnboardingProductName} from "../actions/dcl-actions";

const spanishEmployers = () => {
  return ['ya45', 'rn12', 'iz17']
}

function Employers() {
  console.log("Load the function Employers() and its routes");
  const employer = useSelector(state => state.employer)
  const locale = useSelector(state => state.locale)
  const login = useSelector(state => state.login)
  const { employerCode } = useParams()
  const dispatch = useDispatch()
  let history = useHistory()

   // TransUnion - clear the employerCode from the browser's address bar after fetching employer data
  useEffect(() => {
    if (employerCode === 'h310') {
      dispatch(getEmployer(employerCode.toLowerCase()))
      history.push('/')
    }
  });

  useEffect(() => {
    if (employer &&
      employer.code &&
      employerCode &&
      employerCode !== '0000' &&
      employer.code.toLowerCase() !== employerCode.toLowerCase()) {

      dispatch(getEmployer(employerCode)).then(() => {
        dispatch(updateMsgFile())
      })
    }
  }, [])

  useEffect(() => {
    if (employer &&
      employer.code &&
      employer.code === 'err') {

      history.push(`/`)
    }
  }, [employer])

  useEffect(() => {
    if (employerCode &&
      locale !== 'es-US' &&
      spanishEmployers().includes(employerCode.toLowerCase())
    ) {
      dispatch(toggleLocale(locale))
    }
  }, [locale])

  useEffect(() => {
    if (login &&
      login.isCustomersContact === true  ) {
      // history.push(`/customer-portal`)
      history.push(`/action-plan`)
    }
  }, [login])

    useEffect(() => {
        let productName = grabProductName();
        if (productName === 'DebtConsolidationLoan') {
            dispatch(updateOnboardingProductName(productName))
        } else {
            dispatch(updateOnboardingProductName(""))
        }
    })

    function grabProductName() {
        const url = location.search;
        const idParams = new URLSearchParams(url);
        const productName = idParams.get("ProductName")
        return productName;
    }

  return (
    <Switch>
      <Route exact path="/:employerCode" component={IntroductionContainer}/>

      <Redirect exact from="/:employerCode/appointment-schedule" to="/enter-your-email-address" />
      <Redirect exact from="/:employerCode/appointment-pick-coach" to="/enter-your-email-address" />
      <Redirect exact from="/:employerCode/enter-your-email-address" to="/enter-your-email-address" />

      <Route exact path="/:employerCode/hello" component={LandingSevenSevenFiveContainer}/>
      <Route exact path="/:employerCode/stimulus" component={LandingPropelContainer}/>
      <Route exact path="/:employerCode/taxes" component={LandingTaxesContainer}/>
      <Route exact path="/:employerCode/rent" component={LandingRentContainer}/>
      <Route exact path="/:employerCode/learn-more" component={LearnMore}/>

      <Route exact path="/:employerCode/sign-up" component={IntroductionContainer}/>
      <Route exact path="/:employerCode/sign-up-password" component={IntroductionContainer}/>

      <Route path="/:employerCode/emergency-response" component={EmergencyResponsePlaceholderContainer}/>
      <Route path="/:employerCode/emergency-relief" component={OpportunityFundInactiveContainer}/>
      <Route path="/:employerCode/crisis-response" component={PartnerCrisisResponseInactiveContainer}/>
      <Route path="/:employerCode/ntfcu-support" component={NtfcuSupportPlaceholderContainer}/>
      <Route path="/:employerCode/covid-relief" component={HumanityForwardPlaceholderContainer}/>
    </Switch>
)

}

export default Employers
