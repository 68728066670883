import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import ChangeEmailFormContainer from '../../containers/change-email-form-container'
import ChangePassFormContainer from '../../containers/change-pass-form-container'
import FooterActionPlan from '../footer-action-plan';
import ActionPlanNav from "./action-plan-nav";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {verifyToken} from "../../actions/login-actions";
import {currentMyAccountComponent} from "../../actions/nav-actions";
import {displayLinkMoneyModal} from "../../actions/link-money-actions";

export default function ActionPlanMyAccount(props) {

  const myAccountComponent= useSelector(state => state.currentMyAccountComponent)
  const login = useSelector(state => state.login)

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
      dispatch(displayLinkMoneyModal(false))
  }, [])

  return (
      <div id='ap-change-password'>
        <ActionPlanNav />
        <div id='action-plan-content'>
          <div className='action-plan-title-background-gradient'></div>
            <div className='action-plan-title-wrapper margin-bottom-24-px'>
                <div className='action-plan-title margin-bottom-8-px'>
                    <FormattedMessage id='app.action-plan-account.page-title'/>
                </div>
            </div>
            <div className='my-account-container'>
                    <ChangeEmailFormContainer login={login}
                                              verifyToken={() => dispatch(verifyToken(login))}
                                              setMyAccountComponent={(myAccountComponent) => dispatch(currentMyAccountComponent(myAccountComponent))}
                                              currentMyAccountComponent={myAccountComponent}/>

                    <ChangePassFormContainer login={login}
                                             verifyToken={() => dispatch(verifyToken(login))}
                                             setMyAccountComponent={(myAccountComponent) => dispatch(currentMyAccountComponent(myAccountComponent))}
                                             currentMyAccountComponent={myAccountComponent}/>
                    <div className='my-account-wrapper padding-bottom-16-px'>
                        <div className='my-account-reg-view-field margin-bottom-8-px'>
                            <FormattedMessage id='app.action-plan-dashboard.button.see-dashboard'/>
                        </div>
                        <div className='submit-button-round my-account-edit-button'>
                            <button id='edit-profile-questions'
                                    type={'button'}
                                    className='btn btn-default btn-lg my-account-btn'
                                    onClick={(e) => history.push('/action-plan')}>
                                    <FormattedMessage id='app.common.next.button'/>
                            </button>
                        </div>
                    </div>
            </div>
          <FooterActionPlan />
        </div>
      </div>
  )
}
