import React , { useState } from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import TradeDebtTable from "./trade-debt-table";

export default function TradeDebtHelpDispute(props) {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <div>
      <div className='recommendations-gradient-color'>
        <div className='top-priority'>
          <FormattedMessage id='app.debt-report-recommendations.top-priority'/>
        </div>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.collections'/>
        </h2>
      </div>

      <div className='recommendations-page-wrapper'>
        <div className='work-on-this-page-container'>
          <div className='action-plan-subtitle margin-bottom-24-px'>
            { props.account.name }
          </div>

          <p>
            <FormattedMessage id='app.debt-report-work-on-this.text-1'/>
          </p>
          <TradeDebtTable account={props.account} />

          <h3>
            <FormattedMessage id='app.debt-report-reviewed-collections.do-not-recognize-account.title'/>
          </h3>

          <p>
            <FormattedMessage id='app.debt-report-reviewed-collections.do-not-recognize-account.text-1'/>
          </p>

          <p>
            <FormattedMessage id='app.debt-report-reviewed-collections.do-not-recognize-account.text-2'/>
          </p>

          <div className='submit-button-round sixty-percent-btn'>
            <button className='btn btn-primary btn-block btn-work-on-this'
                    id='debt-report-reviewed-collections-yes'
                    onClick={()=>props.backToList()}>
              <FormattedMessage id='app.debt-report-collections.help-dispute-back-to-collections' />
            </button>
          </div>

        </div>
      </div>
    </div>

  )
}