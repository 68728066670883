import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import CustomerPortalFooter  from './customer-portal-footer';
import {injectIntl} from "react-intl";

import {useDispatch, useSelector} from "react-redux";
import CustomerPortalNav from "./customer-portal-nav";
import {useHistory} from "react-router-dom";
import CustomerPortalContact from "./customer-portal-contact";


function CustomerPortalContractAllSteps(props) {

    const edsContract = useSelector(state => state.edsContract)
    const pageCompleted = useSelector(state => state.pageCompleted);

    const dispatch = useDispatch();
    const history = useHistory();

    const goBack = (e) => {
        e.preventDefault()
        history.goBack()
    }

    return(
        <div id='customer-portal'>
            <CustomerPortalNav />
            <div id='customer-portal-content'>

            <div id='customer-portal-contracts' className='customer-portal-pages'>

                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <div className='action-plan-title margin-bottom-8-px'>
                        <FormattedMessage id='app.customer-portal.contracts-header'/>
                    </div>
                </div>

                <div className='customer-portal-back-link'>
                    <a href="" onClick={(e) => goBack(e)}>
                        <FormattedMessage id='app.customer-portal-common.back-link'/>
                    </a>
                </div>
                <h2><FormattedMessage id='app.customer-portal-contracts.progress-bar-details.title'/></h2>
                <div className='content-text margin-bottom-24-px'>
                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.text-1'/>
                </div>
                <div className='contracts-form-container'>
                    <div className='contracts-form-wrapper'>
                        <div className='contracts-form-title'>
                            <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.title-2'/>
                        </div>
                        <div className='color-key-container'>
                            <div className='color-key-wrapper'>
                                <div className='color-key-circle not-done'></div>
                                <div className='color-key-text'>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.text-2'/>
                                </div>
                            </div>
                            <div className='color-key-wrapper'>
                                <div className='color-key-circle done'></div>
                                <div className='color-key-text'>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.text-3'/>
                                </div>
                            </div>
                        </div>
                        <div className='progress-indicator-contract-heading margin-bottom'>
                            <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.text-4'/>
                        </div>
                        <div className='progress-bar-details-steps-container'>
                            <div className='color-key-wrapper'>
                                <div className={`color-key-circle ${pageCompleted >= 1 ? 'done' : ''}`} >
                                    <div className='color-key-number'>1</div>
                                </div>
                                <div className='color-key-text progress-bar-details-step'>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-1'/>
                                </div>
                            </div>
                            <div className='color-key-wrapper'>
                                <div className={`color-key-circle ${pageCompleted >= 2 ? 'done' : ''}`} >
                                    <div className='color-key-number'>2</div>
                                </div>
                                <div className='color-key-text progress-bar-details-step'>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-2'/>
                                </div>
                            </div>
                            <div className='color-key-wrapper'>
                                <div className={`color-key-circle ${pageCompleted >= 3 ? 'done' : ''}`} >
                                    <div className='color-key-number'>3</div>
                                </div>
                                <div className='color-key-text progress-bar-details-step'>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3a'/>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-3b'/>
                                </div>
                            </div>
                            <div className='contract-details-list'>
                                <ul>
                                    <li>
                                        <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.text-5'/>
                                    </li>
                                    <li>
                                        <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.text-6'/>
                                    </li>
                                    <li>
                                        <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.text-7'/>
                                    </li>
                                    <li>
                                        <FormattedMessage id='app.customer-portal-contracts.progress-bar-details.text-8'/>
                                    </li>
                                </ul>
                            </div>
                            <div className='color-key-wrapper'>
                                <div className={`color-key-circle ${pageCompleted >= 4 ? 'done' : ''}`} >
                                    <div className='color-key-number'>4</div>
                                </div>
                                <div className='color-key-text progress-bar-details-step'>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-4a'/>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-4b'/>
                                </div>
                            </div>
                            <div className='color-key-wrapper'>
                                <div className={`color-key-circle ${pageCompleted >= 5 ? 'done' : ''}`} >
                                    <div className='color-key-number'>5</div>
                                </div>
                                <div className='color-key-text progress-bar-details-step'>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-5a'/>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-5b'/>
                                </div>
                            </div>
                            <div className='color-key-wrapper'>
                                <div className={`color-key-circle ${pageCompleted >= 6 ? 'done' : ''}`} >
                                    <div className='color-key-number'>6</div>
                                </div>
                                <div className='color-key-text progress-bar-details-step'>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-6a'/>
                                    <FormattedMessage id='app.customer-portal-contracts.progress-bar-step-title.title-6b'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contracts-form-container-end'>
                </div>
                <div className='submit-button-round continue'>
                    <button className="btn btn-primary btn-block" id="visit-onboarding" onClick={(e) => goBack(e)}><FormattedMessage id='app.common.button.goback'/></button>
                </div>

                <CustomerPortalFooter/>
                <CustomerPortalContact />

            </div>
            </div>
        </div>
    )
}

export default (injectIntl(CustomerPortalContractAllSteps))
