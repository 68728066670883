import React from 'react'

export const DclTestimonialQuotation = ({
                              style = {},
                              fill = '#fff',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 308 177',
                            }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M1.75 20.9766C1.75 9.93087 10.7043 0.976562 21.75 0.976562H287.75C298.796 0.976562 307.75 9.93087 307.75 20.9766V176.977H1.75V20.9766Z" fill="#C2C3C4"/>
    <path opacity="0.15" d="M140.201 125.899L140.201 142.047C140.201 157.835 132.135 164.175 117.592 164.414L116.003 156.879C124.436 156.041 128.591 151.855 127.98 145.037L120.403 145.037L120.403 125.899L140.201 125.899ZM171.242 125.899L171.242 142.047C171.242 157.835 163.176 164.175 148.633 164.414L147.045 156.879C155.477 156.041 159.632 151.855 159.021 145.037L151.444 145.037L151.444 125.899L171.242 125.899Z" fill="white"/>
    <path opacity="0.15" d="M277.193 75.6459L277.193 58.5132C277.193 41.7612 285.751 35.0351 301.182 34.7812L302.867 42.7765C293.92 43.6649 289.512 48.1067 290.16 55.3405L298.199 55.3405L298.199 75.6459L277.193 75.6459ZM244.258 75.6459L244.258 58.5132C244.258 41.7612 252.816 35.0351 268.246 34.7812L269.932 42.7765C260.985 43.6649 256.576 48.1067 257.225 55.3405L265.264 55.3405L265.264 75.6459L244.258 75.6459Z" fill="white"/>
    <path opacity="0.15" d="M235.095 58.6613L199.349 58.6613C164.399 58.6613 150.365 41.5577 149.836 10.7193L166.517 7.35045C168.37 25.2315 177.638 34.0425 192.73 32.7467L192.73 16.6797L235.095 16.6797L235.095 58.6613ZM235.095 124.484L199.35 124.484C164.399 124.484 150.366 107.381 149.836 76.5424L166.517 73.1735C168.37 91.0546 177.638 99.8655 192.73 98.5698L192.73 82.5027L235.095 82.5027L235.095 124.484Z" fill="white"/>
    <path opacity="0.15" d="M0.0902696 100.908L44.3329 100.908C87.5924 100.908 104.962 122.078 105.617 160.248L84.9706 164.417C82.6766 142.285 71.2062 131.38 52.526 132.984L52.526 152.87L0.0902673 152.87L0.0902696 100.908ZM0.0902732 19.4375L44.3329 19.4375C87.5924 19.4375 104.962 40.6071 105.617 78.7766L84.9706 82.9464C82.6766 60.8145 71.2062 49.9089 52.526 51.5127L52.526 71.3993L0.0902709 71.3993L0.0902732 19.4375Z" fill="white"/>
    <path d="M86.6594 104.357V70.844C86.6594 38.0754 103.4 24.9184 133.583 24.4219L136.881 40.0614C119.379 41.7991 110.755 50.4877 112.024 64.6378H127.749V104.357H86.6594ZM22.2344 104.357V70.844C22.2344 38.0754 38.9747 24.9184 69.1581 24.4219L72.4555 40.0614C54.9542 41.7991 46.3304 50.4877 47.5986 64.6378H63.3244V104.357H22.2344Z" fill="#003057"/>
    <path d="M242.918 100.478L242.918 99.478L241.918 99.478L210.783 99.478L209.783 99.478L209.783 100.478L209.783 130.574L209.783 131.574L210.783 131.574L221.766 131.574C221.997 136.268 220.654 139.976 217.91 142.703C214.962 145.632 210.268 147.556 203.766 148.201L202.656 148.312L202.886 149.403L205.384 161.253L205.554 162.06L206.379 162.047C217.934 161.857 227.113 159.243 233.4 153.359C239.704 147.461 242.918 138.447 242.918 125.872L242.918 100.478ZM291.734 100.478L291.734 99.478L290.734 99.478L259.6 99.478L258.6 99.478L258.6 100.478L258.6 130.574L258.6 131.574L259.6 131.574L270.583 131.574C270.813 136.268 269.471 139.976 266.726 142.703C263.778 145.632 259.084 147.556 252.582 148.201L251.472 148.312L251.702 149.403L254.201 161.253L254.371 162.06L255.196 162.047C266.75 161.857 275.929 159.243 282.217 153.359C288.52 147.461 291.734 138.447 291.734 125.872L291.734 100.478Z" stroke="#003057" stroke-width="2"/>

  </svg>;

export default DclTestimonialQuotation