import React, {useEffect} from 'react'
import {useHistory} from "react-router-dom";
import FormattedMessage from '../helpers/FormattedMessage'
import Field from './../helpers/Field'
import Option from '../helpers/Option'
import {reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {useDispatch, useSelector} from "react-redux";
import {injectIntl} from "react-intl";

import CustomerPortalFooter  from './customer-portal-footer';
import CustomerPortalContact from "./customer-portal-contact";
import CustomerPortalProgressBar from "../helpers/CustomerPortalProgressBar";
import CustomerPortalNav from "./customer-portal-nav";


import {updateCPOrganization} from '../../actions/employer-actions'
import {pageCompleted} from '../../actions/cp-flow-actions'





const renderField = ({input, type, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)


function CustomerPortalContractAbout(props) {

  const employer = useSelector(state => state.employer)
  const registration = useSelector(state => state.registration)

  const dispatch = useDispatch();
  const history = useHistory();

    useEffect( () => {
      window.scrollTo(0, 0)
    })

    const ask = (e) => {
      e.preventDefault()
      history.push('/customer-portal/messaging/')
    }

    const goBackToLandingPage = (componentName) => {
      history.push('/customer-portal/contracts/')
    }

    const handleViewSteps = (e) => {
      e.preventDefault()
      history.push('/customer-portal/contracts/view-all-steps')
    }

    const {intl} = props;

    return(
        <div id='customer-portal'>
          <CustomerPortalNav />
        <div id='customer-portal-content'>
          <div id='customer-portal-contracts' className='customer-portal-pages'>

          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <div className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.customer-portal.contracts-header'/>
            </div>
          </div>

          <div className='customer-portal-dashboard-container'>

            <div className='customer-portal-back-link' onClick={goBackToLandingPage}>
              <FormattedMessage id='app.customer-portal-common.back-link'/>
            </div>
            <h2><FormattedMessage id='app.customer-portal-contracts.about-you.heading'/></h2>
            <div className='contract-content-wrapper'>
              <div className='contract-content-intro-wrapper'>
                <div className='contract-content-intro-text'>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you.text-1'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.about-you-text-2'/>
                  </div>
                </div>
                <CustomerPortalProgressBar pageNum={1}
                                           handleViewSteps={(e) => handleViewSteps(e)}
                />
              </div>

              <div className='contracts-form-container'>

                <div className='contracts-form-title-container'>
                  <div className='contracts-form-title'>
                    <FormattedMessage id='app.customer-portal-contracts.form.step-1.title'/>
                  </div>
                  <div className='content-text'>
                    <FormattedMessage id='app.customer-portal-contracts.form.about.intro-text-1'/>
                    <FormattedMessage id='app.customer-portal-contracts.form.common.intro-text-2'/>
                    <a href="#" onClick={(e) => ask(e)}><FormattedMessage id='app.customer-portal-contracts.form.common.intro-text-3'/></a>
                    <FormattedMessage id='app.customer-portal-contracts.form.common.intro-text-4'/>
                  </div>
                </div>

                <div className='contracts-form-wrapper'>
                  <div className='contracts-prefill-wrapper'>
                    <div className='contracts-prefilled-label'>
                      <label htmlFor='full-name'>
                        Full Name
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      {registration.firstName} {registration.lastName}
                    </div>
                  </div>
                  <div className='contracts-prefill-wrapper'>
                    <div className='contracts-prefilled-label'>
                      <label htmlFor='position'>
                        Position
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      {registration.title}
                    </div>
                  </div>
                  <div className='contracts-prefill-wrapper'>
                    <div className='contracts-prefilled-label'>
                      <label htmlFor='organization'>
                        Organization
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      {employer.name}
                    </div>
                  </div>

                  <form onSubmit={props.handleSubmit}>

                    <label htmlFor='organization-type'>
                      Organization Type
                    </label>

                    <Field component='select' name='organizationType' className='form-control' id='organizationType'>
                      <Option value='' i18nPrefix='app.customer-portal-contracts.form.about.select'/>
                      <Option value='Association' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Corporation' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Credit Union' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Employer Solution Organization' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Foundation' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Government' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Individual' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Media' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Nonprofit' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Small Business' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                      <Option value='Other' i18nPrefix='app.customer-portal-contracts.about.organization-type.answer.'/>
                    </Field>

                    <label htmlFor='billing-street'>
                      Billing Address
                    </label>
                    <div className='contracts-input margin-bottom-8-px'>
                      <Field
                        name='billingStreet'
                        type='input'
                        id='billingStreet'
                        component={renderField}
                        />
                    </div>

                    <label htmlFor='billing-city'>
                      City
                    </label>
                    <div className='contracts-input margin-bottom-8-px'>
                      <Field
                        name='billingCity'
                        type='input'
                        id='billingCity'
                        component={renderField}
                        />
                    </div>

                    <label htmlFor='billing-state'>
                      State
                    </label>
                    <div className='contracts-input margin-bottom-8-px'>
                      <Field
                        name='billingState'
                        type='input'
                        id='billingState'
                        component={renderField}
                        />
                    </div>

                    <label htmlFor='billing-postal-code'>
                      Zip Code
                    </label>
                    <div className='contracts-input margin-bottom-8-px'>
                      <Field
                        name='billingPostalCode'
                        type='input'
                        id='billingPostalCode'
                        component={renderField}
                        />
                    </div>

                    <label htmlFor='phone'>
                      Telephone
                    </label>
                    <div className='contracts-input margin-bottom-8-px'>
                      <Field
                        name='phone'
                        type='input'
                        id='phone'
                        component={renderField}
                        />
                    </div>

                    <div className='contracts-prefilled-label'>
                      <label htmlFor='email'>
                        Email
                      </label>
                    </div>
                    <div className='contracts-prefilled-data'>
                      {employer.email}
                    </div>

                    <div className='submit-button-round continue'>
                      <button
                        className="btn btn-primary btn-block"
                        id="submit"
                        type={'submit'}>
                        <FormattedMessage id='app.common.continue.button'/>
                      </button>
                    </div>

                  </form>

                </div>

              </div>
            </div>

            <CustomerPortalFooter />
            <CustomerPortalContact />

          </div>
        </div>
        </div>
      </div>
    )
}

function goToNextPage(props) {
  window.location.href = "/customer-portal/contracts/employees";
}


const mapStateToProps = (state) => {
  return {
    initialValues: {
      organizationType: state.employer.organizationType,
      billingStreet: state.employer.billingStreet,
      billingCity: state.employer.billingCity,
      billingState: state.employer.billingState,
      billingPostalCode: state.employer.billingPostalCode,
      phone: state.employer.phone,
      email: state.employer.email
    }
  }
}

const UpdateEmployer = reduxForm({
    form: 'CustomerPortalContractAbout',
    // validate: ValidationService.fullName,
    onSubmit: (values, dispatch, props) => {
        console.log("CustomerPortalContractAbout Submit");
        console.log(values);
        console.log(props);
        return dispatch(updateCPOrganization(values)).then(() => {
            dispatch(pageCompleted(1))
            goToNextPage(props)
        })
    },
    destroyOnUnmount: false
})(injectIntl(CustomerPortalContractAbout))

export default connect(mapStateToProps)(UpdateEmployer)

