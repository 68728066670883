import React from 'react'

export const ScaredEmoji = ({
                               style = {},
                               fill = '#000000',
                               width = '100%',
                               className = '',
                               height = '100%',
                               viewBox = '0 0 460 460',
                             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-5" transform="translate(120.500000, 114.600000)">
        <circle id="Oval" stroke="#AEE0E8" stroke-width="16" fill="#FFFFFF" cx="109" cy="109" r="101"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="82" cy="77" r="16"></circle>
        <circle id="Oval" fill="#AEE0E8" cx="136" cy="77" r="16"></circle>
        <ellipse id="Oval" stroke="#AEE0E8" stroke-width="16" cx="109" cy="151.5" rx="29" ry="30.5"></ellipse>
        <path d="M48.5,51 C52.1369681,45.4401042 58.8036348,42.6601562 68.5,42.6601562 C78.1963652,42.6601562 84.8630319,45.4401042 88.5,51" id="Line" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square" transform="translate(68.500000, 46.830078) rotate(-20.000000) translate(-68.500000, -46.830078) "></path>
        <path d="M128.5,52 C132.136968,46.4401042 138.803635,43.6601562 148.5,43.6601562 C158.196365,43.6601562 164.863032,46.4401042 168.5,52" id="Line" stroke="#AEE0E8" stroke-width="16" strokeLinecap="square" transform="translate(148.500000, 47.830078) rotate(20.000000) translate(-148.500000, -47.830078) "></path>
      </g>
    </g>
  </svg>;

export default ScaredEmoji