import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import Icon from '../../resources/icons/'

export default class Header_AP extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isProcessing: false
        }
    }

    onLocalToggle(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        this.props.onLocaleToggle(this.props.locale);
    }

    render() {

        return (
            <div className='header'>
                <div className='page-header-ap'>
                    <div className='container-ap'>
                      <div className='logo-wrapper'>
                        <div className='logo'>
                          <Icon name="tp-full-logo"/>
                        </div>
                      </div>
                      <button className='spanish-link'
                              onClick={ e => this.onLocalToggle(e) }>
                        <FormattedMessage id='app.introduction.locale-link'/>
                      </button>
                    </div>
                </div>
            </div>
        )
    }
}
