import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from "../footer-action-plan";
import FormattedMessage from "../helpers/FormattedMessage";
import {useHistory} from "react-router-dom";
import {displayLinkMoneyModal, getLinkedAccounts} from "../../actions/link-money-actions";

export default function ActionPlanLinkMoneyLanding (props) {

    const login = useSelector(state => state.login)
    const linkMoneyAccounts = useSelector(state => state.linkMoneyAccounts)

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(displayLinkMoneyModal(false))
    }, [])

    return (
        <div id='action-plan-link-money-landing'>
            <ActionPlanNav />
            <div id='action-plan-content'>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <h1 className='action-plan-title margin-bottom-8-px'>
                        <FormattedMessage id='app.action-plan-link-money.title'/>
                    </h1>
                </div>

                <div className='link-money-landing-header margin-bottom-24-px'>
                    <FormattedMessage id='app.action-plan-link-money.welcome'/>
                </div>

                <div className='link-money-landing-description margin-bottom-24-px'>
                    <FormattedMessage id='app.action-plan-link-money.welcome.description-1'/>
                </div>

                <div className='link-money-landing-description margin-bottom-24-px'>
                    <FormattedMessage id='app.action-plan-link-money.welcome.description-2'/>
                </div>

                <div className="submit-button-round btn-link-money margin-bottom-24-px">
                    <button id='link-money-landing-btn'
                            type={'button'}
                            className="btn btn-primary btn-block btn-lg"
                            onClick={(e) => history.push('/action-plan/link-money/gateway')}>
                        <FormattedMessage id='app.action-plan-link-money.button.link-accounts'/>
                    </button>
                </div>

                <div className='link-money-landing-link margin-bottom-24-px'>
                    <a href={"/action-plan"}><FormattedMessage id='app.action-plan-link-money.button.cancel'/></a>
                </div>

                <div className='link-money-landing-privacy'>
                    <FormattedMessage id='app.action-plan-link-money.welcome.description-3'/>
                </div>

                <div className='link-money-landing-encrypted'>
                    <FormattedMessage id='app.action-plan-link-money.welcome.description-4'/>
                </div>

                <FooterActionPlan />
            </div>
        </div>
    )
}