import FormattedMessage from "../helpers/FormattedMessage";
import React from "react";


function IntroductionTestimonials(props) {

  return(
    <div className='dcl-testimonials-section'>
      <div className='landing-container'>
        <h2>
          <FormattedMessage id='app.dcl-landing-page.testimonials.title'/>
        </h2>
        <div className='dcl-testimonials-container'>
          <div className='dcl-testimonial testimonial-1'>
            <div className='dcl-testimonial-image'>
              <img className='dcl-testimonial-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.testimonial-1'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/Yakaira-Desktop.png`}/>
            </div>
            <div className='dcl-testimonial-text'>
              <p>
                <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-1'/>
              </p>
              <div className='dcl-testimonial-author'>
                <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-author-1'/>
              </div>
            </div>
          </div>
          <div className='dcl-testimonial testimonial-2'>
            <div className='dcl-testimonial-image'>
              <img className='dcl-testimonial-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.testimonial-2'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/Edward-Desktop.png`}/>
            </div>
            <div className='dcl-testimonial-text'>
              <p>
                <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-2'/>
              </p>
              <div className='dcl-testimonial-author'>
                <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-author-2'/>
              </div>
            </div>
          </div>
          <div className='dcl-testimonial testimonial-3'>
            <div className='dcl-testimonial-image'>
              <img className='dcl-testimonial-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.testimonial-3'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/Annette-Desktop.png`}/>
            </div>
            <div className='dcl-testimonial-text'>
              <p>
                <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-3'/>
              </p>
              <div className='dcl-testimonial-author'>
                <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-author-3'/>
              </div>
            </div>
          </div>
          <div className='dcl-testimonial testimonial-4'>
            <div className='dcl-testimonial-image'>
              <img className='dcl-testimonial-image' role="img" aria-label={`${props.intl.formatMessage({id: 'app.dcl-landing-page.aria-label.testimonial-4'})}`} src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/DCL/James-Desktop.png`}/>
            </div>
            <div className='dcl-testimonial-text'>
              <p>
                <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-4'/>
              </p>
              <div className='dcl-testimonial-author'>
                <FormattedMessage id='app.dcl-landing-page.testimonials.testimonial-author-4'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
export default IntroductionTestimonials