import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import CustomerPortalNavItem from './customer-portal-nav-item'
import CustomerPortalBlueNavItem from './customer-portal-blue-nav-item'
import Icon from '../../../resources/icons'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {toggleLocale} from "../../actions/locale-actions";
import {cpLogout} from "../../actions/cp-flow-actions";

export default function CustomerPortalNav(props) {

  const employer = useSelector(state => state.employer)
  const locale = useSelector(state => state.locale)

  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    dispatch(cpLogout())
    history.push(`/customers/enter-your-email-address`);
  }

  const onLocalToggle = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    dispatch(toggleLocale(locale))
  }

  const onResetNavigation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    resetNavigation();
  }

  const resetNavigation = () => {
    if (window.innerWidth < 650) {
      history.push('/customer-portal/')
    } else {
      history.push('/customer-portal/')
    }
  }


  const employerImage = (code) => {
    return (
      <div className='company-logo-wrapper'>
        <img
          src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/Customer_Logos/${employer.code}.png`}
          width="auto" height="auto"
          alt={`${employer.displayName}`}
              />
      </div>
    )
  }

    return (

      <div className='container-nav'>

        <div className='login-landing-page'>
          <div className='nav-header'>
            <div className='nav-logo-wrapper margin-bottom-32-px'>
              <div className='nav-logo'>
                <a href='#' onClick={ e => onResetNavigation(e) }>
                  <Icon name="tp-full-logo"/>
                </a>
              </div>
            </div>
          </div>

          <div className='nav-pages-container'>

            <CustomerPortalNavItem
                key={'CustomerPortalDashboard'}
                componentActive={window.location.pathname == '/customer-portal'}
                title={'app.customer-portal-nav.dashboard'}
                icon={'customer-home'}
                navItemClick={() => history.push('/customer-portal')}
                />

            <CustomerPortalNavItem
                key={'CustomerPortalContractLanding'}
                componentActive={window.location.pathname.startsWith('/customer-portal/contracts')}
                title={'app.customer-portal-nav.contracts'}
                icon={'contracts'}
                navItemClick={() => history.push('/customer-portal/contracts')}
                />

            <CustomerPortalNavItem
                key={'CustomerPortalMyAccount'}
                componentActive={window.location.pathname.startsWith('/customer-portal/my-account')}
                title={'app.customer-portal-nav.account'}
                icon={'my-account'}
                navItemClick={() => history.push('/customer-portal/my-account')}
                />

            <CustomerPortalNavItem
                key={'CustomerPortalReports'}
                componentActive={window.location.pathname.startsWith('/customer-portal/reports')}
                title={'app.customer-portal-nav.reports'}
                icon={'reports'}
                navItemClick={() => history.push('/customer-portal/reports')}
              />

            <div className='nav-page-box-blue blue-box-margin-top'>
              <CustomerPortalBlueNavItem
                key={'CustomerPortalFaq'}
                componentActive={window.location.pathname.startsWith('/customer-portal/faqs')}
                title={'app.customer-portal-nav.faq'}
                icon={'faq'}
                navItemClick={() => history.push('/customer-portal/faqs')}
                />
            </div>

            <div className='nav-page-box-blue'>
              <a href='#' onClick={ e => onLogout(e) }>
                <div className='nav-page-white-box-container'>
                  <div className='nav-page-logo-wrapper'>
                    <div className='nav-page-logo'>
                      <Icon fill="#ffffff " name="sign-out"/>
                    </div>
                  </div>
                  <div className='nav-page-name-wrapper'>
                    <div className='nav-page-name-utility'>
                      <FormattedMessage id='app.action-plan-nav.sign-out'/>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
}