import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from "../footer-action-plan";
import {getLinkedAccounts} from "../../actions/link-money-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import FormattedMessage from "../helpers/FormattedMessage";

export default function ActionPlanLinkMoneyAccounts(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const linkMoneyAccounts = useSelector(state => state.linkMoneyAccounts)

  useEffect(() => {
    dispatch(getLinkedAccounts())
  }, [])

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const accountSum = () => {
    let sum = 0;

    linkMoneyAccounts.forEach( v => {
      sum += v.amount;
    })

    return sum;
  };

  return (
    <div id='action-plan-link-money-accounts'>
      <ActionPlanNav />
      <div id='action-plan-content'>
        <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <h1 className='action-plan-title margin-bottom-8-px'>
              Your Linked Accounts
            </h1>
        </div>


        <div className='link-money-accounts'>
          <div className='link-money-account-totals'>
            <div className='account-row'>
              <div className='col'>
                <h3>Cash</h3>
              </div>
              <div className='col align-right'>
                <h3>{USDollar.format(accountSum())}</h3>
              </div>
            </div>
          </div>

          { linkMoneyAccounts.map((item) => {
            return <div className='account-row'>
              <div className='col account-row-logo'>
                <div className='img-wrapper'>
                  <img src={item.logoUrl} width="auto" height="auto" alt={item.bankName} />
                </div>
              </div>
              <div className='col account-row-details'>
                <div className='account-type'>
                  {item.accountName}
                </div>
                <div className='account-bank'>
                  {item.bankName} &bull; {item.lastFour}
                </div>
              </div>
              <div className='col account-row-details align-right'>
                <div className='account-type'>
                  {USDollar.format(item.amount)}
                </div>
                <div className='account-bank'>
                  {item.updatedBy}
                </div>
              </div>
            </div>
          })}

          <div className='account-row'>
          <div className='col account-row-logo'>
            <div className='img-wrapper'>
              <button id='link-money-accounts-btn'
                      type={'button'}
                      onClick={(e) => history.push('/action-plan/link-money/gateway')}>
              <div className='icon'>
                <FontAwesomeIcon icon={['fas', 'plus-circle']} />
              </div>
              <div className='add-new'>
                ADD NEW
              </div>
              </button>
            </div>
          </div>
          <div className='col account-add'>
          </div>
        </div>

        </div>

        <FooterActionPlan />
      </div>
    </div>
  )
}

