export const PROGRESS_PERCENTAGE = 'PROGRESS_PERCENTAGE'

export function progressPercentage(progress_percentages, key, val) {
  let percentages = progress_percentages
  let k = key
  let v = val
  percentages[k] = v
  return {
    type: PROGRESS_PERCENTAGE,
    progressPercentage: percentages
  }
}
