import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import FormattedMessage from '../helpers/FormattedMessage';
import HeaderContainerAP from "../../containers/header-container-ap";
import FooterContainerAp from "../../containers/footer-container-ap";
import Field from "../helpers/Field";
import {reduxForm} from "redux-form";
import {injectIntl} from "react-intl";
import ValidationService from "../../services/validation-service";
import {createLead} from "../../actions/customer-actions";

const renderField = ({input, autocomplete, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
        <input {...input}  placeholder={placeholder} type={type}
               className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

function CustomersScheduleADemo(props) {

    const [isProcessing, setIsProcessing] = useState();

    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid ||
            isProcessing;
    }

    useEffect(() => {
        // for planned customer portal login logic
        // if (props.login === null) {
        //     props.history.push(`customers/enter-your-email-address`);
        // } else if (props.login !== null) {
        //     if (props.login.nextPage === null) {
        //         props.history.push(`/customer-portal`);
        //     }
        // }
    })

    const {intl, handleSubmit} = props;

    return (
        <div className='b-flow b-flow-background'>
            <HeaderContainerAP/>
            <div className='b-flow-body'>
                <div className='container-ap schedule-a-demo'>
                    <div className='page-title-div margin-bottom-32-px'>
                        <h1 className='page-title'>
                            <FormattedMessage id='app.customers.welcome'/>
                        </h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group margin-bottom-32-px'>
                            <Field name='name' type='text' id='name' component={renderField}
                                   placeholder={intl.formatMessage({ id: 'app.customers.schedule-a-demo.input-name'})}
                            />
                            <Field name='title' type='text' id='title' component={renderField}
                                   placeholder={intl.formatMessage({ id: 'app.customers.schedule-a-demo.input-title'})}
                            />
                            <Field name='email' type='text' id='email' component={renderField}
                                   placeholder={intl.formatMessage({ id: 'app.customers.schedule-a-demo.input-email'})}
                            />
                            <Field name='companyName' type='text' id='companyName' component={renderField}
                                   placeholder={intl.formatMessage({ id: 'app.customers.schedule-a-demo.input-company-name'})}
                            />
                            <Field name='sizeOfWorkforce' type='number' id='sizeOfWorkforce' component={renderField}
                                   placeholder={intl.formatMessage({ id: 'app.customers.schedule-a-demo.input-size-of-workforce'})}
                            />
                        </div>
                        <div className='submit-button-round margin-bottom-24-px'>
                            <button id='submit'
                                    type={'submit'}
                                    className='btn btn-red'
                                    disabled={isSubmitDisabled(props, isProcessing)}>
                                <FormattedMessage id='app.customers.schedule-a-demo.button.continue'/>
                            </button>
                        </div>
                    </form>
                </div>
                <FooterContainerAp/>
            </div>
        </div>
    )
}

export default reduxForm({
    form: 'CustomersScheduleADemo',
    validate: ValidationService.scheduleADemo,
    onSubmit: (values, dispatch, props) => {
        return dispatch(createLead(values)).then(() => {
            // props.history.push('/customers')
            console.log("lead successfully saved!");
        })
    },
})(injectIntl(CustomersScheduleADemo))