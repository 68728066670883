import React from 'react';
import Icon from "../../resources/icons";
import FormattedMessage from './helpers/FormattedMessage'
import moment from 'moment'

const options = {
  year: 'numeric', month: 'long', day: '2-digit', timeZone: 'UTC'};

const today = moment().format('MMMM Do YYYY');

export default class TakeActionTodayAchievedRow extends React.Component {

  constructor(props) {
    super(props);
  }




  render() {
    return (
      <div>
        <div className='take-action-today-item-wrapper'>

          <div className='take-action-today-column-1'>
            <div className='take-action-today-sub-heading margin-bottom-4-px'>
              { this.props.item.tatGoalComment }
            </div>

            {this.props.item.tatDateAchieved != null ?
              <div className='take-action-today-due-date'>
                <FormattedMessage id='app.action-plan-take-action-today.achieved-date'/>
               {
                  new Intl.DateTimeFormat(this.props.locale, options).format(
                    new Date(this.props.item.tatDateAchieved))
                }
              </div>
            :
              <div className='take-action-today-due-date'>
                <FormattedMessage id='app.action-plan-take-action-today.achieved-date'/>
                {today}
              </div>

          }

          </div>

          <div className='take-action-today-column-2'>
            <div className='take-action-today-second-heading margin-bottom-4-px'>
              <FormattedMessage id='app.action-plan-take-action-today.how-to-achieve'/>
            </div>
            <div className='take-action-today-details'>
              <p>
                <div dangerouslySetInnerHTML={{
                  __html: this.props.item.tatHowToAccomplish}} />
              </p>
            </div>
          </div>

          <div className='take-action-today-column-3'>
            <div className='achieved-plant-wrapper'>
              <div className='achieved-plant-svg-mobile'>
                <Icon name="achieved-plant-mobile"/>
              </div>
            </div>
          </div>

        </div>
      {this.props.lastItem ? '' :
        <div className='action-plan-divide take-action-today-divide'>
        </div>


      }

      </div>
        )
    }
}
