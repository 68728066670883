import React from 'react'
import Icon from "../../../resources/icons";

export default class HumanityForwardHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isProcessing: false
        }
    }

  render() {
    return (
        <div className='er-header'>

          <div className='landing-container landing-intro-container'>
            <div className='landing-header-links-wrapper er-links-wrapper'>

              <div className='nav-logo-landing-page'>
                <div className='nav-logo-landing-page-desktop er-nav-logo'>
                  <Icon name="tp-full-logo"/>
                </div>
              </div>

                <div className='landing-header-links-right humanity-forward-links-right'>
                    <div className='nav-logo-landing-page-desktop er-nav-logo humanity-forward-logo'>
                    </div>
                </div>

            </div>
          </div>
        </div>
    )
  }
}
