import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'

export default function FooterActionPlan(props) {

  return (
    <div className='footer-action-plan'>
      <a href='/action-plan/terms-of-service'>
        <FormattedMessage id='app.action-plan-nav.terms-of-service'/>
      </a>
      <FormattedMessage id='app.action-plan-nav.and'/>
      <a href='/action-plan/privacy-policy'>
        <FormattedMessage id='app.action-plan-nav.privacy-policy'/>
      </a>
    </div>
  )
}
