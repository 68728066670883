import {combineReducers} from 'redux'
import login from './login'
import registration from './registration'
import client from './client'
import clientRetrieved from "./client-retrieved";
import employer from './employer'
import debtReport from './debt-report'
import englishCounselors from "./english-counselors"
import englishCounselorsReturned from './english-counselors-returned'
import spanishCounselors from "./spanish-counselors"
import spanishCounselorsReturned from './spanish-counselors-returned'
import locale from './locale'
import msgFile from './msgFile'
import displayAlert from './display-alert'
import alertName from './alert-name'
import progressPercentage from './progress-percentage'
import form from './form'
import randomCounselor from './randomCounselor'
import debtReportTimeout from './debt-report-timeout'
import debtReportAttempt from './debt-report-attempt'
import displayLoadingIcon from './display-loading-icon'
import clearReduxStore from './clear-redux-store'
import logout from './logout'
import navEntered from './nav-entered'
import navCurrentComponent from './nav-current-component'
import currentMyAccountComponent from './current-my-account-component'
import ficoScore from './fico-score'
import budgetIncome from './budget-income'
import budgetExpense from './budget-expense'
import isCreditFormOpen from './is-credit-form-open'
import debtSummaryCategoriesExpanded from './debt-summary-categories-expanded'
import debtSummaryAccountDetail from './debt-summary-account-detail'
import pendingTakeActionToday from './take-action-today-pending'
import achievedTakeActionToday from './take-action-today-achieved'
import takeActionTodayAchievedDivHeight from './take-action-today-achieved-div-height'
import markedAchievedTakeActionToday from './marked-achieved-take-action-today'
import updatedPendingTakeActionToday from './updated-pending-take-action-today'
import tradeDebtAccounts from './trade-debt-account'
import createSuccessStory from "./create-success-story";
import updateSuccessStory from './update-success-story'
import activeCounselors from "./active-counselors";
import activeCounselor from './active-counselor';
import bookedAppointments from "./bookedAppointments";
import completedAttendedAppointments from "./completedAttendedAppointments";
import currentCalendlyConfirmationPath from "./current-calendly-confirmation-path";
import collectionTATCreated from './collection-take-action-today-created';
import englishCurrentlyWorkingCounselors from "./english-currently-working-counselors";
import englishCurrentlyWorkingCounselorsReturned from "./english-currently-working-counselors-returned";
import spanishCurrentlyWorkingCounselors from "./spanish-currently-working-counselors";
import spanishCurrentlyWorkingCounselorsReturned from "./spanish-currently-working-counselors-returned";
import activeCurrentlyWorkingCounselors from "./active-currently-working-counselors";
import timezone from "./client-timezone";
import activeTab from "./active-tab";
import temporaryEmail from "./temporary-email";
import lead from "./lead";
import activeEmployers from "./active-employers";
import edsContract from "./eds-contract"
import pageCompleted from "./pageCompleted"
import calendlyRoundRobinUrl from "./calendly-round-robin-url";
import calendlyRoundRobinUrlSpanish from "./calendly-round-robin-url-spanish";
import incomeBudgetFormPristine from "./income-budget-form-pristine";
import expenseBudgetFormPristine from "./expense-budget-form-pristine";
import displayTimeoutModal from "./display-timeout-modal";
import employerLoadedForNav from "./employer-loaded-for-nav";
import updateRegistrationCompleted from "./update-registration-completed";
import linkMoneyCategoryData from "./linkMoneyCategoryData";
import linkMoneyAccounts from "./linkMoneyAccounts";
import linkMoneyTransactions from "./linkMoneyTransactions";
import displayLinkMoneyModal from "./display-link-money-modal";
import dcl from "./dcl";
import contactCreated from "./contact-created";

const appReducer = combineReducers({
  login,
  registration,
  client,
  clientRetrieved,
  locale,
  msgFile,
  form,
  employer,
  debtReport,
  englishCounselors,
  englishCounselorsReturned,
  spanishCounselors,
  spanishCounselorsReturned,
  randomCounselor,
  debtReportTimeout,
  debtReportAttempt,
  displayAlert,
  alertName,
  progressPercentage,
  displayLoadingIcon,
  clearReduxStore,
  logout,
  navEntered,
  navCurrentComponent,
  currentMyAccountComponent,
  ficoScore,
  budgetIncome,
  budgetExpense,
  isCreditFormOpen,
  debtSummaryCategoriesExpanded,
  debtSummaryAccountDetail,
  pendingTakeActionToday,
  achievedTakeActionToday,
  takeActionTodayAchievedDivHeight,
  markedAchievedTakeActionToday,
  updatedPendingTakeActionToday,
  tradeDebtAccounts,
  createSuccessStory,
  updateSuccessStory,
  activeCounselors,
  activeCounselor,
  bookedAppointments,
  completedAttendedAppointments,
  currentCalendlyConfirmationPath,
  collectionTATCreated,
  englishCurrentlyWorkingCounselors,
  englishCurrentlyWorkingCounselorsReturned,
  spanishCurrentlyWorkingCounselors,
  spanishCurrentlyWorkingCounselorsReturned,
  activeCurrentlyWorkingCounselors,
  timezone,
  activeTab,
  temporaryEmail,
  lead,
  activeEmployers,
  edsContract,
  pageCompleted,
  calendlyRoundRobinUrl,
  calendlyRoundRobinUrlSpanish,
  incomeBudgetFormPristine,
  expenseBudgetFormPristine,
  displayTimeoutModal,
  employerLoadedForNav,
  updateRegistrationCompleted,
  linkMoneyCategoryData,
  linkMoneyAccounts,
  linkMoneyTransactions,
  displayLinkMoneyModal,
  dcl,
  contactCreated
})

export default appReducer
