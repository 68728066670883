import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {useHistory} from "react-router-dom";

function CustomerPortalContact () {

    const edsContract = useSelector(state => state.edsContract)
    const employer = useSelector(state => state.employer)
    const dispatch = useDispatch();
    const history = useHistory();

    const useSalesOwner = () => {
        if (edsContract) {
            return edsContract.status !== "Complete";
        } else {
            return true
        }
    }

    const handleImage = () => {
        return `https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/${handleName()}.jpg`
    }

    const handleName = () => {
        return useSalesOwner() ? employer.salesOwnerFirstName : employer.relationshipManagerFirstName
    }

    const handleContactId = () => {
        return useSalesOwner() ? employer.salesOwnerContactId : employer.relationshipManagerContactId
    }

    return (
        <div className='messaging-container'>
            <div className='messaging-box'>
                <div className='messaging-interior-box'>
                    <div className='interior-box-text'>
                        <FormattedMessage id='app.customer-portal-footer.text-1'/>
                        <span className='bold'><FormattedMessage
                            id='app.customer-portal-footer.text-2'/> {handleName()}.</span>
                    </div>
                    <div className='ask-container'>
                        <div className='ask-circle'>
                            <div className='ask-circle-container'>
                                <img src={handleImage()} className='img-circle'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default (injectIntl(CustomerPortalContact))