import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import FicoScore from '../fico-score'
import DebtSummaryWaiting from '../debt-summary-waiting'
import DebtSummaryPermission from '../debt-summary-permission'
import DebtSummaryCreditReportPageContainer from "../../containers/debt-summary-credit-report-page-container";
import FooterActionPlan from '../footer-action-plan';
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../../actions/login-actions";
import {isCreditFormOpen} from '../../actions/credit-form-actions'
import ActionPlanNav from "./action-plan-nav";
import {getFicoScore} from "../../actions/fico-score-actions";
import {displayLinkMoneyModal} from "../../actions/link-money-actions";

export default function ActionPlanFicoScore (props) {
  const ficoScoreDummyData={
    id: 'Fake data',
    contactId: 'xyz',
    ficoScore: '667',
    meter: '18',
    detail1: '1 Too many accounts recently opened:',
    detail2: '2 Time since most recent account opening is too short.',
    desc1: '1 Opening several credit accounts in a short time period is generally reflective of greater risk - especially for people with a short credit history.',
    desc2: '2 Opening several credit accounts in a short time period is generally reflective of greater risk - especially for people with a short credit history.',
    kim1: '1 FICO scores consider the number of recent credit account openings. Your FICO score was impacted because of too many recent credit openings.',
    kim2: '2 FICO scores consider the number of recent credit account openings. Your FICO score was impacted because of too many recent credit openings.',
    detailsp1: '1sp Too many accounts recently opened:',
    detailsp2: '2sp Time since most recent account opening is too short.',
    descsp1: '1sp Opening several credit accounts in a short time period is generally reflective of greater risk - especially for people with a short credit history.',
    descsp2: '2sp Opening several credit accounts in a short time period is generally reflective of greater risk - especially for people with a short credit history.',
    kimsp1: '1sp FICO scores consider the number of recent credit account openings. Your FICO score was impacted because of too many recent credit openings.',
    kimsp2: '2sp FICO scores consider the number of recent credit account openings. Your FICO score was impacted because of too many recent credit openings.',
    createdDate: '04/10/2019'
  }

  const ficoScore = useSelector(state => state.ficoScore)
  const locale = useSelector(state => state.locale)
  const login = useSelector(state => state.login)
  const employer = useSelector(state => state.employer)
  const registration = useSelector(state => state.registration)
  const creditFormOpen = useSelector(state => state.isCreditFormOpen)
  const currentComponent = useSelector((state) => state.navCurrentComponent)

  const dispatch = useDispatch();

  function toggleCreditFormOpen(truthy) {
    dispatch(isCreditFormOpen(truthy))
    dispatch(verifyToken(login))
  }

  useEffect( () => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (ficoScore === null &&
      registration.creditReportAuthorizationReceived === 'Yes') {
      dispatch(getFicoScore())
    }
    dispatch(displayLinkMoneyModal(false))
  }, [])

  function pageSection() {
    let auth = registration.creditReportAuthorizationReceived
      if (ficoScore != null) {
          return <FicoScore
              ficoScore={ficoScore}
              locale={locale}/>
      }
      else if ((auth === null || auth === "No") && creditFormOpen === false) {
          return <DebtSummaryPermission
              contactId={registration.id}
              employer={employer}
              creditReportAuthorizationReceived={registration.creditReportAuthorizationReceived}
              isCreditFormOpen={creditFormOpen}
              navCurrentComponent={currentComponent}
              toggleCreditFormOpen={(truthy) => toggleCreditFormOpen(truthy)}/>
      }
      else if ((auth === null || auth === "No") && creditFormOpen) {
        return <DebtSummaryCreditReportPageContainer contactId={registration.id}
                                                     navCurrentComponent={currentComponent}/>
      }
      else {
          return <DebtSummaryWaiting/>;
      }
    }

    return (
      <div id='action-plan-fico-score'>
        <ActionPlanNav />
        <div id='action-plan-content'>
          <div className='action-plan-title-wrapper margin-bottom-24-px'>
            <div className='action-plan-title-background-gradient'></div>
            <h1 className='action-plan-title margin-bottom-8-px'>
              <FormattedMessage id='app.action-plan-fico-score.page-title'/>
            </h1>
          </div>

          {pageSection()}

          <FooterActionPlan />
        </div>
      </div>
    )
}
