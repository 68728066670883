import React from 'react'
import {Field} from 'redux-form'
import FormattedMessage from './FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const BudgetIncomeField = ({name, id, i18n, minus, increment, decrement}) => (
  <div className='budget-form-group margin-bottom-8-px'>
    <div className='budget-label-text-wrapper'>
      <div className='budget-label-text'>
        <FormattedMessage id={i18n}/>
      </div>
    </div>
    <div className='budget-income-field-wrapper'>
      <div className={`budget-page-minus-svg-wrapper ${minus}`}>
        <div className='budget-page-svg'>
          <a href='#' onClick={ e => decrement(e) }>
            <FontAwesomeIcon icon={['fas', 'minus-square']} />
          </a>
        </div>
      </div>
      <div className='budget-field'>
        <Field name={name} type='number' id={id} component={renderField} placeholder='$0'/>
      </div>
      <div className='budget-page-plus-svg-wrapper'>
        <div className='budget-page-svg'>
          <a href='#' onClick={ e => increment(e) }>
            <FontAwesomeIcon icon={['fas', 'plus-square']} />
          </a>
        </div>
      </div>
    </div>
  </div>
)

const renderField = ({input, id, placeholder, normalize, props, isDisabled, meta: {touched, error}}) => (
  <div>
    <input {...input}  id={id} placeholder={placeholder} type="number" pattern="[0-9]*" inputMode="numeric" min="0"
           className={!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}
           disabled={isDisabled}/>

    {
      touched && (error &&
        <div className='label-text red'>
          <FormattedMessage id={error}/>
        </div>
      )
    }
  </div>
)

export default BudgetIncomeField