import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import BudgetIncomeFormContainer from '../../containers/budget-income-form-container'
import BudgetExpenseFormContainer from '../../containers/budget-expense-form-container'
import {CSSTransition} from 'react-transition-group'
import FooterActionPlan from '../footer-action-plan';
import {useDispatch, useSelector} from "react-redux";
import {verifyToken} from "../../actions/login-actions";
import ActionPlanNav from "./action-plan-nav";
import {
  expenseBudgetFormPristine,
  getBudgetExpense,
  getBudgetIncome,
  incomeBudgetFormPristine
} from "../../actions/budget-actions";
import BudgetSaveWarningModal from "../modals/budget-save-warning-modal";
import {useHistory} from "react-router-dom";
import { useLocation} from 'react-router';
import {displayLinkMoneyModal} from "../../actions/link-money-actions";

export default function ActionPlanBudget(props) {

  const [totalExpense, setTotalExpense] = useState(0)
  const [showGrayButton, setShowGrayButton] = useState(false)
  const [showRedButton, setShowRedButton] = useState(true)
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false)
  const [submitIncome, setSubmitIncome] = useState(false)
  const [submitExpense, setSubmitExpense] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)

  const login = useSelector(state => state.login)
  const budgetExpense = useSelector(state => state.budgetExpense)
  const budgetIncome = useSelector(state => state.budgetIncome)
  const activeCounselor = useSelector(state => state.activeCounselor)
  const incomeBudgetPristine = useSelector(state => state.incomeBudgetFormPristine)
  const expenseBudgetPristine = useSelector(state => state.expenseBudgetFormPristine)
  const randomCounselor = useSelector(state => state.randomCounselor)

  const dispatch = useDispatch()
  const history = useHistory()

  const prevLocation = useLocation().pathname;

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if ((incomeBudgetPristine === false || expenseBudgetPristine === false) && location.pathname !== prevLocation) {
        history.push(prevLocation)
        setDisplayModal(true)
      }
    });

    return () => {
      unlisten()
    }
  },[incomeBudgetPristine, expenseBudgetPristine])

  useEffect( () => {
    window.scrollTo(0, 0)
    dispatch(getBudgetIncome())
    dispatch(getBudgetExpense())
    dispatch(displayLinkMoneyModal(false))
  }, [])

  function notifyIncomeBudgetSubmitted() {
    setSubmitIncome(false)
    dispatch(incomeBudgetFormPristine(true))
  }

  function notifyExpenseBudgetSubmitted() {
    setSubmitExpense(false)
    dispatch(expenseBudgetFormPristine(true))
  }

  function animationStep1() {
    setShowGrayButton(true)
    setShowRedButton(false)
    submitForms()
  }

  function animationStep2() {
    setShowSaveConfirmation(true)
    setShowGrayButton(false)
  }

  function animationStep3() {
    setShowRedButton(true)
    setShowSaveConfirmation(false)
  }

  function submitForms() {
    setSubmitIncome(true)
    setSubmitExpense(true)
  }

  function cancelModal(){
    setDisplayModal(false)
    dispatch(incomeBudgetFormPristine(true))
    dispatch(expenseBudgetFormPristine(true))
  }

  function saveModal() {
    setSubmitIncome(true)
    setSubmitExpense(true)
    setDisplayModal(false)
  }

  return (
    login && <div id='action-plan-budget' >
      {displayModal ? <div onClick={() => cancelModal()} className="back-drop"></div> : null }
      {displayModal ?
        <BudgetSaveWarningModal
          className="modal1"
          imageId={activeCounselor === null ? (randomCounselor === null ? '' : randomCounselor.userId) : activeCounselor.userId}
          displayModal={displayModal}
          cancelModal={() => cancelModal()}
          saveModal={() => saveModal()} /> : <div/>}

      <ActionPlanNav />
      <div id='action-plan-content'>
        <div className='action-plan-title-wrapper margin-bottom-24-px'>
          <div className='action-plan-title-background-gradient'></div>
          <div className='budget-save-button-wrapper margin-bottom-8-px'>
            {showRedButton && (
              <div className='submit-button-round budget-save-button'>

                <button id='save'
                        onClick={() => animationStep1()}
                        className='btn btn-primary btn-block btn-lg budget-save-button-color-1'>
                  <div className='save-icon'>
                    <FontAwesomeIcon icon={['far', 'save']} />
                  </div>
                  <div>
                    <FormattedMessage id='app.action-plan-budget.button.save'/>
                  </div>
                </button>
              </div>
            )}

            <CSSTransition
              in={showGrayButton}
              timeout={2500}
              classNames='budget-save-gray-button'
              unmountOnExit
              onEntered={() =>  {animationStep2()}}
            >
              <div>
              {showGrayButton && (
              <div className='submit-button-round budget-save-button'>

                <button id='save'
                        className='btn btn-primary btn-block btn-lg budget-save-button-color-2'>
                  <div className='save-icon'>
                    <FontAwesomeIcon icon={['far', 'save']} />
                  </div>
                  <div>
                    <FormattedMessage id='app.action-plan-budget.button.save'/>
                  </div>
                </button>
              </div>
              )}
              </div>
            </CSSTransition>

            <CSSTransition
              in={showSaveConfirmation}
              timeout={2500}
              classNames='budget-save-gray-button'
              unmountOnExit
              onEntered={() => {animationStep3()}}
            >
              <div>
                {showSaveConfirmation && (
              <div className='budget-save-confirmation'>
                  <FormattedMessage id='app.action-plan-budget.button.saved-message'/>
              </div>
                )}
              </div>
            </CSSTransition>

          </div>
          <h1 className='action-plan-title margin-bottom-8-px'>
            <FormattedMessage id='app.action-plan-budget.page-title'/>
          </h1>
        </div>

        <div className='budget-page-container'>
          <div className='budget-income-wrapper'>
            <BudgetIncomeFormContainer budgetIncome={budgetIncome}
                                       imageId={activeCounselor === null ? (randomCounselor === null ? '' : randomCounselor.userId) : activeCounselor.userId}
                                       submitIncome={submitIncome}
                                       updateIncomeBudgetFormPristine={(truthy) => dispatch(incomeBudgetFormPristine(truthy))}
                                       notifyIncomeBudgetSubmitted={() => notifyIncomeBudgetSubmitted()}
                                       totalExpense={totalExpense}
                                       verifyToken={() => dispatch(verifyToken(login)) }/>
          </div>
          <div className='budget-expense-wrapper'>
            <BudgetExpenseFormContainer budgetIncome={budgetExpense}
                                        imageId={activeCounselor === null ? (randomCounselor === null ? '' : randomCounselor.userId) : activeCounselor.userId}
                                        submitExpense={submitExpense}
                                        updateExpenseBudgetFormPristine={(truthy) => dispatch(expenseBudgetFormPristine(truthy))}
                                        updateExpenseBudget={(totalExpense) => setTotalExpense(totalExpense)}
                                        notifyExpenseBudgetSubmitted={() => notifyExpenseBudgetSubmitted()}
                                        verifyToken={() => dispatch(verifyToken(login))}/>
          </div>
        </div>
        <FooterActionPlan />
      </div>
    </div>
  )
}
