import {
  GET_TRADE_DEBT_ACCOUNT
  } from '../actions/trade-debt-account-actions'

const tradeDebtAccounts= (state = null, action) => {
  switch (action.type) {
    case GET_TRADE_DEBT_ACCOUNT:
      return action.tradeDebtAccounts
    default:
      return state
  }
}

export default tradeDebtAccounts