import React, {useEffect} from 'react';
import FormattedMessage from '../helpers/FormattedMessage';
import Icon from "../../../resources/icons";
import {injectIntl} from "react-intl";
import {createMessage} from "../../actions/messages-actions"
import ValidationService from "../../services/validation-service";
import {connect, useSelector} from "react-redux";
import {Field, reduxForm} from "redux-form";

const renderSubject = ({input, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div className='compose-subject-input-wrapper'>
        <input {...input} placeholder={placeholder} type={type}
               className={`compose-subject-input-new-message ${!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}`}
               disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
)

const renderTextArea = ({input, type, placeholder, isDisabled, meta: {touched, error}}) => (
    <div>
            <textarea {...input} placeholder={placeholder} type={type}
                      className={`compose-body-input-new-message ${!touched && 'form-control' || touched && (!error && 'form-control') || touched && (error && 'form-control red')}`}
                      disabled={isDisabled}/>
        {
            touched && (error &&
                <div className='label-text red'>
                    <FormattedMessage id={error}/>
                </div>
            )
        }
    </div>
);

function ActionPlanMessagesNew(props) {

    const fromName = useSelector(state => state.registration.firstName)

    useEffect(() => {
        if (props.submitSucceeded) {
            const timeout = setTimeout(() => {
                props.reset();
            }, 3000);

            return () => clearTimeout(timeout)
        }
    }, [props.submitSucceeded]);

    useEffect(() => {
        props.reset();
    }, []);



    const isSubmitDisabled = (props) => {
        return props.pristine ||
            props.submitting ||
            !props.valid
    }


    return (<>
                <hr/>
                <div className='messaging-messages-container'>
                    {!props.submitSucceeded ? <>
                            <div className='compose-label'> To: </div>
                            <div className='compose-label'> From: {fromName} </div>
                            <form onSubmit={props.handleSubmit}>
                                <div className='compose-subject'>
                                    <label className='compose-label compose-subject-new-label'> Subject: </label>
                                    <Field name='subject' type='text' id='subject' component={renderSubject}/>
                                </div>
                                <Field className='compose-body-input-new-message' name='body' type='text' id='body'
                                       component={renderTextArea}/>
                                <div className='submit-button-round send-message'>
                                    <button className="btn btn-primary btn-block" id="visit-onboarding"
                                            type={"submit"}
                                            disabled={isSubmitDisabled(props)}>
                                        <FormattedMessage id='app.customer-portal-messaging.btn-1'/></button>
                                </div>
                            </form>
                        </> :
                        <div className='sent-messages-image-container'>
                            <div className='sent-messages-image-circle'>
                                <div className='sent-messages-image-icon'>
                                    <Icon name="messaging-sent"/>
                                </div>
                            </div>
                            <div className='sent-message-text'>
                                <FormattedMessage id='app.customer-portal-messaging.sent.sent-exclaim'/>
                            </div>
                        </div>
                    }
                </div>
    </>)
}




const mapStateToProps = (state) => {

    return {
        initialValues: {
            fromId: state.login.username

        }
    }
}


const sendMessageForm = reduxForm({
    form: 'MessageToSend',
    validate: ValidationService.validateMessageToSend,
    onSubmit: (values, dispatch, props) => {
        // console.log("MessageToSend Submit");
        // console.log("VALUES ", values);
        // console.log("PROPS ", props);
        return dispatch(createMessage(values))
    },
})(injectIntl(ActionPlanMessagesNew))

export default connect(mapStateToProps)(sendMessageForm)