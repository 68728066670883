import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'

export default function Spinner() {
  return (
    <div className='loading-icon'>
      <div className='loading-icon-rect'>
        <div className='loading-icon-text'>
          <FormattedMessage id='app.spinner.loading'/>
        </div>
        <div className='loading-circle-wrapper'>
          <div className='loading-circle-container'>
            <div className='loading-circle one'>
            </div>
          </div>
          <div className='loading-circle-container'>
            <div className='loading-circle two'>
            </div>
          </div>
          <div className='loading-circle-container'>
            <div className='loading-circle three'>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
