import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import FooterContainer from '../containers/footer-container'


import HeaderContainer from "../containers/header-container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CalendlyWidgetAdminHelper from "./helpers/CalendlyWidgetAdminHelper";

export default class AdminSchedule extends React.Component {

  constructor(props){
    super(props);
    this.onEventScheduled = this.onEventScheduled.bind(this);
    this.state = {adminScheduled: true};
  }

  componentDidMount() {
    if (this.props.activeCounselor === null) {
      this.props.history.push(`/coaches-calendly-form`)
    }
  }

  onEventScheduled(event) {
    this.props.getCalendlyFollowUpEvent(event.data.payload.event.uri, event.data.payload.invitee.uri)
    this.props.setCurrentCalendlyConfirmationPath('/schedule-confirmation')
  }

  goBack(){
    this.props.history.push(`/coaches-calendly-form?cid=${this.props.client && this.props.client.id}`)
  }

  render() {

    return (
      this.props.clientRetrieved && <div>
          <div>
            <HeaderContainer displayAlert={this.props.showAlert}
                             alertName={this.props.nameOfAlert}/>
            <main>
              <div className='container ptxxl'>
                <div className='container pick-a-coach-change-coach'>
                  <button onClick={()=>this.goBack()}>
                    <div className='pick-a-coach-back-wrapper'>
                      <div className='pick-a-coach-back-arrow'>
                        <FontAwesomeIcon icon={['fas', 'arrow-left']} aria-label='A back arrow' />
                      </div>
                      <div className='pick-a-coach-back-text'>
                        <FormattedMessage id='app.common.change-coach.button'/>
                      </div>
                    </div>
                  </button>
                </div>
                <CalendlyWidgetAdminHelper locale={this.props.locale}
                                      counselor={this.props.activeCounselor}
                                      client={this.props.client}
                                      onEventScheduled={this.onEventScheduled}
                                      landingPage={true}
                                      aria-label='A calendar widget to schedule an appointment'
                                      history={this.props.history}
                                      adminScheduled={this.state.adminScheduled}
                />

                <FooterContainer/>
              </div>
            </main>
          </div>
        }
      </div>)
  }
}
