import React , { useState } from 'react'
import FormattedMessage from './helpers/FormattedMessage'


export default function Footer(props) {
  const {intl}=props;
  let supportEmail = intl.formatMessage({id: 'app.common.email.support'});
  let supportEmailAction = 'mailto:' + supportEmail;
  let externalTermsOfSvc = 'https://' + intl.formatMessage({id: 'app.common.link.external.terms'});
  let externalPrivacy = 'https://' + intl.formatMessage({id: 'app.common.link.external.privacy'});

  const [constructorHasRun, setConstructorHasRun] = useState(false);

  const constructor = () => {
    if (constructorHasRun) return;
    //console.log("Run inline constructor() and test for employer image.");
    setConstructorHasRun(true);
  };

  const buildEmployerImage= (code)  => {
    var imageFile = "https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/Customer_Logos/" + code + ".png";
    var image = new Image();
    image.src = imageFile;


    if (image.width == 0) {
      return false;
    } else {
      return true;
    }
  }

  const employerImage= (code) => {
    return (
      <div className='company-logo-wrapper margin-bottom-24-px'>
        {buildEmployerImage(code) == true ? <img
          src={`https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/Customer_Logos/${props.employer.code}.png`}
          width="auto" height="auto"
          alt={`${props.employer.displayName}`}
          />
        :
        <h3>{props.employer.displayName}</h3> }
      </div>
    )
  }

  // run the constructor which fetches the employer image just once with this call
  constructor();

  return (
    <div className='footer'>
      <div className='container'>
        {
          props.employer && props.employer.id ?
            <div className='sponsor'>
              <p className='lg'>
                <FormattedMessage id='app.footer.sponsored-by'/>
              </p>
              { employerImage(props.employer.code) }
            <div>
              <br/>
                <p className='sml'>
                  <a target="_blank" href={externalTermsOfSvc} id='app-terms'>
                    <FormattedMessage id='app.footer.terms'/>
                  </a>
                </p>
                <p className='sml'>
                    <a target="_blank" href={externalPrivacy} id='app-privacy'>
                        <FormattedMessage id='app.footer.privacy'/>
                    </a>
                </p>
                <p className='sml'>
                  <FormattedMessage id='app.footer.support'/> <br/>
                  <a href={supportEmailAction}>
                    {supportEmail}
                  </a>
                </p>
              </div>
            </div> :

              <div className='sponsor'>
                  <div>
                      <br/>
                      <p className='sml'>
                          <a target="_blank" href={externalTermsOfSvc} id='app-terms'>
                              <FormattedMessage id='app.footer.terms'/>
                          </a>
                      </p>
                      <p className='sml'>
                          <a target="_blank" href={externalPrivacy} id='app-privacy'>
                              <FormattedMessage id='app.footer.privacy'/>
                          </a>
                      </p>
                      <p className='sml'>
                          <FormattedMessage id='app.footer.support'/> <br/>
                          <a href={supportEmailAction}>
                              {supportEmail}
                          </a>
                      </p>
                  </div>
              </div>
        }
      </div>
    </div>
  )
}
