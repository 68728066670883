import {ENGLISH_CURRENTLY_WORKING_COUNSELORS_RETURNED} from '../actions/counselor-actions'

function englishCurrentlyWorkingCounselorsReturned(state = false, action) {
    switch (action.type) {
        case ENGLISH_CURRENTLY_WORKING_COUNSELORS_RETURNED:
            return action.englishCurrentlyWorkingCounselorsReturned
        default:
            return state
    }
}

export default englishCurrentlyWorkingCounselorsReturned