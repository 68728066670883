import React from 'react'
import {intl} from 'react-intl'
import FormattedMessage from './helpers/FormattedMessage'
import BudgetExpenseField from './helpers/BudgetExpenseField'
import CounselorBubbleBudget from './helpers/CounselorBubbleBudget'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export default class BudgetExpenseForm extends React.Component {

  constructor(props){
    super(props);

    this.budgetExpenseFormRef = null;

    this.setBudgetExpenseFormRef = element => {
      this.budgetExpenseFormRef = element;
    };

    this.props.updateExpenseBudget(this.props.formTotalExp)

    this.props.updateExpenseBudgetFormPristine(true)
  }

  handleClick() {
    // https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11
    // without ie11, it would simply be:
    // this.budgetExpenseFormRef.dispatchEvent(new Event("submit"))

    let event;
    if(typeof(Event) === 'function') {
      // updates to bubble the event up to root, https://github.com/facebook/react/issues/20151#issuecomment-721000418
      event = new Event('submit', {cancelable: true, bubbles:true} );
    }else{
      event = document.createEvent('Event');
      event.initEvent('submit', true, true);
    }

    this.budgetExpenseFormRef.dispatchEvent(event);

    this.props.verifyToken()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.submitExpense != prevProps.submitExpense &&
      this.props.submitExpense === true) {
      this.handleClick();
    }

    if (this.props.formTotalExp != prevProps.formTotalExp && prevProps.formTotalExp != 0) {
      this.props.updateExpenseBudgetFormPristine(false)
      this.props.verifyToken()
    }

    if (this.props.formTotalExp != prevProps.formTotalExp) {
      this.props.updateExpenseBudget(this.props.formTotalExp)
    }
  }

  decrement(e, id, prop) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    if (_.isNaN(prop) || parseInt(prop) < 1) {
      // do nothing
    } else {
      if (parseInt(prop) - 25 > 0)
        this.props.change(id, parseInt(prop) - 25)
      else
        this.props.change(id, 0)
    }
  }

  increment(e, id, prop) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    this.props.change(id, (_.isNaN(parseInt(prop)) ? 0 : parseInt(prop)) + 25)
  }

  minusClass(prop) {
    if (_.isNaN(parseInt(prop)) || parseInt(prop) < 1) {
      return 'budget-minus-inactive'
    } else {
      return ''
    }
  }

  jarClass(prop) {
    if (_.isNaN(parseInt(prop)) || parseInt(prop) < 1) {
      return 'budget-jar-empty'
    } else if (parseInt(prop) < 500) {
      return 'budget-jar-some'
    } else {
      return 'budget-jar-more'
    }
  }

  render() {

    return (
      <form onSubmit={this.props.handleSubmit} ref={this.setBudgetExpenseFormRef}>
        <div className='budget-expense-form'>
          <div className='action-plan-sub-heading margin-bottom-16-px'>
            <FormattedMessage id='app.action-plan-budget.expense.title'/>
          </div>
          <div className='budget-expense-income-minus-title-wrapper '>
            <div className='budget-total-avg-wrapper margin-bottom-16-px'>
              <div className='budget-total-avg-heading'>
                <FormattedMessage id='app.action-plan-budget.income.total'/>
              </div>
              <div className='budget-total-avg-number'>
                {
                  _.isNaN(this.props.formTotalExp) ?
                      ''
                      : formatter.format(this.props.formTotalExp)
                }
              </div>
            </div>

            <div className='budget-expense-fields-wrapper'>

              <BudgetExpenseField name='home' id='home' i18n='app.action-plan-budget.expense.home'
                                  increment={(e) => this.increment(e, 'home', this.props.formHome)}
                                  decrement={(e) => this.decrement(e, 'home', this.props.formHome)}
                                  jar={this.jarClass(this.props.formHome)}
                                  minus={this.minusClass(this.props.formHome)}/>

              <BudgetExpenseField name='food' id='food' i18n='app.action-plan-budget.expense.food'
                                  increment={(e) => this.increment(e, 'food', this.props.formFood)}
                                  decrement={(e) => this.decrement(e, 'food', this.props.formFood)}
                                  jar={this.jarClass(this.props.formFood)}
                                  minus={this.minusClass(this.props.formFood)}/>

              <BudgetExpenseField name='utilities' id='utilities' i18n='app.action-plan-budget.expense.utilities'
                                  increment={(e) => this.increment(e, 'utilities', this.props.formUtilities)}
                                  decrement={(e) => this.decrement(e, 'utilities', this.props.formUtilities)}
                                  jar={this.jarClass(this.props.formUtilities)}
                                  minus={this.minusClass(this.props.formUtilities)}/>

              <BudgetExpenseField name='transportation' id='transportation' i18n='app.action-plan-budget.expense.transportation'
                                  increment={(e) => this.increment(e, 'transportation', this.props.formTransportation)}
                                  decrement={(e) => this.decrement(e, 'transportation', this.props.formTransportation)}
                                  jar={this.jarClass(this.props.formTransportation)}
                                  minus={this.minusClass(this.props.formTransportation)}/>

              <BudgetExpenseField name='entertainment' id='entertainment' i18n='app.action-plan-budget.expense.entertainment'
                                  increment={(e) => this.increment(e, 'entertainment', this.props.formEntertainment)}
                                  decrement={(e) => this.decrement(e, 'entertainment', this.props.formEntertainment)}
                                  jar={this.jarClass(this.props.formEntertainment)}
                                  minus={this.minusClass(this.props.formEntertainment)}/>

              <BudgetExpenseField name='educationChildCare' id='educationChildCare' i18n='app.action-plan-budget.expense.education-child-care'
                                  increment={(e) => this.increment(e, 'educationChildCare', this.props.formEducationChildCare)}
                                  decrement={(e) => this.decrement(e, 'educationChildCare', this.props.formEducationChildCare)}
                                  jar={this.jarClass(this.props.formEducationChildCare)}
                                  minus={this.minusClass(this.props.formEducationChildCare)}/>

              <BudgetExpenseField name='personalCare' id='personalCare' i18n='app.action-plan-budget.expense.personal-care'
                                  increment={(e) => this.increment(e, 'personalCare', this.props.formPersonalCare)}
                                  decrement={(e) => this.decrement(e, 'personalCare', this.props.formPersonalCare)}
                                  jar={this.jarClass(this.props.formPersonalCare)}
                                  minus={this.minusClass(this.props.formPersonalCare)}/>

              <BudgetExpenseField name='otherExpenses' id='otherExpenses' i18n='app.action-plan-budget.expense.other'
                                  increment={(e) => this.increment(e, 'otherExpenses', this.props.formOtherExpenses)}
                                  decrement={(e) => this.decrement(e, 'otherExpenses', this.props.formOtherExpenses)}
                                  jar={this.jarClass(this.props.formOtherExpenses)}
                                  minus={this.minusClass(this.props.formOtherExpenses)}/>

            </div>
          </div>

            <CounselorBubbleBudget i18n={'app.action-plan-budget.counselor.advice'}
                                   imageId={this.props.imageId}
                                   wrapper={'budget-advice-wrapper budget-advice-wrapper-expense'}/>
        </div>
      </form>
    )
  }
}
