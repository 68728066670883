import {RESET_TEMPORARY_EMAIL, SET_TEMPORARY_EMAIL} from '../actions/b-flow-actions'

const temporaryEmail = (state = null, action) => {
    switch (action.type) {
        case SET_TEMPORARY_EMAIL:
            return action.temporaryEmail
        case RESET_TEMPORARY_EMAIL:
            return action.temporaryEmail
        default:
            return state
    }
}

export default temporaryEmail
