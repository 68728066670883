import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CalendlyWidgetHelper from "../helpers/CalendlyWidgetHelper";
import {useDispatch, useSelector} from "react-redux";
import {calendlyEvent} from "../../actions/calendly-actions";
import {verifyToken} from "../../actions/login-actions";
import {useHistory} from "react-router-dom";
import ActionPlanNav from "./action-plan-nav";

export default function ActionPlanAppointmentSchedule(props) {

  const locale = useSelector((state) => state.locale)
  const login = useSelector((state) => state.login)
  const registration = useSelector((state) => state.registration)
  const activeCounselor = useSelector((state) =>state.activeCounselor)

  const dispatch = useDispatch();

  const history = useHistory();

  const onEventScheduled = (event) => {
    dispatch(calendlyEvent(event.data.payload.event.uri, event.data.payload.invitee.uri))
    history.push("/action-plan/sessions/appointment-confirmation")
    dispatch(verifyToken(login))
  };

  const goToPickCoach = () => {
    history.push("/action-plan/sessions/pick-your-coach")
    dispatch(verifyToken(login))
  };

  return (
      <div id='ap-sessions'>
        <ActionPlanNav />
        <div id='action-plan-content'>
          <main>
            <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title-background-gradient'></div>
              <h1 className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.action-plan-sessions.page-title'/>
              </h1>
            </div>
            <div className='action-plan-subtitle margin-bottom-24-px'>
              <FormattedMessage id='app.appointment.title'/>
            </div>
            <div className='pick-a-coach-change-coach'>
              <button onClick={()=>goToPickCoach()}>
                <div className='pick-a-coach-back-wrapper'>
                  <div className='pick-a-coach-back-arrow'>
                    <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                  </div>
                  <div className='pick-a-coach-back-text'>
                    <FormattedMessage id='app.common.change-coach.button'/>
                  </div>
                </div>
              </button>
            </div>

            <CalendlyWidgetHelper locale={locale}
                                  counselor={activeCounselor}
                                  registration={registration}
                                  onEventScheduled={onEventScheduled}
            />
          </main>
        </div>
      </div>)

};