import React from 'react'

export const DclDashboard = ({
                           style = {},
                           fill = '#fff',
                           width = '100%',
                           className = '',
                           height = '100%',
                           viewBox = '0 0 420 435',
                       }) =>
    <svg
        width="60"
        height="61"
        viewBox="0 0 60 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M17.14 44.69a1.058 1.058 0 0 1-1.058-1.06v-3.872l4.68 4.933H17.14v-.002z" fill="#141006"
              stroke="#141006" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M25.92 44.69h-5.159l-4.679-4.934V20.402c0-.585.474-1.058 1.058-1.058H32.29c.584 0 1.058.473 1.058 1.058v5.997"
            stroke="#141006" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.154 24.453h11.12M19.154 30.844h9.173M19.154 37.234h8.071" stroke="#141006" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M29.11 42.665c.01-.481.082-.949.21-1.392.06-.14.118-.276.176-.416l.933-1.631 2.426-4.203h3.396l2.42 4.191.914 1.593c.077.187.152.37.23.554.122.453.186.928.186 1.42 0 2.979-2.391 4.02-5.359 4.067-3.075.048-5.596-1.108-5.531-4.183z"
            fill="#141006" stroke="#141006" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M37.65 31.753c-.782.678-1.981 2.534-1.414 3.262.024.03-.085-.017-.2-.01-.385.022-1.261.006-1.271.006 0 0-1.004.039-1.529.023-.18-.005-.393.014-.367-.018.568-.727-.627-2.585-1.41-3.263-.36-.311-.08-.902.388-.82.72.127 1.437.597 1.879.934.188-.514.498-1.177.955-1.623.34-.333.633.02.49.694a5.11 5.11 0 0 0-.082 1.168c.346-.295 1.258-1.011 2.173-1.173.469-.082.75.509.389.82z"
            fill="#141006" stroke="#141006" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M37.112 34.563h-5.206c-.337 0-.492.194-.492.433v.002c0 .24.155.433.492.433h5.206c.337 0 .497-.194.497-.433v-.002c0-.24-.16-.434-.497-.434zM36.316 39.691c-.415-.3-.849-.518-1.28-.613v1.809c1.43.357 2.043.923 2.043 1.93v.02c0 1.026-.809 1.714-2.006 1.826v.922h-.82v-.942a4.342 4.342 0 0 1-2.222-.933l.603-.866c.536.442 1.045.725 1.657.829v-1.855c-1.374-.358-2.014-.866-2.014-1.93v-.02c0-1.007.8-1.695 1.977-1.799v-.546h.819v.566c.677.084 1.242.33 1.77.715l-.528.885v.002zm-2.025 1v-1.668c-.603.057-.903.368-.903.782v.01c0 .395.179.65.903.876zm.744 1.307v1.714c.603-.067.933-.357.933-.809v-.02c0-.414-.208-.669-.933-.885z"
            fill="#fff"/>
    </svg>

export default DclDashboard