import React , { useState } from 'react'
import FormattedMessage from "../helpers/FormattedMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function DisputeOffline(props) {

  return (
    <div>
      <div className='recommendations-gradient-color'>
        <div className='top-priority'>
          <FormattedMessage id='app.debt-report-recommendations.top-priority'/>
        </div>
        <h2>
          <FormattedMessage id='app.debt-report-recommendations.collections'/>
        </h2>
      </div>

      <div className='recommendations-page-wrapper'>
        <div className='work-on-this-page-container'>
          <div className='action-plan-subtitle margin-bottom-24-px'>
            <FormattedMessage id='app.debt-report-reviewed-collections.dispute-offline.title'/>
          </div>

          <p>
            <FormattedMessage id='app.debt-report-reviewed-collections.dispute-offline.text-1'/>
          </p>

          <div className='collections-session-scheduled flex'>
            <div className='collections-session-schedule-icon-circle'>
              <FontAwesomeIcon icon={['fas', 'circle']} aria-label='An icon of a circle' />
              <div className='collections-session-scheduled-icon'>
                <FontAwesomeIcon icon={['far', 'calendar-alt']} aria-label='An icon of a calendar on a circle' />
              </div>
            </div>
            <div className='collections-session-scheduled-text'>
                  <span className='bold'>
                    <FormattedMessage id='app.debt-report-reviewed-collections.text-4'/>
                  </span>
              <br />
              {props.firstBookedAppointmentStr}
            </div>
          </div>
          {/*<div className='submit-button-round sixty-percent-btn'>*/}
          {/*  <button className='btn btn-primary btn-block btn-work-on-this'*/}
          {/*          id='debt-report-reviewed-collections-yes'*/}
          {/*          onClick={()=>props.nextPage()}>*/}
          {/*    <FormattedMessage id='app.common.continue.button'/>*/}
          {/*  </button>*/}
          {/*</div>*/}

        </div>
      </div>
    </div>

  )
}