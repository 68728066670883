import React, {useEffect} from 'react'

import HeaderContainer from '../../containers/header-container'
import FooterContainer from "../../containers/footer-container";
import {useSelector} from "react-redux";

export default function BFlowCalendlyConfirmation() {

    const login = useSelector(state => state.login);
    const registration = useSelector(state => state.registration);
    const showAlert = useSelector(state => state.displayAlert);
    const nameOfAlert = useSelector(state => state.alertName);

    useEffect(() => {
        if(login === null || registration === null){
          this.props.history.push(`/enter-your-email-address`)
        }
        else {
          this.props.history.push(`/${this.props.currentCalendlyConfirmationPath}`)
        }
    })

    return (

      <div id='schedule-confirmation'>
        <HeaderContainer displayAlert={showAlert}
                         alertName={nameOfAlert}/>

        <div className='container ptxxl'>

          <div className='action-plan-subtitle margin-bottom-16-px'>

          </div>

        </div>
        <div className='container'></div>
        <FooterContainer/>
      </div>
    )
}

