import React, {useEffect, useState} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import Icon from '../../../resources/icons'
import TakeActionTodayPendingFormContainer from '../../containers/take-action-today-pending-form-container'
import TakeActionTodayAchievedRow from '../take-action-today-achieved-row'
import TakeActionTodayCreateForm from '../../containers/take-action-today-create-form-container'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link, useHistory} from 'react-router-dom'
import FooterActionPlan from '../footer-action-plan'
import {useDispatch, useSelector} from "react-redux";
import { useRef } from 'react';
import {
  getAchievedTakeActionToday,
  getPendingTakeActionToday,
  takeActionTodayAchievedDivHeight,
  setAchievedTakeActionToday
} from "../../actions/take-action-today-actions";
import {verifyToken} from "../../actions/login-actions";
import ActionPlanNav from "./action-plan-nav";
import {displayLinkMoneyModal} from "../../actions/link-money-actions";

const treeHeight = 371

export default function ActionPlanTakeActionToday(props) {

  const login = useSelector(state => state.login)
  const registration = useSelector(state => state.registration)
  const locale = useSelector(state => state.locale)
  const pendingTakeActionToday = useSelector(state => state.pendingTakeActionToday)
  const achievedTakeActionToday = useSelector(state => state.achievedTakeActionToday)
  const achievedDivHeight = useSelector(state => state.takeActionTodayAchievedDivHeight)
  // const markedAchievedTakeActionToday = useSelector(state => state.markedAchievedTakeActionToday)
  // const updatedPendingTakeActionToday = useSelector(state => state.updatedPendingTakeActionToday)

  const divElementRef = useRef(null);
  const divElementPlantRef = useRef(null);

  const [pendingTatChanges, setPendingTatChanges] = useState(false);
  const [updateAllItems, setUpdateAllItems] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [lastItem, setLastItem] = useState(false);

  const dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(displayLinkMoneyModal(false))
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      removeStyles()
      checkPlantPlacement()
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function isScrolledIntoView(el) {
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;

    // Only completely visible elements return true:
    return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  }

  function checkPlantPlacement() {
    const height = divElementRef.current.clientHeight;

    if (height >= treeHeight) {
      setPlantToBottom()
    } else {
      setPlantToTop()
    }

    return height;
  }

  function setPlantToTop() {
    divElementPlantRef.current.style.top = '0px'
  }

  function setPlantToBottom() {
    divElementPlantRef.current.style.bottom = '-5px'
  }

  function removeStyles() {
    divElementPlantRef.current.style.top = 0
    divElementPlantRef.current.style.bottom = 0
  }

  useEffect(() => {
    dispatch(getPendingTakeActionToday(registration.id))
    dispatch(getAchievedTakeActionToday(registration.id))
  }, []);

  useEffect(() => {
    if(divElementRef.current) {
      dispatch(takeActionTodayAchievedDivHeight(checkPlantPlacement()))
    }

    if (divElementPlantRef.current && isScrolledIntoView(divElementPlantRef.current) === false) {
      removeStyles()
      setPlantToTop()
    }
  }, [pendingTakeActionToday, achievedTakeActionToday]);

  useEffect(() => {
    dispatch(getPendingTakeActionToday(registration.id))
    dispatch(getAchievedTakeActionToday(registration.id))
    setUpdateAllItems(false)
    setPendingTatChanges(false)
  }, []);

  function onCreateClick(e) {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()

    history.push('/action-plan/take-action-today/create')
    dispatch(verifyToken(login))
    setUpdateAllItems(true)
  }

  function handleUpdatedStatus(updatedItem) {
    handleRemovedItem(updatedItem);
  }

  function handleRemovedItem(updatedItem) {
    dispatch(setAchievedTakeActionToday(updatedItem.id)).then(() => {
      dispatch(getAchievedTakeActionToday(registration.id)).then(() => {
        setPendingTatChanges(true)
      })
    })
    setUpdateAllItems(true)
  }

  return (
    pendingTakeActionToday && achievedTakeActionToday
      &&  <div id='ap-take-action-today' >
      <ActionPlanNav />
      <div id='action-plan-content'>

        <div className='action-plan-title-wrapper margin-bottom-24-px'>
          <div className='action-plan-title-background-gradient'></div>
          <h1 className='action-plan-title margin-bottom-8-px'>
            <FormattedMessage id='app.action-plan-take-action-today.page-title'/>
          </h1>
        </div>

        { props.currentComponent === 'TakeActionTodayForm' ?
          <div className='take-action-today-create-container margin-bottom-48-px'>
             <TakeActionTodayCreateForm
              key={"TakeActionTodayCreateForm"}
              history={history}
              onCancelClick={() => history.push('/action-plan/take-action-today')}
             />
          </div>
        : '' }

        { props.currentComponent === 'TakeActionTodayOverview' ?
          <div className='take-action-today-to-do-container margin-bottom-24-px'>
            <div className='action-plan-sub-heading take-action-today-heading margin-bottom-8-px'>
              <FormattedMessage id='app.action-plan-take-action-today.to-do'/>
            </div>

            {
              pendingTakeActionToday[0] ? pendingTakeActionToday.map((item) => {
                    return <TakeActionTodayPendingFormContainer
                      key={item.id}
                      item={item}
                      locale={locale}
                      handleUpdatedStatus={(item) => handleUpdatedStatus(item)}/>
                  })

              : ''
            }

            {
              pendingTakeActionToday.length < 1 ? <div className='take-action-today-to-do-wrapper margin-bottom-24-px'>
                  <div className='take-action-today-item-wrapper'>
                    <div className='take-action-today-sub-heading margin-bottom-4-px'>
                      <FormattedMessage id='app.action-plan-take-action-today.no-current-to-do-items'/>
                    </div>
                  </div>
                </div> : ''
              }

          </div>
        : '' }

        { props.currentComponent === 'TakeActionTodayOverview' ?
            <div className='margin-bottom-48-px'>
              <Link to='' onClick={e => onCreateClick(e) } class='take-action-today-create-link-container'>
                <div className='take-action-today-create-plus-icon'>
                  <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                </div>
                <div className='take-action-today-create-link take-action-today-sub-heading take-action-today-sub-heading-link'>
                  <FormattedMessage id='app.action-plan-take-action-today.create'/>
                </div>
              </Link>
            </div>
        : '' }

        { props.currentComponent === 'TakeActionTodayOverview' ?
          <div className='take-action-today-achieved-container'>
            <div className='action-plan-sub-heading take-action-today-heading margin-bottom-8-px'>
              <FormattedMessage id='app.action-plan-take-action-today.achieved'/>
            </div>

            <div className='take-action-today-achieved-wrapper'
                 ref={divElementRef}></div>

              {
                achievedTakeActionToday[0] ?
                  achievedTakeActionToday.map((item) => {
                    var last = (item.id ===  achievedTakeActionToday[achievedTakeActionToday.length - 1].id);
                    console.log("In the set of achieved items");
                    return <TakeActionTodayAchievedRow
                      key={item.id}
                      item={item}
                      locale={locale}
                      lastItem={last}
                      />
                  })
                  :
                  <div className='take-action-today-achieved-wrapper'>
                    <div className='take-action-today-item-wrapper'>
                      <div className='take-action-today-sub-heading margin-bottom-4-px'>
                        <FormattedMessage id='app.action-plan-take-action-today.no-completed-items'/>
                      </div>
                    </div>
                  </div>
              }

              <div className='last-column-3'>
                <div className='achieved-plant-wrapper-desktop' style={{"height" : achievedDivHeight}}>
                  <div className='achieved-plant-svg-desktop' ref={divElementPlantRef} >
                    <Icon name="achieved-plant-desktop"/>
                  </div>
                </div>
              </div>

            </div>

        : ''}

      <FooterActionPlan/>

      </div>
    </div>
  )
}
