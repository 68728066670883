import {GET_DEBT_REPORT} from '../actions/debt-report-actions'

const debtReport = (state = null, action) => {
  switch (action.type) {
    case GET_DEBT_REPORT:
      return action.debtReport
    default:
      return state
  }
}

export default debtReport
