import React, {useState} from 'react';

import FormattedMessage from '../helpers/FormattedMessage'
import {Link} from "react-scroll";

export default function ResendCodeModal(props) {

    const [displayModal, setDisplayModal] = useState(true)

    return (
        <div className="modal-wrapper">
            <div className="modal-body" style={
                {
                    transform: displayModal ? 'none' : 'translateY(-100vh)',
                    opacity: displayModal ? '1' : '0',
                    display: displayModal ? 'block' : 'none'
                }}>
                <div className='resend-code-modal-wrapper'>
                    <div className='resend-code-modal-box'>
                        <FormattedMessage id='app.b-flow.temp-code-form.modal.title'/>
                        <Link to="/resend-temp-code" className='back-link' onClick={(e) => props.resend(e)}
                              id='resend'>
                            <FormattedMessage id='app.b-flow.temp-code-form.modal.link'/>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )

}