import React from 'react'

export const NavComponentIcons = {
  'ActionPlanTakeActionToday': 'clipboard',
  'ActionPlanDebtSummary': 'chartpie',
  'ActionPlanFicoScore': 'tachometer',
  'ActionPlanSessions' : 'calendar',
  'ActionPlanBudget': 'money-bill',

}


export default NavComponentIcons
