import React, {useEffect} from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import CustomerPortalFooter  from './customer-portal-footer';
import {injectIntl} from "react-intl";

import {useDispatch, useSelector} from "react-redux";
import CustomerPortalNav from "./customer-portal-nav";
import {useHistory} from "react-router-dom";
import CustomerPortalContact from "./customer-portal-contact";


function CustomerPortalContractLanding(props) {

  const edsContract = useSelector(state => state.edsContract)

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect( () => {
    window.scrollTo(0, 0)
  })

  const goToAboutPage = () => {
      history.push('/customer-portal/contracts/about')
    }

    const goToMyAccount = () => {
      history.push('/customer-portal/my-account')
    }

    const hasPendingContract = () => {
      return edsContract && edsContract.status === 'Pending' ? <div>
        <h2><FormattedMessage id='app.customer-portal-contracts.heading-1'/></h2>
        <div className='contract-content-wrapper'>
          <div className='content-text'>
              <FormattedMessage id='app.customer-portal-contracts.text-1a'/>
          </div>
          <div className='content-text header'>
              <FormattedMessage id='app.customer-portal-contracts.text-1b'/>
          </div>
          <div className='submit-button-round visit-onboarding'>
            <button
              className="btn btn-primary btn-block"
              id="visit-onboarding"
              onClick={goToAboutPage}>
              <FormattedMessage id='app.customer-portal-contracts.btn-1'/>
            </button>
          </div>
        </div>

      </div> : null
    }

    const hasCompletedContract = () => {
      return edsContract && edsContract.status === 'Complete' ? <div>
        <h2><FormattedMessage id='app.customer-portal-contracts.heading-3'/></h2>
          <div className='contract-content-wrapper'>
            <div className='content-text'>
              <FormattedMessage id='app.customer-portal-contracts.text-3a'/>
            </div>
            <div className='content-text'>
              <FormattedMessage id='app.customer-portal-contracts.text-3b'/>
            </div>
            <div className='submit-button-round visit-onboarding'>
              <button
                className="btn btn-primary btn-block"
                id="visit-onboarding"
                onClick={goToMyAccount}>
                <FormattedMessage id='app.customer-portal-contracts.btn-3'/></button>
            </div>
          </div>
      </div> : null
    }

    const hasNoContract = () => {
      return edsContract ?  null
        :
        <div>
          <div>There are no contracts for you to review.</div>
        </div>

    }

    return(
      <div id='customer-portal'>
        <CustomerPortalNav />
        <div id='customer-portal-content'>
          <div id='customer-portal-contracts' className='customer-portal-pages'>

            <div className='action-plan-title-wrapper margin-bottom-24-px'>
              <div className='action-plan-title-background-gradient'></div>
              <div className='action-plan-title margin-bottom-8-px'>
                <FormattedMessage id='app.customer-portal.contracts-header'/>
              </div>
            </div>

            <div className='customer-portal-dashboard-container'>

              {hasNoContract()}
              {hasPendingContract()}
              {hasCompletedContract()}

              <CustomerPortalFooter />
              <CustomerPortalContact />

            </div>
          </div>
        </div>
      </div>
    )
}

export default (injectIntl(CustomerPortalContractLanding))