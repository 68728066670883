export const PRESCREENER_ANSWER_1 = 'PRESCREENER_ANSWER_1'
export const PRESCREENER_ANSWER_2 = 'PRESCREENER_ANSWER_2'
export const PRESCREENER_ANSWER_3 = 'PRESCREENER_ANSWER_3'
export const UPDATE_ONBOARDING_PRODUCT_NAME = 'UPDATE_ONBOARDING_PRODUCT_NAME'
export const UPDATE_ONBOARDING_PRODUCT_STATUS = 'UPDATE_ONBOARDING_PRODUCT_STATUS'

export function submitPrescreenerAnswer1(val) {
  let finalVal = val
  return {
    type: PRESCREENER_ANSWER_1,
    dcl: {
      prescreenerAnswer1: finalVal
    }
  }
}

export function submitPrescreenerAnswer2(val) {
  let finalVal = val
  return {
    type: PRESCREENER_ANSWER_2,
    dcl: {
      prescreenerAnswer2: finalVal
    }
  }
}

export function submitPrescreenerAnswer3(val) {
  let finalVal = val
  return {
    type: PRESCREENER_ANSWER_3,
    dcl: {
      prescreenerAnswer3: finalVal
    }
  }
}

export function updateOnboardingProductName(val) {
  return {
    type: UPDATE_ONBOARDING_PRODUCT_NAME,
    dcl: {
      onboardingProductName: val
    }
  }
}

export function updateOnboardingProductStatus(val) {
  return {
    type: UPDATE_ONBOARDING_PRODUCT_STATUS,
    dcl: {
      onboardingProductStatus: val
    }
  }
}