import React from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import FooterContainer from '../containers/footer-container'
import HeaderContainer from "../containers/header-container";
import PickACoachBlock from "./pick-a-coach-block";
import PickACoachLearnMoreModal from "./modals/pick-a-coach-learn-more-modal";

export default class AdminPickACoach extends React.Component {

  constructor(props){
    super(props);

    this.state={
      displayLearnMore: false,
      selectedCounselor:'',
    }
  }

  selectCounselor(counselor) {
    this.props.setActiveCounselor(counselor)
    this.props.history.push(`/coaches-calendly-schedule`)
  }

  buildCoachBlock(){
    return this.props.activeCounselors.map(counselor => {
      return (
        <PickACoachBlock counselor={counselor}
                         key={counselor.contactId}
                         locale={this.props.locale}
                         employer={this.props.employer}
                         selectCounselor={(counselor)=>this.selectCounselor(counselor)}
                         openLearnMore={(counselor)=>this.openLearnMoreModal(counselor)}
                         closeLearnMore={()=>this.closeLearnMoreModal}/>
      )
    })
  }

  openLearnMoreModal(counselor){
    this.setState({
      displayLearnMore: true,
      selectedCounselor: counselor
    })
  }

  closeLearnMoreModal(){
    this.setState({
      displayLearnMore: false,
      selectedCounselor: ''
    })
  }

  render() {

    return (
      <div>
          <div>
            {this.state.displayLearnMore ? <div onClick={() => this.closeLearnMoreModal()} className="back-drop"></div> : null }

            <HeaderContainer displayAlert={this.props.showAlert}
                             alertName={this.props.nameOfAlert}/>

            {this.state.displayLearnMore ?
              <PickACoachLearnMoreModal counselor={this.state.selectedCounselor}
                                        locale={this.props.locale}
                                        closeModal={()=> this.closeLearnMoreModal()}
                                        displayModal={this.state.displayLearnMore}
                                        selectCounselor={(counselor)=>this.selectCounselor(counselor)}/>
              : ''
            }

            <div className='container ptxxl'>
              <h1 className='action-plan-subtitle margin-bottom-24-px'>
                <FormattedMessage id='app.appointment.title'/>
              </h1>
            </div>
            <div className='container'>
              <div className='pick-a-counselor-container'>
                {this.props.activeCounselors && this.props.activeCounselors.length > 0 ? this.buildCoachBlock() : ''}
              </div>
            </div>

            <FooterContainer/>
          </div>
      </div>)
  }
}
