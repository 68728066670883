import {ACTIVE_COUNSELOR} from "../actions/counselor-actions"

const activeCounselor = (state = null, action) => {
  switch (action.type) {
    case ACTIVE_COUNSELOR:
      return action.activeCounselor
    default:
      return state
  }
}

export default activeCounselor