import axios from 'axios'
import moment from 'moment'
import {SubmissionError} from 'redux-form'

export const GET_PENDING_TAKE_ACTION_TODAY = 'GET_PENDING_TAKE_ACTION_TODAY'
export const GET_ACHIEVED_TAKE_ACTION_TODAY = 'GET_ACHIEVED_TAKE_ACTION_TODAY'
export const TAKE_ACTION_TODAY_ACHIEVED_DIV_HEIGHT = 'TAKE_ACTION_TODAY_ACHIEVED_DIV_HEIGHT'
export const MARKED_ACHIEVED_TAKE_ACTION_TODAY = 'MARKED_ACHIEVED_TAKE_ACTION_TODAY'
export const UPDATED_PENDING_TAKE_ACTION_TODAY = 'UPDATED_PENDING_TAKE_ACTION_TODAY'
export const COLLECTION_TAKE_ACTION_TODAY_CREATED = 'COLLECTION_TAKE_ACTION_TODAY_CREATED'

export const createTakeActionToday = data => {
    return (dispatch, getState) => {
      const createTakeActionToday = Object.assign({}, data)

      createTakeActionToday.clientId = getState().registration.id
      createTakeActionToday.clientEmail = getState().registration.email
      createTakeActionToday.clientPhone = getState().registration.mobilePhone
      createTakeActionToday.tatGoalStatus = "Pending"
      createTakeActionToday.tatGoalPurpose = "Manage My Debt"
      createTakeActionToday.tatClientProposed = true;
      createTakeActionToday.tatGoalDueDate = moment(createTakeActionToday.tatGoalDueDate).format('YYYY-MM-DD')

      return axios
        .post('/api/createTakeActionToday', createTakeActionToday)
        .then((response) => {})
        .catch(error => {
          throw new SubmissionError({_error: error.response.data.message})
        })
    }
}

export function createCollectionTAT() {
    return (dispatch, getState) => {
    const tat = Object.assign({})
    tat.clientId = getState().registration.id
    tat.clientEmail = getState().registration.email
    tat.clientPhone = getState().registration.mobilePhone
    tat.dateOfEntry = moment(new Date(), "YYYY-MM-DD")
    tat.tatGoalDueDate = moment(new Date(), "YYYY-MM-DD").add(3, 'days')
    tat.tatGoalStatus = "Pending"
    tat.tatClientProposed = true
    tat.tatGoalComment = "Work on the collection account from the TrustPlus app"
    tat.customReminder = "Hi, it's your TrustPlus coach. Remember to work on the collections account from your credit report."
    tat.exclude = "No"

    return axios
      .post('/api/createTakeActionToday', tat)
      .then((response) => {})
      .catch(error => {
        throw new SubmissionError({_error: error.response.data.message})
      })
  }
}

export function takeActionTodayAchievedDivHeight(height) {
  let entered = height
  return {
    type: TAKE_ACTION_TODAY_ACHIEVED_DIV_HEIGHT,
    takeActionTodayAchievedDivHeight: entered
  }
}

export function getPendingTakeActionToday() {
    return (dispatch, getState) => {
        let id = getState().login.contactId

        return axios
            .get('/api/get-take-action-today-pending/{id}', {params: {id}})
            .then(response => {
            dispatch({
                type: GET_PENDING_TAKE_ACTION_TODAY,
                pendingTakeActionToday: response.data
            })
        })
    .catch(() => {})
    }
}

export function getAchievedTakeActionToday() {
    return (dispatch, getState) => {
        let id = getState().login.contactId

        return axios
            .get('/api/get-take-action-today-achieved/{id}', {params: {id}})
            .then(response => {
            dispatch({
                type: GET_ACHIEVED_TAKE_ACTION_TODAY,
                achievedTakeActionToday: response.data
            })
        })
    .catch(() => {})
    }
}

export function setAchievedTakeActionToday(id) {

  return (dispatch, getState) => {

    return axios
        .patch('/api/updateTakeActionToday',
          { 
            id: id,
            tatGoalStatus: 'Achieved',
            tatDateAchieved: moment().format()
          })
        .then((response) => {})
        .catch(error => {
            throw new SubmissionError({_error: error.response.data.message})
        })
  }
}

export function markCollectionTATCreated() {
    return {
        type: COLLECTION_TAKE_ACTION_TODAY_CREATED,
        collectionTATCreated: true
    }
}

