import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {reduxForm} from 'redux-form'

import {getFormSyncErrors/*, getFormMeta*/} from 'redux-form'
import {postgresPasswordReset} from '../actions/login-actions'
import PostgresResetPasswordPage from '../components/postgres-reset-password-page'

function mapStateToProps(state) {
    return {
        formSyncErrors: getFormSyncErrors('login')(state),
        login: state.login,
    }
}

const PostgresResetPasswordPageContainer = connect(
    mapStateToProps,
)(reduxForm({
        form: 'PostgresResetPasswordPage',
        onSubmit: (values, dispatch, props) => {
            return dispatch(postgresPasswordReset(values))
        },
        destroyOnUnmount: false
    })(injectIntl(PostgresResetPasswordPage))
)

export default PostgresResetPasswordPageContainer;

