import React, {useEffect, useState} from 'react'

import FormattedMessage from '.././helpers/FormattedMessage'
import HeaderContainerAP from '../../containers/header-container-ap'
import FooterContainerAp from '../../containers/footer-container-ap'
import {reduxForm} from "redux-form";
import {injectIntl} from "react-intl";
import {passwordReset} from "../../actions/b-flow-actions";
import {useSelector} from "react-redux";

function BFlowForgotPasswordForm(props) {

    const [submitted, setSubmitted] = useState(false);

    const login = useSelector(state => state.login);
    const registration = useSelector(state => state.registration);

    useEffect(() => {
        if (login === null || registration === null) {
            props.history.push(`/enter-your-email-address`);
        }
    })

    const handleSubmit = () => {
        setSubmitted(true);
        props.handleSubmit();
    }

    return (
        <div className='b-flow b-flow-background'>
            <HeaderContainerAP/>
            <div className='b-flow-body'>

                <div className='container-ap forgotpassword-form'>
                    {
                        submitted ?
                            <div>
                                <h1>
                                    <FormattedMessage id='app.b-flow.forgot-password-form.instruction2'/>
                                </h1>
                            </div>
                            :
                            <div>
                                <h1>
                                    <FormattedMessage id='app.b-flow.forgot-password-form.title'/>
                                    <br/>
                                    <FormattedMessage id='app.b-flow.forgot-password-form.instruction'/>
                                </h1>

                                <form onSubmit={handleSubmit}>
                                    <div className='submit-button-round margin-bottom-24-px'>
                                        <button id='submit'
                                                type={'submit'}
                                                className='btn btn-red'>
                                            <FormattedMessage id='app.b-flow.forgot-password-form.send.button'/>
                                        </button>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
            <FooterContainerAp/>
            </div>
        </div>)
}

function goToNextPage(props) {
    props.history.push(`/enter-one-time-code`);
}

export default reduxForm({
    form: 'BFlowForgotPasswordForm',
    onSubmit: (values, dispatch, props) => {
        return dispatch(passwordReset(values)).then(() => {
            // goToNextPage(props)
        })
    },
    destroyOnUnmount: false
})(injectIntl(BFlowForgotPasswordForm))