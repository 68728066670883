import React, {useEffect} from 'react'
import {
  getBookedAppointments, getCalendlyRoundRobinUrl, getCalendlyRoundRobinUrlSpanish,
  getCompletedAttendedAppointments,
  setCurrentCalendlyConfirmationPath
} from "../../actions/calendly-actions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {Route, Switch} from "react-router";
import ActionPlanTermsOfServiceContainer from "../../containers/action-plan/action-plan-terms-of-service-container";
import ActionPlanPrivacyPolicyContainer from "../../containers/action-plan/action-plan-privacy-policy-container";
import ActionPlanFeedbackContainer from "../../containers/action-plan/action-plan-feedback-container";
import ActionPlanMyAccountContainer from "../../containers/action-plan/action-plan-my-account-container";
import ActionPlanFicoScore from "./action-plan-fico-score";
import ActionPlanSessions from "./action-plan-sessions";
import ActionPlanAppointmentSchedule from "./action-plan-appointment-schedule";
import ActionPlanAppointmentConfirmation from "./action-plan-appointment-confirmation";
import ActionPlanPickCoach from "./action-plan-pick-coach";
import ActionPlanDashboard from "./action-plan-dashboard";
import ActionPlanTakeActionToday from "./action-plan-take-action-today";
import ActionPlanDebtSummary from "./action-plan-debt-summary";
import ActionPlanBudget from "./action-plan-budget";
import ActionPlanMessages from "./action-plan-messages";
import ActionPlanSelectByAvailibility from "./action-plan-select-by-availibility";
import ActionPlanLinkMoneyGateway from "./action-plan-link-money-gateway";
import ActionPlanLinkMoneyConfirmation from "./action-plan-link-money-confirmation";
import ActionPlanLinkMoneyLanding from "./action-plan-link-money-landing";
import ActionPlanLinkMoneyRedirect from "./action-plan-link-money-redirect";
import {logout} from "../../actions/logout-actions";
import {expenseBudgetFormPristine, incomeBudgetFormPristine} from "../../actions/budget-actions";
import {displayTimeoutModal} from "../../actions/display-timeout-action";
import {getAllActiveEmployersWithSSO, getEmployerFromContactId} from "../../actions/employer-actions";
import {
  doesContactHaveCreditReportOnFile,
} from "../../actions/trust-plus-credit-actions";

import {employerLoadedForNav} from "../../actions/nav-actions";
import {
  getEnglishCurrentlyWorkingCounselors,
  getEnglishSpeakers, getSpanishCurrentlyWorkingCounselors,
  getSpanishSpeakers, setActiveCounselors, setActiveCurrentlyWorkingCounselors, setRandomCounselor
} from "../../actions/counselor-actions";
import {getContact, reGetContact} from "../../actions/registration-actions";
import ActionPlanLinkMoneyCategoryChart from "./action-plan-link-money-category-chart";
import ActionPlanLinkMoneyAccounts from "./action-plan-link-money-accounts";
import ActionPlanLinkMoneyTransactions from "./action-plan-link-money-transactions";

export default function ActionPlan() {

  const login = useSelector(state => state.login)
  const locale = useSelector(state => state.locale)
  const registration = useSelector(state => state.registration)
  const activeCounselors = useSelector((state) =>state.activeCounselors)

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    dispatch(setCurrentCalendlyConfirmationPath("/action-plan"))
  }, [])

  function timeout() {
    dispatch(displayTimeoutModal(true))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      clearInterval(interval);
      dispatch(incomeBudgetFormPristine(true))
      dispatch(expenseBudgetFormPristine(true))
      setTimeout(timeout, 2000);
    }, 900000);

    return () => {
      clearInterval(interval);
    };
  }, [login]);

  useEffect(() => {
    const intervalID = setInterval(() =>  {
      dispatch(reGetContact())
      clearInterval(intervalID)
    }, 3000);

    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    if (login === null && registration === null) {
      history.push(`/welcome`)
      window.location.reload()
    }
    else if (login.isCustomersContact === true) {
      // history.push(`/customer-portal`)
      history.push(`/action-plan`)
    }
  }, [login, registration])

  useEffect(()=>{
    if (login) {
      dispatch(getBookedAppointments()).then(() => {
        dispatch(getCompletedAttendedAppointments()).then(() => {
          dispatch(getEmployerFromContactId(login.contactId)).then(() => {
            dispatch(employerLoadedForNav(true))
            dispatch(doesContactHaveCreditReportOnFile())
          })
        })
      })
    }
  }, [registration])

  useEffect(() => {
    dispatch(getAllActiveEmployersWithSSO())
    dispatch(getEnglishSpeakers()).then(()=>{
      dispatch(getSpanishSpeakers()).then(()=>{
        dispatch(getEnglishCurrentlyWorkingCounselors()).then(()=>{
          dispatch(getSpanishCurrentlyWorkingCounselors()).then(()=>{
            dispatch(setActiveCurrentlyWorkingCounselors())
            dispatch(setActiveCounselors())
            dispatch(setRandomCounselor())
            dispatch(getCalendlyRoundRobinUrl())
            dispatch(getCalendlyRoundRobinUrlSpanish())
          })
        })
      })
    })
  }, [login])

    useEffect(() => {
        if(login.nextPage !== null && login.nextPage == "TEMPPASSWORD" || login.nextPage == "CREATEPASSWORD")
            dispatch(getContact(grabContactId())).then(() => {
                history.push(`/make-a-password`)
            })
    });

    function grabContactId() {
        const sfId = location.search;
        const idParams = new URLSearchParams(sfId);
        const contactId = idParams.get("sfrecid")
        return contactId;
    }

  return (
    <Switch>
      <Route exact path="/action-plan"><ActionPlanDashboard /></Route>

      <Route exact path="/action-plan/terms-of-service"><ActionPlanTermsOfServiceContainer /></Route>
      <Route exact path="/action-plan/privacy-policy"><ActionPlanPrivacyPolicyContainer /></Route>
      <Route exact path="/action-plan/feedback" ><ActionPlanFeedbackContainer /></Route>
      <Route exact path="/action-plan/account"><ActionPlanMyAccountContainer/></Route>
      <Route exact path="/action-plan/fico-score"><ActionPlanFicoScore/></Route>
      <Route exact path="/action-plan/sessions"><ActionPlanSessions /></Route>
      <Route exact path="/action-plan/sessions/appointment-schedule"><ActionPlanAppointmentSchedule /></Route>
      <Route exact path="/action-plan/sessions/appointment-confirmation"><ActionPlanAppointmentConfirmation /></Route>
      <Route exact path="/action-plan/sessions/select-appointment"><ActionPlanSelectByAvailibility /></Route>
      <Route exact path="/action-plan/sessions/pick-your-coach"><ActionPlanPickCoach /></Route>
      <Route exact path="/action-plan/take-action-today"><ActionPlanTakeActionToday currentComponent="TakeActionTodayOverview" /></Route>
      <Route exact path="/action-plan/take-action-today/create"><ActionPlanTakeActionToday currentComponent="TakeActionTodayForm" /></Route>
      <Route exact path="/action-plan/debt-summary"><ActionPlanDebtSummary /></Route>
      <Route exact path="/action-plan/budget"><ActionPlanBudget /></Route>
      <Route exact path="/action-plan/messages"><ActionPlanMessages /></Route>
      <Route exact path="/action-plan/link-money/gateway"><ActionPlanLinkMoneyGateway /></Route>
      <Route exact path="/action-plan/link-money/confirmation"><ActionPlanLinkMoneyConfirmation /></Route>
      <Route exact path="/action-plan/link-money/get-started"><ActionPlanLinkMoneyLanding /></Route>
      <Route exact path="/action-plan/link-money/redirect"><ActionPlanLinkMoneyRedirect /></Route>
      <Route exact path="/action-plan/link-money/category-chart"><ActionPlanLinkMoneyCategoryChart /></Route>
      <Route exact path="/action-plan/link-money/accounts"><ActionPlanLinkMoneyAccounts /></Route>
      <Route exact path="/action-plan/link-money/transactions"><ActionPlanLinkMoneyTransactions /></Route>
    </Switch>
  )
}
