import React from 'react'
import FormattedMessage from '../helpers/FormattedMessage'
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";



function ActionPlanMessagesSubheader(props) {


    return (<>
        <div className='action-plan-sub-heading margin-bottom-8-px'>
            {props.selectedTab === props.tab_NEW && <FormattedMessage id='app.action-plan-messaging.compose'/>}
        </div>
        <div className='content-wrapper'>
            <div className='content-text-header'>
            </div>
            <br/>
        </div>
    </>)
}

export default (injectIntl(ActionPlanMessagesSubheader))