import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionPlanNav from "./action-plan-nav";
import FooterActionPlan from "../footer-action-plan";
import FormattedMessage from "../helpers/FormattedMessage";
import {useHistory} from "react-router-dom";
import {getLinkedAccounts, displayLinkMoneyModal, getConnectedAccounts} from "../../actions/link-money-actions";
import Icon from "../../../resources/icons";

export default function ActionPlanLinkMoneyConfirmation () {

    const login = useSelector(state => state.login);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(displayLinkMoneyModal(false))
    })

    useEffect(() => {
        dispatch(getConnectedAccounts())
    }, [])

    return (
        <div id='action-plan-link-money-confirmation'>
            <ActionPlanNav />
            <div id='action-plan-content'>
                <div className='action-plan-title-wrapper margin-bottom-24-px'>
                    <div className='action-plan-title-background-gradient'></div>
                    <h1 className='action-plan-title margin-bottom-8-px'>
                        <FormattedMessage id='app.action-plan-link-money.title'/>
                    </h1>
                </div>

                <div className='link-money-confirmation-image-container'>
                    <div className='link-money-confirmation-image'>
                        <Icon name="award" className="award-icon" />
                    </div>
                </div>

                <div className='link-money-confirmation-header'>
                    <FormattedMessage id='app.action-plan-link-money-confirmation.header'/>
                </div>

                <div className='link-money-confirmation-description margin-bottom-24-px'>
                    <FormattedMessage id='app.action-plan-link-money-confirmation.description'/>
                </div>

                <div className="submit-button-round btn-link-money margin-bottom-24-px">
                    <button id='link-money-landing-btn'
                            type={'button'}
                            className="btn btn-primary btn-block btn-lg"
                            onClick={(e) => history.push('/action-plan/link-money/gateway')}>
                        <FormattedMessage id='app.action-plan-link-money-confirmation.button'/>
                    </button>
                </div>

                <div className='link-money-landing-link margin-bottom-24-px'>
                    <a href={"/action-plan"}><FormattedMessage id='app.action-plan-link-money.button.cancel'/></a>
                </div>

                <FooterActionPlan />
            </div>
        </div>
    )
}