import React from 'react'

export const DclProducts = ({
                              style = {},
                              fill = '#fff',
                              width = '100%',
                              className = '',
                              height = '100%',
                              viewBox = '0 0 200 200',
                            }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >

    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <circle cx="100" cy="100" r="100" fill="#003057"/>
        <g>
          <g>
            <rect x="112.45" y="65.55" width="7.58" height="21.93" rx="3.79" ry="3.79" transform="translate(150.62 -58.33) rotate(70.95)" fill="#fff" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <rect x="113.22" y="85.35" width="7.58" height="21.93" rx="3.79" ry="3.79" transform="translate(176.63 -43.02) rotate(73.71)" fill="#fff" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <rect x="117.26" y="77.34" width="8.96" height="14.41" rx="4.48" ry="4.48" transform="translate(174.22 -54.07) rotate(75.96)" fill="#fff" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <rect x="117.71" y="97.95" width="6.1" height="12.52" rx="3.05" ry="3.05" transform="translate(186.91 -40.94) rotate(73.71)" fill="#fff" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <path d="M106.51,119.85c-.57,1.53-3.7,11.4-11.43,15.75-7.74,4.36-8.5,5.97-8.5,5.97l-14.73-5.2s3.12-43,22.9-37.96c19.78,5.04,11.77,21.44,11.77,21.44Z" fill="#fff" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <polygon points="95.16 112.13 71.6 86.75 96.99 63.19 113.99 91.89 95.16 112.13" fill="#003057" stroke="#00c7b1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <g>
              <g id="Phone">
                <rect x="78.53" y="35.07" width="42.73" height="87.46" rx="6.07" ry="6.07" transform="translate(-.37 .48) rotate(-.27)" fill="#003057" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
              </g>
              <path d="M110.3,35.41c.15.24.23.51.23.81v.94c0,.87-.7,1.59-1.58,1.6l-18.16.09c-.85,0-1.55-.69-1.55-1.54v-.98c0-.3.08-.57.22-.81" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            </g>
            <path d="M78,131c8.32-9.05,3.16-28.75,2.26-34.61-.4-2.64-.45-4.72-.38-6.21.06-1.32.35-2.61.82-3.84,2.25-5.85,4.94-22.01.06-23.14-3.54-.82-5.2,3.7-5.9,6.71-.51,2.19-1.15,4.34-1.94,6.45-1.02,2.74-2.19,6.05-2.27,6.86-.13,1.43-2.55,8.05-4.24,11.31-.42.81-1.42,2.77-2.14,4.98-1.18,3.57-1.93,7.25-2.28,10.99l-1.34,10.78c-.11.85-.25,1.7-.45,2.53-.96,4.16-2.09,12.84-6.94,21.49-1,1.79,21.32,13.45,23.13,10.76,4.55-6.76,8.44-12.45,11.64-16.59" fill="#fff" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <rect x="47.67" y="132.95" width="45.18" height="24.65" transform="translate(57.85 -15.62) rotate(21.4)" fill="#003057" filter="url(#drop-shadow-1)" stroke="#00c7b1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
          </g>
          <g>
            <circle cx="99.71" cy="57.91" r="11.25" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2.21"/>
            <path d="M102.42,55.84c-.57-.38-1.17-.68-1.75-.83v2.15c2.15.58,3.07,1.44,3.07,3.03v.03c0,1.58-1.22,2.6-3,2.79v1.4h-1.47v-1.43c-1.22-.16-2.4-.64-3.34-1.34l1.1-1.61c.76.58,1.47.98,2.31,1.15v-2.25c-2.04-.55-3.04-1.32-3.04-3v-.03c0-1.56,1.19-2.59,2.97-2.77v-.82h1.47v.86c1,.13,1.85.49,2.63,1.03l-.95,1.65ZM99.34,56.8v-1.92c-.69.09-.98.42-.98.88v.03c0,.45.2.74.98,1.02ZM100.67,59.25v1.98c.66-.1,1-.4,1-.9v-.03c0-.47-.24-.76-1-1.05Z" fill="#00c7b1"/>
          </g>
          <g>
            <line x1="86.36" y1="81.64" x2="114.66" y2="81.64" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <line x1="86.36" y1="93.87" x2="114.66" y2="93.87" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
          </g>
          <g>
            <path d="M100.51,129.16c0-6.36,2.67-12.77,8.19-16.26,1.61-1.02-8.19-8.42-8.19-8.42,7.19-.17,12.79,5.36,13.84,4.86,3.99-1.9,8.62-2.98,13.54-2.98,15.12,0,27.38,10.2,27.38,22.79s-12.26,22.79-27.38,22.79-27.38-10.2-27.38-22.79Z" fill="#00c7b1" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
            <polyline points="118.02 130.75 124.22 136.39 137.75 120.04" fill="none" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.21"/>
          </g>
        </g>
      </g>
    </g>

  </svg>;

export default DclProducts